import { Loader } from 'components';
import { IFormDashboardButton } from "interfaces/Form/IFormDashboardButton";
import React from 'react';

const DashboardButton: React.FC<IFormDashboardButton> = ({
    label,
    icon,
    type = 'button',
    onClick,
    disabled = false,
    classname = "dashboardBtn",
    style,
    name,
    ref,
    displayLoader = false,
    loader = {
        size: 12,
        color: 'var(--background-color)',
    }
}) => {
    return (
        <button
            type={type}
            className={classname}
            style={style}
            disabled={disabled}
            name={name}
            ref={ref}
            onClick={onClick}
        >
            {
                label
            }
            {displayLoader &&
                <Loader
                    size={loader?.size ? loader.size : 12}
                    isSmall={loader?.isSmall}
                    color={loader?.color}
                    loaderDescription={loader?.loaderDescription}
                    spacing={{
                        left: '10px'
                    }}
                />
            }
        </button>
    )
}

export default DashboardButton;