import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DashboardUserParameters from "components/common/DashboardUserParameters/DashboardUserParameters";
import ReferringDoctor from "components/common/ReferringDoctor/ReferringDoctor";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import React, { Fragment, useContext } from "react";
import { useTranslation } from "services";
import AddressInformation from "./components/AddressInformation";
import ContactInformation from "./components/ContactInformation";
import GeneralInformation from "./components/GeneralInformation";
import HealthInformation from "./components/HealthInformation";
import ProfileAdditionalActions from "./components/ProfileAdditionalActions";
import SpecialInformation from "./components/SpecialInformation";
import "./scss/PatientProfile.scss";

const NewPatientProfile: React.FC = () => {
    const t = useTranslation('pages/profile');
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const handleShouldShowDoctor = (): boolean => {
        if (subscriptionTrackingDetail?.isPlanMicrobiotaOnly()) return false;
        if (subscriptionTrackingDetail?.isPlanMicrobiotaStressBurnout()) return false;
        if (subscriptionTrackingDetail?.isPlanStressBurnout()) return false;

        return true;
    }

    return (
        <div className="column" id="patient-profile">
            <DashboardHeader preTitle={t.translate("pre")} title={t.translate("title")} />
            <div className="flex-column full-width">
                <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                    <GeneralInformation />
                    <ContactInformation />
                </div>
                <AddressInformation />
                {handleShouldShowDoctor()
                    ? <Fragment>
                        <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                            <HealthInformation />
                            <ReferringDoctor />
                        </div>
                        <SpecialInformation />
                    </Fragment>
                    : <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                        <HealthInformation />
                        <SpecialInformation />
                    </div>
                }
                <DashboardUserParameters />
                <ProfileAdditionalActions />
            </div>
        </div>
    );
}

export default NewPatientProfile;