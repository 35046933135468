import { parseISO } from "date-fns";

class DateService {
    private isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

    public isIsoDateString(value: Date | string | number): boolean {
        return (!!(value && typeof value === "string") && this.isoDateFormat.test(value));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public handleDates(body?: any) {
        if (body === null || body === undefined || typeof body !== "object") return body;

        for (const key of Object.keys(body)) {
            const value = body[key];

            if (this.isIsoDateString(value)) {
                body[key] = parseISO(value.toString());
            } else if (typeof value === "object") {
                this.handleDates(value);
            }
        }

        return body;
    }

    public addHours(oldDate: Date, hours: number) {
        const date = new Date(oldDate);
        date.setHours(date.getHours() + hours);

        return date;
    }

    public removeHours(oldDate: Date, hours: number) {
        const date = new Date(oldDate);
        date.setHours(date.getHours() - hours);

        return date;
    }

    public addDays(oldDate: Date, days: number) {
        const date = new Date(oldDate);
        date.setDate(date.getDate() + days);

        return date;
    }

    public removeDays(oldDate: Date, days: number) {
        const date = new Date(oldDate);
        date.setDate(date.getDate() - days);

        return date;
    }

    public calculateAge(birthDay?: Date): number|null {
        if (!birthDay && birthDay !== 'Invalid Date') return null;

        const actualDay = new Date();

        let age = actualDay.getFullYear() - birthDay.getFullYear();

        if ((actualDay.getMonth() <= birthDay.getMonth()) && (actualDay.getDate() < birthDay.getDate())) {
            age -= 1;
        }

        return age;
    }
}

export default new DateService();