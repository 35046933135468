import { Loader } from "components";
import VideoMeeting from "components/VideoMeeting/common/VideoMeeting";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import doctorService from "services/doctor.service";
import userAuthService from "services/user-auth.service";

const Patient: React.FC = () => {

    const { roomId, doctorId } = useParams<({ roomId: string, doctorId: string })>();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ meetingDoctor, setMeetingDoctor ] = useState<string>();

    const fetchDoctor = useCallback(() => {
        setIsLoading(true);

        userAuthService.getDoctor(doctorId)
            .then((res) => setMeetingDoctor(doctorService.formatDoctorName(res?.doctorInfo)))
            .catch(() => setMeetingDoctor(undefined))
            .finally(() => setIsLoading(false));
    }, [doctorId]);

    useEffect(() => {
        fetchDoctor();
    }, [fetchDoctor]);

    return isLoading
        ? <Loader />
        : <VideoMeeting
            roomId={roomId}
            side='patient'
            appointmentWith={meetingDoctor}
        />
}

export default Patient;