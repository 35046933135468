import cancer_detection_img from 'assets/home/cancer_detection.webp';
import microbiota_analysis_img from 'assets/home/microbiota_analysis.webp';
import { IDSubscriptionPlanType } from "interfaces/Database";
import { SolutionName } from 'interfaces/Database/IDatabaseSubscriptionPlanType';
import React, { createContext, useState } from "react";

export interface SubscriptionPlanTypeWithImage extends IDSubscriptionPlanType {
    image?: string
    name: SolutionName
    showInGroup: ('microbiota' | 'other')[]
}

type SolutionsContextProps = {
    solutions: SubscriptionPlanTypeWithImage[];
    setSolutions: (solutions: SubscriptionPlanTypeWithImage[]) => void;
}

export const defaultSolutionsList: SubscriptionPlanTypeWithImage[] = [
    {
        name: 'microbiota_analysis',
        price: 1195,
        monthlyPayment: false,
        standaloneAllowed: true,
        category: undefined,
        description: undefined,
        image: microbiota_analysis_img,
        showInGroup: ['microbiota']
    },
    {
        name: 'microbiota_only',
        price: 275,
        monthlyPayment: false,
        standaloneAllowed: true,
        category: undefined,
        description: undefined,
        image: microbiota_analysis_img,
        showInGroup: ['microbiota']
    },
    {
        name: 'microbiota_stress_burnout',
        price: 395,
        monthlyPayment: false,
        standaloneAllowed: true,
        category: undefined,
        description: undefined,
        image: microbiota_analysis_img,
        showInGroup: ['microbiota']
    },
    {
        name: 'stress_burnout',
        price: 179,
        monthlyPayment: false,
        standaloneAllowed: true,
        category: undefined,
        description: undefined,
        image: microbiota_analysis_img,
        showInGroup: ['microbiota']
    },
    {
        name: 'cancer_detection',
        price: 1595,
        monthlyPayment: false,
        standaloneAllowed: true,
        category: undefined,
        description: undefined,
        image: cancer_detection_img,
        showInGroup: ['other']
    }
];

export const SolutionsContext = createContext<SolutionsContextProps>({
    solutions: defaultSolutionsList,
    setSolutions: (solutions: SubscriptionPlanTypeWithImage[]) => undefined,
});

export const CountriesProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const [ solutions, setSolutions ] = useState<SubscriptionPlanTypeWithImage[]>(defaultSolutionsList);

    return (
        <SolutionsContext.Provider value={{ solutions, setSolutions }}>
            {children}
        </SolutionsContext.Provider>
    )
}