import img from 'assets/Help/profile.svg';
import React from 'react';
import { useTranslation } from 'services';

const SSFirstPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2><span className='text-main-color'>1.</span> {t.translate('standalone.profile.title')}</h2>
            <div className="help-step-content">
                <img src={img} className='help-img' alt="Complete profile" />
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '320px' }}>
                    <p><span className='text-important'>{t.translate('standalone.profile.addresses')}</span> {t.translate('standalone.profile.personalized')}</p>
                    <p><span className='text-important'>{t.translate('standalone.profile.allData')}</span> {t.translate('standalone.profile.consult')} <span className='text-important'>{t.translate('standalone.profile.protected')}</span>.</p>
                </div>
            </div>
        </div>
    )
}

export default SSFirstPage;