import { Button, Input, Label, LeftArrowIcon, TextArea } from "components";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import contactService from "services/contact.service";
import verifierService from "services/verifier.service";
import "../scss/FormContactUs.scss";

const FormContactUs: React.FC<({ handleSuccess: () => void })> = ({ handleSuccess }) => {
    const t = useTranslation('contact-us');
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ businessName, setBusinessName ] = useState<string>("");
    const [ email, setEmail ] = useState<string>("");
    const [ firstname, setFirstname ] = useState<string>("");
    const [ lastname, setLastname ] = useState<string>("");
    const [ comment, setComment ] = useState<string>("");

    const handleSendInfo = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        contactService.sendContact(
            businessName,
            email,
            firstname,
            lastname,
            comment
        )
            .then(() => handleSuccess())
            .catch((e) => console.error(e))
            .finally(() => setIsLoading(false));
    }

    return (
        <form id="form-contact-us" className="column shadow" onSubmit={handleSendInfo}>
            <LeftArrowIcon onClick={() => history.go(-1)} />

            <div id="title-container" className="column">
                <h1 className="title font-title-bold">
                    {t.translate('title')}
                </h1>
                <span>{t.translate('sub')}</span>
            </div>

            <div className="row inputs-row" style={{ margin: '10px 0 10px 0' }}>
                <div className="flex-column-start">
                    <Label
                        for="company"
                        label={t.translate('business')}
                    />
                    <Input
                        name="company"
                        disabled={isLoading}
                        value={businessName}
                        setValue={(e) => setBusinessName(e.currentTarget.value)}
                    />
                </div>
                <div className="flex-column-start">
                    <Label
                        required
                        for="email"
                        label={t.translate('email')}
                    />
                    <Input
                        required
                        name="email"
                        type="email"
                        disabled={isLoading}
                        value={email}
                        setValue={(e) => setEmail(e.currentTarget.value)}
                        checkFunction={() => verifierService.checkEmail(email)}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
            </div>
            <div className="row inputs-row" style={{ margin: '10px 0 10px 0' }}>
                <div className="flex-column-start">
                    <Label
                        required
                        for="firstname"
                        label={t.translate('firstName')}
                    />
                    <Input
                        required
                        name="firstname"
                        disabled={isLoading}
                        value={firstname}
                        setValue={(e) => setFirstname(e.currentTarget.value)}
                        checkFunction={() => firstname !== ""}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
                
                <div className="flex-column-start">
                    <Label
                        required
                        for="lastname"
                        label={t.translate('lastName')}
                    />
                    <Input
                        required
                        name="lastname"
                        disabled={isLoading}
                        value={lastname}
                        setValue={(e) => setLastname(e.currentTarget.value)}
                        checkFunction={() => lastname !== ""}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
            </div>
            <div className="full-width flex-column-start" style={{ margin: '10px 0 10px 0' }}>
                <Label
                    required
                    for="content"
                    label={t.translate('content')}
                />
                <TextArea
                    required
                    name="content"
                    disabled={isLoading}
                    value={comment}
                    setValue={(e) => setComment(e.currentTarget.value)}
                    checkFunction={() => comment !== "" && comment.length > 10}
                    checkmark={{
                        displayed: true
                    }}
                />
            </div>
            <Button label={t.translate('button')} type="submit" disabled={isLoading} />

            <div className="column" id="send-email-wrapper">
                <p id="send-email-text">
                    {t.translate('ratherSendEmail')}{' '}
                    <span id="email-label" onClick={() => window.open("mailto: contact@bodycheckup.com")}>
                        contact@bodycheckup.com
                    </span>
                </p>
            </div>
        </form>
    );
}

export default FormContactUs;