import { Button, Loader } from "components";
import { EditAddress } from "components/common/Address";
import useIsComponentMounted from "hooks/useIsComponentMounted";
import { IAddress, defaultAddress } from "interfaces/User/IAddress";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import addressService from "services/address.service";
import verifierService from "services/verifier.service";
import "../../scss/ClaimKitFormUserAddress.scss";

const ClaimKitFormUserAddress: React.FC<({
    handleNextStep: () => void
})> = ({ handleNextStep }) => {
    const t = useTranslation('firstConnection');
    const isMounted = useIsComponentMounted();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ delivery, setDelivery ] = useState<IAddress>(defaultAddress);
    const [ billing, setBilling ] = useState<IAddress>(defaultAddress);
    const [ isBillingSameAddress, setBillingSameAddress ] = useState<boolean>(true);

    const fetchData = async () => {
        addressService.getAddresses()
            .then((res) => {
                if (res.shipping) setDelivery(addressService.databaseAddressToLocal(res.shipping));
                if (res.billing) setBilling(addressService.databaseAddressToLocal(res.billing));

                if (res.billing && res.shipping && addressService.compareAddresses(res.billing, res.shipping)) setBillingSameAddress(true);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (isMounted.current) {
            fetchData();
        }
    }, []);

    const handleSaveData = async () => {
        if (
            verifierService.checkString(delivery.streetAddress) &&
            verifierService.checkString(delivery.postalCode) &&
            verifierService.checkString(delivery.city) &&
            verifierService.checkString(delivery.countryName)
        ) {
            addressService.updateAddresses({
                shipping: delivery,
                billing: billing,
                sameAddresses: isBillingSameAddress
            })
                .then(() => handleNextStep())
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }
    }

    if (isLoading) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <div id="claim-kit-form-user-address" className="column">
                <div id="claim-kit-form-user-address-wrapper" className='column'>
                    <h1 id="title">{t.translate('yourAddress')}</h1>
                    
                    <EditAddress isLoading={isLoading} address={delivery} setAddress={setDelivery} />
                    <div className="flex-column full-width">
                        <Button label={t.translate('next')} onClick={() => handleSaveData()} disabled={isLoading} displayLoader={isLoading} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ClaimKitFormUserAddress;