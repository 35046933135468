import { UserTypeContext } from "context/User/UserTypeContext";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import React, { useContext } from "react";
import { useTranslation } from "services";
import CompanySidebar from "./components/CompanySidebar";
import DefaultSidebar from "./components/DefaultSidebar";
import DoctorSidebar from "./components/DoctorSidebar";
import PatientSidebar from "./components/PatientSidebar";
import StandaloneSidebar from "./components/StandaloneSidebar";

const Sidebar: React.FC<({
    menuStatus?: IMenuStatus,
    user?: IDatabaseUser
})> = ({
    menuStatus,
    user
}) => {
    const t = useTranslation('layout/sidebar');
    const { type } = useContext(UserTypeContext);

    function renderSidebar() {
        switch (true) {
            case type.isCompany():
                return <CompanySidebar menuStatus={menuStatus} user={user} />
            case type.isDoctor():
                return <DoctorSidebar menuStatus={menuStatus} user={user} />
            case type.isStaff():
            case type.isPatient():
                return <PatientSidebar menuStatus={menuStatus} user={user} />
            case type.isStandalone():
                return <StandaloneSidebar menuStatus={menuStatus} user={user} />
            default:
                return <DefaultSidebar
                    menuStatus={menuStatus}
                    menuItems={[
                        {
                            name: t.translate('menuItems.employee.logout.name'),
                            path: '/logout',
                            code: 'logout',
                            logout: true
                        }
                    ]}
                    helloMessage={<p className="sidebar-title text-uppercase">{t.translate('header.hello')} <span id="last-name">{user?.patientInfo?.firstname ?? 'PATIENT'}</span></p>}
                    subMessage={<p id="subTitle">{t.translate('header.defaultSub')}</p>}
                />
        }
    }

    return (
        renderSidebar()
    );
}

export default Sidebar;