import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const CustomCircleProgressbar: React.FC<({
    min?: number;
    max?: number;
    value?: number;
    text?: string;
    pathColor?: string;
    displayValue?: boolean;
})> = ({
    min = 0,
    max = 0,
    value = 0,
    text = "",
    pathColor = 'var(--light-gray)',
    displayValue = false
}) => {
    return (
        <CircularProgressbar
            value={value}
            text={text ? text : displayValue ? `${value}/${max}` : undefined}
            minValue={min}
            maxValue={max}
            styles={buildStyles({
                textColor: '#000',
                trailColor: 'var(--light-gray)',
                pathColor: pathColor,
                pathTransitionDuration: 2,
            })}
            counterClockwise={true}
            className="MavenPro-bold"
        />
    )
}

export default CustomCircleProgressbar;