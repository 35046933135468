import { InformationCircleIcon } from "components";
import React, { CSSProperties, useState } from "react";
import './scss/ToolTip.scss';

type directions = 'top' | 'right' | 'bottom' | 'left';

const ToolTip: React.FC<({
    children: React.ReactNode
    space?: directions[]
    title?: string,
    style?: CSSProperties
})> = ({
    children = <p>Example text !</p>,
    space = ['right'],
    title,
    style
}) => {
    const [ isHovered, setIsHovered ] = useState<boolean>(false);

    const size = 24;

    const handleStyle = () => {
        const calculated: CSSProperties = {
            width: `${size}px`,
            height: `${size}px`,
            ...style
        }

        if (space.includes('top')) calculated.marginTop = '10px';
        if (space.includes('right')) calculated.marginRight = '10px';
        if (space.includes('bottom')) calculated.marginBottom = '10px';
        if (space.includes('left')) calculated.marginLeft = '10px';

        return calculated;
    }

    return (
        <div className="ToolTip" style={handleStyle()}>
            <InformationCircleIcon className="ToolTip-icon" onMouseEnter={() => setIsHovered(true)} />
            {isHovered && <div className={"ToolTip-section"} onMouseLeave={() => setIsHovered(false)}>
                {title && <h4>{title}</h4>}
                <div>
                    {children}
                </div>
            </div>}
        </div>
    )
}

export default ToolTip;