import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Button } from 'components';
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { ProfilePicture } from "components/common/ProfilePicture";
import { SubscriptionTrackingDetailContext } from 'context/SolutionProcessingContext';
import { UserContext } from "context/User/UserContext";
import { IDAppointment, IDAppointmentWithNutritionist } from 'interfaces/Database';
import { IDatabaseAnalysisKit } from "interfaces/Database/IDatabaseAnalysisKit";
import { ISubscriptionTrackingStep } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingStep";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { HiCheck } from "react-icons/hi";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import doctorService from "services/doctor.service";
import "../scss/CurrentTest.scss";

interface step {
    index: number,
    libelle: string,
    value?: ISubscriptionTrackingStep
}

const CurrentTest: React.FC<({
    tracking?: ISubscriptionTrackingSteps,
    analysisKit?: IDatabaseAnalysisKit,
    nextAppointment?: IDAppointment | IDAppointmentWithNutritionist,
})> = ({
    tracking,
    analysisKit,
    nextAppointment
}) => {
    const t = useTranslation('dashboard/patient/recommendations');
    const history = useHistory();
    const { user } = useContext(UserContext);
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ activeStep, setActiveStep ] = useState<number>(-1);
    const [ steps, setSteps ] = useState<step[]>([]);

    const handleShouldDisplayAppointment = (): boolean => {
        if (subscriptionTrackingDetail?.isPlanCancerDetection()) return false;
        if (subscriptionTrackingDetail?.isPlanMicrobiotaOnly() && !subscriptionTrackingDetail?.doctorAppointment && !subscriptionTrackingDetail?.appointmentWNutritionist) return false;
        if (subscriptionTrackingDetail?.isPlanMicrobiotaStressBurnout() && !subscriptionTrackingDetail?.doctorAppointment && !subscriptionTrackingDetail?.appointmentWNutritionist) return false;
        if (subscriptionTrackingDetail?.isPlanStressBurnout() && !subscriptionTrackingDetail?.doctorAppointment && !subscriptionTrackingDetail?.appointmentWNutritionist) return false;

        // Display appointment by default
        return true;
    }

    const DEFAULT_STEPS = useMemo<step[]>(() =>
        handleShouldDisplayAppointment()
            ? [
                {
                    index: 1,
                    libelle: 'subscriptionTracking.steps.step-kit-registered',
                    value: tracking?.kitClaim
                },
                {
                    index: 2,
                    libelle: 'subscriptionTracking.steps.step-kit-sent',
                    value: tracking?.kitSend
                },
                {
                    index: 3,
                    libelle: 'subscriptionTracking.steps.step-kit-under-analysis',
                    value: tracking?.kitAnalysing
                },
                {
                    index: 4,
                    libelle: 'subscriptionTracking.steps.step-kit-analysis-finalized',
                    value: tracking?.analyseDone
                },
                {
                    index: 5,
                    libelle: 'subscriptionTracking.steps.step-kit-scheduled-appointment',
                    value: tracking?.appointment
                },
            ]
            : [
                {
                    index: 1,
                    libelle: 'subscriptionTracking.steps.step-kit-registered',
                    value: tracking?.kitClaim
                },
                {
                    index: 2,
                    libelle: 'subscriptionTracking.steps.step-kit-sent',
                    value: tracking?.kitSend
                },
                {
                    index: 3,
                    libelle: 'subscriptionTracking.steps.step-kit-under-analysis',
                    value: tracking?.kitAnalysing
                },
                {
                    index: 4,
                    libelle: 'subscriptionTracking.steps.step-kit-analysis-finalized',
                    value: tracking?.analyseDone
                },
            ]
    , [tracking?.kitClaim, tracking?.kitSend, tracking?.kitAnalysing, tracking?.analyseDone, tracking?.appointment]);


    const initActiveStep = useCallback(() => {
        let index = 0;
        let isFinish = false;

        while (index < DEFAULT_STEPS.length && !isFinish) {
            DEFAULT_STEPS[index].value?.isDone ?
                index++ : isFinish = true;
        }

        setActiveStep(index > 0 ? index - 1 : -1);
    }, [DEFAULT_STEPS]);


    useEffect(() => {
        initActiveStep();

        if (activeStep >= (DEFAULT_STEPS.length-1)) {
            setSteps([
                DEFAULT_STEPS[activeStep-1],
                DEFAULT_STEPS[activeStep]
            ]);
        } else {
            if (activeStep === -1) {
                setSteps([
                    DEFAULT_STEPS[activeStep+1],
                    DEFAULT_STEPS[activeStep+2]
                ]);
            } else {
                setSteps([
                    DEFAULT_STEPS[activeStep],
                    DEFAULT_STEPS[activeStep+1]
                ]);
            }
        }
    }, [initActiveStep, DEFAULT_STEPS, activeStep]);

    return (
        <DefaultContainer
            title={
                <div className="flex-column-start full-width" id="title-wrapper">
                    <h1 className="bold">
                        {t.translate('current-test.title')}
                    </h1>
                    <div className="flex-row space-between align-items-center full-width">
                        <span className={`sub-title ${!analysisKit?.verificationCode ? 'no-data' : ''}`}>
                            {analysisKit?.verificationCode ? '#' + analysisKit.verificationCode : t.translateFromFile('common', 'no-data')}
                        </span>
                        <div id="title-line-separator" />
                        <span className={`sub-title ${!analysisKit?.createdOn ? 'no-data' : ''}`}>
                            {analysisKit?.createdOn ? t.translateDate(analysisKit.createdOn) : t.translateFromFile('common', 'no-data')}
                        </span>
                    </div>
                </div>
            }
            body={
                <div className="flex-row" id="current-test-content-wrapper">
                    <div id="stepper-wrapper">
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepLabel
                                    icon={
                                        <div
                                            className={`
                                                step MavenPro flex-column ${!step?.value?.isDone ?
                                                    "step-not-done" :
                                                    "step-done"} 
                                            `}
                                        >
                                            {!step?.value?.isDone ?
                                                <span>0{step.index}</span> :
                                                <HiCheck size={24} className="icon-check" />
                                            }
                                        </div>
                                    }
                                >
                                    <span className="step-label">
                                        {t.translate(step?.libelle)}
                                    </span>
                                    {(step?.index === 1 && activeStep === 0) && (
                                        step?.value?.dhlToPatient
                                            ? <p className="clickable text-main-color" onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${step?.value?.dhlToPatient}`, '_blank')}>{t.translate('subscriptionTracking.follow-up-number') + ' ' + step?.value?.dhlToPatient}</p>
                                            : <p>{t.translate('subscriptionTracking.noFollowUp')}</p>
                                    )}
                                    {(step?.index === 2 && activeStep === 1 && subscriptionTrackingDetail?.analysisKit?.activated) && (
                                        step?.value?.dhlToLab
                                            ? <p className="clickable text-main-color" onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${step?.value?.dhlToLab}`, '_blank')}>{t.translate('subscriptionTracking.follow-up-number') + ' ' + step?.value?.dhlToLab}</p>
                                            : <p>{t.translate('subscriptionTracking.noFollowUpLab')}</p>
                                    )}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    </div>
                    {handleShouldDisplayAppointment() &&
                        <div id="doctor-info-wrapper" className="column">
                            <div className="row" id="doctor-info-header-wrapper">
                                <ProfilePicture
                                    user={user?.doctor}
                                    margin={{
                                        right: true
                                    }}
                                    maxSize={'6rem'}
                                />
                                <div className="column">
                                    <span id="doctor-name" className="MavenPro-black">
                                        {doctorService.formatDoctorName(nextAppointment
                                            ? 'doctor' in nextAppointment
                                                ? nextAppointment.doctor.doctorInfo
                                                : nextAppointment.nutritionist.doctorInfo
                                            : user?.doctor?.doctorInfo)}
                                    </span>
                                    <span id="appointment-date" className={!nextAppointment?.appointmentOn ? 'no-data' : ''}>
                                        {nextAppointment?.appointmentOn ? t.translateDate(nextAppointment.appointmentOn) : t.translateFromFile('common', 'no-data')}
                                    </span>
                                </div>
                            </div>
                            <div className="column" id="video-meeting-wrapper">
                                <div id="video-status" className="row">
                                    {nextAppointment?.status?.name === "Done"
                                        ? <span className="MavenPro-black">
                                            {t.translate('current-test.video-status-done')}
                                        </span>
                                        : <span className="MavenPro-black">
                                            {t.translate('current-test.video-status-not-done')}
                                        </span>
                                    }
                                </div>
                                <span id="video-recommendations">
                                    {t.translate('current-test.video-info')}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            }
            footer={
                subscriptionTrackingDetail?.doctorAppointment?.isDone()
                    && <Button label={t.translate('current-test.detailBtn')} onClick={() => history.push('/account/recommendations/details')} />
            }
        />
    );
}

export default CurrentTest;