import { IDatabaseHistory } from "interfaces/Database/IDatabaseHistory";
import APIService from "./APIService";

class LogService {
    private ApiService = new APIService('/history');

    public async getHistory() {
        return await this.ApiService.call<IDatabaseHistory[]>('/', 'get');
    }
}

function useLogService(): LogService {
    return new LogService();
}

export default useLogService;