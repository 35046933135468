import { Loader } from "components";
import LastPrescription from "components/Prescription/components/LastPrescription";
import PrescriptionList from "components/Prescription/components/PrescriptionList";
import "components/Prescription/scss/Prescription.scss";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import ReferringDoctor from "components/common/ReferringDoctor/ReferringDoctor";
import { IDPrescription } from "interfaces/Database";
import 'moment-timezone';
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "services";
import prescriptionService from "services/prescription.service";

const Prescriptions: React.FC = () => {
    const t = useTranslation('dashboard/patient/prescription');
    
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ prescriptions, setPrescriptions ] = useState<IDPrescription[]>([]);
    const [ lastPrescription, setLastPrescription ] = useState<IDPrescription>();

    const fetchPrescriptions = useCallback(async () => {
        setIsLoading(true);

        prescriptionService.getAllPrescriptions()
            .then((res) => {
                setPrescriptions(res);
                setLastPrescription(res[res.length-1]);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchPrescriptions()
    }, [fetchPrescriptions]);

    return (
        <div id="prescription" className="flex-column">
            <DashboardHeader
                preTitle={t.translate('header.my')}
                title={t.translate('header.prescriptions')}
            />
            <div className="flex-column full-width">
                {isLoading
                    ? <Loader />
                    : <Fragment>
                        <div className="flex-row dashboard-container-gap flex-wrap full-width align-items-stretch">
                            <ReferringDoctor />
                            <LastPrescription prescription={lastPrescription} />
                        </div>
                        <PrescriptionList prescriptions={prescriptions} />
                    </Fragment>}
            </div>
        </div>
    )
}

export default Prescriptions;