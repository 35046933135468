import { CheckIcon, CloseIcon } from "components";
import { IFormDashboardInput } from "interfaces/Form/IFormDashboardInput";
import React, { CSSProperties } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete from "use-places-autocomplete";

const DashboardCityAutocompleteInput: React.FC<(IFormDashboardInput)> = ({
    placeholder,
    name,
    disabled = false,
    required = false,
    backgroundColor = "transparent",
    min,
    type = "text",
    value,
    setValue,
    checkFunction,
    checkmark = {
        displayed: false
    },
}) => {
    const invalidDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'none',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '34px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const validDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'none',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '34px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const checkmarkDefaultStyle: CSSProperties = {
        position: 'absolute',
        top: 0,
        right: '10px',
        marginTop: '12px'
    }

    const {
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        ready,
        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        value: usePlacesAutocompleteValue,
        suggestions: { status, data },
        setValue: setInputValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['locality'],
            componentRestrictions: {
                country: [
                    'fr',
                    'uk'
                ]
            }
        },
        debounce: 300
    });

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    // eslint-disable-next-line
    const handleSelect = ({ description }: any) => 
        () => {
            // When user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter to "false"
            setInputValue(description, false);
            clearSuggestions();

            if (setValue) {
                setValue({
                    currentTarget: {
                        value: description
                    }
                } as React.ChangeEvent<HTMLInputElement>);
            }
        };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)} className="address-suggestion">
                    <strong>{main_text}</strong> <span>{secondary_text}</span>
                </li>
            );
        });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setValue) setValue(e);
        setInputValue(e.currentTarget.value);
    }

    return (
        <div ref={ref} style={{ position: 'relative' }}>
            <input
                name={name}
                disabled={disabled}
                required={required}
                placeholder={placeholder}
                type={type}
                min={min}
                value={value}
                onChange={(e) => handleInputChange(e)}
                style={
                    checkFunction && !checkFunction() ?
                        invalidDashboardInput :
                        validDashboardInput
                }
                className={
                    checkFunction && !checkFunction() ?
                        'dashboardInput-invalid' :
                        'dashboardInput'
                }
            />
            {
                (!disabled && checkmark.displayed) && (
                    checkFunction && checkFunction() ?
                    (
                        checkmark.valid?.component ?
                            <checkmark.valid.component
                                color={checkmark.valid.color ? checkmark.valid.color : 'var(--dashboard-main-color)'}
                                className={checkmark.valid.className ? checkmark.valid.className : 'icon-check'}
                                style={checkmarkDefaultStyle} /> :
                            <CheckIcon size={16}
                                className="icon-check"
                                style={checkmarkDefaultStyle} />
                    ) :
                    (
                        checkmark.invalid?.component ?
                            <checkmark.invalid.component
                                color={checkmark.invalid.color ? checkmark.invalid.color : 'var(--red)'}
                                className={checkmark.invalid.className ? checkmark.invalid.className : 'icon-check'}
                                style={checkmarkDefaultStyle} /> :
                            <CloseIcon size={16}
                                color={`var(--red)`}
                                className="icon-check"
                                style={checkmarkDefaultStyle} />
                    )
                )
            }

            <div id="address-suggestion-container" className="column">
                {status === "OK" && <ul>{renderSuggestions()}</ul>}
            </div>
        </div>
    )
}

export default DashboardCityAutocompleteInput;