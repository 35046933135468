import { Button, Input } from 'components/Form';
import Add from 'components/Icons/Add';
import Remove from 'components/Icons/Remove';
import { Cart } from 'interfaces';
import { IComplement } from 'interfaces/Database';
import { Fragment } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'services';
import priceService from 'services/price.service';
import './CartRecap.scss';

interface CartRecapProps {
    cart: Cart<IComplement>
    setCart: React.Dispatch<React.SetStateAction<Cart<IComplement>>>
    handleNextStep: () => void
}

const CartRecap: React.FC<CartRecapProps> = (props) => {
    const t = useTranslation('pages/orderSolution');

    const handleUpdateCartItemQuantity = (complement: IComplement, newQuantity: number) => {
        const found = props.cart.items.filter((cartItem) => cartItem.item.id === complement.id);

        if (found.length > 0) {
            found[0].setQuantity(newQuantity);
        }

        props.setCart(new Cart(props.cart));
    }

    const handleIncreaseQuantity = (complement: IComplement) => {
        const found = props.cart.items.filter((cartItem) => cartItem.item.id === complement.id);

        if (found.length > 0) {
            found[0].increaseQuantity();
        }

        props.setCart(new Cart(props.cart));
    }

    const handleDecreaseQuantity = (complement: IComplement) => {
        const found = props.cart.items.filter((cartItem) => cartItem.item.id === complement.id);

        if (found.length > 0) {
            found[0].decreaseQuantity();
        
            if (found[0].quantity === 0) props.cart.removeItem(complement);
        }

        props.setCart(new Cart(props.cart));
    }

    const handleRemove = (complement: IComplement) => {
        props.setCart(new Cart(props.cart.removeItem(complement)));
    }

    return (
        <Fragment>
            <div className='cart-items-container'>
                {props.cart.items.length === 0
                    ? <i className='no-data'>{t.translate('emptyCart')}</i>
                    : props.cart.items.map((cartItem) =>
                        <div
                            key={cartItem.item.id}
                            className='flex-row full-width'
                            style={{ columnGap: '.75vw' }}
                        >
                            <div className='flex-column-start flex-grow'>
                                <p className='MavenPro-bold' style={{ margin: '0' }}>{cartItem.item.name}</p>
                                <i>{priceService.roundAndFormat(cartItem.item.price)}€</i>
                            </div>
                            <Add className='clickable' size={20} color='white' style={{ backgroundColor: 'var(--dashboard-main-color)', borderRadius: '50%' }} onClick={() => handleIncreaseQuantity(cartItem.item)} />
                            <Input
                                inputStyle={{ width: '120px', minWidth: '120px' }}
                                wrapperStyle={{ width: '120px', minWidth: '120px' }}
                                type='number'
                                name={`complements-${cartItem.item.name}`}
                                value={cartItem.quantity}
                                setValue={(e) => handleUpdateCartItemQuantity(cartItem.item, e.currentTarget.valueAsNumber)}
                            />
                            <Remove className='clickable' size={20} color='white' style={{ backgroundColor: 'var(--dashboard-main-color)', borderRadius: '50%' }} onClick={() => handleDecreaseQuantity(cartItem.item)} />
                            <FaTrash className='clickable' color='var(--red)' size={20} onClick={() => handleRemove(cartItem.item)} />
                        </div>
                    )
                }
            </div>
            <div className='flex-row flex-gap-col'>
                <p className='full-width text-align-end'>
                    {t.translateReplaceValues('total', [
                        {
                            tag: '{{price}}',
                            value: priceService.roundAndFormat(props.cart.getTotal())
                        }
                    ])}
                </p>
                <Button label={'Suivant'} onClick={props.handleNextStep} disabled={props.cart.items.length === 0} />
            </div>
        </Fragment>
    )
}

export default CartRecap;