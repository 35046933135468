import { Button, Input, Label, TextArea } from "components";
import { Modal } from 'components/common/Modal';
import ToolTip from "components/common/ToolTip/ToolTip";
import { UserContext } from "context/User/UserContext";
import moment from 'moment';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";

const EditStandaloneHealthInfo: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void
})> = ({
    showModal,
    handleClose,
    handleSuccess
}) => {
    const t = useTranslation('profile/patient/myHealthInformation');
    const { user } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ size, setSize ] = useState<number | undefined>(user?.patientInfo?.size);
    const [ weight, setWeight ] = useState<number | undefined>(user?.patientInfo?.weight);
    const [ waistSize, setWaistSize ] = useState<number | undefined>(user?.patientInfo?.waistSize);
    const [ birthDate, setBirthDate ] = useState<Date | undefined>(user?.patientInfo?.birthDate);

    const [ specialMedication, setSpecialMedication ] = useState<string>(user?.patientInfo?.specialMedication ?? '');
    const [ specialInformation, setSpecialInformation ] = useState<string>(user?.patientInfo?.specialInformation ?? '');
    const [ socialSecurityNumber, setSocialSecurityNumber ] = useState<string>(user?.patientInfo?.socialSecurityNumber ?? '');

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'scroll';
    }

    const successModal = async () => {
        setIsLoading(true);
    
        standaloneService.updateInformations({
            birthDate: moment(birthDate).format('DD-MM-YYYY') === "Invalid date" ?
                undefined :
                moment(birthDate).format('DD-MM-YYYY'),
            size: size,
            weight: weight,
            waistSize: waistSize,
            specialMedication: specialMedication,
            specialInformation: specialInformation,
            socialSecurityNumber: socialSecurityNumber
        })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate("titleModal")}
            subTitle={t.translate('subTitle')}
            footer={
                <div className="row flex-end">
                    <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                    <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                </div>
            }
        >
            <div className="flex-row flex-wrap flex-gap-col flex-gap-row align-items-stretch full-width space-between" style={{ overflowX: "visible" }}>
                <div className="flex-column-start flex-grow">
                    <Label for="size" label={t.translate("size")} required labelClassName="flex-grow" />
                    <Input
                        required
                        name="size"
                        value={size}
                        setValue={(e) => e.currentTarget.valueAsNumber && setSize(e.currentTarget.valueAsNumber)}
                        type="number"
                        min={0}
                        max={400}
                    />
                </div>
                <div className="flex-column-start flex-grow">
                    <Label for="weight" label={t.translate("weight")} required labelClassName="flex-grow" />
                    <Input
                        required
                        name="weight"
                        value={weight}
                        setValue={(e) => e.currentTarget.valueAsNumber && setWeight(e.currentTarget.valueAsNumber)}
                        type="number"
                        min={0}
                        max={400}
                    />
                </div>
                <div className="flex-column-start flex-grow">
                    <div className="flex-row-start">
                        <ToolTip>
                            <p>{t.translate('waistSizeHelp')}</p>
                        </ToolTip>
                        <Label for="waistSize" label={t.translate("waistSize")} labelClassName="flex-grow" />
                    </div>
                    <Input
                        name="waistSize"
                        value={waistSize}
                        setValue={(e) => e.currentTarget.valueAsNumber && setWaistSize(e.currentTarget.valueAsNumber)}
                        type="number"
                        min={0}
                        max={400}
                    />
                </div>
                <div className="flex-column-start flex-grow">
                    <Label for="birthdate" label={t.translate("birthDate")} required labelClassName="flex-grow" />
                    <Input
                        required
                        name="birthdate"
                        value={birthDate ? moment(birthDate).format('YYYY-MM-DD') : null}
                        setValue={(e) => e.currentTarget.valueAsDate && setBirthDate(e.currentTarget.valueAsDate)}
                        type="date"
                    />
                </div>
                <div className="flex-column-start flex-grow">
                    <Label for="socialSecurityNumber" label={t.translate('socialSecurityNumber')} labelClassName="flex-grow" />
                    <Input
                        name="socialSecurityNumber"
                        value={socialSecurityNumber}
                        setValue={(e) => setSocialSecurityNumber(e.currentTarget.value)}
                    />
                </div>
            </div>
            <div className="flex-column-start full-width">
                <Label for="specialMedication" label={t.translate('specialMedication')} />
                <TextArea
                    name="specialMedication"
                    value={specialMedication}
                    setValue={(e) => setSpecialMedication(e.currentTarget.value)}
                />
            </div>
            <div className="flex-column-start full-width">
                <Label for="specialInformation" label={t.translate('specialInformation')} />
                <TextArea
                    name="specialInformation"
                    value={specialInformation}
                    setValue={(e) => setSpecialInformation(e.currentTarget.value)}
                />
            </div>
        </Modal>
    );
}

export default EditStandaloneHealthInfo;