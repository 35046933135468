class VerifierService {
    public checkString = (input?: string, params?: { len?: { min?: number, max?: number } }): boolean => {
        if (!input || input === null) return false;

        if (params?.len?.min && params.len.min > input.length) return false;
        if (params?.len?.max && params.len.max < input.length) return false;

        return true;
    }

    public checkNumber = (input?: number, params?: { min?: number, max?: number }): boolean => {
        let resp = true;

        if (input) {
            if (params?.min && params.min > input) resp = false;
            if (params?.max && params.max < input) resp = false;
        } else {
            resp = false;
        }

        return resp;
    }

    public checkEmail = (email?: string): boolean => {
        const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (email && filter.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    public checkDate = (input?: string | Date | null, params?: { min?: Date, max?: Date }): boolean => {
        let resp = true;

        let temp = input;

        if (temp) {
            if (typeof temp === 'string') {
                temp = new Date(temp);
                if (params?.min && params.min > temp) resp = false;
                if (params?.max && params.max < temp) resp = false;
            } else {
                if (params?.min && params.min > temp) resp = false;
                if (params?.max && params.max < temp) resp = false;
            }
        } else {
            resp = false;
        }

        return resp;
    }

    public checkPwdEnough = (password?: string): boolean => {
        const enoughRegex = new RegExp("(?=.{8,}).*", "g");

        if (password && enoughRegex.test(password)) {
            return true;
        } else {
            return false;
        }
    }

    public checkPwdSame = (password1?: string, password2?: string): boolean => {
        if (!password1 || !password2) {
            return false;
        }

        return password1 === password2;
    }

    public stringEquals = (firstString?: string, secondString?: string): boolean => {
        if (firstString && secondString && (firstString === secondString)) {
            return true;
        } else {
            return false;
        }
    }

    public checkPhoneNumber(phoneNumber?: string) {
        if (!phoneNumber || phoneNumber === null) {
            return false;
        }
        return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber);
    }

    public checkPhone(phoneCode?: string, phoneNumber?: string) {
        if (!phoneCode || phoneCode === null || !phoneNumber || phoneNumber === null) {
            return false;
        }
        return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneCode+phoneNumber);
    }
}

export default new VerifierService();