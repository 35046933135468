import CustomIconBack from 'components/common/CustomIconBack/CustomIconBack';
import React from 'react';
import '../scss/GoBack.scss';

const GoBack: React.FC<({
    text: string
    handleGoBack: (e: React.MouseEvent<SVGElement, MouseEvent>) => void
    className?: string
    containerStyle?: React.CSSProperties
})> = ({
    text,
    handleGoBack,
    className,
    containerStyle
}) => {
    return (
        <div id='go-back' className={`row ${className}`} style={containerStyle}>
            <CustomIconBack handleClick={handleGoBack} />
            <h2>{text.toUpperCase()}</h2>
        </div>
    )
}

export default GoBack