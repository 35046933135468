import axios from "axios";
import ErrorService from "services/Error.service";
import dateService from "services/date.service";
import userAuthService from "services/user-auth.service";

class APIService {
    private BASE_URI = process.env.REACT_APP_API_URL;
    private headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };

    constructor(baseURI?: string, activeCredentials = true) {
        this.BASE_URI = this.BASE_URI + '/api' + baseURI;

        axios.defaults.withCredentials = activeCredentials;
        axios.interceptors.response.use(
            (originalResponse) => {
                if (originalResponse) {
                    dateService.handleDates(originalResponse.data);
                }
                return originalResponse;
            }
        );
    }

    private async get<T,>(endpoint: string, _?: unknown, useToken: boolean = true) {
        return axios.get<T>(`${this.BASE_URI}${endpoint}`, {
            headers: {
                ...this.headers,
                ...(useToken && {
                    "Authorization": `Bearer ${userAuthService.getToken()}`
                })
            },
            withCredentials: false
        });
    }

    private async post<T = unknown,>(endpoint: string, body?: unknown, useToken: boolean = true) {
        return axios.post<T>(
            `${this.BASE_URI}${endpoint}`,
            body
            , {
                headers: {
                    ...this.headers,
                    ...(useToken && {
                        "Authorization": `Bearer ${userAuthService.getToken()}`
                    })
                },
                withCredentials: false,
            });
    }

    public async call<T = unknown,>(endpoint: string, method: 'get' | 'post' = 'get', data?: unknown, useToken: boolean = true): Promise<T> {
        return await this[method]<T>(endpoint, data, useToken)
            .then((res) => Promise.resolve(res.data))
            .catch((err) => Promise.reject(ErrorService.handleApiError(err)));
    }
}

export default APIService;