import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDAppointment, IDAppointmentWithNutritionist } from "interfaces/Database";
import React from "react";
import { useTranslation } from "services";
import appointmentService from "services/appointment.service";
import doctorService from "services/doctor.service";
import "../scss/AllAppointments.scss";

const STATUS_BOOKED = "Booked";
const STATUS_DONE = "Done";

const AllAppointments: React.FC<({
    upcomingAppointments: (IDAppointment | IDAppointmentWithNutritionist)[]
    pastAppointments: (IDAppointment | IDAppointmentWithNutritionist)[]
})> = ({ upcomingAppointments, pastAppointments }) => {
    const t = useTranslation('dashboard/patient/appointment');

    const renderUpcomingAppointments = upcomingAppointments.map((appointment, index) =>
        (appointment.appointmentOn && (appointment.status.name === STATUS_BOOKED)) &&
        <div className="appointment-wrapper row" key={index}>
            <span className="MavenPro-black appointment-doctor-name">
                {doctorService.formatDoctorName(
                    'doctor' in appointment
                        ? appointment?.doctor?.doctorInfo
                        : appointment?.nutritionist?.doctorInfo
                )}
            </span>
            <span>
                {appointmentService.getFormattedDate(appointment.appointmentOn)}
            </span>
        </div>
    );

    const renderPastAppointments = pastAppointments.map((appointment, index) =>
        appointment.status.name === STATUS_DONE &&
        <div className="appointment-wrapper row" key={index}>
            <span className="MavenPro-black appointment-doctor-name">
                {doctorService.formatDoctorName(
                    'doctor' in appointment
                        ? appointment?.doctor?.doctorInfo
                        : appointment?.nutritionist?.doctorInfo
                )}
            </span>
            <span>{appointmentService.getFormattedDate(appointment.appointmentOn)}</span>
        </div>
    )

    return (
        <DefaultContainer
            title={
                <h1 className="container-title bold">
                    {t.translate('all-appointments.title')}
                </h1>
            }
            body={
                <div className="flex-row" style={{ margin: '15px 0' }}>
                    <div className="flex-column" id="past-appointments">
                        <p className="list-title">
                            {t.translate('all-appointments.past')}
                        </p>
                        <div className="full-width flex-row flex-wrap">
                            {renderPastAppointments}
                        </div>
                    </div>
                    <div className="flex-column" id="upcoming-appointments">
                        <p className="list-title">
                            {t.translate('all-appointments.next')}
                        </p>
                        <div className="full-width flex-row flex-wrap">
                            {renderUpcomingAppointments}
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default AllAppointments;