import { Button } from "components";
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { ProfilePicture } from 'components/common/ProfilePicture';
import ToolTip from 'components/common/ToolTip/ToolTip';
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { IDAppointment, IDAppointmentWithNutritionist } from "interfaces/Database";
import 'moment-timezone';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import appointmentService from 'services/appointment.service';
import dateService from 'services/date.service';
import doctorService from 'services/doctor.service';
import userAuthService from "services/user-auth.service";
import "../scss/NextAppointmentPreview.scss";

const NextAppointmentPreview: React.FC = () => {
    const history = useHistory();

    const t = useTranslation('dashboard/patient/home');
    const { subscriptionTrackingDetail, reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ nextAppointment, setNextAppointment ] = useState<IDAppointment | IDAppointmentWithNutritionist>();
    const [ isMeeting, setIsMeeting ] = useState<boolean>(false);

    const [ appointmentDate, setAppointmentDate ] = useState<Date>();
    const [ minDateStartAppointment, setMinDateStartAppointment ] = useState<Date>();
    const [ maxDateStartAppointment, setMaxDateStartAppointment ] = useState<Date>();

    const fetchData = useCallback(() => {
        appointmentService.getNextAppointment()
            .then((res) => {
                setNextAppointment(res);
                setIsMeeting(true);

                
                if (res?.appointmentOn) {
                    setMinDateStartAppointment(dateService.removeHours(res?.appointmentOn, 1));
                    setMaxDateStartAppointment(dateService.addHours(res?.appointmentOn, 1));
                } else {
                    setAppointmentDate(dateService.addDays(new Date(), 1));
                    setMinDateStartAppointment(dateService.addDays(dateService.removeHours(new Date(), 1), 1));
                    setMaxDateStartAppointment(dateService.addDays(dateService.addHours(new Date(), 1), 1));
                }
            })
            .catch((err) => console.warn(err));
    }, []);

    useEffect(() => {
        if (!subscriptionTrackingDetail) {
            reloadSubscriptionTrackingDetail()
        }

        fetchData();
    }, []);

    const handleVideoMeeting = () => {
        if (nextAppointment) {
            userAuthService.getOrCreateMeeting(nextAppointment.id)
                .then((res) => {
                    const patientId = nextAppointment?.subscriptionPlan?.patient?.id;
                    const roomId = res.roomUrl.split('/')[3];
                    
                    window.open(`${window.location.origin}/account/meeting/withdoctor/${patientId}/${roomId}`);
                    setIsMeeting(true);
                })
                .catch(() => setIsMeeting(false));
        }
    }

    return (
        <DefaultContainer
            title={
                <div className='flex-row'>
                    <ToolTip>
                        <Fragment>
                            <p>{t.translate('appointment-overview.tooltip.desc')}</p>
                            {nextAppointment && nextAppointment.appointmentOn
                                ? <Fragment>
                                    <p>{t.translateDate(minDateStartAppointment, { dayName: true, hours: true, minutes: true })}</p>
                                    <p>{t.translateDate(maxDateStartAppointment, { dayName: true, hours: true, minutes: true })}</p>
                                </Fragment>
                                : <Fragment>
                                    <p style={{ marginTop: '5px' }}>{t.translateReplaceValues('appointment-overview.tooltip.example', [
                                        {
                                            tag: '{{hour}}',
                                            value: t.translateDate(appointmentDate, { dayName: true, hours: true, minutes: true }).toLowerCase()
                                        }
                                    ])}</p>
                                    <p className="full-width text-align-center" style={{ margin: '10px 0' }}>
                                        {t.translateDate(minDateStartAppointment, { hours: true, minutes: true, hideDay: true, hideMonth: true, hideYear: true })}
                                        {' et '}
                                        {t.translateDate(maxDateStartAppointment, { hours: true, minutes: true, hideDay: true, hideMonth: true, hideYear: true })}
                                    </p>
                                </Fragment> 
                            }
                        </Fragment>
                    </ToolTip>
                    <div className="flex-col-start">
                        <h1 className="bold">{t.translate('appointment-overview.title')}</h1>
                        {(nextAppointment && 'nutritionist' in nextAppointment) && <i>{t.translate('appointment-overview.withNutritionist')}</i>}
                    </div>
                </div>
            }
            body={nextAppointment && nextAppointment.appointmentOn
                ? <div className="row" id="next-appointment-wrapper">
                    <div id="doctor-image-wrapper" className="background-gradient">
                        <ProfilePicture user={'doctor' in nextAppointment ? nextAppointment.doctor : nextAppointment.nutritionist} />
                    </div>
                    <div className="column">
                        <p className="uppercase MavenPro-black" id="doctor-name">
                            {doctorService.formatDoctorName(('doctor' in nextAppointment ? nextAppointment.doctor : nextAppointment.nutritionist).doctorInfo)}
                        </p>
                        <span id="appointment-date">
                            {appointmentService.getFormattedDate(nextAppointment?.appointmentOn)}
                        </span>
                    </div>
                </div>
                : <p className="no-data">
                    {t.translate('appointment-overview.no-appointment')}
                </p>
            }
            footer={nextAppointment && (nextAppointment.appointmentOn
                ? <Button
                    onClick={handleVideoMeeting}
                    label={t.translate('appointment-overview.button-label')}
                    disabled={
                        !isMeeting ||
                        (minDateStartAppointment && (minDateStartAppointment > new Date())) ||
                        (maxDateStartAppointment && (maxDateStartAppointment < new Date()))
                    }
                />
                : (subscriptionTrackingDetail?.parcelToLaboratory?.isDelivered() &&
                    ('doctor' in nextAppointment
                        ? subscriptionTrackingDetail?.doctorAppointment?.isNotBooked()
                        : subscriptionTrackingDetail?.appointmentWNutritionist?.isNotBooked()
                    )) &&
                        <Button
                            label={t.translate(
                                'doctor' in nextAppointment
                                    ? 'appointment-overview.button-schedule'
                                    : 'appointment-overview.button-scheduleNutritionist'
                            )}
                            onClick={() => history.push("/account/appointments")}
                        />)
            }
            hideSeparator={false}
        />
    )
}

export default NextAppointmentPreview;