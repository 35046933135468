import { Loader } from "components";
import Addresses from "components/KitRegister/components/Addresses";
import ConfirmPhone from "components/KitRegister/components/ConfirmPhone";
import Informations from "components/KitRegister/components/Informations";
import NoPlanAvailable from "components/KitRegister/components/NoPlanAvailable";
import PlanAlreadyActivated from "components/KitRegister/components/PlanAlreadyActivated";
import SolutionAttributed from "components/KitRegister/components/SolutionAttributed";
import SubmitCode from "components/KitRegister/components/SubmitCode";
import "components/KitRegister/scss/KitRegister.scss";
import { UserContext } from "context/User/UserContext";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import patientService from "services/patient.service";
import standaloneService from "services/standalone.service";
import NotFound from "./NotFound";

const RegisterKit: React.FC = () => {
    const { type } = useContext(UserTypeContext);
    const { refreshUser } = useContext(UserContext);
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ solutionAttributed, setSolutionAttributed ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const standaloneCheckSolutionStatus = useCallback(() => {
        setIsLoading(true);
        setError(undefined);

        standaloneService.getCurrentSolutionStatus()
            .then((res) => res === "subscription_attributed" ? setSolutionAttributed(true) : setSolutionAttributed(false))
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }, []);

    const patientCheckSolutionStatus = useCallback(() => {
        setIsLoading(true);
        setError(undefined);

        patientService.getCurrentSolutionStatus()
            .then((res) => res === "subscription_attributed" ? setSolutionAttributed(true) : setSolutionAttributed(false))
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }, []);

    const reloadWithNextStep = () => {
        if (type.isStandalone()) {
            standaloneCheckSolutionStatus();
        } else {
            patientCheckSolutionStatus();
        }
    }

    useEffect(() => {
        refreshUser()
            .then(() => reloadWithNextStep());
    }, []);

    const handleCurrentStep = () => {
        if (!error) {
            if (solutionAttributed) {
                return <SolutionAttributed />
            } else {
                return <SubmitCode isLoading={isLoading} setIsLoading={setIsLoading} error={error} setError={setError} />
            }
        } else {
            switch (error) {
                case "missing_informations":
                    return <Informations handleNextStep={reloadWithNextStep} />
                case "phone_missing":
                case "phone_not_verified":
                    return <ConfirmPhone handleNextStep={reloadWithNextStep} />
                case "missing_addresses":
                    return <Addresses handleNextStep={reloadWithNextStep} />
                case "no_available_plan":
                    return <NoPlanAvailable />
                case "user_already_active_plan":
                case "already_active_plan":
                case "kit_already_activated":
                    return <PlanAlreadyActivated />
                case "no_consent_form":
                    return history.push(`/account/kit/consent-form`);
                default:
                    return <NotFound />
            }
        }
    }

    return (
        <div id="kit-register" className="column">
            {isLoading
                ? <Loader />
                : handleCurrentStep()
            }
        </div>
    );
}

export default RegisterKit;