import { IDatabaseSubscriptionPlanTypeCategory } from "./IDatabaseSubscriptionPlanTypeCategory";

export type SolutionName = 'microbiota_analysis' | 'cancer_detection' | string;

export interface IDatabaseSubscriptionPlanType {
    category?: IDatabaseSubscriptionPlanTypeCategory
    description?: string
    id?: number
    name?: SolutionName
    price?: number
    standaloneAllowed?: boolean
    monthlyPayment?: boolean
}

export default class DatabaseSubscriptionPlanType implements IDatabaseSubscriptionPlanType {
    category?: IDatabaseSubscriptionPlanTypeCategory;
    description?: string;
    id?: number;
    name?: SolutionName;
    price?: number;
    standaloneAllowed?: boolean;
    monthlyPayment?: boolean;
  
    constructor(data: IDatabaseSubscriptionPlanType) {
        if (data.category) this.category = data.category;
        if (data.description) this.description = data.description;
        if (data.id) this.id = data.id;
        if (data.name) this.name = data.name;
        if (data.price) this.price = data.price;
        if (data.standaloneAllowed) this.standaloneAllowed = data.standaloneAllowed;
        if (data.monthlyPayment) this.monthlyPayment = data.monthlyPayment;
    }

    public isMicrobiota(): boolean {
        return this.name === 'microbiota_analysis';
    }

    public isCancer(): boolean {
        return this.name === 'cancer_detection';
    }

    public isMicrobiotaOnly(): boolean {
        return this.name === 'microbiota_only';
    }

    public isMicrobiotaStressBurnout(): boolean {
        return this.name === 'microbiota_stress_burnout';
    }

    public isStressBurnout(): boolean {
        return this.name === 'stress_burnout';
    }
}