import CompanyEmployees from "components/DashboardHome/components/Company/components/CompanyEmployees";
import CompanyKitContracts from "components/DashboardHome/components/Company/components/CompanyKitContracts";
import CompanyLastInvoices from "components/DashboardHome/components/Company/components/CompanyLastInvoices";
import CompanyNews from "components/DashboardHome/components/Company/components/CompanyNews";
import CompanyProfileComponent from "components/DashboardHome/components/Company/components/CompanyProfileComponent";
import CompanySolutionResume from "components/DashboardHome/components/Company/components/CompanySolutionResume";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import React, { Fragment } from "react";
import { useTranslation } from "services";

const CompanyDashboardHomePage: React.FC = () => {
    const t = useTranslation('dashboard/company/home');

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate('header.my')} title={t.translate('header.dashboard')} />
            <div>
                <CompanyProfileComponent />
                <CompanyEmployees />
                <CompanySolutionResume />
                <CompanyLastInvoices />
                <CompanyKitContracts />
                <CompanyNews />
            </div>
        </Fragment>
    )
}

export default CompanyDashboardHomePage;