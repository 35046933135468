import React from "react";
import "./scss/DashboardDefaultButton.scss";

const DashboardDefaultButton: React.FC<({
    libelle: string,
    handleClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    disabled?: boolean
})> = ({ 
    libelle,
    handleClick = undefined,
    disabled = false
}) => {
    return (
        <button
            className={"dashboardBtn"}
            onClick={handleClick}
            disabled={disabled}
        >
            <span>{libelle}</span>
        </button>
    );
}

export default DashboardDefaultButton;