import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/scss/main.scss';
import AuthLayoutHeader from "./Header/AuthLayoutHeader";
import "./scss/Layout.scss";


const Layout: React.FC<({
    children: React.ReactNode
})> = ({ children }) => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // const isValidUrlForAuthLayout = () => {
    //     const validUrls = new RegExp(/(login\b)|(register\b)|(account-recovery\b)|(reset\b)|(order\b)/gmi);
    
    //     return validUrls.test(window.location.href);
    // }

    return <div className="flex-column" style={{ flexGrow: 1 }}>
        <ToastContainer position="top-center" />
        <AuthLayoutHeader />
        {children}
    </div>

    // if (isValidUrlForAuthLayout()) {
    //     return <div className="flex-column" style={{ flexGrow: 1 }}>
    //         <AuthLayoutHeader />
    //         {children}
    //     </div>
    // }

    // return (
    //     <section id="layout" className="flex column">
    //         <ToastContainer position="top-center" />
    //         <Header />
    //         <main id="content">
    //             {children}
    //         </main>
    //         <Footer />
    //         <CookieConsent />
    //     </section>
    // );
}

export default Layout;