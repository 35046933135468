import cancer_detection from 'assets/home/cancer_detection.webp';
import microbiota_analysis from 'assets/home/microbiota_analysis.webp';
import { AddIcon, CheckCircleIcon, EditIcon, LeftArrowIcon, Loader } from "components";
import { OrderSolutionComplementary } from 'components/Modals';
import Address from "components/OrderKit/Address/Address";
import Identify from "components/OrderKit/Identify/Identify";
import OrderOptions from 'components/OrderKit/OrderOptions/OrderOptions';
import Payment from 'components/OrderKit/Payment/Payment';
import { SolutionsContext, SubscriptionPlanTypeWithImage } from 'context/SolutionsContext';
import { UserContext } from 'context/User/UserContext';
import { IDAddress, IDPlanType, IDPlatform, IDPrices, IDProductOrderOption, IDProductOrderType } from "interfaces/Database";
import PaymentType from "interfaces/Database/IDatabasePaymentType";
import NotFound from 'pages/NotFound';
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import addressService from "services/address.service";
import usePaymentTypeService from "services/api/paymentType.service";
import usePlatformService from "services/api/platform.service";
import useProductOrderTypeService from "services/api/productOrderType.service";
import companyService from 'services/company.service';
import subscriptionPlanTypeService from "services/subscriptionPlanType.service";
import userAuthService from "services/user-auth.service";
import './OrderSolution.scss';

export interface OrderSolutionProps {}

const OrderSolution: React.FC<OrderSolutionProps> = () => {
    const { solutionName } = useParams<({ solutionName: string })>();

    const t = useTranslation('pages/orderSolution');
    const history = useHistory();

    const { user, refreshUser, setUser } = useContext(UserContext);
    const { solutions } = useContext(SolutionsContext);

    const [ showComplementaryModal, setShowComplementaryModal ] = useState<boolean>(false);
    const toggleShowComplementaryModal = () => setShowComplementaryModal(!showComplementaryModal);

    const platformService = usePlatformService();
    const productOrderTypeService = useProductOrderTypeService();
    const paymentTypeService = usePaymentTypeService();

    const [ isLoadingComplementary, setIsLoadingComplementary ] = useState<boolean>(false);

    const [ isConnected, setIsConnected ] = useState<boolean>(false);
    const [ hasAddress, setHasAddress ] = useState<boolean>(false);
    const [ areOptions, setAreOptions ] = useState<boolean>(false);

    const [ isLoadingAuth, setIsLoadingAuth ] = useState<boolean>(false);

    const [ isConnecting, setIsConnecting ] = useState<boolean>(false);
    const toggleConnecting = () => setIsConnecting(!isConnecting);

    const [ isPaymentFormLoaded, setIsPaymentFormLoaded ] = useState<boolean>(false);
    const togglePaymentFormLoaded = () => setIsPaymentFormLoaded(!isPaymentFormLoaded);

    const [ baseOptions, setBaseOptions ] = useState<IDProductOrderOption[]>([]);
    const [ defaultOptions, setDefaultOptions ] = useState<IDProductOrderOption[]>([]);
    const [ options, setOptions ] = useState<IDProductOrderOption[]>([]);

    const [ billingAddress, setBillingAddress ] = useState<IDAddress>();
    const [ shippingAddress, setShippingAddress ] = useState<IDAddress>();
    const [ areBillingAndShippingSame, setAreBillingAndShippingSame ] = useState<boolean>(true);

    const [ platform, setPlatform ] = useState<IDPlatform>();
    const [ paymentTypes, setPaymentTypes ] = useState<PaymentType[]>([]);
    const [ planType, setPlanType ] = useState<IDPlanType>();
    const [ productOrderType, setProductOrderType ] = useState<IDProductOrderType>();
    const [ customPrice, setCustomPrice ] = useState<IDPrices>();

    const [ solution, setSolution ] = useState<SubscriptionPlanTypeWithImage>();

    const informationsDivRef = useRef<HTMLDivElement>(null);
    const connectionDivRef = useRef<HTMLDivElement>(null);
    const addressDivRef = useRef<HTMLDivElement>(null);
    const optionsDivRef = useRef<HTMLDivElement>(null);
    const paymentDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const finalSolutionName = solutions.filter((e) => e.name === solutionName)[0];
        setSolution(finalSolutionName);

        subscriptionPlanTypeService.getPlanTypeByName(solutionName)
            .then((res) => {
                if (res.availableProductOrderOptions.length > 0) {
                    setBaseOptions(res.availableProductOrderOptions);
                    setDefaultOptions(res.defaultProductOrderOptions);
                    setOptions(res.defaultProductOrderOptions);
                    setAreOptions(false);
                } else {
                    setBaseOptions([]);
                    setAreOptions(true);
                }
            })
            .catch(() => {
                setBaseOptions([]);
                setAreOptions(true);
            });
    }, [solutionName]);

    const loadData = async () => {
        if (isConnected === true || userAuthService.isUserConnected()) {
            setIsLoadingAuth(true);
            const result = await refreshUser();

            if (!result) {
                setIsConnected(false);
                userAuthService.logoutWORedirect();
                return;
            } else {
                setIsConnected(true);
            }

            setIsLoadingAuth(false);

            setIsLoadingComplementary(true);

            Promise.all([
                addressService.getAddresses()
                    .then((res) => {
                        setShippingAddress(res.shipping);
                        setBillingAddress(res.billing);

                        if (!addressService.compareAddresses(res.billing, res.shipping)) setAreBillingAndShippingSame(false);
                    }),
                platformService.getWebPlatform(),
                productOrderTypeService.getPlanType(),
                paymentTypeService.getAllPaymentTypes(),
                subscriptionPlanTypeService.getPlanTypeByName(solutionName),
                subscriptionPlanTypeService.getPlanTypeCustomPriceByName(solutionName),
            ])
                .then((res) => {
                    setPlatform(res[1]);
                    setProductOrderType(res[2]);
                    setPaymentTypes(res[3]);
                    setPlanType(res[4]);
                    setCustomPrice(res[5])
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoadingComplementary(false));
        } else {
            setIsConnected(false);
            setUser(undefined);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (isConnecting && !isConnected) {
            informationsDivRef.current?.scrollTo({
                top: (connectionDivRef.current?.offsetTop || 0) - 80,
                behavior: 'smooth'
            });
        } else if (isConnected && !hasAddress) {
            informationsDivRef.current?.scrollTo({
                top: (addressDivRef.current?.offsetTop || 0) - 80,
                behavior: 'smooth'
            });
        } else if (isConnected && hasAddress && !areOptions) {
            setTimeout(() => informationsDivRef.current?.scrollTo({
                top: (optionsDivRef.current?.offsetTop || 0) - 80,
                behavior: 'smooth'
            }), 1000);
        } else if (isConnected && hasAddress && areOptions && isPaymentFormLoaded) {
            setTimeout(() => informationsDivRef.current?.scrollTo({
                top: (paymentDivRef.current?.offsetTop || 0) - 80,
                behavior: 'smooth'
            }), 1000);
        }
    }, [ isConnected, hasAddress, isPaymentFormLoaded, isConnecting, areOptions ]);

    const handleGoBack = () => {
        history.push('/order');
    }

    const handleKitImg = () => {
        switch (solutionName) {
            case 'cancer_detection':
                return cancer_detection;

            default:
                return microbiota_analysis;
        }
    }

    const handleChangeAccount = () => {
        userAuthService.logoutWORedirect();
        setHasAddress(false);
        setBillingAddress(undefined);
        setShippingAddress(undefined);
        setIsConnected(false);
    }

    const handleEditAddress = () => {
        setHasAddress(false);
    }

    const handleValidateAddress = async () => {
        setHasAddress(true);
        Promise.all([
            addressService.getAddresses()
                .then((res) => {
                    setShippingAddress(res.shipping);
                    setBillingAddress(res.billing);

                    if (!addressService.compareAddresses(res.billing, res.shipping)) setAreBillingAndShippingSame(false);
                }),
            companyService.createOrGetStripeCustomer()
        ]);
    }

    const handleEditOptions = () => {
        setAreOptions(false);
    }

    const handleValidateOptions = () => {
        setAreOptions(true);
    }

    const handleOpenComplementary = () => {
        toggleShowComplementaryModal();
    }

    if (!solution) {
        return (
            <main id='order-solution' className='flex-column'>
                <NotFound />
            </main>
        )
    }
    
    return (
        <main id='order-solution' className='flex-column'>
            <div id='order-solution-page'>
                <div className='left-section'>
                    <div className='order-gallery-wrapper'>
                        <img className='kit_image' src={handleKitImg()} alt={`${t.translateSubscriptionType(solution.name, 'name')} kit`} />
                    </div>
                    <div className='left-section_detail' onClick={handleOpenComplementary}>
                        <h3>{t.translate('complementary.title')}</h3>
                        <AddIcon />
                    </div>
                </div>
                <div className='order-informations-wrapper' ref={informationsDivRef}>
                    <div className='flex-row full-width' style={{ position: 'relative' }}>
                        <LeftArrowIcon onClick={handleGoBack} className='clickable' style={{ position: 'absolute', left: 0 }} />
                        <h1 className='text-align-center'>{t.translateSubscriptionType(solution.name, 'short_name')}</h1>
                    </div>
                    {solution.price && <div className='flex-row full-width'>
                        <h4 className='text-main-color MavenPro' style={{ margin: 0, fontSize: '24px' }}>{t.translateSubscriptionPrice(solution.name)}</h4>
                    </div>}
                    <div className='order-informations-wrapper-step' ref={connectionDivRef}>
                        <div className='order-informations-wrapper-step-title'>
                            {isConnected
                                ? <CheckCircleIcon size={32} />
                                : <p>1.</p>
                            }
                            <div className='flex-column-start'>
                                <h2 style={isConnected ? { marginBottom: 0 } : {}}>{t.translate('steps.login.title')}</h2>
                                {isConnected
                                    && <p style={{ marginTop: 0 }}>
                                    {t.translateReplaceValues(
                                        'steps.login.as',
                                        [
                                            {
                                                tag: '{{identity}}',
                                                value: user?.formatName() ?? ''
                                            }
                                        ]
                                    )}
                                    </p>
                                }
                            </div>
                            {isConnected
                                && <EditIcon className='clickable' onClick={handleChangeAccount} style={{ position: 'absolute', right: '0' }} />
                            }
                        </div>
                        {!isConnected &&
                            (!isLoadingAuth
                                ? <Identify refresh={loadData} toggleConnecting={toggleConnecting} />
                                : <Loader />)}
                    </div>
                    <div className='order-informations-wrapper-step' ref={addressDivRef}>
                        <div className='order-informations-wrapper-step-title'>
                            {hasAddress
                                ? <CheckCircleIcon size={32} />
                                : <p>2.</p>
                            }
                            <div>
                                <h2 style={hasAddress ? { marginBottom: 0 } : {}}>{t.translate('steps.address.title')}</h2>
                                {hasAddress
                                    && <p style={{ marginTop: 0 }}>
                                    {t.translateReplaceValues(
                                        'steps.address.using',
                                        [
                                            {
                                                tag: '{{address}}',
                                                value: shippingAddress?.streetAddress
                                            }
                                        ]
                                    )}
                                    </p>
                                }
                            </div>
                            {isLoadingComplementary && <Loader size={16} spacing={{ left: '12px' }} />}
                            {hasAddress
                                && <EditIcon className='clickable' onClick={handleEditAddress} style={{ position: 'absolute', right: '0' }} />
                            }
                        </div>
                        {(isConnected && !hasAddress)
                            && <Address
                                billingAddress={billingAddress}
                                shippingAddress={shippingAddress}
                                areSame={areBillingAndShippingSame}
                                handleNextStep={handleValidateAddress}
                            />
                        }
                    </div>
                    {baseOptions?.length > 0
                        && <div className='order-informations-wrapper-step' ref={optionsDivRef}>
                            <div className='order-informations-wrapper-step-title'>
                                {areOptions
                                    ? <CheckCircleIcon size={32} />
                                    : <p>3.</p>
                                }
                                <div>
                                    <h2 style={areOptions ? { marginTop: 0, marginBottom: 0 } : {}}>{t.translate('steps.options.title')}</h2>
                                </div>
                                {isLoadingComplementary && <Loader size={16} spacing={{ left: '12px' }} />}
                                {areOptions
                                    && <EditIcon className='clickable' onClick={handleEditOptions} style={{ position: 'absolute', right: '0' }} />
                                }
                            </div>
                            {(hasAddress && !areOptions)
                                && <OrderOptions
                                    baseOptions={baseOptions}
                                    defaultOptions={defaultOptions}
                                    options={options}
                                    setOptions={setOptions}
                                    handleNextStep={handleValidateOptions}
                                />
                            }
                        </div>
                    }
                    <div className='order-informations-wrapper-step' ref={paymentDivRef} style={{ marginBottom: '12vh' }}>
                        <div className='order-informations-wrapper-step-title'>
                            <p>{baseOptions.length > 0 ? '4' : '3'}.</p>
                            <div>
                                <h2>{t.translate('steps.payment.title')}</h2>
                            </div>
                        </div>
                        {(
                            hasAddress &&
                            planType &&
                            platform &&
                            productOrderType &&
                            billingAddress &&
                            areOptions
                        ) &&
                            <Payment
                                customPrice={customPrice}
                                payment_types={paymentTypes}
                                plan_type={planType}
                                platform={platform}
                                product_order_type={productOrderType}
                                renew={false}
                                billingAddress={billingAddress}
                                options={options}
                                togglePaymentFormLoaded={togglePaymentFormLoaded}
                                togglePreviousStep={() => setHasAddress(false)}
                            />
                        }
                    </div>
                </div>
            </div>
            <OrderSolutionComplementary
                isShow={showComplementaryModal}
                toggleShow={toggleShowComplementaryModal}
                solutionName={solutionName}
            />
        </main>
    )
}

export default OrderSolution;