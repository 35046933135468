import { UserContext } from 'context/User/UserContext';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'services';
import doctorService from 'services/doctor.service';
import EditSignatureModal from './EditSignatureModal';
import { EditIcon } from './Icons';
import DefaultContainer from './common/DefaultContainer/DefaultContainer';

const DoctorSignature: React.FC = () => {
    const t = useTranslation('components/signature');
    const { user, refreshUser } = useContext(UserContext);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ signature, setSignature ] = useState<string>();
    const toggleShowModal = () => setShowModal(!showModal);

    useEffect(() => {
        const loadSignature = () => {
            doctorService.getSignature()
                .then((res) => setSignature(res))
                .catch((err) => console.warn(err));
        }

        if (user?.doctorInfo?.signature) {
            loadSignature();
        }
    }, [ user ]);

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <div className='flex-column-start'>
                        <h2 className='container-title bold'>{t.translate('title')}</h2>
                        <p>{t.translate('desc')}</p>
                    </div>
                    <EditIcon onClick={() => toggleShowModal()} className='clickable' />
                </Fragment>
            }
            body={
                <Fragment>
                    {signature
                        ? <img src={signature} alt='doctor signature' />
                        : <p className='no-data'>{t.translate('none')}</p>
                    }
                    <EditSignatureModal
                        isShow={showModal}
                        toggleShow={toggleShowModal}
                        handleSuccess={refreshUser}
                    />
                </Fragment>
            }
        />
    )
}

export default DoctorSignature;