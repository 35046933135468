import { AddressInput, Input, Label, Select } from "components";
import { CountriesContext, PRIOR_COUNTRIES } from "context/CountriesContext";
import { LanguageContext } from "context/Language/LanguageContext";
import { IAddress } from "interfaces/User/IAddress";
import React, { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "services";
import verifierService from "services/verifier.service";

const EditAddressForm: React.FC<({
    isLoading: boolean,
    address?: IAddress,
    setAddress: React.Dispatch<React.SetStateAction<IAddress>>
})> = ({
    isLoading,
    address,
    setAddress
}) => {
    const t = useTranslation('various/address');
    const { language } = useContext(LanguageContext);
    const { countries, reloadCountries } = useContext(CountriesContext);

    useEffect(() => {
        if (!countries || countries.length === 0) {
            reloadCountries();
        }
    }, [ countries, language ]);

    function handleAddressChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        setAddress(address => ({
            ...address,
            [e.target.name]:
                e.target.type === "number" ?
                    parseInt(e.target.value) :
                    e.target.value
        }));
    }

    return (
        <div className="flex-column" style={{ rowGap: '1vh' }}>
            <div className="flex-row full-width">
                <div className="column input-container full-width">
                    <Label
                        required
                        for="streetAddress"
                        label={t.translate('address')}
                        labelStyle={{
                            color: 'black',
                        }}
                    />
                    <AddressInput
                        required
                        disabled={isLoading}
                        name="streetAddress"
                        address={address}
                        setAddress={setAddress}
                        value={address?.streetAddress}
                        setValue={handleAddressChange}
                        checkFunction={() => verifierService.checkString(address?.streetAddress)}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
            </div>
            <div className="flex-row flex-wrap full-width" style={{ columnGap: '1vw' }}>
                <div className="flex-column-start" style={{ width: '45%', minWidth: '180px', flexGrow: 1 }}>
                    <Label
                        required
                        for="postalCode"
                        label={t.translate('postalCode')}
                        labelStyle={{
                            color: 'black',
                        }}
                    />
                    <Input
                        required
                        disabled={isLoading}
                        name="postalCode"
                        value={address?.postalCode}
                        setValue={handleAddressChange}
                        checkFunction={() => verifierService.checkString(address?.postalCode)}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
                <div className="flex-column-start" style={{ width: '45%', minWidth: '180px', flexGrow: 1 }}>
                    <Label
                        required
                        for="city"
                        label={t.translate('city')}
                        labelStyle={{
                            color: 'black',
                        }}
                    />
                    <Input
                        disabled={isLoading}
                        name="city"
                        required
                        value={address?.city}
                        setValue={handleAddressChange}
                        checkFunction={() => verifierService.checkString(address?.city)}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
            </div>
            <div className="flex-row flex-wrap full-width" style={{ columnGap: '1vw' }}>
                <div className="flex-column-start" style={{ width: '45%', minWidth: '180px', flexGrow: 1 }}>
                    <Label
                        for="aptNumber"
                        label={t.translate('appartment')}
                        labelStyle={{
                            color: 'black',
                        }}
                    />
                    <Input
                        disabled={isLoading}
                        name="aptNumber"
                        value={address?.aptNumber}
                        setValue={handleAddressChange}
                    />
                </div>
                <div className="flex-column-start" style={{ width: '45%', minWidth: '180px', flexGrow: 1 }}>
                    <Label
                        required
                        for="countryName"
                        label={t.translate('country')}
                        labelStyle={{
                            color: 'black',
                        }}
                    />
                    <Select
                        required
                        name="countryName"
                        defaultValue="none"
                        value={address?.countryName}
                        setValue={(e) => handleAddressChange(e)}
                        options={
                            <Fragment>
                                <option value={""} disabled>{t.translate('chooseCountry')}</option>
                                {countries
                                    .sort((a, b) => {
                                        if (PRIOR_COUNTRIES.includes(a.name)) {
                                            return -1;
                                        } else {
                                            return t.translateCountry(a.name) > t.translateCountry(b.name)
                                                ? 1
                                                : -1;
                                        }
                                    }).map((country, index) =>
                                    <option className='dashboardSelect-option MavenPro' key={index} value={country.name}>{t.translateCountry(country.name)}</option>
                                )}
                            </Fragment>
                        }
                    />
                </div>
            </div>
            <div className="flex-row flex-wrap full-width space-between">
                <div className="column input-container" style={{ width: "100%" }}>
                    <Label
                        for="additionalInfos"
                        label={t.translate('additionalInformations')}
                        labelStyle={{
                            color: 'black',
                        }}
                    />
                    <Input
                        disabled={isLoading}
                        name="additionalInfos"
                        value={address?.additionalInfos}
                        setValue={handleAddressChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default EditAddressForm;