import React from 'react';

import './scss/ChartCaption.scss';
import { IChartData } from "interfaces/Services/ChartService/IChartData";
import { chartCalculatePercentage } from 'services/chart.service';
import translationService from 'services/translation.service';

export interface ChartCaptionParams {
    showCaption?: boolean,
    captionPosition?: 'bottom' | 'right',
    showTicks?: boolean,
    hideColor?: boolean,
    hidePercentage?: boolean,
    hideLabel?: boolean,
    hideValue?: boolean,
    translateLabel?: boolean
}

const ChartCaption: React.FC<({
    data: IChartData[],
    params?: ChartCaptionParams,
    sectionHovered?: IChartData,
    setSectionHovered?: React.Dispatch<React.SetStateAction<IChartData | undefined>>
})> = ({
    data,
    params,
    sectionHovered,
    setSectionHovered
}) => {
    return params?.showCaption ? (
        params?.captionPosition === "bottom" ? (
            <div className="chart-caption-wrapper-horizontal">
                {data.map((chartData, i) =>
                    <div
                        key={`chart-part-${i}`}
                        className={`flex-row-start full-width ${sectionHovered === chartData ? 'chart-caption-wrapper-item-hovered' : 'chart-caption-wrapper-item'}`}
                        style={{
                            backgroundColor: chartData.color,
                            margin: '5px 0',
                            padding: '5px 10px',
                            borderRadius: '30px',
                            color: 'white'
                        }}
                        onMouseEnter={() => setSectionHovered ? setSectionHovered(chartData) : null}
                        onMouseLeave={() => setSectionHovered ? setSectionHovered(undefined) : null}
                    >
                        <p style={{ fontSize: '14px' }}>
                            {(!params?.hideLabel && chartData?.label) ?
                                (params?.showTicks ?
                                    `"${params?.translateLabel ? translationService.translate(chartData.label) : chartData.label}"` :
                                    `${params?.translateLabel ? translationService.translate(chartData.label) : chartData.label}`
                                ) :
                                ''}
                            {params?.hidePercentage ?? ` ${chartCalculatePercentage(chartData, data)}%`}
                            {params?.hideValue ?? ` (${chartData.value})`}
                        </p>
                    </div>
                )}
            </div>
        ) : (
            <div className="flex-column" style={{ justifyContent: 'center' }}>
                {data.map((chartData, i) =>
                    <div
                        key={`chart-part-${i}`}
                        className={`flex-row-start full-width ${sectionHovered === chartData ? 'chart-caption-wrapper-item-hovered' : 'chart-caption-wrapper-item'}`}
                        style={{
                            backgroundColor: chartData.color,
                            margin: '5px 0',
                            padding: '5px 10px',
                            borderRadius: '30px',
                            color: 'white'
                        }}
                        onMouseEnter={() => setSectionHovered ? setSectionHovered(chartData) : null}
                        onMouseLeave={() => setSectionHovered ? setSectionHovered(undefined) : null}
                    >
                        <p style={{ fontSize: '14px' }}>
                            {(!params?.hideLabel && chartData?.label) ?
                                (params?.showTicks ?
                                    `"${params?.translateLabel ? translationService.translate(chartData.label) : chartData.label}"` :
                                    `${params?.translateLabel ? translationService.translate(chartData.label) : chartData.label}`
                                ) :
                                ''}
                            {params?.hidePercentage ?? ` ${chartCalculatePercentage(chartData, data)}%`}
                            {params?.hideValue ?? ` (${chartData.value})`}
                        </p>
                    </div>
                )}
            </div>
        )
    ) : null;
}

export default ChartCaption;