import ErrorService from "./Error.service";
import apiService from "./api.service";

class PatientService {
    private ROOT_ROUTE = '/api/patient';

    public canRegisterKit = async () => {
        return await apiService.get(this.ROOT_ROUTE + `/canRegisterKit`)
            .then((res) => res)
            .catch((err) => ErrorService.handleApiError(err));
    }

    public getDoctor = async () => {
        return await apiService.makeApiCall(this.ROOT_ROUTE + `/doctor`, 'get');
    }

    public getNutritionist = async () => {
        return await apiService.makeApiCall(this.ROOT_ROUTE + `/nutritionist`, 'get');
    }

    public async updateInformations(data?: {
        firstName?: string,
        lastName?: string,
        birthDate?: string,
        gender?: string,
        weight?: number,
        size?: number,
        waistSize?: number,
        specialMedication?: string,
        specialInformation?: string,
        socialSecurityNumber?: string
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE + '/informations', 'put', data);
    }

    public async updateContactInformations(data?: {
        email?: string,
        countryCode?: string,
        phoneNumber?: string
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE + '/contact', 'put', data);
    }

    public async getCurrentSolutionStatus() {
        return await apiService.makeApiCall(this.ROOT_ROUTE + '/getCurrentSolutionStatus', 'get');
    }
}

export default new PatientService();