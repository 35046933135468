import { IDatabasePreventiveAdvice } from "interfaces/Database/IDatabasePreventiveAdvice";
import apiService from "./api.service";

class PreventiveAdviceService {
    private ROOT_ROUTE = "/api/preventiveAdvice";

    public async getLast() {
        return await apiService.makeApiCall<IDatabasePreventiveAdvice>(this.ROOT_ROUTE+'/last', 'get');
    }

    public async getPreventiveAdvicePDF(preventiveAdviceId: number) {
        return await apiService.makeApiCall<string>(this.ROOT_ROUTE+`/${preventiveAdviceId}/pdf`, 'get');
    }

    public async createOrUpdate(data: {
        appointmentId: string
        preventiveAdvice: string
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/createOrUpdate', 'post', data);
    }
}

export default new PreventiveAdviceService();