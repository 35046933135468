import backgroundLogo from "assets/dashboard/dashboard-logo-background.png";
import { Loader } from "components";
import { MenuStatusContext } from "context/MenuStatusContext";
import { UserContext } from "context/User/UserContext";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/scss/main.scss';
import userAuthService from "services/user-auth.service";
import Sidebar from './Sidebar/Sidebar';
import "./scss/PrivateLayout.scss";

const PrivateLayout: React.FC<({
    children: React.ReactNode
})> = ({
    children
}) => {
    const history = useHistory();

    const { menuStatus, setMenuStatus } = useContext(MenuStatusContext);
    const { type, refreshType } = useContext(UserTypeContext);
    const { user, setUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const loadDatas = useCallback(async () => {
        setIsLoading(true);

        if (!type.isDefined()) {
            await refreshType()
                .catch(() => userAuthService.logout(history, '/login', true));
        }

        Promise.all([
            userAuthService.getUser()
                .then((res) => setUser(res)),
            userAuthService.getMenuStatus()
                .then((res) => setMenuStatus(res))
        ])
            .catch((err) => err.message === 'invalid_token' ? userAuthService.logout(history, '/login', true) : console.warn(err))
            .finally(() => setIsLoading(false));
    }, [history, type, refreshType, setMenuStatus, setUser]);

    useEffect(() => {
        loadDatas();
    }, [loadDatas]);

    return (
        <div id="private-layout">
            <ToastContainer position="top-center" />
            {isLoading
                ? <Loader />
                : <Fragment>
                    <Sidebar menuStatus={menuStatus} user={user} />
                    <section id="layout-right">
                        <img src={backgroundLogo} alt="background logo" id="dashboard-background-logo" />
                        <main id="content">
                            {children}
                        </main>
                    </section> 
                </Fragment>
            }
        </div>
    )
}

export default PrivateLayout;