import { Loader } from "components";
import AllMyAppointments from "components/common/Dashboard/Home/Doctor/AllMyAppointments";
import NextAppointment from "components/common/Dashboard/Home/Doctor/NextAppointment";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import { IDAppointment, IDAppointmentWithNutritionist } from "interfaces/Database";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import doctorService from "services/doctor.service";
import translationService from "services/translation.service";

const DoctorAppointment: React.FC = () => {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ nextAppointment, setNextAppointment ] = useState<IDAppointment | IDAppointmentWithNutritionist>();
    const [ allNextAppointments, setAllNextAppointments ] = useState<(IDAppointment | IDAppointmentWithNutritionist)[]>([]);
    const [ isMeeting, setIsMeeting ] = useState<boolean>(false);

    const fetchAppointments = useCallback(() => {
        setIsLoading(true);

        doctorService.getNextAppointments()
            .then((res) => {
                if (res && res.length > 0) {
                    setNextAppointment(res[0]);
                    setIsMeeting(true);
                } else {
                    setNextAppointment(undefined);
                }
                setAllNextAppointments(res ?? []);
            })
            .catch(() => {
                setNextAppointment(undefined);
                setAllNextAppointments([]);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchAppointments();
    }, []);

    return (
        <Fragment>
            <DashboardHeader preTitle={translationService.translate("appointments.pre")} title={translationService.translate("appointments.title")} />
            {isLoading
                ? <Loader />
                : <Fragment>
                    <NextAppointment
                        nextAppointment={nextAppointment}
                        isMeeting={isMeeting}
                        reloadNextAppointment={fetchAppointments}
                    />
                    <AllMyAppointments
                        appointments={allNextAppointments}
                    />
                </Fragment>
            }
        </Fragment>
    )
}

export default DoctorAppointment;