import { CheckIcon, CloseIcon, EyeIcon, EyeSlashIcon } from "components";
import { IFormDashboardInput } from "interfaces/Form/IFormDashboardInput";
import React, { CSSProperties, Fragment, useState } from "react";

const DashboardInput: React.FC<(IFormDashboardInput)> = ({
    placeholder,
    name,
    readOnly,
    disabled = false,
    required = false,
    backgroundColor = "transparent",
    min,
    max,
    type = "text",
    value,
    setValue,
    checkFunction,
    checkmark = {
        displayed: false
    },
    wrapperStyle,
    inputStyle
}) => {
    const [ forceShow, setForceShow ] = useState<boolean>(false);
    const toggleForceShow = () => setForceShow(!forceShow);

    const invalidDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'textfield',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '12px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const validDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'textfield',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '12px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const checkmarkDefaultStyle: CSSProperties = {
        position: 'absolute',
        top: 0,
        right: '10px',
        marginTop: '12px'
    }

    const checkInvalid = () => {
        if (checkFunction && !checkFunction()) {
            return true;
        } else {
            return false;
        }
    }

    const shouldDisplay = (): boolean => {
        if (required && !value) return true;

        if (!required && value) return false;

        if (!value) {
            return false;
        } else {
            if (type === 'number') {
                if (value === 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (value.length === 0) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }

    const calculateStyle = (): CSSProperties => {
        let style: CSSProperties = {};
        
        if (shouldDisplay()) {
            if (checkInvalid()) {
                style = invalidDashboardInput;
            } else {
                style = validDashboardInput;
            }

            style.paddingRight = '34px';
        } else {
            style = Object.assign(validDashboardInput);
        }

        if (type === 'password') {
            style.paddingLeft = '28px';
        }

        return style;
    }

    return (
        <Fragment>
            <div style={{ position: 'relative', width: "100%", ...wrapperStyle }}>
                {type === 'password'
                    && (forceShow
                        ? <EyeIcon
                            size={16}
                            onClick={() => toggleForceShow()}
                            className="clickable"
                            style={{
                                zIndex: 1,
                                position: 'absolute',
                                top: '50%',
                                transform: 'translate(60%, -50%)'
                            }}
                        />
                        : <EyeSlashIcon
                            size={16}
                            onClick={() => toggleForceShow()}
                            className="clickable"
                            style={{
                                zIndex: 1,
                                position: 'absolute',
                                top: '50%',
                                transform: 'translate(60%, -50%)'
                            }}
                        />
                    )
                }
                <input
                    // id={name}
                    name={name}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required}
                    placeholder={placeholder}
                    type={
                        type === 'password'
                            ? forceShow
                                ? 'text'
                                : type
                            : type
                    }
                    min={min}
                    max={max}
                    value={value}
                    onChange={setValue ? setValue : () => undefined}
                    className={
                        shouldDisplay() && checkInvalid() ?
                            'dashboardInput-invalid' :
                            'dashboardInput'
                    }
                    style={{
                        ...calculateStyle(),
                        ...inputStyle
                    }}
                />
                {
                    (shouldDisplay() && (!disabled && checkmark.displayed)) && (
                        checkInvalid() ?
                        (
                            checkmark.invalid?.component ?
                                <checkmark.invalid.component
                                    color={checkmark.invalid.color ? checkmark.invalid.color : 'var(--red)'}
                                    className={checkmark.invalid.className ? checkmark.invalid.className : ''}
                                    style={checkmarkDefaultStyle} /> :
                                <CloseIcon
                                    size={16}
                                    color={`var(--red)`}
                                    style={checkmarkDefaultStyle} />
                        ) :
                        (
                            checkmark.valid?.component ?
                                <checkmark.valid.component
                                    color={checkmark.valid.color ? checkmark.valid.color : 'var(--dashboard-main-color)'}
                                    className={checkmark.valid.className ? checkmark.valid.className : ''}
                                    style={checkmarkDefaultStyle} /> :
                                <CheckIcon
                                    size={16}
                                    style={checkmarkDefaultStyle} />
                        )
                    )
                }
            </div>
        </Fragment>
    )
}

export default DashboardInput;