import CompanyProfileComponent from "components/DashboardHome/components/Company/components/CompanyProfileComponent";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DashboardUserParameters from "components/common/DashboardUserParameters/DashboardUserParameters";
import React, { Fragment } from 'react';
import { useTranslation } from 'services';
import CompanyAdditionalInfos from "./components/CompanyAdditionalInfos";
import CompanyDetail from "./components/CompanyDetail";
import CompanyDetailContacts from "./components/CompanyDetailContacts";

const CompanyProfile: React.FC = () => {
    const t = useTranslation('dashboard/company/profile');

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate('header.pre')} title={t.translate('header.title')} />
            <div className="flex-column full-width">
                <CompanyProfileComponent />
                <CompanyDetail />
                <CompanyDetailContacts />
                <DashboardUserParameters />
                <CompanyAdditionalInfos />
            </div>
        </Fragment>
    )
}

export default CompanyProfile;