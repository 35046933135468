import { Chip, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Box } from "@mui/system";
import React from 'react';

// eslint-disable-next-line
interface IDashboardMultipleSelect<T = any> {
    name: string,
    baseItems: T[],
    selectedItems: T[],
    setSelectedItems: React.Dispatch<React.SetStateAction<T[]>>
    comparisonKey?: string,
    displayKey?: string,
    transformer?: (text: string) => string
}

const DashboardMultipleSelect: React.FC<IDashboardMultipleSelect> = ({
    name,
    baseItems,
    selectedItems,
    setSelectedItems,
    comparisonKey = 'id',
    displayKey = 'id',
    transformer
}) => {
    return (
        <Select
            className="dashboardSelect-multiple"
            id={name}
            name={name}
            multiple
            value={selectedItems}
            onChange={(event) => {
                const {
                    target: { value }
                } = event;

                const itemToFind = value[value.length - 1] as unknown as number;
                const foundItem = baseItems.filter((diet) => diet[comparisonKey] === itemToFind)[0];
                let newList = value.slice(0, -1) as (typeof baseItems[0])[];

                if (newList.filter((diet) => diet[comparisonKey] === foundItem[comparisonKey])[0]) {
                    newList = newList.filter((diet) => diet[comparisonKey] !== foundItem[comparisonKey]);
                } else {
                    newList.push(foundItem);
                }

                setSelectedItems(newList);
            }}
            input={
                <OutlinedInput id="select-multiple-chip" />
            }
            renderValue={(selected) => (
                <Box
                    sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                    style={selectedItems.length > 2
                        ? {
                            transform: 'translateY(17px)'
                        }
                        : {}
                    }
                >
                    {selected.map((item, index) =>
                        <Chip
                            key={`choice-list${name ? `-${name}` : ''}-${index}`}
                            label={transformer ? transformer(item[displayKey]) : item[displayKey]}
                        />
                    )}
                </Box>
            )}
        >
            {baseItems.map((item, index) =>
                <MenuItem
                    key={`choosed-list${name ? `-${name}` : ''}-${index}`}
                    value={item[comparisonKey]}
                    style={
                        selectedItems.filter((e) => e[comparisonKey] === item[comparisonKey]).length > 0
                            ? {
                                backgroundColor: 'var(--dashboard-main-color)',
                                color: 'white'
                            }
                            : {
                                backgroundColor: 'white',
                                color: 'black'
                            }
                    }
                >
                    {transformer ? transformer(item[displayKey]) : item[displayKey]}
                </MenuItem>
            )}
        </Select>
    )
}

export default DashboardMultipleSelect;