type IDatabasePaymentTypeName = 'one time' | 'monthly';

export interface IDatabasePaymentType {
    id: number,
    name: IDatabasePaymentTypeName
}

export default class PaymentType implements IDatabasePaymentType {
    id: number;
    name: IDatabasePaymentTypeName;

    constructor(id: number, name: IDatabasePaymentTypeName) {
        this.id = id;
        this.name = name;
    }

    public isOneTime(): boolean {
        return this.name === 'one time';
    }

    public isMonthly(): boolean {
        return this.name === 'monthly';
    }
}