import logo from 'assets/logos/bcu_full.svg';
import { Button, Loader } from 'components';
import Question from "components/Questionnaire/components/Question";
import "components/Questionnaire/scss/Question.scss";
import "components/Questionnaire/scss/Questionnaire.scss";
import questions from "components/common/Survey/SurveyQuestions";
import useWindowDimensions from 'hooks/useWindowDimensions';
import { IDSurveyAnswer } from 'interfaces/Database';
import { Line } from 'rc-progress';
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import surveyService from "services/survey.service";

const Questionnaire: React.FC = () => {
    const t = useTranslation('pages/questionnaire');

    const history = useHistory();
    const { width } = useWindowDimensions();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ currentIndexQuestion, setCurrentIndexQuestion ] = useState<number>(1);
    const [ isAnswerValid, setIsAnswerValid ] = useState<boolean>(true);
    const [ questionnaireEnded, setQuestionnaireEnded ] = useState<boolean>(false);
    const [ userCurrentAnswer, setUserCurrentAnswer ] = useState<(string|number)[]>([]);
    const [ previousAnswers, setPreviousAnswers ] = useState<IDSurveyAnswer[]>();

    useEffect(() => {
        const init = async () => {
            setIsLoading(true);
            surveyService.getOrCreateSurvey()
                .then((res) => setCurrentIndexQuestion(res.currentStep))
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }
        init();
    }, []);

    useEffect(() => {
        const handleEndQuestionnaire = () => {
            if (!questionnaireEnded) {
                setQuestionnaireEnded(currentIndexQuestion === questions.length);
            }
        };

        handleEndQuestionnaire();
    }, [questionnaireEnded, currentIndexQuestion]);

    useEffect(() => {
        setIsLoading(true);
        
        const handleGetResponse = () => {
            surveyService.getQuestionUserAnswers(questions[currentIndexQuestion-1].code)
                .then((res) => setPreviousAnswers(res))
                .catch(() => setPreviousAnswers(undefined))
                .finally(() => setIsLoading(false));
        }
        
        setTimeout(handleGetResponse, 500);
    }, [currentIndexQuestion]);

    function goNextStep() {
        setCurrentIndexQuestion(currentIndexQuestion + 1);
    }

    function handlePrevious() {
        if (currentIndexQuestion > 1) {
            setCurrentIndexQuestion(currentIndexQuestion - 1);
        }
    }

    function goToDashboard() {
        history.push("/account/home");
    }

    function getPercentProgressBar() {
        return (currentIndexQuestion - 1) / (questions.length) * 100;
    }

    const handleNextStep = async () => {
        setIsLoading(true);

        surveyService.updateSurvey({
            question: questions[currentIndexQuestion - 1].code,
            answers: userCurrentAnswer,
            step: currentIndexQuestion + 1,
            type: questions[currentIndexQuestion - 1].type
        })
            .then(() => goNextStep())
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    const handleValidation = useCallback(async (isValid: boolean) => {
        setIsAnswerValid(isValid);
    }, []);

    return (
        <div id="questionnaire" className="flex column">
            <div className="column">
                <Line
                    percent={getPercentProgressBar()}
                    strokeWidth={1}
                    strokeColor="var(--dashboard-main-color)"
                    strokeLinecap="square"
                />
                <div className="row" id="header-questionnaire">
                    <div className="row" id="back-container" onClick={goToDashboard}>
                        <RiArrowLeftSLine color={"var(--dashboard-main-color)"} size={30} />
                        <span>{t.translate('dashboardBtn')}</span>
                    </div>
                    <img src={logo} alt="logo" id='logo' onClick={goToDashboard} />
                    {currentIndexQuestion <= questions.length ?
                        <span id="questionIndexResume">{t.translate('question')}{width < 524 ? <br /> : ' '}{currentIndexQuestion} / {questions.length}</span> :
                        <span id="questionIndexResume">{t.translate('question')}{width < 524 ? <br /> : ' '}{currentIndexQuestion - 1} / {questions.length}</span>
                    }
                </div>
            </div>

            {isLoading
                ? <Loader />
                : ((currentIndexQuestion <= questions.length)
                    ? <Fragment>
                        <Question
                            question={questions[currentIndexQuestion-1]}
                            handleValidation={handleValidation}
                            handleUserAnswer={setUserCurrentAnswer}
                            previousAnswers={previousAnswers}
                        />
                        <div className="flex-row" id="buttons-navigation-container">
                            <Button disabled={currentIndexQuestion < 2} onClick={() => handlePrevious()} label={t.translate('ongoing.previous')} />
                            <Button disabled={!isAnswerValid} onClick={() => handleNextStep()} label={t.translate('ongoing.next')} />
                        </div>
                    </Fragment>
                    : <div className="flex-column" style={{ flexGrow: 1 }}>
                        <div className="flex-column" style={{ transform: 'translateY(-50%)' }}>
                            <div className="column" id="success-container">
                                <h1 id="title" className="MavenPro-black">{t.translate('success.title')}</h1>
                                <p id="success-message">{t.translate('success.body')}</p>
                            </div>
                            <Button
                                label={t.translate('success.endBtn')}
                                onClick={() => goToDashboard()}
                            />
                        </div>
                    </div>
            )}
        </div>
    );
}

export default Questionnaire;