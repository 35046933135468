export interface IIcon {
    id?: string;
    name?: string;
    className?: string;
    size?: string | number;
    color?: string;
    onClick?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
    onMouseEnter?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
    style?: React.CSSProperties;
}

export const defaultIconProps: IIcon = {
    size: 24,
    color: 'var(--dashboard-main-color)'
}