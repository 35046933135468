import languageService from "./language.service";

class PriceService {
    public roundPrice(price: number) {
        return Math.round(price * 100) / 100;
    }

    public formatPrice(price: number) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public roundAndFormat(price: number): string {
        return price.toLocaleString(
            languageService.getLSLanguage().countryCode,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        );
    }
}

export default new PriceService();