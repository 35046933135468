import box from 'assets/home/microbiota_analysis.webp';
import { Button, CheckCircleIcon, Input, Label, Loader, Phone, StepIndicator } from 'components';
import LeftArrow from 'components/Icons/LeftArrow';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import patientService from 'services/patient.service';
import translationService from "services/translation.service";
import userAuthService from "services/user-auth.service";
import "../scss/KitRegister.scss";

const SEND_BACK_CODE_TIME = 59000;

const TS = 'firstConnection.';

const ConfirmPhone: React.FC<({
    handleNextStep: () => void
})> = ({
    handleNextStep
}) => {
    const t = useTranslation('pages/kitRegister');
    const history = useHistory();
    const { user, refreshUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<'first' | 'submit' | 'updatePhone' | undefined>('first');
    const [ error, setError ] = useState<string>();
    const [ hasPhone, setHasPhone ] = useState<boolean>(true);

    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ countryCode, setCountryCode ] = useState<string>();

    const [ isConfirmationDone, setIsConfirmationDone ] = useState<boolean>(false);
    const [ smsCode, setSmsCode ] = useState<string>();
    const [ countDownKey, setCountDownKey ] = useState<number>(1);
    const [ countDownTime, setCountDownTime ] = useState(Date.now() + SEND_BACK_CODE_TIME);

    const handleReceiveSmsCode = async () => {
        setIsLoading('first');

        if (!user?.phoneIsVerified) {
            await userAuthService.getSmsCode()
                .catch((err) => setError(err.message));
        } else {
            setIsConfirmationDone(true);
        }

        setIsLoading(undefined);
    }

    useEffect(() => {
        if (user?.phone && user.phone.country && user.phone.number) {
            handleReceiveSmsCode();
        } else {
            setHasPhone(false);
            setIsLoading(undefined);
        }
    }, []);

    const getSmsCode = async () => {
        setCountDownKey(countDownKey + 1);
        setCountDownTime(Date.now() + SEND_BACK_CODE_TIME);
        setSmsCode("");
        
        await userAuthService.getSmsCode().catch((err) => setError(err.message));
    }

    const renderCountDown = ({ seconds, completed }: CountdownRenderProps) => {
        if (completed) {
            return <Button label={t.translateFromFile('firstConnection', 'resendCodeSMS')} onClick={getSmsCode} />
        } else {
            return <Button label={`${t.translateFromFile('firstConnection', 'resendCodeSMS')} (${seconds})`} disabled />
        }
    }

    const updatePhone = () => {
        setIsLoading('first');
        patientService.updateContactInformations({
            countryCode: countryCode,
            phoneNumber: phoneNumber,
        })
            .then(() => {
                refreshUser();
                handleNextStep();
                setHasPhone(true);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(undefined));
    }

    const verifySMSCode = async () => {
        setIsLoading('submit');
        setError(undefined);

        if (smsCode) {
            await userAuthService.checkSMSCode({ code: smsCode })
                .then(() => handleNextStep())
                .catch((err) => setError(err))
                .finally(() => setSmsCode(undefined));
        } else {
            setError('code_missing')
        }
        
        setIsLoading(undefined);
    }

    return (
        <DefaultContainer
            wrapperClassName="customContainerWrapper"
            title={
                <div className="flex-row-start">
                    <LeftArrow
                        className='clickable'
                        onClick={() => history.push('/account/home')}
                    />
                    <div style={{ margin: '0 1vw' }} className="flex-column-start">
                        <StepIndicator step={1} />
                        <div className="flex-row-start align-items-stretch">
                            <h3>{t.translate('title')}</h3>
                            <span style={{ display: 'grid', alignContent: 'center', color: 'var(--dashboard-main-color)', margin: '0 .5vw' }}>1/3</span>
                        </div>
                    </div>
                </div>
            }
            body={
                <div className='row'>
                    <div id="image-wrapper" className="column">
                        <img src={box} alt="kit" id="kit-image" />
                    </div>
                    {hasPhone
                        ? isConfirmationDone
                            ? <div className="flex-column">
                                <div className="flex-column">
                                    <CheckCircleIcon size={70} />
                                    <p id="title" className="MavenPro-bold">
                                        {translationService.translate(TS + 'successCodeSMS')}
                                    </p>
                                </div>
                                <Button label={translationService.translate(TS + 'next')} onClick={() => { setIsLoading('first'); handleNextStep(); }} />
                            </div>
                            : <div id="form-wrapper" className="flex-column">
                                <div className="flex-column">
                                    <h2 id="title" className="MavenPro-black full-width" style={{ textAlign: "center" }}>
                                        {t.translate('confirmPhone.title')}
                                    </h2>
                                    <p id="form-description" className="full-width" style={{ textAlign: "center" }}>
                                        {t.translate('confirmPhone.body')}
                                    </p>
                                </div>
    
                                <div className="flex-column flex-grow">
                                    {isLoading
                                        ? <Loader loaderDescription={translationService.translate(TS+'loading.'+isLoading)} />
                                        : <Fragment>
                                            <div className="column" id="input-container">
                                                <Label for='code-phone' label={translationService.translate(TS+'codePhone')} required />
                                                <Input
                                                    name='code-phone'
                                                    type="number"
                                                    value={smsCode}
                                                    setValue={(e) => setSmsCode(e.currentTarget.value)}
                                                    required
                                                />
                                            </div>
                                            <p>
                                                <Countdown
                                                    date={countDownTime}
                                                    renderer={(props) => renderCountDown(props)}
                                                    key={countDownKey}
                                                />
                                            </p>
                                            
                                            <ErrorMessage error={error} />
                                            <Button label={t.translate('validate')} onClick={() => verifySMSCode()} disabled={isLoading} />
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        : <div id="form-wrapper" className="flex-column">
                            <div className="flex-column">
                                <h2 id="title" className="MavenPro-black full-width" style={{ textAlign: "center" }}>
                                    {t.translate('updatePhone.title')}
                                </h2>
                                <p id="form-description" className="full-width" style={{ textAlign: "center" }}>
                                    {t.translate('updatePhone.body')}
                                </p>
                            </div>

                            <div className="flex-column flex-grow">
                                {isLoading
                                    ? <Loader loaderDescription={translationService.translate(TS+'loading.'+isLoading)} />
                                    : <Fragment>
                                        <div>
                                            <Label name='phone' label={t.translateFormField('phone.label')} required />
                                            <Phone
                                                required
                                                labelledBy='phone'
                                                defaultCountryCode={countryCode}
                                                setCountryCode={setCountryCode}
                                                defaultPhoneNumber={phoneNumber}
                                                setPhoneNumber={setPhoneNumber}
                                                checkFunction={() => !!countryCode && !!phoneNumber}
                                                checkmark={{
                                                    displayed: true
                                                }}
                                            />
                                        </div>
                                        
                                        <ErrorMessage error={error} />
                                        <Button
                                            label={t.translate('update')}
                                            onClick={() => updatePhone()}
                                            disabled={isLoading}
                                        />
                                    </Fragment>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
            hideSeparator={true}
        />
    )
}

export default ConfirmPhone;