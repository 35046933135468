import React from "react";
import 'style/components/Form/DashboardInputSlider.scss';

const DashboardInputSlider: React.FC<({
    min?: number;
    max: number;
    step?: number;
    value: number;
    onChange: React.ChangeEventHandler<HTMLInputElement> | undefined
})> = ({
    min = 0,
    max,
    step = 1,
    value,
    onChange
}) => {
    return (
        <input
            type="range"
            min={min}
            max={max}
            value={value}
            step={step}
            onChange={onChange}
        />
    )
}

export default DashboardInputSlider;