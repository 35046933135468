import React from 'react';
import translationService from "services/translation.service";

const ErrorMessage: React.FC<({
    error?: string
    withTranslation?: boolean
})> = ({
    error,
    withTranslation = true
}) => {
    return (
        error ? <i className="text-error">{withTranslation ? translationService.translateAPIError(error) : error}</i> : null
    )
}

export default ErrorMessage;