import banner from 'assets/order_box_banner.png';
import { Button, Checkbox } from "components";
import StepIndicator from 'components/StepIndicator';
import { EditAddress } from "components/common/Address";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IAddress, defaultAddress } from "interfaces/User/IAddress";
import React, { useState } from "react";
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";

const NoAddress: React.FC<({
    reload?: () => void;
})> = ({
    reload
}) => {
    const t = useTranslation('dashboard/company/order_kit');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const [ newBillingAddress, setNewBillingAddress ] = useState<IAddress>(defaultAddress);
    const [ newShippingAddress, setNewShippingAddress ] = useState<IAddress>(defaultAddress);
    const [ sameAddresses, setSameAddresses ] = useState<boolean>(true);

    const submitAddress = async () => {
        setIsLoading(true);
        setError(undefined);

        standaloneService.updateAddresses({
            sameAddresses: sameAddresses,
            billing: newBillingAddress,
            shipping: newShippingAddress
        })
            .then(() => reload ? reload() : location.reload())
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    return (
        <div className="page-wrapper">
            <StepIndicator step={1} />
            <div className="order-dashboard">
                <div className="order-dashboard-banner">
                    <img src={banner} alt="A person is typing on a laptop" />
                    <h2 className="text-uppercase MavenPro-black">
                        {t.translate('title.first')}
                        <br />
                        {t.translate('title.second')}
                    </h2>
                    <hr />
                </div>
                <div className="flex-column" style={{ padding: '2vh 10vw' }}>
                    <p className="text-align-center">{t.translate('noAddress.first')}</p>
                    <EditAddress
                        isLoading={isLoading}
                        address={newBillingAddress}
                        setAddress={setNewBillingAddress}
                    />
                    <Checkbox
                        name='areAddressesSame'
                        isChecked={sameAddresses}
                        setChecked={setSameAddresses}
                        label={t.translate('noAddress.areAddressesSame')}
                    />
                    { !sameAddresses
                        ? <EditAddress
                            isLoading={isLoading}
                            address={newShippingAddress}
                            setAddress={setNewShippingAddress}
                        />
                        : null
                    }

                    <ErrorMessage error={error} />

                    <div className="flex-row full-width">
                        <Button label={t.translate('noAddress.save')} onClick={() => submitAddress()} disabled={isLoading} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoAddress;