import { Button, Input, Label, Select } from "components";
import { Modal } from 'components/common/Modal';
import User from "interfaces/Database/IDatabaseUser";
import moment from 'moment';
import 'moment-timezone';
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import patientService from "services/patient.service";
import translationService from "services/translation.service";
import verifierService from "services/verifier.service";

const ModalEditProfileInfo: React.FC<({
    showModal: boolean
    handleClose: () => void
    handleSuccess: () => void
    currentUser: User
})> = ({ showModal, handleClose, handleSuccess, currentUser }) => {
    const t = useTranslation('profile/patient/myGeneralInformation');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ user, setUser ] = useState<User>(currentUser);
    const [ gender, setGender ] = useState<string>(!currentUser?.patientInfo?.gender ? 'Male' : currentUser.patientInfo.gender.name === 'Male' ? 'Male' : 'Female')

    const closeModal = () => {
        handleClose();
    }

    const successModal = () => {
        setIsLoading(true);

        patientService.updateInformations({
            firstName: user?.patientInfo?.firstname,
            lastName: user?.patientInfo?.lastname,
            birthDate: moment(user?.patientInfo?.birthDate).format('DD-MM-YYYY') === "Invalid date" ?
                undefined :
                moment(user?.patientInfo?.birthDate).format('DD-MM-YYYY'),
            gender: gender
        })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate("titleModal")}
            footer={
                <div className="flex-row full-width">
                    <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                    <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                </div>
            }
        >
            <Fragment>
                <div className="flex-row full-width flex-grow flex-gap-col flex-wrap">
                    <div className="flex-column-start flex-grow">
                        <Label for="lastname" label={t.translate('lastname')} required />
                        <Input
                            required
                            name="lastname"
                            disabled={isLoading}
                            value={user?.patientInfo?.lastname}
                            setValue={(e) => setUser(new User({
                                ...user,
                                patientInfo: {
                                    ...user.patientInfo,
                                    lastname: e.currentTarget.value
                                }
                            }))}
                            checkFunction={() => verifierService.checkString(user?.patientInfo?.lastname)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                    <div className="flex-column-start flex-grow">
                        <Label for="firstname" label={t.translate('firstname')} required />
                        <Input
                            required
                            name="firstname"
                            disabled={isLoading}
                            value={user?.patientInfo?.firstname}
                            setValue={(e) => setUser(new User({
                                ...user,
                                patientInfo: {
                                    ...user.patientInfo,
                                    firstname: e.currentTarget.value
                                }
                            }))}
                            checkFunction={() => verifierService.checkString(user?.patientInfo?.firstname)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <div className="flex-row full-width flex-grow flex-gap-col flex-wrap">
                    <div className="flex-column-start flex-grow">
                        <Label for="gender" label={t.translate('genre')} required />
                        <Select
                            required
                            name="gender"
                            value={gender}
                            setValue={(e) => setGender(e.currentTarget.value)}
                            checkFunction={() => verifierService.checkString(gender)}
                            checkmark={{
                                displayed: true
                            }}
                            options={
                                <Fragment>
                                    <option value='' style={{ fontStyle: 'italic' }}></option>
                                    <option value="Male">{translationService.translateGender('Male')}</option>
                                    <option value="Female">{translationService.translateGender('Female')}</option>
                                </Fragment>
                            }
                        />
                    </div>
                    <div className="flex-column-start flex-grow">
                        <Label for="birthdate" label={t.translate('birthdate')} required />
                        <Input
                            required
                            name="birthdate"
                            disabled={isLoading}
                            type="date"
                            value={user?.patientInfo?.birthDate ? moment(new Date(user?.patientInfo?.birthDate)).format('YYYY-MM-DD') : undefined}
                            setValue={(e) => setUser(new User({
                                ...user,
                                patientInfo: {
                                    ...user.patientInfo,
                                    birthDate: e.currentTarget.valueAsDate
                                        ? e.currentTarget.valueAsDate
                                        : undefined
                                }
                            }))}
                            checkFunction={() => !!(user?.patientInfo?.birthDate && verifierService.checkString(moment(new Date(user?.patientInfo?.birthDate)).format('YYYY-MM-DD')))}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
            </Fragment>
        </Modal>
    );
}

export default ModalEditProfileInfo;