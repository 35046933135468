import { Button, Input, Label, Loader, Select, TextArea } from "components";
import "components/DoctorForms/FormNewPrescription/scss/FormNewPrescription.scss";
import Close from "components/Icons/Close";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import IntakeCalendar from "components/common/IntakeCalendar/IntakeCalendar";
import { IComplement, IDDietarySupplement, IDUser, defaultDietarySupplement } from "interfaces/Database";
import { IDay } from "interfaces/IDay";
import { IIntakeSchedule } from "interfaces/IIntakeSchedule";
import moment from 'moment';
import 'moment-timezone';
import React, { Fragment, useEffect, useState } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import useComplementService from "services/api/complement.service";
import doctorService from "services/doctor.service";
import prescriptionService from "services/prescription.service";
import verifierService from "services/verifier.service";

const PrescriptionCreation: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('patientDetail');
    const { patientId, appointmentId } = useParams<({ patientId: string, appointmentId: string })>();

    const complementService = useComplementService();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ dbComplements, setDbComplements ] = useState<IComplement[]>([]);

    const [ complementList, setComplementList ] = useState<IDDietarySupplement[]>([]);
    const [ patient, setPatient ] = useState<IDUser>();
    const [ lastUpdate, setLastUpdate ] = useState<Date>();

    const fetchPatient = () => {
        setIsLoading(true);

        Promise.all([
            doctorService.getPatientDetails(patientId)
                .then((res) => {
                    setPatient(res.result);
                    setComplementList(res?.dietarySupplement ?? []);
                    setLastUpdate(res?.prescription?.updatedAt);
                }),
            complementService.getAll()
                .then((res) => setDbComplements(res))
                .catch(() => setDbComplements([]))
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        fetchPatient();
    }, []);

    useEffect(() => {
        const unloadCallback = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    const handleRemoveComplement = (index: number) => {
        setComplementList(complementList =>
            complementList.filter((_, i) => index !== i)
        );
    }

    const handleUpdateComplement = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, index: number) => {
        const newComplementList = complementList.map((complement, i) => {
            if (index === i) {
                const elementNameSplitted = e.target.name.split('-');

                if (elementNameSplitted[elementNameSplitted.length-1] === 'baseComplement') {
                    if (
                        e.target.value === null ||
                        !parseInt(e.target.value) ||
                        parseInt(e.target.value) === -1
                    ) {
                        return {
                            ...complement,
                            baseComplement: undefined
                        }
                    }

                    // DB Complement
                    const foundComplements = dbComplements.filter((dbComplement) => dbComplement.id == parseInt(e.target.value));
                    const foundComplement = foundComplements[0] ?? undefined;

                    if (!foundComplement) return complement;

                    return {
                        ...complement,
                        name: foundComplement.name,
                        quantity: foundComplement.baseIntakeQuantity ?? complement.quantity,
                        baseComplement: foundComplement
                    }
                }

                return {
                    ...complement, [elementNameSplitted[elementNameSplitted.length-1]]:
                        e.target.type === "number"
                            ? parseInt(e.target.value)
                            : e.target.value
                }
            }
            return complement;
        });
        setComplementList(newComplementList);
    }

    const getDateLastUpdate = () => {
        return lastUpdate ? moment(lastUpdate).format("DD/MM/YYYY HH:mm") : "";
    }

    const handleGeneratePrescription = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        setIsLoading(true);

        prescriptionService.createOrUpdate({
            appointmentId: appointmentId,
            dietarySupplement: complementList
        })
            .then(() => history.push(`/account/patient/${patientId}`))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    const handleAddElement = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        setComplementList([ ...complementList, defaultDietarySupplement ]);
    }

    const updateComplementSchedule = (
        index: number,
        intakeSchedule: IIntakeSchedule
    ) => {
        const newComplementList = complementList.map((complement, i) => {
            if (index === i) {
                return {
                    ...complement,
                    intakeSchedule
                }
            }
            return complement;
        });
        setComplementList(newComplementList);
    }

    const isScheduleEmpty = (intakeSchedule: IIntakeSchedule) => {
        const calculated = Object.keys(intakeSchedule).map((value: string) => {
            const val = value as IDay;

            if (
                val &&
                (intakeSchedule[val].morning ||
                intakeSchedule[val].noon ||
                intakeSchedule[val].evening)
            ) {
                return 'something_is_booked';
            } else {
                return 'aled';
            }
        });

        return calculated.filter((e) => e === 'something_is_booked').length === 0;
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div id="form-new-prescription" className="column">
            <div className="column" id="content">
                <div id="title-wrapper" className="row">
                    <ImArrowLeft2 id="icon-go-back" size={26} onClick={handleGoBack} />
                    <h1 id="title" className="MavenPro-black">
                        {t.translate('pdf.prescription.titleDietary')} {patient?.patientInfo?.firstname} {patient?.patientInfo?.lastname}
                    </h1>
                </div>
                <DefaultContainer
                    title={
                        <div className="flex-column-start">
                            <h2 className="MavenPro-bold" id="header-title">
                                {t.translate('pdf.prescription.writeDietarySupplement')}
                            </h2>

                            {lastUpdate &&
                                <span id="last-update-label">
                                    {t.translate('pdf.lastUpdate')} {getDateLastUpdate()}
                                </span>
                            }
                        </div>
                    }
                    body={
                        <Fragment>
                            <div id="form-medication-list-wrapper" className="flex-column full-width">
                                <form id="form-medication-wrapper" className="full-width" onSubmit={(e) => handleGeneratePrescription(e)}>
                                    <div className="flex-column full-width">
                                        {complementList.map((complement, i) =>
                                            <div key={i} className="flex-column full-width" style={{ padding: '10px 0' }}>
                                                <div className="flex-row full-width space-between">
                                                    <Label for={`complement-${i}-close`} label={t.translate('pdf.prescription.dietarySupplement.type') + ` n°${i + 1}`} required />
                                                    <Close
                                                        name={`complement-${i}-close`}
                                                        size={30}
                                                        id="button-remove"
                                                        className="clickable"
                                                        onClick={() => handleRemoveComplement(i)}
                                                    />
                                                </div>
                                                <div className="flex-row full-width space-between flex-gap-col">
                                                    <div className="flex-column-start full-width">
                                                        <Label for={`complement-${i}-baseComplement`} label={t.translate('pdf.prescription.baseComplement')} />
                                                        <Select
                                                            name={`complement-${i}-baseComplement`}
                                                            value={complement.complement?.id}
                                                            setValue={(e) => handleUpdateComplement(e, i)}
                                                            options={
                                                                <Fragment>
                                                                    <option value={-1}>{t.translate('pdf.prescription.baseComplement')}</option>
                                                                    {dbComplements.map((dbComplement, index) =>
                                                                        <option key={index} value={dbComplement.id}>{dbComplement.name}</option>
                                                                    )}
                                                                </Fragment>
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex-column-start full-width">
                                                        <Label for={`complement-${i}-name`} label={t.translate('pdf.prescription.name')} required />
                                                        <Input
                                                            name={`complement-${i}-name`}
                                                            value={complement.name}
                                                            setValue={(e) => handleUpdateComplement(e, i)}
                                                            checkFunction={() => verifierService.checkString(complement.name)}
                                                            checkmark={{
                                                                displayed: true
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="prescription-intakeSchedule">
                                                    <Label name={`complement-${i}-calendar`} label={t.translate("pdf.prescription.intakeCalendar")} required />
                                                    <IntakeCalendar labelledBy={`complement-${i}-calendar`} index={i} pre="com" intakeSchedule={complement.intakeSchedule} setSchedule={(schedule) => updateComplementSchedule(i, schedule)} />
                                                </div>
                                                <div className="flex-column-start full-width" style={{ margin: '10px 0' }}>
                                                    <Label for={`complement-${i}-quantity`} label={t.translate('pdf.prescription.quantity')} required />
                                                    <Input name={`complement-${i}-quantity`} value={complement.quantity} type="number" setValue={(e) => handleUpdateComplement(e, i)} required />
                                                    {complement.quantity === 0 && <p className="text-error full-width text-align-center">{t.translate('pdf.prescription.medication.noQuantity')}</p>}
                                                </div>
                                                <div className="flex-column-start full-width" style={{ margin: '10px 0' }}>
                                                    <Label for={`complement-${i}-description`} label={t.translate('pdf.prescription.additionalInformations')} />
                                                    <TextArea name={`complement-${i}-description`} value={complement.description} setValue={(e) => handleUpdateComplement(e, i)} />
                                                </div>
                                            </div>
                                        )}
                                        <Button label={t.translate('pdf.prescription.addComplement')} onClick={(e) => handleAddElement(e)} style={{ margin: '10px' }} />
                                    </div>
                                    {complementList.length <= 0 &&
                                        <div id="no-prescription-wrapper" className="row">
                                            <p className="no-data">
                                                {t.translate('pdf.prescription.noDietarySupplement')}
                                            </p>
                                        </div>
                                    }
                                    <input type="submit" id="submit-form" style={{ display: "none" }} />
                                </form>
                            </div>
                        </Fragment>
                    }
                    footer={
                        <Button label={t.translate('pdf.save')} onClick={(e) => handleGeneratePrescription(e)} disabled={isLoading || complementList.filter((e) => e.quantity === 0 || isScheduleEmpty(e.intakeSchedule)).length > 0} />
                    }
                />
            </div>
        </div>
    );
}

export default PrescriptionCreation;