import img from 'assets/Help/order.svg';
import React from 'react';
import { useTranslation } from 'services';

const SSSecondPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2><span className='text-main-color'>2.</span> {t.translate('standalone.order.title')}</h2>
            <div className="help-step-content">
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '320px' }}>
                    <p>{t.translate('standalone.order.body1')} <span className='text-important'>{t.translate('standalone.order.securePayment')}</span> {t.translate('standalone.order.body2')} <span className='text-important'>{t.translate('standalone.order.48h')}</span> {t.translate('standalone.order.body3')}</p>
                </div>
                <img src={img} className='help-img' alt="Order cart" />
            </div>
        </div>
    )
}

export default SSSecondPage;