import apiService from "./api.service";
import ErrorService from "./Error.service";

class kitService {

    public initKit = async (code: string) => {
        return await apiService.post(`/api/analysisKit/checkCode`, JSON.stringify({ code }))
            .then((res) => res)
            .catch((err) => ErrorService.handleApiError(err));
    };

    public localStorageSaveKitCode = (code: string) => {
        localStorage.setItem('kitCode', code);
        window.dispatchEvent(new CustomEvent('storage', { detail: { kitCode: code } }));
    }

    public localStorageGetKitCode = () => {
        const kitCode = localStorage.getItem('kitCode');
        
        if (kitCode) {
            return kitCode;
        } else {
            return undefined;
        }
    }

    public checkIfUserHasKit = async () => {
        return await apiService.post(`/api/analysisKit/checkCode`, JSON.stringify({ code: this.localStorageGetKitCode() }))
            .then((res) => res)
            .catch((err) => ErrorService.handleApiError(err));
    };

}
export default new kitService();