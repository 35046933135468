import { EditIcon, Loader } from "components";
import DisplayAddress from "components/common/Address/DisplayAddress";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { MenuStatusContext } from "context/MenuStatusContext";
import { IDAddress } from "interfaces/Database";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "services";
import addressService from "services/address.service";
import "../scss/AddressInformation.scss";
import ModalEditAddressInfo from "./modal/ModalEditAddressInfo";

const AddressInformation: React.FC = () => {
    const t = useTranslation('profile/patient/address');
    
    const history = useHistory();
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);

    const { refreshMenuStatus } = useContext(MenuStatusContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();

    const [ isBillingAndShippingSame, setIsBillingAndShippingSame ] = useState<boolean>(false);
    const [ currentBillingAddress, setCurrentBillingAddress ] = useState<IDAddress>();
    const [ currentShippingAddress, setCurrentShippingAddress ] = useState<IDAddress>();
    
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const toggleShowEdit = () => setShowEdit(!showEdit);

    const loadAddresses = useCallback(() => {
        setIsLoading(true);

        addressService.getAddresses()
            .then((res) => {
                if (res.billing) setCurrentBillingAddress(res.billing);
                if (res.shipping) setCurrentShippingAddress(res.shipping);

                if ((res.billing && res.shipping) && addressService.compareAddresses(res.billing, res.shipping)) {
                    setIsBillingAndShippingSame(true);
                }
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        loadAddresses();
    }, [loadAddresses]);

    useEffect(() => {
        // IS MODAL SHOULD BE OPEN
        const openModal = queryParameters.get('openModal');

        if (openModal === 'address') toggleShowEdit();
    }, []);

    const loadContent = () => {
        if (isLoading) {
            return <Loader />
        } else if (error) {
            return <ErrorMessage error={error} />
        } else {
            return (!currentBillingAddress && !currentShippingAddress)
                ? <p className="no-data">{t.translate('detail.address.neither')}</p>
                : <Fragment>
                    <div className="full-width flex-row space-between">
                        <DisplayAddress address={currentBillingAddress} />
                    </div>
                    {!isBillingAndShippingSame
                        ? <div className="full-width flex-row space-between" style={{ marginTop: '20px' }}>
                            <DisplayAddress address={currentShippingAddress} />
                        </div>
                        : <p style={{ marginTop: '20px' }}>{t.translate('detail.address.same')}</p>
                    }
                </Fragment>
        }
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold">{t.translate("title")}</h1>
                    <EditIcon
                        className="clickable"
                        onClick={toggleShowEdit}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    {loadContent()}
                    <ModalEditAddressInfo
                        showModal={showEdit}
                        handleSuccess={() => {
                            Promise.all([
                                loadAddresses(),
                                refreshMenuStatus()
                            ]);
                        }}
                        handleClose={() => toggleShowEdit()}
                        areSame={isBillingAndShippingSame}
                        billingAddress={currentBillingAddress}
                        shippingAddress={currentShippingAddress}
                    />
                </Fragment>
            }
        />
    );
}

export default AddressInformation;