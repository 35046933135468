import { Button, Checkbox, Label, LabelInput } from "components";
import { Phone } from "components/Form";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import React, { useState } from "react";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import verifierService from "services/verifier.service";

const RegisterForm: React.FC<({
    definedEmail?: string,
    onSuccess: (props: { email: string, password: string }) => void
})> = ({
    definedEmail,
    onSuccess
}) => {
    const t = useTranslation('pages/register');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ fieldErrors, setFieldErrors ] = useState<string[]>([]);
    const [ error, setError ] = useState<string>();

    const [ firstName, setFirstName ] = useState<string>();
    const [ lastName, setLastName ] = useState<string>();

    const [ email, setEmail ] = useState<string | undefined>(definedEmail);
    const [ confirmEmail, setConfirmEmail ] = useState<string>();

    const [ phoneCode, setPhoneCode ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();

    const [ password, setPassword ] = useState<string>();
    const [ confirmPassword, setConfirmPassword ] = useState<string>();

    const [ cg, setCG ] = useState<boolean>(false);

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        setIsLoading(true);
        setError(undefined);
        setFieldErrors([]);

        const tmp_error = [];

        if (!cg) tmp_error.push('fields.cg.error');
        if (!verifierService.stringEquals(password, confirmPassword)) tmp_error.push('fields.confirmPassword.error');
        if (!verifierService.checkPwdEnough(password)) tmp_error.push('fields.password.error');
        if (!verifierService.stringEquals(email, confirmEmail)) tmp_error.push('fields.confirmEmail.error');
        if (!verifierService.checkEmail(email)) tmp_error.push('fields.email.error');
        if (!verifierService.checkString(lastName, { len: { min: 4 } })) tmp_error.push('fields.lastName.error');
        if (!verifierService.checkString(firstName, { len: { min: 4 } })) tmp_error.push('fields.firstName.error');

        if (tmp_error.length === 0 && firstName && lastName && email && confirmEmail && phoneCode && phoneNumber && password &&  confirmPassword) {
            await userAuthService.register({
                firstName: firstName,
                lastName: lastName,
                email: email,
                confirmEmail: confirmEmail,
                phoneCode: phoneCode,
                phoneNumber: phoneNumber,
                password: password,
                confirmPassword: confirmPassword,
                cg: cg
            })
                .then(() => onSuccess({ email: email, password: password }))
                .catch((err) => setError(err.message));
        } else {
            setFieldErrors(tmp_error);
        }

        setIsLoading(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex-column">
                <div className="register-row flex-gap-col">
                    <LabelInput
                        required
                        error={
                            fieldErrors.filter((err) => err.includes('firstName')).length > 0 ?
                                t.translate(fieldErrors.filter((err) => err.includes('firstName'))[0]) : undefined
                        }
                        disabled={isLoading}
                        label={`${t.translate('fields.firstName.label')} :`}
                        value={firstName}
                        setValue={(e) => setFirstName(e.currentTarget.value)}
                        name="firstName"
                        checkmark={{
                            displayed: true
                        }}
                        checkFunction={() => verifierService.checkString(firstName, { len: { min: 3 } })}
                    />
                    <LabelInput
                        required
                        error={
                            fieldErrors.filter((err) => err.includes('lastName')).length > 0 ?
                                t.translate(fieldErrors.filter((err) => err.includes('lastName'))[0]) : undefined
                        }
                        disabled={isLoading}
                        label={`${t.translate('fields.lastName.label')} :`}
                        value={lastName}
                        setValue={(e) => setLastName(e.currentTarget.value)}
                        name="lastName"
                        checkmark={{
                            displayed: true
                        }}
                        checkFunction={() => verifierService.checkString(lastName, { len: { min: 3 } })}
                    />
                </div>
                <div className="register-row flex-gap-col">
                    <LabelInput
                        required
                        error={
                            fieldErrors.filter((err) => err.includes('email')).length > 0 ?
                                t.translate(fieldErrors.filter((err) => err.includes('email'))[0]) : undefined
                        }
                        disabled={isLoading}
                        // disabled={definedEmail ? true : isLoading}
                        label={`${t.translate('fields.email.label')} :`}
                        value={email}
                        setValue={(e) => setEmail(e.currentTarget.value)}
                        type="email"
                        name="email"
                        checkmark={{
                            displayed: true
                        }}
                        checkFunction={() => verifierService.checkEmail(email)}
                    />
                    <LabelInput
                        required
                        error={
                            fieldErrors.filter((err) => err.includes('confirmEmail')).length > 0 ?
                                t.translate(fieldErrors.filter((err) => err.includes('confirmEmail'))[0]) : undefined
                        }
                        disabled={isLoading}
                        label={`${t.translate('fields.confirmEmail.label')} :`}
                        value={confirmEmail}
                        setValue={(e) => setConfirmEmail(e.currentTarget.value)}
                        type="email"
                        name="confirmEmail"
                        checkmark={{
                            displayed: true
                        }}
                        checkFunction={() => verifierService.stringEquals(email, confirmEmail)}
                    />
                    
                </div>
                <div className='register-row'>
                    <div className="flex-column-start full-width">
                        <Label name="register-phone" required label={`${t.translate('fields.phone.label')} :`} />
                        <Phone
                            labelledBy="register-phone"
                            defaultCountryCode={phoneCode}
                            setCountryCode={setPhoneCode}
                            defaultPhoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            checkmark={{
                                displayed: true
                            }}
                        />
                        {fieldErrors.filter((err) => err.includes('phone')).length > 0 && <div className="text-error">
                            {t.translate(fieldErrors.filter((err) => err.includes('phone'))[0])}
                        </div>}
                    </div>
                </div>
                <div className="register-row flex-gap-col">
                    <LabelInput
                        required
                        error={
                            fieldErrors.filter((err) => err.includes('password')).length > 0 ?
                                t.translate(fieldErrors.filter((err) => err.includes('password'))[0]) : undefined
                        }
                        disabled={isLoading}
                        label={`${t.translate('fields.password.label')} :`}
                        value={password}
                        setValue={(e) => setPassword(e.currentTarget.value)}
                        type="password"
                        name="password"
                        checkmark={{
                            displayed: true
                        }}
                        checkFunction={() => verifierService.checkPwdEnough(password)}
                    />
                    <LabelInput
                        required
                        error={
                            fieldErrors.filter((err) => err.includes('confirmPassword')).length > 0 ?
                                t.translate(fieldErrors.filter((err) => err.includes('confirmPassword'))[0]) : undefined
                        }
                        disabled={isLoading}
                        label={`${t.translate('fields.confirmPassword.label')} :`}
                        value={confirmPassword}
                        setValue={(e) => setConfirmPassword(e.currentTarget.value)}
                        type="password"
                        name="confirmPassword"
                        checkmark={{
                            displayed: true
                        }}
                        checkFunction={() => verifierService.checkPwdSame(password, confirmPassword)}
                    />
                </div>

                <div className='flex-row'>
                    <Checkbox
                        required
                        error={
                            fieldErrors.filter((err) => err.includes('cg')).length > 0 ?
                                t.translate(fieldErrors.filter((err) => err.includes('cg'))[0]) : undefined
                        }
                        isChecked={cg}
                        setChecked={setCG}
                        disabled={isLoading}
                        name="cg"
                        label={
                            t.translateReplaceValues(
                                'fields.cg.label',
                                [
                                    {
                                        tag: 'cgLink',
                                        value:
                                            <a href='/conditions' target='_blank'>
                                                {t.translate('fields.cg.linkLabel')}
                                            </a>
                                    }
                                ]
                            )
                        }
                    />
                </div>

                <ErrorMessage error={error} />
                <Button label={t.translate('confirmBtn')} type="submit" disabled={isLoading} />
            </div>
        </form>
    )
}

export default RegisterForm;