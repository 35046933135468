import { Button, Loader } from "components";
import { Modal } from "components/common/Modal";
import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import { IDUser } from "interfaces/Database";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import messagingService from "services/messaging.service";
import userAuthService from "services/user-auth.service";
import SearchRecipient from "../components/SearchReceipient";
import '../scss/ModalNewMessage.scss';

const ModalNewMessage: React.FC<({
    showModal: boolean;
    setShowModal: (newState: boolean) => void;
    handleSuccess: () => void;
    choosedRecipient?: IDUser;
    setChoosedRecipient: (newChoosedRecipient?: IDUser) => void;
    recipients: IDUser[];
    currentUser?: IDUser;
})> = ({ showModal, setShowModal, handleSuccess, choosedRecipient, setChoosedRecipient, recipients, currentUser }) => {
    const t = useTranslation('messaging');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    
    const [ message, setMessage ] = useState<string>();
    const [ userSearch, setKeyword ] = useState<string>();
    const [ totalMessages, setTotalMessages ] = useState<number>(0);
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);

    const fetchTotalMessages = async () => {
        setIsLoading(true);
        
        messagingService.getTotalMessagesWithDoctor()
            .then((res) => setTotalMessages(res))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchTotalMessages();
    }, []);

    const resetUseStates = () => {
        setKeyword(undefined);
        setChoosedRecipient(undefined);
        setMessage(undefined);
    }

    const closeModal = () => {
        resetUseStates();
        setShowModal(false);
        document.documentElement.style.overflow = 'scroll';
    }

    const checkIsDoctor = () => {
        if (message && currentUser?.patientInfo !== null && choosedRecipient && choosedRecipient?.doctorInfo !== null && choosedRecipient?.doctorInfo?.id === currentUser?.doctor?.doctorInfo?.id ) {
            setShowModalConfirm(true);
        } else {
            closeSuccess();
        }
    }

    const closeSuccess = async () => {
        setIsLoading(true);

        if (choosedRecipient && message) {
            messagingService.sendMessage(choosedRecipient.id, { message: message })
                .then(() => {
                    resetUseStates();
                    handleSuccess();
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ showModal ]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>{
        setMessage(event.target.value);
    }

    const alerteStringDoctor = () => {
        return `${t.translate('check')} ${totalMessages !== null ? `${t.translate('reminder')} ${`${(5 - (totalMessages+1))} message${(5 - totalMessages) > 1 ? "s" : ""}`}.`: ''}`
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate('modalNewMessage.title')}
            subTitle={choosedRecipient && t.translateReplaceValues('to', [
                {
                    tag: '{{name}}',
                    value: userAuthService.formatName(choosedRecipient)
                }
            ])}
            footer={
                !isLoading
                    && <div className="flex-row-end full-width">
                        <Button
                            label={t.translate('send')}
                            onClick={checkIsDoctor}
                            disabled={!message || message.length < 5}
                        />
                    </div>
            }
        >
            {isLoading
                ? <Loader />
                : !choosedRecipient
                    ? <SearchRecipient isLoading={isLoading} recipients={recipients} userSearch={userSearch} setUserSearch={setKeyword} setUserSelected={setChoosedRecipient} />
                    : <Fragment>
                        <div id="chat" className="column full-width">
                            <div id="chat-input-wrapper" className="row">
                                <textarea
                                    id="chat-input"
                                    value={message}
                                    onChange={handleChange}
                                    placeholder={t.translate('placeholder.writeMessage')}
                                />
                            </div>
                        </div>
                    </Fragment>
            }
            <ModalConfirmAction 
                confirmAction={closeSuccess}
                description={alerteStringDoctor()}
                handleClose={() => setShowModalConfirm(false)}
                showModal={showModalConfirm}
            />
        </Modal>
    );
}

export default ModalNewMessage;