import { Button, Loader } from "components";
import AddEmployee from "components/CompanyEmployees/components/AddEmployee";
import AllocateResume from "components/CompanyEmployees/components/AllocateResume";
import AllocateSolution from "components/CompanyEmployees/components/AllocateSolution";
import EmployeeTable from "components/CompanyEmployees/components/EmployeeTable";
import "components/CompanyEmployees/scss/CompanyEmployees.scss";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDatabaseSubscriptionPlan } from "interfaces/Database/IDatabaseSubscriptionPlan";
import { IUserWithOnGoing } from "interfaces/IUserWithOnGoing";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import companyService from "services/company.service";

const Employees: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('dashboard/company/employees');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isAllocating, setIsAllocating ] = useState<boolean>(false);
    const [ isAddingEmployee, setIsAddingEmployee ] = useState<boolean>(false);

    const [ free_kits, set_free_kits ] = useState<IDatabaseSubscriptionPlan[]>([]);
    const [ given_kits, set_given_kits ] = useState<IDatabaseSubscriptionPlan[]>([]);

    const [ employees, setEmployees ] = useState<IUserWithOnGoing[]>([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            companyService.getCompanyAllEmployeesWithSolutions()
                .then((res) => setEmployees(res)),
            companyService.getCompanyPlansData()
                .then((res) => {
                    set_free_kits(res.free ?? []);
                    set_given_kits(res.given ?? []);
                })
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const redirectTo = (path?: string) => {
        if (path) {
            history.push(path);
        }
    }

    const toggleAddingEmployee = (updateEmployees = false) => {
        if (updateEmployees) {
            fetchData().then(() =>
                setIsLoading(false)
            );
        }

        setIsAddingEmployee(!isAddingEmployee);
    }

    const toggleAllocating = (updateEmployees = false) => {
        if (updateEmployees) {
            fetchData().then(() =>
                setIsLoading(false)
            );
        }

        setIsAllocating(!isAllocating);
    }

    if (isLoading) {
        return (
            <div className="flex-column" style={{ minHeight: '100vh' }}>
                <Loader isSmall={false} />
            </div>
        )
    } else {
        return (
            <div id="invoicing">
                <DashboardHeader preTitle={t.translate('header.pre')} title={t.translate('header.title')} />
                <DefaultContainer
                    title={<h2 className="MavenPro-bold">{t.translate('available.title')}</h2>}
                    body={
                        <div id="tous-mes-rdv" className="flex-column full-width">
                            <AllocateResume
                                freeSolutions={free_kits.length}
                                givenSolutions={given_kits.length}
                                desc={t.translate('available.sub')}
                                btnFunc={toggleAllocating}
                                btnText={t.translate('available.allocate')}
                                btnDisabled={free_kits.length === 0}
                                btnShow={free_kits.length > 0}
                            />
                            {free_kits.length === 0 && <div className="flex-column" style={{ padding: '2vh 0' }}>
                                <Button label={t.translate('available.buy')} onClick={() => redirectTo('/order')} />
                            </div>}
                        </div>
                    }
                />
                <DefaultContainer
                    title={
                        <Fragment>
                            <h2 className="MavenPro-bold">{t.translate('registeredEmployees.title')}</h2>
                            <div className='icon-container'><AiOutlinePlus onClick={() => toggleAddingEmployee()} className='icon action' color={'var(--dashboard-main-color)'} size={30} /></div>
                        </Fragment>
                    }
                    body={
                        employees?.length === 0
                            ? <p className="no-data">{t.translate('registeredEmployees.none')}</p>
                            : <EmployeeTable
                                columns={[
                                    {
                                        name: t.translate('registeredEmployees.col.kitRunning'),
                                        colSpan: 2
                                    },
                                    {
                                        name: t.translate('registeredEmployees.col.name'),
                                        colSpan: 1
                                    },
                                    {
                                        name: t.translate('registeredEmployees.col.email'),
                                        colSpan: 1
                                    },
                                    {
                                        name: t.translate('registeredEmployees.col.phone'),
                                        colSpan: 1
                                    },
                                    {
                                        name: "",
                                        colSpan: 1
                                    }
                                ]}
                                employees={employees}
                                isFreeKits={free_kits.length > 0}
                                toggleAllocate={toggleAllocating}
                            />
                    }
                />

                <AllocateSolution isShow={isAllocating} toggleShow={toggleAllocating} maxSolutionNumber={free_kits.length+given_kits.length} usedSolutionNumber={given_kits.length} solutions={free_kits} />
                <AddEmployee isShow={isAddingEmployee} toggleShow={toggleAddingEmployee} />
            </div>
        )
    }
}

export default Employees;