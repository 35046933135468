import { Button } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { ProfilePicture } from "components/common/ProfilePicture";
import { IDGeneralComment, IDUser } from "interfaces/Database";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import translationService from "services/translation.service";
import "../scss/DoctorComment.scss";

const TS = 'dashboard/patient/recommendations.';

const DoctorComment: React.FC<({
    doctor?: IDUser,
    numberMessagesSentToDoctor: number,
    generalComment?: IDGeneralComment,
})> = ({
    doctor,
    numberMessagesSentToDoctor = 0,
    generalComment
}) => {
    const t = useTranslation('dashboard/patient/recommendations');
    const history = useHistory();

    return (
        <DefaultContainer
            title={
                <h1 className="container-title bold">
                    {t.translate('doctor-comment.title')}
                </h1>
            }
            body={generalComment
                ? <div className="flex-row" id="doctor-comment-content-wrapper">
                    <div id="comment-wrapper" style={{ marginRight: '20px' }}>
                        {generalComment
                            ? <p>{generalComment.comment}</p>
                            : <p className="no-data">
                                {t.translate('doctor-comment.no-doctor-comment')}
                            </p>
                        }
                    </div>
                    <div id="doctor-wrapper" className="column">
                        <div className="flex-row" id="doctor-info-wrapper">
                            <ProfilePicture
                                user={doctor}
                                margin={{
                                    right: true
                                }}
                                maxSize={'6rem'}
                            />
                            <div className="column">
                                <div className="column margin">
                                    <span className="libelle-info MavenPro-black">
                                        {t.translate('doctor-comment.doctor-name')}
                                    </span>
                                    <span>{doctorService.formatDoctorName(doctor?.doctorInfo)}</span>
                                </div>
                                <div className="column">
                                    <span className="libelle-info MavenPro-black">
                                        {t.translate('doctor-comment.doctor-message')}
                                    </span>
                                    <span>{numberMessagesSentToDoctor}/5 {t.translate('doctor-comment.sent')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <p className="no-data">
                    {t.translate('doctor-comment.no-doctor-comment')}
                </p>
            }
            footer={(generalComment && doctor) &&
                <Button label={translationService.translate(TS+'doctor-comment.btn-send-message')} onClick={() => history.push('/account/messaging', { openDoctorConversation: doctor.id })} />
            }
        />
    );
}

export default DoctorComment;