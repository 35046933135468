import { IDAppointmentStatus, IDSubscriptionPlan, IDUser } from ".";

export interface IDatabaseAppointment {
    id: number,
    createdOn: Date,
    appointmentOn?: Date,
    endAppointment?: Date,
    doctor: IDUser,
    meetingUrl?: string,
    status: IDAppointmentStatus,
    subscriptionPlan: IDSubscriptionPlan
}

export default class DatabaseAppointment implements IDatabaseAppointment {
    id: number;
    createdOn: Date;
    appointmentOn?: Date;
    endAppointment?: Date;
    doctor: IDUser;
    meetingUrl?: string;
    status: IDAppointmentStatus;
    subscriptionPlan: IDSubscriptionPlan;
  
    constructor(data: IDatabaseAppointment) {
        this.id = data.id;
        this.createdOn = new Date(data.createdOn);
        this.appointmentOn = data.appointmentOn ? new Date(data.appointmentOn) : undefined;
        this.endAppointment = data.endAppointment ? new Date(data.endAppointment) : undefined;
        this.doctor = data.doctor;
        this.meetingUrl = data.meetingUrl;
        this.status = data.status;
        this.subscriptionPlan = data.subscriptionPlan;
    }

    public isNotBooked(): boolean {
        return this.status.name === 'Not Booked';
    }

    public isCancelled(): boolean {
        return this.status.name === 'Cancelled';
    }

    public isBooked(): boolean {
        return this.status.name === 'Booked';
    }

    public isDone(): boolean {
        return this.status.name === 'Done';
    }
}