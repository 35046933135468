import { Button, Input, Label, Loader, Phone, Select } from "components";
import useIsComponentMounted from "hooks/useIsComponentMounted";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import 'moment-timezone';
import moment from "moment-timezone";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import patientService from "services/patient.service";
import stringService from "services/string.service";
import userAuthService from "services/user-auth.service";
import verifierService from "services/verifier.service";
import "../../scss/ClaimKitFormUserInformation.scss";

const ClaimKitFormUserInformation: React.FC<({ handleNextStep: () => void })> = ({ handleNextStep }) => {
    const t = useTranslation('firstConnection');
    const isMounted = useIsComponentMounted();

    const [ firstname, setFirstname ] = useState<string>();
    const [ lastname, setLastname ] = useState<string>();
    const [ email, setEmail ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ phoneCode, setPhoneCode ] = useState<string>();
    const [ birthDate, setBirthDate ] = useState<Date>();
    const [ weight, setWeight ] = useState<number>();
    const [ size, setSize ] = useState<number>();
    const [ waistSize, setWaistSize ] = useState<number>();
    const [ gender, setGender ] = useState<string>();
    const [ isValidForm, setIsValidForm ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    useEffect(() => {
        checkForm();
    }, [firstname, lastname, phoneNumber, email, birthDate, size, weight, gender]);

    const fetchUser = () => {
        setIsLoading(true);

        userAuthService.getUser()
            .then((res) => initUserInformation(res))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (isMounted.current) {
            fetchUser();
        }
    }, []);

    const initUserInformation = (user?: IDatabaseUser) => {
        if (user) {
            setFirstname(stringService.capitalize(user?.patientInfo?.firstname));
            setLastname(stringService.capitalize(user?.patientInfo?.lastname));
            setEmail(user.email);
            setPhoneNumber(user?.phone?.number ?? undefined);
            setPhoneCode(user?.phone?.country?.phonecode ?? undefined);
            setBirthDate(user?.patientInfo?.birthDate);
            setGender(user?.patientInfo?.gender?.name ?? "");
            setWeight(user?.patientInfo?.weight ?? undefined);
            setSize(user?.patientInfo?.size ?? undefined);
            setWaistSize(user?.patientInfo?.waistSize ?? undefined);
        }
    }

    function checkForm() {
        setIsValidForm(
            (!!phoneCode && !!phoneNumber) &&       // TODO: Add new verification for phone number (based on location & format)
            verifierService.checkEmail(email) &&
            verifierService.checkString(firstname) &&
            verifierService.checkString(lastname) &&
            (!!birthDate && verifierService.checkDate(birthDate)) &&
            (!!weight && weight > 0) &&
            (!!size && size > 0)
        );
    }

    const handleSaveData = async () => {
        setIsLoading(true);

        Promise.all([
            patientService.updateContactInformations({
                countryCode: phoneCode,
                phoneNumber: phoneNumber,
            }),
            patientService.updateInformations({
                firstName: stringService.capitalize(firstname),
                lastName: stringService.capitalize(lastname),
                birthDate: moment(birthDate).format('DD-MM-YYYY'),
                gender: gender,
                weight: weight,
                size: size,
                waistSize: waistSize,
            })
        ])
            .then(() => handleNextStep())
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <div id="claim-kit-user-information" className="flex-column">
            <div
                id="form-container"
                className="flex-column full-width shadow flex-row-gap flex-col-gap"
                style={{
                    backgroundColor: 'var(--background-color)',
                    padding: '5vh 2vw',
                }}
            >
                {isLoading
                    ? <Loader />
                    : <Fragment>
                        <div className="flex-row flex-gap-col full-width">
                            <div className="flex-column-start full-width">
                                <Label for="firstname" label={t.translate('firstname')} required />
                                <Input
                                    required
                                    name="firstname"
                                    value={firstname}
                                    setValue={(e) => setFirstname(e.currentTarget.value)}
                                    checkFunction={() => verifierService.checkString(firstname)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                            <div className="flex-column-start full-width">
                                <Label for="lastname" label={t.translate('lastname')} required />
                                <Input
                                    required
                                    name="lastname"
                                    value={lastname}
                                    setValue={(e) => setLastname(e.currentTarget.value)}
                                    checkFunction={() => verifierService.checkString(lastname)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex-row flex-gap-col full-width">
                            <div className="flex-column-start full-width">
                                <Label for="email" label={t.translate('mail')} required />
                                <Input
                                    disabled
                                    required
                                    name="email"
                                    type="email"
                                    value={email}
                                    setValue={(e) => setEmail(e.currentTarget.value)}
                                    checkFunction={() => verifierService.checkEmail(email)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                            <div className="flex-column-start full-width">
                                <Label name="phone" label={t.translate('phone')} required />
                                <Phone
                                    labelledBy="phone"
                                    defaultCountryCode={phoneCode}
                                    setCountryCode={setPhoneCode}
                                    defaultPhoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex-row flex-gap-col full-width">
                            <div className="flex-column-start full-width">
                                <Label for="birthdate" label={t.translate('birthdate')} required />
                                <Input
                                    type="date"
                                    name="birthdate"
                                    value={moment(birthDate).format('YYYY-MM-DD')}
                                    setValue={(e) => e.currentTarget.valueAsDate && setBirthDate(e.currentTarget.valueAsDate)}
                                    checkFunction={() => verifierService.checkDate(birthDate)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                            <div className="flex-column-start full-width">
                                <Label for="gender" label={t.translate('gender')} required />
                                <Select
                                    name="gender"
                                    options={
                                        <Fragment>
                                            <option value="" style={{ fontStyle: 'italic' }} disabled>
                                                {t.translateFormField('chooseOption')}
                                            </option>
                                            <option value="Male">{t.translate('men')}</option>
                                            <option value="Female">{t.translate('female')}</option>
                                        </Fragment>
                                    }
                                    value={gender}
                                    setValue={(e) => setGender(e.currentTarget.value)}
                                    checkFunction={() => verifierService.checkString(gender)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex-row flex-wrap flex-gap-col full-width">
                            <div className="flex-column-start" style={{ minWidth: '120px', maxWidth: '33%' }}>
                                <Label for="weight" label={t.translate('weight') + " (KG)"} />
                                <Input
                                    name="weight"
                                    type="number"
                                    value={weight}
                                    min={0}
                                    setValue={(e) => setWeight(e.currentTarget.valueAsNumber)}
                                />
                            </div>
                            <div className="flex-column-start" style={{ minWidth: '120px', maxWidth: '33%' }}>
                                <Label for="height" label={t.translate('height') + " (CM)"} />
                                <Input
                                    name="height"
                                    type="number"
                                    value={size}
                                    min={0}
                                    setValue={(e) => setSize(e.currentTarget.valueAsNumber)}
                                />
                            </div>
                            <div className="flex-column-start" style={{ minWidth: '120px', maxWidth: '33%' }}>
                                <Label for="waist-size" label={t.translate('waistSize') + " (CM)"} />
                                <Input
                                    name="waist-size"
                                    type="number"
                                    value={waistSize}
                                    min={0}
                                    setValue={(e) => setWaistSize(e.currentTarget.valueAsNumber)}
                                />
                            </div>
                        </div>
                        <Button label={t.translate('next')} onClick={() => handleSaveData()} disabled={!isValidForm || isLoading} />
                    </Fragment>
                }
            </div>
        </div>
    )
}

export default ClaimKitFormUserInformation;