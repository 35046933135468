import img_serge_balon from 'assets/our_team/doctors/serge-balon.webp';
import img_jl_robert from 'assets/our_team/doctors/jean-luc-robert.webp';
import img_akeel_alisa from 'assets/our_team/doctors/akeel-alisa-rond.webp';
import img_robert_arlt from 'assets/our_team/doctors/robert-arlt-cercle.webp';
import img_jm_barbaste from 'assets/our_team/doctors/jean-michel-barbaste-cercle.webp';
import img_alex_nairi from 'assets/our_team/doctors/alexandre-nairi.webp';

import img_tim from 'assets/our_team/leaderships/tim.webp';
import img_philippe from 'assets/our_team/leaderships/philippe.webp';

import translationService from 'services/translation.service';

const team = {
    ourTeamList: [
        // SCIENTISTS
        {
            category: 'medical',
            name: "Dr Serge Balon-Perin",
            specialties: [
                translationService.translate('our-team.balon-perin.specialty1'),
                translationService.translate('our-team.balon-perin.specialty2')
            ],
            picture: img_serge_balon,
            description: translationService.translate('our-team.balon-perin.desc')
        },
        {
            category: 'medical',
            name: "Dr Jean-Luc Robert",
            specialties: [
                translationService.translate('our-team.robert.specialty1')
            ],
            picture: img_jl_robert,
            description: translationService.translate('our-team.robert.desc')
        },
        {
            category: 'medical',
            name: "Dr Alexandre Nairi",
            specialties: [
                translationService.translate('our-team.nairi.specialty1')
            ],
            picture: img_alex_nairi,
            description: translationService.translate('our-team.nairi.desc')
        },
        {
            category: 'medical',
            name: "Dr Akeel Alisa",
            specialties: [
                translationService.translate('our-team.alisa.specialty1')
            ],
            picture: img_akeel_alisa,
            description: translationService.translate('our-team.alisa.desc')
        },
        {
            category: 'medical',
            name: "Dr Robert Arlt",
            specialties: [
                translationService.translate('our-team.arlt.specialty1')
            ],
            picture: img_robert_arlt,
            description: translationService.translate('our-team.arlt.desc')
        },
        {
            category: 'medical',
            name: "Dr Jean-Michel Barbaste",
            specialties: [
                translationService.translate('our-team.barbaste.specialty1')
            ],
            picture: img_jm_barbaste,
            description: translationService.translate('our-team.barbaste.desc')
        },
        // LEADERSHIP
        {
            category: 'management',
            name: "Timothy Derville",
            specialties: [
                "General Manager"
            ],
            picture: img_tim,
            description: `BodyCheckup<br/>Corporate Healthcare<br/>www.bodycheckup.com<br/>+33(0)6.23.93.71.95<br/><br/>t.derville@bodycheckup.com`
        },
        {
            category: 'management',
            name: "Philippe Derville",
            specialties: [
                "General Manager"
            ],
            picture: img_philippe,
            description: `BodyCheckup<br/>Corporate Healthcare<br/>www.bodycheckup.com<br/>+44(0) 7958 082489`
        }
    ]
};

export default team;