import { Loader } from "components";
import { IDAppointment, IDAppointmentWithNutritionist } from "interfaces/Database";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import appointmentService from "services/appointment.service";
import doctorService from "services/doctor.service";
import "./DoctorNextAppointment.scss";

const DoctorNextAppointment: React.FC = () => {
    const t = useTranslation('layout/sidebar');
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ nextAppointment, setNextAppointment ] = useState<IDAppointment | IDAppointmentWithNutritionist>();

    const fetchNextAppointment = useCallback(() => {
        setIsLoading(true);

        Promise.all([
            doctorService.getDoctorNextAppointments()
                .catch((err) => console.warn(err)),
            doctorService.getNextNutritionistAppointment()
                .catch((err) => console.warn(err))
        ])
            .then((res) => {
                const nextAppointment = res[0] && res[0].length > 0 ? res[0][0] : null;
                const nextAppointmentWNutritionist = res[1] && res[1].length > 0 ? res[1][0] : null;

                if (nextAppointment && nextAppointmentWNutritionist) {
                    if (nextAppointment.appointmentOn && nextAppointmentWNutritionist.appointmentOn) {
                        setNextAppointment(nextAppointment.appointmentOn < nextAppointmentWNutritionist.appointmentOn ? nextAppointment : nextAppointmentWNutritionist);
                    } else if (!nextAppointment.appointmentOn && nextAppointmentWNutritionist.appointmentOn) {
                        setNextAppointment(nextAppointmentWNutritionist);
                    } else {
                        setNextAppointment(nextAppointment);
                    }
                } else if (nextAppointment && !nextAppointmentWNutritionist) {
                    setNextAppointment(nextAppointment);
                } else if (!nextAppointment && nextAppointmentWNutritionist) {
                    setNextAppointment(nextAppointmentWNutritionist);
                }
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchNextAppointment();
    }, [fetchNextAppointment]);

    const handleRedirectPatientDetail = (patientId?: number) => {
        if (patientId) {
            history.push(`/account/patient/${patientId}`);
        }
    }

    const getNextAppointmentDate = () => {
        return appointmentService.getFormattedDate(nextAppointment?.appointmentOn);
    }

    return (
        <div
            id="doctor-next-appointement"
            className={`flex-column ${nextAppointment ? 'clickable' : ''}`}
            onClick={nextAppointment
                ? () => handleRedirectPatientDetail(nextAppointment?.subscriptionPlan?.patient?.id)
                : undefined
            }
        >
            {isLoading
                ? <Loader isSmall color="var(--default-background-color)" />
                : nextAppointment
                    ? <Fragment>
                        <h4 className="MavenPro-bold" style={{ marginTop: 0 }}>{t.translate('nextAppointment.title')}</h4>
                        <div className="flex-row full-width text-align-center">
                            <p id="text-appointement">
                                {getNextAppointmentDate() + ' ' + t.translate('nextAppointment.with') + ' '}
                                <span
                                    className="sidebar-patient-name bold"
                                    onClick={() => handleRedirectPatientDetail(nextAppointment?.subscriptionPlan?.patient?.id)}
                                >
                                    {nextAppointment?.subscriptionPlan?.patient?.patientInfo?.firstname}
                                    {' '}
                                    {nextAppointment?.subscriptionPlan?.patient?.patientInfo?.lastname}
                                </span>
                            </p>
                        </div>
                    </Fragment>
                    : <p className="no-data" style={{ color: 'white' }}>{t.translate('nextAppointment.none')}</p>
            }
        </div>
    )
}

export default DoctorNextAppointment;