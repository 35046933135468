import { IDatabaseProductOrderType } from "interfaces/Database/IDatabaseProductOrderType";
import APIService from "./APIService";

class ProductOrderTypeService {
    private apiService = new APIService('/productOrder/type');

    public async getPlanType() {
        return await this.apiService.call<IDatabaseProductOrderType>('/plan', 'get');
    }

    public async getAppointmentType() {
        return await this.apiService.call<IDatabaseProductOrderType>('/appointment', 'get');
    }

    public async getComplementType() {
        return await this.apiService.call<IDatabaseProductOrderType>('/complement', 'get');
    }
}

function useProductOrderTypeService(): ProductOrderTypeService {
    return new ProductOrderTypeService();
}

export default useProductOrderTypeService;