import { IDatabaseCompanyAdministrator } from "interfaces/Database/IDatabaseCompanyAdministrator";
import { IDatabaseSubscriptionPlan } from "interfaces/Database/IDatabaseSubscriptionPlan";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import { IInvoiceByMonth } from "interfaces/IInvoiceByMonth";
import { IUserWithOnGoing } from "interfaces/IUserWithOnGoing";
import { IAddress } from "interfaces/User/IAddress";
import apiService from "./api.service";

interface getPaginatedContactsParams {
    page: number
    nbrOnPage: number
    orderBy?: 'createdOn'
    orderType?: 'ASC' | 'DESC'
}
interface getInvoicesByMonthParams {
    before: number
    after: number
}

interface updateGlobalInformationsParams {
    managerFirstName: string
    managerLastName: string
    phoneNumber: string
    countryCode: string
    email?: string
}

interface updateCompanyContactParams {
    contactId: number
    data: {
        firstname?: string
        lastname?: string
        email?: string
        currentPosition?: string
        countryCode?: string
        phoneNumber?: string
        makePrefered?: boolean
    }
}

interface createCompanyContactParams {
    firstname: string
    lastname: string
    email: string
    currentPosition: string
    countryCode: string
    phoneNumber: string
    makePreferred: boolean
}

class companyService {

    public getCompanyContacts = async () => {
        return await apiService.makeApiCall(`/api/getCompanyContacts`, 'get');
    }

    public getCompanyContactsPaginated = async (params: getPaginatedContactsParams) => {
        return await apiService.makeApiCall(`/api/company/getContactsPaginated?` +
                (params.page ? `page=${params.page}` : '') +
                (params.nbrOnPage ? `&nbrOnPage=${params.nbrOnPage}` : '') +
                (params.orderBy ? `&orderBy=${params.orderBy}` : '') +
                (params.orderType ? `&orderType=${params.orderType}` : ''), 'get')
            .then((res) => res as IDatabaseCompanyAdministrator[]);
    }
    
    public getCompanyContactsSummary = async () => {
        return await apiService.makeApiCall(`/api/company/getContactsSummary`, 'get')
            .then((res) => res as { total: number });
    }

    public updateCompanyContact = async (data: updateCompanyContactParams) => {
        return await apiService.makeApiCall(`/api/company/updateContact`, 'post', data);
    }

    public createCompanyContact = async (data: createCompanyContactParams) => {
        return await apiService.makeApiCall(`/api/company/createContact`, 'post', data);
    }

    public removeCompanyContact = async (contactId: number) => {
        return await apiService.makeApiCall(`/api/company/removeContact?contactId=${contactId}`, 'delete');
    }

    public getCompanyPreferredContact = async () => {
        return await apiService.makeApiCall<IDatabaseCompanyAdministrator>(`/api/company/preferredContact`, 'get');
    }

    public getCompanyEmployees = async () => {
        return await apiService.makeApiCall(`/api/company/getEmployees`, 'get');
    }

    public getCompanyAllEmployeesWithSolutions = async () => {
        return await apiService.makeApiCall<IUserWithOnGoing[]>(`/api/company/employees/includingOngoingPlan`, 'get');
    }

    public getCompanyPlansData = async () => {
        return await apiService.makeApiCall<{ given: IDatabaseSubscriptionPlan[], free: IDatabaseSubscriptionPlan[] }>(`/api/getCompanyPlanData`, 'get');
    }
    
    public getInvoices = async () => {
        return await apiService.makeApiCall(`/api/getInvoices`, 'get');
    }

    public getInvoicesByMonth = async (params?: getInvoicesByMonthParams) => {
        return await apiService.makeApiCall<IInvoiceByMonth[]>(`/api/getInvoicesByMonth?` +
                ((params && params.before) ? `before=${params.before}` : '') +
                ((params && params.after) ? `&after=${params.after}` : ''), 'get');
    }
    
    public getMonthlyInvoicePDF = async (month: number, year: number) => {
        return await apiService.makeApiCall(`/api/generateInvoiceForMonth`, 'post', { month: month, year: year });
    }

    public oneTimePayment = async (data: {
        solution_nbr: number,
        payment_type_id: number,
        product_order_type_id: number,
        plan_type_id: number,
        platform_id: number,
        renew: boolean
    }) => {
        return await apiService.makeApiCall<{ url: string }>(`/api/oneTimeCheckout`, 'post', data);
    }

    public createOrGetStripeCustomer = async () => {
        return await apiService.makeApiCall(`/api/createOrGetStripeCustomer`, 'post');
    }

    public getCompanyCurrentCard = async () => {
        return await apiService.makeApiCall(`/api/getCards`, 'get');
    }

    public assignCard = async (cardId: string) => {
        return await apiService.makeApiCall(`/api/assignCard`, 'post', { cardId: cardId });
    }

    public monthlyPayment = async (data: {
        solution_nbr: number,
        payment_type_id: number,
        product_order_type_id: number,
        plan_type_id: number,
        platform_id: number,
        renew: boolean,
        card_id: number
    }) => {
        return await apiService.makeApiCall(`/api/monthlyCheckout`, 'post', data);
    }

    public checkEmployee = async (email: string) => {
        return await apiService.makeApiCall(`/api/company/employees/emailUsed?email=${email}`, 'get');
    }

    public getSolutionResume = async () => {
        return apiService.makeApiCall(`/api/company/solutionResume`, 'get');
    }

    public createEmployee = async (data: {
        firstName: string
        lastName: string
        email: string
        kitId?: string | number
    }) => {
        return await apiService.makeApiCall('/api/company/employees', 'post', data);
    }

    public getEmployeesData = async () => {
        return await apiService.makeApiCall<{ notRegisteredEmployees: IDatabaseUser[], registeredEmployees: IDatabaseUser[] }>('/api/company/employees/getResume', 'get');
    }

    public updateGlobalInformations = async (newData: updateGlobalInformationsParams) => {
        return await apiService.makeApiCall(`/api/company/updateGlobalInformations`, 'post', newData);
    }

    public updateDetail = async (newData: {
        newBillingAddress: IAddress
        newShippingAddress: IAddress
    }) => {
        return await apiService.makeApiCall(`/api/company/updateDetails`, 'post', newData);
    }

    public getEmployeeOngoingPlan = async (employeeId: string) => {
        return await apiService.makeApiCall(`/api/company/employeeOnGoingPlan?employee_id=${employeeId}`, 'get');
    }

    public stopEmployeePlan = async (employeeId: string | number, planId: string | number) => {
        return await apiService.makeApiCall<string>(`/api/company/stopEmployeePlan?employee_id=${employeeId}&plan_id=${planId}`, 'delete');
    }

    public getAvailableEmployees = async () => {
        return await apiService.makeApiCall<IDatabaseUser[]>(`/api/company/getAvailableEmployee`, 'get');
    }

    public assignPlanEmployee = async (newData: { employeeId: string | number, kitId: string | number }) => {
        return await apiService.makeApiCall(`/api/company/assignEmployeeKit`, 'post', newData);
    }

    public getAddress = async () => {
        return await apiService.makeApiCall(`/api/company/getAddress`, 'get');
    }

    public checkSidebarStatus = async () => {
        return await apiService.makeApiCall(`/api/company/sidebarStatus`, 'get');
    }

    public async updatePreferredContact(data?: {
        contactFirstname?: string;
        contactLastname?: string;
        contactEmail?: string;
        contactCountryCode?: string;
        contactPhoneNumber?: string;
        contactPosition?: string;
    }) {
        return await apiService.makeApiCall(`/api/company/preferredContact`, 'post', data);
    }
}
export default new companyService();