import { IDAnalysisKit, IDAppointment, IDAppointmentWithNutritionist, IDInternalNote, IDSurvey } from "interfaces/Database";
import { IDatabaseDoctorInfo } from "interfaces/Database/IDatabaseDoctorInfo";
import User from "interfaces/Database/IDatabaseUser";
import { IPatientDetail } from "interfaces/IPatientDetail";
import 'moment-timezone';
import apiService from "./api.service";

class doctorService {
    public formatDoctorName(doctorInfo?: IDatabaseDoctorInfo) {
        if (doctorInfo) {
            return `Dr. ${doctorInfo.firstname} ${doctorInfo.lastname}`;
        } else {
            return undefined;
        }
    }

    public async updateInformations(data: {
        firstName?: string;
        lastName?: string;
        gender?: string;
        birthDate?: Date;
        clinic?: string;
        registrationTypeId?: number;
        registrationNumber?: string;
        languages?: string[];
        specialties?: string[];
        specialInformation?: string;
        phoneCode?: string;
        phoneNumber?: string;
    }) {
        return await apiService.makeApiCall(`/api/doctor/informations/update`, 'post', data);
    }

    public async getPatients(): Promise<User[]> {
        return await apiService.makeApiCall<User[]>(`/api/getPatients`, 'get').then((res) => res.map((e) => new User(e)));
    }

    public async getAllPatients() {
        return await apiService.makeApiCall<User[]>(`/api/getAllPatients`, 'get').then((res) => res.map((e) => new User(e)));
    }

    public async getPatientDetails(idPatient: string) {
        return await apiService.makeApiCall<IPatientDetail>(`/api/patient/${idPatient}/getDetails`, 'get');
    }

    public async getPatientResults(patientId: string) {
        return await apiService.makeApiCall<IDAnalysisKit[]>(`/api/doctor/patient/${patientId}/getResults`, 'get');
    }

    public async getPatientPrescriptions(idPatient: number) {
        return await apiService.get(`/api/getPrescriptionsByPatient/${idPatient}`);
    }

    public async getNextAppointments() {
        return await apiService.makeApiCall<(IDAppointment | IDAppointmentWithNutritionist)[]>(`/api/appointments/next/all`, 'get');
    }

    public async getDoctorNextAppointments() {
        return await apiService.makeApiCall<IDAppointment[]>(`/api/getDoctorNextAppointments`, 'get');
    }

    public async getNextNutritionistAppointment() {
        return await apiService.makeApiCall<IDAppointmentWithNutritionist[]>(`/api/appointmentsWNutritionist/next`, 'get');
    }

    public async getPreviousAppointments() {
        return await apiService.makeApiCall<IDAppointment[]>(`/api/doctor/appointment/previous`, 'get');
    }

    public async getPreviousAppointmentsWithNutritionist() {
        return await apiService.makeApiCall<IDAppointmentWithNutritionist[]>(`/api/doctor/appointmentsWNutritionist/previous`, 'get');
    }

    public async getPatientSurvey(idPatient: string|number) {
        return await apiService.makeApiCall<IDSurvey>(`/api/getPatientSurvey/${idPatient}`, 'get');
    }

    public async getInternalNotes(idPatient: string|number) {
        return await apiService.makeApiCall<IDInternalNote[]>(`/api/getInternalNotes/${idPatient}`, 'get');
    }

    public async deleteInternalNote(idNote: string|number) {
        return await apiService.makeApiCall(`/api/deleteInternalNote/${idNote}`, 'post');
    }

    public async createInternalNote(idPatient: string|number, data: { text: string }) {
        return await apiService.makeApiCall<IDInternalNote>(`/api/patient/${idPatient}/createInternalNote`, 'post', JSON.stringify(data));
    }

    public async updateInternalNote(idInternalNote: string|number, data: { text: string }) {
        return await apiService.makeApiCall<IDInternalNote>(`/api/updateInternalNote/${idInternalNote}`, 'post', JSON.stringify(data));
    }

    public async getPatientLastGeneralCommentPdf(patientId: number) {
        return await apiService.makeApiCall<string>(`/api/doctor/patient/${patientId}/generalComment/last/pdf`, 'get');
    }

    public async getPatientLastPrescriptionPdf(patientId: number) {
        return await apiService.makeApiCall<string>(`/api/doctor/patient/${patientId}/prescription/last/pdf`, 'get');
    }

    public async getPatientLastDietaryComplementPdf(patientId: number) {
        return await apiService.makeApiCall<string>(`/api/doctor/patient/${patientId}/dietaryComplement/last/pdf`, 'get');
    }

    public async getPatientLastPreventiveAdvicePdf(patientId: number) {
        return await apiService.makeApiCall<string>(`/api/doctor/patient/${patientId}/preventiveAdvice/last/pdf`, 'get');
    }

    public async getPatientLastFoodAdvicePdf(patientId: number) {
        return await apiService.makeApiCall<string>(`/api/doctor/patient/${patientId}/foodAdvice/last/pdf`, 'get');
    }

    public async getAnalysisKitResults(analysisKitId: number) {
        return await apiService.makeApiCall(`/api/doctor/analysisKit/${analysisKitId}/results`, 'get');
    }

    public async getSignature() {
        return await apiService.makeApiCall<string>(`/api/doctor/signature`, 'get');
    }

    public async updateSignature(newSignature: string) {
        return await apiService.makeApiCall(`/api/doctor/signature`, 'post', { signature: newSignature });
    }
}
export default new doctorService();