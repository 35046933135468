import { Button, CloseIcon, Input, Label, Loader } from 'components';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import { IDatabaseSubscriptionPlan } from 'interfaces/Database/IDatabaseSubscriptionPlan';
import { IUserWithOnGoing } from 'interfaces/IUserWithOnGoing';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import CompanyStopEmployeePlanModal from './CompanyStopEmployeePlanModal';

const CompanyShowEmployeeModal: React.FC<({
    isShow: boolean,
    toggleShow: (updateEmployees?: IUserWithOnGoing) => void,
    employee?: IUserWithOnGoing,
    canAllocateKit: boolean,
    toggleAllocate: () => void
})> = ({
    isShow,
    toggleShow,
    employee,
    canAllocateKit,
    toggleAllocate
}) => {
    const t = useTranslation('dashboard/company/employees')
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string|undefined>();
    const [ planInProgress, setPlanInProgress ] = useState<IDatabaseSubscriptionPlan>();

    const [ showStopPlan, setShowStopPlan ] = useState(false);

    const toggleShowStop = () => setShowStopPlan(!showStopPlan);

    const fetchData = async () => {
        setIsLoading(true);
        if (employee && employee.id) {
            if (employee.onGoing) {
                setPlanInProgress(employee.onGoing);
            }
        } else {
            setError(t.translate('showEmployee.errors.invalidUser'));
        }
        setTimeout(() => setIsLoading(false), 500);
    }

    useEffect(() => {
        setError(undefined);
        fetchData();
    }, [ employee ]);

    const reset = () => {
        setIsLoading(true);
        setPlanInProgress(undefined);
        setError(undefined);

        toggleShow();
    }

    const handleAttribute = () => {
        reset();

        toggleAllocate();
    }

    if (isShow && employee) {
        if (isLoading) {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <Loader />
                    </div>
                </div>
            )
        } else {
            return (
                <Fragment>
                    <div className='modal'>
                        <div className='modal-content'>
                            <div className='modal-content-header'>
                                <CloseIcon className='close' onClick={() => reset()} />
                                {planInProgress
                                    ? <Fragment>
                                        <h2 className="MavenPro-bold">{t.translate('showEmployee.title.solution')} #{planInProgress.id}</h2>
                                        <p style={{ margin: 0 }}>{t.translate('showEmployee.title.attributedAt')} {t.translateDate(planInProgress.allocatedOn)}</p>
                                    </Fragment>
                                    : <h2 className='MavenPro-bold'>{t.translate('showEmployee.title.none')}</h2>
                                }
                            </div>
                            <div className='modal-content-body'>
                                <div className='allocate-form'>
                                    <div className='allocate-form-fields'>
                                        <div className='allocate-form-fields-wrapper'>
                                            <Label for='employee-lastname' label={t.translate('showEmployee.lastname.label')} />
                                            <Input
                                                name='employee-lastname'
                                                value={employee?.patientInfo?.lastname}
                                                placeholder={t.translate('showEmployee.lastname.placeholder')}
                                                readOnly
                                            />
                                        </div>
                                        <div className='allocate-form-fields-wrapper'>
                                            <Label for='employee-firstname' label={t.translate('showEmployee.firstname.label')} />
                                            <Input
                                                name='employee-firstname'
                                                value={employee?.patientInfo?.firstname}
                                                placeholder={t.translate('showEmployee.firstname.placeholder')}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className='allocate-form-fields'>
                                        <div className='allocate-form-fields-wrapper'>
                                            <Label for='employee-email' label={t.translate('showEmployee.email.label')} />
                                            <Input
                                                name='employee-email'
                                                value={employee.email}
                                                placeholder={t.translate('showEmployee.email.placeholder')}
                                                readOnly
                                            />
                                        </div>
                                        <div className='allocate-form-fields-wrapper'>
                                            <Label for='employee-phone' label={t.translate('showEmployee.phone.label')} />
                                            <Input
                                                name='employee-phone'
                                                value={employee && employee.phone && employee.phone.number ? employee.phone.number : 'Not set'}
                                                placeholder={t.translate('showEmployee.phone.placeholder')}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className='allocate-form-fields'>
                                        {planInProgress 
                                            ? planInProgress.endedOn 
                                                ? <div className='flex-column'>
                                                    <div className='flex-column'>
                                                        <p>{t.translate('showEmployee.planDetail.startedAt')} {t.translateDate(planInProgress.startedOn)}</p>
                                                        <p>{t.translate('showEmployee.planDetail.earlyEnd')} {t.translateDate(planInProgress.endedOn)}</p>
                                                    </div>
                                                </div>
                                                : <div className='flex-column'>
                                                    <div className='flex-column'>
                                                        <p>{t.translate('showEmployee.planDetail.startedAt')} {t.translateDate(planInProgress.startedOn)}</p>
                                                        <p>{planInProgress.autoRenew ? t.translate('showEmployee.planDetail.renewedAt') : t.translate('showEmployee.planDetail.endedAt')} {t.translateDate(planInProgress.startedOn, { nextYear: true })}</p>
                                                    </div>
                                                    <Button label={t.translate('showEmployee.planDetail.stopTracking')} classname='dashboardBtn-dngr text-uppercase' onClick={() => toggleShowStop()} />
                                                </div>
                                            : <div className='flex-column'>()
                                                <p>{t.translate('showEmployee.planDetail.noSolution')}</p>
                                                {canAllocateKit
                                                    ? <Button label={t.translate('showEmployee.planDetail.allocateSolution')} onClick={() => handleAttribute()} />
                                                    : <Button label={t.translate('showEmployee.planDetail.buySolution')} onClick={() => history.push('/order')} />
                                                }
                                            </div>
                                        }
                                    </div>
                                    <ErrorMessage error={error} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <CompanyStopEmployeePlanModal isShow={showStopPlan} toggleShow={toggleShowStop} employee={employee} plan={planInProgress} fetchPlan={fetchData} closeParent={reset} />
                </Fragment>
            )
        }
    } else {
        return null;
    }
}

export default CompanyShowEmployeeModal;