import { IDatabaseCountry } from "./IDatabaseCountry"

export interface IDatabaseConsentForm {
    aptNumber: string
    birthdate: Date
    city: string
    country: IDatabaseCountry
    didAt: string
    didOn: Date
    firstname: string
    hasAcceptedCGU: boolean
    hasAcceptedCGV: boolean
    hasAcceptedDisclosure: boolean
    hasConsentToDoSamples: boolean
    id: number
    lastname: string
    postalCode: string
    streetAddress: string
}

export default class DatabaseConsentForm implements IDatabaseConsentForm {
    aptNumber: string;
    birthdate: Date;
    city: string;
    country: IDatabaseCountry;
    didAt: string;
    didOn: Date;
    firstname: string;
    hasAcceptedCGU: boolean;
    hasAcceptedCGV: boolean;
    hasAcceptedDisclosure: boolean;
    hasConsentToDoSamples: boolean;
    id: number;
    lastname: string;
    postalCode: string;
    streetAddress: string;
  
    constructor(data: IDatabaseConsentForm) {
        this.aptNumber = data.aptNumber;
        this.birthdate = new Date(data.birthdate);
        this.city = data.city;
        this.country = data.country;
        this.didAt = data.didAt;
        this.didOn = new Date(data.didOn);
        this.firstname = data.firstname;
        this.hasAcceptedCGU = data.hasAcceptedCGU;
        this.hasAcceptedCGV = data.hasAcceptedCGV;
        this.hasAcceptedDisclosure = data.hasAcceptedDisclosure;
        this.hasConsentToDoSamples = data.hasConsentToDoSamples;
        this.id = data.id;
        this.lastname = data.lastname;
        this.postalCode = data.postalCode;
        this.streetAddress = data.streetAddress;
    }

    public isValid(): boolean {
        return this.hasAcceptedCGU && this.hasAcceptedCGV;
    }
}