import { Button } from "components";
import { Modal } from "components/common/Modal";
import React, { Fragment, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import CustomLoader from "./CustomLoader";

const EditSignatureModal: React.FC<({
    isShow: boolean;
    toggleShow: () => void;
    handleSuccess: () => void;
})> = ({
    isShow,
    toggleShow,
    handleSuccess
}) => {
    const t = useTranslation('components/signature');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const canvaRef = useRef<any>(null);

    const handleValidate = () => {
        setIsLoading(true);

        const saveData = canvaRef?.current?.getSaveData();
        doctorService.updateSignature(canvaRef?.current?.getDataURL('png', false, "#ffffff"))
            .then(() => {
                toast.success(t.translate('update.success'));
                handleSuccess();
                toggleShow();
            })
            .catch((err) => {
                console.warn(err);
                canvaRef?.current?.loadSaveData(saveData, false);
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={isShow}
            toggleShow={toggleShow}
            title={t.translate('update.title')}
            footer={
                !isLoading && <div className="flex-row-end">
                    <Button label={t.translate('update.cancel')} onClick={toggleShow} />
                    <Button label={t.translate('update.validate')} onClick={handleValidate} />
                </div>
            }
        >
            {isLoading
                ? <CustomLoader />
                : <Fragment>
                    <CanvasDraw
                        ref={canvaRef}
                        hideGrid={true}
                        brushRadius={2}
                        brushColor={"#101010"}
                        canvasWidth={500}
                        canvasHeight={300}
                        style={{
                            border: '2px solid var(--dashboard-main-color)',
                            borderRadius: '5px',
                            marginBottom: '10px'
                        }}
                    />
                    <Button label={t.translate('update.reset')} onClick={() => canvaRef?.current?.clear()} />
                </Fragment>
            }
        </Modal>
    )
}

export default EditSignatureModal;