import DoctorNextAppointment from "components/common/DoctorNextAppointment/DoctorNextAppointment";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import { IMenuItem } from "interfaces/Pages/Layout/IMenuItems";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import React from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import DefaultSidebar from "./DefaultSidebar";

const DoctorSidebar: React.FC<({
    menuStatus?: IMenuStatus,
    user?: IDatabaseUser
})> = ({
    menuStatus,
    user
}) => {
    const t = useTranslation('layout/sidebar');

    const menuItems: IMenuItem[] = [
        {
            name: 'menuItems.doctor.dashboard.name',
            path: '/account/home',
            code: 'home',
            logout: false
        },
        {
            name: 'menuItems.doctor.appointments.name',
            path: '/account/appointments',
            code: 'appointments',
            logout: false
        },
        {
            name: 'menuItems.doctor.history.name',
            path: '/account/history',
            code: 'history',
            logout: false
        },
        {
            name: 'menuItems.doctor.patients.name',
            path: '/account/patient/list',
            code: 'patient',
            logout: false
        },
        {
            name: 'menuItems.doctor.messaging.name',
            path: '/account/messaging',
            code: 'messaging',
            logout: false
        },
        {
            name: 'menuItems.doctor.profil.name',
            path: '/account/profile',
            code: 'profile',
            logout: false
        },
        {
            name: 'menuItems.doctor.support.name',
            path: '/account/support',
            code: 'support',
            logout: false
        },
        {
            name: 'menuItems.doctor.logout.name',
            path: '/logout',
            code: 'logout',
            logout: true
        }
    ];

    return (
        <DefaultSidebar
            menuStatus={menuStatus}
            menuItems={menuItems}
            helloMessage={
                <p className="sidebar-title" style={{ maxWidth: '100%' }}>
                    {t.translate('header.hello')}<br/><span id="last-name">{doctorService.formatDoctorName(user?.doctorInfo)}</span>
                </p>
            }
            subMessage={<p id="subTitle">{t.translate('header.defaultSub')}</p>}
            button={<DoctorNextAppointment />}
        />
    );
}

export default DoctorSidebar;