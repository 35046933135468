import { IDNutritionalComment } from "interfaces/Database";
import apiService from "./api.service";

class NutritionalCommentService {
    public async getLatestUserNutritionalComment() {
        return await apiService.makeApiCall<IDNutritionalComment>(`/api/nutritionalComment/latest`, 'get');
    }

    public async getNutritionalCommentPDF(nutritionalCommentId: number) {
        return await apiService.makeApiCall<string>(`/api/nutritionalComment/${nutritionalCommentId}/pdf`, 'get');
    }

    public async createOrUpdate(data: {
        appointmentId: string
        nutritiousComment: string
        diets: string[]
    }) {
        return await apiService.makeApiCall(`/api/nutritionalComment/createOrUpdate`, 'post', data);
    }
}

export default new NutritionalCommentService();