import { Button, Input, Label, Loader, Phone, Radio } from 'components';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'services';
import companyService from 'services/company.service';
import verifierService from 'services/verifier.service';

const CompanyAddContact: React.FC<({
    isShow: boolean
    toggleShow: () => void
    refreshContacts: () => Promise<void>
})> = ({ isShow, toggleShow, refreshContacts }) => {
    const t = useTranslation('dashboard/company/profile');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ success, setSuccess ] = useState<string>();
    const [ error, setError ] = useState<string>();

    const [ lastName, setLastName ] = useState<string>();
    const [ firstName, setFirstName ] = useState<string>();
    const [ email, setEmail ] = useState<string>();
    const [ makePreferred, setMakePreferred ] = useState<boolean>(false);
    const [ currentPosition, setCurrentPosition ] = useState<string>();

    const [ countryCode, setCountryCode ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();

    useEffect(() => {
        if (isShow) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ isShow ]);

    const reset = async () => {
        setLastName(undefined);
        setFirstName(undefined);
        setEmail(undefined);
        setCountryCode(undefined);
        setPhoneNumber(undefined);
        setMakePreferred(false);

        if (success) setSuccess(undefined);

        toggleShow();
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setIsLoading(true);
        setError(undefined);

        if (firstName && lastName && email && currentPosition && countryCode && phoneNumber) {
            companyService.createCompanyContact({
                firstname: firstName,
                lastname: lastName,
                email: email,
                currentPosition: currentPosition,
                countryCode: countryCode,
                phoneNumber: phoneNumber,
                makePreferred: makePreferred
            })
                .then(() => {
                    setSuccess(t.translate('contacts.modal.success.add'));
                    refreshContacts();
                })
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }

    if (isShow) {
        if (isLoading) {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <h2 className="MavenPro-bold">{t.translate('contacts.modal.title.add')}</h2>
                        </div>
                        <div className='modal-content-body'>
                            <Loader />
                        </div>
                    </div>
                </div>
            )
        } else if (success) {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <AiOutlineClose className='close' onClick={() => reset()} />
                            <h2 className="MavenPro-bold">{t.translate('contacts.modal.title.add')}</h2>
                        </div>
                        <div className='modal-content-body'>
                            <p>{t.translate('contacts.modal.success.add')}</p>
                            <p>{t.translate('contacts.modal.success.second')}</p>
                            <Button label={t.translate('contacts.modal.success.btn')} onClick={() => reset()} disabled={!success} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <AiOutlineClose className='close' onClick={() => reset()} />
                            <h2 className="MavenPro-bold">{t.translate('contacts.modal.title.add')}</h2>
                        </div>
                        <div className='modal-content-body'>
                            <form className='allocate-form' onSubmit={(e) => handleSubmit(e)}>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='lastname' label={t.translate('contacts.modal.lastname')} required />
                                        <Input
                                            required
                                            name='lastname'
                                            disabled={isLoading}
                                            value={lastName}
                                            setValue={(e) => setLastName(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(lastName)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='firstname' label={t.translate('contacts.modal.firstname')} required />
                                        <Input
                                            required
                                            name='firstname'
                                            disabled={isLoading}
                                            value={firstName}
                                            setValue={(e) => setFirstName(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(firstName)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='email' label={t.translate('contacts.modal.email')} required />
                                        <Input
                                            required
                                            name='email'
                                            disabled={isLoading}
                                            type="email"
                                            value={email}
                                            setValue={(e) => setEmail(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkEmail(email)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='position' label={t.translate('contacts.modal.position')} required />
                                        <Input
                                            required
                                            name='position'
                                            disabled={isLoading}
                                            value={currentPosition}
                                            setValue={(e) => setCurrentPosition(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(currentPosition)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label name='phone' label={t.translate('contacts.modal.phone')} required />
                                        <Phone
                                            required
                                            labelledBy='phone'
                                            disabled={isLoading}
                                            defaultCountryCode={countryCode}
                                            setCountryCode={setCountryCode}
                                            defaultPhoneNumber={phoneNumber}
                                            setPhoneNumber={setPhoneNumber}
                                            checkFunction={() => verifierService.checkPhone(countryCode, phoneNumber)}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <label>{t.translate('contacts.modal.prefered.title')}</label>
                                        <div className='flex-row full-width space-evenly'>
                                            <div>
                                                <Radio
                                                    required
                                                    name='prefered'
                                                    value={true}
                                                    isChecked={makePreferred}
                                                    setChecked={() => setMakePreferred(true)}
                                                    label={
                                                        <Label for='prefered' label={t.translate('contacts.modal.prefered.yes')} />
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <Radio
                                                    required
                                                    name='prefered'
                                                    value={false}
                                                    isChecked={!makePreferred}
                                                    setChecked={() => setMakePreferred(false)}
                                                    label={
                                                        <Label for='prefered' label={t.translate('contacts.modal.prefered.no')}/>
                                                    }
                                                />
                                            </div>
                                        </div>
                                        { makePreferred && <p className='MavenPro allocate-form-sub'>*{t.translate('contacts.modal.prefered.warn')}</p> }
                                    </div>
                                </div>
                                <ErrorMessage error={error} />
                                <Button type='submit' label={t.translate('contacts.modal.btn')} disabled={isLoading} displayLoader={isLoading} />
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return null;
    }
}

export default CompanyAddContact;