import DoctorAppointment from "components/Appointment/components/Doctor/DoctorAppointment";
import PatientAppointment from "components/Appointment/components/Patient/PatientAppointment";
import RedirectUserPov from "components/common/RedirectUserPov/RedirectUserPov";
import React from "react";

const Appointment: React.FC = () => {
    return (
        <RedirectUserPov
            patientPov={<PatientAppointment />}
            standalonePov={<PatientAppointment />}
            doctorPov={<DoctorAppointment />}
        />
    );

}

export default Appointment;
