import { InformationWrapper } from "components";
import { IDatabaseAddress } from "interfaces/Database/IDatabaseAddress";
import React from 'react';
import { useTranslation } from 'services';

const DisplayAddress: React.FC<({
    address?: IDatabaseAddress
})> = ({
    address
}) => {
    const t = useTranslation('various/address');

    return address
        ? <div className="full-width flex-row flex-wrap" style={{ alignItems: 'stretch', rowGap: '2vh', columnGap: '2vw' }}>
            <InformationWrapper
                libelle={t.translate('number')}
                value={address?.aptNumber}
            />
            <InformationWrapper
                libelle={t.translate('road')}
                value={address?.streetAddress}
            />
            <InformationWrapper
                libelle={t.translate('postalCode')}
                value={address?.postalCode}
            />
            <InformationWrapper
                libelle={t.translate('city')}
                value={address?.city}
            />
            <InformationWrapper
                libelle={t.translate('country')}
                value={t.translateCountry(address?.country?.name)}
            />
        </div>
        : <p className='no-data'>{t.translate('not')}</p>
}

export default DisplayAddress;