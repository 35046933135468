import { EditIcon, InformationWrapper, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { EditProfilePicture } from "components/common/ProfilePicture";
import { UserContext } from "context/User/UserContext";
import moment from "moment-timezone";
import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "services";
import translationService from "services/translation.service";
import userAuthService from "services/user-auth.service";
import EditStandaloneProfileInfo from "../modals/EditStandaloneProfileInfo";

const StandaloneGeneralInformations: React.FC = () => {
    const t = useTranslation('profile/patient/myGeneralInformation');
    const { user, setUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);

    const refreshUser = () => {
        setIsLoading(true);
        setShowModal(false);

        userAuthService.getUser()
            .then((res) => setUser(res))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    const getFormatedBirthDate = () => {
        if (user?.patientInfo?.birthDate) {
            return moment(new Date(user.patientInfo.birthDate)).format('DD/MM/YYYY');
        } else {
            return undefined;
        }
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold">{t.translate("title")}</h1>
                    <EditIcon
                        className="clickable"
                        onClick={() => setShowModal(true)}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    {isLoading
                        ? <Loader />
                        : <div className="flex-row" id="general-information-wrapper">
                            <div id="profile-picture-container">
                                <EditProfilePicture />
                            </div>
                            <div id="list-information-wrapper">
                                <InformationWrapper
                                    libelle={t.translate("name")}
                                    value={`${user?.patientInfo?.firstname} ${user?.patientInfo?.lastname}`}
                                />
                                <InformationWrapper
                                    libelle={t.translate("birthdate")}
                                    value={getFormatedBirthDate()}
                                />
                                <InformationWrapper
                                    libelle={t.translate("genre")}
                                    value={user?.patientInfo?.gender
                                        ? translationService.translateGender(user.patientInfo.gender.name)
                                        : undefined
                                    }
                                />
                            </div>
                        </div>
                    }
                    {user
                        ? <EditStandaloneProfileInfo
                            showModal={showModal}
                            handleClose={() => setShowModal(false)}
                            handleSuccess={() => refreshUser()}
                            currentUser={user}
                        />
                        : null
                    }
                </Fragment>
            }
        />
    )
}

export default StandaloneGeneralInformations;