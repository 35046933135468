import { Button, Loader } from "components";
import CustomErrorContainer from "components/common/CustomMessageContainer/CustomErrorContainer";
import CustomSuccessContainer from "components/common/CustomMessageContainer/CustomSuccessContainer";
import CustomToggleSwitch from "components/common/CustomToggleSwitch/CustomToggleSwitch";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import { IDatabaseUserParameters } from "interfaces/Database/IDatabaseUserParameters";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";

import 'style/components/Parameters.scss';

const StandaloneParameters: React.FC = () => {
    const t = useTranslation('dashboard/common/UserParameters');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    
    const [ updateSuccess, setUpdateSuccess ] = useState<boolean>(false);
    const [ updateError, setUpdateError ] = useState<boolean>(false);
    const [ confirmModal, setConfirmModal ] = useState<boolean>(false);
    
    const toggleUpdateSuccess = () => setUpdateSuccess(!updateSuccess);
    const toggleUpdateError = () => setUpdateError(!updateError);
    const toggleConfirmModal = () => setConfirmModal(!confirmModal);

    const [ actualParams, setActualParams ] = useState<IDatabaseUserParameters>();

    const [ sub_newsletter, setIsSubNewsletter ] = useState<boolean>(false);
    const [ allow_cookies, setIsAllowCookies ] = useState<boolean>(false);
    const [ functional_mails, setIsAllowFunctionalMails ] = useState<boolean>(false);
    const [ appointment_mails, setIsAllowAppointmentMails ] = useState<boolean>(false);
    const [ delivery_mails, setIsAllowDeliveryMails ] = useState<boolean>(false);
    const [ messaging_mails, setIsAllowMessagingMails ] = useState<boolean>(false);
    const [ confirmation_mails, setIsAllowConfirmationMails ] = useState<boolean>(false);

    const fetchParameters = useCallback(() => {
        setIsLoading(true);

        userAuthService.getUserParameters()
            .then((res) => {
                // backup actual parameters to avoid useless request later
                setActualParams(res);

                // set current parameters as base parameters for switches
                setIsAllowFunctionalMails(res.functional_mails || false);
                setIsAllowMessagingMails(res.messaging_mails || false);
                setIsAllowAppointmentMails(res.appointment_mails || false);
                setIsAllowDeliveryMails(res.delivery_mails || false);
                setIsAllowMessagingMails(res.messaging_mails || false);
                setIsAllowConfirmationMails(res.confirmation_mails || false);
                setIsSubNewsletter(res.sub_newsletter || false);
                setIsAllowCookies(res.allow_cookies || false);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchParameters();
    }, [fetchParameters]);

    const hasParamChanged = () => {
        const newParams: IDatabaseUserParameters = {
            allow_cookies: allow_cookies,
            appointment_mails: appointment_mails,
            confirmation_mails: confirmation_mails,
            delivery_mails: delivery_mails,
            functional_mails: functional_mails,
            messaging_mails: messaging_mails,
            sub_newsletter: sub_newsletter
        }

        return !isEqual(actualParams, newParams);
    }

    const submitNewParameters = async () => {
        setIsLoading(true);

        const newParams: IDatabaseUserParameters = {
            allow_cookies: allow_cookies,
            appointment_mails: appointment_mails,
            confirmation_mails: confirmation_mails,
            delivery_mails: delivery_mails,
            functional_mails: functional_mails,
            messaging_mails: messaging_mails,
            sub_newsletter: sub_newsletter
        }

        userAuthService.updateUserParameters(newParams)
            .then(() => {
                fetchParameters();
                toast.success(t.translate('update.success'), { containerId: 'parameters_update_success' });
            })
            .catch(() => toggleUpdateError())
            .finally(() => setIsLoading(false));
    }
    
    return (
        <DefaultContainer
            title={<h2 className='bold'>{t.translate('title')}</h2>}
            body={isLoading
                ? <Loader />
                : <div className='flex-column'>
                    <CustomSuccessContainer isShow={updateSuccess} close={toggleUpdateSuccess} text={t.translate('update.success')} />
                    <CustomErrorContainer isShow={updateError} close={toggleUpdateError} text={t.translate('update.error')} />
                    <div className='flex-column' style={{ alignItems: 'stretch' }}>
                        <div className='parameter-wrapper'>
                            <h4>{t.translate('newsletter')} :</h4>
                            <CustomToggleSwitch name={"newsletter"} isChecked={sub_newsletter} setIsChecked={setIsSubNewsletter} />
                        </div>
                        <div className='parameter-wrapper'>
                            <h4>{t.translate('cookies')} :</h4>
                            <CustomToggleSwitch name={"allow_cookies"} isChecked={allow_cookies} setIsChecked={setIsAllowCookies} />
                        </div>
                    </div>
                    {hasParamChanged() &&
                        <Button label={t.translate('update.btn')} onClick={() => toggleConfirmModal()} style={{ margin: '20px 0 0 0' }} />
                    }
                    <ModalConfirmAction
                        showModal={confirmModal}
                        handleClose={toggleConfirmModal}
                        confirmAction={submitNewParameters}
                        description={t.translate('update.confirm')}
                    />
                </div>
            }
        />
    )
}

export default StandaloneParameters;