import { Button } from 'components';
import { IStandaloneSidebarActions } from 'interfaces/IStandaloneSidebarActions';
import { IMenuItem } from 'interfaces/Pages/Layout/IMenuItems';
import SubscriptionTrackingDetail from 'interfaces/SubscriptionProcessing/ISubscriptionTrackingDetail';
import React, { Fragment } from 'react';
import { ITranslationService } from './translation2.service';

class UserService {
    public handleStandaloneSidebar(
        t: ITranslationService,
        history: any,
        sidebarActions?: IStandaloneSidebarActions,
        solutionProcessing?: SubscriptionTrackingDetail,
    ): React.ReactNode {
        if (((sidebarActions?.solutionNumber ?? 0) > 0) && !solutionProcessing?.subscriptionPlan) {
            return <Button label={t.translate('actions.registerSolution')} onClick={() => history.push("/account/kit/register-your-kit")} />
        }

        if (solutionProcessing?.subscriptionPlan && !solutionProcessing?.parcelToPatient?.isDelivered()) {
            return <Fragment>
                <h3 className="text-align-center" style={{ marginBottom: '5px' }}>{t.translate('actions.kitOnItsWay')}</h3>
                {solutionProcessing?.parcelToPatient?.trackingNumber && <p className="text-align-center clickable text-main-color" style={{ marginTop: '0' }} onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${solutionProcessing.parcelToPatient?.trackingNumber}`, '_blank')}>{t.translate('actions.trackIt')}<br/>{solutionProcessing.parcelToPatient?.trackingNumber}</p>}
            </Fragment>
        }

        if (solutionProcessing?.parcelToPatient?.isDelivered() && !solutionProcessing.analysisKit?.activated) {
            return <Button label={t.translate('actions.activateKit')} onClick={() => history.push("/account/kit/register-your-kit")} />
        }

        if (solutionProcessing?.analysisKit?.activated && (!solutionProcessing.consentForm || !solutionProcessing.consentForm.isValid())) {
            return <Button label={t.translate('actions.fillConsentForm')} onClick={() => history.push("/account/kit/consent-form")} />
        }

        if (
            solutionProcessing?.parcelToPatient?.isDelivered() &&
            !solutionProcessing?.parcelToLaboratory?.isDelivered()
        ) {
            return <Fragment>
                <h3 className="text-align-center" style={{ marginBottom: '5px' }}>{t.translate('actions.waitingLabDelivery')}</h3>
                {(solutionProcessing?.parcelToLaboratory?.trackingNumber && solutionProcessing?.analysisKit?.activated) && <p className="text-align-center clickable text-main-color" style={{ marginTop: '0' }} onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${solutionProcessing.parcelToLaboratory?.trackingNumber}`, '_blank')}>{t.translate('actions.trackIt')}<br/>{solutionProcessing.parcelToLaboratory?.trackingNumber}</p>}
                {!solutionProcessing.subscriptionPlan?.isTypeCancer() && !solutionProcessing.survey?.isComplete
                    && <Button label={t.translate('actions.answerSurvey')} onClick={() => history.push("/account/kit/questionnaire")} />
                }
            </Fragment>
        }

        if (
            solutionProcessing?.parcelToLaboratory?.isDelivered() &&
            !solutionProcessing?.analysisKit?.areResultsAvailable()
        ) {
            return <Fragment>
                <h3 className="text-align-center" style={{ marginBottom: '5px' }}>{t.translate('actions.waitingAnalyses')}</h3>
                {!solutionProcessing.subscriptionPlan?.isTypeCancer() && !solutionProcessing.survey?.isComplete
                    && <Button label={t.translate('actions.answerSurvey')} onClick={() => history.push("/account/kit/questionnaire")} />
                }
            </Fragment>
        }

        if (solutionProcessing?.subscriptionPlan?.type?.name !== 'cancer_check') {
            if (
                solutionProcessing?.parcelToLaboratory?.isDelivered() &&
                solutionProcessing?.analysisKit?.areResultsAvailable()
            ) {
                if (
                    solutionProcessing?.doctorAppointment?.isNotBooked() &&
                    solutionProcessing?.appointmentWNutritionist?.isNotBooked()
                ) {
                    return <Button label={t.translate('actions.bookAppointments')} onClick={() => history.push("/account/appointments")} />
                } else if (
                    !solutionProcessing?.doctorAppointment?.isNotBooked() &&
                    solutionProcessing?.appointmentWNutritionist?.isNotBooked()
                ) {
                    return <Button label={t.translate('actions.bookAppointmentWNutritionist')} onClick={() => history.push("/account/appointments")} />
                } else if (
                    solutionProcessing?.doctorAppointment?.isNotBooked() &&
                    !solutionProcessing?.appointmentWNutritionist?.isNotBooked()
                ) {
                    return <Button label={t.translate('actions.bookAppointmentWDoctor')} onClick={() => history.push("/account/appointments")} />
                }
            }
    
            if (solutionProcessing?.doctorAppointment && solutionProcessing?.appointmentWNutritionist) {
                if (
                    solutionProcessing?.doctorAppointment.appointmentOn &&
                    solutionProcessing.appointmentWNutritionist.appointmentOn
                ) {
                    if (
                        solutionProcessing?.doctorAppointment?.isDone() &&
                        solutionProcessing?.appointmentWNutritionist?.isDone()
                    ) {
                        return (
                            <Fragment>
                                <Button label={t.translate('actions.showRecommendations')} onClick={() => history.push("/account/recommendations")} />
                                <div style={{ margin: '5px 0' }} />
                                {((sidebarActions?.solutionNumber ?? 0) > 0)
                                    ? <Button label={t.translate('actions.restart')} onClick={() => history.push('/account/kit/register-your-kit')} />
                                    : <Button label={t.translate('actions.orderSolution')} onClick={() => history.push('/order')} disabled={!sidebarActions?.canOrderKit} />
                                }
                            </Fragment>
                        )
                    }

                    // If appointment with doctor is booked before appointment with nutritionist
                    if (solutionProcessing?.doctorAppointment.appointmentOn < solutionProcessing.appointmentWNutritionist.appointmentOn) {
                        return <div className="full-width flex-column">
                            <p className="text-align-center" style={{ marginTop: 0 }}>{t.translate('actions.bookedOn')}<br />{t.translateDate(solutionProcessing.doctorAppointment.appointmentOn, { dayName: true, hours: true, minutes: true })}</p>
                            <Button label={t.translate('actions.seeAppointment')} onClick={() => history.push("/account/appointments")} />
                        </div>
                    } else {
                        return <div className="full-width flex-column">
                            <p className="text-align-center" style={{ marginTop: 0 }}>{t.translate('actions.nutritionistBookedOn')}<br />{t.translateDate(solutionProcessing.appointmentWNutritionist.appointmentOn, { dayName: true, hours: true, minutes: true })}</p>
                            <Button label={t.translate('actions.seeAppointment')} onClick={() => history.push("/account/appointments")} />
                        </div>
                    }
                }
            } else if (solutionProcessing?.doctorAppointment && !solutionProcessing?.appointmentWNutritionist) {
                if (
                    solutionProcessing?.doctorAppointment?.isBooked() &&
                    !solutionProcessing?.doctorAppointment?.isDone()
                ) {
                    return <div className="full-width flex-column">
                        <p className="text-align-center" style={{ marginTop: 0 }}>{t.translate('actions.bookedOn')}<br />{t.translateDate(solutionProcessing.doctorAppointment.appointmentOn, { dayName: true, hours: true, minutes: true })}</p>
                        <Button label={t.translate('actions.seeAppointment')} onClick={() => history.push("/account/appointments")} />
                    </div>
                }
        
                if (solutionProcessing?.doctorAppointment?.isDone()) {
                    return (
                        <Fragment>
                            <Button label={t.translate('actions.showRecommendations')} onClick={() => history.push("/account/recommendations")} />
                            <div style={{ margin: '5px 0' }} />
                            {((sidebarActions?.solutionNumber ?? 0) > 0)
                                ? <Button label={t.translate('actions.restart')} onClick={() => history.push('/account/kit/register-your-kit')} />
                                : <Button label={t.translate('actions.orderSolution')} onClick={() => history.push('/order')} disabled={!sidebarActions?.canOrderKit} />
                            }
                        </Fragment>
                    )
                }
            } else if (!solutionProcessing?.doctorAppointment && solutionProcessing?.appointmentWNutritionist) {
                if (
                    solutionProcessing?.appointmentWNutritionist?.isBooked() &&
                    !solutionProcessing?.appointmentWNutritionist?.isDone()
                ) {
                    return <div className="full-width flex-column">
                        <p className="text-align-center" style={{ marginTop: 0 }}>{t.translate('actions.nutritionistBookedOn')}<br />{t.translateDate(solutionProcessing.appointmentWNutritionist.appointmentOn, { dayName: true, hours: true, minutes: true })}</p>
                        <Button label={t.translate('actions.seeAppointment')} onClick={() => history.push("/account/appointments")} />
                    </div>
                }
        
                if (solutionProcessing?.appointmentWNutritionist?.isDone()) {
                    return (
                        <Fragment>
                            <Button label={t.translate('actions.showRecommendations')} onClick={() => history.push("/account/recommendations")} />
                            <div style={{ margin: '5px 0' }} />
                            {((sidebarActions?.solutionNumber ?? 0) > 0)
                                ? <Button label={t.translate('actions.restart')} onClick={() => history.push('/account/kit/register-your-kit')} />
                                : <Button label={t.translate('actions.orderSolution')} onClick={() => history.push('/order')} disabled={!sidebarActions?.canOrderKit} />
                            }
                        </Fragment>
                    )
                }
            }
        }

        return <Button label={t.translate('actions.orderSolution')} onClick={() => history.push('/order')} disabled={!sidebarActions?.canOrderKit} />
    }

    public handlePatientSidebarButton(
        t: ITranslationService,
        history: any,
        disableMenuBtn: boolean,
        solutionProcessing?: SubscriptionTrackingDetail,
    ) {
        if (solutionProcessing?.subscriptionPlan && !solutionProcessing?.analysisKit) {
            return <Button label={t.translate('actions.registerSolution')} onClick={() => history.push("/account/kit/register-your-kit")} />
        }

        if (solutionProcessing?.subscriptionPlan && !solutionProcessing?.parcelToPatient?.isDelivered()) {
            return <Fragment>
                <h3 className="text-align-center" style={{ marginBottom: '5px' }}>{t.translate('actions.kitOnItsWay')}</h3>
                {solutionProcessing?.parcelToPatient?.trackingNumber && <p className="text-align-center clickable text-main-color" style={{ marginTop: '0' }} onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${solutionProcessing.parcelToPatient?.trackingNumber}`, '_blank')}>{t.translate('actions.trackIt')}<br/>{solutionProcessing.parcelToPatient?.trackingNumber}</p>}
            </Fragment>
        }

        if (solutionProcessing?.parcelToPatient?.isDelivered() && !solutionProcessing.analysisKit?.activated) {
            return <Button label={t.translate('actions.activateKit')} onClick={() => history.push("/account/kit/register-your-kit")} />
        }

        if (solutionProcessing?.analysisKit?.activated && (!solutionProcessing.consentForm || !solutionProcessing.consentForm.isValid())) {
            return <Button label={t.translate('actions.fillConsentForm')} onClick={() => history.push("/account/kit/consent-form")} />
        }

        if (
            solutionProcessing?.parcelToPatient?.isDelivered() &&
            !solutionProcessing?.parcelToLaboratory?.isDelivered()
        ) {
            return <Fragment>
                <h3 className="text-align-center" style={{ marginBottom: '5px' }}>{t.translate('actions.waitingLabDelivery')}</h3>
                {(solutionProcessing?.parcelToLaboratory?.trackingNumber && solutionProcessing?.analysisKit?.activated) && <p className="text-align-center clickable text-main-color" style={{ marginTop: '0' }} onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${solutionProcessing.parcelToLaboratory?.trackingNumber}`, '_blank')}>{t.translate('actions.trackIt')}<br/>{solutionProcessing.parcelToLaboratory?.trackingNumber}</p>}
                {!solutionProcessing.subscriptionPlan?.isTypeCancer() && !solutionProcessing.survey?.isComplete
                    && <Button label={t.translate('actions.answerSurvey')} onClick={() => history.push("/account/kit/questionnaire")} />
                }
            </Fragment>
        }

        if (
            solutionProcessing?.parcelToLaboratory?.isDelivered() &&
            !solutionProcessing?.analysisKit?.areResultsAvailable()
        ) {
            return <Fragment>
                <h3 className="text-align-center" style={{ marginBottom: '5px' }}>{t.translate('actions.waitingAnalyses')}</h3>
                {!solutionProcessing.subscriptionPlan?.isTypeCancer() && !solutionProcessing.survey?.isComplete
                    && <Button label={t.translate('actions.answerSurvey')} onClick={() => history.push("/account/kit/questionnaire")} />
                }
            </Fragment>
        }

        if (!solutionProcessing?.isPlanCancerDetection()) {
            if (
                solutionProcessing?.parcelToLaboratory?.isDelivered() &&
                solutionProcessing?.analysisKit?.areResultsAvailable()
            ) {
                if (
                    solutionProcessing?.doctorAppointment?.isNotBooked() &&
                    solutionProcessing?.appointmentWNutritionist?.isNotBooked()
                ) {
                    return <Button label={t.translate('actions.bookAppointments')} onClick={() => history.push("/account/appointments")} />
                } else if (
                    !solutionProcessing?.doctorAppointment?.isNotBooked() &&
                    solutionProcessing?.appointmentWNutritionist?.isNotBooked()
                ) {
                    return <Button label={t.translate('actions.bookAppointmentWNutritionist')} onClick={() => history.push("/account/appointments")} />
                } else if (
                    solutionProcessing?.doctorAppointment?.isNotBooked() &&
                    !solutionProcessing?.appointmentWNutritionist?.isNotBooked()
                ) {
                    return <Button label={t.translate('actions.bookAppointmentWDoctor')} onClick={() => history.push("/account/appointments")} />
                }
            }
    
            if (solutionProcessing?.doctorAppointment || solutionProcessing?.appointmentWNutritionist) {
                if (
                    solutionProcessing?.doctorAppointment?.isBooked() &&
                    !solutionProcessing?.doctorAppointment?.isDone()
                ) {
                    return <div className="full-width flex-column">
                        <p className="text-align-center" style={{ marginTop: 0 }}>{t.translate('actions.bookedOn')}<br />{t.translateDate(solutionProcessing.doctorAppointment.appointmentOn, { dayName: true, hours: true, minutes: true })}</p>
                        <Button label={t.translate('actions.seeAppointment')} onClick={() => history.push("/account/appointments")} />
                    </div>
                }

                if (solutionProcessing?.doctorAppointment?.isDone()) {
                    return (
                        <Fragment>
                            <Button label={t.translate('actions.showRecommendations')} onClick={() => history.push("/account/recommendations")} />
                            <div style={{ margin: '5px 0' }} />
                            {!solutionProcessing.analysisKit?.activated
                                ? <Button label={t.translate('actions.restart')} onClick={() => history.push('/account/kit/register-your-kit')} />
                                : <p>{t.translate('actions.restart')}</p>
                            }
                        </Fragment>
                    )
                }
            } else if (
                solutionProcessing?.parcelToLaboratory?.isDelivered() &&
                solutionProcessing?.analysisKit?.areResultsAvailable()
            ) {
                return (
                    <Fragment>
                        <Button label={t.translate('actions.showRecommendations')} onClick={() => history.push("/account/recommendations")} />
                        <div style={{ margin: '5px 0' }} />
                        {!solutionProcessing?.analysisKit?.activated
                            ? <Button label={t.translate('actions.restart')} onClick={() => history.push('/account/kit/register-your-kit')} />
                            : <p>{t.translate('actions.restart')}</p>
                        }
                    </Fragment>
                )
            }
        }

        return <Button label={t.translate('action.registerKit')} onClick={() => history.push("/account/kit/register-your-kit")} disabled={disableMenuBtn} />;
    }

    public handleStandaloneMenuItems(solutionProcessing?: SubscriptionTrackingDetail): IMenuItem[] {
        const baseMenuItems: IMenuItem[] = [
            {
                name: 'menuItems.standalone.dashboard.name',
                path: '/account/home',
                code: 'home',
                logout: false
            },
            {
                name: 'menuItems.standalone.tests.name',
                path: '/account/tests',
                code: 'tests',
                logout: false
            }
        ];

        const extendedMenuItems: IMenuItem[] = [
            {
                name: 'menuItems.standalone.prescriptions.name',
                path: '/account/prescription',
                code: 'prescription',
                logout: false
            },
            {
                name: 'menuItems.standalone.appointments.name',
                path: '/account/appointments',
                code: 'appointments',
                logout: false
            },
            {
                name: 'menuItems.standalone.recommendations.name',
                path: '/account/recommendations',
                code: 'recommendations',
                logout: false
            }
        ]

        const globalMenuItems: IMenuItem[] = [
            {
                name: 'menuItems.standalone.invoicing.name',
                path: '/account/invoicing',
                code: 'invoicing',
                logout: false
            },
            {
                name: 'menuItems.standalone.messaging.name',
                path: '/account/messaging',
                code: 'messaging',
                logout: false
            },
            {
                name: 'menuItems.standalone.profil.name',
                path: '/account/profile',
                code: 'profile',
                logout: false
            },
            {
                name: 'menuItems.standalone.support.name',
                path: '/account/support',
                code: 'support',
                logout: false
            },
            {
                name: 'menuItems.standalone.logout.name',
                path: '/logout',
                code: 'logout',
                logout: true
            }
        ]
        
        let final = [
            ...baseMenuItems,
            ...extendedMenuItems,
            ...globalMenuItems
        ];

        if (solutionProcessing?.isPlanCancerDetection()) {
            final = [
                ...baseMenuItems,
                ...globalMenuItems
            ];
        }

        if (
            (
                solutionProcessing?.isPlanMicrobiotaOnly() ||
                solutionProcessing?.isPlanMicrobiotaStressBurnout() ||
                solutionProcessing?.isPlanStressBurnout()
            ) &&
            !solutionProcessing.areDoctorOrNutritionistAppointments()
        ) {
            final = final.filter((item) => item.code !== 'prescription');
        }
        
        return final;
    }
}

export default new UserService();