export interface ICartItem<T = unknown> {
    item: T,
    quantity?: number
}

export class CartItem<T extends { id: string | number, price: number }> implements ICartItem {
    item: T;
    quantity: number;

    constructor(data: ICartItem) {
        this.item = data.item as T;
        this.quantity = data.quantity ?? 1;
    }

    public setQuantity(newQuantity: number): this {
        this.quantity = newQuantity;

        return this;
    }

    public increaseQuantity(): this {
        this.quantity = this.quantity+1;

        return this;
    }

    public decreaseQuantity(): this {
        this.quantity = this.quantity-1;

        return this;
    }

    public getTotal(): number {
        return this.quantity * this.item.price;
    }
}