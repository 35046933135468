import { Button, TextArea } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from "components/common/Modal";
import { IDatabaseInternalNote } from 'interfaces/Database/IDatabaseInternalNote';
import React, { useEffect, useState } from "react";
import { useInternalNoteService, useTranslation } from "services";

const ModalUpdateInternalNote: React.FC<({
    note?: IDatabaseInternalNote
    showModal: boolean
    handleClose: () => void,
    handleSuccess: () => void
})> = ({
    note,
    showModal,
    handleClose,
    handleSuccess
}) => {
    const t = useTranslation('patientDetail');
    const internalNoteService = useInternalNoteService();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ text, setText ] = useState<string>();

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    const handleConfirm = () => {
        if (note && note.id) {
            if (text) {
                setError(undefined);
                setIsLoading(true);

                internalNoteService.update(note.id, { note: text })
                    .then(() => {
                        handleSuccess();
                        handleClose();
                    })
                    .catch((err) => setError(err.message))
                    .finally(() => setIsLoading(false));
            } else {
                setError('missing_note');
            }
        } else {
            setError('internal_note_not_found')
        }
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={handleClose}
            title={t.translate('modals.modify.title')}
            footer={
                <div className="flex-row-end">
                    <Button
                        label={t.translate('modals.cancel')}
                        onClick={handleClose}
                        disabled={isLoading}
                    />
                    <Button
                        label={t.translate('modals.confirm')}
                        onClick={handleConfirm}
                        disabled={isLoading}
                        displayLoader={isLoading}
                    />
                </div>
            }
        >
            <TextArea
                required
                name="update-internal-note"
                disabled={isLoading}
                placeholder={note?.text ?? t.translate('modals.create.placeholder')}
                defaultValue={note?.text}
                value={text}
                setValue={(e) => setText(e.currentTarget.value)}
            />

            <ErrorMessage error={error} />
        </Modal>
    )
}

export default ModalUpdateInternalNote;