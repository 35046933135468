import notFound from 'assets/not_found.svg';
import { Button } from 'components';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";

const NotFound: React.FC = () => {
    const t = useTranslation('pages/notFound');
    const history = useHistory();
    const [ isInAccount, setIsInAccount ] = useState<boolean>(false);

    useEffect(() => {
        setIsInAccount(/\/account/gmi.test(window.location.href));
    }, []);

    return (
        <div className="full-height flex-column" style={{ boxSizing: 'border-box', margin: 'auto', maxWidth: '600px', minHeight: isInAccount ? '70vh' : '', padding: isInAccount ? '' : '10vh 0' }}>
            <img src={notFound} width={300} alt="" />
            <h3>{t.translate('title')}</h3>
            <p className="text-align-center">{t.translate('body')}</p>
            <div className="full-width flex-row">
                <Button label={t.translate('contact')} onClick={() => history.push('/contact-us')} />
                {isInAccount
                    ? <Button label={t.translate('dashboard')} onClick={() => history.push('/account/home')} />
                    : <Button label={t.translate('landing')} onClick={() => history.push('/')} />
                }
            </div>
        </div>
    );
}

export default NotFound;