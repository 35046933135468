import { IDatabaseLanguage } from "interfaces/Database/IDatabaseLanguage";
import apiService from "./api.service";
import { IUserLanguage } from "interfaces/User/IUserLanguage";
import languages from "context/Language/Languages";

class LanguageService {
    private readonly lngItem = 'bcu-language';

    public getLSLanguage = (): IUserLanguage => {
        const languageCountryCode = localStorage.getItem(this.lngItem);

        let language = languages.filter((lng) => lng.countryCode === languageCountryCode)[0];

        if (!language) {
            language = languages.filter((lng) => lng.countryCode === 'fr')[0];
        }

        return language;
    }

    public saveLSLanguage = (languageCode: IUserLanguage) => {
        localStorage.setItem(this.lngItem, languageCode.countryCode);
        window.dispatchEvent(new CustomEvent('storage', { detail: { newLng: languageCode.countryCode } }));
    }

    public saveCurrentLanguage = async (languageCode: string) => {
        return await apiService.makeApiCall(`/api/user/language`, 'post', { newLanguage: languageCode })
            .catch((err) => console.warn(err.message));
    }

    public getCurrentLanguage = async () => {
        return await apiService.makeApiCall(`/api/user/language`, 'get')
            .then((res) => res as IDatabaseLanguage);
    }
}

export default new LanguageService();