import { ICustomLoader } from "interfaces/ICustomLoader";
import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";

const CustomLoader: React.FC<ICustomLoader> = ({
    size,
    isSmall = false,
    color = "var(--dashboard-main-color)",
    loaderDescription,
    className = "flex-column loader-container",
    spacing
}) => {

    return (
        <div className={className} id="custom-loader" style={{
            marginLeft: spacing?.left,
            marginRight: spacing?.right,
            marginTop: spacing?.top,
            marginBottom: spacing?.bottom
        }}>
            <ClipLoader
                color={color}
                loading={true}
                size={size
                    ? size
                    : isSmall
                        ? 36
                        : 60
                
                }
            />
            {loaderDescription &&
                <div className="flex-row" style={{ alignItems: 'flex-end', textAlign: 'center', marginTop: '2vh' }}>
                    <span className="MavenPro-bold" style={{ fontSize: '18px', margin: '0 .5rem 0 0' }}>
                        {loaderDescription} <BeatLoader size={4} />
                    </span>
                </div>
            }
        </div>
    );
}

export default CustomLoader;