import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { IDatabaseHistory } from 'interfaces/Database/IDatabaseHistory';
import React from 'react';
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';
import '../scss/HistoryActions.scss';

const HistoryActions: React.FC<({ listActions: IDatabaseHistory[] })> = ({ listActions = [] }) => {
    const t = useTranslation('history');

    return (
        <DefaultContainer
            wrapperStyle={{
                flexGrow: 1,
                margin: '0 0 0 1rem'
            }}
            title={<h2 className="bold">{t.translate("actions")}</h2>}
            body={
                !listActions || listActions.length === 0
                    ? <p className="no-data">{t.translate("noAction")}</p>
                    : <div className='historyAppointment-wrapper'>
                        {listActions.map((action, index) =>
                            <div key={`action-${index}`}>
                                <p>{action.content}</p>
                                <p className='MavenPro'>
                                    {appointmentService.getFormattedDate(action.date)}
                                </p>
                            </div>
                        )}
                    </div>
            }
        />
    )
}

export default HistoryActions