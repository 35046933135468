import { Line } from 'rc-progress';
import React from 'react';

const ProgressBar: React.FC<({
    currentStep: number
    numberSteps: number
    color?: string
})> = ({ currentStep = 0, numberSteps, color }) => {
    const getPercentProgressBar = () => (currentStep - 1) / numberSteps * 100;

    return (
        <Line
            percent={getPercentProgressBar()}
            strokeWidth={1}
            strokeColor={color ?? "var(--dashboard-main-color)"}
            strokeLinecap="square"
        />
    );
}

export default ProgressBar;