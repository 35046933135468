import React from 'react';
import '../scss/Info.scss';

const Info: React.FC<({
    text: string
    onClick?: () => void
    isOk?: boolean
})> = ({
    text,
    onClick,
    isOk
}) => {
    return (
        <div onClick={onClick ? () => onClick() : undefined} id={isOk ? 'isOk' : 'notOk'} className={`MavenPro-black ${isOk ? 'clickable' : ''}`}>
            {text}
        </div>
    )
}

export default Info;