import React, { Fragment } from "react";
import { useTranslation } from "services";

const DSWelcome: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <Fragment>
            <div className="help-step">
                <h2>{t.translate('doctor.welcome.title')}</h2>
                <p>{t.translate('doctor.welcome.body')}</p>
            </div>
        </Fragment>
    )
}

export default DSWelcome;