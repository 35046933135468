import { SolutionsContext } from 'context/SolutionsContext';
import { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import './SolutionSelection.scss';

export interface SolutionSelectionProps {}

const SolutionSelection: React.FC<SolutionSelectionProps> = () => {
    const history = useHistory();
    const t = useTranslation('pages/solutionSelection');

    const { solutions } = useContext(SolutionsContext);

    const handleRedirectOrder = (name: string) => {
        history.push({
            pathname: `/order/${name}`
        });
    }

    return (
        <Fragment>
            <div className='order-page'>
                {solutions
                    .filter((elem) => elem.showInGroup.includes('microbiota'))
                    .sort((a, b) => (a.price ?? 0) > (b.price ?? 0) ? 1 : -1)
                    .map((solution, index) =>
                        <div key={index} className='solution-type-wrapper' onClick={() => handleRedirectOrder(solution.name)}>
                            <h2>{t.translateSubscriptionType(solution.name, 'short_name')}</h2>
                            {solution.price && <p style={{ margin: 0 }}>{t.translateSubscriptionPrice(solution.name)}</p>}
                            <img src={solution.image} alt={`${t.translateSubscriptionType(solution.name)} health kit`} />
                        </div>
                    )
                }
            </div>
            <h3 style={{ marginBottom: '0' }}>{t.translate('otherTitle')}</h3>
            <div className='order-page'>
                {solutions
                    .filter((elem) => elem.showInGroup.includes('other'))
                    .sort((a, b) => (a.price ?? 0) > (b.price ?? 0) ? 1 : -1)
                    .map((solution, index) =>
                        <div key={index} className='solution-type-wrapper' onClick={() => handleRedirectOrder(solution.name)}>
                            <h2>{t.translateSubscriptionType(solution.name, 'short_name')}</h2>
                            {solution.price && <p style={{ margin: 0 }}>{t.translateSubscriptionPrice(solution.name)}</p>}
                            <img src={solution.image} alt={`${t.translateSubscriptionType(solution.name)} health kit`} />
                        </div>
                    )
                }
            </div>
        </Fragment>
    )
}

export default SolutionSelection;