import { IDSchedule, Schedule, ScheduleCreate } from "interfaces/Database";
import APIService from "./APIService";

export class ScheduleService {
    private readonly apiService: APIService = new APIService('/schedules');

    public async get() {
        return this.apiService.call<IDSchedule>('/', 'get').then((res) => new Schedule(res));
    }

    public async createOrUpdate(newSchedule: ScheduleCreate) {
        return this.apiService.call<IDSchedule>('/createOrUpdate', 'post', newSchedule).then((res) => {
            return new Schedule(res);
        });
    }
}

function useScheduleService(): ScheduleService {
    return new ScheduleService();
}

export default useScheduleService;