import { Loader } from "components";
import QA from "components/QuestionnaireOverview/components/QA";
import "components/QuestionnaireOverview/scss/DoctorQuestionnaireOverview.scss";
import { IDSurvey } from "interfaces/Database";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";

const Doctor: React.FC = () => {
    const t = useTranslation('questionnaire');
    const history = useHistory();
    const { patientId } = useParams<({ patientId: string })>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ patientSurvey, setPatientSurvey ] = useState<IDSurvey>();
    const [ renderedQuestion, setRenderedQuestion ] = useState<number>(10);
    const [ patientFullName, setPatientFullName ] = useState<string>();

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            doctorService.getPatientSurvey(patientId)
                .then((res) => setPatientSurvey(res)),
            doctorService.getPatientDetails(patientId)
                .then((res) => setPatientFullName(`${res?.result?.patientInfo?.firstname} ${res?.result?.patientInfo?.lastname}`))
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, [ patientId ]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const renderSurvey = patientSurvey && patientSurvey.userAnswers.map((survey, index: number) =>
        index < renderedQuestion && <QA
            key={index}
            answer={survey}
            index={index + 1}
        />
    );

    const handleShowMoreQuestion = () => {
        setRenderedQuestion(renderedQuestion + 10);
    }

    const isAllQuestionDisplayed = () => {
        if (patientSurvey) {
            return renderedQuestion > patientSurvey.userAnswers.length;
        } else {
            return true;
        }
    }

    const displaySurveyCurrentStep = () => {
        return patientSurvey ? patientSurvey.currentStep : 0;
    }

    const isSurveyEmpty = () => {
        return !patientSurvey || patientSurvey.userAnswers.length === 0;
    }

    return (
        <div id="doctor-questionnaire-overview" className="column">
            <div id="doctor-questionnaire-overview-wrapper" className="column shadow">
                <div id="question-overview-header" className="row">
                    <div className="column">
                        <h1 id="patient-name" className="MavenPro-black">
                            {patientFullName}
                        </h1>
                        <span>
                            {t.translate("title")} ({displaySurveyCurrentStep()}/59)
                        </span>
                    </div>
                    <AiOutlineClose
                        size={30}
                        color="var(--dashboard-main-color)"
                        id="icon-close"
                        onClick={() => history.push(`/account/patient/${patientId}`)}
                    />
                </div>
                <div id="qa-list-wrapper" className="column">
                    {isLoading
                        ? <Loader />
                        : <Fragment>
                            {renderSurvey}
                            {!isAllQuestionDisplayed() &&
                                <div onClick={handleShowMoreQuestion} id="button-see-more">
                                    <span className="MavenPro-black">{t.translate('seeMore')}</span>
                                </div>
                            }
                            {isSurveyEmpty() &&
                                <div id="empty-survey-wrapper" className="column">
                                    <p className="no-data">
                                        {t.translate("notCompleted")}
                                    </p>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default Doctor;