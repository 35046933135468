import { IIntakeSchedule, defaultIntakeSchedule } from "interfaces/IIntakeSchedule"


export interface IDatabasePrescriptionMedication {
    id: number
    name: string
    description?: string
    quantity: number
    intakeSchedule: IIntakeSchedule
}

export const defaultPrescriptionMedication: IDatabasePrescriptionMedication = {
    id: 0,
    name: '',
    description: '',
    quantity: 0,
    intakeSchedule: defaultIntakeSchedule
} 