import { IDatabaseDoctorSpecialty } from "./IDatabaseDoctorSpecialty"
import { IDatabaseGender } from "./IDatabaseGender"
import { IDatabaseLanguage } from "./IDatabaseLanguage"
import { IDatabaseRegistrationType } from "./IDatabaseRegistrationType"

export interface IDatabaseDoctorInfo {
    id: number
    birthDate: Date
    clinic: string
    doctorSpecialties: IDatabaseDoctorSpecialty[]
    firstConnection: boolean
    firstname: string
    gender: IDatabaseGender
    isNutritionist: boolean
    languages: IDatabaseLanguage[]
    lastname: string
    registrationType?: IDatabaseRegistrationType
    registrationNumber: string
    signature?: string
    specialInformation: string
}

export default class IDoctorInfo implements IDatabaseDoctorInfo {
    id: number;
    birthDate: Date;
    clinic: string;
    doctorSpecialties: IDatabaseDoctorSpecialty[];
    firstConnection: boolean;
    firstname: string;
    gender: IDatabaseGender;
    isNutritionist: boolean;
    languages: IDatabaseLanguage[];
    lastname: string;
    registrationType?: IDatabaseRegistrationType;
    registrationNumber: string;
    signature?: string;
    specialInformation: string;

    constructor(doctorInfo: IDatabaseDoctorInfo) {
        this.id = doctorInfo.id;
        this.birthDate = doctorInfo.birthDate;
        this.clinic = doctorInfo.clinic;
        this.doctorSpecialties = doctorInfo.doctorSpecialties;
        this.firstConnection = doctorInfo.firstConnection;
        this.firstname = doctorInfo.firstname;
        this.gender = doctorInfo.gender;
        this.isNutritionist = doctorInfo.isNutritionist;
        this.languages = doctorInfo.languages;
        this.lastname = doctorInfo.lastname;
        this.registrationType = doctorInfo.registrationType;
        this.registrationNumber = doctorInfo.registrationNumber;
        this.signature = doctorInfo.signature;
        this.specialInformation = doctorInfo.specialInformation;
    }

    public doctorIsNutritionist(): boolean {
        return this.isNutritionist;
    }
}