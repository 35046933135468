import { FaqQuestion } from "components/Support/components/FrequentQuestions";

const companyQuestions: FaqQuestion[] = [
    {
        libelle: 'questions.company.how-to-command.ask',
        answer: 'questions.company.how-to-command.answer'
    },
    {
        libelle: 'questions.company.i-see-an-error.ask',
        answer: 'questions.company.i-see-an-error.answer'
    },
    {
        libelle: 'questions.company.how-to-claim-kit.ask',
        answer: 'questions.company.how-to-claim-kit.answer'
    },
    {
        libelle: 'questions.company.want-to-cancel-subscription.ask',
        answer: 'questions.company.want-to-cancel-subscription.answer'
    },
    {
        libelle: 'questions.company.where-find-invoice.ask',
        answer:  'questions.company.where-find-invoice.answer'
    },
    {
        libelle: 'questions.company.invoice-explained.ask',
        answer:  'questions.company.invoice-explained.answer'
    },
    {
        libelle: 'questions.company.change-bank-details.ask',
        answer:  'questions.company.change-bank-details.answer'
    },
    {
        libelle: 'questions.company.how-to-pay-invoice.ask',
        answer:  'questions.company.how-to-pay-invoice.answer'
    },
    {
        libelle: 'questions.company.other-questions.ask',
        answer:  'questions.company.other-questions.answer'
    }
];

export default companyQuestions;