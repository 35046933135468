import box from 'assets/home/microbiota_analysis.webp';
import { Button, LeftArrowIcon, StepIndicator } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { SubscriptionTrackingDetailContext } from 'context/SolutionProcessingContext';
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import "../scss/KitRegister.scss";

const SolutionAttributed: React.FC = () => {
    const t = useTranslation('pages/kitRegister');
    const { reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const history = useHistory();

    const handleFinish = () => {
        reloadSubscriptionTrackingDetail();
        history.push('/account/home');
    }

    return (
        <DefaultContainer
            wrapperClassName="customContainerWrapper"
            title={
                <div className='flex-row-start'>
                    <LeftArrowIcon
                        className="clickable"
                        onClick={() => history.push('/account/home')}
                    />
                    <div style={{ margin: '0 1vw' }} className='flex-column-start'>
                        <StepIndicator step={1} />
                    </div>
                </div>
            }
            body={
                <div className='row'>
                    <div id="image-wrapper" className="column">
                        <img src={box} alt="kit" id="kit-image" />
                    </div>
                    <div id="form-wrapper" className="flex-column">
                        <div className="flex-column">
                            <h2 id="title" className="MavenPro-black full-width" style={{ textAlign: "center" }}>
                                {t.translate('solutionAttributed.title')}
                            </h2>
                            <p id="form-description" className="full-width" style={{ textAlign: "center" }}>
                                {t.translate('solutionAttributed.body')}
                            </p>
                        </div>
                        <div className="flex-row full-width">
                            <Button label={t.translate('solutionAttributed.btn')} onClick={() => handleFinish()} />
                        </div>
                    </div>
                </div>
            }
            hideSeparator={true}
        />
    )
}

export default SolutionAttributed;