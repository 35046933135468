import { Button, Input, Label, Loader } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from 'components/common/Modal';
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import verifierService from "services/verifier.service";

const ModalEditPassword: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void
})> = ({
    showModal,
    handleClose,
    handleSuccess
}) => {
    const t = useTranslation('dashboard/company/profile');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ password, setPassword ] = useState<string>();
    const [ confirmPassword, setConfirmPassword ] = useState<string>();
    const [ oldPassword, setOldPassword ] = useState<string>();

    const successModal = () => {
        handleSuccess();
        handleClose();
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ showModal ]);

    function handleEditPassword() {
        if ((password && oldPassword) && password !== '' && confirmPassword !== '' && password === confirmPassword) {
            setError(undefined);
            setIsLoading(true);

                userAuthService.updatePassword({
                    newPassword: password,
                    confirmPassword: confirmPassword,
                    oldPassword: oldPassword,
                })
                    .then((res) => {
                        userAuthService.setToken(res);
                        setOldPassword(undefined);
                        setPassword(undefined);
                        setConfirmPassword(undefined);
                        successModal();
                    })
                    .catch((err) => setError(err.message))
                    .finally(() => setIsLoading(false));
        } else {
            setError(
                (password === '' || confirmPassword === '' || oldPassword === '')
                    ? t.translate('additional.password.modal.fillAll')
                    : (password !== confirmPassword)
                        ? t.translate('additional.password.modal.differents')
                        : t.translate('additional.password.modal.length')
            )
        }
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={handleClose}
            title={t.translate('additional.password.modal.title')}
            footer={
                !isLoading
                    && <div className="row flex-end">
                        <Button label={t.translate('additional.password.modal.cancel')} onClick={() => handleClose()} />
                        <Button label={t.translate('additional.password.modal.save')} onClick={() => handleEditPassword()} />
                    </div>
            }
        >
            {isLoading
                ? <Loader />
                : <Fragment>
                    <div className="flex-row space-between full-width" style={{ marginBottom: '2vh' }}>
                        <div className="flex-column-start full-width">
                            <Label for="password-old" label={t.translate('additional.password.modal.old')} required />
                            <Input
                                required
                                name="password-old"
                                type="password"
                                value={oldPassword}
                                setValue={(e) => setOldPassword(e.currentTarget.value)}
                                checkFunction={() => verifierService.checkString(oldPassword, { len: { min: 4 } })}
                            />
                        </div>
                    </div>
                    <div className="flex-row space-between full-width" style={{ marginBottom: '2vh' }}>
                        <div className="flex-column-start" style={{ marginRight: '10px' }}>
                            <Label for="password-new" label={t.translate('additional.password.modal.new')} required />
                            <Input
                                required
                                name="password-new"
                                type="password"
                                value={password}
                                setValue={(e) => setPassword(e.currentTarget.value)}
                                checkFunction={() => verifierService.checkPwdEnough(password)}
                            />
                        </div>
                        <div className="flex-column-start" style={{ marginLeft: '10px' }}>
                            <Label for="password-new-confirm" label={t.translate('additional.password.modal.confirm')} required />
                            <Input
                                required
                                name="password-new-confirm"
                                type="password"
                                value={confirmPassword}
                                setValue={(e) => setConfirmPassword(e.currentTarget.value)}
                                checkFunction={() => verifierService.checkPwdSame(password, confirmPassword)}
                            />
                        </div>
                    </div>
                    <ErrorMessage error={error} />
                </Fragment>
            }
        </Modal>
    );
}

export default ModalEditPassword;