import logo from 'assets/logos/bcu_logo_left.svg';
import { Button, Checkbox, CityAutocompleteInput, Input, Label, Loader, StepIndicator } from 'components';
import { EditAddress } from 'components/common/Address';
import ConsentFormSuccess from 'components/ConsentForm/components/ConsentFormSuccess';
import 'components/ConsentForm/scss/ConsentForm.scss';
import LeftArrow from 'components/Icons/LeftArrow';
import { SubscriptionTrackingDetailContext } from 'context/SolutionProcessingContext';
import { UserContext } from 'context/User/UserContext';
import { defaultAddress, IAddress } from 'interfaces/User/IAddress';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import addressService from 'services/address.service';
import analysisKitService from 'services/analysisKit.service';
import consentFormService from 'services/consentForm.service';
import kitService from 'services/kit.service';
import verifierService from 'services/verifier.service';

const ConsentForm: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('dashboard/patient/register-kit');
    const { refreshUser } = useContext(UserContext);
    const { reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ success, setSuccess ] = useState<boolean>(false);

    const [ firstName, setFirstName ] = useState<string>("");
    const [ lastName, setLastName ] = useState<string>("");
    const [ birthDate, setBirthDate ] = useState<string>("");
    const [ address, setAddress ] = useState<IAddress>(defaultAddress);

    const [ acceptCGV, setAcceptCGV ] = useState<boolean>(false);
    const [ agreedDiscloseResults, setAgreedDiscloseResults ] = useState<boolean>(false);
    const [ consentSamples, setConsentSamples ] = useState<boolean>(false);

    const [ actualLocation, setActualLocation ] = useState<string>("");
    const [ didOn, setDidOn ] = useState<Date>();

    const [ isValidForm, setIsValidForm ] = useState<boolean>(false);

    const loadConsentFormValues = async (data?: {
        firstname?: string,
        lastname?: string,
        birthdate?: Date,
        streetAddress?: string,
        postalCode?: string,
        city?: string,
        country?: {
            name?: string
        },
        aptNumber?: string,
        hasAcceptedCGV?: boolean,
        hasAcceptedDisclosure?: boolean,
        hasConsentToDoSamples?: boolean,
        didAt?: string,
        didOn?: Date,
    }) => {
        if (data?.firstname) setFirstName(data.firstname);
        if (data?.lastname) setLastName(data.lastname);
        if (data?.birthdate) setBirthDate(moment(data.birthdate).format('YYYY-MM-DD'));
        if (data?.didAt) setActualLocation(data.didAt);
        if (data?.didOn) setDidOn(data.didOn);
        
        setAddress({
            firstname: data?.firstname ?? "",
            lastname: data?.lastname ?? "",
            streetAddress: data?.streetAddress ?? "",
            postalCode: data?.postalCode ?? "",
            city: data?.city ?? "",
            countryName: data?.country?.name ?? "",
            aptNumber: data?.aptNumber ?? "",
        });
    }

    const resetConsentForm = () => {
        setFirstName('');
        setLastName('');
        setBirthDate('');
        setActualLocation('');
        setDidOn(undefined);
        setAddress({
            firstname: '',
            lastname: '',
            streetAddress: '',
            postalCode: '',
            city: '',
            countryName: '',
            aptNumber: '',
        })
    }

    const doChecks = useCallback(() => {
        setIsLoading(true);

        analysisKitService.canFillConsent()
            .then((res) => {
                if (!res) {
                    history.push("/account/home");
                }
            })
            .catch(() => history.push("/account/home"))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        doChecks();
    }, [doChecks]);

    const loadConsentForm = useCallback(async () => {
        setIsLoading(true);

        if (!kitService.localStorageGetKitCode() || kitService.localStorageGetKitCode() === 'null') {
            await analysisKitService.getOnGoingAnalysisKit()
                .then((res) => kitService.localStorageSaveKitCode(res.verificationCode))
                .catch(() => history.push("/account/home"));
        }

        await consentFormService.retrieveConsentForm(kitService.localStorageGetKitCode())
            .then((res) => loadConsentFormValues(res))
            .catch(() =>
                consentFormService.retrieveLastConsentForm()
                    .then((res) => loadConsentFormValues(res))
                    .catch(() =>
                        Promise.all([
                            refreshUser(),
                            addressService.getShippingAddress()
                        ])
                            .then(([ user, address ]) =>
                                loadConsentFormValues({
                                    firstname: user.patientInfo?.firstname,
                                    lastname: user.patientInfo?.lastname,
                                    birthdate: user.patientInfo?.birthDate ?? undefined,
                                    aptNumber: address.aptNumber,
                                    city: address.city,
                                    country: {
                                        name: address.country.nicename
                                    },
                                    postalCode: address.postalCode,
                                    streetAddress: address.streetAddress,
                                    didAt: address.city
                                })
                            )
                            .catch(() => resetConsentForm())
                    )
            )
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        loadConsentForm();
    }, [loadConsentForm]);

    const handleGoBack = () => {
        history.go(-1);
    }

    const handleNextStep = () => {
        setIsLoading(true);

        consentFormService.create({
            analysisKitCode: kitService.localStorageGetKitCode(),
            firstname: firstName,
            lastname: lastName,
            address: address,
            birthdate: birthDate,
            hasAcceptedCGU: true,
            hasAcceptedCGV: acceptCGV,
            hasAcceptedDisclosure: agreedDiscloseResults,
            hasConsentToDoSamples: consentSamples,
            didAt: actualLocation
        })
            .then(() => {
                setSuccess(true);
                reloadSubscriptionTrackingDetail();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        handleCheckForm();
    }, [firstName, lastName, birthDate, actualLocation,
        acceptCGV, agreedDiscloseResults, consentSamples, address])

    const handleCheckForm = () => {
        setIsValidForm(verifierService.checkString(firstName) && verifierService.checkString(lastName) &&
            verifierService.checkDate(birthDate) && actualLocation.length > 0 &&
            acceptCGV && agreedDiscloseResults && consentSamples);
    }

    if (isLoading) {
        return (
            <Loader />
        )
    } else {
        return (
            <div id="consent-form">
                <div id="logo-container">
                    <img src={logo} alt='logo' />
                </div>
                <div id="form">
                    <div id="back-container" className='flex-row-start full-width' style={!success ? { marginTop: '6vh' } : {}}>
                        <LeftArrow
                            className='clickable'
                            onClick={handleGoBack}
                        />
                        
                        <div style={{ margin: '0 1vw' }} className='flex-column-start'>
                            <StepIndicator step={2} />
                        </div>
                    </div>
                    {success
                        ? <ConsentFormSuccess />
                        : <div className="flex-grow flex-column" style={{ paddingBottom: '10vh' }}>
                            <h1
                                id="title"
                                className="MavenPro-black text-align-center"
                                style={{ marginTop: '2vh' }}
                            >
                                {t.translate('consentForm.title')}
                            </h1>
                            <div className="flex-column-start" style={{ width: "80%", maxWidth: '880px' }}>
                                <h4 className="sub-title">{t.translate('consentForm.undersigned.title')}</h4>
                                <div className="form-section">
                                    <div className="flex-column-start full-width">
                                        <Label
                                            for='consent-form-firstname'
                                            label={t.translate('consentForm.undersigned.firstname')}
                                            required
                                            labelStyle={{
                                                color: 'black'
                                            }}
                                        />
                                        <Input
                                            disabled={isLoading}
                                            name="consent-form-firstname"
                                            value={firstName}
                                            setValue={(e) => setFirstName(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(firstName)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                    <div className="flex-column-start full-width">
                                        <Label
                                            for='consent-form-lastname'
                                            label={t.translate('consentForm.undersigned.lastname')}
                                            required
                                            labelStyle={{
                                                color: 'black'
                                            }}
                                        />
                                        <Input
                                            disabled={isLoading}
                                            name="consent-form-lastname"
                                            value={lastName}
                                            setValue={(e) => setLastName(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(lastName)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <h4 className="sub-title">{t.translate('consentForm.born.title')} :</h4>
                                <div className="form-section">
                                    <div className="flex-column-start full-width">
                                        <Input
                                            required
                                            disabled={isLoading}
                                            name="consent-form-birthdate"
                                            type="date"
                                            value={birthDate}
                                            setValue={(e) => setBirthDate(moment(e.currentTarget.value).format('YYYY-MM-DD'))}
                                            checkFunction={() => verifierService.checkString(birthDate)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <h4 className="sub-title">{t.translate('consentForm.address.title')} :</h4>
                                <div className='form-section'>
                                    <EditAddress
                                        isLoading={isLoading}
                                        address={address}
                                        setAddress={setAddress}
                                    />
                                </div>

                                <h4 className="sub-title">{t.translate('consentForm.Acknowledge.title')}</h4>
                                <div className='form-section flex-column'>
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            <Checkbox
                                                name='accept-cgv'
                                                disabled={isLoading}
                                                isChecked={acceptCGV}
                                                setChecked={setAcceptCGV}
                                                label={t.translate('consentForm.accepts.cgv')}
                                                labelStyle={{
                                                    color: 'black',
                                                    margin: 0
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <Checkbox
                                                name='accept-sampling'
                                                disabled={isLoading}
                                                isChecked={consentSamples}
                                                setChecked={setConsentSamples}
                                                label={t.translate('consentForm.accepts.sampling')}
                                                labelStyle={{
                                                    color: 'black',
                                                    margin: 0
                                                }}
                                            />
                                        </li>
                                        <li>
                                            <Checkbox
                                                name='accept-disclosure'
                                                disabled={isLoading}
                                                isChecked={agreedDiscloseResults}
                                                setChecked={setAgreedDiscloseResults}
                                                label={t.translate('consentForm.accepts.disclosure')}
                                                labelStyle={{
                                                    color: 'black',
                                                    margin: 0
                                                }}
                                            />
                                        </li>
                                    </ul>
                                    <div className="column form-container-section" id="acknowledge-container">
                                        <span id="genetic-information">
                                            {t.translate('consentForm.Acknowledge.description')}
                                        </span>
                                    </div>
                                    <p id="text-anonymisation">
                                        {t.translate("consentForm.medicalData")}
                                    </p>
                                </div>
                                <div className="flex-row flex-wrap flex-gap-col flex-gap-row align-items-stretch">
                                    <div className="flex-column-start">
                                        <h4 className="sub-title"><span style={{ color: 'var(--red)' }}>*</span>{t.translate("consentForm.doneIn.title")} :</h4>
                                        <CityAutocompleteInput
                                            name='consent-form-city'
                                            disabled={isLoading}
                                            value={actualLocation}
                                            setValue={(e) => setActualLocation(e.currentTarget.value)}
                                            required
                                            checkFunction={() => verifierService.checkString(actualLocation)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                    <div className="flex-column-start">
                                        <h4 className="sub-title">{t.translate("consentForm.doneOn.title")} :</h4>
                                        <Input
                                            name='consent-form-date'
                                            value={t.translateDate(moment(didOn).toDate())}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="flex-column full-width" style={{ margin: '4vh 0 10vh 0' }}>
                                    <Button label={t.translate('consentForm.iConsent')} onClick={() => handleNextStep()} disabled={!isValidForm || isLoading} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ConsentForm;