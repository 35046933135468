import React, { useContext, useEffect, useState } from 'react';
import './scss/CustomLinkWrapper.scss';
import { LanguageContext } from 'context/Language/LanguageContext';

const CustomLinkWrapper: React.FC<({
    title: string
    link_text: string
    link: string
    target?: string
    rel?: string
})> = ({ title, link_text, link, target="_blank", rel="noopener noreferrer" }) => {
    const { language } = useContext(LanguageContext);
    const [ data, setData ] = useState('');

    useEffect(() => {
        setData(link);
    }, [ link, language ]);

    return (
        <div className='flex-column CustomLinkWrapper'>
            <h3 className='CustomLinkWrapper-title text-uppercase'>{title}</h3>
            <a href={data} target={target} rel={rel} className='text-uppercase dashboardBtn' style={{ textDecoration: 'none' }}>{link_text}</a>
        </div>
    )
}

export default CustomLinkWrapper;