import test_kit_1 from 'assets/order/microbiota_analysis/test_kit_1.png';
import test_kit_2 from 'assets/order/microbiota_analysis/test_kit_2.png';
import { useTranslation } from "services";

export interface TestKitProps {
    key: number | string
}

const TestKit: React.FC<TestKitProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');

    return (
        <div key={props.key} className="orderSolution-section">
            <div className='flex-column'>
                <div className="orderSolution-section-title">
                    <p>1.</p>
                    <div className="flex-column-start">
                        <h2>{t.translate('microbiota_analysis.test_kit.title')}</h2>
                    </div>
                </div>

                <p>{t.translate('microbiota_analysis.test_kit.body')}</p>
            </div>
            <div className='orderSolution-img-wrapper'>
                <img src={test_kit_1} />
                <img src={test_kit_2} />
            </div>
        </div>
    )
}

export default TestKit;