import { Button, LeftArrowIcon, TextArea } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import 'moment-timezone';
import React, { Fragment } from "react";
import translationService from "services/translation.service";
import "./scss/DoctorForm.scss";

const TS = "patientDetail.pdf.";

const DoctorForm: React.FC<({
    title: string
    onSubmit: () => void
    setFormValue: (newValue: string) => void
    patientFullName?: string
    value?: string
    dateLastUpdate?: Date
    handleGoBack: React.MouseEventHandler<SVGElement>
    additionalFields?: JSX.Element
})> = ({
    title,
    onSubmit,
    setFormValue,
    patientFullName,
    value = undefined,
    dateLastUpdate = undefined,
    handleGoBack,
    additionalFields = undefined
}) => {
    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onSubmit();
    }

    return (
        <div id="doctor-form" className="column">
            <div className="column" id="content">
                <div id="title-wrapper" className="row">
                    <LeftArrowIcon id="icon-go-back" onClick={handleGoBack} />
                    <h1 id="title" className="MavenPro-black">
                        {title} {patientFullName}
                    </h1>
                </div>
                <DefaultContainer
                    title={
                        <Fragment>
                            <h2>
                                {translationService.translate(TS + 'writeMy')} {title.toLowerCase()}
                            </h2>

                            {dateLastUpdate &&
                                <span id="last-update-label">
                                    {translationService.translate(TS + 'lastUpdate')} : <b>{translationService.translateDate(dateLastUpdate, { hours: true, minutes: true })}</b>
                                </span>
                            }
                        </Fragment>
                    }
                    body={
                        <Fragment>
                            <form id="input-wrapper" className="flex-column full-width" style={{ padding: '2vh 0' }}>
                                <TextArea
                                    required
                                    name="form-text-area"
                                    value={value}
                                    setValue={(e) => setFormValue(e.currentTarget.value)}
                                    checkFunction={() => value && value.length > 5 ? true : false}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />

                                {additionalFields}
                            </form>

                            <Button label={translationService.translate(TS+'save')} onClick={(e) => handleSubmit(e)} />
                        </Fragment>
                    }
                />
            </div>
        </div>
    );
}

export default DoctorForm;