import "components/Questionnaire/scss/Question.scss";
import { IDSurveyAnswer } from "interfaces/Database";
import { IQuestion } from "interfaces/Survey/IQuestion";
import React from "react";
import QuestionInput from "./QuestionInput";
import QuestionMultipleChoice from "./QuestionMultipleChoice";
import QuestionSingleChoice from "./QuestionSingleChoice";
import QuestionSlider from "./QuestionSlider";

const Question: React.FC<({
    question: IQuestion;
    handleValidation: (isValid: boolean) => void;
    handleUserAnswer: (params: (string|number)[]) => void;
    previousAnswers?: IDSurveyAnswer[];
})> = ({
    question,
    handleValidation,
    handleUserAnswer,
    previousAnswers,
}) => {
    const renderQuestion = () => {
        switch (question.type) {
            case 'input_answer':
                return (
                    <QuestionInput
                        question={question}
                        handleValidation={handleValidation}
                        handleUserAnswer={handleUserAnswer}
                        previousAnswers={previousAnswers}
                    />
                )
            case 'multiple_answers':
                return (
                    <QuestionMultipleChoice
                        question={question}
                        handleValidation={handleValidation}
                        handleUserAnswer={handleUserAnswer}
                        previousAnswers={previousAnswers}
                    />
                )
            case 'single_answer':
                return (
                    <QuestionSingleChoice
                        question={question}
                        handleValidation={handleValidation}
                        handleUserAnswer={handleUserAnswer}
                        previousAnswers={previousAnswers}
                    />
                )
            case 'slider_answer':
                return (
                    <QuestionSlider
                        question={question}
                        handleValidation={handleValidation}
                        handleUserAnswer={handleUserAnswer}
                        previousAnswers={previousAnswers}
                    />
                )
            default:
                return (
                    <p>An error occured, please report question &quot;{question.code}&quot; to BodyCheckup support.</p>
                )
        }
    }

    return (
        <div id="question" className="columnn">
            {renderQuestion()}
        </div>
    );
}

export default Question;