import { IDUser } from "interfaces/Database";
import { IDatabaseMessage } from "interfaces/Database/IDatabaseMessage";
import moment from 'moment';
import 'moment-timezone';
import apiService from "./api.service";

class messagingService {

    public getMessageFormattedDate = (date: moment.MomentInput) => {
        return moment(date).format('DD/MM/YYYY - HH:mm');
    }

    public getConversations = async () => {
        return await apiService.makeApiCall(`/api/messaging/conversations`, 'get');
    }

    public fetchConversation = async (conversationId: number) => {
        return await apiService.makeApiCall(`/api/getConversation/${conversationId}`, 'get')
            .then((res) => res as { receivedMessages: IDatabaseMessage[], sentMessages: IDatabaseMessage[], numberMessages: number });
    }

    public sendMessage = async (idReceiver: number, data: { message: string }) => {
        return await apiService.makeApiCall(`/api/messaging/user/${idReceiver}`, 'post', data)
            .then((res) => res.data);
    };

    public getTotalMessages = async (conversationId: string) => {
        return await apiService.makeApiCall(`/api/messaging/conversations/${conversationId}/getTotalMessages`, 'get');
    }

    public getTotalMessagesWithDoctor = async () => {
        return await apiService.makeApiCall<number>(`/api/messaging/conversations/totalDoctorMessages`, 'get');
    }

    public getTotalMessagesWithNutritionist = async () => {
        return await apiService.makeApiCall<number>(`/api/messaging/conversations/totalNutritionistMessages`, 'get');
    }

    public getRecipientsList = async () => {
        return await apiService.makeApiCall<IDUser[]>(`/api/messaging/recipients`, 'get');
    }

}

export default new messagingService();