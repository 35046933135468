import { IFormCheckmark } from "interfaces/Form/IFormCheckmark";
import React, { CSSProperties } from "react";
import DashboardInput from "./DashboardInput";
import DashboardLabel from "./DashboardLabel";

const DashboardLabelAndInput: React.FC<({
    error?: string,
    wrapperClassName?: string,
    wrapperStyle?: CSSProperties,
    required?: boolean,
    label: string,
    labelClassName?: string,
    placeholder?: string,
    name: string,
    disabled?: boolean,
    readOnly?: boolean,
    type?: React.HTMLInputTypeAttribute,
    value?: string|number,
    setValue?: React.ChangeEventHandler<HTMLInputElement>,
    checkFunction?: () => boolean,
    checkmark?: IFormCheckmark
})> = ({
    error,
    wrapperClassName = 'flex-column-start full-width',
    wrapperStyle = undefined,
    required = false,
    label,
    labelClassName = undefined,
    placeholder,
    name,
    readOnly,
    disabled = false,
    type = "text",
    value,
    setValue,
    checkFunction,
    checkmark = {
        displayed: false
    },
}) => {
    return (
        <div className={wrapperClassName} style={wrapperStyle}>
            <DashboardLabel
                required={required}
                labelClassName={labelClassName}
                label={label}
                for={name}
            />
            <DashboardInput
                required={required}
                placeholder={placeholder}
                name={name}
                readOnly={readOnly}
                disabled={disabled}
                type={type}
                value={value}
                setValue={setValue}
                checkFunction={checkFunction}
                checkmark={checkmark}
            />
            {error && <div className="text-error">
                {error}
            </div>}
        </div>
    )
}

export default DashboardLabelAndInput;