import React, { Fragment } from "react";
import { useTranslation } from "services";

const SSWelcome: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <Fragment>
            <div className="help-step">
                <h2>{t.translate('standalone.welcome.title')}</h2>
                <p>{t.translateReplaceValues('standalone.welcome.body1', [
                    {
                        tag: '{{pre}}',
                        value: <Fragment>
                            <b>{t.translate('standalone.welcome.pre')}</b>
                            <br />
                        </Fragment>
                    }
                ])}</p>
                <p>{t.translate('standalone.welcome.body2')}</p>
                <p>{t.translate('standalone.welcome.body3')}</p>
            </div>
        </Fragment>
    )
}

export default SSWelcome;