import { IDInternalNote } from "interfaces/Database";
import apiService from "./api.service";
import APIService from "./api/APIService";

class InternalNoteService {
    private ApiService = new APIService('/internalNote');

    public async getInternalNoteByPatient(patientId: number | string)
    {
        return await this.ApiService.call<IDInternalNote[]>(`/patient/${patientId}`, 'get');
    }

    public async create(
        patientId: number | string,
        data: {
            readonly note: string
        }
    ) {
        return await apiService.makeApiCall<IDInternalNote>(`/api/internalNote/patient/${patientId}`, 'post', data);
    }

    public async update(internalNoteId: number, data: { readonly note: string })
    {
        return await apiService.makeApiCall<IDInternalNote>(`/api/internalNote/${internalNoteId}`, 'put', data);
    }

    public async delete(internalNoteId: number)
    {
        return await apiService.makeApiCall(`/api/internalNote/${internalNoteId}`, 'delete');
    }
}

function useInternalNoteService(): InternalNoteService {
    return new InternalNoteService();
}

export default useInternalNoteService;