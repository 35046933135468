import fullLogo from 'assets/logos/bcu_full.svg';
import { Button } from 'components/Form';
import { LanguageContext } from 'context/Language/LanguageContext';
import languages from 'context/Language/Languages';
import { IUserLanguage } from 'interfaces/User/IUserLanguage';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import userAuthService from 'services/user-auth.service';
import "./scss/AuthLayoutHeader.scss";

const AuthLayoutHeader: React.FC = () => {
    const history = useHistory();
    const { language: currentLanguage, setLanguage } = useContext(LanguageContext);
    const t = useTranslation('common');

    const [ showDropdownLanguages, setShowDropdownLanguages ] = useState<boolean>(false);

    const currentLanguageRef = useRef<any>(null);       // eslint-disable-line

    const handleSelectCurrentLanguage = (language: IUserLanguage) => {
        setLanguage(language);
        setShowDropdownLanguages(false);
    }

    const displayLanguages = languages.map((language, index) =>
        <div
            key={index}
            className="row dropdown-language"
            id={`${Object.is(currentLanguage, language) ?
                "dropdown-language-selected" : ""}
            `}
            onClick={() => handleSelectCurrentLanguage(language)}
        >
            <img
                className="image-language"
                src={language.flagImage}
                alt='current-flag-language'
            />
            <span className="label-language">{language.name}</span>
        </div>
    );

    const handleRedirectHome = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            history.push('/');
        } else {
            window.location.replace('https://bodycheckup.com');
        }
    }

    const handleRedirectDashboard = () => {
        history.push('/account/home');
    }

    const shouldDisplayDashboardBtn = () => {
        return userAuthService.isUserConnected();
    }
    
    return (
        <header id="auth-layout-header" className="flex-row full-width">
            <img src={fullLogo} alt='BodyCheckup' id='auth-layout-header-logo' className='clickable' onClick={handleRedirectHome} />
            <nav>
                {shouldDisplayDashboardBtn() &&
                    <Button label={t.translate('dashboard')} onClick={handleRedirectDashboard} />
                }
                <div id="current-language" className="column" ref={currentLanguageRef}>
                    {currentLanguage && currentLanguage.flagImage
                        ? <img
                            className="flag-current-language"
                            src={currentLanguage.flagImage}
                            alt='current-flag-language'
                            onClick={() => setShowDropdownLanguages(!showDropdownLanguages)}
                        />
                        : null
                    }
                    {showDropdownLanguages &&
                        <div id="dropdown-languages" className="column">
                            {displayLanguages}
                        </div>
                    }
                </div>
            </nav>
        </header>
    )
}

export default AuthLayoutHeader;