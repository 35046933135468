import { Button, CloseIcon, Loader } from 'components';
import { IDatabaseSubscriptionPlan } from 'interfaces/Database/IDatabaseSubscriptionPlan';
import { IDatabaseSubscriptionPlanType } from 'interfaces/Database/IDatabaseSubscriptionPlanType';
import { IDatabaseUser } from 'interfaces/Database/IDatabaseUser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'services';
import '../scss/AllocateKit.scss';
import AllocateChooseSolution from './AllocateChooseSolution';
import AllocateExistingEmployee from './AllocateExistingEmployee';
import AllocateNewEmployee from './AllocateNewEmployee';
import AllocateResume from './AllocateResume';

const AllocateSolution: React.FC<({
    isShow: boolean
    toggleShow: (newValue: boolean) => void
    maxSolutionNumber: number
    usedSolutionNumber: number
    solutions: IDatabaseSubscriptionPlan[]
})> = ({ isShow, toggleShow, maxSolutionNumber, usedSolutionNumber, solutions }) => {
    const t = useTranslation('dashboard/company/employees');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    
    const [ newEmployeeError, setNewEmployeeError ] = useState<string>();

    const [ success, setSuccess ] = useState<string>();
    const [ solution, setSolution ] = useState<IDatabaseSubscriptionPlan>();
    const [ solutionTypes, setSolutionTypes ] = useState<IDatabaseSubscriptionPlanType[]>([]);
    const [ employee, setEmployee ] = useState<IDatabaseUser>();

    useEffect(() => {
        solutions.forEach(solution => {
            if (solution?.type && !solutionTypes.includes(solution.type)) {
                setSolutionTypes((old) => {
                    if (solution?.type) old.push(solution.type);
                    return old;
                })
            }
        });
    }, [solutions, solutionTypes]);

    const reset = (updateEmployees = false) => {
        setSuccess(undefined);
        setIsLoading(false);
        toggleShow(updateEmployees);
        setSolution(undefined);
    }

    const handleChangeSolution = (solutionId?: number) => {
        if (solutions.length > 0 && solutions.filter((e) => e.id === solutionId).length === 1) {
            setSolution(solutions.filter((e) => e.id === solutionId)[0]);
        }
    }

    if (isShow) {
        if (success) {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <CloseIcon className='close' onClick={() => reset(true)} />
                            <h2 className="MavenPro-bold">{t.translate('allocateModal.title')}</h2>
                        </div>
                        <div className='modal-content-body' style={{ textAlign: 'center' }}>
                            <h3>{t.translate('allocateModal.success.sub')} {employee?.patientInfo?.lastname ?? ""} {employee?.patientInfo?.firstname ?? ""}</h3>
                            <p>{t.translate('allocateModal.success.content')}</p>
                            <Button label={t.translate('allocateModal.success.back')} disabled={isLoading} onClick={() => reset(true)} />
                        </div>
                    </div>
                </div>
            )
        } else if (isLoading) {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <CloseIcon className='close' onClick={() => reset()} />
                            <h2 className="MavenPro-bold">{t.translate('allocateModal.title')}</h2>
                            <AllocateResume
                                freeSolutions={maxSolutionNumber-usedSolutionNumber}
                                givenSolutions={usedSolutionNumber}
                                desc={t.translate('allocateModal.resumeDesc')}
                                btnShow={false}
                                containerStyle={{
                                    width: 50,
                                    height: 50
                                }}
                            />
                        </div>
                        <div className='modal-content-body'>
                            <Loader />
                        </div>
                    </div>
                </div>
            )
        } else {
            if (solution) {
                return (
                    <div className='modal'>
                        <div className='modal-content'>
                            <div className='modal-content-header'>
                                <CloseIcon className='close' onClick={() => reset()} />
                                <h2 className="MavenPro-bold">{t.translate('allocateModal.title')}</h2>
                                <AllocateResume
                                    freeSolutions={maxSolutionNumber-usedSolutionNumber}
                                    givenSolutions={usedSolutionNumber}
                                    desc={t.translate('allocateModal.resumeDesc')}
                                    btnShow={false}
                                    containerStyle={{
                                        width: 50,
                                        height: 50
                                    }}
                                />
                            </div>
                            <div className='modal-content-body'>
                                <AllocateNewEmployee isLoading={isLoading} setIsLoading={setIsLoading} error={newEmployeeError} setError={setNewEmployeeError} setEmployee={setEmployee} setSuccess={setSuccess} solution={solution} />
                                <hr />
                                <AllocateExistingEmployee isLoading={isLoading} setIsLoading={setIsLoading} setSuccess={setSuccess} solution={solution} setEmployee={setEmployee} />
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <AllocateChooseSolution reset={reset} solutions={solutions} maxSolutionNumber={maxSolutionNumber} usedSolutionNumber={usedSolutionNumber} handleKitValidation={handleChangeSolution} />
                )
            }
        }
    } else {
        return null;
    }
}

export default AllocateSolution;