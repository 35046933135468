import { CheckIcon, CloseIcon } from "components/Icons";
import { IFormDashboardPhone } from "interfaces/Form/IFormDashboardPhone";
import React, { CSSProperties, useEffect, useState } from "react";
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const DashboardPhone: React.FC<(IFormDashboardPhone)> = ({
    labelledBy,
    required = false,
    disabled = false,
    setPhoneNumber,
    setCountryCode,
    defaultPhoneNumber = undefined,
    defaultCountryCode = undefined,
    checkmark = {
        displayed: false
    },
    checkFunction
}) => {
    const [ isValid, setIsValid ] = useState<boolean>(false);
    const [ color, setColor ] = useState<'dashboard-main-color' | 'red'>('red');

    useEffect(() => {
        if (
            (defaultCountryCode && defaultCountryCode.length > 0) &&
            (defaultPhoneNumber && defaultPhoneNumber.length > 4)
        ) {
            setIsValid(true);
            setColor('dashboard-main-color');
        }
    }, []);

    const handleUpdatePhoneValue = (value: string, countryData: CountryData) => {
        setPhoneNumber(value);
        setCountryCode(countryData.dialCode);
    }

    const checkmarkDefaultStyle: CSSProperties = {
        position: 'absolute',
        right: 0,
        marginRight: '5px',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }

    const isEntryValid = (): boolean => {
        if (checkFunction) {
            return checkFunction() && isValid;
        } else {
            return isValid;
        }
    }

    return (
        <div aria-labelledby={labelledBy} className="flex-row full-width" style={{ position: 'relative' }}>
            <PhoneInput
                placeholder="+33 1 23 45 67 89"
                disabled={disabled}
                country={defaultCountryCode || 'fr'}
                preferredCountries={[ 'fr', 'gb', 'be' ]}
                value={defaultPhoneNumber}
                isValid={(
                    value: string,
                    country: {
                        name?: string;
                        dialCode?: string;
                        countryCode?: string;
                        format?: string;
                    }
                ) => {
                    if (country && country.format) {

                        const regex = new RegExp("\\"+country.format.replaceAll(' ', '').replaceAll('.', '\\d'), 'gi');
            
                        if (('+'+value).match(regex)) {
                            setIsValid(true);
                            setColor('dashboard-main-color');
                            return true;
                        } else {
                            setIsValid(false);
                            setColor('red');
                            return true;
                        }
                    }

                    return false;
                }}
                onChange={handleUpdatePhoneValue}
                containerStyle={{
                    flexGrow: 1
                }}
                inputProps={{
                    required: required,
                    style: {
                        width: '100%',
                        borderTop: `2px solid var(--${color})`,
                        borderRight: `2px solid var(--${color})`,
                        borderBottom: `2px solid var(--${color})`,
                        paddingRight: '50px'
                    }
                }}
                buttonStyle={{
                    borderTop: `2px solid var(--${color})`,
                    borderLeft: `2px solid var(--${color})`,
                    borderBottom: `2px solid var(--${color})`,
                }}
            />
            {(!disabled && checkmark.displayed) && (!isEntryValid()
                ? checkmark.invalid?.component
                    ? <checkmark.invalid.component
                        color={checkmark.invalid.color ? checkmark.invalid.color : 'var(--red)'}
                        className={checkmark.invalid.className ? checkmark.invalid.className : 'icon-check'} />
                    : <CloseIcon size={16} color={`var(--red)`} style={checkmarkDefaultStyle} />
                : checkmark.valid?.component
                    ? <checkmark.valid.component
                        color={checkmark.valid.color ? checkmark.valid.color : 'var(--dashboard-main-color)'}
                        className={checkmark.valid.className ? checkmark.valid.className : 'icon-check'} />
                    : <CheckIcon size={16} style={checkmarkDefaultStyle} />
            )}
        </div>
    );
}

export default DashboardPhone;