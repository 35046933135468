import { Loader } from "components";
import AllPatients from "components/DoctorPatients/components/AllPatients";
import MyPatients from "components/DoctorPatients/components/MyPatients";
import "components/DoctorPatients/scss/DoctorPatients.scss";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import User from "interfaces/Database/IDatabaseUser";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";

const DoctorPatients: React.FC = () => {
    const t = useTranslation('doctorPatients');

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ doctorPatients, setDoctorPatients ] = useState<User[]>([]);
    const [ allPatients, setAllPatients ] = useState<User[]>([]);

    const fetchData = () => {
        setIsLoading(true);

        Promise.all([
            doctorService.getPatients()
                .then((res) => setDoctorPatients(res))
                .catch(() => setDoctorPatients([])),
            doctorService.getAllPatients()
                .then((res) => setAllPatients(res))
                .catch(() => setAllPatients([]))
        ])
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate('pre')} title={t.translate('title')} />
            {isLoading
                ? <Loader loaderDescription={t.translate('loader')} />
                : <Fragment>
                    <MyPatients patients={doctorPatients} />
                    <AllPatients patients={allPatients} />
                </Fragment>
            }
        </Fragment>
    )
}

export default DoctorPatients;