import { EditIcon, UserCircleIcon } from 'components';
import { UserContext } from "context/User/UserContext";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { refreshHeaderProfilePicture } from "redux/updateProfilePicture";
import { useTranslation } from "services";
import ModalEditProfilePicture from "../../Profile/components/Patient/components/modal/ModalEditProfilePicture";
import "./scss/ProfilePicture.scss";

const IMAGE_MAX_SIZE = 5*1024*1024; // 5MB
const NB_BYTES_IN_MB = 1024*1024; // 1MB

const EditProfilePicture: React.FC = () => {
    const t = useTranslation('components/profilePicture');
    const { user, profilePicture, refreshProfilePicture } = useContext(UserContext);
    const dispatch = useDispatch();

    const [ showEditPicture, setShowEditPicture ] = useState<boolean>(false);
    const [ showModalEditPicture, setShowModalEditPicture ] = useState<boolean>(false);
    const [ newProfilePicture, setNewProfilePicture ] = useState<File>();

    useEffect(() => {
        refreshProfilePicture();
    }, [user]);

    const handleEditProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            if (e.target.files[0].size < IMAGE_MAX_SIZE) {
                setNewProfilePicture(e.target.files[0]);
                setShowModalEditPicture(true);
            } else {
                alert(t.translateReplaceValues('overSizeLimit', [
                    {
                        tag: '{{size}}',
                        value: (IMAGE_MAX_SIZE / NB_BYTES_IN_MB)
                    }
                ]));
            }
        }
    }

    const handleSuccessUpdateProfilePicture = () => {
        setShowModalEditPicture(false);
        dispatch(refreshHeaderProfilePicture());
        toast.success(t.translate('success'));
    }

    return (
        <div id="profile-picture">
            <div
                id="profile-pic-container"
                className="column background-main-color"
                onMouseEnter={() => setShowEditPicture(true)}
                onMouseLeave={() => setShowEditPicture(false)}
            >
                <label htmlFor="inputEditPicture" style={{ width: "100%", height: "100%", boxSizing: 'border-box' }} id="test">
                    { profilePicture
                        ? <img src={profilePicture} alt={"User logged in"} id="profile-picture-img" />
                        : <div id="no-picture-container" className="flex-column">
                            <UserCircleIcon size={"100%"} id="icon-user" />
                        </div>
                    }
                    { showEditPicture &&
                        <div id="edit-picture-container" className="column">
                            <EditIcon id="icon-edit" color='white' />
                            <span className="MavenPro-bold">{t.translate('choose')}</span>
                        </div>
                    }
                </label>
            </div>
            <input type="file" id="inputEditPicture" style={{ display: "none" }} accept="image/jpeg, image/png" onChange={handleEditProfilePicture}></input>
            <ModalEditProfilePicture
                showModal={showModalEditPicture}
                handleClose={() => setShowModalEditPicture(false)}
                handleSuccess={() => handleSuccessUpdateProfilePicture()}
                img={newProfilePicture}
            />
        </div>
    );
}

export default EditProfilePicture;