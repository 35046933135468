import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { IDAppointment, IDAppointmentWithNutritionist } from 'interfaces/Database';
import React from 'react';
import Calendar from 'react-calendar';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';
import './styles/AllMyAppointments.scss';

const AllMyAppointments: React.FC<({
    appointments: (IDAppointment  | IDAppointmentWithNutritionist)[]
})> = ({ appointments }) => {
    const history = useHistory();
    const t = useTranslation('dashboard/doctor/allMyAppointments');
    
    const handleSeePatient = (patientId?: number) => {
        if (patientId) {
            history.push(`/account/patient/${patientId}`);
        }
    }

    return (
        <DefaultContainer
            title={<h2 className='bold'>{t.translate('title')}</h2>}
            body={!appointments || appointments.length === 0
                ? <p className="no-data">{t.translate('noAppointment')}</p>
                : <div id='allMyAppointments-wrapper'>
                    <div className="flex-column">
                        <Calendar
                            minDate={new Date()}
                            value={null}
                        />
                    </div>
                    <div className='appointments-list'>
                        {appointments.map((appointment, index) =>
                            <div key={`appointment-${index}`}>
                                <p onClick={() => handleSeePatient(appointment?.subscriptionPlan?.patient?.id)}>
                                    {(appointment?.subscriptionPlan?.patient?.patientInfo?.firstname + ' ' + appointment?.subscriptionPlan?.patient?.patientInfo?.lastname).toUpperCase()}
                                </p>
                                <p className='MavenPro'>
                                    {appointmentService.getFormattedDate(appointment.appointmentOn)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            }
        />
    )
}

export default AllMyAppointments