import { IQuestionWithAnswers } from "interfaces";
import React from "react";
import translationService from "services/translation.service";

const QuestionWithAnswers: React.FC<({
    questionWithAnswers: IQuestionWithAnswers;
})> = ({
    questionWithAnswers
}) => {
    return (
        <div id="qa" className="column">
            <span className="question MavenPro-bold">
                {questionWithAnswers.id}. {translationService.translateSurveyQuestion(questionWithAnswers.codeQuestion)}
            </span>
            {questionWithAnswers.answers.length > 0
                ? <ul>
                    {questionWithAnswers.answers.map((answer, index) => 
                        <li key={`${questionWithAnswers.id}${index}`}>{answer.answer}</li>
                    )}
                </ul>
                : <i>L&apos;utilisateur n&apos;a pas répondu à cette question</i>
            }
        </div>
    )
}

export default QuestionWithAnswers;