import React from 'react';
import { CircularProgressbar as LibCircularProgressBar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressbar: React.FC<({
    value: number,
    maxValue: number,
    text?: string,
    strokeWidth?: number,
    styles?: {
        root?: React.CSSProperties,
        path?: React.CSSProperties,
        trail?: React.CSSProperties,
        text?: React.CSSProperties
    }
})> = ({
    value,
    maxValue,
    text,
    strokeWidth = 5,
    styles
}) => {
    return (
        <LibCircularProgressBar
            value={value}
            text={text}
            maxValue={maxValue}
            strokeWidth={strokeWidth}
            styles={{
                // Customize the root svg element
                root: {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...styles?.root
                },
                // Customize the path, i.e. the "completed progress"
                path: {
                    // Path color
                    stroke: `var(--dashboard-main-color)`,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'round',
                    // Customize transition animation
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    // Rotate the path
                    transform: 'rotate(0turn)',
                    transformOrigin: 'center center',
                    ...styles?.path
                },
                // Customize the circle behind the path, i.e. the "total progress"
                trail: {
                    // Trail color
                    stroke: '#d6d6d6',
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                    // Rotate the trail
                    transform: 'rotate(0turn)',
                    transformOrigin: 'center center',
                    ...styles?.trail
                },
                // Customize the text
                text: {
                    position: 'relative',
                    // Text color
                    fill: 'var(--dashboard-main-color)',
                    // Text size
                    fontSize: '32px',
                    width: '100%',
                    ...styles?.text
                }
            }}
        />
    )
}

export default CircularProgressbar;