import { Step, StepLabel, Stepper } from '@mui/material';
import { Button, CheckIcon } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import ModalConfirmAction from 'components/common/Modals/ConfirmAction/ConfirmAction';
import { ProfilePicture } from 'components/common/ProfilePicture';
import { IDAppointment, IDAppointmentWithNutritionist } from 'interfaces/Database';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';
import userAuthService from 'services/user-auth.service';

const NextAppointment: React.FC<({
    nextAppointment?: IDAppointment | IDAppointmentWithNutritionist;
    isMeeting: boolean;
    reloadNextAppointment: () => void;
})> = ({ nextAppointment, isMeeting, reloadNextAppointment }) => {
    const t = useTranslation('dashboard/doctor/nextAppointment');

    const [ showModalCancel, setShowModalCancel ] = useState<boolean>(false);
    const [ isCancelling, setIsCancelling ] = useState<boolean>(false);

    const toggleCancelConfirmation = () => setShowModalCancel(!showModalCancel);

    const history = useHistory();
    const activeStep = 1;

    const changeHistory = (id?: number) => {
        if (id) {
            history.push(`/account/patient/${id}`);
        }
    }

    const getNextAppointmentDate = () => {
        return nextAppointment &&
            appointmentService.getFormattedDate(nextAppointment.appointmentOn);
    }

    const handleVideoMeeting = async () => {
        if (nextAppointment) {
            userAuthService.getOrCreateMeeting(nextAppointment.id)
                .then((res) => {
                    const roomId = res.roomUrl.split('/')[3];
                    window.open(`${window.location.origin}/account/meeting/withpatient/${nextAppointment?.subscriptionPlan.patient?.id}/${roomId}`);
                })
                .catch((err) => console.warn(err));
        }
    }

    async function handleCancelAppointment() {
        if (nextAppointment?.id) {
            setIsCancelling(true);

            await appointmentService.doctorCancelAppointment(nextAppointment?.id)
                .then(() => toast.success(t.translate('cancel.success')))
                .catch(() => toast.error(t.translate('cancel.failure')))
                .finally(() => {
                    reloadNextAppointment();
                    setIsCancelling(false);
                });
        }
    }

    async function handleValidation() {
        toggleCancelConfirmation();
        handleCancelAppointment();
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h2 className='bold'>{t.translate("title")}</h2>
                    {nextAppointment
                        ? <Fragment>
                            <p className='MavenPro' style={{ marginRight: "1vw" }}>{getNextAppointmentDate()}</p>
                            <Button 
                                label={t.translate("visio")} 
                                disabled={isCancelling || !isMeeting}
                                onClick={() => handleVideoMeeting()}
                            />
                        </Fragment>
                        : null
                    }
                </Fragment>
            }

            body={nextAppointment
                ? <Fragment>
                    <ModalConfirmAction
                        showModal={showModalCancel}
                        handleClose={() => toggleCancelConfirmation()}
                        confirmAction={handleValidation}
                        description={t.translate('cancel.description')}
                    />
                    <Stepper
                        className='full-width'
                        style={{ padding: '20px 0' }}
                        activeStep={activeStep}
                        alternativeLabel
                    >
                        <Step className='flex-column'>
                            <StepLabel
                                icon={
                                    <div className={
                                        `step ${1 > activeStep 
                                            ? "step-not-done"
                                            : "step-done"}
                                        `}
                                    >
                                        { 1 > activeStep 
                                            ? <span>0{0 + 1}.</span>
                                            : <CheckIcon />
                                        }
                                    </div>
                                }
                            >
                                <div id="patient-prochain-rdv">
                                    <div className="patient-desc">
                                        <div className="patient-desc-imgWrapper">
                                            <ProfilePicture user={nextAppointment?.subscriptionPlan.patient} maxSize={'8rem'} />
                                        </div>
                                        <p className="patient-desc-name MavenPro-bold text-uppercase" onClick={() => changeHistory(nextAppointment?.subscriptionPlan?.patient?.id)}>{!nextAppointment ? '' : `${nextAppointment?.subscriptionPlan?.patient?.patientInfo?.firstname} ${nextAppointment?.subscriptionPlan?.patient?.patientInfo?.lastname}`}</p>
                                    </div>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step className='flex-column'>
                            <StepLabel
                                icon={
                                    <div className={
                                        `step ${2 > activeStep 
                                            ? "step-not-done"
                                            : "step-done"}
                                        `}
                                    >
                                        { 2 > activeStep 
                                            ? <span>0{1 + 1}.</span>
                                            : <CheckIcon />
                                        }
                                    </div>
                                }
                            >
                                <div className='flex-column'>
                                    <Button
                                        style={{ margin: '1vh 0' }}
                                        disabled={isCancelling}
                                        label={t.translate("prescription")}
                                        onClick={() => history.push(`/account/patient/${nextAppointment?.subscriptionPlan?.patient?.id}`)}
                                    />
                                    <Button
                                        style={{ margin: '1vh 0' }}
                                        disabled={isCancelling}
                                        displayLoader={isCancelling}
                                        label={t.translate("cancel.button")}
                                        onClick={() => toggleCancelConfirmation()}
                                    />
                                </div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </Fragment>
                : <p className="no-data">{t.translate("noAppointment")}</p>
            }
        />
    )
}
export default NextAppointment