import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { IDUser } from "interfaces/Database";
import { IMenuItem } from "interfaces/Pages/Layout/IMenuItems";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import SubscriptionTrackingDetail from "interfaces/SubscriptionProcessing/ISubscriptionTrackingDetail";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserService, useTranslation } from "services";
import patientService from "services/patient.service";
import DefaultSidebar from "./DefaultSidebar";

const baseItems: IMenuItem[] = [
    {
        name: 'menuItems.employee.dashboard.name',
        path: '/account/home',
        code: 'home',
        logout: false
    },
    {
        name: 'menuItems.employee.tests.name',
        path: '/account/tests',
        code: 'tests',
        logout: false
    },
    {
        name: 'menuItems.employee.prescriptions.name',
        path: '/account/prescription',
        code: 'prescription',
        logout: false
    },
    {
        name: 'menuItems.employee.appointments.name',
        path: '/account/appointments',
        code: 'appointments',
        logout: false
    },
    {
        name: 'menuItems.employee.recommendations.name',
        path: '/account/recommendations',
        code: 'recommendations',
        logout: false
    },
    {
        name: 'menuItems.employee.messaging.name',
        path: '/account/messaging',
        code: 'messaging',
        logout: false
    },
    {
        name: 'menuItems.employee.profil.name',
        path: '/account/profile',
        code: 'profile',
        logout: false
    },
    {
        name: 'menuItems.employee.support.name',
        path: '/account/support',
        code: 'support',
        logout: false
    },
    {
        name: 'menuItems.employee.logout.name',
        path: '/logout',
        code: 'logout',
        logout: true
    },
];

const PatientSidebar: React.FC<({
    menuStatus?: IMenuStatus,
    user?: IDUser
})> = ({
    menuStatus,
    user
}) => {
    const t = useTranslation('layout/sidebar');
    const history = useHistory();
    const { subscriptionTrackingDetail, reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ disableMenuBtn, setDisableMenuBtn ] = useState<boolean>(true);
    const [ menuItems, setMenuItems ] = useState<IMenuItem[]>(baseItems);

    const handleMenuItems = (e: SubscriptionTrackingDetail | undefined) => {
        let newList = baseItems;

        if (
            e?.isPlanMicrobiotaOnly() ||
            e?.isPlanMicrobiotaStressBurnout() ||
            e?.isPlanStressBurnout()
        ) {
            if (e?.arentDoctorAndNutritionistAppointments()) {
                newList = newList.filter((item) => !['prescription'].includes(item.code));
            }
        }

        setMenuItems(newList);
    }

    useEffect(() => {
        reloadSubscriptionTrackingDetail()
            .then((e) => handleMenuItems(e));

        patientService.canRegisterKit()
            .then((res) => setDisableMenuBtn(!res))
            .catch((err) => console.warn(err));
    }, []);

    return (
        <DefaultSidebar
            menuStatus={menuStatus}
            menuItems={menuItems}
            helloMessage={<p className="sidebar-title">{t.translate('header.hello')} <span id="last-name">{user?.patientInfo?.firstname ?? 'PATIENT'}</span></p>}
            subMessage={<p id="subTitle">{t.translate('header.defaultSub')}</p>}
            button={
                UserService.handlePatientSidebarButton(t, history, disableMenuBtn, subscriptionTrackingDetail)
            }
        />
    );
}

export default PatientSidebar;