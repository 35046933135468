import React, { CSSProperties } from "react";
import { useTranslation } from "services";
import "style/components/InformationWrapper.scss";

const InformationWrapper: React.FC<({
    libelle: string
    value?: string | number
    style?: CSSProperties
    fullWidth?: boolean
    alignStart?: boolean
    hideNone?: boolean
})> = ({
    libelle,
    value,
    style,
    fullWidth,
    alignStart,
    hideNone = false
}) => {
    const t = useTranslation('common');

    return (
        <div id="dashboard-information-wrapper" className={`flex-column${alignStart ? '-start' : ''} ${fullWidth ? 'full-width' : ''}`} style={style}>
            <span className="label-information">
                {libelle}
            </span>
            <span className={`information ${((value === undefined || value === null) && !hideNone) ? 'no-data-small' : ''}`}>
                {(value !== undefined && value !== null)
                    ? value
                    : !hideNone
                        ? t.translate('no-data')
                        : null
                }
            </span>
        </div>
    );
}

export default InformationWrapper;