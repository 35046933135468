import { Button } from "components";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ReferringDoctor from "components/common/ReferringDoctor/ReferringDoctor";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import StandaloneAdditionalActions from "./components/StandaloneAdditionalActions";
import StandaloneAddressesInformations from "./components/StandaloneAddressesInformations";
import StandaloneContactInformations from "./components/StandaloneContactInformations";
import StandaloneGeneralInformations from "./components/StandaloneGeneralInformations";
import StandaloneHealthInformations from "./components/StandaloneHealthInformations";
import StandaloneParameters from "./components/StandaloneParameters";
import StandaloneSpecialInformations from "./components/StandaloneSpecialInformations";

const StandaloneProfile: React.FC = () => {
    const t = useTranslation('profile/patient/patient');
    const { user } = useContext(UserContext);
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const history = useHistory();

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate("pre")} title={t.translate("title")} />
            {user ?
                <Fragment>
                    <div className="flex-row align-items-stretch dashboard-container-gap full-width flex-wrap" style={{ alignItems: 'normal' }}>
                        <StandaloneGeneralInformations />
                        <StandaloneContactInformations />
                    </div>

                    <StandaloneAddressesInformations />
                    <StandaloneHealthInformations />

                    <div className="flex-row align-items-stretch dashboard-container-gap full-width flex-wrap">
                        <StandaloneSpecialInformations />
                        {subscriptionTrackingDetail?.subscriptionPlan?.isTypeCancer() || <ReferringDoctor />}
                    </div>
                    
                    <DefaultContainer
                        title={<h2 className="container-title bold">{t.translate('firstBuy.title')}</h2>}
                        body={
                            <Fragment>
                                <p>{t.translate('firstBuy.body')}</p>
                                <Button label={t.translate('firstBuy.button')} onClick={() => history.push('/order')} />
                            </Fragment>
                        }
                    />

                    <StandaloneParameters />
                    <StandaloneAdditionalActions />
                </Fragment> :
                <p>User hasnt been found</p>
            }
        </Fragment>
    )
}

export default StandaloneProfile;