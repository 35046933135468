import React from 'react';
import { FiDroplet } from "react-icons/fi";
import { GiSquareBottle } from "react-icons/gi";
import { GiCorkedTube } from "react-icons/gi";
import { SiMoleculer } from "react-icons/si";

const markers = {
    content: [
        {
            name: "Microbiote",
            icon: <SiMoleculer color="#FFF" />,
            color:"var(--purple)",
            markers: [
                {
                    name: "marker1",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "marker2",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
            ]
        },
        {
            name: "Urine",
            icon: <GiSquareBottle color="#FFF" />,
            color:"var(--yellow)",
            markers: [
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
            ]
        },
        {
            name: "Saliva",
            icon: <GiCorkedTube color="#FFF" />,
            color:"var(--blue)",
            markers: [
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
            ]
        },
        {
            name: "Blood",
            icon: <FiDroplet color="#FFF" />,
            color:"var(--light-red)",
            markers: [
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                },
                {
                    name: "Firmicutes",
                    description: `These bacteria are specialised in the degradation of complex polysaccharides and the production of short-chain fatty acids 
                    (acetate, propionate, butyrate). They are able to produce short-chain fatty acids (SCFAs) either directly in response to food polysaccharides or by 
                    fermenting lactate produced by primary degraders. Indeed, these firmicutes are part of the secondary degraders. Faecalibacterium prauznitzii and Roséburia 
                    are the two main producers of butyrate.`
                }
       
            ]
        },
    ]
}

export default markers;