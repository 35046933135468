import chroma from "chroma-js";
import "components/Privacy/scss/Privacy.scss";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";

const Privacy: React.FC = () => {
    const t = useTranslation('privacy');
    const history = useHistory();

    function redirectTo(route: string) {
        history.push(route);
    }

    const gradient = chroma.scale(['#0099ff', '#66ff00']);

    return (
        <div id="privacy" className="flex column">
            <div className="column background-gradient" id="privacy-policy-top-container">
                <div id="privacy-policy-wrapper" className="column">
                    <h1 className="font-title-bold" id="title">{t.translate('title')}</h1>
                    <h4 className="bold" id="sub-title">{t.translate('sub')}</h4>
                    <p>{t.translate('desc')}</p>
                </div>
            </div>
            <div className="column" id="content">
                <h4 className="font-title-bold section-title" style={{ color: gradient(1 / 6).hex() }}>
                    1. {t.translate('about-us.title')}
                </h4>
                <p>
                    {t.translate('about-us.first')}{' '}
                    <span className="color-link" onClick={() => redirectTo("/conditions")}>{t.translate('about-us.link')}</span>{' '}
                    {t.translate('about-us.second')}
                    <br />
                    {t.translate('about-us.third')}
                    <br />
                    {t.translate('about-us.fourth')}
                </p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(2 / 6).hex() }}>
                    2. {t.translate('your-data.title')}
                </h4>
                <p>{t.translate('your-data.prelist')}</p>
                <ul>
                    <li>
                        <span className="bold">{t.translate('your-data.givenInfo.bold')}</span>
                        {t.translate('your-data.givenInfo.text')}
                    </li>
                    <br />
                    <li>
                        <span className="bold">
                        {t.translate('your-data.takenInfo.bold')}
                        </span>{' '}
                        {t.translate('your-data.takenInfo.pre')}
                        <ul>
                            <li>{t.translate('your-data.takenInfo.first')}</li>
                            <li>{t.translate('your-data.takenInfo.second')}</li>
                        </ul>
                    </li>
                    <li>
                        <span className="bold">{t.translate('your-data.receivedInfo.bold')}</span>
                        {t.translate('your-data.receivedInfo.text')}
                    </li>
                </ul>
                <h4 className="font-title-bold section-title" style={{ color: gradient(3 / 6).hex() }}>
                    3. {t.translate('personal-data.title')}
                </h4>
                <p>
                    {t.translate('personal-data.text')}
                </p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(4 / 6).hex() }}>
                    4. {t.translate('cookies-data.title')}
                </h4>
                <p>
                    {t.translate('cookies-data.text')}
                </p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(5 / 6).hex() }}>
                    5. {t.translate('usage-data.title')}
                </h4>
                <p>{t.translate('usage-data.prelist')}</p>
                <ul>
                    <li>
                        <span className="bold">{t.translate('usage-data.given.bold')}</span>{' '}{t.translate('usage-data.given.prelist')}
                        <ul>
                            <li>{t.translate('usage-data.given.first')}</li>
                            <li>{t.translate('usage-data.given.second')}</li>
                            <li>{t.translate('usage-data.given.third')}</li>
                            <li>{t.translate('usage-data.given.fourth')}</li>
                            <li>{t.translate('usage-data.given.fifth')}</li>
                        </ul>
                    </li>
                    <li>
                        <span className="bold">{t.translate('usage-data.taken.bold')}</span>{' '}{t.translate('usage-data.taken.prelist')}
                        <ul>
                            <li>{t.translate('usage-data.taken.first')}</li>
                            <li>{t.translate('usage-data.taken.second')}</li>
                            <li>{t.translate('usage-data.taken.third')}</li>
                            <li>{t.translate('usage-data.taken.fourth')}</li>
                            <li>{t.translate('usage-data.taken.fifth')}</li>
                            <li>{t.translate('usage-data.taken.sixth')}</li>
                        </ul>
                    </li>
                    <li>
                        <span className="bold">{t.translate('usage-data.received.bold')}</span>{' '}
                        {t.translate('usage-data.received.text')}
                    </li>
                </ul>
                <h4 className="font-title-bold section-title" style={{ color: gradient(6 / 6).hex() }}>
                    6. {t.translate('share-data.title')}
                </h4>
                <p>{t.translate('share-data.desc')}</p>
                <span>{t.translate('share-data.prelist')}</span>
                <ul>
                    <li>{t.translate('share-data.first')}</li>
                    <li>{t.translate('share-data.second')}</li>
                    <li>{t.translate('share-data.third')}</li>
                </ul>
                <h4 className="font-title-bold section-title" style={{ color: gradient(1 / 6).hex() }}>
                    7. {t.translate('published-data.title')}
                </h4>
                <p>{t.translate('published-data.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(2 / 6).hex() }}>
                    8. {t.translate('email-sent.title')}
                </h4>
                <p>{t.translate('email-sent.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(3 / 6).hex() }}>
                    9. {t.translate('id-theft.title')}
                </h4>
                <p>{t.translate('id-theft.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(4 / 6).hex() }}>
                    10. {t.translate('store-data.title')}
                </h4>
                <p>{t.translate('store-data.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(5 / 6).hex() }}>
                    11. {t.translate('your-rights.title')}
                </h4>
                <p>{t.translate('your-rights.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(6 / 6).hex() }}>
                    12. {t.translate('compliance.title')}
                </h4>
                <p>{t.translate('compliance.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(1 / 6).hex() }}>
                    13. {t.translate('other-site.title')}
                </h4>
                <p>{t.translate('other-site.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(2 / 6).hex() }}>
                    14. {t.translate('modification.title')}
                </h4>
                <p>{t.translate('modification.text')}</p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(3 / 6).hex() }}>
                    15. {t.translate('coordinates.title')}
                </h4>
                <p>
                    {t.translate('coordinates.pre.first')}{' '}
                    <span className="color-link" onClick={() => redirectTo("/contact")}>{t.translate('coordinates.pre.link')}</span>{' '}
                    {t.translate('coordinates.pre.second')}
                    <p className="bold">{t.translate('coordinates.bold')}</p>
                    <p>
                        {t.translate('coordinates.content.first')}{' '}
                        <span className="color-link" onClick={() => window.open("https://www.allaboutcookies.org/")}>www.allaboutcookies.org</span>.
                        <br />
                        {t.translate('coordinates.content.second')}
                        <br />
                        {t.translate('coordinates.content.third')}
                    </p>
                </p>
                <h4 className="font-title-bold section-title" style={{ color: gradient(4 / 6).hex() }}>
                    16. {t.translate('rgpd.title')}
                </h4>
                <p>
                    {t.translate('rgpd.first')}
                    <br />
                    {t.translate('rgpd.second')}
                    <br />
                    {t.translate('rgpd.third')}
                    <br />
                    <span className="color-link" onClick={() => window.open("mailto: t.derville@bodycheckup.com/")}>
                        t.derville@bodycheckup.com
                    </span>
                </p>
                <p>
                    <span className="underline">
                        {t.translate('rgpd.data.taken.title')}
                    </span>
                    <br />
                    {t.translate('rgpd.data.taken.first')}
                    <br />
                    {t.translate('rgpd.data.taken.prelist')}
                    <br />
                    <ul>
                        <li>
                            {t.translate('rgpd.data.taken.list')}
                        </li>
                    </ul>
                    {t.translate('rgpd.data.taken.content')}
                </p>
                <p>
                    <span className="underline">
                        {t.translate('rgpd.data.usage.title')}
                    </span>
                    <br />
                    {t.translate('rgpd.data.usage.prelist')}
                    <ul>
                        <li>{t.translate('rgpd.data.usage.list.first')}</li>
                        <li>{t.translate('rgpd.data.usage.list.second')}</li>
                        <li>{t.translate('rgpd.data.usage.list.third')}</li>
                        <li>{t.translate('rgpd.data.usage.list.fourth')}</li>
                        <li>{t.translate('rgpd.data.usage.list.fifth')}</li>
                    </ul>
                </p>
                <p>
                    <span className="underline">
                    {t.translate('rgpd.data.share.title')}
                    </span>
                    <br />
                    {t.translate('rgpd.data.share.text')}
                </p>
                <p>
                    <span className="underline">
                    {t.translate('rgpd.data.security.title')}
                    </span>
                    <br />
                    {t.translate('rgpd.data.security.text')}
                </p>
                <p>
                    <span className="underline">
                    {t.translate('rgpd.data.can-do.title')}
                    </span>
                    <br />
                    {t.translate('rgpd.data.can-do.text')}
                </p>
                <p>
                    <span className="underline">
                    {t.translate('rgpd.more.title')}
                    </span>
                    <br />
                    {t.translate('rgpd.more.pre')}
                    <ul>
                        <li>
                            {t.translate('rgpd.more.mail')}{' '}
                            <span className="color-link" onClick={() => window.open("mailto: t.derville@bodycheckup.com/")}>
                                t.derville@bodycheckup.com
                            </span>
                        </li>
                        <li>
                            {t.translate('rgpd.more.website.pre')}{' '}
                            <span className="color-link" onClick={() => window.open("https://bodycheckup.com/contact-us")}>
                                bodycheckup.com
                            </span>{' '}
                            {t.translate('rgpd.more.website.post')}
                        </li>
                    </ul>
                    {t.translate('rgpd.more.modify')}
                </p>
            </div>
        </div>
    );
}

export default Privacy;