import DoctorSignature from "components/DoctorSignature";
import DoctorSchedule from "components/Schedules/DoctorSchedule/DoctorSchedule";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DashboardUserParameters from "components/common/DashboardUserParameters/DashboardUserParameters";
import 'moment-timezone';
import React, { Fragment } from 'react';
import { useTranslation } from "services";
import ProfileAdditionalActions from "../Patient/components/ProfileAdditionalActions";
import AddressInformation from "./components/AddressInformation";
import Contact from "./components/Contact";
import Information from "./components/Information";
import SpecialInformation from "./components/SpecialInformation";
import './scss/DoctorProfile.scss';

const DoctorProfile: React.FC = () => {
    const t = useTranslation('profile/doctor/doctor');

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate("pre")} title={t.translate("title")} />
            <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                <Information />
                <Contact />
            </div>
            <DoctorSignature />
            <AddressInformation />
            <SpecialInformation />
            <DoctorSchedule />
            <DashboardUserParameters />
            <ProfileAdditionalActions />
        </Fragment>
    );
}

export default DoctorProfile;