import PaymentType, { IDatabasePaymentType } from "interfaces/Database/IDatabasePaymentType";
import APIService from "./APIService";

class PaymentTypeService {
    private apiService = new APIService('/productOrder/paymentType');

    public async getAllPaymentTypes(): Promise<PaymentType[]> {
        return await this.apiService.call<IDatabasePaymentType[]>('/').then((res) => res.map<PaymentType>((e) => new PaymentType(e.id, e.name)));
    }
}

function usePaymentTypeService(): PaymentTypeService {
    return new PaymentTypeService();
}

export default usePaymentTypeService;