import { Button, Checkbox } from "components/Form";
import EditAddressForm from "components/common/Address/EditAddressForm";
import { IDAddress } from "interfaces/Database";
import { IAddress, defaultAddress } from "interfaces/User/IAddress";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import addressService from "services/address.service";
import standaloneService from "services/standalone.service";

export interface AddressProps {
    billingAddress?: IDAddress,
    shippingAddress?: IDAddress,
    areSame?: boolean,
    handleNextStep: () => Promise<void>,
}

const Address: React.FC<AddressProps> = (props) => {
    const t = useTranslation('pages/orderSolution');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ newBillingAddress, setBillingAddress ] = useState<IAddress>(defaultAddress);
    const [ newShippingAddress, setShippingAddress ] = useState<IAddress>(defaultAddress);
    const [ areBillingAndShippingSame, setAreBillingAndShippingSame ] = useState<boolean>(true);

    const [ hasntChanged, setHasntChanged ] = useState<boolean>(false);

    useEffect(() => {
        if (props.billingAddress) setBillingAddress(addressService.databaseAddressToLocal(props.billingAddress));
    }, [props.billingAddress]);

    useEffect(() => {
        if (props.shippingAddress) setShippingAddress(addressService.databaseAddressToLocal(props.shippingAddress));
    }, [props.shippingAddress]);

    useEffect(() => {
        if (props.areSame) setAreBillingAndShippingSame(props.areSame);
    }, [props.areSame]);

    useEffect(() => {
        // if addresses are same, only verify shipping, else, verify both
        if (
            props.shippingAddress && props.billingAddress
        ) {
            if (areBillingAndShippingSame) {
                setHasntChanged(
                    addressService.compareDBWithLiveAddress(props.shippingAddress, newShippingAddress)
                );
            } else {
                setHasntChanged(
                    addressService.compareDBWithLiveAddress(props.shippingAddress, newShippingAddress) &&
                    addressService.compareDBWithLiveAddress(props.billingAddress, newBillingAddress)
                );
            }
        }
    }, [newBillingAddress, newShippingAddress, areBillingAndShippingSame]);

    const handleSubmit = () => {
        setIsLoading(true);

        standaloneService.updateAddresses({
            sameAddresses: areBillingAndShippingSame,
            billing: newBillingAddress,
            shipping: newShippingAddress
        })
            .then(async () => await props.handleNextStep().then(() => setIsLoading(false)))
            .catch((err) => console.warn(err.message));
    }

    const handlePass = () => {
        setIsLoading(true);

        props.handleNextStep()
            .finally(() => setIsLoading(false));
    }

    return (
        <div className='full-width flex-column'>
            <p>{t.translate('steps.address.shipping')}</p>
            <EditAddressForm
                isLoading={isLoading}
                address={newShippingAddress}
                setAddress={setShippingAddress}
            />
            <Checkbox
                name="areAddressesSame"
                isChecked={areBillingAndShippingSame}
                setChecked={setAreBillingAndShippingSame}
                disabled={isLoading}
                label={t.translate('steps.address.areSame')}
            />
            {!areBillingAndShippingSame &&
                <Fragment>
                    <p>{t.translate('steps.address.billing')}</p>
                    <EditAddressForm
                        isLoading={isLoading}
                        address={newBillingAddress}
                        setAddress={setBillingAddress}
                    />
                </Fragment>
            }

            <div className="flex-row full-width flex-gap-col flex-gap-row">
                <Button
                    label={hasntChanged
                        ? t.translate('steps.address.button.use')
                        : t.translate('steps.address.button.save')
                    }
                    onClick={hasntChanged
                        ? () => handlePass()
                        : () => handleSubmit()
                    }
                    disabled={isLoading}
                    displayLoader={isLoading}
                />
            </div>
        </div>
    )
}

export default Address;