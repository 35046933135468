import apiService from "./api.service";
import ErrorService from "./Error.service";
import { IDatabaseDiet } from "interfaces/Database/IDatabaseDiet";

class DietService {
    public getFoodImage = async (name: string) => {
        return import(`assets/foods/${name}.png`)
            .then((res) => res.default);
    }

    public fetchDiets = async () => {
        return await apiService.makeApiCall(`/api/diets`, 'get')
            .then((res) => res as IDatabaseDiet[]);
    }
    
    public fetchDietsWithFoods = async () => {
        return await apiService.get(`/api/diets/withFoods`)
            .then((res) => res)
            .catch((err) => ErrorService.handleApiError(err));
    }

    public fetchDiet = async (dietId: number) => {
        return await apiService.get(`/api/diets/${dietId}`)
            .then((res) => res)
            .catch((err) => ErrorService.handleApiError(err));
    }

    public getRecommendedDiets = async () => {
        return await apiService.get(`/api/diets/getRecommended`)
            .then((res) => res)
            .catch((err) => ErrorService.handleApiError(err));
    }
}

export default new DietService();