import { Button } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from 'components/common/Modal';
import { IDUser } from "interfaces/Database";
import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import translationService from "services/translation.service";
import userAuthService from "services/user-auth.service";

const TS = 'profile/patient/mySpecialInformation.';

const ModalEditSpecificInfo: React.FC<({
    showModal: boolean
    handleClose: () => void
    handleSuccess: () => void
    currentUser?: IDUser
})> = ({ showModal, handleClose, handleSuccess, currentUser }) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ specialInformation, setSpecialInformation ] = useState<string>();

    const textarea: CSSProperties = {
        width: '100%',
        height: "100%",
        padding: "1em",
        borderRadius: "25px",
        border: '1px solid var(--dashboard-main-color)',

    }

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'auto';
    }

    useEffect(() => {
        setSpecialInformation(currentUser?.patientInfo?.specialInformation);
    }, [ currentUser ]);

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, []);

    const handleEditUser = () => {
        setIsLoading(true);
        userAuthService.updateUserInformations({ specialInformation: specialInformation })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={translationService.translate(TS + "titleModal")}
            footer={
                <div className="flex-row flex-end">
                    <Button label={translationService.translate(TS+'cancel')} onClick={() => closeModal()} />
                    <Button label={translationService.translate(TS+'save')} onClick={() => handleEditUser()} />
                </div>
            }
        >
            <Fragment>
                <textarea
                    disabled={isLoading}
                    style={textarea}
                    defaultValue={currentUser?.patientInfo?.specialInformation}
                    placeholder={translationService.translate(TS + "placeholder")}
                    onChange={(e) => setSpecialInformation(e.target.value)}
                />
                <ErrorMessage error={error} />
            </Fragment> 
        </Modal>
    );
}

export default ModalEditSpecificInfo;