import { AddIcon, Button, CheckIcon, EditIcon } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import { UserContext } from 'context/User/UserContext';
import { IDAppointment, IDGeneralComment, IDNutritionalComment, IDPrescription, IDPreventiveAdvice, IDUser } from "interfaces/Database";
import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';
import fileService from 'services/file.service';
import generalCommentService from "services/generalComment.service";
import nutritionalCommentService from "services/nutritionalComment.service";
import prescriptionService from 'services/prescription.service';
import preventiveAdviceService from "services/preventiveAdvice.service";
import userAuthService from 'services/user-auth.service';
import Info from '../common/Info';
import '../scss/NextAppointment.scss';

interface INextAppointmentStep {
    isNutritionistEditable: boolean;
    path: string;
    content: React.ReactNode;
    hasValue: boolean;
}

const NextAppointment: React.FC<({
    appointment?: IDAppointment;
    patient?: IDUser;
    generalComment?: IDGeneralComment;
    prescription?: IDPrescription;
    preventiveAdvice?: IDPreventiveAdvice;
    nutritionalComment?: IDNutritionalComment;
})> = ({
    patient,
    appointment,
    generalComment,
    prescription,
    preventiveAdvice,
    nutritionalComment
}) => {
    const t = useTranslation('patientDetail');
    const { user } = useContext(UserContext);
    const history = useHistory();

    const [ isMeeting, setIsMeeting ] = useState<boolean>(appointment?.meetingUrl ? true : false);

    const fetchPrescription = async () => {
        if (prescription?.id) {
            await prescriptionService.getPrescriptionPDF(prescription.id)
                .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-prescription` }))
                .catch((err) => console.warn(err));
        }
    }

    const fetchDietarySupplement = async () => {
        if (prescription?.id) {
            await prescriptionService.getDietarySupplementPDF(prescription.id)
                .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-dietary_supplement` }))
                .catch((err) => console.warn(err));
        }
    }

    const fetchGeneralComment = async () => {
        if (generalComment?.id) {
            await generalCommentService.getGeneralCommentPDF(generalComment?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-general_comment` }))
                .catch((err) => console.warn(err));
        }
    }

    const fetchPreventiveAdvice = async () => {
        if (preventiveAdvice?.id) {
            await preventiveAdviceService.getPreventiveAdvicePDF(preventiveAdvice?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-preventive_advice`.toLowerCase().replaceAll(' ', '_') }))
                .catch((err) => console.warn(err));
        }
    }

    const fetchNutritionalComment = async () => {
        if (nutritionalComment?.id) {
            await nutritionalCommentService.getNutritionalCommentPDF(nutritionalComment?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-nutritional_comment`.toLowerCase().replaceAll(' ', '_') }))
                .catch((err) => console.warn(err));
        }
    }

    const handleVideoMeeting = async () => {
        if (isMeeting && appointment?.id) {
            try {
                const result = await userAuthService.getOrCreateMeeting(appointment?.id);
                if (result.code === 200) {
                    const patientId = appointment?.subscriptionPlan?.patient?.id;

                    const roomId = result.roomUrl.split('/')[3];
                    window.open(`${window.location.origin}/account/meeting/withpatient/${patientId}/${roomId}`);
                } else {
                    setIsMeeting(false);
                }
            } catch (err) {
                setIsMeeting(false)
                console.warn(err);
            }
        }
    }

    const handleActionButton = (step: INextAppointmentStep) => {
        if (user?.isDoctorOf(patient)) {
            return step.hasValue
                ? <EditIcon
                    className="clickable"
                    onClick={() => history.push(step.path)}
                />
                : <AddIcon
                    className='clickable'
                    onClick={() => history.push(`${step.path}/new`)}
                />
        }

        if (user?.isNutritionistOf(patient) && step.isNutritionistEditable) {
            return step.hasValue
                ? <EditIcon
                    className="clickable"
                    onClick={() => history.push(step.path)}
                />
                : <AddIcon
                    className='clickable'
                    onClick={() => history.push(`${step.path}/new`)}
                />
        }

        return null;
    }

    const steps: INextAppointmentStep[] = [
        {
            isNutritionistEditable: false,
            content: <Info
                text={t.translate("nextAppointment.prescription")}
                isOk={prescription ? true : false}
                onClick={prescription ? fetchPrescription : undefined}
            />,
            path: `/account/patient/${patient?.id}/prescription/${appointment?.id}`,
            hasValue: prescription ? true : false
        },
        {
            isNutritionistEditable: true,
            content: <Info
                text={t.translate("nextAppointment.dietarySupplement")}
                isOk={prescription ? true : false}
                onClick={prescription ? fetchDietarySupplement : undefined}
            />,
            path: `/account/patient/${patient?.id}/dietarySupplement/${appointment?.id}`,
            hasValue: prescription ? true : false
        },
        {
            isNutritionistEditable: false,
            content: <Info
                text={t.translate("nextAppointment.generalComment")}
                isOk={generalComment ? true : false}
                onClick={generalComment ? fetchGeneralComment : undefined}
            />,
            path: `/account/patient/${patient?.id}/general-comment/${appointment?.id}`,
            hasValue: generalComment ? true : false
        },
        {
            isNutritionistEditable: false,
            content: <Info
                text={t.translate("nextAppointment.preventiveAdvice")}
                isOk={preventiveAdvice ? true : false}
                onClick={preventiveAdvice ? fetchPreventiveAdvice : undefined}
            />,
            path: `/account/patient/${patient?.id}/preventive-advice/${appointment?.id}`,
            hasValue: preventiveAdvice ? true : false
        },
        {
            isNutritionistEditable: true,
            content: <Info
                text={t.translate("nextAppointment.nutritiousComment")}
                isOk={nutritionalComment ? true : false}
                onClick={nutritionalComment ? fetchNutritionalComment : undefined}
            />,
            path: `/account/patient/${patient?.id}/nutritious-comment/${appointment?.id}`,
            hasValue: nutritionalComment ? true : false
        }
    ];

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h2 className='bold'>{t.translate("nextAppointment.title")}</h2>
                    {appointment
                        && <Fragment>
                            <p className='MavenPro' style={{ marginRight: "1vw" }}>
                                {appointment.appointmentOn
                                    ? appointmentService.getFormattedDate(appointment.appointmentOn)
                                    : null}
                            </p>
                            {user?.isDoctorOf(patient) && <Button
                                label={t.translate("nextAppointment.meeting")}
                                disabled={(steps.filter((step) => !step.hasValue).length > 0) || !isMeeting}
                                onClick={handleVideoMeeting}
                            />}
                        </Fragment>
                    }
                </Fragment>
            }
            body={appointment
                ? <Fragment>
                    <div className='flex-row align-items-stretch full-width space-around flex-wrap' style={{ padding: '20px 0' }}>
                        {steps.map((step, index) =>
                            <div
                                key={`next-appointment-step-${index}`}
                                className='flex-column shadow'
                                style={{
                                    borderRadius: '10px',
                                    padding: '1vh',
                                    marginBottom: '1vh',
                                    flexGrow: 1,
                                    maxWidth: '25%',
                                    minWidth: '300px',
                                    margin: '20px 20px'
                                }}
                            >
                                <div className="step"
                                    style={{
                                        paddingBottom: '1vh'
                                    }}
                                >
                                    {step.hasValue
                                        ? <CheckIcon />
                                        : null
                                    }
                                </div>
                                <div style={{ marginBottom: '2vh' }}>
                                    {step.content}
                                </div>
                                {handleActionButton(step)}
                            </div>
                        )}
                    </div>
                    <ErrorMessage
                        error={steps.filter((step) => !step.hasValue).length > 0
                            ? 'Vous devez remplir tous les documents afin de lancer la visio'
                            : undefined
                        }
                        withTranslation={false}
                    />
                </Fragment>
                : <p className="no-data">{t.translate("nextAppointment.noAppointment")}</p>
            }
        />
    )
}

export default NextAppointment;