import { EditIcon, InformationWrapper, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { EditProfilePicture } from "components/common/ProfilePicture";
import { UserContext } from "context/User/UserContext";
import moment from 'moment';
import 'moment-timezone';
import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "services";
import "../scss/GeneralInformation.scss";
import ModalEditProfileInfo from "./modal/ModalEditProfileInfo";

const GeneralInformation: React.FC = () => {
    const t = useTranslation('profile/patient/myGeneralInformation');
    const { user, refreshUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);

    const getFormatedBirthDate = () => {
        if (user?.patientInfo?.birthDate) {
            return moment(user.patientInfo.birthDate).format('DD/MM/YYYY');
        } else {
            return undefined;
        }
    }

    const refreshData = async () => {
        setIsLoading(true);
        setShowModal(false);

        refreshUser()
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold">{t.translate("title")}</h1>
                    <EditIcon
                        className="clickable"
                        onClick={() => setShowModal(true)}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    { isLoading
                        ? <div className="column loader-container">
                            <Loader />
                        </div>
                        : <div className="row" id="general-information-wrapper">
                            <div id="profile-picture-container">
                                <EditProfilePicture />
                            </div>
                            <div className='row' id="list-information-wrapper">
                                <div className="column information-wrapper">
                                    <InformationWrapper
                                        libelle={t.translate("name")}
                                        value={`${user?.patientInfo?.firstname} ${user?.patientInfo?.lastname}`}
                                    />
                                </div>
                                <div className="column information-wrapper">
                                    <InformationWrapper
                                        libelle={t.translate("birthdate")}
                                        value={getFormatedBirthDate()}
                                    />
                                </div>
                                <div className="column information-wrapper">
                                    <InformationWrapper
                                        libelle={t.translate("genre")}
                                        value={
                                            user?.patientInfo?.gender?.name
                                                ? t.translateGender(user?.patientInfo?.gender?.name)
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="column information-wrapper">
                                    <InformationWrapper
                                        libelle={t.translate("society")}
                                        value={user?.company?.companyInfo?.name}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {user
                        ? <ModalEditProfileInfo
                            showModal={showModal}
                            handleClose={() => setShowModal(false)}
                            handleSuccess={() => refreshData()}
                            currentUser={user}
                        />
                        : null
                    }
                </Fragment>
            }
        />
    );
}

export default GeneralInformation;