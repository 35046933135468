import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDAnalysisKit } from "interfaces/Database";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import analysisKitService from "services/analysisKit.service";
import fileService from "services/file.service";
import "./styles/PreviousAnalysis.scss";

const PreviousAnalysis: React.FC = () => {
    const t = useTranslation('dashboard/patient/testResults');
    const [ isDownloading, setIsDownloading ] = useState<number>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string|undefined>();
    const [ previousAnalysis, setPreviousAnalysis ] = useState<IDAnalysisKit[]>([]);

    const getPreviousAnalysis = () => {
        setIsLoading(true);
        setError(undefined);

        analysisKitService.getPreviousAnalysisKits()
            .then((res) => setPreviousAnalysis(res))
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        getPreviousAnalysis();
    }, []);

    const fetchResult = async (analysisKitId: number) => {
        setIsDownloading(analysisKitId);

        analysisKitService.getAnalysisKitResultsPdf(analysisKitId)
            .then((res) => fileService.downloadFile(res, { addPre: true }))
            .catch((err) => console.warn(err))
            .finally(() => setIsDownloading(undefined));
    }

    const renderAnalysis = () => {
        if (isLoading) {
            return (
                <Loader />
            )
        } else if (error) {
            return (
                <div>
                    <p style={{ color: 'red' }}>{t.translate('previousAnalysis.error')}</p>
                    <small>{error}</small>
                </div>
            )
        } else if (previousAnalysis.length === 0) {
            return (
                <p className="no-data" style={{ margin: '15px 0' }}>
                    {t.translate('previousAnalysis.none')}
                </p>
            )
        } else {
            return <div className="flex-column">
                {previousAnalysis.map((analysis, index) =>
                    <div
                        key={index}
                        className="flex-row"
                        style={{
                            backgroundColor: 'var(--light-gray2)',
                            padding: '1vh 2vw'
                        }}
                    >
                        <p style={{ marginRight: '20px' }}>{t.translateReplaceValues('previousAnalysis.analysisKit', [
                            {
                                tag: '{{analysisKitId}}',
                                value: analysis.verificationCode
                            }
                        ])}</p>
                        <span className="MavenPro-black" style={{ margin: '0 20px' }}>
                            {t.translateDate(new Date(analysis.microbiotaResultsReceivedOn))}
                        </span>
                        <Button
                            style={{ marginLeft: '20px' }}
                            label={t.translate('previousAnalysis.download')}
                            onClick={() => fetchResult(analysis.id)}
                            disabled={isDownloading !== undefined}
                            displayLoader={isDownloading === analysis.id}
                        />
                    </div>
                )}
            </div>
        }
    }

    return (
        <DefaultContainer
            title={
                <h1 className="bold">
                    {t.translate('previousAnalysis.title')}
                </h1>
            }
            body={renderAnalysis()}
        />
    );
}

export default PreviousAnalysis;