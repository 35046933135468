import { IIntakeSchedule, defaultIntakeSchedule } from "interfaces/IIntakeSchedule";
import { IDatabaseComplement } from "./IDatabaseComplement";

export interface IDatabaseDietarySupplement {
    id: number;
    name: string;
    description?: string;
    quantity: number;
    intakeSchedule: IIntakeSchedule;
    complement?: IDatabaseComplement;
}


export const defaultDietarySupplement: IDatabaseDietarySupplement = {
    id: 0,
    name: '',
    description: '',
    quantity: 0,
    intakeSchedule: defaultIntakeSchedule,
    complement: undefined
} 