import { Button } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDAnalysisKit, IDPrescription } from "interfaces/Database";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import fileService from "services/file.service";
import prescriptionService from "services/prescription.service";
import "../scss/LastPrescription.scss";

const LastPrescription: React.FC<({
    prescription?: IDPrescription
})> = ({ prescription }) => {
    const t = useTranslation('dashboard/patient/prescription');
    const [ isDownloading, setIsDownloading ] = useState<'prescription' | 'dietarySupplement'>();
    const [ prescriptionAnalysisKit, setPrescriptionAnalysisKit ] = useState<IDAnalysisKit>();

    useEffect(() => {
        const analysisKits = prescription?.doctorAppointment?.subscriptionPlan.analysisKits ?? [];
        setPrescriptionAnalysisKit(analysisKits[analysisKits.length-1]);
    }, [ prescription ]);

    const handleDownloadPrescription = () => {
        if (prescription) {
            setIsDownloading('prescription');

            prescriptionService.getPrescriptionPDF(prescription?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${prescription?.patient?.patientInfo?.firstname}_${prescription?.patient?.patientInfo?.lastname}-prescription_${prescription.id}` }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(undefined));
        }
    }

    const handleDownloadDietarySupplement = () => {
        if (prescription) {
            setIsDownloading('dietarySupplement');

            prescriptionService.getDietarySupplementPDF(prescription?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${prescription?.patient?.patientInfo?.firstname}_${prescription?.patient?.patientInfo?.lastname}-dietary_supplement_${prescription.id}` }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(undefined));
        }
    }

    return (
        <DefaultContainer
            title={
                <div className="flex-column-start">
                    <h1 className="container-title bold">{t.translate('last-prescription.title')}</h1>
                    {prescriptionAnalysisKit
                        && <i>
                            {t.translateReplaceValues('last-prescription.analysisKit', [
                                {
                                    tag: '{{analysisKitId}}',
                                    value: prescriptionAnalysisKit?.verificationCode
                                }
                            ])}
                        </i>
                    }
                </div>
            }
            body={prescription
                ? <div className="flex-row">
                    <div className="flex-column" style={{ margin: '0 2vw' }}>
                        <p className="MavenPro-black text-align-center">{t.translate('last-prescription.prescription')}</p>
                        <i>
                            {t.translate('last-prescription.written-by') + ' '}
                            {doctorService.formatDoctorName(prescription?.doctor?.doctorInfo)}
                        </i>
                        <span>
                            {t.translateReplaceValues('last-prescription.didAt', [
                                {
                                    tag: '{{date}}',
                                    value: t.translateDate(prescription.updatedAt)
                                }
                            ])}
                        </span>
                        {!prescription.prescriptionMedications || prescription.prescriptionMedications?.length === 0
                            ? <i className="text-align-center" style={{ margin: '1vh 0' }} >{t.translate('last-prescription.noPrescription')}</i>
                            : <Button
                                label={t.translate('last-prescription.button-label')}
                                disabled={isDownloading !== undefined}
                                displayLoader={isDownloading === 'prescription'}
                                onClick={handleDownloadPrescription}
                                style={{
                                    margin: '1vh 0'
                                }}
                            />
                        }
                    </div>
                    <div className="flex-column" style={{ margin: '0 2vw' }}>
                        <p className="MavenPro-black text-align-center">{t.translate('last-prescription.dietaryComplement')}</p>
                        <i>
                            {t.translate('last-prescription.written-by') + ' '}
                            {doctorService.formatDoctorName(prescription?.doctor?.doctorInfo)}
                        </i>
                        <span>
                            {t.translateReplaceValues('last-prescription.didAt', [
                                {
                                    tag: '{{date}}',
                                    value: t.translateDate(prescription.updatedAt)
                                }
                            ])}
                        </span>
                        {!prescription.dietarySupplements || prescription.dietarySupplements?.length === 0
                            ? <i className="text-align-center" style={{ margin: '1vh 0' }} >{t.translate('last-prescription.noComplement')}</i>
                            : <Button
                                label={t.translate('last-prescription.button-label')}
                                disabled={isDownloading !== undefined}
                                displayLoader={isDownloading === 'dietarySupplement'}
                                onClick={handleDownloadDietarySupplement}
                                style={{
                                    margin: '1vh 0'
                                }}
                            />
                        }
                    </div>
                </div>
                : <p className="no-data">
                    {t.translate('last-prescription.no-prescription')}
                </p>
            }
        />
    );
}

export default LastPrescription;