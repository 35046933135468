import { Button, CloseIcon, Loader } from "components";
import HistoryActions from "components/DashboardHome/components/Doctor/components/HistoryActions";
import HistoryAppointments from "components/DashboardHome/components/Doctor/components/HistoryAppointments";
import InternalChat from "components/DashboardHome/components/Doctor/components/InternalChat";
import 'components/DashboardHome/scss/DoctorDashboard.scss';
import AllMyAppointments from "components/common/Dashboard/Home/Doctor/AllMyAppointments";
import NextAppointment from "components/common/Dashboard/Home/Doctor/NextAppointment";
import Help from "components/common/Dashboard/Home/Help/Help";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import { UserContext } from "context/User/UserContext";
import { IDAppointment, IDAppointmentWithNutritionist } from "interfaces/Database";
import { IDatabaseHistory } from "interfaces/Database/IDatabaseHistory";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import useLogService from "services/api/log.service";
import doctorService from "services/doctor.service";

const DoctorDashboardHomePage: React.FC = () => {
    const t = useTranslation('dashboard/doctor/doctorDashboard');
    const { user } = useContext(UserContext);

    const logService = useLogService();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ showHelp, setShowHelp ] = useState<boolean>(user?.firstLogin ?? true);
    const toggleHelp = () => setShowHelp(!showHelp);

    const [ nextAppointment, setNextAppointment ] = useState<IDAppointment | IDAppointmentWithNutritionist>();
    const [ allNextAppointments, setAllNextAppointments ] = useState<(IDAppointment | IDAppointmentWithNutritionist)[]>([]);
    const [ historyActions, setHistoryActions ] = useState<IDatabaseHistory[]>([]);
    const [ lastAppointments, setLastAppointments ] = useState<(IDAppointment | IDAppointmentWithNutritionist)[]>([]);
    const [ isMeeting, setIsMeeting ] = useState<boolean>(false);

    async function reloadNextAppointments() {
        setIsLoading(true);

        doctorService.getNextAppointments()
            .then((res) => {
                if (res && res.length > 0) {
                    setNextAppointment(res[0]);
                    setAllNextAppointments(res);
                    setIsMeeting(true);
                } else {
                    setNextAppointment(undefined);
                    setAllNextAppointments([]);
                    setIsMeeting(false);
                }
            })
            .catch(() => {
                toast.error(t.translate('nextAppointment.error'));
                setNextAppointment(undefined);
                setAllNextAppointments([]);
                setIsMeeting(false);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
    
            Promise.all([
                doctorService.getNextAppointments(),
                doctorService.getPreviousAppointments()
                    .catch(() => []),
                doctorService.getPreviousAppointmentsWithNutritionist()
                    .catch(() => []),
                logService.getHistory()
                    .then((res) => setHistoryActions(res))
            ])
                .then(
                    (
                        [
                            nextAppointments,
                            previousAppointments,
                            previousAppointmentsNutritionist,
                        ]
                    ) => {
                        setAllNextAppointments(nextAppointments);

                        if (nextAppointments.length > 0) {
                            setNextAppointment(nextAppointments[0]);
                            setIsMeeting(true);
                        }

                        // Merge previous appointments with doctors & nutritionists
                        let tempPrev = [...previousAppointments, ...previousAppointmentsNutritionist];
                        tempPrev = tempPrev.sort((a, b) => {
                            if (!a.appointmentOn && !b.appointmentOn) return 0;
                            if (!a.appointmentOn) return -1;
                            if (!b.appointmentOn) return 1;

                            return a.appointmentOn < b.appointmentOn ? 1 : -1;
                        });

                        setLastAppointments(tempPrev);
                    }
                )
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }

        fetchData();
    }, []);

    return (
        <div className="flex-column" style={{ minHeight: "60vh" }}>
            <DashboardHeader
                preTitle={t.translate("pre")}
                title={t.translate("title")}
                button={!showHelp
                    ? <Button label={t.translate('help')} onClick={() => toggleHelp()} />
                    : !user?.firstLogin
                        ? <CloseIcon
                            className="clickable"
                            onClick={() => toggleHelp()}
                        />
                        : undefined
                }
            />
            <div className="flex-column full-width flex-grow">
                {isLoading
                    ? <Loader />
                    : showHelp
                        ? <Help toggleHelp={toggleHelp} type="Doctor" />
                        : <Fragment>
                            <NextAppointment
                                nextAppointment={nextAppointment}
                                isMeeting={isMeeting}
                                reloadNextAppointment={reloadNextAppointments}
                            />
                            <AllMyAppointments appointments={allNextAppointments} />
                            <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                                <HistoryAppointments lastAppointments={lastAppointments} />
                                <HistoryActions historyActions={historyActions} />
                            </div>
                            <InternalChat />
                        </Fragment>
                }
            </div>
        </div>
    )
}

export default DoctorDashboardHomePage;