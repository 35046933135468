import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDAnalysisKit } from "interfaces/Database";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import fileService from "services/file.service";
import resultsService from "services/results.service";
import translationService from "services/translation.service";
import '../scss/TestResult.scss';

const TS = "patientDetail.resultTests.";
interface CurrentDownload {
    id: number,
    type: 'patient' | 'doctor'
}

const TestResult: React.FC = () => {
    const { patientId } = useParams<{ patientId: string }>();

    const t = useTranslation('doctorPatients');

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isDownloading, setIsDownloading ] = useState<CurrentDownload>();
    const [ results, setResults ] = useState<IDAnalysisKit[]>([]);

    const fetchResults = useCallback(() => {
        setIsLoading(true);

        doctorService.getPatientResults(patientId)
            .then((res) => setResults(res))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchResults();
    }, [fetchResults]);

    const fetchResult = async (analysisKitId: number, forDoctor: boolean = false) => {
        setIsDownloading({
            id: analysisKitId,
            type: forDoctor ? 'doctor' : 'patient'
        });

        resultsService.retrieveResultsForKit(analysisKitId, forDoctor)
            .then((res) => fileService.downloadFile(res.pdf, { filename: 'results' }))
            .catch((err) => console.warn(err))
            .finally(() => setIsDownloading(undefined));
    }

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{translationService.translate(TS + "title")}</h1>}
            body={isLoading
                ? <Loader />
                : results.length === 0
                    ? <p>{t.translate('testResults.noPrevious')}</p>
                    : results.map((result) => (
                        <div key={`patient-kit-${result.verificationCode}`} className='test-result'>
                            <div className='title-test-result row space-between'>
                                <p>#{result.verificationCode}</p>
                                <p className="divider"/>
                                <p>{result.generalResultsReceivedOn && translationService.translateDate(result.generalResultsReceivedOn)}</p>
                            </div>
                            <div className="downloadable row space-around">
                                <div className="card-downloadable column space-between">
                                    <p className="bold text-align-center">
                                        {translationService.translate(TS + "testReport")}
                                    </p>
                                    <Button
                                        label={translationService.translate(TS + "download")}
                                        onClick={() => fetchResult(result.id)}
                                        disabled={
                                            !!isDownloading ||
                                            !(result.labStatus === 4)
                                        }
                                        displayLoader={
                                            !!isDownloading &&
                                            isDownloading.id === result.id &&
                                            isDownloading.type === 'patient'
                                        }
                                    />
                                </div>
                                <div className="card-downloadable column space-between">
                                    <p className="bold text-align-center">
                                        {translationService.translate(TS + "detailedReport")}
                                    </p>
                                    <Button
                                        label={translationService.translate(TS + "download")}
                                        onClick={() => fetchResult(result.id, true)}
                                        disabled={
                                            !!isDownloading ||
                                            !(result.labStatus === 4)
                                        }
                                        displayLoader={
                                            !!isDownloading &&
                                            isDownloading.id === result.id &&
                                            isDownloading.type === 'doctor'
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ))
            }
        />
    )
}

export default TestResult;