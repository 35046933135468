import englishManual from "assets/Manual/manual_english-version.pdf";
import frenchManual from "assets/Manual/manual_french-version.pdf";
import bannerImg from "assets/how-it-works/how-it-works-banner.png";
import { LanguageContext } from "context/Language/LanguageContext";
import React, { useContext } from "react";
import { useTranslation } from "services";
import "../scss/HowItWorksBanner.scss";

const HowItWorksBanner: React.FC = () => {
    const t = useTranslation('how-it-works');
    const { language } = useContext(LanguageContext);

    const openManualPdf = async () => {
        switch (language.countryCode) {
            case "fr":
                window.open(frenchManual);
                break;
            case "en":
                window.open(englishManual);
                break;
            default:
                window.open(englishManual);
                break;
        }
    }

    return (
        <div id="how-it-works-banner" className="column">
            <div id="header-banner-wrapper" className="column">
                <img src={bannerImg} alt="banner" id="banner-img" />
                <div id="header-banner-text-wrapper" className="column">
                    <h1 id="title" className="MavenPro">
                        {t.translate("title-part1")}
                        <span className="MavenPro-black">
                            {' '}{t.translate("title-bold")}{' '}
                        </span>
                        {t.translate("title-part2")}
                    </h1>
                    <p id="sub-title">
                        {t.translate("sub-title")}
                    </p>
                </div>
            </div>
            <div id="instructions-wrapper" className="column">
                <p id="instructions-text" className="MavenPro-bold">
                    {t.translate("instructions")}
                </p>
                <div id="instructions-button" className="background-gradient" onClick={openManualPdf}>
                    <div id="instructions-button-wrapper">
                        <span className="MavenPro-bold text-gradient">
                            {t.translate("instructions-btn")}
                        </span>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HowItWorksBanner;