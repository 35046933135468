import { Loader } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from "components/common/Modal";
import ScheduleDayBreakForm from "components/common/ScheduleForms/ScheduleDayBreakForm";
import { Button, Label, Select, TimeInput } from "components/Form";
import { AddIcon, RemoveIcon } from "components/Icons";
import { Schedule, ScheduleCreate } from "interfaces/Database";
import { ScheduleDayName, scheduleDayNames } from "interfaces/Database/IDatabaseScheduleDay";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import useScheduleService from "services/api/Schedule.service";

interface EditScheduleProps {
    isShow: boolean;
    toggleShow: () => void;
    previous?: Schedule;
    onSuccess: (updatedSchedule: Schedule) => void;
}

export interface FormError {
    field: 'day' | 'start' | 'end' | 'time';
    index: number;
    subIndex?: number;
    error: string;
}

const EditSchedule: React.FC<EditScheduleProps> = (props) => {
    const scheduleService = useScheduleService();

    const t = useTranslation('components/schedule');

    const [ error, setError ] = useState<string>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ formErrors, setFormErrors ] = useState<FormError[]>([]);
    const [ selectedDay, setSelectedDay ] = useState<number>();

    const addFormError = (newError: FormError) => {
        setFormErrors((prev) => [...prev, newError]);
    }

    const [ newSchedule, setNewSchedule ] = useState<ScheduleCreate>(new ScheduleCreate({}));

    useEffect(() => {
        if (props.previous) {
            setNewSchedule(new ScheduleCreate(props.previous));
        }
    }, [props.previous, props.isShow]);

    const isBetween = (value: number, low_norm: number, high_norm: number) => {
        return (value < high_norm) && (value >= low_norm);
    }

    useEffect(() => {
        setError(undefined);
        setFormErrors([]);

        if (newSchedule.days.length > 0) {
            newSchedule.days.forEach((scheduleDay, index, arr) => {
                if (arr.filter((e) => e.day !== undefined && e.day === scheduleDay.day).length > 1) {
                    if (scheduleDay.day) {
                        addFormError({
                            index: index,
                            field: 'day',
                            error: t.translateReplaceValues('editModal.error.duplicateDay', [
                                {
                                    tag: '{{day}}',
                                    value: t.translateDayName(scheduleDay.day)
                                }
                            ])
                        });

                        setError('schedule_duplicate_day');
                    }
                }

                if (scheduleDay.startHour === undefined && !scheduleDay.startMinute) {
                    addFormError({
                        index: index,
                        field: 'start',
                        error: t.translate('editModal.error.missingStart')
                    });
                }

                if (scheduleDay.endHour === undefined && !scheduleDay.endMinute) {
                    addFormError({
                        index: index,
                        field: 'end',
                        error: t.translate('editModal.error.missingEnd')
                    });
                }

                if (!!scheduleDay.start && !!scheduleDay.end) {
                    if (scheduleDay.start > scheduleDay.end) {
                        addFormError({
                            index: index,
                            field: 'time',
                            error: t.translate('editModal.error.endBeforeStarts')
                        });
                    }
                }

                if (scheduleDay.breaks.length > 0) {
                    scheduleDay.breaks.forEach((dayBreak, db_index, db_arr) => {
                        // checks if all good set
                        if (dayBreak.startHour === undefined && !dayBreak.startMinute) {
                            addFormError({
                                index: index,
                                subIndex: db_index,
                                field: 'start',
                                error: t.translate('editModal.error.missingStart')
                            });
                        }
        
                        if (dayBreak.endHour === undefined && !dayBreak.endMinute) {
                            addFormError({
                                index: index,
                                subIndex: db_index,
                                field: 'end',
                                error: t.translate('editModal.error.missingEnd')
                            });
                        }

                        if (!!dayBreak.start && !!dayBreak.end) {
                            if (dayBreak.start > dayBreak.end) {
                                addFormError({
                                    index: index,
                                    subIndex: db_index,
                                    field: 'time',
                                    error: t.translate('editModal.error.endBeforeStarts')
                                });
                            }

                            // checks if no overlap
                            // gotta check if another element has a starting or ending hour in current delimitation
                            const filtered = db_arr.filter((e, db_arr_idx) => {
                                if (
                                    db_index !== db_arr_idx &&
                                    e.start && e.end && dayBreak.start && dayBreak.end &&
                                    (isBetween(e.start, dayBreak.start, dayBreak.end) || isBetween(e.end, dayBreak.start, dayBreak.end))
                                ) {
                                    return e;
                                } return;
                            });

                            if (filtered.length > 0) {
                                addFormError({
                                    index: index,
                                    subIndex: db_index,
                                    field: 'time',
                                    error: t.translate('editModal.error.breaksOverlap')
                                });
                            }
                        }
                    });
                }
            })
        }
    }, [ newSchedule ]);

    const handleSwitchDay = (newDayIndex: number) => {
        if (selectedDay === newDayIndex) {
            setSelectedDay(undefined);
        } else {
            setSelectedDay(newDayIndex);
        }
    }

    const handleAddDay = () => {
        newSchedule.addDay();
        setNewSchedule(new ScheduleCreate(newSchedule));
    }
    
    const handleDuplicateDay = (index: number) => {
        newSchedule.addDay(newSchedule.days[index]);
        setNewSchedule(new ScheduleCreate(newSchedule));

        handleSwitchDay(newSchedule.days.length-1);
    }

    const handleRemoveDay = (index: number) => {
        newSchedule.removeDay(index);
        setNewSchedule(new ScheduleCreate(newSchedule));
    }

    const handleUpdateScheduleDay = (index: number, e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setError(undefined);
        setFormErrors([]);

        const fieldNameSplitted = e.target.name.split('-');
        const fieldName = fieldNameSplitted[fieldNameSplitted.length-1];

        if (fieldName === 'hours') {
            if (e.target.name.includes('start')) {
                newSchedule.days[index].setStartHour(parseInt(e.target.value));
            } else {
                newSchedule.days[index].setEndHour(parseInt(e.target.value));
            }
        } else if (fieldName === 'minutes') {
            if (e.target.name.includes('start')) {
                newSchedule.days[index].setStartMinute(parseInt(e.target.value));
            } else {
                newSchedule.days[index].setEndMinute(parseInt(e.target.value));
            }
        } else {
            newSchedule.days[index].setDay(e.target.value as ScheduleDayName);
        }
        
        setNewSchedule(new ScheduleCreate(newSchedule));
    }

    const handleAddBreak = (dayIndex: number) => {
        newSchedule.days[dayIndex].addBreak();
        setNewSchedule(new ScheduleCreate(newSchedule));
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        setIsLoading(true);

        scheduleService.createOrUpdate(newSchedule)
            .then(props.onSuccess)
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={props.isShow}
            toggleShow={props.toggleShow}
            title={t.translate('editModal.title')}
            footer={
                <div className="flex-column">
                    <ErrorMessage error={error} />
                    <div className="flex-row">
                        <Button label={t.translate('editModal.addDay')} onClick={() => handleAddDay()} disabled={isLoading} />
                        <Button label={t.translate('editModal.save')} onClick={handleSubmit} disabled={formErrors.length > 0 || isLoading} />
                    </div>
                </div>
            }
        >
            {isLoading
                ? <Loader />
                : newSchedule?.days.map((scheduleDay, index) =>
                    <div key={`scheduleDay-${index}`} className="flex-column full-width flex-gap-row">
                        <div className="flex-row full-width space-between">
                            <div>
                                <h3 style={{ marginBottom: 0 }}>
                                    {scheduleDay.day
                                        ? t.translateDayName(scheduleDay.day)
                                        : t.translateReplaceValues('editModal.day', [
                                            {
                                                tag: '{{index}}',
                                                value: index
                                            }
                                        ])
                                    }
                                </h3>
                                <div className="flex-row flex-gap-col">
                                    <small className="text-main-color clickable" style={{ fontStyle: 'normal' }} onClick={() => handleDuplicateDay(index)}>{t.translate('editModal.duplicateDay')}</small>
                                    <small className="text-error clickable" style={{ fontStyle: 'normal' }} onClick={() => handleRemoveDay(index)}>{t.translate('editModal.removeDay')}</small>
                                </div>
                            </div>

                            {(scheduleDay.startHour !== undefined && scheduleDay.endHour !== undefined)
                                ? selectedDay !== index
                                    && <div className="flex-column">
                                        <p style={{margin:0}}>{t.translateReplaceValues('fromAt', [
                                            {
                                                tag: '{{from}}',
                                                value: scheduleDay.renderStart()
                                            },
                                            {
                                                tag: '{{at}}',
                                                value: scheduleDay.renderEnd()
                                            }
                                        ])}</p>
                                    </div>
                                : <p className="text-error">{t.translate('editModal.error.missingHours')}</p>
                            }

                            {selectedDay === index
                                ? <RemoveIcon className="clickable" onClick={() => handleSwitchDay(index)} />
                                : <AddIcon className="clickable" onClick={() => handleSwitchDay(index)} />
                            }
                        </div>
                        {selectedDay === index
                            && <Fragment>
                                <div className="full-width">
                                    <Label for={`scheduleDay-${index}-day`} label={t.translateFormField('day.label')} required />
                                    <Select
                                        required
                                        name={`scheduleDay-${index}-day`}
                                        value={scheduleDay.day}
                                        setValue={(e) => handleUpdateScheduleDay(index, e)}
                                        options={
                                            <Fragment>
                                                <option>{t.translateFormField('chooseOption')}</option>
                                                {scheduleDayNames.map((dayName, dayIndex) =>
                                                    <option key={`day-${dayIndex}`} value={dayName}>
                                                        {t.translateDayName(dayName)}
                                                    </option>
                                                )}
                                            </Fragment>
                                        }
                                    />
                                    <ErrorMessage withTranslation={false} error={formErrors.filter((e) => e.index === index && e.subIndex === undefined && e.field === `day`)[0]?.error} />
                                </div>
                                <div className="full-width">
                                    <Label for={`scheduleDay-${index}-start`} label={t.translateFormField('startHour.label')} required />
                                    <TimeInput
                                        name={`scheduleDay-${index}-start`}
                                        hours={scheduleDay.startHour}
                                        setHours={(e) => handleUpdateScheduleDay(index, e)}
                                        minutes={scheduleDay.startMinute}
                                        setMinutes={(e) => handleUpdateScheduleDay(index, e)}
                                    />
                                    <ErrorMessage withTranslation={false} error={formErrors.filter((e) => e.index === index && e.subIndex === undefined && e.field === `start`)[0]?.error} />
                                </div>
                                <div className="full-width">
                                    <Label for={`scheduleDay-${index}-end`} label={t.translateFormField('endHour.label')} required />
                                    <TimeInput
                                        name={`scheduleDay-${index}-end`}
                                        hours={scheduleDay.endHour}
                                        setHours={(e) => handleUpdateScheduleDay(index, e)}
                                        minutes={scheduleDay.endMinute}
                                        setMinutes={(e) => handleUpdateScheduleDay(index, e)}
                                    />
                                    <ErrorMessage withTranslation={false} error={formErrors.filter((e) => e.index === index && e.subIndex === undefined && e.field === `end`)[0]?.error} />
                                </div>

                                <ErrorMessage withTranslation={false} error={formErrors.filter((e) => e.index === index && e.subIndex === undefined && e.field === `time`)[0]?.error} />

                                <div className="flex-row full-width space-between">
                                    <h4>{t.translate('editModal.breaks')}</h4>

                                    <Button label={t.translate('editModal.addBreak')} onClick={() => handleAddBreak(index)} />
                                </div>
                                {scheduleDay.breaks.map((dayBreak, db_index) =>
                                    <ScheduleDayBreakForm
                                        key={`scheduleDay-${index}-dayBreak-${db_index}`}
                                        break={dayBreak}
                                        break_index={db_index}
                                        day_index={index}
                                        formErrors={formErrors}
                                        newSchedule={newSchedule}
                                        setNewSchedule={setNewSchedule}
                                    />
                                )}

                                <section style={{ width: '80%', height: '5px', backgroundColor: 'var(--dashboard-main-color)', borderRadius: '50px' }} />
                            </Fragment>
                        }
                    </div>
                )
            }
        </Modal>
    )
}

export default EditSchedule;