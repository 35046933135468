import FormContactUs from "components/ContactUs/components/FormContactUs";
import "components/ContactUs/scss/ContactUs.scss";
import React, { useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { toast } from 'react-toastify';
import { useTranslation } from "services";

const ContactUs: React.FC = () => {
    const t = useTranslation('contact-us');
    const [ isRequestSent, setIsRequestSent ] = useState<boolean>(false);

    const handleSuccessSendRequest = () => {
        setIsRequestSent(true);
        toast.success(`${t.translate('congrats')} ${t.translate('registered')}`, {
            theme: "light"
        });
    }

    return (
        <div id="contact-us" className="column">
            <div className="column" id="content">
                <div id="form-container" className="column">
                    {!isRequestSent
                        ? <FormContactUs handleSuccess={handleSuccessSendRequest} />
                        : <div className="column shadow" id="success-container">
                            <div className="column title-container">
                                <h1 id="title" className="font-title-bold">
                                    {t.translate('congrats')}
                                </h1>
                                <p className="sub-title">
                                    {t.translate('registered')}
                                </p>
                            </div>
                            <div className="row align-items-center space-center">
                                <p id="success-message">
                                    {t.translate('success')}
                                </p>
                                <BiCheckCircle color={"var(--dashboard-main-color)"} size={26} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ContactUs;