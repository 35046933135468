import { CloseIcon } from "components";
import React from "react";
import './scss/CustomErrorContainer.scss';

const CustomErrorContainer: React.FunctionComponent<({
    isShow: boolean,
    close: () => void,
    text?: string
})> = ({
    isShow,
    close,
    text = 'Une erreur est survenue.'
}) => {
    if (!isShow) {
        return null;
    } else {
        return (
            <div className='error-container-wrapper'>
                <p className='error-container-wrapper-text'>{text}</p>
                <CloseIcon onClick={() => close()} />
            </div>
        )
    }
}

export default CustomErrorContainer;