import { LeftArrowIcon } from 'components';
import React from "react";
import { useHistory } from "react-router-dom";

const CustomIconBack: React.FC<({ handleClick: (e: React.MouseEvent<SVGElement, MouseEvent>) => void })> = ({ handleClick = null }) => {

    const history = useHistory();

    return (
        <LeftArrowIcon
            onClick={(e) => handleClick ? handleClick(e) : history.go(-1)}
        />
    );
}

export default CustomIconBack;