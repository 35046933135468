import { Modal } from "components/common/Modal";
import React, { useState } from "react";
import { useTranslation } from "services";

const WhichCancer: React.FC = () => {
    const [ showMore, setShowMore ] = useState<boolean>(false);
    const toggleShowMore = () => setShowMore(!showMore);

    const t = useTranslation('dashboard/support');

    return (
        <div className="answer-wrapper">
            <p>{t.translate('frequent.questions.cancer_check.which-cancer.desc')}</p>
            <ul>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.melanoma')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.head_and_shoulders')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.salivary_glands')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.thyroid')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.lung')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.breast')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.liver')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.biliary')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.gastrointestinal')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.soft_tissue')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.osteosarcomas')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.mesothelioma')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.urinary_tract')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.gynecological')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.prostate')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.thymus')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.adrenals')}</li>
                <li>{t.translate('frequent.questions.cancer_check.which-cancer.central_nervous_system')}</li>
                <li className="clickable" onClick={() => toggleShowMore()}>
                    <p className="text-main-color">
                        {t.translate('frequent.questions.cancer_check.which-cancer.other')}
                    </p>
                </li>
            </ul>
            <Modal
                isShow={showMore}   
                toggleShow={toggleShowMore}
            >
                <ol>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.adenocarcinoma')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.squamous_cell_carcinoma')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.adenoid_cystic_carcinoma')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.neuroendocrine')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.renal_cell_carcinoma')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.transitional_cell_carcinoma')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.gastrointestinal_stromal_tumors')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.melanomas')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.mesothelioma_desc')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.sarcomas')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.small_cell_lung_cancer')}</li>
                    <li>{t.translate('frequent.questions.cancer_check.which-cancer.glioma')}</li>
                </ol>
            </Modal>
        </div>
    )
}

export default WhichCancer;