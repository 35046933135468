import { Button, Checkbox, Loader } from 'components';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';
import fileService from 'services/file.service';
import "../scss/VideoMeeting.scss";

export type MeetingDocument = 'prescription' | 'generalComment' | 'preventiveAdvice' | 'nutritionalComment' | 'survey' | 'report';

const VideoMeeting: React.FC<({
    appointmentWith?: string
    roomId: string
    isError?: boolean
    side: 'patient' | 'doctor'
})> = ({ appointmentWith, roomId, isError = false, side }) => {
    const t = useTranslation('pages/meeting');
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isDownloading, setIsDownloading ] = useState<MeetingDocument>();
    const [ downloadError, setDownloadError ] = useState<string>();

    const [ presentingSelf, setPresentingSelf ] = useState<boolean>(false);
    const [ presentingResults, setPresentingResults ] = useState<boolean>(false);
    const [ explainingSolutions, setExplainingSolutions ] = useState<boolean>(false);
    const [ questionAnswers, setQuestionAnswers ] = useState<boolean>(false);

    const progressSteps = [
        {
            isChecked: presentingSelf,
            setChecked: setPresentingSelf,
            name: 'presentingSelf',
            validation: !presentingSelf
        },
        {
            isChecked: presentingResults,
            setChecked: setPresentingResults,
            name: 'presentingResults',
            validation: (presentingSelf && !presentingResults)
        },
        {
            isChecked: explainingSolutions,
            setChecked: setExplainingSolutions,
            name: 'explainingSolutions',
            validation: (presentingResults && !explainingSolutions)
        },
        {
            isChecked: questionAnswers,
            setChecked: setQuestionAnswers,
            name: 'questionAnswers',
            validation: (explainingSolutions && !questionAnswers)
        }
    ];

    const getTodayDate = () => {
        const formatter = new Intl.DateTimeFormat('fr', { dateStyle: 'full' });
        return formatter.format(new Date(new Date()));
    }

    useEffect(() => {
        appointmentService.connectsToMeeting(roomId)
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    const handleDownload = (file: MeetingDocument) => {
        setDownloadError(undefined);
        setIsDownloading(file);

        appointmentService.getMeetingDocument(roomId, file, 'doctor')
            .then((res) => fileService.downloadFile(res, { filename: file }))
            .catch((err) => setDownloadError(err.message))
            .finally(() => setIsDownloading(undefined));
    }

    if (isLoading) {
        return <Loader loaderDescription={t.translate('loading')} />
    }

    if (isError) {
        return (
            <div id="error-wrapper" className="column">
                <div className="column" id="message-wrapper">
                    <p className="MavenPro-bold error-message">
                        {t.translate('error.desc')}
                    </p>
                    <p className="MavenPro-bold error-message">
                        {t.translate('error.contactUs')}
                    </p>
                </div>
                <Button label={t.translate('error.backBtn')} onClick={() => history.push("/account/appointments")} />
            </div>
        )
    } else {
        return (
            <div id="video-meeting" className="column">
                <div id="video-meeting-header" className="column">
                    <h1 id="title" className="MavenPro-bold">{t.translate('title')}</h1>
                    <div className="row space-between align-items-center">
                        <span className={`meeting-info ${!appointmentWith ? 'no-data' : ''}`}>
                            {appointmentWith ? appointmentWith : t.translateFromFile('common', 'no-data')}
                        </span>
                        <div id="spacer" />
                        <span className="meeting-info">
                            {getTodayDate()}
                        </span>
                    </div>
                </div>
                <div className='flex-grow flex-row flex-wrap flex-gap-col full-width align-items-stretch' style={{ boxSizing: 'border-box', padding: '0 5vw' }}>
                    {side === 'doctor'
                        && <div className='flex-column' style={{ width: '30%', minWidth: '460px', justifyContent: 'start' }}>
                            <h2 className='text-main-color'>{t.translate('appointmentProgress.title')}</h2>
                            {progressSteps.map((step, index) => 
                                <div key={index} className='flex-row full-width align-items-stretch'>
                                    <Checkbox
                                        name={step.name}
                                        isChecked={step.isChecked}
                                        setChecked={step.setChecked}
                                    />
                                    <div className='flex-column flex-grow' style={{ paddingLeft: '15px' }}>
                                        <div className='flex-row-start full-width justify-between'>
                                            <h3 style={{ margin: 0 }}>{t.translate(`appointmentProgress.steps.${step.name}.title`)}</h3>
                                            <span>{t.translate(`appointmentProgress.steps.${step.name}.duration`)}</span>
                                        </div>
                                        {step.validation && <p style={{ textAlign: 'justify' }}>{t.translate(`appointmentProgress.steps.${step.name}.description`)}</p>}
                                    </div>
                                </div>
                            )}
                            {(presentingSelf && presentingResults && explainingSolutions && questionAnswers)
                                && <div className='flex-column'>
                                    <div className='flex-row text-main-color'>
                                        <IoIosCheckmarkCircle size={20} />
                                        <h3 className='bold' style={{ marginLeft: '10px' }}>{t.translate('appointmentProgress.done.title')}</h3>
                                    </div>
                                    <p className='text-align-center'>{t.translate('appointmentProgress.done.sub')}</p>
                                    <p className='text-align-center'>{t.translate('appointmentProgress.done.final')}</p>
                                </div>
                            }
                        </div>
                    }
                    <iframe
                        title="Doctor's meeting"
                        src={`https://bodycheckup.whereby.com/${roomId}`}
                        allow="camera; microphone; fullscreen; speaker; display-capture"
                        className="shadow"
                        style={{
                            marginBottom: 0,
                            width: '60%',
                            height: 'auto'
                        }}
                    />
                </div>
                <hr style={{ backgroundColor: 'var(--dashboard-main-color)', border: 0, height: '2px', width: '60%' }} />
                <h4 style={{ marginBottom: '0' }}>{t.translate('documents.title')}</h4>
                <div className='flex-row flex-wrap full-width flex-gap-col flex-gap-row' style={{ boxSizing: 'border-box', padding: '2vh 5vw' }}>
                    <Button
                        label={t.translate('documents.prescription')}
                        onClick={() => handleDownload('prescription')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'prescription'}
                    />
                    <Button
                        label={t.translate('documents.generalComment')}
                        onClick={() => handleDownload('generalComment')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'generalComment'}
                    />
                    <Button
                        label={t.translate('documents.preventiveAdvice')}
                        onClick={() => handleDownload('preventiveAdvice')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'preventiveAdvice'}
                    />
                    <Button
                        label={t.translate('documents.nutritionalComment')}
                        onClick={() => handleDownload('nutritionalComment')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'nutritionalComment'}
                    />
                    <Button
                        label={t.translate('documents.survey')}
                        onClick={() => handleDownload('survey')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'survey'}
                    />
                    <Button
                        label={t.translate('documents.report')}
                        onClick={() => handleDownload('report')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'report'}
                    />
                </div>
                <ErrorMessage error={downloadError} />
            </div>
        );
    }
}

export default VideoMeeting;