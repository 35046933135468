import { IDAddress, IDParcelStatus, IDParcelType } from ".";

export interface IDatabaseParcel {
    id                      ?: number;
    expectedShippedDate     ?: Date;
    shippedOn               ?: Date;
    status                  ?: IDParcelStatus;
    statusModifiedOn        ?: Date;
    createdOn               ?: Date;
    base64PDFLabel          ?: string;
    type                    ?: IDParcelType;
    shipmentPrice           ?: number;
    shipmentType            ?: string;
    shipmentPriceCurrency   ?: string;
    shipmentAddress         ?: IDAddress;
    trackingNumber          ?: string;
    pickupDHLNumber         ?: string;
    statusDate              ?: Date;
}

export default class DatabaseParcel implements IDatabaseParcel {
    id?: number;
    expectedShippedDate?: Date;
    shippedOn?: Date;
    status?: IDParcelStatus;
    statusModifiedOn?: Date;
    createdOn?: Date;
    base64PDFLabel?: string;
    type?: IDParcelType;
    shipmentPrice?: number;
    shipmentType?: string;
    shipmentPriceCurrency?: string;
    shipmentAddress?: IDAddress;
    trackingNumber?: string;
    pickupDHLNumber?: string;
    statusDate?: Date;
  
    constructor(data: IDatabaseParcel) {
        if (data.id) this.id = data.id;
        if (data.expectedShippedDate) this.expectedShippedDate = new Date(data.expectedShippedDate);
        if (data.shippedOn) this.shippedOn = new Date(data.shippedOn);
        if (data.status) this.status = data.status;
        if (data.statusModifiedOn) this.statusModifiedOn = new Date(data.statusModifiedOn);
        if (data.createdOn) this.createdOn = new Date(data.createdOn);
        if (data.base64PDFLabel) this.base64PDFLabel = data.base64PDFLabel;
        if (data.type) this.type = data.type;
        if (data.shipmentPrice) this.shipmentPrice = data.shipmentPrice;
        if (data.shipmentType) this.shipmentType = data.shipmentType;
        if (data.shipmentPriceCurrency) this.shipmentPriceCurrency = data.shipmentPriceCurrency;
        if (data.shipmentAddress) this.shipmentAddress = data.shipmentAddress;
        if (data.trackingNumber) this.trackingNumber = data.trackingNumber;
        if (data.pickupDHLNumber) this.pickupDHLNumber = data.pickupDHLNumber;
        if (data.statusDate) this.statusDate = new Date(data.statusDate);
    }

    public isDelivered(): boolean {
        return this.status?.statusCode === 'OK';
    }
}