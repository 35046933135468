import React from "react";
import { useTranslation } from "services";

const WhyRecommended: React.FC = () => {
    const t = useTranslation('dashboard/support');

    return (
        <div className="answer-wrapper">
            <ol>
                <li>{t.translate('frequent.questions.cancer_check.why-recommended.family_history')}</li>
                <li>{t.translate('frequent.questions.cancer_check.why-recommended.annual_health_checkup')}</li>
                <li>{t.translate('frequent.questions.cancer_check.why-recommended.high_risk')}</li>
            </ol>
            <p>{t.translate('frequent.questions.cancer_check.why-recommended.desc')}</p>
        </div>
    )
}

export default WhyRecommended;