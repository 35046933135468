import img from 'assets/Help/analysis.svg';
import React from 'react';
import { useTranslation } from 'services';

const SSFourthPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2><span className='text-main-color'>3.</span> {t.translate('standalone.results.title')}</h2>
            <div className="help-step-content">
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '320px' }}>
                    <p>{t.translate('standalone.results.laboratory')} <span className='text-important'>{t.translate('standalone.results.days')}</span>.</p>
                </div>
                <img src={img} className='help-img' alt="Sample analysis" />
            </div>
        </div>
    )
}

export default SSFourthPage;