import { IComplement, IDatabaseComplement } from "interfaces/Database";
import APIService from "./APIService";

class ComplementService {
    private apiService = new APIService('/complements');

    public async getAll(): Promise<IComplement[]> {
        return await this.apiService.call<IDatabaseComplement[]>('/').then((res) => res.map<IComplement>((e) => new IComplement(e)));
    }
}

function useComplementService(): ComplementService {
    return new ComplementService();
}

export default useComplementService;