import { Button } from "components";
import Modal from "components/common/Modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import "./scss/ModalConfirmAction.scss";

const ModalConfirmAction: React.FC<({
    showModal: boolean
    handleClose: () => void
    description?: string | React.ReactElement
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    confirmAction: () => Promise<void>,
})> = ({ showModal, handleClose, description, confirmAction }) => {
    const t = useTranslation('dashboard/modal-confirm-action');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const closeModal = () => {
        handleClose();
    }

    const handleConfirm = async () => {
        setIsLoading(true);
        confirmAction()
            .then(() => closeModal())
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate('modal.title')}
            footer={
                <div className="flex-row full-width">
                    <Button
                        label={t.translate('modal.btn-cancel')}
                        onClick={closeModal}
                        disabled={isLoading}
                    />
                    <Button
                        label={t.translate('modal.btn-confirm')}
                        onClick={handleConfirm}
                        disabled={isLoading}
                        displayLoader={isLoading}
                    />
                </div>
            }
        >
            {description}
        </Modal>
    );
}

export default ModalConfirmAction;