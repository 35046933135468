import { Button, Input, Label } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from "components/common/Modal";
import React, { Fragment, useState } from "react";
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";
import verifierService from "services/verifier.service";

const ModalShareBodycheckup: React.FC<({
    showModal: boolean;
    toggleShow: () => void;
})> = ({
    showModal,
    toggleShow
}) => {
    const t = useTranslation('modals/shareBodyCheckup');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ email, setEmail ] = useState<string>("");
    const [ emailError, setEmailError ] = useState<string>();

    const [ success, setSuccess ] = useState<boolean>(false);

    const handleClose = () => {
        setEmail("");
        setEmailError(undefined);

        setSuccess(false);

        toggleShow();
    }

    const sendToContacts = () => {
        setIsLoading(true);

        standaloneService.sendShareToContacts({
            emails: [email]
        })
            .then(() => setSuccess(true))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={handleClose}
            title={t.translate('title')}
            footer={
                <div className="flex-row">
                    {success
                        ? <Button label={t.translate('buttons.close')} onClick={() => handleClose()} />
                        : <Button label={t.translate('buttons.share')} onClick={() => sendToContacts()} disabled={!verifierService.checkEmail(email) || isLoading} displayLoader={isLoading} />
                    }
                </div>
            }
        >
            {success
                ? <Fragment>
                    <div className="flex-column full-width">
                        <h2 className="text-gradient">{t.translate('success.sub')}</h2>
                        <p>{t.translate('success.desc')}</p>
                    </div>
                </Fragment>
                : <Fragment>
                    <div className="flex-column-start full-width">
                        <Label for="email" label={t.translate('mailLabel')} />
                        <div className="flex-row full-width">
                            <Input name="email" value={email} setValue={(e) => setEmail(e.currentTarget.value)} />
                        </div>
                        <ErrorMessage error={emailError} />
                    </div>
                </Fragment>
            }
        </Modal>
    )
}

export default ModalShareBodycheckup;