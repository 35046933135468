import img from 'assets/Help/doctor.svg';
import React from 'react';
import { useTranslation } from 'services';

const DSFifthPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2>{t.translate('doctor.appointments.title')}</h2>
            <div className="help-step-content">
                <img src={img} className='help-img' alt="Doctor appointment" />
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '400px' }}>
                    <p>{t.translate('doctor.appointments.pre')}
                        <ul>
                            <li><b>{t.translate('doctor.appointments.prescription')}</b>{t.translate('doctor.appointments.prescriptionDetail')}</li>
                            <li><b>{t.translate('doctor.appointments.dietaryComplement')}</b>{t.translate('doctor.appointments.dietaryComplementDetail')}</li>
                            <li><b>{t.translate('doctor.appointments.nutritionalComment')}</b>{t.translate('doctor.appointments.nutritionalCommentDetail')}</li>
                            <li><b>{t.translate('doctor.appointments.dietList')}</b>{t.translate('doctor.appointments.dietListDetail')}</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DSFifthPage;