import { Button } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { IDatabaseHistory } from 'interfaces/Database/IDatabaseHistory';
import 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';

const HistoryActions: React.FC<({ historyActions: IDatabaseHistory[] })> = ({ historyActions }) => {
    const t = useTranslation('dashboard/doctor/history');
    const history = useHistory();

    const handleClickHistory = () => {
        history.push('/account/history');
    }

    const getLatestActions = () => {
        return historyActions.sort(function (a: { date: Date }, b: { date: Date }) {
            return b.date.getTime() - a.date.getTime();
        });
    }

    return (
        <DefaultContainer
            title={<h2 className='bold'>{t.translate("titleHistoryActions")}</h2>}
            body={historyActions.length > 0
                ? <div className='appointments-list' style={{ margin: '10px 0' }}>
                    {getLatestActions().map((action, index) =>
                        <div key={`action-${index}`}>
                            <p>{action.content}</p>
                            <p className='MavenPro'>
                                {appointmentService.getFormattedDate(action.date)}
                            </p>
                        </div>
                    )}
                </div>
                : <p className="no-data">
                    {t.translate("noAction")}
                </p>
            }
            footer={
                <Button label={t.translate('see')} onClick={handleClickHistory} />
            }
        />
    )
}

export default HistoryActions