import { EditIcon, InformationWrapper, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { EditProfilePicture } from "components/common/ProfilePicture";
import { UserContext } from "context/User/UserContext";
import { IDatabaseCompanyAdministrator } from "interfaces/Database/IDatabaseCompanyAdministrator";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "services";
import companyService from "services/company.service";
import '../scss/CompanyProfile.scss';
import CompanyProfileModal from "./CompanyProfileModal";

const CompanyProfileComponent: React.FC = () => {
    const t = useTranslation('dashboard/company/home');
    const { user } = useContext(UserContext);

    const history = useHistory();
    const location = useLocation();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ showEditModal, setShowEditModal ] = useState<boolean>(false);
    const [ preferredContact, setPreferredContact ] = useState<IDatabaseCompanyAdministrator>();

    const fetchData = useCallback(() => {
        setIsLoading(true);        
        companyService.getCompanyPreferredContact()
            .then((res) => setPreferredContact(res))
            .catch(() => setPreferredContact(undefined))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const toggleIsShow = () => {
        setShowEditModal(!showEditModal);
    }

    const historyPushOrToggle = () => {
        return location.pathname === "/account/home" ? history.push('/account/profile') : toggleIsShow(); 
    }

    return (
        <Fragment>
            <DefaultContainer
                title={
                    <Fragment>
                        <h2 className='bold'>{t.translate('profil.title')}</h2>
                        <EditIcon onClick={historyPushOrToggle} />
                    </Fragment>
                }
                body={isLoading
                    ? <Loader />
                    : <div className="company-profile-infos">
                        <div className="company-profile-infos-pic">
                            <EditProfilePicture />
                        </div>
                        <div className="flex-column flex-gap-row full-width">
                            <div className="flex-row flex-wrap flex-gap-col full-width">
                                <div style={{ flex: '1' }}>
                                    <InformationWrapper
                                        libelle={t.translate('profil.name')}
                                        value={user && user.companyInfo && user.companyInfo.name ? user.companyInfo.name : undefined}
                                    />
                                </div>
                                <div className="flex-row" style={{ flex: '1' }}>
                                    <InformationWrapper libelle={t.translate('profil.mail')} value={user?.email} />
                                </div>
                            </div>
                            <div className="flex-row flex-wrap flex-gap-col full-width">
                                <div style={{ flex: '1' }}>
                                    <InformationWrapper
                                        libelle={t.translate('profil.responsible')}
                                        value={
                                            preferredContact
                                                ? preferredContact.firstname + " " + preferredContact.lastname
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="flex-row" style={{ flex: '1' }}>
                                    <InformationWrapper
                                        libelle={t.translate('profil.phone')}
                                        value={(preferredContact?.phones && preferredContact?.phones?.length > 0)
                                            ? preferredContact?.phones[preferredContact?.phones.length-1].number
                                            : undefined
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
            <CompanyProfileModal
                isShow={showEditModal}
                toggleIsShow={toggleIsShow}
                onSuccess={fetchData}
                preferredContact={preferredContact}
            />
        </Fragment>
    )
}

export default CompanyProfileComponent;