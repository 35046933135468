import { Button, Loader } from "components";
import CustomCircleProgressbar from "components/common/CustomCircleProgressbar/CustomCircleProgressbar";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IResult } from "interfaces/IResult";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import analysisKitService from "services/analysisKit.service";
import fileService from "services/file.service";
import resultsService from "services/results.service";
import '../scss/CurrentResults.scss';

const CurrentResults: React.FC<({
    analysisKitId?: number
})> = ({
    analysisKitId
}) => {
    const t = useTranslation('dashboard/patient/testResults');

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ downloadable, setDownloadable ] = useState<boolean>(false);

    const [ results, setResults ] = useState<IResult[]>([]);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            setError(undefined);

            if (analysisKitId) {
                Promise.all([
                    resultsService.getFormattedResults(analysisKitId)
                        .then((res) => setResults(res))
                        .catch(() => setResults([])),
                    resultsService.canDownloadLimsResultsPdf(analysisKitId)
                        .then((res) => setDownloadable(res.downloadable))
                        .catch(() => setDownloadable(false))
                ])
                    .finally(() => setIsLoading(false));
            } else {
                setError('no-data');
                setIsLoading(false);
                setDownloadable(false);
            }
        }

        fetchData();
    }, [analysisKitId]);

    const handleDownloadResults = () => {
        setIsDownloading(true);

        if (analysisKitId) {
            analysisKitService.getAnalysisKitResultsPdf(analysisKitId)
                .then((res) => fileService.downloadFile(res, { addPre: true }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(false));
        } else {
            setError('no-data');
            setIsDownloading(false);
        }
    }

    const handleContent = () => {
        if (isLoading) return <Loader />

        if (error) return <ErrorMessage error={error} />

        if (results.length === 0)
            return <p className="no-data">{t.translate('not-available')}</p>

        return results.map((category, index) => 
            <div key={index} className="doctor-result-wrapper">
                <div className="doctor-result-progressbar-wrapper">
                    {category.max !== 0
                        ? <CustomCircleProgressbar
                            displayValue={true}
                            max={category.max}
                            value={category.score}
                            pathColor={category.color}
                        />
                        : <CustomCircleProgressbar text="No data" />
                    }
                </div>
                <div  className="doctor-result-wrapper-detail">
                    <p className="MavenPro-black" style={{ margin: 0 }}>
                        {t.translate('category.'+category.name+'.label')}
                    </p>
                    <span>
                        {t.translate('category.'+category.name+'.desc')}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <DefaultContainer
            title={
                <h2 className='bold'>{t.translate('resultsTitle')}</h2>
            }
            body={
                <div className="flex-row flex-wrap space-between">
                    {handleContent()}
                </div>
            }
            footer={
                (!isLoading && results.length !== 0) &&
                    <div className="flex-row flex-wrap">
                        <Button
                            label={t.translate('downloadReport')}
                            onClick={handleDownloadResults}
                            disabled={!downloadable || isDownloading}
                            displayLoader={isDownloading}
                        />
                    </div>
            }
        />
    )
}

export default CurrentResults;