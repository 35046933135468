import { Loader } from "components";
import DoctorForm from "components/DoctorForms/common/DoctorForm";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import preventiveAdviceService from "services/preventiveAdvice.service";

const PreventiveAdvice: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('patientDetail');
    const { patientId, appointmentId } = useParams<({ patientId: string, appointmentId: string })>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ patient, setPatient ] = useState<IDatabaseUser>();
    const [ preventiveAdvice, setPreventiveAdvice ] = useState<string>();
    const [ lastUpdate, setLastUpdate ] = useState<Date>();

    const fetchData = () => {
        setIsLoading(true);

        doctorService.getPatientDetails(patientId)
            .then((res) => {
                setPatient(res.result);
                setPreventiveAdvice(res.preventiveAdvice?.advice);
                setLastUpdate(res.preventiveAdvice?.updatedAt);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = () => {
        setIsLoading(true);

        if (appointmentId && preventiveAdvice) {
            preventiveAdviceService.createOrUpdate({
                appointmentId: appointmentId,
                preventiveAdvice: preventiveAdvice
            })
                .then(() => history.push(`/account/patient/${patientId}`))
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(true);
        }
    }

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    if (isLoading) {
        return (
            <Loader />
        )
    } else {
        return (
            <DoctorForm
                title={t.translate('pdf.preventiveAdvice.title')}
                onSubmit={handleSubmit}
                setFormValue={setPreventiveAdvice}
                patientFullName={`${patient?.patientInfo?.firstname} ${patient?.patientInfo?.lastname}`}
                value={preventiveAdvice}
                dateLastUpdate={lastUpdate}
                handleGoBack={handleGoBack}
            />
        );
    }
}

export default PreventiveAdvice;