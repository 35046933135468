import { Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useTranslation } from "services";
import "../scss/SpecialInformation.scss";
import ModalEditSpecificInfo from "./modal/ModalEditSpecificInfo";

const SpecialInformation: React.FC = () => {
    const t = useTranslation('profile/patient/mySpecialInformation');
    const { user, refreshUser } = useContext(UserContext);

    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const refreshData = async () => {
        setIsLoading(true);
        setShowModal(false);

        refreshUser()
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    } 

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold">{t.translate("title")}</h1>
                    <FiEdit
                        size={24}
                        className="clickable"
                        color="var(--dashboard-main-color)"
                        onClick={() => setShowModal(true)}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    {isLoading
                        ? <Loader />
                        : <div className="special-information-content">
                            <p id="special-information-placeholder">
                                {user?.patientInfo?.specialInformation ?? t.translate("placeholder")}
                            </p>
                        </div>
                    }
                    <ModalEditSpecificInfo
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        handleSuccess={() => refreshData()}
                        currentUser={user}
                    />
                </Fragment>
            }
        />
    );
}

export default SpecialInformation;