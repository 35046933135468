import React from "react";

const Base: React.FC = () => {
    return (
        <div className="flex-column">
            <h1>Woops !</h1>
            <p>It seems like an unexpected error occured...</p>
        </div>
    )
}

export default Base;