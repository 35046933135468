import { Checkbox, Input } from "components/Form";
import { IDSurveyAnswer } from "interfaces/Database";
import { IQuestion } from "interfaces/Survey/IQuestion";
import React, { useCallback, useEffect, useState } from "react";
import translationService from "services/translation.service";
import "../scss/Question.scss";

const QuestionMultipleChoice: React.FC<({
    question: IQuestion;
    handleValidation: (isValid: boolean) => void;
    handleUserAnswer: (params: (string|number)[]) => void;
    previousAnswers?: IDSurveyAnswer[];
})> = ({
    question,
    handleValidation,
    handleUserAnswer,
    previousAnswers
}) => {
    const [ selectedAnswer, setSelectedAnswer ] = useState<number[]>([]);
    const [ otherAnswer, setOtherAnswer ] = useState("");

    const getUserAnswers = useCallback(() => {
        let userAnswers = selectedAnswer.map((answerIndex) => question.answers[answerIndex]);
        if (otherAnswer.length > 0) {
            userAnswers = userAnswers.filter((e) => e !== 'other');
            userAnswers.push(`other, ${otherAnswer}`);
        }

        return userAnswers;
    }, [otherAnswer, question.answers, selectedAnswer]);

    useEffect(() => {
        if (previousAnswers && previousAnswers.length > 0) {
            const tmp_answers: number[] = [];

            previousAnswers.forEach((previousAnswer) => {
                if (previousAnswer.answer.includes('other')) {
                    const answers = question.answers.indexOf('other');
                    tmp_answers.push(answers);
                    setOtherAnswer(previousAnswer.answer.split('other,').join(' ').trim());
                } else {
                    const answers = question.answers.indexOf(previousAnswer.answer);
                    tmp_answers.push(answers);
                }
            });

            setSelectedAnswer(tmp_answers);
        }
    }, [previousAnswers]);

    useEffect(() => {
        handleValidation(selectedAnswer.length > 0 || otherAnswer.length > 0);
        handleUserAnswer(getUserAnswers());
    }, [selectedAnswer, otherAnswer, handleValidation, handleUserAnswer, getUserAnswers]);

    const selectAnswer = (index: number) => {
        if (!selectedAnswer.includes(index)) {
            if (question.answers[index] === 'none') {
                setSelectedAnswer([ index ]);
            } else {
                setSelectedAnswer([
                    ...selectedAnswer.filter((idx) => question.answers[idx] !== 'none'), index
                ]);
            }
        } else {
            const indexRemoveAnswer = selectedAnswer.indexOf(index);
            setSelectedAnswer(selectedAnswer.filter((_, index) =>
                indexRemoveAnswer !== index
            ));
        }
    }

    return (
        <div className="question-wrapper">
            <h2>{translationService.translateSurveyQuestion(question.code)}</h2>
            {question.description && <span id="question-description">{translationService.translateSurveyDescription(question.code)}</span>}
            <div id="answers-container">
                {question.answers.map((answer, index) =>
                    <div
                        key={index}
                        className={selectedAnswer.includes(index) ? "answer-selected" : "answer"}
                        onClick={() => selectAnswer(index)}
                    >
                        <Checkbox
                            name={answer.toString()}
                            isChecked={selectedAnswer.includes(index)}
                            label={translationService.translateSurveyAnswer(question.code, answer)}
                        />
                    </div>
                )}
                {selectedAnswer.filter((e) => question.answers[e] === 'other').length > 0 &&
                    <Input
                        name="other"
                        value={otherAnswer}
                        setValue={(e) => setOtherAnswer(e.currentTarget.value)}
                        wrapperStyle={{ maxWidth: '450px', margin: '5vh 1vw 0 1vw', boxSizing: 'border-box' }}
                    />
                }
            </div>

        </div>
    );
}

export default QuestionMultipleChoice;