import { AddIcon, Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import { IDInternalNote } from "interfaces/Database";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import useInternalNoteService from "services/internalNote.service";
import ModalCreateInternalNote from "../modal/ModalCreateInternalNote";
import ModalDeleteInternalNote from "../modal/ModalDeleteInternalNote";
import ModalUpdateInternalNote from "../modal/ModalUpdateInternalNote";
import "../scss/InternalDoctorsNote.scss";

const InternalDoctorsNote: React.FC<({
    patientId: string;
})> = ({
    patientId
}) => {
    const t = useTranslation('patientDetail');
    const { user } = useContext(UserContext);
    const internalNoteService = useInternalNoteService();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ internalNotes, setInternalNotes ] = useState<IDInternalNote[]>();
    const [ selectedNote, setSelectedNote ] = useState<IDInternalNote>();

    const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);
    const [ showUpdateModal, setShowUpdateModal ] = useState<boolean>(false);
    const [ showDeleteModal, setShowDeleteModal ] = useState<boolean>(false);

    const toggleCreateModal = () => setShowCreateModal(!showCreateModal);
    const toggleUpdateModal = () => setShowUpdateModal(!showUpdateModal);
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

    const fetchInternalNotes = () => {
        setIsLoading(true);

        internalNoteService.getInternalNoteByPatient(patientId)
            .then((res) => setInternalNotes(res))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchInternalNotes();
    }, []);

    const handleUpdate = (note: IDInternalNote) => {
        setSelectedNote(note);
        setShowUpdateModal(true);
    }

    const handleDelete = (note: IDInternalNote) => {
        setSelectedNote(note);
        setShowDeleteModal(true);
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold row space-between">{t.translate("internal-doctor-note.title")}</h1>
                    <AddIcon className="clickable" onClick={toggleCreateModal} />
                </Fragment>
            }
            body={
                <Fragment>
                    {isLoading
                        ? <Loader />
                        : <div id="internal-doctors-note">
                            { (!internalNotes || (internalNotes.length === 0))
                                ? <div id="empty-appointment-wrapper" className="column align-items-center">
                                    <p className="no-data">
                                        {t.translate("internal-doctor-note.no-data")}
                                    </p>
                                </div>
                                : internalNotes.map((note, index) =>
                                    <div key={index} className="internalNote">
                                        <p>{t.translate('internal-doctor-note.writeBy')} {doctorService.formatDoctorName(note?.doctor?.doctorInfo)}</p>
                                        <div className="dates row space-between">
                                            <p className="createdAt">{t.translate('internal-doctor-note.createdAt')} {t.translateDate(note.createdAt)}</p>
                                            {note.updatedAt && 
                                                <p className="createdAt">{t.translate('internal-doctor-note.updatedAt')} {t.translateDate(note.updatedAt)}</p>
                                            }
                                        </div>

                                        <p className="MavenPro-bold text">{note.text}</p>

                                        { user && note.doctor.id === user.id
                                            ? <div className="buttons row space-around">
                                                    <Button
                                                        label={t.translate('internal-doctor-note.modify')}
                                                        onClick={() => handleUpdate(note)} />
                                                    <Button
                                                        label={t.translate('internal-doctor-note.delete')}
                                                        onClick={() => handleDelete(note)} />
                                                </div>
                                            : null
                                        }
                                    </div>
                                )
                            }
                        </div>
                    }
                    <ModalCreateInternalNote
                        patientId={patientId}
                        showModal={showCreateModal}
                        handleClose={toggleCreateModal}
                        handleSuccess={fetchInternalNotes}
                    />
                    <ModalUpdateInternalNote
                        note={selectedNote}
                        showModal={showUpdateModal}
                        handleClose={toggleUpdateModal}
                        handleSuccess={fetchInternalNotes}
                    />
                    <ModalDeleteInternalNote
                        note={selectedNote}
                        showModal={showDeleteModal}
                        handleClose={toggleDeleteModal}
                        handleSuccess={fetchInternalNotes}
                    />
                </Fragment>
            }
        />
    );
}

export default InternalDoctorsNote;