import { Button, Input, Label, Loader, Phone, Radio } from 'components';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import { IDatabaseCompanyAdministrator } from 'interfaces/Database/IDatabaseCompanyAdministrator';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'services';
import companyService from 'services/company.service';
import verifierService from 'services/verifier.service';

const CompanyEditContact: React.FC<({
    isShow: boolean,
    toggleShow: () => void,
    contact?: IDatabaseCompanyAdministrator,
    refreshContacts: () => Promise<void>
})> = ({ isShow, toggleShow, contact, refreshContacts }) => {
    const t = useTranslation('dashboard/company/profile');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ success, setSuccess ] = useState<string>();
    const [ error, setError ] = useState<string>();

    const [ lastName, setLastName ] = useState<string>();
    const [ firstName, setFirstName ] = useState<string>();
    const [ email, setEmail ] = useState<string>();
    const [ currentPosition, setCurrentPosition ] = useState<string>();
    const [ makePrefered, setMakePrefered ] = useState<boolean>(false);

    const [ countryCode, setCountryCode ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();

    useEffect(() => {
        setMakePrefered(contact?.preferredContact ?? false);
    }, [ contact ]);

    useEffect(() => {
        if (isShow) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ isShow ]);

    const reset = async () => {
        setLastName(undefined);
        setFirstName(undefined);
        setEmail(undefined);
        setCountryCode(undefined);
        setPhoneNumber(undefined);

        if (success) setSuccess(undefined);

        toggleShow();
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, deleteContact=false) => {
        e.preventDefault();

        setIsLoading(true);
        setError(undefined);

        if (contact?.id) {
            if (deleteContact) {
                await companyService.removeCompanyContact(contact?.id)
                    .then(() => {
                        setSuccess(t.translate('contacts.modal.success.deleted'));
                        refreshContacts();
                    })
                    .catch((err) => setError(err.message));
            } else {
                await companyService.updateCompanyContact({
                    contactId: contact.id,
                    data: {
                        firstname: firstName,
                        lastname: lastName,
                        email: email,
                        currentPosition: currentPosition,
                        countryCode: countryCode,
                        phoneNumber: phoneNumber,
                        makePrefered: makePrefered
                    }
                })
                    .then(() => {
                        setSuccess(t.translate('contacts.modal.success.updated'));
                        refreshContacts();
                    })
                    .catch((err) => setError(err.message));
            }
        }

        setIsLoading(false);
    }

    if (isShow) {
        if (isLoading) {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <h2 className="MavenPro-bold">{t.translate('contacts.modal.title.edit')}</h2>
                        </div>
                        <div className='modal-content-body'>
                            <Loader />
                        </div>
                    </div>
                </div>
            )
        } else if (success) {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <AiOutlineClose className='close' onClick={() => reset()} />
                            <h2 className="MavenPro-bold">{t.translate('contacts.modal.title.edit')}</h2>
                        </div>
                        <div className='modal-content-body'>
                            <p>{success}</p>
                            <p>{t.translate('contacts.modal.success.leaveMsg')}</p>
                            <button onClick={() => reset()} disabled={!success} className='text-uppercase allocate-form-validate MavenPro-black'>{t.translate('contacts.modal.success.btn')}</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='modal-content-header'>
                            <AiOutlineClose className='close' onClick={() => reset()} />
                            <h2 className="MavenPro-bold">{t.translate('contacts.modal.title.edit')}</h2>
                        </div>
                        <div className='modal-content-body'>
                            <form className='allocate-form'>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='lastname' label={t.translate('contacts.modal.lastname')} required />
                                        <Input
                                            required
                                            name='lastname'
                                            disabled={isLoading}
                                            placeholder={contact?.lastname}
                                            value={lastName}
                                            setValue={(e) => setLastName(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(lastName)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='firstname' label={t.translate('contacts.modal.firstname')} required />
                                        <Input
                                            required
                                            name='lastname'
                                            disabled={isLoading}
                                            placeholder={contact?.firstname}
                                            value={firstName}
                                            setValue={(e) => setFirstName(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(firstName)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='email' label={t.translate('contacts.modal.email')} required />
                                        <Input
                                            required
                                            name='email'
                                            type='email'
                                            disabled={isLoading}
                                            placeholder={contact?.email}
                                            value={email}
                                            setValue={(e) => setEmail(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkEmail(email)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label for='position' label={t.translate('contacts.modal.position')} required />
                                        <Input
                                            required
                                            name='position'
                                            disabled={isLoading}
                                            placeholder={contact?.position}
                                            value={currentPosition}
                                            setValue={(e) => setCurrentPosition(e.currentTarget.value)}
                                            checkFunction={() => verifierService.checkString(currentPosition)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <Label name='phone' label={t.translate('contacts.modal.phone')} required />
                                        <Phone
                                            required
                                            labelledBy='phone'
                                            defaultCountryCode={countryCode}
                                            setCountryCode={setCountryCode}
                                            defaultPhoneNumber={phoneNumber}
                                            setPhoneNumber={setPhoneNumber}
                                            checkFunction={() => verifierService.checkPhone(countryCode, phoneNumber)}
                                            checkmark={{
                                                displayed: true
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='allocate-form-fields'>
                                    <div className='allocate-form-fields-wrapper'>
                                        <label>{t.translate('contacts.modal.prefered.title')}</label>
                                        <div className='flex-row full-width space-evenly'>
                                            <div>
                                                <Radio
                                                    name='prefered'
                                                    value={true}
                                                    isChecked={makePrefered}
                                                    setChecked={() => setMakePrefered(true)}
                                                    label={
                                                        <Label for='prefered' label={t.translate('contacts.modal.prefered.yes')} />
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <Radio
                                                    name='prefered'
                                                    value={false}
                                                    isChecked={!makePrefered}
                                                    setChecked={() => setMakePrefered(false)}
                                                    label={
                                                        <Label for='prefered' label={t.translate('contacts.modal.prefered.no')} />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        { makePrefered && <p className='MavenPro allocate-form-sub'>*{t.translate('contacts.modal.prefered.warn')}</p> }
                                    </div>
                                </div>
                                <ErrorMessage error={error} />
                                <div className='flex-row'>
                                    <Button label={t.translate('contacts.modal.deleteBtn')} onClick={(e) => handleSubmit(e, true)} disabled={isLoading} displayLoader={isLoading} />
                                    <Button label={t.translate('contacts.modal.updateBtn')} onClick={(e) => handleSubmit(e, false)} disabled={isLoading} displayLoader={isLoading} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return null;
    }
}

export default CompanyEditContact;