import { Button, Label, Loader, TextArea } from "components";
import GoBack from "components/DoctorPatients/components/GoBack";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import useIsComponentMounted from "hooks/useIsComponentMounted";
import { IDatabaseNutritionalComment } from "interfaces/Database/IDatabaseNutritionalComment";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import translationService from "services/translation.service";

const NutritionalComment: React.FC = () => {
    const isMounted = useIsComponentMounted();

    const t = useTranslation('patientDetail');
    const history = useHistory();
    const { user } = useContext(UserContext);
    const { patientId, appointmentId } = useParams<{ patientId: string, appointmentId: string }>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [patient, setPatient] = useState<IDatabaseUser>();
    const [nutritionalComment, setNutritionalComment] = useState<IDatabaseNutritionalComment>();

    const fetchPatient = async () => {
        setIsLoading(true);

        doctorService.getPatientDetails(patientId)
            .then((res) => {
                setPatient(res.result);
                setNutritionalComment(res.nutritionalComment);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (isMounted.current) {
            fetchPatient();
        }
    }, [0]);

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    const handleFormNewNutritiousComment = () => {
        history.push(`/account/patient/${patientId}/nutritious-comment/${appointmentId}/new`);
    }

    return (
        <div id='form-prescription' className="flex-column-start full-width">
            <GoBack
                className="MavenPro-Black"
                text={`${patient?.patientInfo?.firstname} ${patient?.patientInfo?.lastname}`}
                handleGoBack={handleGoBack}
            />
            <DefaultContainer
                title={<h2 className='bold'>{t.translate('pdf.nutritiousComment.title')}</h2>}
                body={isLoading
                    ? <Loader />
                    : <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                        { !nutritionalComment
                            ? <p className="no-data">{t.translate('pdf.nutritiousComment.none')}</p>
                            : <Fragment>
                                <div className="flex-column flex-grow">
                                    <TextArea name="nutritional-comment" value={nutritionalComment.comment} disabled />
                                </div>
                                <div className="flex-column flex-grow">
                                    <Label name="recommended-diets" label={t.translate('pdf.nutritionsComment.recommendedDiets')} />
                                    <div aria-labelledby="recommended-diets" className="full-width flex-row flex-wrap space-around">
                                        {nutritionalComment.diets.map((diet, index) =>
                                            <div
                                                key={index}
                                                style={{
                                                    margin: '10px 5px',
                                                    padding: '0 10px',
                                                    border: '1px solid var(--dashboard-main-color)',
                                                    borderRadius: '10px'
                                                }}
                                            >
                                                <p className="text-main-color">{translationService.translateDiet(diet.name)}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Fragment> 
                        }
                    </div>
                }
                footer={
                    (!isLoading && (
                        user?.isDoctorOf(patient) || user?.isNutritionistOf(patient)
                    )) && <Button label={t.translate('pdf.edit')} onClick={() => handleFormNewNutritiousComment()} />
                }
            />
        </div>
    )
}

export default NutritionalComment;