import { Button, Label, TextArea } from "components";
import { UserContext } from "context/User/UserContext";
import 'moment-timezone';
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from 'react-modal';
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";

const EditStandaloneSpecialInfo: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void
})> = ({
    showModal,
    handleClose,
    handleSuccess
}) => {
    const t = useTranslation('profile/patient/myHealthInformation');
    const { user } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ specialInformation, setSpecialInformation ] = useState<string>(user?.patientInfo?.specialInformation ?? '');

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            padding: '3vh 3vw 5vh 3vw',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "40%",
            minWidth: "700px",
            borderRadius: "5px",
            overflow: "hidden",
            boxShadow: '-5px 5px 20px 1px rgba(0, 0, 0, 0.12)'
        }
    };

    const title = {
        margin: 0,
    }

    const containerEditInfo = {
        marginTop: "5vh",
        marginBottom: '7%'
    };

    const cross = {
        display: 'flex',
        alignItems: 'center', 
        cursor: "pointer",
        color: "var(--dashboard-main-color)"
    }

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'scroll';
    }

    const successModal = () => {
        setIsLoading(true);
    
        standaloneService.updateInformations({
            specialInformation: specialInformation
        })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    return (
        <div id="modal-profil-info">
            <Modal
                isOpen={showModal}
                style={modalStyle}
                contentLabel="Example Modal"
                preventScroll={true}
                ariaHideApp={false}
            >
                <div className="column space-between" style={{ height: '100%' }}>
                    <div className="row space-between">
                        <h2 className="bold" style={title}>{t.translate("titleModal")}</h2>
                        <div onClick={closeModal} style={cross}><AiOutlineClose size={30} /></div>
                    </div>
                    <div className="column" style={containerEditInfo}>
                        <div className="flex-column-start full-width">
                            <Label for="special-information" label={t.translate('specialInformation')} />
                            <TextArea
                                name="special-information"
                                value={specialInformation}
                                setValue={(e) => setSpecialInformation(e.currentTarget.value)}
                            />
                        </div>
                    </div>
                    <div className="row flex-end">
                        <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                        <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default EditStandaloneSpecialInfo;