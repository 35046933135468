import { Button, Input, Label, Phone } from 'components';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import { UserContext } from 'context/User/UserContext';
import React, { CSSProperties, useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from 'react-modal';
import { useTranslation } from 'services';
import standaloneService from 'services/standalone.service';
import verifierService from "services/verifier.service";

const EditStandaloneContactInfo: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void
})> = ({
    showModal,
    handleClose,
    handleSuccess
}) => {
    const t = useTranslation('profile/patient/contact');
    const { user } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const [ email, setEmail ] = useState<string | undefined>(user?.email);
    const [ phoneNumber, setPhoneNumber ] = useState<string | undefined>(user?.phone?.number);
    const [ countryCode, setCountryCode ] = useState<string | undefined>(user?.phone?.country?.phonecode);

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            padding: '3vh 3vw 5vh 3vw',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "40%",
            minWidth: "700px",
            borderRadius: "5px",
            overflow: "hidden",
            boxShadow: '-5px 5px 20px 1px rgba(0, 0, 0, 0.12)'
        }
    };

    const titleContainer: CSSProperties = {
        // margin: '1vh 0vh 4vh 0vh'
    }

    const title: CSSProperties = {
        margin: 0,
    }

    const containerEditInfo: CSSProperties = {
        marginTop: "3vh",
        marginBottom: "1vh"
    };

    const inputContainer: CSSProperties = {
        width: "48%"
    };

    const rowInputContainer: CSSProperties = {
        marginBottom: "2vh"
    };

    const cross: CSSProperties = {
        display: 'flex',
        alignItems: 'center', 
        cursor: 'pointer',
        color: "var(--dashboard-main-color)"
    }

    const closeModal = () => {
        handleClose();
    }

    const successModal = async () => {
        setIsLoading(true);
        setError(undefined);

        standaloneService.updateContactInformations({
            email: email === user?.email ? undefined : email,
            countryCode: countryCode,
            phoneNumber: phoneNumber
        })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    return (
        <div>
            <Modal
                isOpen={showModal}
                style={modalStyle}
                contentLabel="Example Modal"
                preventScroll={true}
                ariaHideApp={false}
            >
                <div className="column space-between" style={{ height: '100%' }}>
                    <div style={titleContainer} className="row space-between">
                        <h2 style={title} className='bold'>{t.translate("titleModal")}</h2>
                        <div onClick={closeModal} style={cross}><AiOutlineClose size={30} /></div>
                    </div>
                    <div className="column" style={containerEditInfo}>
                        <div className="row space-between" style={rowInputContainer}>
                            <div className="column" style={inputContainer}>
                                <Label name='phone' label={t.translate('phone')} required />
                                <Phone
                                    required
                                    labelledBy='phone'
                                    defaultCountryCode={countryCode}
                                    setCountryCode={setCountryCode}
                                    defaultPhoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                    checkFunction={() => !!(countryCode && phoneNumber && verifierService.checkPhoneNumber(countryCode+phoneNumber))}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                            <div className="column" style={inputContainer}>
                                <Label for='email' label={t.translate('email')} required />
                                <Input
                                    required
                                    name='email'
                                    value={email}
                                    setValue={(e) => setEmail(e.currentTarget.value)}
                                    checkFunction={() => verifierService.checkEmail(email)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <ErrorMessage error={error} />
                    <div className="row flex-end">
                        <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                        <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default EditStandaloneContactInfo;