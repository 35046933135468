import { Button } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDatabasePreventiveAdvice } from "interfaces/Database/IDatabasePreventiveAdvice";
import React, { useState } from "react";
import { useTranslation } from "services";
import fileService from "services/file.service";
import preventiveAdviceService from "services/preventiveAdvice.service";
import "../scss/CurrentPrescription.scss";

const CurrentPreventiveAdvice: React.FC<({
    preventiveAdvice?: IDatabasePreventiveAdvice,
    doctorName?: string
})> = ({ preventiveAdvice, doctorName }) => {
    const t = useTranslation('dashboard/patient/recommendations');
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);

    const fetchFile = async () => {
        if (preventiveAdvice?.id) {
            setIsDownloading(true);
            
            preventiveAdviceService.getPreventiveAdvicePDF(preventiveAdvice?.id)
                .then((res) => fileService.downloadFile(res, { filename: 'preventive_advice' }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(false));
        }
    }

    return (
        <DefaultContainer
            title={
                <h1 className="container-title bold">
                    {t.translate('preventive-advice.title')}
                </h1>
            }
            body={preventiveAdvice
                ? <div className="flex-column">
                    <p>&quot;{preventiveAdvice.advice}&quot;</p>
                    <span id="prescription-info">
                        <span className="MavenPro-black">
                            {t.translateDate(preventiveAdvice.updatedAt)} -{' '}
                        </span>
                        {doctorName ?? t.translateFromFile('common', 'no-data')}
                    </span>
                </div>
                : <p className="no-data">
                    {t.translate('preventive-advice.no-preventive-advice')}
                </p>
            }
            footer={
                preventiveAdvice && <Button label={t.translate('preventive-advice.btn-download')} onClick={() => fetchFile()} disabled={isDownloading} />
            }
        />
    );
}

export default CurrentPreventiveAdvice;