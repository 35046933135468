import { Button, Input, Label, Phone } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from "components/common/Modal";
import { IDatabaseCompanyAdministrator } from "interfaces/Database/IDatabaseCompanyAdministrator";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import companyService from "services/company.service";
import '../scss/CompanyProfileModal.scss';

const CompanyProfileModal: React.FC<({
    isShow: boolean;
    toggleIsShow: () => void;
    onSuccess: () => void;
    preferredContact?: IDatabaseCompanyAdministrator;
})> = ({ isShow, toggleIsShow, onSuccess, preferredContact }) => {
    const t = useTranslation('dashboard/company/profile');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ success, setSuccess ] = useState<string>();

    const [ managerFirstName, setManagerFirstName ] = useState<string>();
    const [ managerLastName, setManagerLastName ] = useState<string>();
    const [ managerEmail, setManagerEmail ] = useState<string>();
    const [ managerPosition, setManagerPostition ] = useState<string>();
    
    const [ countryCode, setCountryCode ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();

    useEffect(() => {
        if (preferredContact) {
            setManagerFirstName(preferredContact.firstname);
            setManagerLastName(preferredContact.lastname);
            setManagerEmail(preferredContact.email);
            setManagerPostition(preferredContact.position);
            if (preferredContact?.phones && preferredContact?.phones?.length > 0) {
                const phone = preferredContact?.phones[preferredContact?.phones.length - 1];

                setCountryCode(phone.country?.phonecode);
                setPhoneNumber(phone.number);
            }
        }
    }, [preferredContact]);

    useEffect(() => {
        if (isShow) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ isShow ]);

    const handleClose = () => {
        setError(undefined);
        setIsLoading(false);
        setManagerFirstName(undefined);
        setManagerLastName(undefined);
        setManagerEmail(undefined);
        setManagerPostition(undefined);
        setPhoneNumber(undefined);
        setCountryCode(undefined);

        if (success) {
            setSuccess(undefined);
        }

        toggleIsShow();
    }

    const handleSubmitCompanyProfile = () => {
        setIsLoading(true);
        setError(undefined);

        Promise.all([
            companyService.updatePreferredContact({
                contactFirstname: managerFirstName,
                contactLastname: managerLastName,
                contactEmail: managerEmail,
                contactCountryCode: countryCode,
                contactPhoneNumber: phoneNumber,
                contactPosition: managerPosition,
            })
        ])
            .then(() => {
                setSuccess('success');
                onSuccess();
            })
            .catch((err) => setError(err.message ? err.message : t.translate('detail.profile.error')))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={isShow}
            toggleShow={toggleIsShow}
            title={t.translate('detail.profile.title')}
            footer={
                <div className="flex-row">
                    {success
                        ? <Button label={t.translate('detail.profile.success.btn')} onClick={() => handleClose()} disabled={isLoading} displayLoader={isLoading} />
                        : <Button label={t.translate('detail.profile.btn')} onClick={() => handleSubmitCompanyProfile()} disabled={isLoading} displayLoader={isLoading} />
                    }
                </div>
            }
        >
            {success
                ? <p>{t.translate('detail.profile.success.text1')}</p> 
                : <form className="CompanyProfileModal-content-body-form flex-gap-row">
                    <h4 style={{ marginBottom: 0 }}>{t.translate('detail.profile.manager.title')} :</h4>
                    <div className="flex-row flex-wrap flex-gap-col full-width">
                        <div className="flex-column-start">
                            <Label for="contact-email" label={t.translate('detail.profile.contact.email.label')}
                                required />
                            <Input
                                required
                                name="contact-email"
                                type="email"
                                disabled={isLoading}
                                placeholder={t.translate('detail.profile.contact.email.placeholder')}
                                value={managerEmail}
                                setValue={(e) => setManagerEmail(e.currentTarget.value)}
                            />
                        </div>                              
                        <div className="flex-column-start">
                            <Label name="contact-phone" label={t.translate('detail.profile.contact.phone.label')} required />
                            <Phone
                                required
                                labelledBy="contact-phone"
                                defaultCountryCode={countryCode}
                                setCountryCode={setCountryCode}
                                defaultPhoneNumber={phoneNumber}
                                setPhoneNumber={setPhoneNumber}
                            />
                        </div>
                    </div>
                    <div className="flex-row flex-wrap flex-gap-col full-width">
                        <div className="flex-column-start">
                            <Label for="contact-lastname" label={t.translate('detail.profile.manager.lastname.label')} required />
                            <Input
                                required
                                name="contact-lastname"
                                disabled={isLoading}
                                placeholder={t.translate('detail.profile.manager.lastname.placeholder')}
                                value={managerLastName}
                                setValue={(e) => setManagerLastName(e.target.value)}
                            />
                        </div>
                        <div className="flex-column-start">
                            <Label for="contact-firstname" label={t.translate('detail.profile.manager.firstname.label')} required />
                            <Input
                                required
                                name="contact-firstname"
                                disabled={isLoading}
                                placeholder={t.translate('detail.profile.manager.firstname.placeholder')}
                                value={managerFirstName}
                                setValue={(e) => setManagerFirstName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex-row flex-wrap flex-gap-col full-width">
                        <div className="flex-column-start">
                            <Label for="contact-position" label={t.translate('detail.profile.manager.position.label')} required />
                            <Input
                                required
                                name="contact-position"
                                disabled={isLoading}
                                placeholder={t.translate('detail.profile.manager.position.placeholder')}
                                value={managerPosition}
                                setValue={(e) => setManagerPostition(e.target.value)}
                            />
                        </div>
                    </div>
                    <h4 style={{ marginBottom: 0 }}>{t.translate('detail.profile.contact.title')} :</h4>
                    <p>Set company informations</p>
                    <ErrorMessage error={error} />
                </form>
            }
        </Modal>
    )
}

export default CompanyProfileModal;