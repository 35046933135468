import translationService from "./translation.service";

class MonthService {
    public loadMonthFromNumber = (monthNumber: number): string => {
        switch (monthNumber) {
            case 0:
                return 'january';
            case 1:
                return 'february';
            case 2:
                return 'march';
            case 3:
                return 'april';
            case 4:
                return 'may';
            case 5:
                return 'june';
            case 6:
                return 'july';
            case 7:
                return 'august';
            case 8:
                return 'september';
            case 9:
                return 'october';
            case 10:
                return 'november';
            case 11:
                return 'december';
        
            default:
                return 'Month not found';
        }
    }

    public previousYear = (date: Date): Date => new Date(date.getFullYear()-1, date.getMonth(), date.getDate());
    public nextYear = (date: Date): Date => new Date(date.getFullYear()+1, date.getMonth(), date.getDate());

    public previousMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth()-1, date.getDate());
    public nextMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth()+1, date.getDate());

    public previousDay = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), date.getDate()-1);
    public nextDay = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), date.getDate()+1);

    public getFirstDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1);
    public getLastDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth()+1, 0);

    public getFirstDayOfYear = (date: Date): Date => new Date(date.getFullYear(), 1, 1);
    public getLastDayOfYear = (date: Date): Date => new Date(date.getFullYear()+1, 1, 0);

    public getFirstDayOfPreviousMonth = (): Date => this.getFirstDayOfMonth(this.previousMonth(new Date()));
    public getLastDayOfPreviousMonth = (): Date => this.getLastDayOfMonth(this.previousMonth(new Date()));

    public getFirstDayOfCurrentMonth = (): Date => this.getFirstDayOfMonth(new Date());
    public getLastDayOfCurrentMonth = (): Date => this.getLastDayOfMonth(new Date());

    public getFirstDayOfNextMonth = (): Date => this.getFirstDayOfMonth(this.nextMonth(new Date()));
    public getLastDayOfNextMonth = (): Date => this.getLastDayOfMonth(this.nextMonth(new Date()));

    public getFirstDayOfPreviousYear = (): Date => this.getFirstDayOfYear(new Date());
    public getLastDayOfPreviousYear = (): Date => this.getLastDayOfYear(new Date());

    public getFirstDayOfCurrentYear = (): Date => this.getFirstDayOfYear(new Date());
    public getLastDayOfCurrentYear = (): Date => this.getLastDayOfYear(new Date());

    public getFirstDayOfNextYear = (): Date => this.getFirstDayOfYear(new Date());
    public getLastDayOfNextYear = (): Date => this.getLastDayOfYear(new Date());

    public getPreviousMonth = (params?: { lower?: boolean }): string => params?.lower ? translationService.loadMonth(this.loadMonthFromNumber((new Date()).getMonth()-1)).toLowerCase() : translationService.loadMonth(this.loadMonthFromNumber((new Date()).getMonth()-1));
    public getCurrentMonth = (params?: { lower?: boolean }): string => params?.lower ? translationService.loadMonth(this.loadMonthFromNumber((new Date()).getMonth())).toLowerCase() : translationService.loadMonth(this.loadMonthFromNumber((new Date()).getMonth()));
    public getNextMonth = (params?: { lower?: boolean }): string => params?.lower ? translationService.loadMonth(this.loadMonthFromNumber((new Date()).getMonth()+1)).toLowerCase() : translationService.loadMonth(this.loadMonthFromNumber((new Date()).getMonth()+1));
    public getMonth = (monthNumber: number, params?: { lower?: boolean }): string => params?.lower ? translationService.loadMonth(this.loadMonthFromNumber(monthNumber)).toLowerCase() : translationService.loadMonth(this.loadMonthFromNumber(monthNumber));
}

export default new MonthService();