import { Loader } from "components";
import FoodAdvicePreview from "components/DashboardHome/components/Patient/components/FoodAdvicePreview";
import NextAppointmentPreview from "components/DashboardHome/components/Patient/components/NextAppointmentPreview";
import PrescriptionPreview from "components/DashboardHome/components/Patient/components/PrescriptionPreview";
import ClaimKit from "components/DashboardHome/components/RequestNewKit/ClaimKit";
import "components/DashboardHome/scss/PatientDashboard.scss";
import SurveyResume from "components/common/Dashboard/SurveyResume/SurveyResume";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import ReferringDoctor from "components/common/ReferringDoctor/ReferringDoctor";
import SubscriptionTracking from "components/common/SubscriptionTracking/SubscriptionTracking";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { UserContext } from "context/User/UserContext";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import subscriptionPlanService from "services/subscriptionPlan.service";

const PatientDashboardHomePage: React.FC = () => {
    const t = useTranslation('dashboard/patient/home');
    const { user } = useContext(UserContext);
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ subscriptionProcessing, setSubscriptionProcessing ] = useState<ISubscriptionTrackingSteps>();

    const fetchData = async () => {
        subscriptionPlanService.getSubscriptionProcessing()
            .then((res) => setSubscriptionProcessing(res))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        user?.patientInfo?.canClaimKit
            ? <ClaimKit />
            : <Fragment>
                <DashboardHeader
                    preTitle={t.translate('header.my')}
                    title={t.translate('header.dashboard')}
                />
                <Fragment>
                    {isLoading
                        ? <Loader />
                        : <Fragment>
                            <SubscriptionTracking tracking={subscriptionProcessing} />
                            <SurveyResume />
                            {subscriptionTrackingDetail?.handleShouldShowAppointment() &&
                                <div className="row flex-wrap dashboard-container-gap">
                                    <NextAppointmentPreview />
                                    <ReferringDoctor />
                                </div>
                            }
                            {subscriptionTrackingDetail?.handleShouldHidePrescription() ?? <PrescriptionPreview />}
                            <FoodAdvicePreview />
                        </Fragment>
                    }
                </Fragment>
            </Fragment>
    )
}

export default PatientDashboardHomePage;