import default_img from 'assets/foods/default_food.png';
import { CloseIcon } from 'components';
import { IDatabaseScoreFood } from "interfaces/Database/IDatabaseScoreFood";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { useTranslation } from "services";
import dietService from "services/diet.service";
import foodsService from "services/foods.service";
import translationService from "services/translation.service";
import "./scss/ModalFoodDetails.scss";

const ModalFoodDetail: React.FC<({
    handleClose: () => void,
    food?: IDatabaseScoreFood,
    greenLevel?: number,
    orangeLevel?: number,
})> = ({
    handleClose,
    food,
    greenLevel = 0,
    orangeLevel = 0,
}) => {
    const t = useTranslation('modals/FoodDetail');
    const [ foodImage, setFoodImage ] = useState();

    useEffect(() => {
        if (food) {
            document.documentElement.style.overflow = 'hidden';

            const loadImage = async () => {
                dietService.getFoodImage(food?.food)
                    .then((res) => setFoodImage(res))
                    .catch(() => setFoodImage(default_img.default));
            }
    
            loadImage();
        }
    }, [food]);

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            padding: '2vh 2rem 2vh 2rem',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "30rem",
            borderRadius: "5px",
            overflow: "hidden",
            boxShadow: '-5px 5px 20px 1px rgba(0, 0, 0, 0.12)'
        }
    };

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'scroll';
    }

    return (
        food ? (
            <Modal
                isOpen={food ? true : false}
                style={modalStyle}
                contentLabel="Food modal"
                preventScroll={true}
                ariaHideApp={false}
            >
                <div className="row space-between align-items-center" id="modal-food-details-header">
                    <p className="MavenPro-black" id="title">
                        {food?.food ? translationService.translateFood(food.food) : 'No name'}
                    </p>
                    <CloseIcon onClick={() => closeModal()} />
                </div>
                <div id="food-wrapper" className="row">
                    <div
                        id="food-img-wrapper"
                        style={
                            food.score
                                ? foodsService.isGreen(food.score, greenLevel)
                                    ? { backgroundColor: 'var(--dashboard-main-color)' }
                                    : foodsService.isOrange(food.score, orangeLevel)
                                        ? { backgroundColor: 'var(--orange)' }
                                        : { backgroundColor: 'var(--red)' }
                                : {}
                        }
                    >
                        <img
                            src={foodImage}
                            alt={food?.food ? translationService.translateFood(food.food) : 'No name'}
                            id="food-img"
                        />
                    </div>
                    <div className="flex-column-start" id="food-info-wrapper">
                        {
                            food.score ? (
                                <span
                                    id="food-type"
                                    className="MavenPro-black"
                                    style={food.score
                                        ? (foodsService.isGreen(food.score, greenLevel)
                                            ? { color: 'var(--dashboard-main-color)' }
                                            : foodsService.isOrange(food.score, orangeLevel)
                                                ? { color: 'var(--orange)' }
                                                : { color: 'var(--red)' }
                                        )
                                        : {}
                                    }
                                >
                                    {
                                        foodsService.isGreen(food.score, greenLevel) ?
                                            t.translate('nutriscore.good') :
                                            foodsService.isOrange(food.score, orangeLevel) ?
                                            t.translate('nutriscore.medium') :
                                            t.translate('nutriscore.bad')
                                    }
                                </span>
                            ) : (
                                <span id="food-type" className="MavenPro-black">
                                    {t.translate('nutriscore.uncalculated')}
                                </span>
                            )
                        }
                        {
                            food?.type && <span className="MavenPro text-italic">
                                {translationService.translateFoodType(food.type.name)}
                            </span>
                        }
                    </div>
                </div>
            </Modal>
        ) : null
    );
}

export default ModalFoodDetail;