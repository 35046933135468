import { Loader } from "components";
import FoodAdvicePreview from "components/DashboardHome/components/Patient/components/FoodAdvicePreview";
import CurrentPrescription from "components/Recommendations/components/CurrentPrescription";
import CurrentPreventiveAdvice from "components/Recommendations/components/CurrentPreventiveAdvice";
import CurrentTest from "components/Recommendations/components/CurrentTest";
import DoctorComment from "components/Recommendations/components/DoctorComment";
import "components/Recommendations/scss/Recommendations.scss";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import PreviousAnalysis from "components/common/PreviousAnalysis/PreviousAnalysis";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { IDAnalysisKit, IDAppointment, IDAppointmentWithNutritionist, IDGeneralComment, IDPrescription, IDPreventiveAdvice } from "interfaces/Database";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import analysisKitService from "services/analysisKit.service";
import appointmentService from "services/appointment.service";
import doctorService from "services/doctor.service";
import generalCommentService from "services/generalComment.service";
import messagingService from "services/messaging.service";
import prescriptionService from "services/prescription.service";
import preventiveAdviceService from "services/preventiveAdvice.service";
import subscriptionPlanService from "services/subscriptionPlan.service";

const Recommendations: React.FC = () => {
    const t = useTranslation('dashboard/patient/recommendations');
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ subscriptionProcessing, setSubscriptionProcessing ] = useState<ISubscriptionTrackingSteps>();
    const [ analysisKit, setAnalysisKit ] = useState<IDAnalysisKit>();
    const [ nextAppointment, setNextAppointment ] = useState<IDAppointment | IDAppointmentWithNutritionist>();

    const [ totalMessagesToDoctor, setTotalMessagesToDoctor ] = useState<number>(0);

    const [ generalComment, setGeneralComment ] = useState<IDGeneralComment>();
    const [ lastPrescription, setLastPrescription ] = useState<IDPrescription>();
    const [ lastPreventiveAdvice, setLastPreventiveAdvice ] = useState<IDPreventiveAdvice>();

    const loadData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            subscriptionPlanService.getSubscriptionProcessing().then((res) => setSubscriptionProcessing(res)),
            analysisKitService.getOnGoingAnalysisKit().then((res) => setAnalysisKit(res)),
            appointmentService.getNextAppointment().then((res) => setNextAppointment(res)),
            messagingService.getTotalMessagesWithDoctor().then((res) => setTotalMessagesToDoctor(res)),
            generalCommentService.getSelf().then((res) => setGeneralComment(res)),
            prescriptionService.getLast().then((res) => setLastPrescription(res)),
            preventiveAdviceService.getLast().then((res) => setLastPreventiveAdvice(res))
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleShouldHidePrescription = (): boolean => {
        if (subscriptionTrackingDetail?.isPlanMicrobiotaOnly() && (subscriptionTrackingDetail?.doctorAppointment || subscriptionTrackingDetail?.appointmentWNutritionist)) return true;
        if (subscriptionTrackingDetail?.isPlanMicrobiotaStressBurnout() && (subscriptionTrackingDetail?.doctorAppointment || subscriptionTrackingDetail?.appointmentWNutritionist)) return true;
        if (subscriptionTrackingDetail?.isPlanStressBurnout() && (subscriptionTrackingDetail?.doctorAppointment || subscriptionTrackingDetail?.appointmentWNutritionist)) return true;

        // By default, render appointments
        return false;
    }

    return (
        <div id="recommendations" className="flex-column-start">
            <DashboardHeader
                preTitle={t.translate('header.my')}
                title={t.translate('header.recommendations')}
            />
            <div className="flex-column full-width">
                {isLoading
                    ? <Loader loaderDescription={t.translate('loader')} />
                    : <Fragment>
                        <CurrentTest
                            tracking={subscriptionProcessing}
                            analysisKit={analysisKit}
                            nextAppointment={nextAppointment}
                        />
                        {handleShouldHidePrescription() &&
                            <Fragment>
                                <DoctorComment
                                    doctor={nextAppointment
                                        ? 'doctor' in nextAppointment
                                            ? nextAppointment.doctor
                                            : nextAppointment.nutritionist
                                        : undefined}
                                    numberMessagesSentToDoctor={totalMessagesToDoctor}
                                    generalComment={generalComment}
                                />
                                <CurrentPreventiveAdvice
                                    preventiveAdvice={lastPreventiveAdvice}
                                    doctorName={doctorService.formatDoctorName(
                                        nextAppointment && 'nutritionist' in nextAppointment
                                            ? (nextAppointment as unknown as IDAppointmentWithNutritionist).nutritionist.doctorInfo
                                            : (nextAppointment as unknown as IDAppointment).doctor.doctorInfo
                                    )}
                                />
                                <CurrentPrescription
                                    prescription={lastPrescription}
                                />
                            </Fragment>
                        }
                        <FoodAdvicePreview />
                        <PreviousAnalysis />
                    </Fragment>
                }
            </div>
        </div>
    )
}

export default Recommendations;