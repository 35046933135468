import apiService from "./api.service";

class InsuranceService {
    public async getInsuranceCoverage() {
        return await apiService.makeApiCall<string>(`/api/standalone/insurance/coverage`, 'get');
    }

    public async sendInsuranceCoverageByMail() {
        return await apiService.makeApiCall(`/api/insuranceCoverage/byEmail`, 'get');
    }

    public async getInsuranceCoverageAsFile() {
        return await apiService.makeApiCall(`/api/standalone/insurance/coverage/asFile`, 'getFile');
    }
}

export default new InsuranceService();