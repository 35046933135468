import { AddIcon, RemoveIcon } from "components/Icons";
import React, { useState } from "react";
import ReactPlayer from 'react-player';
import useTranslationService from "services/translation2.service";
import "../scss/VideosTests.scss";

const VideosTests: React.FC = () => {
    const t = useTranslationService('how-it-works');
    const [ indexCurrentTest, setIndexCurrentTest ] = useState<number>(-1);

    const TESTS = [
        {
            title: "tests-videos.content.salivary-cortisol.name",
            description: "tests-videos.content.salivary-cortisol.instructions",
            videoUrl: ""
        },
        {
            title: "tests-videos.content.salivary-2.name",
            description: "tests-videos.content.salivary-2.instructions",
            videoUrl: ""
        },
        {
            title: "tests-videos.content.urine.name",
            description: "tests-videos.content.urine.instructions",
            videoUrl: ""
        },
        {
            title: "tests-videos.content.stool.name",
            description: "tests-videos.content.stool.instructions",
            videoUrl: ""
        },
        {
            title: "tests-videos.content.blood.name",
            description: "tests-videos.content.blood.instructions",
            videoUrl: ""
        },
        {
            title: "tests-videos.content.sending-samples.name",
            description: "tests-videos.content.sending-samples.instructions",
            videoUrl: ""
        }
    ];

    const renderTests = TESTS.map((test, index) =>
        <section key={index} className="video-test-container column">
            <div className="video-test-wrapper row" key={index}>
                <p className="test-title">{t.translate(test.title)}</p>
                {indexCurrentTest === index
                    ? <RemoveIcon
                        className="icon"
                        onClick={() => setIndexCurrentTest(-1)}
                    />
                    : <AddIcon
                        className="icon"
                        onClick={() => setIndexCurrentTest(index)}
                    />
                }
            </div>
            {indexCurrentTest === index &&
                <div className="row test-detail-wrapper">
                    <p className="test-description">
                        {t.translate(test.description)}
                    </p>
                    <div className="video-wrapper">
                        <ReactPlayer
                            url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                            width="100%"
                            height="100%"
                            controls={true}
                        />
                    </div>
                </div>
            }
        </section>
    );

    return (
        <div id="videos-tests" className="column">
            <div id="title-wrapper" className="column">
                <h1 className="text-gradient font-title" id="title">
                    {t.translate("tests-videos.title")}
                </h1>
                <span id="sub-title" className="MavenPro-black">
                    {t.translate("tests-videos.sub-title")}
                </span>
            </div>
            <div id="videos-tests-list-wrapper" className="column">
                {renderTests}
            </div>
        </div>
    );
}

export default VideosTests;