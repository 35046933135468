import logo from "assets/logos/bcu_full.svg";
import { CloseIcon, MenuIcon } from "components/Icons";
import PrivateSidebarLanguage from "components/PrivateLayout/Sidebar/components/PrivateSidebarLanguage";
import { UserContext } from "context/User/UserContext";
import { IMenuItem } from "interfaces/Pages/Layout/IMenuItems";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { AiFillExclamationCircle, AiFillInfoCircle, AiFillWarning } from "react-icons/ai";
import { BsHouseFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import "../scss/ResponsiveSidebar.scss";
import "../scss/Sidebar.scss";
import ModalShareBodycheckup from "./ModalShareBodycheckup";
import PrivateSidebarId from "./PrivateSidebarId";

const DefaultSidebar: React.FC<({
    displayShare?: boolean,
    menuStatus?: IMenuStatus,
    menuItems: IMenuItem[],
    helloMessage: JSX.Element,
    subMessage?: JSX.Element,
    button?: JSX.Element
})> = ({
    displayShare = false,
    menuStatus,
    menuItems = [],
    helloMessage,
    subMessage,
    button
}) => {
    const t = useTranslation('layout/sidebar');
    const { user, refreshUser } = useContext(UserContext);

    const [ isShare, setIsShare ] = useState<boolean>(false);
    const toggleIsShare = () => setIsShare(!isShare);
    const [ current, setCurrent ] = useState<string>('');
    const [ showResponsiveSidebar, setShowResponsiveSidebar ] = useState<boolean>(false);

    const fetchUser = useCallback(() => {
        if (!user) {
            refreshUser();
        }
    }, [user, refreshUser]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const setCurrentMenuItemSelected = () => {
        const splittedUrl = window.location.pathname.split("/");
        setCurrent(splittedUrl[2]);
    }

    useEffect(() => {
        if (showResponsiveSidebar) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ showResponsiveSidebar ]);

    useEffect(() => {
        setCurrentMenuItemSelected();
    }, [window.location.pathname]);

    const history = useHistory();

    const redirect = (route: string) => {
        history.push(route);
        setCurrentMenuItemSelected();
    }

    const handleIcon = (item: IMenuItem) => {
        const iconSize = 20;
        const code = item.code as keyof IMenuStatus;

        if (menuStatus && menuStatus[code]) {
            switch (menuStatus[code]) {
                case 'info':
                    return <AiFillInfoCircle color="var(--dashboard-main-color)" className="sidebar-icon" size={iconSize} />
                case 'warn':
                    return <AiFillWarning color="var(--orange)" className="sidebar-icon" size={iconSize} />
                case 'danger':
                    return <AiFillExclamationCircle color="var(--red)" className="sidebar-icon" size={iconSize} />
            }
        } else {
            if (item.code === 'home') {
                return <BsHouseFill color="var(--dashboard-main-color)" className="sidebar-icon" size={iconSize} />
            }
        }

        return null;
    }

    const renderItem = (item: IMenuItem, index?: number) => (
        <div
            key={`menu-item-${index}`}
            className={`menu-item ${item.logout ? 'menu-item-red' : ""} ${item.code === current ? 'menu-item-selected' : ""}`}
            onClick={() => item.logout ? userAuthService.logout(history, '/login', true) : redirect(item.path)}
        >
            <span>{t.translate(item.name)}</span>
            {handleIcon(item)}
        </div>
    )

    const handleRedirectHome = () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            history.push('/');
        } else {
            window.location.replace('https://bodycheckup.com');
        }
    }

    return (
        <Fragment>
            <aside id="sidebar">
                <img
                    onClick={handleRedirectHome}
                    src={logo}
                    alt="Bodycheckup"
                    id="sidebar-logo"
                />

                <div id="sidebar-welcome">
                    {helloMessage}
                    {subMessage ? subMessage : null}
                    {button ? button : null}
                </div>

                <div id="sidebar-menu">
                    {menuItems.map((item, index) => renderItem(item, index))}
                </div>

                {displayShare && <p onClick={() => toggleIsShare()} className="clickable" style={{ color: 'var(--dashboard-main-color)' }}><u>{t.translate('shareTitle')}</u></p>}

                <PrivateSidebarLanguage />

                <PrivateSidebarId />
            </aside>
            {showResponsiveSidebar
                ? <aside id="responsive-sidebar" onClick={() => setShowResponsiveSidebar(!showResponsiveSidebar)}>
                    <div id="responsive-sidebar-wrapper">
                        <img
                            onClick={handleRedirectHome}
                            src={logo}
                            alt="Bodycheckup"
                            id="responsive-sidebar-logo"
                        />

                        <div
                            id="responsive-sidebar-close"
                            onClick={() => setShowResponsiveSidebar(!showResponsiveSidebar)}
                        >
                            <CloseIcon color={"var(--gray)"} />
                        </div>

                        <div id="responsive-sidebar-welcome">
                            {helloMessage}
                            {subMessage ? subMessage : null}
                            {button ? button : null}
                        </div>

                        <div id="sidebar-menu">
                            {menuItems.map((item, index) => renderItem(item, index))}
                        </div>

                        <PrivateSidebarLanguage />

                        <PrivateSidebarId />
                    </div>
                </aside>
                : <MenuIcon
                    id="responsive-sidebar-button"
                    color={"var(--gray)"}
                    onClick={() => setShowResponsiveSidebar(!showResponsiveSidebar)}
                />
            }
            <ModalShareBodycheckup showModal={isShare} toggleShow={toggleIsShare} />
        </Fragment>
    );
}

export default DefaultSidebar;