export interface IDayIntakeSchedule {
    morning: boolean
    noon: boolean
    evening: boolean
}
export interface IIntakeSchedule {
    monday: IDayIntakeSchedule
    tuesday: IDayIntakeSchedule
    wednesday: IDayIntakeSchedule
    thursday: IDayIntakeSchedule
    friday: IDayIntakeSchedule
    saturday: IDayIntakeSchedule
    sunday: IDayIntakeSchedule
}

const defaultDayIntake: IDayIntakeSchedule = {
    morning: false,
    noon: false,
    evening: false
}

export const defaultIntakeSchedule: IIntakeSchedule = {
    monday: defaultDayIntake,
    tuesday: defaultDayIntake,
    wednesday: defaultDayIntake,
    thursday: defaultDayIntake,
    friday: defaultDayIntake,
    saturday: defaultDayIntake,
    sunday: defaultDayIntake
}