import { Button, TextArea } from 'components';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import { Modal } from 'components/common/Modal';
import React, { useEffect, useState } from "react";
import { useInternalNoteService, useTranslation } from 'services';

const ModalCreateInternalNote: React.FC<({
    patientId: string;
    showModal: boolean;
    handleClose: () => void;
    handleSuccess: () => void;
})> = ({
    patientId,
    showModal,
    handleClose,
    handleSuccess
}) => {
    const t = useTranslation('patientDetail');
    const internalNoteService = useInternalNoteService();
    
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ text, setText ] = useState<string>();

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    const closeModal = () => {
        handleClose();
        setText(undefined);
    }

    const handleConfirm = () => {
        setError(undefined);
        setIsLoading(true);

        if (text) {
            internalNoteService.create(patientId, { note: text })
                .then(() => handleSuccess())
                .catch((err) => setError(err.message))
                .finally(() => {
                    closeModal();
                    setIsLoading(false)
                });
        } else {
            setError('parameters_missing');
            setIsLoading(false);
        }
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate('modals.create.title')}
            footer={
                <div className="flex-row-end">
                    <Button
                        label={t.translate('modals.cancel')}
                        onClick={closeModal}
                        disabled={isLoading}
                    />
                    <Button
                        label={t.translate('modals.confirm')}
                        onClick={handleConfirm}
                        disabled={isLoading}
                        displayLoader={isLoading}
                    />
                </div>
            }
        >
            <TextArea
                required
                name='new-internal-note'
                disabled={isLoading}
                placeholder={t.translate('modals.create.placeholder')}
                value={text}
                setValue={(e) => setText(e.currentTarget.value)}
            />

            <ErrorMessage error={error} />
        </Modal>
    )
}

export default ModalCreateInternalNote;