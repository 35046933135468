import { Button } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import React from 'react';
import { FaSlack } from 'react-icons/fa';
import { useTranslation } from 'services';

const InternalChat: React.FC = () => {
    const t = useTranslation('dashboard/doctor/internalChat');

    return (
        <DefaultContainer
            title={<h2 className='bold'>{t.translate("title")}</h2>}
            body={
                <div id="chat-interne" className="row">
                    <div id="slack" className="flex-row">
                        <FaSlack
                            className="clickable"
                            color="var(--dashboard-main-color)"
                            size={24}
                        />
                        <p>{t.translate("slack")}</p>
                    </div>
                </div>
            }
            footer={
                <Button label={t.translate("tchat")} onClick={() => window.open("https://join.slack.com/t/bodycheckup/shared_invite/zt-1f041boki-MH428oOmTs5P5J0M0KovAw", "_blank")} />
            }
        />
    )
}

export default InternalChat 