import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import React, { useState } from "react";
import { toast } from 'react-toastify';
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import ModalEditPassword from "../../../modals/ModalEditPassword";
import "../scss/ProfileAdditionalActions.scss";

const ProfileAdditionalActions: React.FC = () => {
    const t = useTranslation('profile/patient/profileAdditionalActions');
    const [ showModalDelete, setShowModalDelete ] = useState<boolean>(false);
    const [ showModalEditPass, setShowModalEditPass ] = useState<boolean>(false);

    const handleSuccessEditPass = () => {
        setShowModalEditPass(false);

        toast.success(t.translate("changePasswordSuccess"), {
            theme: "light"
        });
    }

    const handleSendRemovePersonalData = async () => {
        await userAuthService.sendRequestRemovePersonalData()
            .then(() => toast.success(t.translate("toast.success"), {
                theme: "light"
            }))
            .catch(() => toast.error(t.translate("toast.fail"), {
                theme: "light"
            }));
    }

    return (
        <div id="profile-add-actions" className="row">
            <div className="column action-wrapper">
                <p className="action MavenPro-black" onClick={() => setShowModalEditPass(true)}>
                    {t.translate("changePassword")}
                </p>
                <span className="action-desc">
                    {t.translate("descChangePassword")}
                </span>
            </div>
            <div className="column action-wrapper">
                <p className="action MavenPro-black" onClick={() => setShowModalDelete(true)}>
                    {t.translate("deletePersonalInformation")}
                </p>
                <span className="action-desc">
                    {t.translate("descDeletePersonalInformation")}
                </span>
            </div>
            <ModalConfirmAction
                showModal={showModalDelete}
                handleClose={() => setShowModalDelete(false)}
                confirmAction={handleSendRemovePersonalData}
                description={t.translate('modalPersonnalData.description')}
            />
            <ModalEditPassword
                showModal={showModalEditPass}
                handleClose={() => setShowModalEditPass(false)}
                handleSuccess={handleSuccessEditPass}
            />
        </div>
    );
}

export default ProfileAdditionalActions;