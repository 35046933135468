import SubscriptionTrackingDetail, { ISubscriptionTrackingDetail } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingDetail";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import apiService from "./api.service";

class SubscriptionPlanService {
    private ROOT_ROUTE = '/api/subscription';

    public getSubscriptionProcessing = async () => {
        return await apiService.makeApiCall<ISubscriptionTrackingSteps>(this.ROOT_ROUTE+`/getProcessing`, 'get');
    }

    public getSubscriptionProcessingDetailed = async () => {
        return await apiService.makeApiCall<SubscriptionTrackingDetail>(this.ROOT_ROUTE+`/processing/detailed`, 'get')
            .then((res: ISubscriptionTrackingDetail) => new SubscriptionTrackingDetail(res));
    }
}

export default new SubscriptionPlanService();