import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import React, { useState } from "react";
import { toast } from 'react-toastify';
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import ModalEditPassword from '../../../modals/ModalEditPassword';

const CompanyAdditionalInfos: React.FC = () => {
    const t = useTranslation('dashboard/company/profile');
    const [ showChangePwdModal, setShowChangePwdModal ] = useState<boolean>(false);
    const [ showPersonalDataModal, setShowPersonalDataModal ] = useState<boolean>(false);

    const togglePwdModal = () => setShowChangePwdModal(!showChangePwdModal);
    const togglePDModal = () => setShowPersonalDataModal(!showPersonalDataModal);

    const handleSuccess = () => {
        setShowChangePwdModal(false);
        toast.success(t.translate('additional.password.success'), {
            theme: "light"
        });
    }

    const handleSendRemovePersonalData = async () => {
        userAuthService.sendRequestRemovePersonalData()
            .then(() => toast.success(
                t.translate('additional.deletePersonnal.success'),
                { theme: "light" }
            ))
            .catch(() => toast.error(
                t.translate('additional.deletePersonnal.error'),
                { theme: "light" }
            ));
    }

    return (
        <div id="profile-add-actions" className="row">
            <div className="column action-wrapper" onClick={() => togglePwdModal()}>
                <p className="action MavenPro-black">
                    {t.translate('additional.password.title')}
                </p>
                <span className="action-desc">
                    {t.translate('additional.password.sub')}
                </span>
            </div>
            <div className="column action-wrapper" onClick={() => togglePDModal()}>
                <p className="action MavenPro-black">
                    {t.translate('additional.deletePersonnal.title')}
                </p>
                <span className="action-desc">
                    {t.translate('additional.deletePersonnal.sub')}
                </span>
            </div>
            <ModalEditPassword showModal={showChangePwdModal} handleClose={togglePwdModal} handleSuccess={handleSuccess} />
            <ModalConfirmAction
                showModal={showPersonalDataModal}
                handleClose={() => setShowPersonalDataModal(false)}
                confirmAction={handleSendRemovePersonalData}
                description={t.translate('additional.deletePersonnal.confirm')}
            />
        </div>
    )
}

export default CompanyAdditionalInfos;