import { IQuestion } from "interfaces/Survey/IQuestion";

// TODO : Remove this const TS later, when sure working without labels and descriptions
const TS = 'various/questions.';

const questions: IQuestion[] = [
    {
        // QUESTION 1
        label: TS+"activity.question",
        answers: [
            "yes",
            "no"
        ],
        type: "single_answer",
        code: "ACTIVITY"
    },
    {
        // QUESTION 2
        label: TS+"tobacco.question",
        answers: [
            "non",
            "former",
            "occasional",
            "regular"
        ],
        type: "single_answer",
        code: "TOBACCO"
    },
    {
        // QUESTION 3
        label: TS+"alcohol.question",
        answers: [
            "never",
            "occasionally",
            "not_now",
            "regular"
        ],
        type: "single_answer",
        code: "ALCOHOL"
    },
    {
        // QUESTION 4
        label: TS+"pet.question",
        answers: [
            "yes",
            "no"
        ],
        type: "single_answer",
        code: "PET"
    },
    {
        // QUESTION 5
        label: TS+"sleep.question",
        answers: [
            "yes",
            "no"
        ],
        type: "single_answer",
        code: "SLEEP"
    },
    {
        // QUESTION 6
        label: TS+"delivery_method.question",
        answers: [
            "vaginal",
            "caesaran",
            "dont_know"
        ],
        type: "single_answer",
        code: "DELIVERY_METHOD"
    },
    {
        // QUESTION 7
        label: TS+"breastfed.question",
        answers: [
            "more_3_month",
            "less_3_month",
            "no",
            "dont_know"
        ],
        type: "single_answer",
        code: "BREASTFED"
    },
    {
        // QUESTION 8
        label: TS+"inflammatory.question",
        answers: [
            "no",
            "crohn",
            "colitis",
            "other"
        ],
        type: "single_answer",
        code: "INFLAMMATORY"
    },
    {
        // QUESTION 9
        label: TS+"chronic_disease.question",
        answers: [
            "no",
            "diabetes_1",
            "diabetes_2",
            "high_blood_pressure",
            "hyperlipidemia",
            "cardiovascular",
            "depression",
            "other"
        ],
        type: "single_answer",
        code: "CHRONIC_DISEASE"
    },
    {
        // QUESTION 10
        label: TS+"medication_intestinal.question",
        answers: [
            "yes",
            "no"
        ],
        type: "single_answer",
        code: "MEDICATION_INTESTINAL"
    },
    {
        // QUESTION 11
        label: TS+"which_symptom.question",
        answers: [
            "none",
            "gastric_reflux",
            "diarrhea",
            "constipation",
            "diarrhea_constipation",
            "bloating",
            "gas"
        ],
        type: "multiple_answers",
        code: "WHICH_SYMPTOM"
    },
    {
        // QUESTION 12
        label: TS+"medication_3months.question",
        answers: [
            "no",
            "oral_contraceptives",
            "antibiotics",
            "antidiabetic_1",
            "antidiabetic_2",
            "anti_hypertensive",
            "hyperlipidemia",
            "cardiovascular",
            "antidepressants",
            "anti_inflammatory",
            "analgesics",
            "other"
        ],
        type: "multiple_answers",
        code: "MEDICATION_3MONTHS"
    },
    {
        // QUESTION 13
        label: TS+"appendectomy.question",
        answers: [
            "yes",
            "no",
            "dont_know"
        ],
        type: "single_answer",
        code: "APPENDECTOMY"
    },
    {
        // QUESTION 14
        label: TS+"colon_cancer.question",
        answers: [],
        type: "input_answer",
        code: "COLON_CANCER"
    },
    {
        // QUESTION 15
        label: TS+"breast_cancer.question",
        answers: [],
        type: "input_answer",
        code: "BREAST_CANCER"
    },
    {
        // QUESTION 16
        label: TS+"cervical_cancer.question",
        answers: [],
        type: "input_answer",
        code: "CERVICAL_CANCER"
    },
    {
        // QUESTION 17
        label: TS+"medical_history.question",
        answers: [],
        type: "input_answer",
        code: "MEDICAL_HISTORY"
    },
    {
        // QUESTION 18
        label: TS+"medication_kind.question",
        answers: [],
        type: "input_answer",
        code: "MEDICATION_KIND"
    },
    {
        // QUESTION 19
        label: TS+"family_history.question",
        answers: [
            "no",
            "diabetes_1",
            "diabetes_2",
            "high_blood_pressure",
            "hyperlipidemia",
            "cardiovascular",
            "bowel",
            "cancer",
            "obesity",
            "depression",
            "hemorrhagic_rectocolitis",
            "dysthyroidism",
            "sudden_death",
            "other"
        ],
        type: "multiple_answers",
        code: "FAMILY_HISTORY"
    },
    {
        // QUESTION 20
        label: TS+"cognitive_ability.question",
        answers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        description: TS+"cognitive_ability.description",
        type: "slider_answer",
        code: "COGNITIVE_ABILITY"
    },
    {
        // QUESTION 21
        label: TS+"food_intolerance.question",
        answers: [
            "none",
            "gluten",
            "crustaceans",
            "eggs",
            "fish",
            "soya",
            "milk",
            "nuts",
            "mustard",
            "sesame",
            "SO2",
            "lupin",
            "molluscs",
            "other",
        ],
        description: "",
        type: "multiple_answers",
        code: "FOOD_INTOLERANCE"
    },
    {
        // QUESTION 22
        label: TS+"quick_eat.question",
        answers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        description: TS+"quick_eat.description",
        type: "slider_answer",
        code: "QUICK_EAT"
    },
    {
        // QUESTION 23
        label: TS+"appetite.question",
        answers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        description: TS+"appetite.description",
        type: "slider_answer",
        code: "APPETITE"
    },
    {
        // QUESTION 24
        label: TS+"foodie.question",
        answers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        description: TS+"foodie.description",
        type: "slider_answer",
        code: "FOODIE"
    },
    {
        // QUESTION 25
        label: TS+"food_priority.question",
        answers: [
            "quantity",
            "more_quantity",
            "even",
            "more_quality",
            "quality"
        ],
        description: TS+"food_priority.description",
        type: "slider_answer",
        code: "FOOD_PRIORITY"
    },
    {
        // QUESTION 26
        label: TS+"diet.question",
        answers: [
            "omnivore",
            "vegan",
            "vegetarian",
            "pesco_vegetarian",
            "pork_free",
            "diabetic",
            "high_blood_pressure",
            "hyperlipidemia",
            "gluten_free",
            "lactose_free",
            "other"
        ],
        type: "multiple_answers",
        code: "DIET"
    },
    {
        // QUESTION 27
        label: TS+"water.question",
        answers: [
            "less_1l5",
            "about_1l5",
            "more_1l5"
        ],
        type: "single_answer",
        code: "WATER"
    },
    {
        // QUESTION 28
        label: TS+"food_category.question",
        answers: [
            "meat_eggs",
            "fish",
            "vegetables",
            "fruits",
            "dairy_products",
            "starches",
            // "pulses",
            "sweet_products",
            "fats",
            "sweet_drinks",
            "coffee_tea"
        ],
        type: "multiple_answers",
        code: "FOOD_CATEGORY"
    },
    {
        // QUESTION 29
        label: TS+"meals.question",
        answers: [
            "less",
            "three",
            "more"
        ],
        type: "single_answer",
        code: "MEALS"
    },
    {
        // QUESTION 30
        label: TS+"snacks.question",
        answers: [
            "none",
            "one",
            "two",
            "three",
            "more"
        ],
        type: "single_answer",
        code: "SNACKS"
    },
    {
        // QUESTION 31
        label: TS+"transit.question",
        answers: [
            "yes",
            "no"
        ],
        type: "single_answer",
        code: "TRANSIT"
    },
    {
        // QUESTION 32
        label: TS+"digestive_problem.question",
        answers: [
            "none",
            "acids",
            "gastric_reflux",
            "diarrhea",
            "constipation",
            "diarrhea_constipation",
            "bloating",
            "gas"
        ],
        type: "multiple_answers",
        code: "DIGESTIVE_PROBLEM"
    },
    {
        // QUESTION 33
        label: TS+"probiotics.question",
        answers: [
            "yes",
            "no"
        ],
        type: "single_answer",
        code: "PROBIOTICS"
    },
    {
        // QUESTION 34
        label: TS+"prebiotics.question",
        answers: [
            "yes",
            "no"
        ],
        type: "single_answer",
        code: "PREBIOTICS"
    },
    {
        // QUESTION 35
        label: TS+"stomach_pain.question",
        answers: [
            "never",
            "rarely",
            "sometimes",
            "usually",
            "always"
        ],
        description: TS+"stomach_pain.description",
        type: "slider_answer",
        code: "STOMACH_PAIN"
    },
    {
        // QUESTION 36
        label: TS+"pain_rate.question",
        answers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        description: TS+"pain_rate.description",
        type: "slider_answer",
        code: "PAIN_RATE"
    },
    {
        // QUESTION 37
        label: TS+"bloated.question",
        answers: [
            "never",
            "rarely",
            "sometimes",
            "usually",
            "always"
        ],
        description: TS+"bloated.description",
        type: "slider_answer",
        code: "BLOATED"
    },
    {
        // QUESTION 38
        label: TS+"reflux.question",
        answers: [
            "never",
            "rarely",
            "sometimes",
            "often",
            "dont_know"
        ],
        type: "single_answer",
        code: "REFLUX"
    },
    {
        // QUESTION 39
        label: TS+"acidity.question",
        answers: [
            "never",
            "rarely",
            "sometimes",
            "usually",
            "always"
        ],
        description: TS+"acidity.description",
        type: "slider_answer",
        code: "ACIDITY"
    },
    {
        // QUESTION 40
        label: TS+"laryngitis.question",
        answers: [
            "yes",
            "no",
            "dont_know"
        ],
        type: "single_answer",
        code: "LARYNGITIS"
    },
    {
        // QUESTION 41
        label: TS+"abdominal_cramps.question",
        answers: [
            "never",
            "rarely",
            "sometimes",
            "usually",
            "always"
        ],
        description: TS+"abdominal_cramps.description",
        type: "slider_answer",
        code: "ABDOMINAL_CRAMPS"
    },
    {
        // QUESTION 42
        label: TS+"pass_wind.question",
        answers: [
            "never",
            "rarely",
            "sometimes",
            "usually",
            "always"
        ],
        description: TS+"pass_wind.description",
        type: "slider_answer",
        code: "PASS_WIND"
    },
    {
        // QUESTION 43
        label: TS+"odour_wind.question",
        answers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        description: TS+"odour_wind.description",
        type: "slider_answer",
        code: "ODOUR_WIND"
    },
    {
        // QUESTION 44
        label: TS+"undo_belt.question",
        answers: [
            "never",
            "rarely",
            "sometimes",
            "usually",
            "always"
        ],
        description: TS+"undo_belt.description",
        type: "slider_answer",
        code: "UNDO_BELT"
    },
    {
        // QUESTION 45
        label: TS+"consumption.question",
        answers: [
            "yes",
            "no",
            "dont_know"
        ],
        type: "single_answer",
        code: "CONSUMPTION"
    },
    {
        // QUESTION 46
        label: TS+"stool.question",
        answers: [
            "none",
            "one",
            "two",
            "more"
        ],
        type: "single_answer",
        code: "STOOL"
    },
    {
        // QUESTION 47
        label: TS+"stool_appearance.question",
        answers: [
            "nuts",
            "lumpy_sausage",
            "cracked_sausage",
            "smooth_sausage",
            "soft_blobs",
            "fluffy_pieces",
            "watery"
        ],
        type: "single_answer",
        code: "STOOL_APPEARANCE"
    },
    {
        // QUESTION 48
        label: TS+"stool_stick.question",
        answers: [
            "yes",
            "sometimes",
            "no"
        ],
        type: "single_answer",
        code: "STOOL_STICK"
    },
    {
        // QUESTION 49
        label: TS+"stool_color.question",
        answers: [
            "dark_brown",
            "light_brown",
            "green",
            "yellow",
            "red"
        ],
        type: "single_answer",
        code: "STOOL_COLOR"
    },
    {
        // QUESTION 50
        label: TS+"meals_before_test.question",
        answers: [],
        type: "input_answer",
        code: "MEALS_BEFORE_TEST"
    },
    {
        // QUESTION 51
        label: TS+"water_before_test.question",
        answers: [
            "dont_know",
            "less_1l5",
            "about_1l5",
            "more_1l5"
        ],
        type: "single_answer",
        code: "WATER_BEFORE_TEST"
    },
    {
        // QUESTION 52
        label: TS+"food_category_before_test.question",
        answers: [
            "none",
            "dont_know",
            "meat_eggs",
            "fish",
            "vegetables",
            "fruits",
            "dairy_products",
            "starches",
            // "pulses",
            "sweet_products",
            "fats",
            "sweet_drinks"
        ],
        type: "multiple_answers",
        code: "FOOD_CATEGORY_BEFORE_TEST"
    },
    {
        // QUESTION 53
        label: TS+"covid_infection.question",
        answers: [
            "yes",
            "no",
            "dont_know"
        ],
        type: "single_answer",
        code: "COVID_INFECTION"
    },
    {
        // QUESTION 54
        label: TS+"covid_contact.question",
        answers: [
            "yes",
            "no",
            "dont_know"
        ],
        type: "single_answer",
        code: "COVID_CONTACT"
    },
    {
        // QUESTION 55
        label: TS+"covid_symptom.question",
        answers: [
            "none",
            "fever",
            "headache",
            "unusual_tiruedness",
            "muscles_aches_pain",
            "cough",
            "difficulty_breathing",
            "runny_nose",
            "taste_smell_problems",
            "nausea",
            "vomiting",
            "diarrhea",
            "chest_pain",
            "skin_issues"
        ],
        type: "multiple_answers",
        code: "COVID_SYMPTOM"
    },
    {
        // QUESTION 56
        label: TS+"covid_discomfort.question",
        answers: [
            "yes",
            "no",
            "dont_know"
        ],
        type: "single_answer",
        code: "COVID_DISCOMFORT"
    },
    {
        // QUESTION 57
        label: TS+"why_bodycheckup.question",
        answers: [],
        type: "input_answer",
        code: "WHY_BODYCHECKUP"
    },
    {
        // QUESTION 58
        label: TS+"how_bodycheckup.question",
        answers: [
            "internet_search",
            "social_networks",
            "press",
            "magazine",
            "blog",
            "television",
            "radio",
            "word_of_mouth",
            "email",
            "health_professional",
            "other"
        ],
        type: "single_answer",
        code: "HOW_BODYCHECKUP"
    }
];

export default questions;