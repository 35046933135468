import { Checkbox, Input } from "components/Form";
import { IDSurveyAnswer } from "interfaces/Database";
import { IQuestion } from "interfaces/Survey/IQuestion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import translationService from "services/translation.service";
import "../scss/Question.scss";

const QuestionInput: React.FC<({
    question: IQuestion;
    handleValidation: (isValid: boolean) => void;
    handleUserAnswer: (params: string[]) => void;
    previousAnswers?: IDSurveyAnswer[];
})> = ({
    question,
    handleValidation,
    handleUserAnswer,
    previousAnswers
}) => {
    const t = useTranslation('common');
    const [ inputAnswer, setInputAnswer ] = useState<string>();

    useEffect(() => {
        if (inputAnswer && inputAnswer.trim().length > 0) {
            handleValidation(inputAnswer.trim().length > 0);
            handleUserAnswer([inputAnswer.trim()]);
        } else {
            handleValidation(false);
        }
    }, [inputAnswer, handleValidation, handleUserAnswer]);

    useEffect(() => {
        if (previousAnswers && previousAnswers.length > 0) {
            const previousAnswer = previousAnswers[previousAnswers.length-1];
            setInputAnswer(previousAnswer.answer);
        }
    }, [previousAnswers]);

    const handleNotConcerned = () => {
        if (inputAnswer === 'not_concerned') {
            setInputAnswer('');
            handleValidation(false);
        } else {
            setInputAnswer('not_concerned');
            handleValidation(true);
        }
    }

    return (
        <div className="question-wrapper">
            <h2>{translationService.translateSurveyQuestion(question.code)}</h2>
            {question.description && <span id="question-description">{translationService.translateSurveyDescription(question.code)}</span>}
            <Input
                name="input-answer"
                value={inputAnswer !== 'not_concerned' ? inputAnswer : ''}
                setValue={(e) => setInputAnswer(e.target.value.trimStart())}
                inputStyle={{ backgroundColor: 'white', marginTop: '4vh' }}
            />
            <Checkbox
                isChecked={inputAnswer === 'not_concerned'}
                name="isConcerned"
                setChecked={handleNotConcerned}
                label={t.translate('not_concerned')}
            />
        </div>
    );
}

export default QuestionInput;