import { InformationWrapper } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { IDatabaseUser } from 'interfaces/Database/IDatabaseUser';
import React from 'react';
import { useTranslation } from 'services';
import '../scss/InformationsMedicals.scss';

const InformationsMedicals: React.FC<({
    patient?: IDatabaseUser
})> = ({ patient }) => {
    const t = useTranslation('patientDetail');

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate("patientMedicalInformation.title")}</h1>}
            body={
                <div className='row full-width'>
                    <div className='row full-width space-around'>
                        <div className='col-data column'>
                            <InformationWrapper
                                libelle={t.translate("patientMedicalInformation.size")}
                                value={patient?.patientInfo?.size}
                            />
                            <InformationWrapper
                                libelle={t.translate("patientMedicalInformation.weight")}
                                value={patient?.patientInfo?.weight}
                            />
                        </div>
                        <div className='col-data column'>
                            <InformationWrapper
                                libelle={t.translate("patientMedicalInformation.waistSize")}
                                value={patient?.patientInfo?.waistSize}
                            />
                            <InformationWrapper
                                libelle={t.translate("patientMedicalInformation.age")}
                                value={patient?.patientInfo?.birthDate
                                    ? Math.abs(new Date(Date.now() - patient.patientInfo.birthDate.getTime()).getFullYear() - 1970)
                                    : undefined
                                }
                            />
                        </div>
                        <div className='col-data column'>
                            <InformationWrapper
                                libelle={t.translate("patientMedicalInformation.specialMedication")}
                                value={patient?.patientInfo?.specialMedication}
                            />
                            <InformationWrapper
                                libelle={t.translate("patientMedicalInformation.specialInformation")}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default InformationsMedicals