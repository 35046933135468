import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe, StripeEmbeddedCheckoutOptions } from "@stripe/stripe-js";
import { Loader } from "components";
import { LanguageContext } from "context/Language/LanguageContext";
import { IDAddress, IDPlatform, IDProductOrderType } from "interfaces/Database";
import PaymentType from "interfaces/Database/IDatabasePaymentType";
import { useContext, useEffect, useMemo, useState } from "react";
import useCheckoutService from "services/checkout.service";

interface AppointmentPaymentProps {
    appointmentType: 'doctor' | 'nutritionist'
    paymentTypes: PaymentType[]
    platform: IDPlatform
    productOrderType: IDProductOrderType
    renew: boolean
    billingAddress: IDAddress
    togglePreviousStep: () => void
}

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PK), { locale: 'auto' });

const AppointmentPayment: React.FC<AppointmentPaymentProps> = (props) => {
    const checkoutService = useCheckoutService();

    const quantity = 1;

    const { language } = useContext(LanguageContext);
    const [ clientSecret, setClientSecret ] = useState<string>('');
    const [ paymentType, setPaymentType ] = useState<PaymentType>();

    const options: StripeEmbeddedCheckoutOptions = useMemo(() => ({
        clientSecret: clientSecret,
    }), [ clientSecret ]);

    const loadData = () => {
        if (paymentType) {
            checkoutService.createCheckoutSessionForAppointment({
                quantity: quantity,
                paymentTypeId: paymentType?.id,
                appointmentType: props.appointmentType,
                platformId: props.platform.id,
                productOrderTypeId: props.productOrderType.id,
                renew: props.renew,
                // options: props.options,
                language: language.countryCode
            })
                .then((res) => setClientSecret(res.client_secret));
        }
    }

    useEffect(() => {
        if (props.appointmentType) {
            setPaymentType(props.paymentTypes.filter((e) => e.isOneTime())[0]);
        }
    }, [props.appointmentType]);

    useEffect(() => {
        setClientSecret('');
        loadData();
    }, [paymentType, language]);

    if (!clientSecret) {
        return (
            <Loader />
        )
    }

    return (
        <div id="checkout" className="full-width">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    )
}

export default AppointmentPayment;