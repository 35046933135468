import img4 from 'assets/Help/analysis.svg';
import img5 from 'assets/Help/doctor.svg';
import img2 from 'assets/Help/order.svg';
import img1 from 'assets/Help/profile.svg';
import { Button } from 'components';
import { SubscriptionTrackingDetailContext } from 'context/SolutionProcessingContext';
import { UserContext } from 'context/User/UserContext';
import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'services';
import userAuthService from 'services/user-auth.service';

const SSSixthPage: React.FC = () => {
    const history = useHistory();
    const { refreshUser } = useContext(UserContext);
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const t = useTranslation('dashboard/standalone/help');

    const handleClick = (path: string) => {
        userAuthService.setFirstLogin({ firstLogin: false })
            .catch((err) => console.warn(err))
            .finally(() => refreshUser());

        history.push(path);
    }

    return (
        <div className="help-step">
            <h2 style={{ textAlign: 'center' }}>{t.translate('standalone.resume.title')}</h2>

            <div className="help-sum-up">
                <div>
                    <img src={img1} alt="Complete profile" />
                    <div className="flex-column">
                        <h3 className='MavenPro'><span className='text-main-color'>1.</span> {t.translate('standalone.resume.profile.title')}</h3>
                        <Button label={t.translate('standalone.resume.profile.button')} onClick={() => handleClick('/account/profile')} />
                    </div>
                </div>
                <div>
                    <img src={img2} alt="Order cart" />
                    <div className="flex-column">
                        <h3 className='MavenPro'><span className='text-main-color'>2.</span> {t.translate('standalone.resume.buy.title')}</h3>
                        <Button label={t.translate('standalone.resume.buy.button')} onClick={() => handleClick('/order')} />
                    </div>
                </div>
                <div>
                    <img src={img4} alt="Sample analysis" />
                    <div className="flex-column">
                        <h3 className='MavenPro'><span className='text-main-color'>3.</span> {t.translate('standalone.resume.results.title')}</h3>
                        <Button label={t.translate('standalone.resume.results.button')} onClick={() => handleClick('/account/tests')} />
                    </div>
                </div>
                { subscriptionTrackingDetail?.subscriptionPlan?.isTypeCancer() ||
                    <div>
                        <img src={img5} alt="Doctor appointment" />
                        <div className="flex-column">
                            <h3 className='MavenPro'><span className='text-main-color'>4.</span> {t.translate('standalone.resume.appointment.title')}</h3>
                            <Button label={t.translate('standalone.resume.appointment.button')} onClick={() => handleClick('/account/appointments')} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default SSSixthPage;