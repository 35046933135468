import { Loader } from "components";
import OrderNotFound from "components/Order/components/OrderNotFound";
import "components/Order/scss/OrderDetail.scss";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDatabaseProductOrder } from "interfaces/Database/IDatabaseProductOrder";
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from "react";
import { FiArrowLeftCircle } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import orderService from "services/order.service";
import translationService from "services/translation.service";

const OrderDetail: React.FC = () => {
    const history = useHistory();
    const { orderId } = useParams<({ orderId?: string })>();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ currentOrder, setCurrentOrder ] = useState<IDatabaseProductOrder>();
    const [ orderNotFound, setOrderNotFound ] = useState<boolean>(false);

    const fecthOrderDetail = useCallback(async () => {
        setIsLoading(true);
        setOrderNotFound(false);

        if (orderId) {
            await orderService.getProductOrderDetails(orderId)
                .then((res) => setCurrentOrder(res))
                .catch(() => setOrderNotFound(true))
                .finally(() => setIsLoading(false));
        } else {
            handleErrorFetchOrder();
            setIsLoading(false);
        }
    }, [orderId]);

    useEffect(() => {
        fecthOrderDetail();
    }, [fecthOrderDetail]);

    const handleErrorFetchOrder = () => {
        setOrderNotFound(true);
    }

    const handleGoBack = () => {
        history.push("/account/order");
    }

    return (
        <div>
            <DashboardHeader
                preTitle={"Order"}
                title={"Detail"}
                button={
                    <FiArrowLeftCircle
                        size={36}
                        color="var(--dashboard-main-color)"
                        id="icon-back"
                        onClick={handleGoBack}
                    />
                }
            />
            <DefaultContainer
                body={isLoading
                    ? <Loader />
                    : orderNotFound && !currentOrder
                        ? <OrderNotFound />
                        : <div className="flex-column" id="order-details-container">
                            <h1>Order details</h1>
                            <div className="row" id="product-container">
                                <div id="product-info-left">
                                    <div id="product-ref-container">
                                        <p>Product reference :
                                            <span className="bold"> {orderId}</span>
                                        </p>
                                    </div>
                                    <div className="row" id="product-img-container">
                                        <img
                                            src="https://image.fritzhansen.com/~/media/C915C1EFDF2F4F38A6D8B34FB73CC787.ashx"
                                            alt={`Ordered product ${currentOrder?.subscriptionPlanType?.type?.name} ${currentOrder?.subscriptionPlanType?.type?.category?.name}`}
                                        />
                                        <p id="img-desc">
                                            Phasellus ac tristique nisi. Nunc gravida nunc et nunc hendrerit, non bibendum leo auctor.
                                            Phasellus felis lectus, vehicula a leo quis, rhoncus imperdiet nunc. Praesent pretium
                                            nulla ut mi convallis, id elementum elit egestas. Maecenas auctor in neque vitae aliquet.
                                            Donec ut diam vitae odio dapibus tempus.
                                    </p>
                                    </div>
                                </div>
                                <div id="product-info-right" className="column">
                                    <div className="row information">
                                        <span>Order Number</span>
                                        <span className="information-bold">{orderId}</span>
                                    </div>
                                    <div className="row information">
                                        <span>Price</span>
                                        <span className="information-bold">{currentOrder?.totalPrice ?? 0}€ / Month</span>
                                    </div>
                                    {/* <div className="row information">
                                        <span>Status</span>
                                        <span className="information-bold active">{currentOrder?.}</span>
                                    </div> */}
                                    <div className="row information">
                                        <span>Starting date</span>
                                        <span className="information-bold">
                                            {translationService.translateDate(currentOrder?.createdOn)}
                                        </span>
                                    </div>
                                    <div className="row information">
                                        <span>Ending date</span>
                                        <span className="information-bold">
                                            {translationService.translateDate(currentOrder?.createdOn, { nextYear: true })}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            />
        </div>
    )
}

export default OrderDetail;