import firstArticleImage from "assets/performance/article1.webp";
import secondArticleImage from "assets/performance/article2.webp";
import thirdArticleImage from "assets/performance/article3.webp";
import fourthArticleImage from "assets/performance/article4.webp";
import fifthArticleImage from "assets/performance/article5.webp";

const defaultArticles = [
    {
        title: 'dietSup.title',
        date: 'dietSup.date',
        link: "https://www.mdpi.com/2072-6643/14/1/70/htm",
        description: 'dietSup.desc',
        image: firstArticleImage
    },
    {
        title: 'nrf2Role.title',
        date: 'nrf2Role.date',
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8615226/",
        description: 'nrf2Role.desc',
        image: secondArticleImage
    },
    {
        title: 'harnessingCardio.title',
        date: 'harnessingCardio.date',
        link: "https://www.sciencedirect.com/science/article/pii/S2666337621000226",
        description: 'harnessingCardio.desc',
        image: thirdArticleImage
    },
    {
        title: 'chronicSulfo.title',
        date: 'chronicSulfo.date',
        link: "https://www.sciencedirect.com/science/article/abs/pii/S0899900721001283",
        description: 'chronicSulfo.desc',
        image: fourthArticleImage
    },
    {
        title: 'nrf2Overview.title',
        date: 'nrf2Overview.date',
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7700122/",
        description: 'nrf2Overview.desc',
        image: fifthArticleImage
    }
]

export default defaultArticles;