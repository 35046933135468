export enum ScheduleDayBreakType {
    BREAK = 'break',
    OTHER = 'other'
}

export interface IDatabaseScheduleDayBreak {
    id: number;
    type: ScheduleDayBreakType;
    start: number;
    startHour: number;
    startMinute: number;
    end: number;
    endHour: number;
    endMinute: number;
}

export interface IScheduleDayBreakCreate {
    type?: ScheduleDayBreakType;
    start?: number;
    startHour?: number;
    startMinute?: number;
    end?: number;
    endHour?: number;
    endMinute?: number;
}

export class ScheduleDayBreakCreate implements IScheduleDayBreakCreate {
    public type?: ScheduleDayBreakType | undefined;
    public start?: number | undefined;
    public startHour?: number | undefined;
    public startMinute?: number | undefined;
    public end?: number | undefined;
    public endHour?: number | undefined;
    public endMinute?: number | undefined;

    constructor(data?: IScheduleDayBreakCreate) {
        this.type = data?.type;
        this.start = data?.start;
        this.startHour = data?.startHour;
        this.startMinute = data?.startMinute;
        this.end = data?.end;
        this.endHour = data?.endHour;
        this.endMinute = data?.endMinute;
    }

    public setType(type: ScheduleDayBreakType) {
        this.type = type;
        return this;
    }

    private updateStart() {
        this.start = ((this.startHour ?? 0) * 60) + (this.startMinute ?? 0);
    }

    public setStartHour(startHour: number) {
        this.startHour = startHour;
        this.updateStart();
        return this;
    }

    public setStartMinute(startMinute: number) {
        this.startMinute = startMinute;
        this.updateStart();
        return this;
    }

    private updateEnd() {
        this.end = ((this.endHour ?? 0) * 60) + (this.endMinute ?? 0);
    }

    public setEndHour(endHour: number) {
        this.endHour = endHour;
        this.updateEnd();
        return this;
    }

    public setEndMinute(endMinute: number) {
        this.endMinute = endMinute;
        this.updateEnd();
        return this;
    }

    public renderStart(): string {
        return this.startHour && this.startMinute
            ? this.startHour.toString().padStart(2, '0') + ':' + this.startMinute.toString().padStart(2, '0')
            : '';
    }

    public renderEnd(): string {
        return this.endHour && this.endMinute
            ? this.endHour.toString().padStart(2, '0') + ':' + this.endMinute.toString().padStart(2, '0')
            : '';
    }
}

export default class ScheduleDayBreak implements IDatabaseScheduleDayBreak {
    id: number;
    type: ScheduleDayBreakType;
    start: number;
    startHour: number;
    startMinute: number;
    end: number;
    endHour: number;
    endMinute: number;

    constructor(data: IDatabaseScheduleDayBreak) {
        this.id = data.id;
        this.type = data.type;
        this.start = data.start;
        this.startHour = data.startHour;
        this.startMinute = data.startMinute;
        this.end = data.end;
        this.endHour = data.endHour;
        this.endMinute = data.endMinute;
    }

    public renderStart(): string {
        return this.startHour.toString().padStart(2, '0') + ':' + this.startMinute.toString().padStart(2, '0');
    }

    public renderEnd(): string {
        return this.endHour.toString().padStart(2, '0') + ':' + this.endMinute.toString().padStart(2, '0');
    }
}