import { Button, Input, Label, Loader, TextArea } from "components";
import "components/DoctorForms/FormNewPrescription/scss/FormNewPrescription.scss";
import Close from "components/Icons/Close";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import IntakeCalendar from "components/common/IntakeCalendar/IntakeCalendar";
import { IDPrescriptionMedication, IDUser, defaultPrescriptionMedication } from "interfaces/Database";
import { IDay } from "interfaces/IDay";
import { IIntakeSchedule } from "interfaces/IIntakeSchedule";
import moment from 'moment';
import 'moment-timezone';
import React, { Fragment, useEffect, useState } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import prescriptionService from "services/prescription.service";
import verifierService from "services/verifier.service";

const PrescriptionCreation: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('patientDetail');
    const { patientId, appointmentId } = useParams<({ patientId: string, appointmentId: string })>();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ medicationList, setMedicationList ] = useState<IDPrescriptionMedication[]>([]);
    const [ patient, setPatient ] = useState<IDUser>();
    const [ lastUpdate, setLastUpdate ] = useState<Date>();

    const fetchPatient = () => {
        setIsLoading(true);

        doctorService.getPatientDetails(patientId)
            .then((res) => {
                setPatient(res.result);
                setMedicationList(res?.prescription?.prescriptionMedications ?? []);
                setLastUpdate(res?.prescription?.updatedAt);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchPatient();
    }, []);

    useEffect(() => {
        const unloadCallback = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    const handleRemoveMedication = (index: number) => {
        setMedicationList(medicationList =>
            medicationList.filter((_, i) => index !== i)
        );
    }

    const handleUpdateMedication = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const newMedicationList = medicationList.map((medication, i) => {
            if (index === i) {
                const elementNameSplitted = e.target.name.split('-');

                return {
                    ...medication, [elementNameSplitted[elementNameSplitted.length-1]]:
                        e.target.type === "number" 
                            ? parseInt(e.target.value)
                            : e.target.value
                }
            }
            return medication;
        });
        setMedicationList(newMedicationList);
    }

    const getDateLastUpdate = () => {
        return lastUpdate ? moment(lastUpdate).format("DD/MM/YYYY HH:mm") : "";
    }

    const handleGeneratePrescription = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        setIsLoading(true);

        prescriptionService.createOrUpdate({
            appointmentId: appointmentId,
            medication: medicationList,
        })
            .then(() => history.push(`/account/patient/${patientId}`))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    const handleAddMedication = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setMedicationList([
            ...medicationList,
            {
                ...defaultPrescriptionMedication,
                id: medicationList.length+1
            }
        ]);
    }

    const updateMedicationSchedule = (
        index: number,
        intakeSchedule: IIntakeSchedule
    ) => {
        const newMedicationList = medicationList.map((medication, i) => {
            if (index === i) {
                return {
                    ...medication,
                    intakeSchedule
                }
            }
            return medication;
        });
        setMedicationList(newMedicationList);
    }

    const isScheduleEmpty = (intakeSchedule: IIntakeSchedule) => {
        const calculated = Object.keys(intakeSchedule).map((value: string) => {
            const val = value as IDay;

            if (
                val &&
                (intakeSchedule[val].morning ||
                intakeSchedule[val].noon ||
                intakeSchedule[val].evening)
            ) {
                return 'something_is_booked';
            } else {
                return 'aled';
            }
        });

        return calculated.filter((e) => e === 'something_is_booked').length === 0;
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div id="form-new-prescription" className="column">
            <div className="column" id="content">
                <div id="title-wrapper" className="row">
                    <ImArrowLeft2 id="icon-go-back" size={26} onClick={handleGoBack} />
                    <h1 id="title" className="MavenPro-black">
                        {t.translate('pdf.prescription.title')} {patient?.patientInfo?.firstname} {patient?.patientInfo?.lastname}
                    </h1>
                </div>
                <DefaultContainer
                    title={
                        <div className="flex-column-start">
                            <h2 className="MavenPro-bold" id="header-title">
                                {t.translate('pdf.prescription.writePrescription')}
                            </h2>

                            {lastUpdate &&
                                <span id="last-update-label">
                                    {t.translate('pdf.lastUpdate')} {getDateLastUpdate()}
                                </span>
                            }
                        </div>
                    }
                    body={
                        <Fragment>
                            <div id="form-medication-list-wrapper" className="flex-column full-width">
                                <form id="form-medication-wrapper" className="full-width" onSubmit={(e) => handleGeneratePrescription(e)}>
                                    <div className="flex-column full-width">
                                        {medicationList.map((medication, i) =>
                                            <div key={i} className="flex-column full-width" style={{ padding: '10px 0' }}>
                                                <div className="flex-row full-width space-between">
                                                    <Label
                                                        label={t.translate('pdf.prescription.medication.type') + ` n°${i + 1}`}
                                                        for={`prescription-${i}-close`}
                                                        required
                                                    />
                                                    <Close
                                                        size={30}
                                                        id="button-remove"
                                                        onClick={() => handleRemoveMedication(i)}
                                                        name={`prescription-${i}-close`} 
                                                    />
                                                </div>
                                                <div className="flex-column-start full-width">
                                                    <Label label={t.translate('pdf.prescription.name')} for={`prescription-${i}-name`} required />
                                                    <Input
                                                        name={`prescription-${i}-name`}
                                                        value={medication.name}
                                                        setValue={(e) => handleUpdateMedication(e, i)}
                                                        checkFunction={() => verifierService.checkString(medication.name)}
                                                        checkmark={{
                                                            displayed: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="prescription-intakeSchedule">
                                                    <Label
                                                        label={t.translate('pdf.prescription.intakeCalendar')}
                                                        name={`prescription-${i}-intakeschedule`}
                                                        required
                                                    />
                                                    <IntakeCalendar
                                                        labelledBy={`prescription-${i}-intakeschedule`}
                                                        index={i}
                                                        pre="med"
                                                        intakeSchedule={medication.intakeSchedule}
                                                        setSchedule={(schedule) => updateMedicationSchedule(i, schedule)}
                                                    />
                                                </div>
                                                <div className="flex-column-start full-width" style={{ margin: '10px 0' }}>
                                                    <Label
                                                        label={t.translate('pdf.prescription.quantity')}
                                                        for={`prescription-${i}-quantity`}
                                                        required
                                                    />
                                                    <Input
                                                        value={medication.quantity}
                                                        name={`prescription-${i}-quantity`}
                                                        type="number"
                                                        setValue={(e) => handleUpdateMedication(e, i)}
                                                        required
                                                    />
                                                    {medication.quantity === 0 && <p className="text-error full-width text-align-center">{t.translate('pdf.prescription.medication.noQuantity')}</p>}
                                                </div>
                                                <div className="flex-column-start full-width" style={{ margin: '10px 0' }}>
                                                    <Label
                                                        label={t.translate('pdf.prescription.additionalInformations')}
                                                        for={`prescription-${i}-additionnalInformations`}
                                                    />
                                                    <TextArea
                                                        value={medication.description}
                                                        name={`prescription-${i}-additionnalInformations`}
                                                        setValue={(e) => handleUpdateMedication(e, i)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <Button label={t.translate('pdf.prescription.addMedication')} onClick={(e) => handleAddMedication(e)} style={{ margin: '10px' }} />
                                    </div>
                                    
                                    {medicationList.length <= 0 &&
                                        <div id="no-prescription-wrapper" className="row">
                                            <p className="no-data">
                                                {t.translate('pdf.prescription.noMedication')}
                                            </p>
                                        </div>
                                    }
                                    <input type="submit" id="submit-form" style={{ display: "none" }} />
                                </form>
                            </div>
                        </Fragment>
                    }
                    footer={
                        <Button label={t.translate('pdf.save')} onClick={(e) => handleGeneratePrescription(e)} disabled={isLoading || medicationList.filter((e) => e.quantity === 0 || isScheduleEmpty(e.intakeSchedule)).length > 0} />
                    }
                />
            </div>
        </div>
    );
}

export default PrescriptionCreation;