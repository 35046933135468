import { Button, Loader } from "components";
import GoBack from "components/DoctorPatients/components/GoBack";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDatabasePreventiveAdvice } from "interfaces/Database/IDatabasePreventiveAdvice";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";

const FormPreventiveAdvice: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('patientDetail');
    const { patientId, appointmentId } = useParams<({ patientId: string, appointmentId: string })>();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ preventiveAdvice, setPreventiveAdvice ] = useState<IDatabasePreventiveAdvice>();
    const [ patient, setPatient ] = useState<IDatabaseUser>();

    const fetchPatient = async () => {
        setIsLoading(true);

        doctorService.getPatientDetails(patientId)
            .then((res) => {
                setPatient(res.result);
                setPreventiveAdvice(res.preventiveAdvice);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchPatient();
    }, [0]);


    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    const handleFormNewPreventiveAdvice = () => {
        history.push(`/account/patient/${patientId}/preventive-advice/${appointmentId}/new`);
    }

    return (
        <div id='form-prescription' className="flex-column">
            <GoBack className="MavenPro-Black" text={`${patient?.patientInfo?.firstname} ${patient?.patientInfo?.lastname}`} handleGoBack={handleGoBack} />
            <DefaultContainer
                title={<h2 className='bold'>{t.translate('pdf.preventiveAdvice.title')}</h2>}
                body={isLoading
                    ? <Loader />
                    : <div className="flex-column">
                        <div id="previsualisation">
                            <p className={!preventiveAdvice?.advice ? 'no-data' : ''}>{preventiveAdvice?.advice ?? t.translateFromFile('common', 'no-data')}</p>
                        </div>
                        <div id="buttons-prescrition" className="column space-around">
                            <Button label={t.translate('pdf.edit')} onClick={handleFormNewPreventiveAdvice} />
                        </div>
                    </div>
                }
            />
        </div>
    )
}

export default FormPreventiveAdvice;