import { Loader } from "components";
import Contact from "components/DoctorPatients/components/Contact";
import CurrentResults from "components/DoctorPatients/components/CurrentResults";
import GoBack from "components/DoctorPatients/components/GoBack";
import Information from "components/DoctorPatients/components/Information";
import InformationSpe from "components/DoctorPatients/components/InformationSpe";
import InformationsMedicals from "components/DoctorPatients/components/InformationsMedicals";
import InternalDoctorsNote from "components/DoctorPatients/components/InternalDoctorsNote";
import MedecinReferent from "components/DoctorPatients/components/MedecinReferent";
import NextAppointment from "components/DoctorPatients/components/NextAppointment";
import PatientBaseInformations from "components/DoctorPatients/components/PatientBaseInformations";
import PatientLastDocuments from "components/DoctorPatients/components/PatientLastDocuments";
import Questionnaire from "components/DoctorPatients/components/Questionnaire";
import TestResult from "components/DoctorPatients/components/TestResult";
import "components/DoctorPatients/scss/PatientDetail.scss";
import { UserContext } from "context/User/UserContext";
import { IDAnalysisKit, IDAppointment, IDGeneralComment, IDNutritionalComment, IDPrescription, IDPreventiveAdvice, IUser } from "interfaces/Database";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";

const PatientDetail: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('doctorPatients');
    const { user } = useContext(UserContext);
    const { patientId } = useParams<({ patientId?: string })>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ patient, setPatient ] = useState<IUser>();
    const [ nextAppointment, setNextAppointment ] = useState<IDAppointment>();
    const [ analysisKit, setAnalysisKit ] = useState<IDAnalysisKit>();
    const [ currentSurveyStep, setCurrentSurveyStep ] = useState<number>(0);
    const [ nutritionalComment, setNutritionalComment ] = useState<IDNutritionalComment>();
    const [ generalComment, setGeneralComment ] = useState<IDGeneralComment>();
    const [ prescription, setPrescription ] = useState<IDPrescription>();
    const [ preventiveAdvice, setPreventiveAdvice ] = useState<IDPreventiveAdvice>();

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        if (patientId) {
            Promise.all([
                doctorService.getPatientDetails(patientId)
                    .then((res) => {
                        setPatient(new IUser(res.result));
                        setAnalysisKit(res.analysisKit);
                        setNextAppointment(res.nextAppointment);
                        setCurrentSurveyStep(res.surveyStep ? res.surveyStep-1 : 0);
                        setGeneralComment(res.generalComment);
                        setPreventiveAdvice(res.preventiveAdvice);
                        setNutritionalComment(res.nutritionalComment);
                        setPrescription(res.prescription);
                    })
            ])
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false))
        } else {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleGoBack = () => {
        history.push("/account/patient/list");
    }

    const loadWarn = () => {
        if (
            !user ||
            !user.isDoctor()
        ) {
            return <div>{t.translate('pageWarn.noDoctor')}</div>
        }

        if (!user.isDoctorOf(patient)) {
            if (user.isNutritionistOf(patient)) {
                return <div>{t.translate('pageWarn.patientNutritionist')}</div>
            }

            return <div>{t.translate('pageWarn.notPatientDoctor')}</div>
        }

        return null;
    }

    if (isLoading) {
        return <Loader />
    }

    if (!patient) {
        return <p className="no-data">{t.translate('patient.notFound')}</p>
    }

    return (
        patientId
            ? <Fragment>
                <GoBack
                    text={`${patient?.patientInfo?.lastname} ${patient?.patientInfo?.firstname}`}
                    handleGoBack={handleGoBack}
                    containerStyle={{
                        margin: '3vh 0'
                    }}
                />

                {loadWarn()}

                <PatientBaseInformations patient={patient} />

                <CurrentResults analysisKitId={analysisKit?.id} />

                <NextAppointment
                    patient={patient}
                    appointment={nextAppointment}
                    generalComment={generalComment}
                    prescription={prescription}
                    preventiveAdvice={preventiveAdvice}
                    nutritionalComment={nutritionalComment}
                />

                <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                    <InformationSpe information={patient?.patientInfo?.specialInformation} />
                    <Questionnaire currentStep={currentSurveyStep} />
                </div>

                <InternalDoctorsNote patientId={patientId} />

                <PatientLastDocuments patient={patient} />

                <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                    <Information patient={patient} />
                    <Contact patient={patient} />
                </div>
                <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                    <InformationsMedicals patient={patient} />
                    <MedecinReferent doctor={patient?.doctor} />
                </div>

                <TestResult />
            </Fragment>
            : <p>You need to provide a valid patient id to display his profile</p>
    )
}

export default PatientDetail;