import NutritionalCommentResume from "components/RecommendationsDetails/components/NutritionalCommentResume";
import RecommendedFoods from "components/RecommendationsDetails/components/RecommendedFoods";
import "components/RecommendationsDetails/scss/RecommendationsDetails.scss";
import CustomIconBack from "components/common/CustomIconBack/CustomIconBack";
import ModalFoodDetail from "components/common/Modals/FoodDetails/FoodDetail";
import { IDatabaseScoreFood } from "interfaces/Database/IDatabaseScoreFood";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";

const Detailed: React.FC = () => {
    const history = useHistory();
    const [ selectedFoodModal, setSelectedFoodModal ] = useState<IDatabaseScoreFood | undefined>();

    return (
        <Fragment>
            <div className="full-width">
                <div className="row" id="go-back-wrapper">
                    <CustomIconBack handleClick={() => history.push("/account/recommendations")} />
                </div>
                <RecommendedFoods
                    handleSelectFood={setSelectedFoodModal}
                />
                <NutritionalCommentResume />
            </div>
            <ModalFoodDetail
                handleClose={() => setSelectedFoodModal(undefined)}
                food={selectedFoodModal}
            />
        </Fragment>
    );
}

export default Detailed;