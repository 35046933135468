import { Button } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { IDAppointment, IDAppointmentWithNutritionist } from 'interfaces/Database';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';

const HistoryAppointments: React.FC<({
    lastAppointments: (IDAppointment | IDAppointmentWithNutritionist)[]
})> = ({ lastAppointments }) => {
    const t = useTranslation('dashboard/doctor/history');
    const history = useHistory();

    const handleRedirectPatient = (id?: number) => {
        if (id) {
            history.push(`/account/patient/${id}`);
        }
    }

    const getLatestAppointments = () => {
        return lastAppointments.sort(function (a, b) {
            if (!a.appointmentOn && !b.appointmentOn) return 0;
            if (!a.appointmentOn) return -1;
            if (!b.appointmentOn) return 1;

            return b.appointmentOn.getTime() - a.appointmentOn.getTime();
        });
    }

    const handleClickHistory = () => {
        history.push('/account/history');
    }
    
    return (
        <DefaultContainer
            title={<h2 className='bold'>{t.translate("titleHistoryAppointments")}</h2>}
            body={lastAppointments.length > 0
                ? <div className='appointments-list' style={{ margin: '10px 0' }}>
                    {getLatestAppointments().map((appointment, index) =>
                        <div key={`appointment-${index}`}>
                            <p onClick={() => handleRedirectPatient(appointment?.subscriptionPlan?.patient?.id)}>
                                {(appointment?.subscriptionPlan?.patient?.patientInfo?.firstname + ' ' + appointment?.subscriptionPlan?.patient?.patientInfo?.lastname).toUpperCase()}
                            </p>
                            <p className='MavenPro'>
                                {appointmentService.getFormattedDate(appointment.appointmentOn)}
                            </p>
                        </div>
                    )}
                </div>
                : <p className="no-data">
                    {t.translate("noAppointment")}
                </p>
            }
            footer={  
                <Button label={t.translate('see')} onClick={handleClickHistory} />
            }
        />
    )
}

export default HistoryAppointments