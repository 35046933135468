import { Button } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from "components/common/Modal";
import { IDatabaseInternalNote } from "interfaces/Database/IDatabaseInternalNote";
import React, { useEffect, useState } from "react";
import { useInternalNoteService, useTranslation } from "services";
import "../scss/ModalDeleteInternalNote.scss";

const ModalDeleteInternalNote: React.FC<({
    note?: IDatabaseInternalNote
    showModal: boolean
    handleClose: () => void
    handleSuccess: () => void
})> = ({
    note,
    showModal,
    handleClose,
    handleSuccess
}) => {
    const t = useTranslation('patientDetail');
    const internalNoteService = useInternalNoteService();

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    const handleConfirm = () => {
        if (note && note.id) {
            setError(undefined);
            setIsLoading(true);
            
            internalNoteService.delete(note?.id)
                .then(() => {
                    handleSuccess();
                    handleClose();
                })
                .catch(() => setError(t.translate('internal-doctor-note.errorDeleteNote')))
                .finally(() => setIsLoading(false));
        } else {
            setError('internal_note_not_found');
        }
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={handleClose}
            title={t.translate('modals.delete.title')}
            footer={
                <div className="flex-row-end">
                    <Button
                        label={t.translate('modals.cancel')}
                        onClick={handleClose}
                        disabled={isLoading}
                    />
                    <Button
                        label={t.translate('modals.confirm')}
                        onClick={() => handleConfirm()}
                        disabled={isLoading}
                        displayLoader={isLoading}
                    />
                </div>
            }
        >
            <p>{t.translate('modals.delete.description')}</p>

            <ErrorMessage error={error} />
        </Modal>
    );
}

export default ModalDeleteInternalNote;