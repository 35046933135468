import { FaqQuestion } from "components/Support/components/FrequentQuestions";
import UnderstandCancerCheck from "./CancerCheck/UnderstandCancerCheck";
import WhichCancer from "./CancerCheck/WhichCancer";
import WhyRecommended from "./CancerCheck/WhyRecommended";

const cancerCheckQuestions: FaqQuestion[] = [
    {
        libelle: 'questions.cancer_check.test-offer.ask',
        answer: 'questions.cancer_check.test-offer.answer'
    },
    {
        libelle: 'questions.cancer_check.how-test-work.ask',
        answer: 'questions.cancer_check.how-test-work.answer'
    },
    {
        libelle: 'questions.cancer_check.why-revolutionnary.ask',
        answer: 'questions.cancer_check.why-revolutionnary.answer'
    },
    {
        libelle: 'questions.cancer_check.which-cancer.ask',
        customComponent: WhichCancer
    },
    {
        libelle: 'questions.cancer_check.why-recommended.ask',
        customComponent: WhyRecommended
    },
    {
        libelle: 'questions.cancer_check.understand.ask',
        customComponent: UnderstandCancerCheck
    },
    {
        libelle: 'questions.cancer_check.advantages.ask',
        answer: 'questions.cancer_check.advantages.answer',
    },
    {
        libelle: 'questions.cancer_check.sample_requirement.ask',
        answer: 'questions.cancer_check.sample_requirement.answer'
    },
    {
        libelle: 'questions.cancer_check.guarantee_success.ask',
        answer: 'questions.cancer_check.guarantee_success.answer'
    }
];

export default cancerCheckQuestions;