import { InformationWrapper, Loader, MailIcon, PhoneIcon } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useTranslation } from "services";
import "../scss/ContactInformation.scss";
import ModalEditContactInfo from "./modal/ModalEditContactInfo";

const ContactInformation: React.FC = () => {
    const t = useTranslation('profile/patient/contact');
    const { user, refreshUser } = useContext(UserContext);

    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const refreshContactInfo = async () => {
        setIsLoading(true);
        setShowModal(false);

        refreshUser()
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold">{t.translate("title")}</h1>
                    <FiEdit
                        size={24}
                        className="clickable"
                        color="var(--dashboard-main-color)"
                        onClick={() => setShowModal(true)}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    {isLoading
                        ? <Loader isSmall={true} />
                        : <div id="contact-information-wrapper">
                            <div className="flex-row information-wrapper">
                                <PhoneIcon />
                                <InformationWrapper
                                    libelle={t.translate("phone")}
                                    value={user?.phone?.number}
                                />
                            </div>
                            <div className="flex-row information-wrapper">
                                <MailIcon />
                                <InformationWrapper
                                    libelle={t.translate("email")}
                                    value={user?.email}
                                />
                            </div>
                        </div>
                    }
                    <ModalEditContactInfo
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        handleSuccess={() => refreshContactInfo()}
                        currentUser={user}
                    />
                </Fragment>
            }
        />
    );
}

export default ContactInformation;