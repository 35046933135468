import { IDCountry } from "interfaces/Database";
import React, { createContext, useState } from "react";
import { useTranslation } from "services";
import useCountryService from "services/api/countries.service";

type CountriesContextProps = {
    countries: IDCountry[];
    setCountries: (countries: IDCountry[]) => void;
    reloadCountries: () => Promise<IDCountry[]>;
}

export const PRIOR_COUNTRIES: string[] = [
    'FRANCE',
    'UNITED KINGDOM',
    'BELGIUM'
];

export const defaultCountriesList: IDCountry[] = [];

export const CountriesContext = createContext<CountriesContextProps>({
    countries: defaultCountriesList,
    setCountries: (countries: IDCountry[]) => undefined,
    reloadCountries: () => useCountryService().getAllCountries()
});

export const CountriesProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const t = useTranslation('various/country');
    const [ countries, setCountries ] = useState<IDCountry[]>(defaultCountriesList);

    const countryService = useCountryService();

    const reloadCountries = async () => {
        return countryService.getAllCountries()
            .then((res) => {
                const tmp_countries = res
                    .filter((e) => !PRIOR_COUNTRIES.includes(e.name))
                    .sort((a, b) => t.translate(a.name) > t.translate(b.name) ? 1 : -1);

                const priorCountries = res
                    .filter((e) => PRIOR_COUNTRIES.includes(e.name));

                const newOrder = [...priorCountries, ...tmp_countries];

                setCountries(newOrder);
                return newOrder;
            })
            .catch((err) => {
                console.warn(err);
                return [];
            });
    }

    return (
        <CountriesContext.Provider value={{ countries, setCountries, reloadCountries }}>
            {children}
        </CountriesContext.Provider>
    )
}