import { Button, CloseIcon, Label, Loader, Radio, Select } from 'components';
import { IDatabaseSubscriptionPlan } from 'interfaces/Database/IDatabaseSubscriptionPlan';
import { IDatabaseSubscriptionPlanTypeCategory } from 'interfaces/Database/IDatabaseSubscriptionPlanTypeCategory';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'services';
import AllocateResume from './AllocateResume';

const AllocateChooseSolution: React.FC<({
    reset: (updateEmployees?: boolean) => void
    usedSolutionNumber: number
    maxSolutionNumber: number
    solutions: IDatabaseSubscriptionPlan[]
    handleKitValidation: (solutionId?: number) => void
})> = ({
    reset, usedSolutionNumber, maxSolutionNumber, solutions, handleKitValidation
}) => {
    const t = useTranslation('dashboard/company/employees');

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ categories, setCategories ] = useState<IDatabaseSubscriptionPlanTypeCategory[]>([]);
    const [ category, setCategory ] = useState<IDatabaseSubscriptionPlanTypeCategory>();
    const [ type, setType ] = useState<string>('');

    const loadTypes = (paramCategory: IDatabaseSubscriptionPlanTypeCategory) => {
        const listTypes: string[] = [];

        const toUsesolutions = solutions.filter((kit) => kit?.type?.category?.name === (paramCategory?.name ?? category?.name));

        toUsesolutions.forEach((kit) =>
            kit?.type?.name && !listTypes.includes(kit?.type?.name)
                ? listTypes.push(kit?.type?.name)
                : null
        );

        if (listTypes.length === 1) {
            setType(listTypes[0]);
            
            if (solutions[0]?.id) {
                handleKitValidation(solutions[0]?.id);
            } else {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }

    const loadCategories = () => {
        const listCategories: IDatabaseSubscriptionPlanTypeCategory[] = [];

        solutions.forEach((kit) => {
            if (kit?.type?.category && listCategories.filter((e) => e.name === kit.type?.category?.name).length === 0) {
                listCategories.push(kit.type.category);
            }
        });

        setCategories(listCategories);
        handleChangeCategory(listCategories[0]);

        if (listCategories.length === 1) {
            loadTypes(listCategories[0]);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadCategories();
    }, [solutions]);
    
    const handleChangeCategory = (newCategory: IDatabaseSubscriptionPlanTypeCategory) => {
        setCategory(newCategory);

        const hasSome: string[] = [];

        ['annual', 'biannual', 'triannual'].forEach(kitOccurence => {
            if (solutions.filter((e) => (e.type?.category === newCategory) && e.type.name === kitOccurence).length > 0) {
                hasSome.push(kitOccurence);
            }
        });

        if (hasSome.length === 1) {
            setType(hasSome[0]);
        } else {
            setType('');
        }
    }

    return (
        <div className='modal'>
            <div className='modal-content'>
                <div className='modal-content-header'>
                    <CloseIcon className='close' onClick={() => reset()} />
                    <h2 className="MavenPro-bold">{t.translate('allocateModal.title')}</h2>
                    {isLoading || <AllocateResume
                        freeSolutions={maxSolutionNumber-usedSolutionNumber}
                        givenSolutions={usedSolutionNumber}
                        desc={'kits attribués'}
                        containerStyle={{
                            width: 50,
                            height: 50
                        }}
                        btnShow={false}
                    />}
                </div>
                {isLoading
                    ? <Loader />
                    : <div className='modal-content-body'>
                        <h3>{t.translate('allocateModal.chooseKit.category.title')} :</h3>
                        <div className='flex-column'>
                            {categories.length > 1
                                ? <Select
                                    name='solution-category'
                                    value={category}
                                    setValue={(e) => handleChangeCategory(categories.filter((cat) => cat.name === e.currentTarget.value)[0])}
                                    options={
                                        <Fragment>
                                            <option className='dashboardSelect-option MavenPro' value='' disabled>
                                                {t.translate('allocateModal.chooseKit.category.pleaseChoose')}
                                            </option>
                                            <option className='dashboardSelect-option MavenPro' selected={category?.name === 'performance'} value='performance'>
                                                {t.translate('allocateModal.chooseKit.category.performance')} ({solutions.filter((e) => e && e.type && e.type.category && (e.type.category.name === 'performance')).length} {t.translate('allocateModal.chooseKit.category.solutions')})
                                            </option>
                                            <option className='dashboardSelect-option MavenPro' selected={category?.name === 'corporate'} value='corporate'>
                                                {t.translate('allocateModal.chooseKit.category.corporate')} ({solutions.filter((e) => e && e.type && e.type.category && (e.type.category.name === 'corporate')).length} {t.translate('allocateModal.chooseKit.category.solutions')})
                                            </option>
                                        </Fragment>
                                    }
                                />
                                : <p>
                                    {t.translate('allocateModal.chooseKit.category.'+category)} ({solutions.filter((e) => e && e.type && e.type.category && (e.type.category.name === category?.name)).length} {t.translate('allocateModal.chooseKit.category.solutions')})
                                </p>
                            }
                        </div>

                        {(category) &&
                            <Fragment>
                                <h3>{t.translate('allocateModal.chooseKit.type.title')} :</h3>
                                <div className='flex-column'>
                                    <div>
                                        {[ 'annual', 'biannual', 'triannual' ].map((kitOccurence, index) => 
                                            solutions.filter((e) => ((e?.type?.category?.name === category.name) ? category : '') && e?.type?.name === kitOccurence).length > 0
                                                ? <div key={`freq-${index}`}>
                                                    <Radio
                                                        name='sendType'
                                                        value={kitOccurence}
                                                        isChecked={type === kitOccurence}
                                                        setChecked={setType}
                                                        label={
                                                            <Label
                                                                for='sendType'
                                                                label={`${t.translate(`allocateModal.chooseKit.type.${kitOccurence}`)} (${solutions.filter((e) => ((e?.type?.category?.name === category.name) ? category : '') && e?.type?.name === kitOccurence).length} ${t.translate('allocateModal.chooseKit.category.solutions')})`}
                                                            />
                                                        }
                                                    />
                                                </div>
                                                : null
                                        )}
                                    </div>
                                </div>
                            </Fragment>
                        }

                        {(category && type) &&
                            <div className='flex-column'>
                                <Button
                                    label={t.translate('allocateModal.chooseKit.btn')}
                                    onClick={() => handleKitValidation(solutions.filter((e) => ((e.type?.category?.name === category.name) ? category : '') && e?.type?.name === type)[0]?.id)}
                                    disabled={(!category || !type)}
                                />
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default AllocateChooseSolution;