import { Button, Input, Label, Select } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from 'components/common/Modal';
import { IDUser } from "interfaces/Database";
import moment from 'moment';
import 'moment-timezone';
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import translationService from "services/translation.service";
import verifierService from 'services/verifier.service';

const ModalEditProfileInfo: React.FC<({
    showModal: boolean
    handleClose: () => void
    handleSuccess: () => void
    user?: IDUser
})> = ({ showModal, handleClose, handleSuccess, user }) => {
    const t = useTranslation('profile/doctor/myGeneralInformation');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const [ lastname, setLastname ] = useState<string>();
    const [ firstname, setFirstname ] = useState<string>();
    const [ birthdate, setBirthdate ] = useState<Date>();
    const [ clinic, setClinic ] = useState<string>();
    const [ gender, setGender ] = useState<string>();

    const inputContainer = {
        width: '48%'
    };

    const rowInputContainer = {
        marginBottom: '2vh'
    };

    useEffect(() => {
        setFirstname(user?.doctorInfo?.firstname);
        setLastname(user?.doctorInfo?.lastname);
        setBirthdate(user?.doctorInfo?.birthDate);
        setClinic(user?.doctorInfo?.clinic);
        setGender(!user?.doctorInfo?.gender ? 'Male' : user.doctorInfo.gender.name === 'Male' ? 'Male' : 'Female');
    }, [user]);

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    const handleEditUser = async () => {
        setIsLoading(true);

        doctorService.updateInformations({
            firstName: firstname,
            lastName: lastname,
            gender: gender,
            birthDate: birthdate,
            clinic: clinic
        })
            .then(() => {
                handleSuccess();
                handleClose();
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={handleClose}
            title={t.translate("titleModal")}
            footer={
                !isLoading && <div className="flex-row flex-end">
                    <Button label={t.translate('cancel')} onClick={() => handleClose()} />
                    <Button label={t.translate('save')} onClick={() => handleEditUser()} />
                </div>
            }
        >
            <Fragment>
                <div className="row space-between full-width" style={rowInputContainer}>
                    <div className="column" style={inputContainer}>
                        <Label for="lastname" label={t.translate('lastname')} required />
                        <Input
                            required
                            name="lastname"
                            disabled={isLoading}
                            value={lastname}
                            setValue={(e) => setLastname(e.currentTarget.value)}
                            checkFunction={() => verifierService.checkString(lastname)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                    <div className="column" style={inputContainer}>
                        <Label for="firstname" label={t.translate('firstname')} required />
                        <Input
                            required
                            name="firstname"
                            disabled={isLoading}
                            value={firstname}
                            setValue={(e) => setFirstname(e.currentTarget.value)}
                            checkFunction={() => verifierService.checkString(firstname)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <div className="row space-between full-width" style={rowInputContainer}>
                    <div className="column" style={inputContainer}>
                        <Label for="gender" label={t.translate('genre')} required />
                        <Select
                            required
                            name="gender"
                            disabled={isLoading}
                            value={gender}
                            setValue={(e) => setGender(e.currentTarget.value)}
                            checkFunction={() => verifierService.checkString(gender)}
                            checkmark={{
                                displayed: true
                            }}
                            options={
                                <Fragment>
                                    <option value='' style={{ fontStyle: 'italic' }}></option>
                                    <option value="Male">{translationService.translateGender('Male')}</option>
                                    <option value="Female">{translationService.translateGender('Female')}</option>
                                </Fragment>
                            }
                        />
                    </div>
                    <div className="column" style={inputContainer}>
                        <Label for="birthdate" label={t.translate('birthdate')} required />
                        <Input
                            required
                            name="birthdate"
                            type="date"
                            disabled={isLoading}
                            value={moment(birthdate).format('YYYY-MM-DD')}
                            setValue={(e) => setBirthdate(e.currentTarget.valueAsDate ?? undefined)}
                            checkFunction={() => verifierService.checkDate(birthdate)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <div className="row space-between full-width" style={rowInputContainer}>
                    <div className="column" style={inputContainer}>
                        <Label for="clinic" label={t.translate('clinical')} required />
                        <Input
                            required
                            name="clinic"
                            disabled={isLoading}
                            value={clinic}
                            setValue={(e) => setClinic(e.currentTarget.value)}
                            checkFunction={() => verifierService.checkString(clinic)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <ErrorMessage error={error} />
            </Fragment>
        </Modal>
    );
}

export default ModalEditProfileInfo;