import { IDatabaseCountry } from "interfaces/Database/IDatabaseCountry";
import APIService from "./APIService";

class CountryService {
    private ApiService = new APIService('/country');

    public async getAllCountries() {
        return await this.ApiService.call<IDatabaseCountry[]>('', 'get', null, false);
    }
}

function useCountryService(): CountryService {
    return new CountryService();
}

export default useCountryService;