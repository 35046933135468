import { Button, Loader } from 'components';
import CustomCircleProgressbar from 'components/common/CustomCircleProgressbar/CustomCircleProgressbar';
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from 'components/common/Modal';
import useIsComponentMounted from "hooks/useIsComponentMounted";
import { IDAnalysisKit } from "interfaces/Database";
import { IResult } from "interfaces/IResult";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import analysisKitService from 'services/analysisKit.service';
import fileService from 'services/file.service';
import resultsService from "services/results.service";
import "../scss/Results.scss";

const ResultsAnalysis: React.FC<({ analysisKit?: IDAnalysisKit })> = ({
    analysisKit
}) => {
    const t = useTranslation('dashboard/patient/testResults');
    const isMounted = useIsComponentMounted();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ results, setResults ] = useState<IResult[]>([]);

    const [ showDetails, setShowDetails ] = useState<string>();
    const toggleShowDetails = (newDetails?: string) => {
        if (newDetails === showDetails) return setShowDetails(undefined);

        setShowDetails(newDetails);
    }

    useEffect(() => {
        if (isMounted.current) {
            if (analysisKit?.id) {
                resultsService.getFormattedResults(analysisKit.id)
                    .then((res) => setResults(res))
                    .catch((err) => setError(err.message))
                    .finally(() => setIsLoading(false));
            } else {
                setError(t.translate('not-found'));
                setIsLoading(false);
            }
        }
    }, [analysisKit]);

    const handleDownloadResults = () => {
        setIsDownloading(true);

        if (analysisKit?.id) {
            analysisKitService.getAnalysisKitResultsPdf(analysisKit?.id)
                .then((res) => fileService.downloadFile(res, { addPre: true, filename: `REPORT analysis kit ${analysisKit.verificationCode}` }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(false));
        } else {
            setError('no-data');
            setIsDownloading(false);
        }
    }

    const renderResults = () => {
        if (isLoading) {
            return <div className="flex-column">
                <Loader />
            </div>
        } else if (!results.length) {
            return (
                <div className="flex-column">
                    <p>{t.translate('not-available')}</p>
                </div>
            )
        } else {
            return results.map((category, index) => 
                <div className="row result-wrapper clickable" key={index} onClick={() => toggleShowDetails(category.name)}>
                    <div className="result-circular-wrapper column">
                        {category.max !== 0
                            ? <CustomCircleProgressbar
                                max={category.max}
                                value={category.score}
                                pathColor={category.color}
                            />
                            : <CustomCircleProgressbar text={t.translate('results.noData')} />
                        }
                    </div>
                    <div className="column result-info-wrapper">
                        <p className="result-info-label MavenPro-black">
                            {t.translate('category.'+category.name+'.label')}
                        </p>
                        <span className="result-info-desc">
                            {t.translate('category.'+category.name+'.desc')}
                        </span>
                    </div>
                </div>
            )
        }
    };

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <div className="flex-column-start">
                        <h1 className='container-title bold'>{t.translate('results.mainTitle')}</h1>
                        {(analysisKit && analysisKit.verificationCode)
                            && <h3 className='sub-title container-title bold'>{t.translateReplaceValues('results.title', [
                                {
                                    tag: '{{analysisKitId}}',
                                    value: analysisKit?.verificationCode
                                }
                            ])}</h3>
                        }
                        {analysisKit?.microbiotaResultsReceivedOn && <span id="sub-title"> {t.translateDate(new Date(analysisKit?.microbiotaResultsReceivedOn))}</span>}
                    </div>
                    {(analysisKit && results.length > 0) &&
                        <Button label={t.translate('results.downloadReport')} onClick={handleDownloadResults} disabled={isDownloading} />
                    }
                </Fragment>
            }
            body={analysisKit
                ? <div className="flex-column full-width">
                    <div className="row" id="results-wrapper">
                        {renderResults()}
                    </div>
                    <ErrorMessage error={error} />
                    <div className="flex-row result-caption-wrapper">
                        <div className="flex-row result-caption">
                            <div className="result-caption-progressbar">
                                <CustomCircleProgressbar max={3} value={3} pathColor={"var(--green)"} />
                            </div>
                            <div className="flex-column-start result-caption-detail">
                                <p className="MavenPro-bold text-uppercase">{t.translate('caption.good.label')}</p>
                                <p>{t.translate('caption.good.desc')}</p>
                            </div>
                        </div>
                        <div className="flex-row result-caption">
                            <div className="result-caption-progressbar">
                                <CustomCircleProgressbar max={3} value={2} pathColor={"var(--orange)"} />
                            </div>
                            <div className="flex-column-start result-caption-detail">
                                <p className="MavenPro-bold text-uppercase">{t.translate('caption.medium.label')}</p>
                                <p>{t.translate('caption.medium.desc')}</p>
                            </div>
                        </div>
                        <div className="flex-row result-caption">
                            <div className="result-caption-progressbar">
                                <CustomCircleProgressbar max={3} value={1} pathColor={"var(--red)"} />
                            </div>
                            <div className="flex-column-start result-caption-detail">
                                <p className="MavenPro-bold text-uppercase">{t.translate('caption.bad.label')}</p>
                                <p>{t.translate('caption.bad.desc')}</p>
                            </div>
                        </div>
                    </div>
                    <Modal
                        isShow={!!showDetails}
                        toggleShow={toggleShowDetails}
                        title={t.translate('category.' + showDetails + '.label')}
                        footer={
                            <div className='flex-row'>
                                <Button label={t.translate('detailModal.close')} onClick={() => toggleShowDetails(undefined)}/>
                            </div>
                        }
                    >
                        <p>{t.translate('category.' + showDetails + '.desc')}</p>
                    </Modal>
                </div>
                : <p className="no-data">
                    {t.translate('kitNotFound')}
                </p>
            }
        />
    );
}

export default ResultsAnalysis;