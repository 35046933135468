import in_case_positive from 'assets/order/cancer_detection/in_case_positive.png';
import { useTranslation } from "services";

export interface InCasePositiveProps {
    key: number | string
}

const InCasePositive: React.FC<InCasePositiveProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');

    return (
        <div key={props.key} className="orderSolution-section">
            <div className='flex-column'>
                <div className="orderSolution-section-title">
                    <p>3.</p>
                    <div className="flex-column-start">
                        <h2>{t.translate('cancer_detection.in_case_positive.title')}</h2>
                    </div>
                </div>

                <p>{t.translate('cancer_detection.in_case_positive.body')}</p>
                <p><u>{t.translate('cancer_detection.in_case_positive.pre_list')}</u></p>

                <ul>
                    <li>{t.translate('cancer_detection.in_case_positive.list.supportMedicalFile')}</li>
                    <li>{t.translate('cancer_detection.in_case_positive.list.callOrVideo')}</li>
                    <li>{t.translate('cancer_detection.in_case_positive.list.personnalizedParkour')}</li>
                    <li>{t.translate('cancer_detection.in_case_positive.list.secondaryTest')}</li>
                </ul>
            </div>
            <div className='orderSolution-img-wrapper'>
                <img src={in_case_positive} />
            </div>
        </div>
    )
}

export default InCasePositive;