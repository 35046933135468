import { RightArrowIcon } from "components";
import { ITableColumn } from "interfaces/ITableColumn";
import { IUserWithOnGoing } from "interfaces/IUserWithOnGoing";
import React, { Fragment, useState } from "react";
import { useTranslation } from "services";
import CompanyShowEmployeeModal from "../modal/CompanyShowEmployeeModal";
import "../scss/EmployeeTable.scss";

const EmployeeTable: React.FC<({
    columns: ITableColumn[]
    employees: IUserWithOnGoing[]
    isFreeKits: boolean,
    toggleAllocate: () => void
})> = ({ columns, employees, isFreeKits, toggleAllocate }) => {
    const t = useTranslation('dashboard/company/employees');
    const [ isShowEmployee, setIsShowEmployee ] = useState<boolean>(false);
    const [ employee, setEmployee ] = useState<IUserWithOnGoing>();

    const toggleShow = (newEmployee?: IUserWithOnGoing) => {
        setIsShowEmployee(!isShowEmployee);
        setEmployee(newEmployee);
    };

    const row = (index: string, employee: IUserWithOnGoing) => {
        /**
         * employee
         */
        return (
            <tr key={`monthly-${index}`} className="full-width data-row employeeTable-body-row">
                {employee.onGoing
                    ? <Fragment>
                        <td className={`MavenPro text-align-center ${!employee.onGoing.id ? 'no-data' : ''}`}>{employee.onGoing.id ? `#${employee.onGoing.id}` : t.translateFromFile('common', 'no-data')}</td>
                        <td className={`MavenPro text-align-center ${!employee.onGoing.status?.name ? 'no-data' : ''}`}>
                            {employee?.onGoing?.status?.name
                                ? t.translateFromFile('dashboard/common/SubscriptionPlanStatus', employee.onGoing.status.name)
                                : t.translateFromFile('common', 'no-data')
                            }
                        </td>
                    </Fragment>
                    : <td className="MavenPro text-align-center no-data" colSpan={2}><i>{t.translate('table.noPlan')}</i></td>
                }
                <td className="MavenPro-bold text-uppercase hide-text-overflow">{employee?.patientInfo?.firstname} {employee?.patientInfo?.lastname}</td>
                <td className="MavenPro hide-text-overflow">{employee.email}</td>
                <td className={`MavenPro text-align-center ${!employee.phone?.number ? 'no-data' : ''}`}>{employee?.phone?.number ? employee.phone.number : t.translateFromFile('common', 'no-data')}</td>
                <td className="employeeButton-wrapper">
                    <RightArrowIcon onClick={() => toggleShow(employee)} />
                </td>
            </tr>
        )
    }

    const renderRows = () => {
        return Object.keys(employees).map((key) => row(key, employees[parseInt(key)]));
    }

    return (
        <Fragment>
            <div className='table flex-column full-width' style={{ marginTop: "1vh" }}>
                <table id="data-table" className='full-width employeeTable'>
                    <thead className="employeeTable-header">
                        <tr>
                            {columns.map((col, index) => <th colSpan={col.colSpan} className="data-titre titre-colonne text-align-center" key={`table-header-${index}`}>{col.name}</th>)}
                        </tr>
                    </thead>
                    <tbody className="employeeTable-body">
                        {renderRows()}
                    </tbody>
                </table>
            </div>
            <CompanyShowEmployeeModal isShow={isShowEmployee} toggleShow={toggleShow} canAllocateKit={isFreeKits} toggleAllocate={toggleAllocate} employee={employee} />
        </Fragment>
    )
}

export default EmployeeTable;