import { UserContext } from "context/User/UserContext";
import React, { useContext, useState } from "react";
import translationService from "services/translation.service";

const TSS = 'layout/sidebar.';

const PrivateSidebarId: React.FC = () => {
    const { user } = useContext(UserContext);
    const [ showCopied, setShowCopied ] = useState<boolean>(false);

    const handleClickId = () => {
        navigator.clipboard.writeText(`${user?.id}`);

        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 1700);
    }

    return (user
        ? <div id='sidebar-id' onClick={() => handleClickId()}>
            {showCopied
                ? <p className="sidebar-id-copied">{translationService.translate(TSS + 'id.copied')}</p>
                : <React.Fragment>
                    <p className="sidebar-id-placeholder">
                        {translationService.translate(TSS + 'id.id')}
                        <span>
                            ({translationService.translate(TSS + 'id.hover')})
                        </span>
                    </p>
                    <p className="sidebar-id-element">
                        {user?.id}
                    </p>
                </React.Fragment>
            }
        </div>
        : null
    )
}

export default PrivateSidebarId;