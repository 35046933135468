import { Loader } from "components";
import DoctorForm from "components/DoctorForms/common/DoctorForm";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import generalCommentService from "services/generalComment.service";

const GeneralCommentCreation: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('patientDetail');

    const { patientId, appointmentId } = useParams<({ patientId: string, appointmentId: string })>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ patient, setPatient ] = useState<IDatabaseUser>();
    const [ generalComment, setGeneralComment ] = useState<string>();
    const [ generalCommentUpdatedAt, setGeneralCommentUpdatedAt ] = useState<Date>();

    const fetchData = async () => {
        if (patientId) {
            doctorService.getPatientDetails(patientId)
                .then(async (res) => {
                    setPatient(res.result);
                    setGeneralComment(res.generalComment?.comment);
                    setGeneralCommentUpdatedAt(res.generalComment?.updatedAt);
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        if (parseInt(appointmentId) && generalComment) {
            generalCommentService.createOrUpdate({
                appointmentId: parseInt(appointmentId),
                comment: generalComment
            })
                .then(() => history.push(`/account/patient/${patientId}`))
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <DoctorForm
                title={t.translate('pdf.generalComment.title')}
                onSubmit={handleSubmit}
                setFormValue={setGeneralComment}
                patientFullName={patient?.patientInfo?.firstname + ' ' + patient?.patientInfo?.lastname}
                value={generalComment}
                dateLastUpdate={generalCommentUpdatedAt}
                handleGoBack={handleGoBack}
            />
        );
    }
}

export default GeneralCommentCreation;