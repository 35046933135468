import ImageNotFound from "assets/undraw_No_data.svg";
import { LeftArrowIcon } from "components/Icons";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import translationService from "services/translation.service";
import "../scss/OrderNotFound.scss";

const OrderNotFound: React.FC = () => {
    const history = useHistory();

    const handleGoBack = () => {
        history.push("/account/order");
    }

    useEffect(() => {
        toast.error(translationService.translate("toast.orderNotFound.error"));
    }, []);

    return (
        <div className="flex column" id="order-not-found">
            <div className="row shadow" id="order-not-found-content">
                <LeftArrowIcon
                    id="icon-back"
                    onClick={handleGoBack}
                />
                <span id="error-message">
                    Error during order retrieval. Please, check your order number and contact us if the problem persists.
                </span>
            </div>
            <div className="column" id="image-not-found-container">
                <img src={ImageNotFound} alt="order not found" />
            </div>
        </div>

    );
}

export default OrderNotFound;