import { FaqQuestion } from "components/Support/components/FrequentQuestions";

const patientQuestions: FaqQuestion[] = [
    {
        libelle: 'questions.patient.1.ask',
        answer: 'questions.patient.1.answer'
    },
    {
        libelle: 'questions.patient.2.ask',
        answer: 'questions.patient.2.answer'
    },
    {
        libelle: 'questions.patient.3.ask',
        answer: 'questions.patient.3.answer'
    },
    {
        libelle: 'questions.patient.4.ask',
        answer: 'questions.patient.4.answer'
    }
];

export default patientQuestions;