import { IIcon, defaultIconProps } from "interfaces/IIcon";
import React from "react";
import { AiOutlineMinus } from "react-icons/ai";

const Remove: React.FC<IIcon> = (props) => {
    return (
        <AiOutlineMinus
            id={props.id}
            className={props.className}
            size={props.size}
            color={props.color}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            style={{
                minWidth: props.size,
                minHeight: props.size,
                ...props.style
            }}
        />
    )
}

Remove.defaultProps = defaultIconProps;

export default Remove;