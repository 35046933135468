import img1 from 'assets/Help/profile.svg';
import { Button } from 'components';
import React from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'services';

const DSSixthPage: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2 style={{ textAlign: 'center' }}>{t.translate('doctor.resume.title')}</h2>

            <div className="help-sum-up">
                <div>
                    <img src={img1} alt="Complete profile" />
                    <div className="flex-column">
                        <p>{t.translate('doctor.resume.profile.title')}</p>
                        <Button label={t.translate('doctor.resume.profile.button')} onClick={() => history.push('/account/profile')} />
                    </div>
                </div>
                <div>
                    <img src={img1} alt="Calendar" />
                    <div className="flex-column">
                        <p>{t.translate('doctor.resume.appointments.title')}</p>
                        <Button label={t.translate('doctor.resume.appointments.button')} onClick={() => history.push('/account/patient/list')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DSSixthPage;