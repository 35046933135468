import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { IDAppointment, IDAppointmentWithNutritionist } from 'interfaces/Database';
import 'moment-timezone';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import appointmentService from 'services/appointment.service';
import '../scss/HistoryAppointments.scss';

const HistoryAppointments: React.FC<({
    lastAppointments: (IDAppointment | IDAppointmentWithNutritionist)[]
})> = ({ lastAppointments }) => {
    const t = useTranslation('history');
    const history = useHistory();

    const handleClickPatient = (id?: number) => {
        if (id) {
            history.push(`/account/patient/${id}`);
        }
    }

    return (
        <DefaultContainer
            wrapperStyle={{
                flexGrow: 1,
                margin: '0 1rem 0 0'
            }}
            title={<h2 className="bold">{t.translate("appointments")}</h2>}
            body={
                !lastAppointments || lastAppointments.length === 0
                    ? <p className="no-data">{t.translate("noAppointment")}</p>
                    : <div className='historyAppointment-wrapper'>
                        {lastAppointments.map((appointment, index) =>
                            <div key={`appointment-${index}`} className='flex-row space-between'>
                                <p onClick={() => handleClickPatient(appointment?.subscriptionPlan?.patient?.id)}>
                                    {(appointment?.subscriptionPlan?.patient?.patientInfo?.firstname + ' ' + appointment?.subscriptionPlan?.patient?.patientInfo?.lastname).toUpperCase()}
                                </p>
                                <p className='MavenPro'>
                                    {appointmentService.getFormattedDate(appointment.appointmentOn)}
                                </p>
                            </div>
                        )}
                    </div>
            }
        />
    )
}

export default HistoryAppointments