import { IDPlanType } from "interfaces/Database";
import apiService from "./api.service";

class subscriptionPlanTypeService {
    public async getAvailableTypes() {
        return await apiService.makeApiCall(`/api/subscription/types/available`, 'get')
            .then((res) => res as IDPlanType[]);
    }

    public getCustomPrices = async () => {
        return await apiService.makeApiCall(`/api/getCustomPrices`, 'get');
    }

    public async getPlanTypeByName(name: 'microbiota_analysis' | 'cancer_detection' | string) {
        return apiService.makeApiCall<IDPlanType>(`/api/subscription/types/byName/${name}`, 'get');
    }

    public async getMicrobiotaAnalysisType() {
        return await this.getPlanTypeByName('microbiota_analysis');
    }
    public async getCancerDetectionType() {
        return await this.getPlanTypeByName('cancer_detection');
    }

    public async getPlanTypeCustomPriceByName(name: 'microbiota_analysis' | 'cancer_detection' | string) {
        return await apiService.makeApiCall(`/api/prices/subscriptionPlanType/byName/${name}`, 'get');
    }

    public async getMicrobiotaAnalysisTypeCustomPrice() {
        return await this.getPlanTypeCustomPriceByName('microbiota_analysis');
    }
    public async getCancerDetectionTypeCustomPrice() {
        return await this.getPlanTypeCustomPriceByName('cancer_detection');
    }
}

export default new subscriptionPlanTypeService();