import { IDatabasePlatform } from "interfaces/Database/IDatabasePlatform";
import APIService from "./APIService";

class PlatformService {
    private platformService = new APIService('/platform');

    public async getWebPlatform() {
        return await this.platformService.call<IDatabasePlatform>('/web', 'get');
    }
}

function usePlatformService(): PlatformService {
    return new PlatformService();
}

export default usePlatformService;