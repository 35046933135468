export interface IDatabaseComplementCategory {
    id: number
    name: string
}

export class IComplementCategory implements IDatabaseComplementCategory {
    id: number;
    name: string;

    constructor(props: IDatabaseComplementCategory) {
        this.id = props.id;
        this.name = props.name;
    }
}