import { Button } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDAnalysisKit } from "interfaces/Database";
import { IDatabasePrescription } from "interfaces/Database/IDatabasePrescription";
import 'moment-timezone';
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import fileService from "services/file.service";
import prescriptionService from "services/prescription.service";
import "../scss/CurrentPrescription.scss";

const CurrentPrescription: React.FC<({
    prescription?: IDatabasePrescription
})> = ({ prescription }) => {
    const t = useTranslation('dashboard/patient/home');
    const [ isDownloading, setIsDownloading ] = useState<'prescription' | 'dietarySupplement'>();
    const [ prescriptionAnalysisKit, setPrescriptionAnalysisKit ] = useState<IDAnalysisKit>();

    useEffect(() => {
        const analysisKits = prescription?.doctorAppointment?.subscriptionPlan.analysisKits ?? [];
        setPrescriptionAnalysisKit(analysisKits[analysisKits.length-1]);
    }, [ prescription ]);
    
    const handleDownloadPrescription = () => {
        if (prescription) {
            setIsDownloading('prescription');

            prescriptionService.getPrescriptionPDF(prescription?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${prescription?.patient?.patientInfo?.firstname}_${prescription?.patient?.patientInfo?.lastname}-prescription_${prescription.id}` }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(undefined));
        }
    }

    const handleDownloadDietarySupplement = () => {
        if (prescription) {
            setIsDownloading('dietarySupplement');

            prescriptionService.getDietarySupplementPDF(prescription?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${prescription?.patient?.patientInfo?.firstname}_${prescription?.patient?.patientInfo?.lastname}-dietary_supplement_${prescription.id}` }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(undefined));
        }
    }

    return (
        <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
            <DefaultContainer
                title={
                    <div className="flex-column-start">
                        <h1 className="container-title bold">
                            {t.translate('prescription.title')}
                        </h1>
                        {prescriptionAnalysisKit
                            && <i>
                                {t.translateReplaceValues('prescription.analysisKit', [
                                    {
                                        tag: '{{analysisKitId}}',
                                        value: prescriptionAnalysisKit?.verificationCode
                                    }
                                ])}
                            </i>
                        }
                    </div>
                }
                body={prescription
                    ? <div className="flex-row">
                        <div className="flex-column" style={{ margin: '0 4vw' }}>
                            <p className="MavenPro-black">{t.translate('prescription.prescription')}</p>
                            <i>
                                {t.translate('prescription.written-by') + ' '}
                                {doctorService.formatDoctorName(prescription?.doctor?.doctorInfo)}
                            </i>
                            <span>
                                {t.translateReplaceValues('prescription.didAt', [
                                    {
                                        tag: '{{date}}',
                                        value: t.translateDate(prescription.updatedAt)
                                    }
                                ])}
                            </span>
                        </div>
                    </div>
                    : <p className="no-data">
                        {t.translate('prescription.no-prescription')}
                    </p>
                }
                footer={prescription && (!prescription.prescriptionMedications || prescription.prescriptionMedications?.length === 0)
                    ? <i style={{ margin: '1vh 0' }} >{t.translate('prescription.noPrescription')}</i>
                    : <Button
                        label={t.translate('prescription.button-label')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'prescription'}
                        onClick={handleDownloadPrescription}
                        style={{
                            margin: '1vh 0'
                        }}
                    />
                }
            />
            <DefaultContainer
                title={
                    <div className="flex-column-start">
                        <h1 className="container-title bold">
                            {t.translate('dietarySupplement.title')}
                        </h1>
                        {prescriptionAnalysisKit
                            && <i>
                                {t.translateReplaceValues('dietarySupplement.analysisKit', [
                                    {
                                        tag: '{{analysisKitId}}',
                                        value: prescriptionAnalysisKit?.verificationCode
                                    }
                                ])}
                            </i>
                        }
                    </div>
                }
                body={prescription
                    ? <div className="flex-row">
                        <div className="flex-column" style={{ margin: '0 4vw' }}>
                            <p className="MavenPro-black">{t.translate('dietarySupplement.dietaryComplement')}</p>
                            <i>
                                {t.translate('dietarySupplement.written-by') + ' '}
                                {doctorService.formatDoctorName(prescription?.doctor?.doctorInfo)}
                            </i>
                            <span>
                                {t.translateReplaceValues('dietarySupplement.didAt', [
                                    {
                                        tag: '{{date}}',
                                        value: t.translateDate(prescription.updatedAt)
                                    }
                                ])}
                            </span>
                        </div>
                    </div>
                    : <p className="no-data">
                        {t.translate('dietarySupplement.no-prescription')}
                    </p>
                }
                footer={prescription && (!prescription.dietarySupplements || prescription.dietarySupplements?.length === 0)
                    ? <i style={{ margin: '1vh 0' }} >{t.translate('dietarySupplement.noComplement')}</i>
                    : <Button
                        label={t.translate('dietarySupplement.button-label')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading === 'dietarySupplement'}
                        onClick={handleDownloadDietarySupplement}
                        style={{
                            margin: '1vh 0'
                        }}
                    />
                }
            />
        </div>
    );
}

export default CurrentPrescription;