import { RightArrowIcon } from 'components';
import { UserContext } from 'context/User/UserContext';
import User from 'interfaces/Database/IDatabaseUser';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import '../scss/Table.scss';

const Table: React.FC<({
    columns: string[]
    patients: User[]
})> = ({ columns, patients }) => {
    const history = useHistory();
    const { user } = useContext(UserContext);
    const t = useTranslation('common');

    const handleRedirectPatient = (id: number) => {
        history.push(`${id}`);
    }

    const row = (index: number, patient: User) => {
        const id = patient.id;
        const name = patient.formatPatientName({ upperCase: 'lastname' });

        return (
            <div key={index} className="flex-row full-width data-row clickable" onClick={() => handleRedirectPatient(id)}>
                <p className='data MavenPro-bold' style={{ width: '20%' }}>
                    {!user?.isPractitionerOf(patient)
                        ? <i>{t.translate('doctor.notLinked')}</i>
                        : user.isDoctorOf(patient)
                            ? <p style={{ margin: 0 }}>{t.translate('doctor.doctor')}</p>
                            : <p style={{ margin: 0 }}>{t.translate('doctor.nutritionist')}</p>
                    }
                </p>
                <p className='data MavenPro-bold' style={{ width: '20%' }}>{name}</p>
                <p className={`data MavenPro ${!patient.email ? 'no-data' : ''}`} style={{ width: '20%' }}>{patient.email || t.translate('no-data')}</p>
                <p className={`data MavenPro ${!patient.phone?.number ? 'no-data' : ''}`} style={{ width: '20%' }}>{patient?.phone?.number || t.translate('no-data')}</p>
                <div className='flex-column' style={{ width: '20%' }}>
                    <RightArrowIcon />
                </div>
            </div>
        )
    }

    return (
        <div className='table flex-column full-width' style={{ boxSizing: 'border-box' }}>
            <div id="data-titre" className='row full-width space-between'>
                {columns.map((col, index) => <p key={index} className='data-titre titre-colonne text-align-center'>{col}</p>)}
            </div>
            <div className='table-body'>
                {patients.map((patient, index) => row(index, patient))}
            </div>
        </div>
    )
}

export default Table;