import { Button, CheckCircleIcon, Input, Label } from 'components';
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Modal from "../Modal/Modal";

const SEND_BACK_CODE_TIME = 59000;

const VerifyPhoneModal: React.FC<({
    showModal: boolean;
    toggleShowModal: () => void;
    toggleDataModal: () => void;
})> = ({
    showModal,
    toggleShowModal,
    toggleDataModal
}) => {
    const t = useTranslation('firstConnection');
    const [ isLoading, setIsLoading ] = useState<'first' | 'submit' | undefined>('first');
    const [ error, setError ] = useState<string>();

    const { user } = useContext(UserContext);

    const [ isConfirmationDone, setIsConfirmationDone ] = useState<boolean>(false);
    const [ smsCode, setSmsCode ] = useState<string>();
    const [ countDownKey, setCountDownKey ] = useState<number>(1);
    const [ countDownTime, setCountDownTime ] = useState<number>();

    const handleReceiveSmsCode = useCallback(async () => {
        setIsLoading('first');
        setCountDownKey(countDownKey + 1);
        setSmsCode("");

        if (!user?.phoneIsVerified) {
            await userAuthService.getSmsCode()
                .catch((err) => setError(err.message))
                .finally(() => setCountDownTime(Date.now() + SEND_BACK_CODE_TIME));
        } else {
            setIsConfirmationDone(true);
        }

        setIsLoading(undefined);
    }, []);

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';

            if (user && user.phone && !user.phoneIsVerified) {
                handleReceiveSmsCode();
            }
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal, handleReceiveSmsCode]);

    const renderCountDown = ({ seconds, completed }: CountdownRenderProps) => {
        if (completed) {
            return <Button label={t.translate('resendCodeSMS')} onClick={handleReceiveSmsCode} />
        } else {
            return <Button label={`${t.translate('resendCodeSMS')} (${seconds})`} disabled />
        }
    }

    const verifySMSCode = async () => {
        setIsLoading('submit');
        setError(undefined);

        if (smsCode) {
            await userAuthService.checkSMSCode({ code: smsCode })
                .then(() => {
                    toast.success(t.translateFromFile('profile/doctor/contact', 'phoneVerification.success'));
                    toggleShowModal();
                })
                .catch((err) => setError(err))
                .finally(() => setSmsCode(undefined));
        } else {
            setError('code_missing');
            setIsLoading(undefined);
        }
    }

    const handleCloseModal = () => {
        setError(undefined);

        toggleShowModal();
    }

    const handleToggleModal = () => {
        setError(undefined);

        toggleShowModal();
        toggleDataModal();
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={handleCloseModal}
            title={t.translateFromFile('profile/doctor/contact', 'phoneVerification.title')}
            footer={
                (!user?.phone && !user?.phone?.number)
                    && <Button label={t.translate('noPhone.fill')} onClick={() => handleToggleModal()} />
            }
        >
            {!user?.phone && !user?.phone?.number
                ? <div>
                    <p>{t.translate('noPhone.desc')}</p>
                </div>
                : isConfirmationDone
                    ? <div id="confirmation-container" className="column">
                        <div className="column" id="text-container">
                            <CheckCircleIcon size={70} />
                            <p id="title" className="MavenPro-bold">
                                {t.translate('successCodeSMS')}
                            </p>
                        </div>
                        <button className="dashboardBtn" onClick={() => { setIsLoading('first'); toggleShowModal() }}>{t.translate('next')}</button>
                    </div>
                    : <Fragment>
                        <div className="column" id="input-container">
                            <Label for='code-phone' label={t.translate('codePhone')} required />
                            <Input
                                required
                                name='code-phone'
                                type="number"
                                value={smsCode}
                                setValue={(e) => setSmsCode(e.currentTarget.value)}
                            />
                        </div>
                        <p>
                            <Countdown
                                date={countDownTime}
                                renderer={(props) => renderCountDown(props)}
                                key={countDownKey}
                            />
                        </p>
                        
                        <ErrorMessage error={error} />
                        <Button label={t.translate('validate')} onClick={() => verifySMSCode()} disabled={isLoading !== undefined} displayLoader={isLoading === 'submit'} />
                    </Fragment>
            }
        </Modal>
    )
}

export default VerifyPhoneModal;