import cancer_detection from 'assets/home/cancer_detection.webp';
import microbiota_analysis from 'assets/home/microbiota_analysis.webp';
import cardLock from 'assets/icons/card_lock.svg';
import { Button, Loader } from "components";
import { MenuStatusContext } from "context/MenuStatusContext";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { SolutionsContext, SubscriptionPlanTypeWithImage } from 'context/SolutionsContext';
import { UserTypeContext } from "context/User/UserTypeContext";
import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "services";
import orderService from "services/order.service";
import userAuthService from 'services/user-auth.service';
import './ComplementsValidation.scss';

export interface ComplementsValidationProps {}

const ComplementsValidation: React.FC<ComplementsValidationProps> = () => {
    const t = useTranslation('dashboard/company/order_kit');

    const { type } = useContext(UserTypeContext);
    const { solutions } = useContext(SolutionsContext);
    const { reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const { refreshMenuStatus } = useContext(MenuStatusContext);

    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();
    const [ success, setSuccess ] = useState<boolean>();

    const [ solution, setSolution ] = useState<SubscriptionPlanTypeWithImage>();

    const search = useLocation().search;

    useEffect(() => {
        const searchParams = new URLSearchParams(search);

        const solutionName = searchParams.get("appointment_name");

        setSolution(solutions.filter((e) => e.name === solutionName)[0]);

        const payment_intent = searchParams.get("payment_intent");
        const session_id = searchParams.get("session_id");

        if (payment_intent && payment_intent !== '') {
            orderService.handleNewOneTimeOrder({
                payment_intent: payment_intent
            })
                .then(() => {
                    if (type.isStandalone()) {
                        reloadSubscriptionTrackingDetail();
                    }
                    refreshMenuStatus();
                    setSuccess(true);
                })
                .catch((err) => setError(err.message))
                .finally(() => setTimeout(() => setIsLoading(false), 4000));
        } else if (session_id && session_id !== '') {
            orderService.handleNewOneTimeOrder({
                session_id: session_id
            })
                .then(() => {
                    if (type.isStandalone()) {
                        reloadSubscriptionTrackingDetail();
                    }
                    refreshMenuStatus();
                    setSuccess(true);
                })
                .catch((err) => setError(err.message))
                .finally(() => setTimeout(() => setIsLoading(false), 4000));
        } else {
            history.push({
                pathname: '/order'
            });
        }
    }, [search]);

    if (!userAuthService.isUserConnected()) history.push('/order');

    const handleKitImg = () => {
        switch (solution?.name) {
            case 'cancer_detection':
                return cancer_detection;

            default:
                return microbiota_analysis;
        }
    }

    return (
        <main id='validation-order' className='flex-column'>
            <div id='validation-order-page'>
                <div className='left-section'>
                    <div className='order-gallery-wrapper'>
                        <img className='kit_image' src={handleKitImg()} alt={`BodyCheckup health kit`} />
                    </div>
                </div>
                <div className='order-informations-wrapper'>
                    {isLoading
                        ? <Fragment>
                            <div className="flex-row MavenPro-bold">
                                <img src={cardLock} width={30} height={30} alt="Card with lock" />
                                <p>{t.translate('resulting.securePayment')}</p>
                            </div>
                            <h2 className="text-uppercase MavenPro-bold order-dashboard-title">
                                {t.translate('resulting.littleMore')}
                            </h2>
                            <p style={{ textAlign: 'center' }}>
                                {t.translate('resulting.fewChecks')}
                                <br />
                                <span style={{ fontWeight: 'bold' }} >
                                    {t.translate('resulting.noLonger')}
                                </span>
                            </p>
                            <Loader loaderDescription={t.translate('resulting.loading')} />
                        </Fragment>
                        : error
                            ? <Fragment>
                                <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{t.translate('resulting.error.title')}</h2>
                                <p>{t.translate('resulting.error.msg')}</p>
                                <div className="flex-row space-evenly">
                                    <Button label={t.translate('resulting.error.contact-us')} onClick={() => history.push('/contact-us')} />
                                    <Button label={t.translate('resulting.error.retry')} onClick={() => history.push('/order')} />
                                </div>
                            </Fragment>
                            : success
                                ? <Fragment>
                                    <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{t.translate('resulting.appointmentSuccess.title')}</h2>
                                    <p>{t.translate('resulting.appointmentSuccess.default.msg')}</p>
                                    <p style={{ textAlign: 'center' }}>{t.translateReplaceValues('resulting.appointmentSuccess.default.msg2', [
                                            {
                                                tag: '{start}',
                                                value: <span className='text-important'>{t.translate('resulting.appointmentSuccess.default.start')}</span>
                                            }
                                        ]
                                    )}</p>
                                    <div className="flex-row flex-wrap">
                                        <Button label={t.translate('resulting.appointmentSuccess.buttons.claim')} onClick={() => history.push('/account/appointments')} />
                                    </div>
                                </Fragment>
                                : <Fragment>
                                    <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{t.translate('resulting.safezone.title')}</h2>
                                    <p>{t.translate('resulting.safezone.msg')}</p>
                                    <Button label={t.translate('resulting.safezone.btn')} onClick={() => history.push('/account/home')} />
                                </Fragment>
                    }
                </div>
            </div>
        </main>
    )
}

export default ComplementsValidation;