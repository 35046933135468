import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IDUser } from "interfaces/Database";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import fileService from "services/file.service";

type PatientDoc = 'generalComment' | 'prescription' | 'dietaryComplement' | 'preventiveAdvice' | 'foodAdvice';

const PatientLastDocuments: React.FC<({
    patient: IDUser
})> = ({
    patient
}) => {
    const t = useTranslation('patientDetail');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();

    const [ isDownloading, setDownloading ] = useState<PatientDoc>();

    const [ generalComment, setGeneralComment ] = useState<string>();
    const [ prescription, setPrescription ] = useState<string>();
    const [ dietaryComplement, setDietaryComplement ] = useState<string>();
    const [ preventiveAdvice, setPreventiveAdvice ] = useState<string>();
    const [ foodAdvice, setFoodAdvice ] = useState<string>();

    useEffect(() => {
        const loadData = () => {
            Promise.all([
                doctorService.getPatientLastGeneralCommentPdf(patient.id)
                    .then((res) => setGeneralComment(res)),
                doctorService.getPatientLastPrescriptionPdf(patient.id)
                    .then((res) => setPrescription(res)),
                doctorService.getPatientLastDietaryComplementPdf(patient.id)
                    .then((res) => setDietaryComplement(res)),
                doctorService.getPatientLastPreventiveAdvicePdf(patient.id)
                    .then((res) => setPreventiveAdvice(res)),
                doctorService.getPatientLastFoodAdvicePdf(patient.id)
                    .then((res) => setFoodAdvice(res))
            ])
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }

        loadData();
    }, []);

    const handleDownloadFile = async (type: PatientDoc) => {
        setDownloading(type);
        setError(undefined);

        switch (type) {
            case 'generalComment':
                await doctorService.getPatientLastGeneralCommentPdf(patient.id)
                    .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-general_comment_last`.toLowerCase().replaceAll(' ', '_') }))
                    .catch((err) => setError(err.message));
                break;
            case 'prescription':
                await doctorService.getPatientLastPrescriptionPdf(patient.id)
                    .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-prescription_last`.toLowerCase().replaceAll(' ', '_') }))
                    .catch((err) => setError(err.message));
                break;
            case 'dietaryComplement':
                await doctorService.getPatientLastDietaryComplementPdf(patient.id)
                    .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-dietary_complement_last`.toLowerCase().replaceAll(' ', '_') }))
                    .catch((err) => setError(err.message));
                break;
            case 'preventiveAdvice':
                await doctorService.getPatientLastPreventiveAdvicePdf(patient.id)
                    .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-preventive_advice_last`.toLowerCase().replaceAll(' ', '_') }))
                    .catch((err) => setError(err.message));
                break;
            case 'foodAdvice':
                await doctorService.getPatientLastFoodAdvicePdf(patient.id)
                    .then((res) => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-food_advice_last`.toLowerCase().replaceAll(' ', '_') }))
                    .catch((err) => setError(err.message));
                break;
        }

        setDownloading(undefined);
    }

    return (
        <DefaultContainer
            title={
                <h2 className='bold'>{t.translate('lastDocuments.title')}</h2>
            }
            body={isLoading
                ? <Loader />
                : <div className="flex-row align-items-stretch flex-wrap">
                    <div className="flex-column flex-grow" style={{ margin: '10px 20px' }}>
                        <p>{t.translate('lastDocuments.generalComment')}</p>
                        <Button label={t.translate('lastDocuments.downloadButton')} disabled={!generalComment || isDownloading !== undefined} displayLoader={isDownloading === 'generalComment'} onClick={() => handleDownloadFile('generalComment')} />
                    </div>
                    <div className="flex-column flex-grow" style={{ margin: '10px 20px' }}>
                        <p>{t.translate('lastDocuments.prescription')}</p>
                        <Button label={t.translate('lastDocuments.downloadButton')} disabled={!prescription || isDownloading !== undefined} displayLoader={isDownloading === 'prescription'} onClick={() => handleDownloadFile('prescription')} />
                    </div>
                    <div className="flex-column flex-grow" style={{ margin: '10px 20px' }}>
                        <p>{t.translate('lastDocuments.dietaryComplement')}</p>
                        <Button label={t.translate('lastDocuments.downloadButton')} disabled={!dietaryComplement || isDownloading !== undefined} displayLoader={isDownloading === 'dietaryComplement'} onClick={() => handleDownloadFile('dietaryComplement')} />
                    </div>
                    <div className="flex-column flex-grow" style={{ margin: '10px 20px' }}>
                        <p>{t.translate('lastDocuments.preventiveAdvice')}</p>
                        <Button label={t.translate('lastDocuments.downloadButton')} disabled={!preventiveAdvice || isDownloading !== undefined} displayLoader={isDownloading === 'preventiveAdvice'} onClick={() => handleDownloadFile('preventiveAdvice')} />
                    </div>
                    <div className="flex-column flex-grow" style={{ margin: '10px 20px' }}>
                        <p>{t.translate('lastDocuments.nutritiousComment')}</p>
                        <Button label={t.translate('lastDocuments.downloadButton')} disabled={!foodAdvice || isDownloading !== undefined} displayLoader={isDownloading === 'foodAdvice'} onClick={() => handleDownloadFile('foodAdvice')} />
                    </div>
                    <ErrorMessage error={error} />
                </div>
            }
        />
    )
}

export default PatientLastDocuments;