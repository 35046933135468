export interface IDatabaseSurveyQuestion {
    id: number;
    question: string;
    codeQuestion: string;
}

export interface IDatabaseSurveyAnswer {
    question: IDatabaseSurveyQuestion;
    answer: string;
}

export interface IDatabaseSurvey {
    userAnswers: IDatabaseSurveyAnswer[];
    currentStep: number;
    isComplete: boolean;
    created_at: Date;
    updated_at: Date;
}

export default class DatabaseSurvey implements IDatabaseSurvey {
    userAnswers: IDatabaseSurveyAnswer[];
    currentStep: number;
    isComplete: boolean;
    created_at: Date;
    updated_at: Date;
    
    constructor(data: IDatabaseSurvey) {
        this.userAnswers = data.userAnswers;
        this.currentStep = data.currentStep;
        this.isComplete = data.isComplete;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
    }
}