import { IDatabaseCompanyAdministrator } from "./IDatabaseCompanyAdministrator";

export interface IDatabaseCompanyInfo {
    id: number;
    name: string;
    size?: number;
    registrationNumber?: string;
    administrators?: IDatabaseCompanyAdministrator[];
    description?: string;
    canBuyCorporate: boolean;
    canBuyPerformance: boolean;
    vat?: string;
    createdAt?: Date;
}

export default class CompanyInfo implements IDatabaseCompanyInfo {
    id: number;
    name: string;
    size?: number;
    registrationNumber?: string;
    administrators?: IDatabaseCompanyAdministrator[];
    description?: string;
    canBuyCorporate: boolean;
    canBuyPerformance: boolean;
    vat?: string;
    createdAt?: Date;

    constructor(data: IDatabaseCompanyInfo) {
        this.id = data.id;
        this.name = data.name;
        this.size = data.size;
        this.registrationNumber = data.registrationNumber;
        this.administrators = data.administrators;
        this.description = data.description;
        this.canBuyCorporate = data.canBuyCorporate;
        this.canBuyPerformance = data.canBuyPerformance;
        this.vat = data.vat;
        this.createdAt = data.createdAt;
    }
}