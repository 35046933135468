class StringService {
    private getStart(str: string, sub: string): number {
        return str.indexOf(sub);
    }

    private getEnd(str: string, sub: string): number {
        return str.indexOf(sub) + sub.length - 1
    }

    public getStartEnd(str: string, sub: string) {
        return [ this.getStart(str, sub), this.getEnd(str, sub)]
    }

    public capitalize(string?: string) {
        return string && string.length > 2 ? string.charAt(0).toUpperCase() + string.substring(1).toLowerCase() : string;
    }
}

export default new StringService();