import banner from 'assets/order_box_banner.png';
import { Button } from "components";
import CustomLoader from 'components/CustomLoader';
import Chat from "components/Messaging/components/Chat";
import LastMessages from "components/Messaging/components/LastMessages";
import ModalNewMessage from "components/Messaging/modal/ModalNewMessage";
import "components/Messaging/scss/Messaging.scss";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import { IDUser } from "interfaces/Database";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import { IConversation } from "interfaces/Messaging/IConversation";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import messagingService from "services/messaging.service";

const Messaging: React.FC = () => {
    const t = useTranslation('messaging');
    const location = useLocation();

    const { user } = useContext(UserContext);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const toggleShowModal = () => setShowModal(!showModal);

    const [ recipients, setRecipients ] = useState<IDatabaseUser[]>([]);
    const [ choosedRecipient, setChoosedRecipient ] = useState<IDatabaseUser>();

    const [ conversations, setConversations ] = useState<IConversation[]>([]);
    const [ conversation, setConversation ] = useState<IConversation>();
    const [ currentInterlocutorProfilePicture, setCurrentInterlocutorProfilePicture ] = useState<string>();

    const tryLoadConversation = (toOpen: number, convs: IConversation[], receips: IDUser[]) => {
        const conv = convs.find((conver) => conver.conversation.interlocutorId === toOpen);

        if (conv) {
            setConversation(conv);
            setCurrentInterlocutorProfilePicture(conv.image);
        } else {
            const user = receips.find((recipient) => recipient.id === toOpen);

            if (user) {
                setChoosedRecipient(user);
                toast.warn(t.translate('creating'), { toastId: 'warn-conv' });
                setShowModal(true);
            }
        }
    }

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            messagingService.getConversations()
                .then(async (res: IConversation[]) => {
                    const sortedConversations = res.sort((a, b) => a.lastMessage.createdAt < b.lastMessage.createdAt ? 1 : -1);
                    setConversations(sortedConversations);
                    
                    if (res.length > 0) {
                        setConversation(res[0]);
                        setCurrentInterlocutorProfilePicture(res[0].image);
                    } else {
                        setConversation(undefined);
                        setCurrentInterlocutorProfilePicture(undefined);
                    }

                    return sortedConversations;
                })
                .catch(async () => {
                    setConversations([]);
                    setConversation(undefined);
                    setCurrentInterlocutorProfilePicture(undefined);

                    return [];
                }),
            messagingService.getRecipientsList()
                .then((res) => {
                    setRecipients(res);
                    return res;
                })
                .catch(() => {
                    setRecipients([]);
                    return [];
                })
        ])
            .then((res) => {
                let id: number | undefined;

                const docId = location?.state?.openDoctorConversation;
                const patId = location?.state?.openConversationWithPatient;

                if (docId && Number(docId)) {
                    id = Number(docId);
                } else if (patId && Number(patId)) {
                    id = Number(patId);
                }

                if (id) tryLoadConversation(id, res[0], res[1]);
                window.history.replaceState({}, document.title);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSelectConversation = (conversationId: number) => {
        if (conversationId && conversationId !== conversation?.conversation.conversationId) {
            const conv = conversations.filter(conversation => conversation.conversation.conversationId === conversationId)[0];
            setConversation(conv);
            setCurrentInterlocutorProfilePicture(conv.image);
        }
    }

    const refreshConversations = () => {
        messagingService.getConversations()
            .then((res: IConversation[]) => {
                const sortedConversations = res.sort((a, b) => a.lastMessage.createdAt < b.lastMessage.createdAt ? 1 : -1);
                setConversations(sortedConversations);

                if (res.length > 0) {
                    setConversation(res[0]);
                    setCurrentInterlocutorProfilePicture(res[0].image);
                }
            }).catch(() => setConversations([]));
    }

    const handleSubmit = () => {
        refreshConversations();
        setShowModal(false);
    }

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate('header.pre')} title={t.translate('header.title')} />
            {isLoading
                ? <CustomLoader />
                : <Fragment>
                    {conversations.length === 0
                        ? <DefaultContainer
                            wrapperStyle={{ minHeight: '573px' }}
                            header={{
                                image: banner,
                                content: <h2>{t.translate('header.title')}</h2>
                            }}
                            body={
                                <Fragment>
                                    <h3>{t.translate('none.title')}</h3>
                                    <p>{t.translate('none.desc')}</p>
                                    <p>{t.translate('none.none')}</p>
                                    <p>{t.translateReplaceValues('none.howTo', [
                                        {
                                            tag: '{{btnLabel}}',
                                            value: t.translate('newMessage')
                                        }
                                    ])}</p>
                                </Fragment>
                            }
                            footer={
                                <Button label={t.translate('newMessage')} onClick={toggleShowModal} />
                            }
                        />
                        : <div id="messaging-wrapper">
                            <div id="last-messages-container">
                                <LastMessages
                                    conversation={conversation}
                                    conversations={conversations}
                                    handleSelectConversation={handleSelectConversation}
                                    setShowModal={setShowModal}
                                />
                            </div>
                            {conversation &&
                                <div id="chat-container">
                                    <Chat
                                        conversation={conversation}
                                        profilePicture={currentInterlocutorProfilePicture}
                                        refreshConversations={refreshConversations}
                                    />
                                </div>
                            }
                        </div>
                    }
                    <ModalNewMessage
                        showModal={showModal}
                        choosedRecipient={choosedRecipient}
                        setChoosedRecipient={setChoosedRecipient}
                        setShowModal={setShowModal}
                        handleSuccess={handleSubmit}
                        recipients={recipients}
                        currentUser={user}
                    />
                </Fragment>
            }
        </Fragment>
    );
}

export default Messaging;