import englishManual from "assets/Manual/manual_english-version.pdf";
import frenchManual from "assets/Manual/manual_french-version.pdf";
import solution_box from 'assets/our_solution/box-essai-1-solution.webp';
import { LanguageContext } from 'context/Language/LanguageContext';
import React, { useContext } from 'react';
import { useTranslation } from 'services';
import '../scss/SpecimensBox.scss';

const SpecimensBox: React.FC = () => {
    const t = useTranslation('product');
    const { language } = useContext(LanguageContext);

    const openManualPdf = async () => {
        switch (language.countryCode) {
            case "fr":
                window.open(frenchManual);
                break;
            case "en":
                window.open(englishManual);
                break;
            default:
                window.open(englishManual);
                break;
        }
    }

    return (
        <div className="specimensBoxWrapper">
            <div className="specimensBoxContent">
                <div className="specimensBoxContent-title">
                    <div className="numeroWrapper">
                        <div className="numeroWrapped">
                            <p className="text-gradient bold">1</p>
                        </div>
                    </div>
                    <p className="MavenPro-black text-uppercase">{t.translate('sample.title')}</p>
                </div>
                <p className="specimensBoxContent-desc MavenPro">
                    {t.translate('sample.receive')}<br />
                    {t.translate('sample.find')}
                </p>

                <div className="specimensBoxContent-button gradient-button" onClick={openManualPdf}>
                    <div>
                        <p className="MavenPro-bold text-uppercase text-gradient">
                            {t.translate('sample.button')}
                        </p>
                    </div>
                </div>
            </div>
            <div className="specimensBoxImg">
                <img
                    width="auto" height="100%"
                    src={solution_box}
                    alt="Bodycheckup specimen box"
                    className=""
                />
                <hr />
            </div>
        </div>
    )
}

export default SpecimensBox;