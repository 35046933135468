import { EditIcon, InformationWrapper, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { MenuStatusContext } from "context/MenuStatusContext";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "services";
import translationService from "services/translation.service";
import EditStandaloneHealthInfo from "../modals/EditStandaloneHealthInfo";

const StandaloneHealthInformations: React.FC = () => {
    const t = useTranslation('profile/patient/myHealthInformation');
    const { user, refreshUser } = useContext(UserContext);
    const { refreshMenuStatus } = useContext(MenuStatusContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const toggleShowEdit = () => setShowModal(!showModal);

    const refreshData = async () => {
        setIsLoading(true);

        Promise.all([
            refreshUser()
                .then(() => setShowModal(false)),
            refreshMenuStatus()
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="bold">{t.translate("title")}</h1>
                    <EditIcon
                        className="clickable"
                        onClick={() => setShowModal(true)}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    {isLoading
                        ? <Loader />
                        : <div id="health-information-wrapper">
                            <InformationWrapper
                                libelle={t.translate("size")}
                                value={user?.patientInfo?.size}
                            />
                            <InformationWrapper
                                libelle={t.translate("weight")}
                                value={user?.patientInfo?.weight}
                            />
                            <InformationWrapper
                                libelle={t.translate("waistSize")}
                                value={user?.patientInfo?.waistSize}
                            />
                            <InformationWrapper
                                libelle={t.translate("socialSecurityNumber")}
                                value={user?.patientInfo?.socialSecurityNumber}
                            />
                            <InformationWrapper
                                libelle={t.translate("specialMedication")}
                                value={user?.patientInfo?.specialMedication}
                            />
                            <InformationWrapper
                                libelle={t.translate("birthDate")}
                                value={translationService.translateDate(user?.patientInfo?.birthDate)}
                            />
                        </div>
                    }
                    <EditStandaloneHealthInfo
                        showModal={showModal}
                        handleClose={() => toggleShowEdit()}
                        handleSuccess={() => refreshData()}
                    />    
                </Fragment>
            }
        />
    );
}

export default StandaloneHealthInformations;