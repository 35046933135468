import { EditIcon, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { ModalEditSchedule } from "components/Modals";
import { Schedule, ScheduleDay } from "interfaces/Database";
import { scheduleDayNames } from "interfaces/Database/IDatabaseScheduleDay";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import useScheduleService from "services/api/Schedule.service";

const DoctorSchedule: React.FC = () => {
    const scheduleService = useScheduleService();

    const t = useTranslation('components/schedule');

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ schedule, setSchedule ] = useState<Schedule>();

    const [ showModal, setShowModal ] = useState<boolean>(false);
    const toggleShowModal = () => setShowModal(!showModal);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);

            scheduleService.get()
                .then((db_schedule) => setSchedule(db_schedule))
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false))
        }

        fetchData();
    }, []);

    const renderTime = (scheduleDay: ScheduleDay, dayIndex: number) => {
        if (scheduleDay.breaks.length === 0) return <p>
            {t.translateReplaceValues('fromAt', [
                {
                    tag: '{{from}}',
                    value: scheduleDay.renderStart()
                },
                {
                    tag: '{{at}}',
                    value: scheduleDay.renderEnd()
                }
            ])}
        </p>

        if (scheduleDay.breaks.length === 1) return <Fragment>
            <p>
                {t.translateReplaceValues('fromAt', [
                    {
                        tag: '{{from}}',
                        value: scheduleDay.renderStart()
                    },
                    {
                        tag: '{{at}}',
                        value: scheduleDay.breaks[0].renderStart()
                    }
                ])}
            </p>
            <p>
                {t.translateReplaceValues('thenAt', [
                    {
                        tag: '{{from}}',
                        value: scheduleDay.breaks[0].renderEnd()
                    },
                    {
                        tag: '{{at}}',
                        value: scheduleDay.renderEnd()
                    }
                ])}
            </p>
        </Fragment>

        return scheduleDay.breaks.map((dayBreak, dayBreakIndex, arr) => {
            if (dayBreakIndex === 0) {
                return <Fragment key={`schedule-day-${dayIndex}-break-${dayBreakIndex}`}>
                    <p key={`schedule-day-${dayIndex}-break-${dayBreakIndex}`}>
                        {t.translateReplaceValues('fromAt', [
                            {
                                tag: '{{from}}',
                                value: scheduleDay.renderStart()
                            },
                            {
                                tag: '{{at}}',
                                value: dayBreak.renderStart()
                            }
                        ])}
                    </p>
                    <p key={`schedule-day-${dayIndex}-break-${dayBreakIndex}`}>
                        {t.translateReplaceValues('thenAt', [
                            {
                                tag: '{{from}}',
                                value: dayBreak.renderEnd()
                            },
                            {
                                tag: '{{at}}',
                                value: arr[dayBreakIndex+1].renderStart()
                            }
                        ])}
                    </p>
                </Fragment>
            }

            if (dayBreakIndex === arr.length-1) {
                return <Fragment key={`schedule-day-${dayIndex}-break-${dayBreakIndex}`}>
                    <p key={`schedule-day-${dayIndex}-break-${dayBreakIndex}`}>
                        {t.translateReplaceValues('finallyAt', [
                            {
                                tag: '{{from}}',
                                value: dayBreak.renderEnd()
                            },
                            {
                                tag: '{{at}}',
                                value: scheduleDay.renderEnd()
                            }
                        ])}
                    </p>
                </Fragment>
            }

            return <p key={`schedule-day-${dayIndex}-break-${dayBreakIndex}`}>
                {t.translateReplaceValues('thenAt', [
                    {
                        tag: '{{from}}',
                        value: dayBreak.renderEnd()
                    },
                    {
                        tag: '{{at}}',
                        value: arr[dayBreakIndex+1].renderStart()
                    }
                ])}
            </p>
        });
    }

    const renderDays = () => {
        return schedule?.days.sort((a, b) => scheduleDayNames.indexOf(a.day) < scheduleDayNames.indexOf(b.day) ? -1 : 1).map((scheduleDay, index) =>
            <div key={index}>
                <h4>{t.translateDayName(scheduleDay.day)}</h4>
                {renderTime(scheduleDay, index)}
            </div>
        )
    }

    return (
        <Fragment>
            <DefaultContainer
                title={
                    <Fragment>
                        <h1 className="container-title bold">{t.translate('title')}</h1>
                        <EditIcon
                            className="clickable"
                            onClick={toggleShowModal}
                        />
                    </Fragment>
                }
                body={isLoading
                    ? <Loader />
                    : !schedule || schedule.days.length === 0
                        ? <p>{t.translate('noSchedule')}</p>
                        : <div className="full-width flex-row flex-gap-col flex-gap-row space-around">
                            {renderDays()}
                        </div>
                }
            />
            
            <ModalEditSchedule
                isShow={showModal}
                toggleShow={toggleShowModal}
                previous={schedule}
                onSuccess={(newSchedule) => {
                    setSchedule(newSchedule);
                    toggleShowModal();
                }}
            />
        </Fragment>
    )
}

export default DoctorSchedule;