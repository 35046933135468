export interface IDatabaseFile {
    id: number
    originalName: string
    path: string
    url: string
}

export class IFile implements IDatabaseFile {
    id: number;
    originalName: string;
    path: string;
    url: string;

    constructor(props: IDatabaseFile) {
        this.id = props.id;
        this.originalName = props.originalName;
        this.path = props.path;
        this.url = props.url;
    }

    getUrl() {
        return `${process.env.REACT_APP_API_URL}/images${this.url}`;
    }
}