import HowItWorksBanner from "components/HowItWorks/components/HowItWorkBanner";
import SampleVideo from "components/HowItWorks/components/SampleVideo";
import VideosTests from "components/HowItWorks/components/VideosTests";
import "components/HowItWorks/scss/HowItWorks.scss";
import React from "react";

const HowItWorks: React.FC = () => {
    return (
        <div id="how-it-works" className="column">
            <HowItWorksBanner />
            <SampleVideo />
            <VideosTests />
        </div>
    );
}

export default HowItWorks;