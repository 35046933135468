import { Button, InsuranceCoverage, Loader } from "components";
import FoodAdvicePreview from "components/DashboardHome/components/Patient/components/FoodAdvicePreview";
import NextAppointmentPreview from "components/DashboardHome/components/Patient/components/NextAppointmentPreview";
import PrescriptionPreview from "components/DashboardHome/components/Patient/components/PrescriptionPreview";
import Help from "components/common/Dashboard/Home/Help/Help";
import SurveyResume from "components/common/Dashboard/SurveyResume/SurveyResume";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ReferringDoctor from "components/common/ReferringDoctor/ReferringDoctor";
import SubscriptionTracking from "components/common/SubscriptionTracking/SubscriptionTracking";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { UserContext } from "context/User/UserContext";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";
import subscriptionPlanService from "services/subscriptionPlan.service";

const StandaloneDashboardHomePage: React.FC = () => {
    const { user } = useContext(UserContext);
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const history = useHistory();
    const t = useTranslation('dashboard/patient/home');

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ hasAlreadyBought, setHasAlreadyBought ] = useState<boolean>(false);
    const [ subscriptionProcessing, setSubscriptionProcessing ] = useState<ISubscriptionTrackingSteps>();
    const [ showHelp, setShowHelp ] = useState<boolean>(user?.firstLogin ?? true);

    const toggleHelp = () => setShowHelp(!showHelp);

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            standaloneService.hasUserBoughtSolutions()
                .then((res) => setHasAlreadyBought(res)),
            subscriptionPlanService.getSubscriptionProcessing()
                .then((res) => setSubscriptionProcessing(res)),
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="flex-column" style={{ minHeight: "60vh", justifyContent: 'flex-start' }}>
            <DashboardHeader
                preTitle={t.translate('header.my')}
                title={t.translate('header.dashboard')}
                button={
                    !showHelp
                        ? <Button label={t.translate('header.help')} onClick={() => toggleHelp()} />
                        : !user?.firstLogin
                            ? <AiOutlineClose
                                size={30}
                                color="var(--dashboard-main-color)"
                                className="clickable"
                                onClick={() => toggleHelp()}
                            />
                            : undefined
                }
            />
            <div className="flex-column full-width flex-grow">
                {isLoading
                    ? <Loader />
                    : showHelp
                        ? <Help toggleHelp={toggleHelp} type={"Standalone"} />
                        : <Fragment>
                            {!hasAlreadyBought && <DefaultContainer
                                title={<h2 className="container-title bold">{t.translate('firstBuy.title')}</h2>}
                                body={
                                    <Fragment>
                                        <p>{t.translate('firstBuy.body')}</p>
                                        <Button label={t.translate('firstBuy.button')} onClick={() => history.push('/order')} />
                                    </Fragment>
                                }
                            />}
                            {!subscriptionProcessing?.kitClaim?.isDone && <InsuranceCoverage />}
                            <SubscriptionTracking tracking={subscriptionProcessing} />
                            {subscriptionProcessing?.kitClaim?.isDone && <InsuranceCoverage />}
                            {subscriptionTrackingDetail?.isPlanCancerDetection() || <SurveyResume />}
                            {subscriptionTrackingDetail?.handleShouldShowAppointment() &&
                                <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                                    <NextAppointmentPreview />
                                    <ReferringDoctor />
                                </div>
                            }
                            {subscriptionTrackingDetail?.handleShouldHidePrescription() || <PrescriptionPreview />}
                            {subscriptionTrackingDetail?.isPlanCancerDetection() || <FoodAdvicePreview />}
                        </Fragment>
                }
            </div>
        </div>
    )
}

export default StandaloneDashboardHomePage;