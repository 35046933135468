import React from "react";
import { useTranslation } from "services";

const StepIndicator: React.FC<({
    step: 1 | 2 | 3  | 4  | 5 | number,
    maxStep?: number
})> = ({
    step,
    maxStep = 5
}) => {
    const t = useTranslation('common');

    return (
        <p>{t.translateReplaceValues(`globalSteps.${step}`, [
            {
                tag: '{{step}}',
                value: <span key={step} className='MavenPro-bold text-main-color'>{step}/{maxStep}</span>
            }
        ])}</p>
    )
}

export default StepIndicator;