import { Button, Loader, TextArea } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import ShowProfilePicture from "components/common/ProfilePicture/ShowProfilePicture";
import { UserContext } from "context/User/UserContext";
import { IDDoctorInfo, IDMessage, IDPatientInfo, IDStaffInfo } from "interfaces/Database";
import { IConversation } from "interfaces/Messaging/IConversation";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import messagingService from "services/messaging.service";
import "../scss/Chat.scss";

const Chat: React.FC<({
    conversation: IConversation
    profilePicture?: string
    refreshConversations: () => void
})> = ({ conversation, profilePicture, refreshConversations }) => {
    const t = useTranslation('messaging');
    const { user } = useContext(UserContext);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ totalMessages, setTotalMessages ] = useState<number>(0);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ conversationMessages, setConversationMessages ] = useState<IDMessage[]>([]);
    const [ newMessage, setNewMessage ] = useState<string>();

    const isMyDoctor = useCallback(() => {
        const doctorInfo = user?.doctor?.doctorInfo;

        if (user?.doctor && (`${doctorInfo?.firstname} ${doctorInfo?.lastname}`.toLowerCase() === conversation?.conversation.interlocutorName?.toLowerCase())) {
            return true;
        } else {
            return false;
        }
    }, [conversation, user]);

    const fetchConversation = useCallback(() => {
        setTimeout(() => {
            messagingService.fetchConversation(conversation.conversation.conversationId)
                .then((res) => {
                    setConversationMessages(res.receivedMessages.concat(res.sentMessages));
                    
                    if (isMyDoctor()) {
                        setTotalMessages(res.sentMessages.length);
                    }
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }, 300);
    }, [conversation, isMyDoctor]);

    useEffect(() => {
        setIsLoading(true);

        fetchConversation();
    }, [fetchConversation]);

    const if5MessagesAndIsMyDoctor = () => {
        try {
            return (totalMessages >= 5 && isMyDoctor()) ? true : false;
        } catch (error) {
            return false;
        }
    }

    const getFilteredConversation = () => {
        return conversationMessages.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1);
    }

    const isMessageReceived = (message: IDMessage) => {
        return message.messageSender.sender.doctorInfo 
            ? getFullName(message.messageSender.sender.doctorInfo) !== conversation?.conversation.interlocutorName
            : message.messageSender.sender.staffInfo
                ? getFullName(message.messageSender.sender.staffInfo) !== conversation?.conversation.interlocutorName
                : getFullName(message.messageSender.sender.patientInfo) !== conversation?.conversation.interlocutorName
    }

    const getFullName = (person?: IDDoctorInfo | IDPatientInfo | IDStaffInfo) => {
        return person ? `${person.firstname} ${person.lastname}` : t.translateFromFile('common', 'no-data');
    }

    const sendMessage = () => {
        if (newMessage && user?.patientInfo && isMyDoctor()) {
            setShowModal(true);
        } else {
            handleSendMessage();
        }
    }

    const handleSendMessage = async () => {
        if (newMessage) {
            setIsLoading(true);

            messagingService.sendMessage(conversation?.conversation.interlocutorId, { message: newMessage })
                .then(() => {
                    setNewMessage('');
                    fetchConversation();
                    refreshConversations();
                })
                .catch((err) => console.warn(err));
        }
    }

    const alerteStringDoctor = () => {
        return `${t.translate('check')} ${totalMessages !== null ? `${t.translate('reminder')} ${`${4 - totalMessages} message${5 - totalMessages > 1 ? "s" : ""}`}.`: ''}`
    }

    const differentSender = (message: IDMessage, message2?: IDMessage) => {
        if (!message2) return true;

        return message.messageSender.sender?.id !== message2.messageSender?.sender?.id;
    }

    const enoughtTimeSepare = (messageSendDate: Date, previousMessageSendDate?: Date) => {
        if (!previousMessageSendDate) return true;
        
        const diff = Math.abs(messageSendDate.getTime() - previousMessageSendDate.getTime());

        return (diff/60000) > 30;
    }

    const renderMessages = getFilteredConversation().map((message, index, array) =>
        <div key={index} className="flex-column full-width">
            {differentSender(message, array[index-1]) || enoughtTimeSepare(message.createdAt, array[index-1]?.createdAt ?? undefined)
                ? <span className={`message-date ${isMessageReceived(message) ? "message-date-right" : "message-date-left"}`}>
                    {messagingService.getMessageFormattedDate(message.createdAt)}
                </span>
                : null
            }
            <div className={`message-wrapper column ${isMessageReceived(message) ? "message-sent" : "message-received"}`}>
                <span className="message MavenPro">
                    {message.content}
                </span>
            </div>
        </div>
    )

    return (
        <DefaultContainer
            title={
                <div className="flex-row" id="chat-header">
                    <ShowProfilePicture image={profilePicture ? `data:image/png;base64, ${profilePicture}` : undefined} maxSize={'4rem'} margin={{ right: true }} />
                    <div id="chat-header-data" className="row space-between flex-grow">
                        <h1 id="contact-name">{conversation?.conversation.interlocutorName}</h1>
                        {if5MessagesAndIsMyDoctor() ? <p>{t.translate('maxMessage')}</p> : ""}
                    </div>
                    {isMyDoctor() && <p>{t.translateReplaceValues('messageSent', [
                        {
                            tag: '{{sent}}',
                            value: totalMessages
                        }
                    ])}</p>}
                </div>
            }
            body={isLoading
                ? <Loader />
                : <div id="conversation-wrapper">
                    {renderMessages}
                </div>
            }
            footer={
                <Fragment>
                    <div id="chat-input-wrapper" className="row">
                        <TextArea
                            name="new-message"
                            disabled={if5MessagesAndIsMyDoctor()}
                            value={newMessage}
                            setValue={(e) => setNewMessage(e.currentTarget.value)}
                            placeholder={t.translate('placeholder.writeMessage')}
                        />
                        <Button label={t.translate('send')} onClick={() => sendMessage()} disabled={if5MessagesAndIsMyDoctor() || !newMessage || newMessage?.length < 5} />
                    </div>
                    <ModalConfirmAction 
                        confirmAction={handleSendMessage}
                        description={alerteStringDoctor()}
                        handleClose={() => setShowModal(false)}
                        showModal={showModal}
                    />
                </Fragment>
            }
            wrapperClassName="full-height"
        />
    )
}

export default Chat;