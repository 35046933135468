import { IDatabaseAddress } from "interfaces/Database/IDatabaseAddress";
import { IDatabasePlanType } from "interfaces/Database/IDatabasePlanType";
import { IStandaloneSidebarActions } from "interfaces/IStandaloneSidebarActions";
import { IAddress } from "interfaces/User/IAddress";
import apiService from "./api.service";
import APIService from "./api/APIService";

class StandaloneService {
    private ROOT_ROUTE = '/api/standalone';
    private ApiService = new APIService('/standalone');

    public async canRegisterKit() {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/canRegisterKit', 'get');
    }

    public async canOrderKit() {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/canOrderKit', 'get');
    }

    public async getAddress() {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/address', 'get');
    }

    public async updateInformations(data?: {
        firstName?: string,
        lastName?: string,
        birthDate?: string,
        gender?: string,
        weight?: number,
        size?: number,
        waistSize?: number,
        specialMedication?: string,
        specialInformation?: string,
        socialSecurityNumber?: string
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/informations', 'put', data);
    }

    public async updateContactInformations(data?: {
        email?: string,
        countryCode?: string,
        phoneNumber?: string
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/contact', 'put', data);
    }

    public async getBillingAndShippingAddresses() {
        return await this.ApiService.call<{ billing?: IDatabaseAddress, shipping?: IDatabaseAddress }>('/address/all', 'get');
    }

    public async updateAddresses(data: {
        sameAddresses: boolean, // areAddressesSame
        billing?: IAddress,
        shipping?: IAddress
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/address/all', 'post', data);
    }

    public async getPlanTypes() {
        return await this.ApiService.call<IDatabasePlanType[]>(this.ROOT_ROUTE+'/planTypes', 'get');
    }

    public async getAvailableSolutions() {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/solutions/available', 'get');
    }

    public async offerPlan(data?: {
        firstName?: string,
        lastName?: string,
        email?: string,
        countryCode?: string,
        phoneNumber?: string,
        planId?: number
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/plan/offer', 'post', data);
    }

    public getDoctor = async () => {
        return await apiService.makeApiCall(this.ROOT_ROUTE+`/doctor`, 'get');
    }

    public getNutritionist = async () => {
        return await apiService.makeApiCall(this.ROOT_ROUTE+`/nutritionist`, 'get');
    }

    public async getCurrentSolutionStatus() {
        return await this.ApiService.call<string>('/getCurrentSolutionStatus', 'get');
    }

    public async getSidebarActions() {
        return await this.ApiService.call<IStandaloneSidebarActions>(`/sidebarActions`, 'get');
    }

    public async hasUserBoughtSolutions() {
        return await this.ApiService.call<boolean>('/hasAlreadyBought', 'get');
    }

    public async sendShareToContacts(data: { emails?: string[], phone?: string[] }) {
        return await apiService.makeApiCall<{ email: { total: number, sent: number, errored: number }, phone: { total: number, sent: number, errored: number } }>(this.ROOT_ROUTE+'/share', 'post', data);
    }
}

export default new StandaloneService();