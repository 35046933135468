import ScheduleDay, { IDatabaseScheduleDay, IScheduleDayCreate, ScheduleDayCreate } from "./IDatabaseScheduleDay";

export interface IDatabaseSchedule {
    id: number;
    days: IDatabaseScheduleDay[];
}

export interface IScheduleCreate {
    days?: ScheduleDayCreate[];
}

export class ScheduleCreate implements IScheduleCreate {
    days: ScheduleDayCreate[];

    constructor(data?: IScheduleCreate | Schedule) {
        this.days = data?.days?.map((day) => new ScheduleDayCreate(day)) ?? [];
    }

    public addDay(data?: IScheduleDayCreate) {
        this.days = [...this.days, new ScheduleDayCreate(data)];
        return this;
    }

    public removeDay(dayIndex: number) {
        this.days = this.days.filter((_, i) => dayIndex !== i);
        return this;
    }
}

export default class Schedule implements IDatabaseSchedule {
    id: number;
    days: ScheduleDay[];

    constructor(data: IDatabaseSchedule) {
        this.id = data.id;
        this.days = data.days.map((scheduleDay) => new ScheduleDay(scheduleDay));
    }
}