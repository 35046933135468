import defaultComplementImg from 'assets/complement_default.jpg';
import { Button, CheckCircleIcon, EditIcon, EyeIcon, Loader } from 'components';
import ComplementDetailModal from 'components/Modals/ComplementDetailModal/ComplementDetailModal';
import CartRecap from 'components/OrderComplements/CartRecap/CartRecap';
import Address from 'components/OrderKit/Address/Address';
import Identify from 'components/OrderKit/Identify/Identify';
import { UserContext } from 'context/User/UserContext';
import { Cart } from 'interfaces';
import { IComplement, IDAddress, IDPlatform, IDProductOrderType } from 'interfaces/Database';
import { IComplementCategory } from 'interfaces/Database/IDatabaseComplementCategory';
import PaymentType from 'interfaces/Database/IDatabasePaymentType';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useTranslation } from 'services';
import addressService from 'services/address.service';
import useComplementService from 'services/api/complement.service';
import usePaymentTypeService from 'services/api/paymentType.service';
import usePlatformService from 'services/api/platform.service';
import useProductOrderTypeService from 'services/api/productOrderType.service';
import prescriptionService from 'services/prescription.service';
import priceService from 'services/price.service';
import userAuthService from 'services/user-auth.service';
import './OrderComplements.scss';
import Payment from './Payment';

import { DeliveryTruck, QuestionAnswer } from 'components/Icons';

import stripeLogo from 'assets/stripe.jpeg';
import { LanguageContext } from 'context/Language/LanguageContext';

interface OrderComplementsProps {}

const steps = ['cart', 'auth', 'address', 'payment'] as const;
type Step = typeof steps[number];

const OrderComplements: React.FC<OrderComplementsProps> = (props) => {
    const t = useTranslation('pages/orderSolution');

    const complementService = useComplementService();
    const platformService = usePlatformService();
    const productOrderTypeService = useProductOrderTypeService();
    const paymentTypeService = usePaymentTypeService();

    const [isLoading, setIsLoading] = useState<'complements' | null>('complements');
    const [isLoadingComplementaries, setIsLoadingComplementaries] = useState<Step>();
    const [baseComplements, setBaseComplements] = useState<IComplement[]>([]);

    const [isAlreadyAuth, setIsAlreadyAuth] = useState<boolean>(false);

    const [categories, setCategories] = useState<IComplementCategory[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<IComplementCategory[]>([]);

    const [cart, setCart] = useState<Cart<IComplement>>(new Cart());
    const [selectedDetailedComplement, setSelectedDetailedComplement] = useState<IComplement>();

    const { isLanguage } = useContext(LanguageContext);
    const { user, refreshUser } = useContext(UserContext);

    const [billingAddress, setBillingAddress] = useState<IDAddress>();
    const [shippingAddress, setShippingAddress] = useState<IDAddress>();
    const [areBillingAndShippingSame, setAreBillingAndShippingSame] = useState<boolean>(true);

    const [step, setStep] = useState<Step>('cart');

    const [ isPaymentFormLoaded, setIsPaymentFormLoaded ] = useState<boolean>(false);
    const togglePaymentFormLoaded = () => setIsPaymentFormLoaded(!isPaymentFormLoaded);

    const [ platform, setPlatform ] = useState<IDPlatform>();
    const [ productOrderType, setProductOrderType ] = useState<IDProductOrderType>();
    const [ paymentTypes, setPaymentTypes ] = useState<PaymentType[]>([]);

    const checkoutDivRef = useRef<HTMLDivElement>(null);
    const cartListDivRef = useRef<HTMLDivElement>(null);
    const addressDivRef = useRef<HTMLDivElement>(null);
    const paymentDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (step === 'auth') {
            if (userAuthService.isUserConnected()) {
                setIsLoadingComplementaries('auth');
                refreshUser()
                    .then((res) => {
                        if (!res) {
                            setStep('auth');
                            userAuthService.logoutWORedirect();
                        } else {
                            setStep('address');
                        }
                    })
                    .catch(() => userAuthService.logoutWORedirect())
                    .finally(() => setIsLoadingComplementaries(undefined));
            }
        } else if (step === 'address') {
            setIsLoadingComplementaries('address');
            addressService.getAddresses()
                .then((res) => {
                    setShippingAddress(res.shipping);
                    setBillingAddress(res.billing);

                    if (!addressService.compareAddresses(res.billing, res.shipping)) setAreBillingAndShippingSame(false);
                })
                .finally(() => setIsLoadingComplementaries(undefined));
        } else if (step === 'payment') {
            setIsLoadingComplementaries('payment');

            Promise.all([
                    platformService.getWebPlatform(),
                    productOrderTypeService.getComplementType(),
                    paymentTypeService.getAllPaymentTypes(),
                ])
                .then((res) => {
                    setPlatform(res[0]);
                    setProductOrderType(res[1]);
                    setPaymentTypes(res[2]);
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoadingComplementaries(undefined));
        }
    }, [step]);

    useEffect(() => {
        setIsAlreadyAuth(false);

        const loadData = () => {
            setIsLoading('complements');

            Promise.all([
                refreshUser()
                    .then((res) => {
                        setIsAlreadyAuth(true);
                        return res;
                    }),
                complementService.getAll()
                    .then((res) => {
                        console.log(res);
                        setBaseComplements(res);
                        
                        const tmpCategories: IComplementCategory[] = [];

                        res.forEach((complement) => {
                            if (
                                complement.category &&
                                tmpCategories.filter((category) => category.id === complement.category?.id).length === 0
                            ) {
                                tmpCategories.push(complement.category);
                            }
                        });
                        
                        setCategories(tmpCategories);
                        return res;
                    })
            ])
                .then(([reqUser, reqComplements]) => {
                    if (reqUser && reqComplements.length > 0) {
                        let hasLoadedSomething = false;

                        prescriptionService.getLast()
                            .then((res) => {
                                res.dietarySupplements?.map((supplement) => {
                                    if (supplement.complement) {
                                        const foundFromBase = reqComplements.filter((base) => base.id === supplement.complement?.id);

                                        if (foundFromBase.length > 0) {
                                            handleAddToCart(foundFromBase[0], 1);
                                            hasLoadedSomething = true;
                                        }
                                    }
                                })
                            })
                                .finally(() => {
                                    if (hasLoadedSomething) toast.success(t.translate('loadedComplements'));
                                });
                    }
                })
                .catch((err) => console.error(err))
                .finally(() => setIsLoading(null));
        }

        loadData();
    }, []);

    const handleAddToCart = (complement: IComplement, customQuantity: number = 1) => {
        const found = cart.items.filter((cartItem) => cartItem.item.id === complement.id);

        if (found.length > 0) {
            // if (customQuantity === 0 || found[0].quantity === customQuantity) {
            //     setSelectedComplements(prev => prev.filter((cartItem) => cartItem.item.id !== complement.id));
            //     return;
            // }
        } else {
            setCart(new Cart(cart.addItem(complement, customQuantity)));
        }
    }

    const handleShowComplementDetail = (complement: IComplement) => {
        if (selectedDetailedComplement === complement) {
            setSelectedDetailedComplement(undefined);
        } else {
            setSelectedDetailedComplement(complement);
        }
    }

    const handleRemoveFromCart = (complement: IComplement) => {
        setCart(new Cart(cart.removeItem(complement)));
    }

    const handleSelectCategory = (category: IComplementCategory) => {
        if (selectedCategories.filter((selectedCategory) => selectedCategory.id === category.id).length > 0) {
            setSelectedCategories((prev) => prev.filter((selectedCategory) => selectedCategory.id !== category.id));
        } else {
            setSelectedCategories((prev) => [
                ...prev,
                category
            ]);
        }
    }

    const renderComplementCategories = () => {
        if (isLoading || categories.length === 0) return undefined;

        return (
            <div className='complement-category-wrapper'>
                {categories.map((category) =>
                    <p
                        key={`category-${category.id}`}
                        className={
                            selectedCategories
                                .filter((selectedCategory) => selectedCategory.id === category.id)
                                .length > 0
                                ? 'selected'
                                : ''
                        }
                        onClick={() => handleSelectCategory(category)}
                    >
                        {t.translateComplementCategoryName(category.name)}
                    </p>
                )}
            </div>
        )
    }

    const handleRenderDescription = (complement: IComplement) => {
        if (isLanguage('fr') && complement.description_fr) {
            return complement.description_fr;
        }

        return complement.description;
    }

    const renderComplementCard = (complement: IComplement) => {
        const foundBasketElements = cart.items.filter((e) => e.item.id === complement.id);

        const cartElement = foundBasketElements[0] ?? null;

        return (
            <div
                key={complement.id}
                className={cartElement
                    ? 'complements-wrapper-box highlighted'
                    : 'complements-wrapper-box'
                }
            >
                <EyeIcon
                    className='complements-wrapper-box-view-icon'
                    size={30}
                    onClick={() => handleShowComplementDetail(complement)}
                />
                {complement?.picture
                    ? <img src={complement.picture.getUrl()} />
                    : <img src={defaultComplementImg} />
                }
                <div className='complements-wrapper-box-info flex-column-start flex-grow' style={{
                    justifyContent: 'flex-start'
                }}>
                    <div className='flex-row full-width align-items-center space-between' style={{ marginTop: '10px' }}>
                        <div className='flex-column-start'>
                            <h3 style={{ margin: '0' }}>{complement.name}</h3>
                            <h4 style={{ margin: '0', textTransform: 'uppercase', fontSize: '10px' }}>{complement.category ? t.translateComplementCategoryName(complement?.category?.name) : ''}</h4>
                        </div>
                        <i>
                            {t.translateReplaceValues('price', [
                                {
                                    tag: '{{price}}',
                                    value: priceService.roundAndFormat(complement.price)
                                }
                            ])}
                        </i>
                    </div>
                    {complement.quantityInBox &&
                        <p style={{ margin: 0, color: 'var(--light-gray)' }}>
                            {t.translateReplaceValues('perBox', [
                                {
                                    tag: '{{amount}}',
                                    value: complement.quantityInBox
                                }
                            ])}
                        </p>
                    }
                    {complement.baseIntakeQuantity &&
                        <p style={{ margin: 0, color: 'var(--light-gray)' }}>
                            {t.translateReplaceValues('baseIntake', [
                                {
                                    tag: '{{amount}}',
                                    value: complement.baseIntakeQuantity
                                }
                            ])}
                        </p>
                    }
                    {complement?.description && <p className='complements-wrapper-box-info-desc'>
                        {handleRenderDescription(complement)}
                    </p>}
                </div>
                <div className='flex-row' style={{ boxSizing: 'border-box', padding: '10px' }}>
                    {cartElement
                        ? <div className='icon-invert-container'>
                            <CheckCircleIcon className='co-icon iconCheck' size={32} />
                            <FaTrash className='co-icon iconTrash clickable' onClick={() => handleRemoveFromCart(complement)} size={20} color='var(--red)' style={{ padding: '6px' }} />
                        </div>
                        : <Button label={t.translate('addToCart')} onClick={() => handleAddToCart(complement)} style={{ whiteSpace: 'nowrap' }} />
                    }
                </div>
            </div>
        )
    }

    const renderComplements = () => {
        if (isLoading) return <Loader />

        if (baseComplements.length === 0) return <p>
            No complements to show
        </p>

        if (selectedCategories.length > 0) {
            const categorizedComplements = baseComplements.filter((complement) =>
                complement.category && selectedCategories.map((e) => e.id).includes(complement.category.id)
            );
        
            const otherComplements = baseComplements.filter((complement) =>
                !complement.category || !selectedCategories.map((e) => e.id).includes(complement.category.id)
            );

            return (
                <div className='full-width full-height flex-column'>
                    <h2 className='text-main-color text-uppercase'>{t.translate('selectedCategories')}</h2>
                    <div className='flex-row align-items-stretch flex-wrap' style={{ columnGap: '2vw', rowGap: '2vh' }}>
                        {categorizedComplements.map((complement) => renderComplementCard(complement))}
                    </div>

                    {otherComplements.length > 0 &&
                        <Fragment>
                            <h2 className='text-main-color text-uppercase'>{t.translate('other')}</h2>
                            <div className='flex-row flex-wrap' style={{ columnGap: '1vw', rowGap: '1vh' }}>
                                {otherComplements.map((complement) => renderComplementCard(complement))}
                            </div>
                        </Fragment>
                    }
                </div>
            )
        }

        return baseComplements.map((complement) => renderComplementCard(complement));
    }

    const isStepPassed = (testStep: Step) => {
        return steps.indexOf(testStep) < steps.indexOf(step);
    }

    const handleNextStep = () => {
        if (step === 'cart' && isAlreadyAuth) {
            setStep(steps[steps.indexOf(step)+2]);
        } else {
            setStep(steps[steps.indexOf(step)+1]);
        }
    }

    return (
        <Fragment>
            <h1 className='full-width text-align-left'
                style={{
                    color: 'var(--dashboard-main-color)',
                    marginBottom: 0,
                    boxSizing: 'border-box',
                    paddingLeft: '11vw',
                }}>
                {t.translate('title')}
            </h1>
            <main id='order-complement' className='flex-column'>
                <div className='complements-wrapper'>
                    {renderComplementCategories()}
                    {renderComplements()}
                </div>
                <div className='complements-checkout' style={{padding: '1vh 1vw' }} ref={checkoutDivRef}>
                    <div className='flex-row full-width' style={{ position: 'relative' }}>
                        <h1 className='text-align-center' style={{ color: 'var(--dashboard-main-color)', marginBottom: 0 }}>
                            {t.translate('titleComplements')}
                        </h1>
                    </div>
                    {cart.items.length > 0 && <p className='full-width text-align-center' style={{ fontSize: '18px' }}>
                        {t.translateReplaceValues('total', [
                            {
                                tag: '{{price}}',
                                value: priceService.roundAndFormat(cart.getTotal())
                            }
                        ])}
                    </p>}
                    {/* Cart validation */}
                    <div className='order-informations-wrapper-step' ref={cartListDivRef}>
                        <div className='order-informations-wrapper-step-title'>
                            {isStepPassed('cart')
                                ? <CheckCircleIcon size={32} />
                                : <p>1.</p>
                            }
                            <div className='flex-column-start'>
                                <h2 style={isStepPassed('cart') ? { marginBottom: 0 } : {}}>{t.translate('steps.cart.title')}</h2>
                                {/* {isStepPassed('cart')
                                    && <p style={{ marginTop: 0 }}>
                                        TODO
                                    </p>
                                } */}
                            </div>
                            {isStepPassed('cart')
                                && <EditIcon className='clickable' onClick={() => setStep('cart')} style={{ position: 'absolute', right: '0' }} />
                            }
                        </div>
                        {step === 'cart' &&
                            <CartRecap
                                cart={cart}
                                setCart={setCart}
                                handleNextStep={handleNextStep}
                            />
                        }
                    </div>
                    {/* Login */}
                    <div className='order-informations-wrapper-step' ref={cartListDivRef}>
                        <div className='order-informations-wrapper-step-title'>
                            {(isStepPassed('auth') || isAlreadyAuth)
                                ? <CheckCircleIcon size={32} />
                                : <p>2.</p>
                            }
                            <div className='flex-column-start'>
                                <h2 style={(isStepPassed('auth') || isAlreadyAuth) ? { marginBottom: 0, marginTop: 0 } : {}}>{t.translate('steps.login.title')}</h2>
                                {isStepPassed('auth')
                                    && <p style={{ marginTop: 0 }}>
                                    {t.translateReplaceValues(
                                        'steps.login.as',
                                        [
                                            {
                                                tag: '{{identity}}',
                                                value: user?.formatName() ?? ''
                                            }
                                        ]
                                    )}
                                    </p>
                                }
                            </div>
                            {isStepPassed('auth')
                                && <EditIcon className='clickable' onClick={() => setStep('auth')} style={{ position: 'absolute', right: '0' }} />
                            }
                        </div>
                        {step === 'auth' && (
                            isLoadingComplementaries === 'auth'
                                ? <Loader />
                                : <Identify refresh={refreshUser} toggleConnecting={handleNextStep} />
                        )}
                    </div>
                    {/* Address */}
                    <div className='order-informations-wrapper-step' ref={addressDivRef}>
                        <div className='order-informations-wrapper-step-title'>
                            {isStepPassed('address')
                                ? <CheckCircleIcon size={32} />
                                : <p>3.</p>
                            }
                            <div>
                                <h2 style={isStepPassed('address') ? { marginBottom: 0 } : {}}>{t.translate('steps.address.title')}</h2>
                                {isStepPassed('address')
                                    && <p style={{ marginTop: 0 }}>
                                        {t.translateReplaceValues(
                                            'steps.address.using',
                                            [
                                                {
                                                    tag: '{{address}}',
                                                    value: shippingAddress?.streetAddress
                                                }
                                            ]
                                        )}
                                    </p>
                                }
                            </div>
                            {isStepPassed('address')
                                && <EditIcon className='clickable' onClick={() => setStep('address')} style={{ position: 'absolute', right: '0' }} />
                            }
                        </div>
                        {step === 'address' && (
                            isLoadingComplementaries === 'address'
                                ? <Loader />
                                : <Address
                                    billingAddress={billingAddress}
                                    shippingAddress={shippingAddress}
                                    areSame={areBillingAndShippingSame}
                                    handleNextStep={async () => handleNextStep()}
                                />
                        )}
                    </div>
                    {/* Payment */}
                    <div className='order-informations-wrapper-step' ref={paymentDivRef} style={{ marginBottom: '12vh' }}>
                        <div className='order-informations-wrapper-step-title'>
                            <p>4.</p>
                            <div>
                                <h2>{t.translate('steps.payment.title')}</h2>
                            </div>
                        </div>
                        {(
                            step === 'payment' &&
                            billingAddress && shippingAddress &&
                            platform &&
                            productOrderType &&
                            billingAddress
                        ) &&
                            <Payment
                                cart={cart}
                                payment_types={paymentTypes}
                                platform={platform}
                                product_order_type={productOrderType}
                                renew={false}
                                billingAddress={billingAddress}
                                togglePaymentFormLoaded={togglePaymentFormLoaded}
                                togglePreviousStep={() => setStep('address')}
                            />
                        }
                    </div>
                </div>
                <ComplementDetailModal
                    complement={selectedDetailedComplement}
                    setComplement={setSelectedDetailedComplement}
                    cart={cart}
                    setCart={setCart}
                />
            </main>
            <div className='full-width flex-row align-items-stretch footerSection'>
                <div>
                    <img src={stripeLogo} />
                    <div className='flex-column-start'>
                        <h4>Paiement sécurisé</h4>
                        <p>avec Stripe (Visa, mastercard, etc.)</p>
                    </div>
                </div>
                <div>
                    <DeliveryTruck />
                    <div className='flex-column-start'>
                        <h4>Livraison rapide</h4>
                        <p>3 à 5 jours ouvrés</p>
                    </div>
                </div>
                <div>
                    <QuestionAnswer />
                    <div className='flex-column-start'>
                        <h4>Une question ?</h4>
                        <a href='https://bodycheckup.com/contact-us'><p>Contactez-nous</p></a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default OrderComplements;