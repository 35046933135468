import { IIcon, defaultIconProps } from "interfaces/IIcon";
import React from "react";
import { BsTelephone } from "react-icons/bs";

const Phone: React.FC<IIcon> = (props) => {
    return (
        <BsTelephone
            id={props.id}
            className={props.className}
            size={props.size}
            color={props.color}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            style={{
                minWidth: props.size,
                minHeight: props.size,
                ...props.style
            }}
        />
    )
}

Phone.defaultProps = defaultIconProps;

export default Phone;