import { Label, Loader, MultipleSelect } from "components";
import DoctorForm from "components/DoctorForms/common/DoctorForm";
import { IDatabaseDiet } from "interfaces/Database/IDatabaseDiet";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import dietService from 'services/diet.service';
import doctorService from "services/doctor.service";
import nutritionalCommentService from "services/nutritionalComment.service";
import translationService from "services/translation.service";

const NutritionalCommentCreation: React.FC = () =>  {

    const history = useHistory();
    const t = useTranslation('patientDetail');
    const { patientId, appointmentId } = useParams<{ patientId: string, appointmentId: string }>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ nutritiousComment, setNutritiousComment ] = useState<string>("");
    const [ patientFullName, setPatientFullName ] = useState<string>("");
    const [ lastUpdate, setLastUpdate ] = useState<Date>();

    const [ allDiets, setAllDiets ] = useState<IDatabaseDiet[]>([]);
    const [ recommendedDiets, setRecommendedDiets ] = useState<IDatabaseDiet[]>([]);

    const fetchData = () => {
        setIsLoading(true);

        Promise.all([
            dietService.fetchDiets()
                .then((res) => setAllDiets(res)),
            doctorService.getPatientDetails(patientId)
                .then((patient) => {
                    setPatientFullName(`${patient?.result?.patientInfo?.firstname} ${patient?.result?.patientInfo?.lastname}`);
                    setNutritiousComment(
                        patient.nutritionalComment
                            ? patient.nutritionalComment.comment
                            : ""
                    );

                    setRecommendedDiets(patient?.nutritionalComment?.diets ?? []);
                    setLastUpdate(patient?.nutritionalComment?.updatedAt);
                })
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async () => {
        setIsLoading(true);

        nutritionalCommentService.createOrUpdate({
            appointmentId: appointmentId,
            nutritiousComment: nutritiousComment,
            diets: recommendedDiets.map((e) => e.name)
        })
            .then(() => history.push(`/account/patient/${patientId}`))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    if (isLoading) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <DoctorForm
                title={t.translate('pdf.nutritiousComment.title')}
                onSubmit={handleSubmit}
                setFormValue={setNutritiousComment}
                patientFullName={patientFullName}
                value={nutritiousComment}
                dateLastUpdate={lastUpdate}
                handleGoBack={handleGoBack}
                additionalFields={
                    <div className="full-width">
                        <Label
                            label={t.translate('pdf.nutritiousComment.diets')}
                            required
                            for="select-diets"
                        />
                        <MultipleSelect
                            name="select-diets"
                            baseItems={allDiets}
                            selectedItems={recommendedDiets}
                            setSelectedItems={setRecommendedDiets}
                            comparisonKey="id"
                            displayKey="name"
                            transformer={(e) => translationService.translateDiet(e)}
                        />
                    </div>
                }
            />
        );
    }
}

export default NutritionalCommentCreation;