import { createSlice } from '@reduxjs/toolkit';


export const cartStatus = createSlice({
  name: 'cartIsEmpty',
  initialState: {
    loading: 'idle',
    cartIsEmpty: false
  },
  reducers: {
    refresh: state => {
      state.cartIsEmpty = false;
    }
  }
});

export const { refresh } = cartStatus.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getCartStatus = (state: any) => state.cartIsEmpty;

export default cartStatus.reducer;
