class ErrorService {
    public handleApiError = (axiosError: any): void => {
        let finalError = 'unexpected_error';

        if (axiosError.response)  {
            if (axiosError.response.status === 401) {
                finalError = 'invalid_token';

                if (axiosError?.response?.data?.error) {
                    finalError = axiosError?.response?.data?.error;
                } else if (axiosError?.response?.data?.message) {
                    const message = axiosError?.response?.data?.message;

                    if (message === 'Invalid credentials.') {
                        finalError = 'invalid_credentials';
                    }
                }
            } else if (axiosError?.response?.data?.message) {
                finalError = axiosError.response.data.message;
            } else if (axiosError?.response?.data?.error) {
                finalError = axiosError.response.data.error;
            } else if (axiosError?.response?.data?.detail) {
                finalError = axiosError?.response?.data?.detail;
            } else {
                console.error("Unexpected error : ", axiosError);
            }
        } else {
            finalError = 'api_disconnected';
        }
    
        throw new Error(finalError);
    }
}

export default new ErrorService();