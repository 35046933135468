import { IDAnalysisKit, IDSubscriptionPlanStatus, IDUser } from ".";
import DatabaseSubscriptionPlanType from "./IDatabaseSubscriptionPlanType";

export interface IDatabaseSubscriptionPlan {
    allocatedOn?: Date
    analysisKits?: IDAnalysisKit[]
    autoRenew?: boolean
    createOn?: Date
    endedOn?: Date
    id?: number
    price?: number
    startedOn?: Date,
    status?: IDSubscriptionPlanStatus
    type?: DatabaseSubscriptionPlanType
    patient?: IDUser
}

export default class DatabaseSubscriptionPlan implements IDatabaseSubscriptionPlan {
    allocatedOn?: Date;
    analysisKits?: IDAnalysisKit[];
    autoRenew?: boolean;
    createOn?: Date;
    endedOn?: Date;
    id?: number;
    price?: number;
    startedOn?: Date;
    status?: IDSubscriptionPlanStatus;
    type?: DatabaseSubscriptionPlanType;
    patient?: IDUser;
  
    constructor(data: IDatabaseSubscriptionPlan) {
        if (data.allocatedOn) this.allocatedOn = new Date(data.allocatedOn);
        if (data.analysisKits) this.analysisKits = data.analysisKits;
        if (data.autoRenew !== undefined) this.autoRenew = data.autoRenew;
        if (data.createOn) this.createOn = new Date(data.createOn);
        if (data.endedOn) this.endedOn = new Date(data.endedOn);
        if (data.id) this.id = data.id;
        if (data.price) this.price = data.price;
        if (data.startedOn) this.startedOn = new Date(data.startedOn);
        if (data.status) this.status = data.status;
        if (data.type) this.type = new DatabaseSubscriptionPlanType(data.type);
        if (data.patient) this.patient = data.patient;
    }

    public isTypeMicrobiota(): boolean {
        return this.type?.isMicrobiota() ?? false;
    }

    public isTypeCancer(): boolean {
        return this.type?.isCancer() ?? false;
    }

    public isTypeMicrobiotaOnly(): boolean {
        return this.type?.isMicrobiotaOnly() ?? false;
    }

    public isTypeMicrobiotaStressBurnout(): boolean {
        return this.type?.isMicrobiotaStressBurnout() ?? false;
    }

    public isTypeStressBurnout(): boolean {
        return this.type?.isStressBurnout() ?? false;
    }
}