import { EditIcon, InformationWrapper, Loader } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { EditProfilePicture } from 'components/common/ProfilePicture';
import { UserContext } from 'context/User/UserContext';
import moment from 'moment';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'services';
import '../scss/Information.scss';
import ModalEditProfileInfo from './modal/ModalEditProfileInfo';

const Information: React.FC = () => {
    const t = useTranslation('profile/doctor/myGeneralInformation');
    const { user, refreshUser } = useContext(UserContext);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const refreshData = () => {
        setShowModal(false);
        setIsLoading(true);

        refreshUser()
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h2 className="bold">{t.translate("title")}</h2>
                    <EditIcon onClick={() => setShowModal(true)} className='icon action' />
                </Fragment>
            }
            body={isLoading
                ? <Loader />
                : <Fragment>
                    <div className='flex-row full-width flex-wrap'>
                        <div id='profile-picture-container'>
                            <EditProfilePicture />
                        </div>
                        <div id='information-personnel' className='flex-row flex-wrap full-width space-center'>
                            <div className='col-data flex-column flex-grow'>
                                <InformationWrapper
                                    libelle={t.translate("name")}
                                    value={`${user?.doctorInfo?.lastname} ${user?.doctorInfo?.firstname}`}
                                />
                                <InformationWrapper
                                    libelle={t.translate("genre")}
                                    value={user?.doctorInfo?.gender?.name
                                        ? t.translateGender(user?.doctorInfo?.gender.name)
                                        : undefined
                                    }
                                />
                            </div>
                            <div className='col-data flex-column flex-grow'>
                                <InformationWrapper
                                    libelle={t.translate("birthdate")}
                                    value={user?.doctorInfo?.birthDate ? moment(new Date(user.doctorInfo.birthDate)).format('DD/MM/YYYY') : undefined}
                                />
                                <InformationWrapper
                                    libelle={t.translate("clinical")}
                                    value={user?.doctorInfo?.clinic}
                                />
                            </div>
                        </div>
                    </div>
                    <ModalEditProfileInfo
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        handleSuccess={() => refreshData()}
                        user={user}
                    />
                </Fragment>
            }
        />
    )
}

export default Information