import { CheckIcon, CloseIcon } from "components";
import { IFormDashboardTextArea } from "interfaces/Form/IFormDashboardTextArea";
import React, { CSSProperties } from "react";

const DashboardTextArea: React.FC<(IFormDashboardTextArea)> = ({
    placeholder,
    name,
    readOnly,
    disabled = false,
    required = false,
    backgroundColor = "transparent",
    defaultValue,
    value,
    setValue,
    checkFunction,
    checkmark = {
        displayed: false
    },
}) => {
    const invalidDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'none',
        MozAppearance: 'textfield'
    }

    const validDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'none',
        MozAppearance: 'textfield'
    }

    const checkmarkDefaultStyle: CSSProperties = {
        position: 'absolute',
        top: 0,
        right: '10px',
        marginTop: '12px'
    }

    const checkInvalid = () => {
        if (!value || value.length === 0) {
            return false;
        } else {
            if (checkFunction && !checkFunction()) {
                return true;
            } else {
                return false;
            }
        }
    }

    const shouldDisplay = () => {
        if (!value) return false;
        
        if (value.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <textarea
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                rows={5}
                required={required}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={value}
                onChange={setValue}
                style={
                    shouldDisplay() && checkInvalid()
                        ? invalidDashboardInput
                        : validDashboardInput
                }
                className={
                    shouldDisplay() && checkInvalid()
                        ? 'dashboardTextArea-invalid'
                        : 'dashboardTextArea'
                }
            />
            {
                (shouldDisplay() && (!disabled && checkmark.displayed)) && (
                    checkInvalid()
                        ? (checkmark.invalid?.component
                            ? <checkmark.invalid.component
                                color={checkmark.invalid.color ? checkmark.invalid.color : 'var(--red)'}
                                className={checkmark.invalid.className ? checkmark.invalid.className : 'icon-check'}
                                style={checkmarkDefaultStyle}
                            />
                            : <CloseIcon
                                size={16}
                                color={`var(--red)`}
                                className="icon-check"
                                style={checkmarkDefaultStyle}
                            />
                        )
                        : (checkmark.valid?.component
                            ? <checkmark.valid.component
                                color={checkmark.valid.color ? checkmark.valid.color : 'var(--dashboard-main-color)'}
                                className={checkmark.valid.className ? checkmark.valid.className : 'icon-check'}
                                style={checkmarkDefaultStyle}
                            />
                            : <CheckIcon
                                size={16}
                                className="icon-check"
                                style={checkmarkDefaultStyle}
                            />
                        )
                )
            }
        </div>
    )
}

export default DashboardTextArea;