import img from 'assets/Help/analysis.svg';
import React from 'react';
import { useTranslation } from 'services';

const DSFourthPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2>{t.translate('doctor.missions.title')}</h2>
            <div className="help-step-content">
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '90%' }}>
                    <p><img src={img} className='help-img' alt="Sample analysis" style={{ float: 'right' }} />
                        {t.translate('doctor.missions.first')}
                        <br/>{t.translate('doctor.missions.second')}
                        <br/>{t.translate('doctor.missions.third')}
                        <br/>{t.translate('doctor.missions.fourth')}
                        <br/>{t.translate('doctor.missions.fifth')}
                        <br/>{t.translate('doctor.missions.sixth')}
                        <br/>{t.translate('doctor.missions.seventh')}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DSFourthPage;