import * as d3 from 'd3';
import { IChartData } from 'interfaces/Services/ChartService/IChartData';
import IDrawChartParams from 'interfaces/Services/ChartService/IDrawChartParams';
import IInternChartData from 'interfaces/Services/ChartService/IInternChartData';

export const chartCalculatePercentage = (data: IChartData, list: IChartData[]): string => {
    return ((data.value * 100)/list.reduce((partialSum, a) => partialSum + a.value, 0)).toFixed(2);
}

export const chartHandleColor = (data: IChartData, list: IChartData[]): string => {
    const colors = [ "#000000", "#330000", "#003300", "#000033", "#333333", "#770000", "#007700", "#000077", "#777777", "#BB0000", "#00BB00", "#0000BB", "#BBBBBB", "#FF0000", "#00FF00", "#0000FF", "#FFFFFF" ];
    
    const sorted = list.sort((a, b) => a.value > b.value ? 1 : -1);

    return data?.color ? data.color : colors[sorted.indexOf(data) % list.length];
}

export const chartHandleLabel = (data: IChartData, list: IChartData[],  params?: IDrawChartParams): string => {
    let res = '';

    if (params?.displayCaption) {
        if (params?.displayLabel) res += ` ${data.label}`

        if (params?.displayPercentage) {
            res += params.displayLabel ?
                ` (${chartCalculatePercentage(data, list)} %)` :
                ` ${chartCalculatePercentage(data, list)} %`;
        }
    }
    
    return res.trim();
}

const handleRadius = (data: IChartData[], thisOne: IInternChartData, sectionHovered?: IChartData, params?: IDrawChartParams): number => {
    const maxWidth = 250;
    const maxWidthValue = data.reduce((cur, val) => Math.max(cur, val.value), 0);

    let resWidth = maxWidth;

    if (params?.customWidth) {
        resWidth = maxWidth - (maxWidthValue - thisOne.value)
    }
    
    if (sectionHovered === thisOne.data) {
        resWidth += 10;
    }

    return resWidth;
}

const handleInnerRadius = (type: 'pie' | 'donut', params?: IDrawChartParams, sectionHovered?: boolean): number => {
    // type === "donut" ? (params?.customInnerRadius ? params.customInnerRadius : 100) : 0

    let resInnerRadius = 0;

    if (type === "donut") {
        resInnerRadius = params?.customInnerRadius ? params.customInnerRadius : 100;
    }

    if (sectionHovered) {
        resInnerRadius += 10;
    }

    return resInnerRadius;
}

const drawChart = (
    type: 'pie' | 'donut',
    element: React.MutableRefObject<null>,
    data: IChartData[],
    params?: IDrawChartParams,
    sectionHovered?: IChartData,
    boxSize: number = 600
): void => {
    d3.select(element).select('svg').remove();

    const svg = d3
        .select(element)
        .append('svg')
        .attr('preserveApectRatio', 'xMidYMid meet')
        .attr('height', '100%')
        .attr('width', '100%')
        .attr('viewBox', `0 0 ${boxSize} ${boxSize}`)
        .append('g')
        .attr('transform', `translate(${boxSize / 2}, ${boxSize / 2})`);

    const arcGenerator = d3.arc()
        .cornerRadius(params?.cornerRadius ? params?.cornerRadius : 0)
        .padAngle(params?.padAngle ? params?.padAngle : 0)
        .innerRadius((d: IInternChartData) => handleInnerRadius(type, params, sectionHovered === d.data))
        .outerRadius((d: IInternChartData) => handleRadius(data, d, sectionHovered, params));
     
    const pieGenerator = d3.pie()
        .startAngle(params?.startAngle ? params?.startAngle * Math.PI: 0)
        .value((d: IInternChartData) => d.value);
    const arcs = svg.selectAll().data(pieGenerator(data)).enter();

    arcs
        .append('path')
        .attr('d', arcGenerator)
        .style('fill', (d: IInternChartData, _: number) => chartHandleColor(d.data, data))
        .transition()
        .duration(params?.transition ? 700 : 0)
        .attrTween('d', (d: IInternChartData) => {
            const i = d3.interpolate(d.startAngle, d.endAngle);
            return (t: any) => {
                d.endAngle = i(t);
                return arcGenerator(d);
            }
        });

    arcs
        .append('text')
        .attr('text-anchor', 'middle')
        .text((d: IInternChartData) => d.value > 0 ? chartHandleLabel(d.data, data, params) : '')
        .style('fill', params?.fontColor ? params.fontColor : '#fff')
        .style('font-size', '30px')
        .attr('transform', (d: IInternChartData) => {
            const [ x, y ] = arcGenerator.centroid(d);
            return `translate(${x}, ${y})`;
        });

    arcs.selectAll('path')
        .on('mouseover', function (_: any, d: IInternChartData) {
            if (params?.setSectionHovered) {
                params.setSectionHovered(d.data);
            }
        })
        .on('mouseout', function () {
            if (params?.setSectionHovered) {
                params.setSectionHovered(undefined);
            }
        })

    if (params?.innerBorder) {
        svg
            .append('circle')
            .attr('cx', 0)
            .attr('cy', 0)
            .attr('r', 100)
            .attr('stroke', '#143F8A')
            .attr('fill', 'transparent')
            .transition()
            .duration(700)
            .attr('stroke-width', 6);
    }
}

export default drawChart;