import cardLock from 'assets/icons/card_lock.svg';
import banner from 'assets/order_box_banner.png';
import { Loader } from 'components';
import 'components/OrderKit/scss/OrderKit.scss';
import { MenuStatusContext } from "context/MenuStatusContext";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import orderService from "services/order.service";
import translationService from "services/translation.service";

const TS = 'dashboard/company/order_kit.';

const SubscriptionHandling: React.FC = () => {
    const { id } = useParams<({ id: string })>();
    const { type } = useContext(UserTypeContext);
    const { reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const { refreshMenuStatus } = useContext(MenuStatusContext);

    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();
    const [ success, setSuccess ] = useState<string>();
    
    useEffect(() => {
        setTimeout(() => {
            orderService.handleNewMonthlyOrder(id)
                .then(() => {
                    reloadSubscriptionTrackingDetail();
                    refreshMenuStatus();
                    setSuccess('Successfuly added all invoices');
                })
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        }, 5000);
    }, [id]);

    return (
        <div className="page-wrapper">
            <div className="order-dashboard flex-column">
                <div className="order-dashboard-banner">
                    <img src={banner} alt="Subscription banner" />
                    <h2 className="text-uppercase MavenPro-black">
                        {translationService.translate(TS+'title.first')}
                        <br />
                        {translationService.translate(TS+'title.second')}
                    </h2>
                    <hr />
                </div>
                <div className="flex-column" style={{ padding: '30px' }}>
                    {isLoading
                        ? <React.Fragment>
                            <div className="flex-row MavenPro-bold">
                                <img src={cardLock} width={30} height={30} alt="Card lock" />
                                <p>{translationService.translate(TS+'resulting.securePayment')}</p>
                            </div>
                            <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{translationService.translate(TS+'resulting.littleMore')}</h2>
                            <p style={{ textAlign: 'center' }}>
                                {translationService.translate(TS+'resulting.fewChecks')}
                                <br />
                                <span style={{ fontWeight: 'bold' }} >
                                    {translationService.translate(TS+'resulting.noLonger')}
                                </span>
                            </p>
                            <Loader loaderDescription={translationService.translate(TS+'resulting.loading')} />
                        </React.Fragment>
                        : error
                            ? <React.Fragment>
                                <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{translationService.translate(TS+'resulting.error.title')}</h2>
                                <p>{translationService.translate(TS+'resulting.error.msg')}</p>
                                <div className="flex-row space-evenly">
                                    <button className="dashboardBtn text-uppercase" onClick={() => history.push('/contact-us')}>{translationService.translate(TS+'resulting.error.contact-us')}</button>
                                    <button className="dashboardBtn text-uppercase" onClick={() => history.push('/order')}>{translationService.translate(TS+'resulting.error.retry')}</button>
                                </div>
                            </React.Fragment>
                            : success
                                ? (type.isCompany()
                                    ? <React.Fragment>
                                        <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{translationService.translate(TS+'resulting.success.title')}</h2>
                                        <p>{translationService.translate(TS+'resulting.success.company.msg')}</p>
                                        <p>{translationService.translate(TS+'resulting.success.company.msg2')}</p>
                                        <button className="dashboardBtn text-uppercase" onClick={() => history.push('/account/employees')}>{translationService.translate(TS+'resulting.success.buttons.employees')}</button>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{translationService.translate(TS+'resulting.success.title')}</h2>
                                        <p>{translationService.translate(TS+'resulting.success.default.msg')}</p>
                                        <p>{translationService.translate(TS+'resulting.success.default.msg2')}</p>
                                        <div className="flex-row flex-wrap">
                                            <button className="dashboardBtn text-uppercase" onClick={() => history.push('/account/offer-kit')}>{translationService.translate(TS+'resulting.success.buttons.offer')}</button>
                                            <button className="dashboardBtn text-uppercase" onClick={() => history.push('/account/kit/register-your-kit')}>{translationService.translate(TS+'resulting.success.buttons.claim')}</button>
                                        </div>
                                    </React.Fragment>)
                                : <React.Fragment>
                                    <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{translationService.translate(TS+'resulting.safezone.title')}</h2>
                                    <p>{translationService.translate(TS+'resulting.safezone.msg')}</p>
                                    <button className="dashboardBtn text-uppercase" onClick={() => history.push('/account/home')}>{translationService.translate(TS+'resulting.safezone.btn')}</button>
                                </React.Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default SubscriptionHandling;