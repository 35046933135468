import { Button, InformationWrapper, MailIcon, PhoneIcon } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { IDUser } from 'interfaces/Database';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import '../scss/Contact.scss';

const Contact: React.FC<({
    patient?: IDUser
})> = ({ patient }) => {
    const history = useHistory();
    const t = useTranslation('patientDetail');

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate('contactPatient.title')}</h1>}
            body={
                <Fragment>
                    <div className='flex-row-start full-width' style={{ boxSizing: 'border-box', padding: "0 4vw" }}>
                        <PhoneIcon />
                        <InformationWrapper
                            libelle={t.translateFormField('phone.label')}
                            value={patient?.phone?.number}
                            alignStart
                        />
                    </div>
                    <div className='flex-row-start full-width' style={{ boxSizing: 'border-box', padding: "0 4vw" }}>
                        <MailIcon />
                        <InformationWrapper
                            libelle={t.translateFormField('email.label')}
                            value={patient?.email}
                            alignStart
                        />
                    </div>
                </Fragment>
            }
            footer={
                <Button label={t.translate('contactPatient.messaging')} onClick={() => history.push('/account/messaging', { openConversationWithPatient: patient?.id })} />
            }
        />
    )
}

export default Contact