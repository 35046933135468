import { UserTypeContext } from "context/User/UserTypeContext";
import React, { Fragment, useContext } from "react";
import CompanyDashboardHomePage from "./CompanyDashboardHomePage";
import DoctorDashboardHomePage from "./DoctorDashboardHomePage";
import PatientDashboardHomePage from "./PatientDashboardHomePage";
import StandaloneDashboardHomePage from "./StandaloneDashboardHomePage";

const DashboardHomePage: React.FC = () => {
    const { type } = useContext(UserTypeContext);

    const renderUserTypeSpecificPage = () => {
        switch (true) {
            case type.isCompany():
                return <CompanyDashboardHomePage />;
            case type.isDoctor():
                return <DoctorDashboardHomePage />;
            case type.isStaff():
            case type.isPatient():
                return <PatientDashboardHomePage />;
            case type.isStandalone():
                return <StandaloneDashboardHomePage />;
            default:
                return null;
        }
    }

    return (
        <Fragment>
            {renderUserTypeSpecificPage()}
        </Fragment>
    )
}

export default DashboardHomePage;