import urinary_img from 'assets/science/urinary.webp';
import React from 'react';
import { useTranslation } from 'services';
import '../scss/Urinary.scss';

const Urinary: React.FC = () => {
    const t = useTranslation('science');

    return (
        <div className="urinaryWrapper">
            <div id="urinary"></div>
            <div className="urinaryWrapper-title">
                <div className="urinaryWrapper-title-number">
                    <div>
                        <p className="MavenPro-bold text-gradient">4</p>
                    </div>
                </div>
                <div className="urinaryWrapper-title-content">
                    <p className="urinaryWrapper-title-content-main font-title-bold text-gradient">{t.translate('urinary.title')}</p>
                    <p className="MavenPro-black urinaryWrapper-title-content-sub text-uppercase">{t.translate('urinary.sub.first')}<br/>{t.translate('urinary.sub.second')}</p>
                </div>
            </div>
            <div className="urinaryWrapper-banner">
                <img
                    width="100%" height="auto"
                    src={urinary_img}
                    alt="Unrinary sample with test strip"
                    className=""
                />
                <hr />
            </div>
            <div className="urinaryWrapper-content">
                <p className="MavenPro-black text-uppercase">{t.translate('urinary.precontent')}</p>
                <p className="MavenPro urinaryWrapper-content-detail">{t.translate('urinary.content')}</p>
            </div>
            <a className="gradient-button urinaryWrapper-button" href='/urinary_report.pdf' target='_blank' rel='noopener noreferrer'>
                <div>
                    <p className="MavenPro-bold text-gradient text-uppercase">{t.translate('urinary.button')}</p>
                </div>
            </a>
        </div>
    )
}

export default Urinary;