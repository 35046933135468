import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import React, { useCallback, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import surveyService from "services/survey.service";
import translationService from "services/translation.service";
import "./styles/SurveyResume.scss";

const SurveyResume: React.FC = () => {
    const questionNumber = 58;
    const t = useTranslation('dashboard/patient/home');
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();
    const [ surveyScore, setSurveyScore ] = useState<number>(0);

    const fetchSurveyResume = useCallback(() => {
        setIsLoading(true);

        surveyService.getSurveyOverview()
            .then((res) => setSurveyScore((res.currentStep ?? 0) + 1))
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchSurveyResume();
    }, [fetchSurveyResume]);

    const handleRenderButton = () => {
        if (surveyScore >= questionNumber) {
            return (
                <Button
                    label={t.translate('suvey-overview.button-display-label')}
                    onClick={() => history.push("/account/questionnaire/overview")}
                />
            )
        } else if (surveyScore && (surveyScore > 1)) {
            return (
                <Button
                    label={t.translate('suvey-overview.button-finish-label')}
                    onClick={() => history.push("/account/kit/questionnaire")}
                />
            )
        } else {
            return (
                <Button
                    label={t.translate('suvey-overview.button-start-label')}
                    onClick={() => history.push("/account/kit/questionnaire")}
                />
            )
        }
    }

    return (
        <DefaultContainer
            title={
                <h1 className="container-title bold">
                    {t.translate('suvey-overview.title')}
                </h1>
            }
            body={isLoading
                ? <Loader />
                : error
                    ? <i className="text-error">{translationService.translateAPIError(error)}</i>
                    : <div className="flex-row" id="circular-progressbar-container" style={{ margin: '20px 0' }}>
                        {surveyScore && (surveyScore > 1) &&
                            <div id="circular-progressbar" className="flex-column">
                                <CircularProgressbar
                                    value={((surveyScore >= questionNumber ? questionNumber : surveyScore) / questionNumber) * 100}
                                    text={`${surveyScore >= questionNumber ? questionNumber : surveyScore}/${questionNumber}`}
                                    styles={buildStyles({
                                        textColor: '#000',
                                        trailColor: 'var(--light-gray2)',
                                        pathColor: `var(--dashboard-main-color)`,
                                    })}
                                    className="MavenPro-bold"
                                />
                            </div>
                        }
                        {handleRenderButton()}
                    </div>
            }
        />
    );
}

export default SurveyResume;