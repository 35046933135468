import React, { createContext, useState } from "react";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import userAuthService from "services/user-auth.service";

type MenuStatusContextProps = {
    menuStatus: IMenuStatus
    setMenuStatus: (menuStatus: IMenuStatus) => void
    refreshMenuStatus: () => Promise<IMenuStatus>
}

export const defaultMenuStatus: IMenuStatus = {};

export const MenuStatusContext = createContext<MenuStatusContextProps>({
    menuStatus: defaultMenuStatus,
    setMenuStatus: (menuStatus: IMenuStatus) => undefined,
    refreshMenuStatus: async () => await userAuthService.getMenuStatus()
});

export const MenuStatusProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const [ menuStatus, setMenuStatus ] = useState<IMenuStatus>(defaultMenuStatus);

    const refreshMenuStatus = async () => {
        return await userAuthService.getMenuStatus()
            .then((res) => {
                setMenuStatus(res);
                return res;
            })
            .catch((err) => err);
    }

    return (
        <MenuStatusContext.Provider value={{ menuStatus, setMenuStatus, refreshMenuStatus }}>
            {children}
        </MenuStatusContext.Provider>
    )
}