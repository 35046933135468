import React, { useEffect, useState } from 'react';
import dietService from "services/diet.service";
import "./scss/Food.scss";
import translationService from "services/translation.service";

import default_img from 'assets/foods/default_food.png';

const Food: React.FC<({
    name: string,
    category?: string,
    color?: string,
    handleClick: () => void,
    description?: string
})> = ({
    name,
    category,
    color,
    handleClick,
    description
}) => {
    const [ foodImage, setFoodImage ] = useState();

    useEffect(() => {
        const loadImage = async () => {
            dietService.getFoodImage(name)
                .then((res) => setFoodImage(res))
                .catch(() => setFoodImage(default_img.default));
        }

        loadImage();
    }, [name]);

    return (
        <div className="food row">
            <div className="food-img-wrapper" style={{ backgroundColor: color }}>
                <img src={foodImage} alt={name} className="food-img" />
            </div>
            <div className="column food-info-wrapper">
                <span className="food-name MavenPro-black">
                    {translationService.translateFood(name)}
                </span>
                <span className="MavenPro-black food-category">
                    {category}
                </span>
                <span className="food-desc">
                    {description}
                </span>
            </div>
        </div>
    );
}

export default Food;