import { Button } from "components";
import { EditAddress } from "components/common/Address";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from "components/common/Modal";
import { IDatabaseAddress } from "interfaces/Database/IDatabaseAddress";
import { IAddress, defaultAddress } from "interfaces/User/IAddress";
import React, { useEffect, useState } from "react";
import { useTranslation } from "services";
import addressService from "services/address.service";

const CompanyDetailModal: React.FC<({
    isShow: boolean;
    toggleIsShow: () => void;
    billingAddress?: IDatabaseAddress;
    refreshAddress: () => void;
})> = ({
    isShow,
    toggleIsShow,
    billingAddress,
    refreshAddress
}) => {
    const t = useTranslation('dashboard/company/profile');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ success, setSuccess ] = useState<boolean>(false);

    const [ newBillingAddress, setNewBillingAddress ] = useState<IAddress>(defaultAddress);

    useEffect(() => {
        setNewBillingAddress(addressService.databaseAddressToLocal(billingAddress));
    }, [billingAddress]);

    useEffect(() => {
        if (isShow) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ isShow ]);

    const handleClose = () => {
        setError(undefined);
        setIsLoading(false);
        setNewBillingAddress(defaultAddress);

        if (success) {
            setSuccess(true);
        }

        toggleIsShow();
    }

    const handleSubmitCompanyDetail = () => {
        setIsLoading(true);
        setError(undefined);

        addressService.updateAddresses({
            billing: newBillingAddress,
            sameAddresses: true,
        })
            .then(() => {
                refreshAddress();
                toggleIsShow();
            })
            .catch((e) => setError(e.message ? e.message : t.translate('detail.modal.error')))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={isShow}
            toggleShow={toggleIsShow}
            title={t.translate('detail.modal.title')}
            footer={
                <div className="flex-row full-width">
                    <Button
                        label={t.translate('detail.modal.address.update')}
                        onClick={() => handleSubmitCompanyDetail()}
                        disabled={isLoading}
                        displayLoader={isLoading}
                    />
                </div>
            }
        >
            {success
                ? <div>
                    <p>{t.translate('detail.modal.success')}</p>
                    <Button label={t.translate('detail.modal.leave')} disabled={!success} onClick={() => handleClose()} />
                </div>
                : <div>
                    <EditAddress isLoading={isLoading} address={newBillingAddress} setAddress={setNewBillingAddress} />

                    <ErrorMessage error={error} />
                </div>
            }
        </Modal>
    )
}

export default CompanyDetailModal;