import banner from 'assets/order_box_banner.png';
import { Button, Label, LabelInput, Loader, Phone, Select } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IDatabaseSubscriptionPlan } from "interfaces/Database/IDatabaseSubscriptionPlan";
import { IDatabaseSubscriptionPlanType } from "interfaces/Database/IDatabaseSubscriptionPlanType";
import { isEqual } from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";
import verifierService from "services/verifier.service";

const OfferKit: React.FC = () => {
    const t = useTranslation('pages/offerKit');
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<"load_data" | "submit" | undefined>('load_data');
    const [ success, setSuccess ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const [ firstName, setFirstName ] = useState<string>();
    const [ lastName, setLastName ] = useState<string>();
    const [ email, setEmail ] = useState<string>();
    const [ countryCode, setCountryCode ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();

    const [ availableSolutions, setAvailableSolutions ] = useState<IDatabaseSubscriptionPlan[]>([]);

    const [ choosedSolutionType, setChoosedSolutionType ] = useState<IDatabaseSubscriptionPlanType>();
    const [ solutionTypes, setSolutionTypes ] = useState<IDatabaseSubscriptionPlanType[]>([]);

    const loadSolutions = useCallback(() => {
        setIsLoading("load_data");

        standaloneService.getAvailableSolutions()
            .then((res: IDatabaseSubscriptionPlan[]) => {
                setAvailableSolutions(res);

                const tempKitTypes: IDatabaseSubscriptionPlanType[] = [];

                res.forEach((plan) => (plan.type && tempKitTypes.filter((type) => isEqual(type, plan.type)).length === 0) && tempKitTypes.push(plan.type));

                setSolutionTypes(tempKitTypes);

                if (tempKitTypes.length === 1) setChoosedSolutionType(tempKitTypes[0]);
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(undefined));
    }, []);

    useEffect(() => {
        loadSolutions();
    }, [loadSolutions]);

    const handleOfferKit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsLoading("submit");
        setSuccess(false);
        setError(undefined);

        const choosedPlan = availableSolutions.filter((kit) => kit.type === choosedSolutionType)[0];

        if (choosedPlan) {
            await standaloneService.offerPlan({
                firstName: firstName,
                lastName: lastName,
                email: email,
                countryCode: countryCode,
                phoneNumber: phoneNumber,
                planId: choosedPlan.id
            })
                .then(() => setSuccess(true))
                .catch((err) => setError(err.message));
        } else {
            setError('unexpected_error');
        }

        setIsLoading(undefined);
    }

    const reset = () => {
        setIsLoading("load_data");

        setSuccess(false);
        setError(undefined);

        setFirstName(undefined);
        setLastName(undefined);
        setEmail(undefined);
        setCountryCode(undefined);
        setPhoneNumber(undefined);

        loadSolutions();
    }

    return (
        <div className="page-wrapper">
            <div className="order-dashboard" style={{ borderRadius: '10px', overflow: 'hidden' }}>
                <div className="order-dashboard-banner">
                    <img src={banner} alt="Women typing on a laptop" />
                    <h2 className="text-uppercase MavenPro-black">
                        {t.translate('title.first')}
                        <br />
                        {t.translate('title.second')}
                    </h2>
                    <hr />
                </div>
                {isLoading
                    ? <div className="flex-column" style={{ padding: '40px' }}>
                        {isLoading === "load_data" ?
                            <Loader loaderDescription={t.translate('loaders.data')} /> :
                            <Loader loaderDescription={t.translate('loaders.submit')} />
                        }
                    </div>
                    : success
                        ? <div className="flex-column full-width">
                            <div className="flex-column" style={{ padding: '40px', width: '70%' }}>
                                <h2>{t.translate('success.title')}</h2>
                                <p style={{ marginBottom: 0, textAlign: 'center' }}>{t.translate('success.offered')}</p>
                                <p style={{ marginTop: 0, textAlign: 'center' }}>{t.translate('success.receiver_mail')}</p>
                                <div className="flex-row">
                                    <Button label={t.translate('success.offerBtn')} onClick={() => reset()} />
                                    <Button label={t.translate('success.buyBtn')} onClick={() => history.push('/order')} />
                                </div>
                            </div>
                        </div>
                        : <form className="flex-column" onSubmit={handleOfferKit} style={{ width: '70%' }}>
                            {solutionTypes.length > 1
                                ? <Fragment>
                                    <div className="flex-column-start full-width">
                                        <Label for='solutionType' label={t.translate('fields.chooseKit.label')} required />
                                        <Select
                                            name='solutionType'
                                            value={choosedSolutionType}
                                            setValue={(e) => setChoosedSolutionType(solutionTypes.filter((type) => type.id === parseInt(e.currentTarget.value))[0])}
                                            defaultValue="none"
                                            required
                                            options={
                                                <Fragment>
                                                    <option value="none" disabled>{t.translate('fields.chooseKit.placeholder')}</option>
                                                    {solutionTypes.map((type, index) =>
                                                        <option key={`solution-${index}`} value={type.id}>{type?.category?.name ? `${type.category.name} ` : ''}{type.name && t.translateSubscriptionTypeName(type.name)}</option>
                                                    )}
                                                </Fragment>
                                            }
                                        />
                                    </div>
                                    <div style={{ margin: '10px' }}/>
                                </Fragment>
                                : null
                            }
                            <div className="flex-row full-width">
                                <LabelInput
                                    name='lastname'
                                    label={t.translate('fields.lastName')}
                                    required
                                    value={lastName}
                                    setValue={(e) => setLastName(e.currentTarget.value)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                    checkFunction={() => verifierService.checkString(lastName)}
                                />
                                <div style={{ margin: '10px' }}/>
                                <LabelInput
                                    name='firstname'
                                    label={t.translate('fields.firstName')}
                                    required
                                    value={firstName}
                                    setValue={(e) => setFirstName(e.currentTarget.value)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                    checkFunction={() => verifierService.checkString(firstName)}
                                />
                            </div>
                            <div style={{ margin: '10px' }}/>
                            <div className="flex-row full-width">
                                <LabelInput
                                    name='email'
                                    label={t.translate('fields.email')}
                                    required
                                    value={email}
                                    setValue={(e) => setEmail(e.currentTarget.value)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                    checkFunction={() => verifierService.checkEmail(email)}
                                />
                            </div>
                            <div style={{ margin: '10px' }}/>
                            <div className="flex-row full-width">
                                <div className="flex-column-start full-width">
                                    <Label
                                        name='phone'
                                        label={t.translate('fields.phone')}
                                        required
                                    />
                                    <Phone
                                        labelledBy='phone'
                                        required
                                        defaultCountryCode={countryCode}
                                        defaultPhoneNumber={phoneNumber}
                                        setCountryCode={setCountryCode}
                                        setPhoneNumber={setPhoneNumber}
                                        checkmark={{
                                            displayed: true
                                        }}
                                        checkFunction={() => verifierService.checkPhone(countryCode, phoneNumber)}
                                    />
                                </div>
                            </div>

                            <div style={{ margin: '20px' }}/>
                            <ErrorMessage error={error} />
                            <Button type='submit' label={t.translate('submit')} />
                        </form>
                }
            </div>
        </div>
    )
}

export default OfferKit;