import { Loader } from "components";
import VideoMeeting from "components/VideoMeeting/common/VideoMeeting";
import { IPatientDetail } from "interfaces/IPatientDetail";
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import doctorService from "services/doctor.service";

const Doctor: React.FC = () => {
    const { roomId } = useParams<({ roomId: string })>();
    const { patientId } = useParams<({ patientId: string })>();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ meetingPatient, setMeetingPatient ] = useState<IPatientDetail>();

    const fetchPatientResults = useCallback(async () => {
        setIsLoading(true);

        doctorService.getPatientDetails(patientId)
            .then((res) => setMeetingPatient(res))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, [patientId]);

    useEffect(() => {
        fetchPatientResults();
    }, [fetchPatientResults]);

    return isLoading
        ? <Loader />
        : <VideoMeeting
            roomId={roomId}
            side={'doctor'}
            appointmentWith={`${meetingPatient?.result.patientInfo?.firstname} ${meetingPatient?.result.patientInfo?.lastname}`}
            isError={meetingPatient === undefined}
        />
}

export default Doctor;