import { Button, Input, Label, LeftArrowIcon, Loader, Phone, Select, StepIndicator } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import ToolTip from "components/common/ToolTip/ToolTip";
import { UserContext } from "context/User/UserContext";
import moment from "moment-timezone";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";
import stringService from "services/string.service";
import verifierService from "services/verifier.service";
import "../scss/KitRegister.scss";

const Informations: React.FC<({
    handleNextStep: () => void
})> = ({
    handleNextStep
}) => {
    const t = useTranslation('pages/kitRegister');
    const history = useHistory();
    const { user, refreshUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const [ firstname, setFirstname ] = useState<string>();
    const [ lastname, setLastname ] = useState<string>();
    const [ phoneCode, setPhoneCode ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ birthDate, setBirthDate ] = useState<string>();
    const [ weight, setWeight ] = useState<number>();
    const [ size, setSize ] = useState<number>();
    const [ waistSize, setWaistSize ] = useState<number>();
    const [ gender, setGender ] = useState<string>('');

    const [ isValidForm, setIsValidForm ] = useState<boolean>(false);

    const loadUser = useCallback(async () => {
        if (user) {
            return user;
        } else {
            return await refreshUser().then((res) => res).catch((err) => setError(err.message));
        }
    }, [user]);

    const loadUserDatas = useCallback(async () => {
        setIsLoading(true);

        const currentUser = await loadUser();

        if (currentUser) {
            setFirstname(currentUser?.patientInfo?.firstname);
            setLastname(currentUser?.patientInfo?.lastname);
            setPhoneCode(currentUser?.phone?.country?.phonecode?.toString());
            setPhoneNumber(currentUser?.phone?.number);
            setBirthDate(currentUser?.patientInfo?.birthDate && moment(currentUser?.patientInfo?.birthDate).format('YYYY-MM-DD'));
            setWeight(currentUser?.patientInfo?.weight);
            setSize(currentUser?.patientInfo?.size);
            setWaistSize(currentUser?.patientInfo?.waistSize);
            setGender(currentUser?.patientInfo?.gender?.name || '');
        }

        setIsLoading(false);
    }, [loadUser]);

    useEffect(() => {
        loadUserDatas();
    }, [loadUserDatas]);

    const checkForm = () => {
        if (
            verifierService.checkString(firstname, { len: { min: 0 } }) &&
            verifierService.checkString(lastname, { len: { min: 0 } }) &&
            verifierService.checkString(phoneCode, { len: { min: 0 } }) &&
            verifierService.checkString(phoneNumber, { len: { min: 0 } }) &&
            verifierService.checkDate(birthDate) &&
            verifierService.checkNumber(weight, { min: 0 }) &&
            verifierService.checkNumber(size, { min: 0 }) &&
            gender
        ) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }

    useEffect(() => {
        checkForm();
    }, [firstname, lastname, phoneNumber, birthDate, size, weight, waistSize]);

    const handleSaveData = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        setIsLoading(true);
        setError(undefined);

        Promise.all([
            standaloneService.updateContactInformations({
                countryCode: phoneCode,
                phoneNumber: phoneNumber
            }),
            standaloneService.updateInformations({
                firstName: stringService.capitalize(firstname),
                lastName: stringService.capitalize(lastname),
                birthDate: moment(birthDate).format('DD-MM-YYYY'),
                gender: gender,
                weight: weight,
                size: size,
                waistSize: waistSize,
            })
        ])
            .then(() => {
                refreshUser();
                handleNextStep();
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            wrapperClassName="customContainerWrapper"
            title={
                <div className="flex-row-start">
                    <LeftArrowIcon
                        className="clickable"
                        onClick={() => history.push('/account/home')}
                    />
                    <div style={{ margin: '0 1vw' }} className="flex-column-start">
                        <StepIndicator step={2} />
                        <div className="flex-row-start align-items-stretch">
                            <h3>{t.translate('title')}</h3>
                            <span style={{ display: 'grid', alignContent: 'center', color: 'var(--dashboard-main-color)', margin: '0 .5vw' }}>2/3</span>
                        </div>
                    </div>
                </div>
            }
            body={
                <div id="form-wrapper">
                    <div className="flex-column">
                        <h2 id="title" className="MavenPro-black full-width" style={{ textAlign: "center" }}>
                            {t.translate('informations.title')}
                        </h2>
                        <p id="form-description" className="full-width" style={{ textAlign: "center" }}>
                            {t.translate('informations.body')}
                        </p>
                    </div>
                
                    {isLoading
                        ? <Loader />
                        : <Fragment>
                            <div className="form-wrapper-row">
                                <div className="form-wrapper-row-field">
                                    <Label for="firstname" label={t.translateFormField('firstname.label')} required />
                                    <Input
                                        required
                                        name="firstname"
                                        value={firstname}
                                        setValue={(e) => setFirstname(e.currentTarget.value)}
                                        checkFunction={() => verifierService.checkString(firstname)}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                                <div className="form-wrapper-row-field">
                                    <Label for="lastname" label={t.translateFormField('lastname.label')} required />
                                    <Input
                                        required
                                        name="lastname"
                                        value={lastname}
                                        setValue={(e) => setLastname(e.currentTarget.value)}
                                        checkFunction={() => verifierService.checkString(lastname)}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-wrapper-row">
                                <div className="form-wrapper-row-field">
                                    <Label for="email" label={t.translateFormField('email.label')} required />
                                    <Input
                                        disabled
                                        required
                                        name="email"
                                        type="email"
                                        value={user?.email}
                                    />
                                </div>
                                <div className="form-wrapper-row-field">
                                    <Label name="phone" label={t.translateFormField('phone.label')} required />
                                    <Phone
                                        labelledBy="phone"
                                        defaultCountryCode={phoneCode}
                                        setCountryCode={setPhoneCode}
                                        defaultPhoneNumber={phoneNumber}
                                        setPhoneNumber={setPhoneNumber}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-wrapper-row">
                                <div className="form-wrapper-row-field">
                                    <Label for="birthdate" label={t.translateFormField('birthdate.label')} required />
                                    <Input
                                        required
                                        name="birthdate"
                                        type="date"
                                        value={birthDate}
                                        setValue={(e) => setBirthDate(e.currentTarget.value)}
                                        checkFunction={() => verifierService.checkDate(birthDate)}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                                <div className="form-wrapper-row-field">
                                    <Label for="gender" label={t.translateFormField('gender.label')} required />
                                    <Select
                                        required
                                        name="gender"
                                        options={
                                            <Fragment>
                                                <option defaultChecked value="" style={{ fontStyle: 'italic' }} disabled>{t.translateFormField('chooseOption')}</option>
                                                <option value="Male">{t.translateGender('male')}</option>
                                                <option value="Female">{t.translateGender('female')}</option>
                                            </Fragment>
                                        }
                                        value={gender}
                                        setValue={(e) => setGender(e.currentTarget.value)}
                                        checkFunction={() => verifierService.checkString(gender)}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="form-wrapper-row">
                                <div className="form-wrapper-row-field">
                                    <Label for="weight" label={t.translateFormField('weight.label') + " (KG)"} required />
                                    <Input
                                        required
                                        name="weight"
                                        type="number"
                                        value={weight}
                                        min={0}
                                        setValue={(e) => setWeight(e.currentTarget.valueAsNumber)}
                                    />
                                </div>
                                <div className="form-wrapper-row-field">
                                    <Label for="height" label={t.translateFormField('height.label') + " (CM)"} required />
                                    <Input
                                        required
                                        name="height"
                                        type="number"
                                        value={size}
                                        min={0}
                                        setValue={(e) => setSize(e.currentTarget.valueAsNumber)}
                                    />
                                </div>
                            </div>
                            <div className="form-wrapper-row">
                                <div className="form-wrapper-row-field">
                                    <div className="flex-row-start">
                                        <ToolTip>
                                            <p>{t.translate('informations.waistSizeHelp')}</p>
                                        </ToolTip>
                                        <Label for="waist-size" label={t.translateFormField("waistSize.label")} />
                                    </div>
                                    <Input
                                        name="waist-size"
                                        type="number"
                                        value={waistSize}
                                        min={0}
                                        setValue={(e) => setWaistSize(e.currentTarget.valueAsNumber)}
                                    />
                                </div>
                            </div>

                            <ErrorMessage error={error} />
                            <Button label={t.translate('informations.saveBtn')} onClick={(e) => handleSaveData(e)} disabled={!isValidForm || isLoading} style={{ marginTop: '2vh' }} />
                        </Fragment>
                    }
                </div>
            }
            hideSeparator={true}
        />
    )
}

export default Informations;