import { EditIcon, InformationWrapper, Loader } from "components";
import DisplayAddress from "components/common/Address/DisplayAddress";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import { IDAddress } from "interfaces/Database";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import addressService from "services/address.service";
import CompanyDetailModal from "./CompanyDetailModal";

const CompanyDetail: React.FC = () => {
    const t = useTranslation('dashboard/company/profile');
    const { user } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();

    const [ showEdit, setShowEdit ] = useState<boolean>(false);
    const toggleShowEdit = () => setShowEdit(!showEdit);

    const [ currentBillingAddress, setCurrentBillingAddress ] = useState<IDAddress>();

    const toggleIsShow = () => setShowEdit(!showEdit);

    const loadAddresses = useCallback(() => {
        setIsLoading(true);

        addressService.getBillingAndShippingAddresses()
            .then((res) => {
                if (res.billing) setCurrentBillingAddress(res.billing);
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        loadAddresses();
    }, [loadAddresses]);

    return (
        <Fragment>
            <DefaultContainer
                title={
                    <div className='full-width flex-row space-between'>
                        <h2 className='bold'>{t.translate('detail.title')}</h2>
                        <EditIcon onClick={() => toggleIsShow()} className='icon action' />
                    </div>
                }
                body={isLoading
                    ? <Loader />
                    : <Fragment>
                        <div className="full-width flex-row">
                            <div className="information-wrapper" style={{ minWidth: "20%" }}>
                                <InformationWrapper
                                    libelle={t.translate('detail.number')}
                                    value={user?.companyInfo?.registrationNumber}
                                />
                            </div>
                            <div className="information-wrapper" style={{ minWidth: "20%" }}>
                                <InformationWrapper
                                    libelle={t.translate('detail.vat')}
                                    value={user?.companyInfo?.vat}
                                />
                            </div>
                        </div>
                        <div className="full-width flex-column space-between" style={{ marginTop: '20px' }}>
                            <DisplayAddress address={currentBillingAddress} />
                        </div>
                    </Fragment>
                }
            />
            <CompanyDetailModal
                isShow={showEdit}
                toggleIsShow={toggleIsShow}
                billingAddress={currentBillingAddress}
                refreshAddress={loadAddresses}
            />
        </Fragment>
    )
}

export default CompanyDetail;