import { InformationWrapper, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useTranslation } from "services";
import translationService from "services/translation.service";
import "../scss/HealthInformation.scss";
import ModalEditMedicalInfo from "./modal/ModalEditMedicalInfo";

const HealthInformation: React.FC = () => {
    const t = useTranslation('profile/patient/myHealthInformation');
    const { user, refreshUser } = useContext(UserContext);

    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const refreshData = async () => {
        setShowModal(false);
        setIsLoading(true);

        refreshUser()
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    } 

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold">{t.translate("title")}</h1>
                    <FiEdit
                        size={24}
                        className="clickable"
                        color="var(--dashboard-main-color)"
                        onClick={() => setShowModal(true)}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    { isLoading
                        ? <Loader />
                        : <div className="row" id="health-information-wrapper">
                            <div className="information-wrapper">
                                <InformationWrapper
                                    libelle={t.translate("size")}
                                    value={user?.patientInfo?.size}
                                />
                            </div>
                            <div className="information-wrapper">
                                <InformationWrapper
                                    libelle={t.translate("waistSize")}
                                    value={user?.patientInfo?.waistSize}
                                />
                            </div>
                            <div className="information-wrapper">
                                <InformationWrapper
                                    libelle={t.translate("specialMedication")}
                                    value={user?.patientInfo?.specialMedication}
                                />
                            </div>
                            <div className="information-wrapper">
                                <InformationWrapper
                                    libelle={t.translate("weight")}
                                    value={user?.patientInfo?.weight}
                                />
                            </div>
                            <div className="information-wrapper">
                                <InformationWrapper
                                    libelle={t.translate("age")}
                                    value={translationService.translateDate(user?.patientInfo?.birthDate)}
                                />
                            </div>
                            <div className="information-wrapper">
                                <InformationWrapper
                                    libelle={t.translate("specialInformation")}
                                    value={undefined}
                                />
                            </div>
                        </div>
                    }
                    <ModalEditMedicalInfo
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        handleSuccess={() => refreshData()}
                        currentUser={user}
                    />
                </Fragment>
            }
        />
    );
}

export default HealthInformation;