import { IUser } from "interfaces/Database";
import React, { createContext, useState } from "react";
import profilePictureService from "services/profilePicture.service";
import userAuthService from "services/user-auth.service";

type UserContextProps = {
    user: IUser|undefined;
    setUser: (user?: IUser) => void;
    refreshUser: () => Promise<IUser>;
    profilePicture?: string;
    setProfilePicture: (newProfilePicture: string) => void;
    refreshProfilePicture: () => Promise<string | void>;
}

export const UserContext = createContext<UserContextProps>({
    user: undefined,
    setUser: () => undefined,
    refreshUser: async () => await userAuthService.getUser(),
    profilePicture: undefined,
    setProfilePicture: () => undefined,
    refreshProfilePicture: async () => new Promise((resolve) => resolve(''))
});

export const UserProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const [ user, setUser ] = useState<IUser>();
    const [ profilePicture, setProfilePicture ] = useState<string>();

    const refreshProfilePicture = async (): Promise<string | void> => {
        if (user) {
            return await profilePictureService.getUserProfilePicture(user.id)
                .then((res) => {
                    setProfilePicture(res);
                    return res;
                })
                .catch(() => setProfilePicture(undefined));
        } else {
            return new Promise<string>((resolve) => resolve(''))
                .then((res) => {
                    setProfilePicture(res);
                    return res;
                });
        }
    }

    const refreshUser = async (): Promise<IUser> => {
        return await userAuthService.getUser()
            .then((res) => {
                setUser(res);
                return res;
            })
            .catch((err) => Promise.reject(err.message));
    }

    return (
        <UserContext.Provider value={{ user, setUser, refreshUser, profilePicture, setProfilePicture, refreshProfilePicture }}>
            {children}
        </UserContext.Provider>
    )
}