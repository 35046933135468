import { Input } from "components/Form";
import { IDSurveyAnswer } from "interfaces/Database";
import { IQuestion } from "interfaces/Survey/IQuestion";
import React, { useEffect, useState } from "react";
import translationService from "services/translation.service";
import "../scss/Question.scss";

const QuestionSingleChoice: React.FC<({
    question: IQuestion;
    handleValidation: (isValid: boolean) => void;
    handleUserAnswer: (params: (string|number)[]) => void;
    previousAnswers?: IDSurveyAnswer[];
})> = ({
    question,
    handleValidation,
    handleUserAnswer,
    previousAnswers
}) => {
    const [indexSelectedAnswer, setIndexSelectedAnswer] = useState<number>(-1);
    const [ other, setOther ] = useState<string>();

    function selectAnswer(index: number) {
        index === indexSelectedAnswer ?
            setIndexSelectedAnswer(-1) :
            setIndexSelectedAnswer(index);
    }

    useEffect(() => {
        if (previousAnswers && previousAnswers.length > 0) {
            const previousAnswer = previousAnswers[previousAnswers.length-1];
            if (previousAnswer.answer.includes('other')) {
                const answers = question.answers.indexOf('other');
                setIndexSelectedAnswer(answers);
                setOther(previousAnswer.answer.split('other,').join(' ').trim());
            } else {
                const answers = question.answers.indexOf(previousAnswer.answer);
                setIndexSelectedAnswer(answers);
            }
        }
    }, [previousAnswers]);

    useEffect(() => {
        if (indexSelectedAnswer !== -1) {
            handleUserAnswer([(question.answers[indexSelectedAnswer] !== 'other') ? question.answers[indexSelectedAnswer] : `${question.answers[indexSelectedAnswer]}, ${other}`]);
        }
    }, [indexSelectedAnswer, other]);

    useEffect(() => {
        handleValidation(indexSelectedAnswer !== -1);
    }, [indexSelectedAnswer, question.answers, handleValidation, handleUserAnswer]);

    return (
        <div className="question-wrapper">
            <h2>{translationService.translateSurveyQuestion(question.code)}</h2>
            {question.description && <span id="question-description">{translationService.translateSurveyDescription(question.code)}</span>}
            <div className="flex-row flex-wrap" id="answers-container">
                {question.answers.map((answer, index) =>
                    <div
                        key={index}
                        className={index === indexSelectedAnswer ? "answer-selected" : "answer"}
                        onClick={() => selectAnswer(index)}
                    >
                        <p key={index}>{translationService.translateSurveyAnswer(question.code, answer)}</p>
                    </div>
                )}
            </div>
            
            {!(indexSelectedAnswer && indexSelectedAnswer !== -1 && question.answers[indexSelectedAnswer] === 'other')
                || <Input name="other" value={other} setValue={(e) => setOther(e.currentTarget.value)} wrapperStyle={{ maxWidth: '450px', margin: '4vh', boxSizing: 'border-box' }} />
            }
        </div>
    );
}

export default QuestionSingleChoice;