import { IFormDashboardTimeInput } from "interfaces/Form/IFormDashboardTimeInput";
import { Fragment } from "react";
import { useTranslation } from "services";
import DashboardSelect from "./DashboardSelect";

const DashboardTimeInput: React.FC<IFormDashboardTimeInput> = (props) => {
    const t = useTranslation('various/formFields');

    return (
        <div
            className="flex-row flex-gap-col"
            aria-label={props.name}
            style={{
                padding: 0
            }}
        >
            <DashboardSelect
                name={props.name + '-hours'}
                value={props.hours}
                setValue={props.setHours}
                options={
                    <Fragment>
                        <option disabled={!!props.hours}>{t.translate('chooseHour')}</option>
                        {Array.from({
                            length: 24
                        }, (_, i) => i).map((hour, index) =>
                            <option key={`input-hour-${index}`} value={hour}>{hour}h</option>
                        )}
                    </Fragment>
                }
            />
            <DashboardSelect
                name={props.name + '-minutes'}
                value={props.minutes}
                setValue={props.setMinutes}
                options={
                    <Fragment>
                        <option disabled={!!props.minutes}>{t.translate('chooseMinute')}</option>
                        {Array.from({
                            length: 60
                        }, (_, i) => i).map((minute, index) =>
                            <option key={`input-minute-${index}`} value={minute}>{minute}</option>
                        )}
                    </Fragment>
                }
            />
        </div>
    )
}

export default DashboardTimeInput;