import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { ICustomProductOrder } from "interfaces/ICustomProductOrder";
import moment from "moment-timezone";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import fileService from "services/file.service";
import orderService from "services/order.service";

export interface IChoosedMonth {
    month?: number
    year?: number
}

const CompanyLastInvoices: React.FC = () => {
    const t = useTranslation('dashboard/company/home');
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isGenerating, setIsGenerating ] = useState<boolean>(false);
    const [ reloadingPages, setReloadingPages ] = useState<boolean>(true);
    const [ choosedOrder, setChoosedOrder ] = useState<ICustomProductOrder>();

    const [ productOrders, setProductOrders ] = useState<ICustomProductOrder[]>([]);

    const fetchData = useCallback((withSummary=false) => {
        setReloadingPages(true);
        setIsLoading(true);

        const requests = [
            orderService.getLastOrders()
                .then((res) => setProductOrders(res))
        ]

        Promise.all(requests)
            .catch((err) => console.warn(err))
            .finally(() => {
                setReloadingPages(false);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const fetchOrder = (order: ICustomProductOrder) => {
        setIsGenerating(true);
        setChoosedOrder(order);

        orderService.getOrderPdf(order.id.toString())
            .then((response) => fileService.downloadFile(response.base64, { filename: `order_${order.id}` }))
            .catch((err) => console.warn(err.message))
            .finally(() => {
                setIsGenerating(false);
                setChoosedOrder(undefined);
            });
    }

    const handleStripePdf = (order: ICustomProductOrder) => {
        if (order.stripePdfLink) {
            const a = document.createElement('a');
            a.download = `stripe_invoice_${order.id}.pdf`;
            a.href = order.stripePdfLink;
            a.click();
        }
    }

    const displayContent = () => {
        if (productOrders.length === 0) {
            return (
                <Fragment>
                    <p>{t.translate('lastInvoices.noKit')}</p>
                    <p>{t.translate('lastInvoices.canBuy')}</p>
                    <Button onClick={() => history.push('/order')} label={t.translate('lastInvoices.btn')} />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div id="tous-mes-rdv">
                        <table id='data-table' className='full-width accountOrder-wrapper-table'>
                            <thead className='accountOrder-wrapper-table-header'>
                                <tr>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.number')}</th>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.date')}</th>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.quantity')}</th>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.purchaseOrder')}</th>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.stripeInvoice')}</th>
                                </tr>
                            </thead>
                            <tbody className='accountOrder-wrapper-table-body align-items-stretch'>
                                {productOrders.map((productOrder, index) =>
                                    <tr key={index} className='data-row accountOrder-wrapper-table-body-row'>
                                        <td><p className="MavenPro-bold">#{productOrder.id}</p></td>
                                        <td><p className="MavenPro">{moment(productOrder.createdOn).format('DD/MM/YYYY')}</p></td>
                                        <td>
                                            <p className={`MavenPro ${!productOrder?.plans || !productOrder?.plans[0]?.type?.name ? 'no-data' : '' }`}>
                                                {productOrder?.plans?.length} plan{(productOrder?.plans?.length ?? 0) > 1 ? 's' : ''} {productOrder?.plans && productOrder?.plans[0]?.type?.name ? t.translateSubscriptionTypeName(productOrder.plans[0].type.name) : t.translateFromFile('common', 'no-data')}
                                            </p>
                                        </td>
                                        <td>
                                            <Button
                                                label={t.translate('orders.download')}
                                                onClick={() => fetchOrder(productOrder)}
                                                disabled={isGenerating}
                                                displayLoader={choosedOrder?.id === productOrder?.id}
                                            />
                                        </td>
                                        <td>
                                            {productOrder.stripePdfLink
                                                ? <Button
                                                    label={t.translate('orders.download')}
                                                    onClick={() => handleStripePdf(productOrder)}
                                                />
                                                : <p className='no-data'>{t.translate('orders.stripeInvoiceUnavailable')}</p>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {/* <InvoiceTable
                            columns={[t.translate('lastInvoices.year'), t.translate('lastInvoices.month'), t.translate('lastInvoices.status'),'']}
                            invoices={invoices}
                            handleDownload={handleDownload}
                            pdfGenerating={pdfGenerating}
                            choosedInvoice={choosedInvoice}
                        /> */}
                    </div>
                    <Button onClick={() => history.push('/account/invoicing')} label={t.translate('lastInvoices.seeAll')} />
                </Fragment>
            )
        }
    }

    return (
        <DefaultContainer
            title={<h2 className="bold">{t.translate('lastInvoices.title')}</h2>}
            body={
                isLoading
                    ? <Loader />
                    : displayContent()
            }
        />
    )
}

export default CompanyLastInvoices;