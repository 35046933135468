import React from 'react';
import { CountriesProvider } from "./CountriesContext";
import { LanguageProvider } from "./Language/LanguageContext";
import { MenuStatusProvider } from "./MenuStatusContext";
import { RegistrationTypesProvider } from './RegistrationTypesContext';
import { SubscriptionTrackingDetailProvider } from './SolutionProcessingContext';
import { UserProvider } from "./User/UserContext";
import { UserTypeProvider } from "./User/UserTypeContext";

const CustomProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    return (
        <LanguageProvider>
            <CountriesProvider>
                <UserTypeProvider>
                    <RegistrationTypesProvider>
                        <UserProvider>
                            <MenuStatusProvider>
                                <SubscriptionTrackingDetailProvider>
                                    {children}
                                </SubscriptionTrackingDetailProvider>
                            </MenuStatusProvider>
                        </UserProvider>
                    </RegistrationTypesProvider>
                </UserTypeProvider>
            </CountriesProvider>
        </LanguageProvider>
    )
}

export default CustomProvider;