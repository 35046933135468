import chroma from "chroma-js";
import "components/CookiePolicy/scss/CookiePolicy.scss";
import React from "react";
import { useTranslation } from "services";

const CookiePolicy: React.FC = () => {
    const t = useTranslation('cookies-policy');
    const list = ["auth", "security", "performance", "analytics", "preferences", "socialMedia", "references", "advertising", "marketing"];

    const gradient = chroma.scale(["#0099ff", '#65eb00']);

    const renderTypesCookies = list.map((cookie, index) => 
        <div key={index} className="row cookie-type-wrapper">
            <div className="cookie-category-wrapper cookie-category column">
                <span>
                    {t.translate(`type.${cookie}.category`)}
                </span>
            </div>
            <div className="cookie-objective-wrapper cookie-objective column">
                <span>
                    {t.translate(`type.${cookie}.objective`)}
                </span>
            </div>
            <div className="cookie-desc-wrapper cookie-description column">
                <span className="description-desc">
                    {t.translate(`type.${cookie}.description`)}
                </span>
            </div>
        </div>
    )

    return (
        <div id="cookie-policy" className="column">
            <div className="column text-wrapper">
                <h1 className="font-title-bold title">
                    {t.translate('title')}
                </h1>
                <p>{t.translate('usage')}</p>
                <p>{t.translate('desc')}</p>
                <p>{t.translate('aboutLaw')}</p>
                <p>{t.translate('range')}</p>
            </div>
            <div className="column text-wrapper">
                <h1 className="font-title-bold sub-title" style={{ color: gradient(0 / 1).hex() }}>
                    {t.translate('manage.title')}
                </h1>
                <ul>
                    <li>{t.translate('manage.handle')}</li>
                    <li>{t.translate('manage.delete')}</li>
                    <li>{t.translate('manage.disclaimer')}</li>
                </ul>
            </div>
            <div id="types-cookies-container">
                <h1 className="font-title-bold sub-stitle" style={{ color: gradient(1 / 1).hex() }}>
                    {t.translate('type.title')}
                </h1>
                <div id="types-cookies-header-wrapper" className="row">
                    <div className="cookie-category types-cookies-header-item cookie-category-header-background column">
                        <span className="bold">
                            {t.translate('type.categories')}
                        </span>
                    </div>
                    <div className="cookie-objective types-cookies-header-item cookie-objective-header-background column">
                        <span className="bold">
                            {t.translate('type.goals')}
                        </span>
                    </div>
                    <div className="cookie-description types-cookies-header-item cookie-description-header-background column">
                        <span className="bold">
                            {t.translate('type.desc')}
                        </span>
                    </div>
                </div>
                {renderTypesCookies}
            </div>
        </div>
    );
}

export default CookiePolicy;