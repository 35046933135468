import { Loader } from "components";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import userAuthService from "services/user-auth.service";

const RedirectUserPov: React.FC<({
    patientPov?: JSX.Element,
    standalonePov?: JSX.Element,
    doctorPov?: JSX.Element,
    companyPov?: JSX.Element
})> = ({
    patientPov = null,
    standalonePov = null,
    doctorPov = null,
    companyPov = null
}) => {
    const { type, refreshType } = useContext(UserTypeContext);
    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const fetchType = useCallback(() => {
        refreshType()
            .catch(() => userAuthService.logout(history))
            .finally(() => setIsLoading(false));
    }, [history, refreshType]);

    useEffect(() => {
        if (type.isDefined()) {
            setIsLoading(false);
        } else {
            fetchType();
        }
    }, [type, fetchType]);

    const handlePov = () => {
        switch (type?.name) {
            case 'Patient':
                return patientPov;
            case 'Standalone':
                return standalonePov;
            case 'Company':
                return companyPov;
            case 'Doctor':
                return doctorPov;
            default:
                return <p>Shouldnt be there</p>
        }
    }

    return (
        <section className="full-height">
            {isLoading
                ? <Loader />
                : handlePov()}
        </section>
    );

}

export default RedirectUserPov;