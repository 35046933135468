import { Button } from "components";
import CircularProgressbar from "components/common/ProgressBar/CircularProgressBar";
import React from "react";

const AllocateResume: React.FC<({
    givenSolutions: number,
    freeSolutions: number,
    desc?: string,
    btnFunc?: () => void,
    btnText?: string,
    btnDisabled?: boolean,
    btnShow?: boolean,
    containerStyle?: React.CSSProperties 
})> = ({
    givenSolutions = 0,
    freeSolutions = 0,
    desc = 'Kits given to employees',
    btnFunc,
    btnText = 'See all',
    btnDisabled = true,
    btnShow = true,
    containerStyle = {
        width: 75,
        height: 75
    }
}) => {
    return (
        <div className="flex-column">
            <div className="flex-row">
                <div style={containerStyle}>
                    <CircularProgressbar
                        value={givenSolutions}
                        text={`${givenSolutions}/${freeSolutions+givenSolutions}`}
                        maxValue={freeSolutions+givenSolutions}
                    />
                </div>
                <div className="flex-column" style={{ padding: '10px' }}>
                    <p>{desc}</p>
                </div>
            </div>
            {btnShow && (
                <Button
                    label={btnText}
                    onClick={() => {
                        if (btnFunc && typeof(btnFunc) === 'function') btnFunc()
                    }}
                    disabled={btnDisabled}
                />
            )}
        </div>
    )
}

export default AllocateResume;