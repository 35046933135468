import { IUserLanguage } from "interfaces/User/IUserLanguage";

import frenchFlag from "assets/flags/french.svg";
import ukFlag from "assets/flags/united_kingdom.svg";

const languages: IUserLanguage[] = [
    {
        name:"English",
        countryCode:"en",
        default: false,
        flagImage: ukFlag
    },
    {
        name:"Français",
        countryCode:"fr",
        default: true,
        flagImage: frenchFlag
    }
];

export default languages;