import { Button, CloseIcon, Loader } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IDatabaseSubscriptionPlan } from "interfaces/Database/IDatabaseSubscriptionPlan";
import { IUserWithOnGoing } from "interfaces/IUserWithOnGoing";
import React, { useState } from "react";
import { useTranslation } from "services";
import companyService from "services/company.service";

const CompanyStopEmployeePlanModal: React.FC<({
    isShow: boolean
    toggleShow: () => void
    employee?: IUserWithOnGoing
    plan?: IDatabaseSubscriptionPlan
    fetchPlan: () => void
    closeParent: () => void
})> = ({ isShow, toggleShow, employee, plan, fetchPlan, closeParent }) => {
    const t = useTranslation('dashboard/company/employees');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ success, setSuccess ] = useState<string>();
    const [ error, setError ] = useState<string>();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        if (employee?.id && plan?.id) {
            await companyService.stopEmployeePlan(employee?.id, plan.id)
                .then((res) => setSuccess(res))
                .catch((err) => setError((err && err.message) ? err.message : t.translate('stopSolutionModal.errors.stopError')) );
        } else {
            setTimeout(() => setError(t.translate('stopSolutionModal.errors.wrongData')), 1000);
        }

        setIsLoading(false);
    }

    const reset = (doFetchPlan = false, doCloseParent = false) => {
        setIsLoading(false);
        setError(undefined);

        if (doFetchPlan && typeof fetchPlan === "function") {
            fetchPlan();
        }

        toggleShow();

        if (doCloseParent && typeof closeParent === 'function') {
            closeParent();
        }
    }

    return isShow
        ? <div className='modal'>
            <div className='modal-content'>
                <div className='modal-content-header'>
                    <CloseIcon className='close' onClick={() => reset()} />
                    <h2 className="MavenPro-bold">{t.translate('stopSolutionModal.title')}</h2>
                </div>
                <div className='modal-content-body'>
                    { isLoading
                        ? <Loader />
                        : success
                            ? <div className="flex-column">
                                <p>{t.translate('stopSolutionModal.success.preId')} {employee?.patientInfo?.lastname} {employee?.patientInfo?.firstname} {t.translate('stopSolutionModal.success.postId')}</p>
                                <p>{t.translate('stopSolutionModal.success.leave')}</p>
                                <div className="flex-row">
                                    <Button label={t.translate('stopSolutionModal.success.back')} onClick={() => reset(true, true)} />
                                    <Button label={t.translate('stopSolutionModal.success.close')} onClick={() => reset(true)} />
                                </div>
                            </div>
                            : <form className='allocate-form' onSubmit={(e) => handleSubmit(e)} onReset={() => reset()}>
                                <p>{t.translate('stopSolutionModal.confirm.first')}</p>
                                <p>{t.translate('stopSolutionModal.confirm.second')}</p>
                                <p>
                                    <u>{t.translate('stopSolutionModal.confirm.underlined')}</u>
                                    {t.translate('stopSolutionModal.confirm.billContinue')}
                                </p>
                                <ErrorMessage error={error} />
                                <div className="flex-row">
                                    <Button label={t.translate('stopSolutionModal.confirm.cancel')} type='reset' />
                                    <Button label={t.translate('stopSolutionModal.confirm.confirm')} classname="dashboardBtn-dngr" type="submit" />
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
        : null
}

export default CompanyStopEmployeePlanModal;