import Languages from "context/Language/Languages";
import { IUserLanguage } from "interfaces/User/IUserLanguage";
import { LanguageContext } from "context/Language/LanguageContext";
import React, { useContext } from "react";

const PrivateSidebarLanguage: React.FC = () => {
    const { language, setLanguage } = useContext(LanguageContext);

    return (
        <div id="sidebar-language">
            { Languages.map((newLanguage: IUserLanguage, index: number) =>
                <img
                    key={'sidebar-language-flag-' + index}
                    className={(newLanguage && (language.countryCode === newLanguage.countryCode)) ? 'language-flag-selected' : 'language-flag'}
                    src={newLanguage.flagImage}
                    alt={`${newLanguage.name} flag`}
                    onClick={() => setLanguage(newLanguage)}
                />
            )}
        </div>
    )
}

export default PrivateSidebarLanguage;