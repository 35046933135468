import { Button, CloseIcon, Input, Label, Loader } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import React, { useState } from "react";
import { useTranslation } from "services";
import companyService from "services/company.service";
import verifierService from "services/verifier.service";

const AddEmployee: React.FC<({
    isShow: boolean,
    toggleShow: (updateEmployee: boolean) => void
})> = ({
    isShow = false,
    toggleShow
}) => {
    const t = useTranslation('dashboard/company/employees');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ formError, setFormError ] = useState<string>();
    const [ success, setSuccess ] = useState<string>();

    const [ employeeFirstname, setEmployeeFirstname ] = useState<string>();
    const [ employeeLastname, setEmployeeLastname ] = useState<string>();
    const [ employeeEmail, setEmployeeEmail ] = useState<string>();

    const reset = (updateEmployee: boolean) => {
        setIsLoading(false);
        setSuccess(undefined);
        setFormError(undefined);

        setEmployeeFirstname(undefined);
        setEmployeeLastname(undefined);
        setEmployeeEmail(undefined);

        toggleShow(updateEmployee);
    }

    const handleAddEmployee = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormError(undefined);
        setSuccess(undefined);
        setIsLoading(true);

        if (employeeEmail && employeeFirstname && employeeLastname) {
            const data = {
                firstName: employeeFirstname,
                lastName: employeeLastname,
                email: employeeEmail
            }

            companyService.checkEmployee(employeeEmail)
                .then(() => {
                    companyService.createEmployee(data)
                        .then(() => setSuccess(t.translate('addEmployee.success')))
                        .catch(() => setFormError(t.translate('addEmployee.error')))
                        .finally(() => setIsLoading(false));
                })
                .catch(() => setFormError(t.translate('addEmployee.error')))
                .finally(() => setIsLoading(false));
        }
    }

    return isShow
        ? <div className='modal'>
            <div className='modal-content'>
                <div className='modal-content-header'>
                    <CloseIcon className='close' onClick={() => reset(false)} />
                    <h2 className="MavenPro-bold">{t.translate('addEmployee.title')}</h2>
                </div>
                <div className='modal-content-body'>
                    {isLoading
                        ? <Loader />
                        : success
                            ? <p>{t.translate('addEmployee.success')}</p>
                            : <form className="CompanyProfileModal-content-body-form" onSubmit={handleAddEmployee}>
                                <div className="CompanyProfileModal-content-body-form-fields-wrapper" style={{ margin: '10px 0 10px 0' }}>
                                    <Label for="new-employee-firstname" label={t.translate('addEmployee.firstname.label')} />
                                    <Input
                                        required
                                        name="new-employee-firstname"
                                        value={employeeFirstname}
                                        setValue={(e) => setEmployeeFirstname(e.currentTarget.value)}
                                        disabled={isLoading}
                                        placeholder={t.translate('addEmployee.firstname.placeholder')}
                                        checkFunction={() => employeeFirstname !== ""}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                                <div className="CompanyProfileModal-content-body-form-fields-wrapper" style={{ margin: '10px 0 10px 0' }}>
                                    <Label for="new-employee-lastname" label={t.translate('addEmployee.lastname.label')} />
                                    <Input
                                        required
                                        name="new-employee-lastname"
                                        value={employeeLastname}
                                        setValue={(e) => setEmployeeLastname(e.currentTarget.value)}
                                        disabled={isLoading}
                                        placeholder={t.translate('addEmployee.lastname.placeholder')}
                                        checkFunction={() => employeeLastname !== ""}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                                <div className="CompanyProfileModal-content-body-form-fields-wrapper" style={{ margin: '10px 0 10px 0' }}>
                                    <Label for="new-employee-email" label={t.translate('addEmployee.email.label')} />
                                    <Input
                                        required
                                        name="new-employee-email"
                                        type={'email'}
                                        value={employeeEmail}
                                        setValue={(e) => setEmployeeEmail(e.currentTarget.value)}
                                        disabled={isLoading}
                                        placeholder={t.translate('addEmployee.email.placeholder')}
                                        checkFunction={() => verifierService.checkEmail(employeeEmail)}
                                        checkmark={{
                                            displayed: true
                                        }}
                                    />
                                </div>
                                <ErrorMessage error={formError} />
                                <Button label={t.translate('addEmployee.btn')} type={"submit"} disabled={isLoading} />
                            </form>
                    }
                </div>
            </div>
        </div>
        : null;
}

export default AddEmployee;