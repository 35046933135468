import React, { useEffect, useRef, useState } from "react";
import { IChartData } from "interfaces/Services/ChartService/IChartData";
import IDrawChartParams from "interfaces/Services/ChartService/IDrawChartParams";
import drawChart from "services/chart.service";
import ChartCaption, { ChartCaptionParams } from "./ChartCaption";

const DonutChart: React.FC<({
    data: IChartData[],
    chartParams?: IDrawChartParams,
    captionParams?: ChartCaptionParams
})> = ({
    data,
    chartParams,
    captionParams
}) => {
    const ref = useRef(null);
    const [ sortedData, setSortedData ] = useState<IChartData[]>([]);
    const [ sectionHovered, setSectionHovered ] = useState<IChartData | undefined>();

    useEffect(() => {
        setSortedData(([] as IChartData[]).concat(data).sort((a, b) => a.value < b.value ? 1 : -1));
    }, [ data ]);

    useEffect(() => {
        if (ref.current) {
            drawChart('donut', ref.current, data, { ...chartParams, setSectionHovered: setSectionHovered }, sectionHovered);
        }
    }, [ data, chartParams, ref, sectionHovered ]);

    return (
        <div
            className={
                captionParams?.captionPosition === 'bottom' ?
                    "flex-column" :
                    "flex-row"
            }
            style={ captionParams?.captionPosition === 'bottom' ? {
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '300px',
                overflowX: 'scroll'
            } : {
                alignItems: 'center',
                overflow: 'visible'
            }}
        >
            <div className="graph" ref={ref} />
            {captionParams?.showCaption ?
               <ChartCaption data={sortedData} params={captionParams} sectionHovered={sectionHovered} setSectionHovered={setSectionHovered} /> :
               null}
        </div>
    )
}

export default React.memo(DonutChart);