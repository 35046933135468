import box from 'assets/home/microbiota_analysis.webp';
import { Button, Input, Label, LeftArrowIcon, Loader, StepIndicator } from 'components';
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import ToolTip from "components/common/ToolTip/ToolTip";
import { SubscriptionTrackingDetailContext } from 'context/SolutionProcessingContext';
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import kitService from "services/kit.service";

const SubmitCode: React.FC<({
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    error?: string
    setError: React.Dispatch<React.SetStateAction<string|undefined>>
})> = ({
    isLoading = true,
    setIsLoading,
    error,
    setError
}) => {
    const t = useTranslation('dashboard/patient/register-kit');
    const { reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const history = useHistory();
    const [ kitCode, setKitCode ] = useState<string>();

    const goNextStep = () => {
        if (kitCode) {
            kitService.localStorageSaveKitCode(kitCode);
            reloadSubscriptionTrackingDetail();
            history.push(`/account/kit/consent-form`);
        }
    }

    const handleNextStep = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(undefined);
        setIsLoading(true);

        if (kitCode) {
            await kitService.initKit(kitCode)
                .then(() => goNextStep())
                .catch((err) => console.warn(err));
        }

        setIsLoading(false);
    }

    return (
        <DefaultContainer
            wrapperClassName="customContainerWrapper"
            title={
                <div className='flex-row-start'>
                    <LeftArrowIcon
                        className="clickable"
                        onClick={() => history.push('/account/home')}
                    />
                    <div style={{ margin: '0 1vw' }} className='flex-column-start'>
                        <StepIndicator step={2} />
                        <div className="flex-row-start align-items-stretch">
                            <h3>{t.translate('step-title')}</h3>
                            <span style={{ display: 'grid', alignContent: 'center', color: 'var(--dashboard-main-color)', margin: '0 .5vw' }}>3/3</span>
                        </div>
                    </div>
                </div>
            }
            body={
                <div className='row'>
                    <div id="image-wrapper" className="column">
                        <img src={box} alt="kit" id="kit-image" />
                    </div>
                    <div id="form-wrapper">
                        <div className="flex-column-start">
                            <ToolTip title={t.translate('help-title')}>
                                <p>{t.translate('help-description')}</p>
                            </ToolTip>
                            <h1 id="title" className="MavenPro-black">
                                {t.translate('title')}
                            </h1>
                            <p id="form-description">
                                {t.translate('sub-title')}
                            </p>
                        </div>
                        {isLoading
                            ? <Loader />
                            : <form className="flex-column-start full-width" id="form" onSubmit={handleNextStep}>
                                <Label
                                    for='input-code'
                                    label={t.translate('input-label')}
                                    required
                                />
                                <Input
                                    required
                                    disabled={isLoading}
                                    placeholder={t.translate('input-placeholder')}
                                    name="input-code"
                                    value={kitCode}
                                    setValue={(e) => setKitCode(e.currentTarget.value)}
                                />
                                <ErrorMessage error={error} />
                                <Button label={t.translate('button-label')} type="submit" disabled={isLoading} style={{ marginTop: '2vh' }} />
                            </form>
                        }
                    </div>
                </div>
            }
            hideSeparator={true}
        />
    )
}

export default SubmitCode;