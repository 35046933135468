import { Button, Input, Label, MultipleSelect, Select } from 'components';
import { EditAddress } from 'components/common/Address';
import { Modal } from 'components/common/Modal';
import { RegistrationTypesContext } from 'context/RegistrationTypesContext';
import { IDAddress, IDLanguage, IDRegistrationType, IDUser } from 'interfaces/Database';
import { IAddress, defaultAddress } from 'interfaces/User/IAddress';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'services';
import addressService from 'services/address.service';
import doctorService from 'services/doctor.service';
import userAuthService from 'services/user-auth.service';
import verifierService from 'services/verifier.service';

const ModalEditSpecificInfo: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void,
    currentUser?: IDUser,
    currentAddress?: IDAddress
})> = ({
    showModal,
    handleClose,
    handleSuccess,
    currentUser,
    currentAddress
}) => {
    const t = useTranslation('profile/doctor/address');

    const { registrationTypes, refreshRegistrationTypes } = useContext(RegistrationTypesContext);

    const [ isLoading, setIsLoading  ] = useState<boolean>(true);
    const [ allLanguages, setAllLanguages ] = useState<IDLanguage[]>([]);
    const [ languages, setLanguages ] = useState<IDLanguage[]>([]);
    const [ specialties, setSpecialties ] = useState<string[]>([]);
    const [ address, setAddress ] = useState<IAddress>(defaultAddress);
    const [ registrationType, setRegistrationType ] = useState<IDRegistrationType>();
    const [ registrationNumber, setRegistrationNumber ] = useState<string>()

    useEffect(() => {
        setLanguages(currentUser?.doctorInfo?.languages ?? []);
        setRegistrationType(currentUser?.doctorInfo?.registrationType);
        setRegistrationNumber(currentUser?.doctorInfo?.registrationNumber ?? "");
        if (currentUser?.doctorInfo?.doctorSpecialties && currentUser.doctorInfo.doctorSpecialties.length > 0) {
            setSpecialties((currentUser?.doctorInfo?.doctorSpecialties ?? []).map((e) => e.name));
        } else {
            setSpecialties([]);
        }

        if (!registrationTypes || registrationTypes.length === 0) refreshRegistrationTypes()
    }, [ currentUser ]);

    useEffect(() => {
        setAddress({
            firstname: currentAddress?.firstname ? currentAddress.firstname : currentUser?.doctorInfo?.firstname,
            lastname: currentAddress?.lastname ? currentAddress.lastname : currentUser?.doctorInfo?.lastname,
            streetAddress: currentAddress?.streetAddress ? currentAddress.streetAddress : "",
            postalCode: currentAddress?.postalCode ? currentAddress.postalCode : "",
            city: currentAddress?.city ? currentAddress.city : "",
            countryName: currentAddress?.country?.name ? currentAddress.country.name : "",
            additionalInfos: currentAddress?.additionalInfos ? currentAddress.additionalInfos : "",
            aptNumber: currentAddress?.aptNumber ? currentAddress.aptNumber : "",
        })
    }, [ showModal, currentAddress, currentUser?.doctorInfo?.firstname, currentUser?.doctorInfo?.lastname ]);
    

    const fetchData = useCallback(() => {
        setIsLoading(true);

        userAuthService.getAllDoctorLanguages()
            .then((res) => 
                setAllLanguages([
                    ...res.filter((res) => res.iso6391 === 'en' || res.iso6391 === 'fr' ),
                    ...res.filter((res) => res.iso6391 !== 'en' && res.iso6391 !== 'fr' ).sort((a, b) => a.name > b.name ? 1 : -1)
                ])
            )
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';

            fetchData();
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal, fetchData]);

    const handleRegistrationType = (registrationTypeId: number) => {
        const newType = registrationTypes.find((type) => type.id === registrationTypeId);

        setRegistrationType(newType);
    }

    const successModal = async () => {
        setIsLoading(true);

        const promises = [
            doctorService.updateInformations({
                registrationTypeId: registrationType?.id,
                registrationNumber: registrationNumber,
                languages: languages.map((e) => e.iso6391),
                specialties: specialties,
            })
        ];

        if (address) {
            promises.push(
                addressService.updateAddresses({
                    shipping: address,
                    billing: address,
                    sameAddresses: true
                })
            );
        }

        Promise.all(promises)
            .then(() => handleSuccess())
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={handleClose}
            title={t.translate("titleModal")}
            footer={
                <div className="flex-row full-width">
                    <Button label={t.translate('cancel')} onClick={() => handleClose()} disabled={isLoading} />
                    <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                </div>
            }
        >
            <Fragment>
                <div className="flex-column full-width">
                    <div className="flex-row flex-wrap full-width space-between">
                        <div className="flex-column-start" style={{ width: '45%' }}>
                            <Label for='registrationType' label={t.translate('numberType')} required />
                            <Select
                                required
                                name='registrationType'
                                value={registrationType?.name ?? ''}
                                setValue={(e) => handleRegistrationType(Number(e.currentTarget.value))}
                                options={
                                    <Fragment>
                                        <option className='dashboardSelect-option MavenPro' value={''} defaultChecked={true} disabled>{t.translate('chooseNumberType')}</option>
                                        {registrationTypes.map((registrationType, index) =>
                                            <option key={`edit-registration-type-${index}`} className='dashboardSelect-option MavenPro' value={registrationType.id}>{registrationType.name}</option>
                                        )}
                                    </Fragment>
                                }
                                checkFunction={() => !!registrationType}
                                checkmark={{
                                    displayed: true
                                }}
                            />
                        </div>
                        <div className="flex-column-start" style={{ width: '45%' }}>
                            <Label for='registrationNumber' label={t.translate('number')} required />
                            <Input
                                required
                                name='registrationNumber'
                                value={registrationNumber}
                                setValue={(e) => setRegistrationNumber(e.currentTarget.value)}
                                checkFunction={() => verifierService.checkString(registrationNumber)}
                                checkmark={{
                                    displayed: true
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex-row flex-wrap full-width space-between">
                        <div className="flex-column-start" style={{ width: '45%' }}>
                            <Label for='specialty' label={t.translate('specialty')} required />
                            <Input
                                required
                                name='specialty'
                                value={specialties.join(', ')}
                                setValue={(e) => setSpecialties(e.currentTarget.value.split(', '))}
                                checkFunction={() => specialties.length > 0}
                                checkmark={{
                                    displayed: true
                                }}
                            />
                        </div>
                        <div className="flex-column-start" style={{ width: '45%' }}>
                            <Label for='doctor-language' label={t.translate('language')} required />
                            <MultipleSelect
                                name="doctor-language"
                                baseItems={allLanguages}
                                selectedItems={languages}
                                setSelectedItems={setLanguages}
                                comparisonKey='id'
                                displayKey='name'
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-row-start full-width">
                    <h2 className="bold">{t.translate("address")}</h2>
                </div>
                <EditAddress isLoading={isLoading} address={address} setAddress={setAddress} />
            </Fragment>
        </Modal>
    )
}

export default ModalEditSpecificInfo