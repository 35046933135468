import { Button, Checkbox } from "components/Form";
import { IDProductOrderOption } from "interfaces/Database";
import { useTranslation } from "services";

export interface OrderOptionsProps {
    baseOptions: IDProductOrderOption[]
    defaultOptions: IDProductOrderOption[]
    options: IDProductOrderOption[]
    setOptions: React.Dispatch<React.SetStateAction<IDProductOrderOption[]>>
    handleNextStep: () => void
}

const OrderOptions: React.FC<OrderOptionsProps> = (props) => {
    const t = useTranslation('pages/orderSolution');

    const handleUpdateOption = (option: IDProductOrderOption) => {
        let newOptions = props.options;

        if (props.options.includes(option)) {
            newOptions = [...newOptions.filter((opt) => opt.name !== option.name)];
        } else {
            newOptions = [
                ...newOptions,
                option
            ];
        }

        props.setOptions(newOptions);
    }

    return (
        <div className="full-width" style={{
            padding: '1vh 2vw',
            boxSizing: 'border-box'
        }}>
            {props.baseOptions.map((option, index) =>
                <div key={`option-${index}-${option.name}`}>
                    <Checkbox
                        name={option.name}
                        label={`${t.translate(`steps.options.${option.name}`)} (${props.defaultOptions.filter((opt) => opt.name === option.name).length > 0 ? 'Inclus' : `+${option.price}€`})`}
                        disabled={props.defaultOptions.filter((opt) => opt.name === option.name).length > 0}
                        isChecked={props.options.filter((opt) => opt.name === option.name).length > 0}
                        onClick={props.defaultOptions.filter((opt) => opt.name === option.name).length > 0 ? undefined : () => handleUpdateOption(option)}
                    />
                </div>
            )}
            <div className="full-width flex-row">
                <Button label="Suivant" onClick={props.handleNextStep} />
            </div>
        </div>
    )
}

export default OrderOptions;