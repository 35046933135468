import { Button, Input, UserCircleIcon } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IConversation } from "interfaces/Messaging/IConversation";
import React, { Fragment, useState } from "react";
import { useTranslation } from "services";
import messagingService from "services/messaging.service";
import "../scss/LastMessages.scss";

const LastMessages: React.FC<({
    conversation?: IConversation
    handleSelectConversation: (newId: number) => void
    conversations: IConversation[]
    setShowModal: React.Dispatch<boolean>
})> = ({ conversation, handleSelectConversation, conversations, setShowModal }) => {
    const t = useTranslation('messaging');
    const [keyword, setKeyword] = useState<string>();

    const filter = (search?: string) => {
        try {
            return conversations.filter((conversation) => conversation.conversation && conversation.conversation.interlocutorName.toLowerCase().includes(search ?? ""));
        } catch (error) {
            return [];
        }
    }

    const truncateLastMessage = (message: string) => {
        return message.length > 30 ? message.slice(0, 27) + "..." : message;
    }

    const renderMessages = filter(keyword).map((conv, index: number) =>
        <div key={index}
            className={`last-message-wrapper ${conv.conversation.conversationId === conversation?.conversation.conversationId && "message-selected"}`}
            onClick={() => handleSelectConversation(conv.conversation.conversationId)}
        >
            <div className="message-content-wrapper column">
                <div className="row contact-info-wrapper">
                    {conv.image 
                        ? <img src={`data:image/png;base64, ${conv.image}`} alt={""}/>
                        : <UserCircleIcon className="icon-no-pic" />
                    }
                    <div className="column">
                        <span className="contact-name MavenPro-bold">
                        {conv.conversation.interlocutorType} - {conv.conversation.interlocutorName}
                        </span>
                        <span className="message-date">
                            {messagingService.getMessageFormattedDate(conv.lastMessage.createdAt)}
                        </span>
                    </div>
                </div>
                <p className="last-message">
                    {truncateLastMessage(conv.lastMessage.content)}
                </p>
            </div>
        </div>
    );

    return (
        <DefaultContainer
            wrapperStyle={{
                minWidth: "10px"
            }}
            title={<h1 className="container-title bold">{t.translate('lastMessages')}</h1>}
            body={
                <Fragment>
                    <Input
                        name="conversation-search"
                        type="text"
                        placeholder={t.translate('placeholder.search')}
                        value={keyword}
                        setValue={(e) => setKeyword(e.currentTarget.value)}
                    />
                    <div className="flex-column flex-grow" id="contact-messages-wrapper">
                        <div id="last-messages-wrapper" className="flex-column flex-grow justify-start" style={{ paddingTop: '5px' }}>
                            {renderMessages}
                        </div>
                        <Button label={t.translate('newMessage')} onClick={() => setShowModal(true)} />
                    </div>
                </Fragment>
            }
            bodyContainerClassName="flex-grow"
            hideSeparator
            wrapperClassName="full-height"
        />
    );
}

export default LastMessages;