import { IDRegistrationType } from "interfaces/Database";
import React, { createContext, useState } from "react";
import registrationTypeService from "services/registrationType.service";

type RegistrationTypesContextProps = {
    registrationTypes: IDRegistrationType[];
    setRegistrationTypes: (registrationTypes: IDRegistrationType[]) => void;
    refreshRegistrationTypes: () => Promise<IDRegistrationType[]>;
}

export const defaultRegistrationTypes: IDRegistrationType[] = [];

export const RegistrationTypesContext = createContext<RegistrationTypesContextProps>({
    registrationTypes: defaultRegistrationTypes,
    setRegistrationTypes: (_: IDRegistrationType[]) => undefined,
    refreshRegistrationTypes: async () => registrationTypeService.getAllRegistrationTypes()
});

export const RegistrationTypesProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const [ registrationTypes, setRegistrationTypes ] = useState<IDRegistrationType[]>(defaultRegistrationTypes);

    const refreshRegistrationTypes = async () => {
        return registrationTypeService.getAllRegistrationTypes()
            .then((res) => {
                setRegistrationTypes(res);
                return res;
            })
            .catch((err) => Promise.reject(err.message));
    }

    return (
        <RegistrationTypesContext.Provider value={{ registrationTypes, setRegistrationTypes, refreshRegistrationTypes }}>
            {children}
        </RegistrationTypesContext.Provider>
    )
}