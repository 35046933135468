import { LeftArrowIcon, Loader } from "components";
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import "../../scss/ClaimKit.scss";
import ClaimKitConfirmPhone from "./ClaimKitConfirmPhone";
import ClaimKitFormUserAddress from "./ClaimKitFormUserAddress";
import ClaimKitFormUserInformation from "./ClaimKitFormUserInformation";
import ClaimKitHome from "./ClaimKitHome";
import FinishStepsSuccess from "./FinishStepsSuccess";
import ProgressBar from "./ProgressBar";

const ClaimKit: React.FC = () => {
    const t = useTranslation('firstConnection');
    const [ indexCurrentStep, setIndexCurrentStep ] = useState<number>(0);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isValidClaimKitProcess, setIsValidClaimKitProcess ] = useState<boolean>(false);

    const handleNextStep = () => {
        setIndexCurrentStep(indexCurrentStep + 1);
    }

    const handlePreviousStep = () => {
        setIndexCurrentStep(indexCurrentStep - 1);
    }

    useEffect(() => {
        if (indexCurrentStep === getSteps().length) {
            handleStepsEnd();
        }
    }, [indexCurrentStep]);


    const handleStepsEnd = async () => {
        setIsLoading(true);
        try {
            await userAuthService.handleClaimKit();
            toast.success(t.translateFromFile('toast', 'firstConnection.success'), {
                theme: "light"
            });
            setIsValidClaimKitProcess(true);
        } catch (error) {
            toast.error(t.translateFromFile('toast', 'firstConnection.fail'), {
                theme: "light"
            });
            setIsValidClaimKitProcess(false);
        }
        setIsLoading(false);
    }



    const getSteps = () => {
        return [
            {
                component: <ClaimKitHome handleNextStep={handleNextStep} />,
            },
            {
                component: <ClaimKitFormUserInformation handleNextStep={handleNextStep} />,
            },
            {
                component: <ClaimKitConfirmPhone handleNextStep={handleNextStep} />,
            },
            {
                component: <ClaimKitFormUserAddress handleNextStep={handleNextStep} />,
            }
        ]
    }

    const handleClaimKitSteps =
        getSteps().map((step, index) =>
            indexCurrentStep === index &&
            <section key={index} className="content column">
                { index !== 0 &&
                    <div className="column">
                        <ProgressBar
                            currentStep={indexCurrentStep}
                            numberSteps={getSteps().length - 1}
                        />
                        <div className="flex-row-start" id="go-back-container">
                            <LeftArrowIcon
                                size={36}
                                onClick={handlePreviousStep}
                            />
                            <p id="steps-info">{t.translate('step')} {indexCurrentStep}/{getSteps().length - 1}</p>
                        </div>
                    </div>
                }
                {step.component}
            </section>
        )

    if (isLoading) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <div id="claim-kit" className="column">
                {handleClaimKitSteps}
                {indexCurrentStep >= getSteps().length &&
                    <FinishStepsSuccess
                        isError={!isValidClaimKitProcess}
                    />
                }
            </div>
        );
    }
}

export default ClaimKit;