import { RoleName } from "interfaces/User/IUserRole";

export interface IDatabaseUserType {
    id?: number,
    name?: RoleName
}

export default class DatabaseUserType implements IDatabaseUserType {
    id?: number;
    name?: RoleName;

    constructor (id?: number, name?: RoleName) {
        this.id = id;
        this.name = name;
    }

    public isDefined(): boolean {
        return !!(this?.name);
    }

    public isPatient(): boolean {
        return this.name === 'Patient';
    }

    public isStandalone(): boolean {
        return this.name === 'Standalone';
    }

    public isCompany(): boolean {
        return this.name === 'Company';
    }

    public isDoctor(): boolean {
        return this.name === 'Doctor';
    }

    public isStaff(): boolean {
        return this.name === 'Staff';
    }
}