import { Button, Loader } from "components";
import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import { IUserParameters } from "interfaces/User/IUserParameters";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import CustomErrorContainer from "../CustomMessageContainer/CustomErrorContainer";
import CustomSuccessContainer from "../CustomMessageContainer/CustomSuccessContainer";
import CustomToggleSwitch from "../CustomToggleSwitch/CustomToggleSwitch";
import DefaultContainer from "../DefaultContainer/DefaultContainer";

const DashboardUserParameters: React.FC = () => {
    const t = useTranslation('dashboard/common/UserParameters');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ updateSuccess, setUpdateSuccess ] = useState<boolean>(false);
    const [ updateError, setUpdateError ] = useState<boolean>(false);

    const toggleUpdateSuccess = () => setUpdateSuccess(!updateSuccess);
    const toggleUpdateError = () => setUpdateError(!updateError);

    const [ currentParameters, setCurrentParameters ] = useState<IUserParameters>();

    const [ isSubNewsletter, setIsSubNewsletter ] = useState<boolean>(false);
    const [ isMailNotifications, setIsMailNotifications ] = useState<boolean>(false);
    const [ isAllowCookies, setIsAllowCookies ] = useState<boolean>(false);
    const [ isAllowFunctionalMails, setIsAllowFunctionalMails ] = useState<boolean>(false);
    const [ isAllowAppointmentMails, setIsAllowAppointmentMails ] = useState<boolean>(false);
    const [ isAllowDeliveryMails, setIsAllowDeliveryMails ] = useState<boolean>(false);
    const [ isAllowMessagingMails, setIsAllowMessagingMails ] = useState<boolean>(false);
    const [ isAllowConfirmationMails, setIsAllowConfirmationMails ] = useState<boolean>(false);

    const [ confirmModal, setConfirmModal ] = useState<boolean>(false);

    const toggleConfirmModal = () => setConfirmModal(!confirmModal);

    const fetchParameters = useCallback(async () => {
        setIsLoading(true);

        userAuthService.getUserParameters()
            .then((res) => {    
                // backup actual parameters to avoid useless request later
                setCurrentParameters({
                    isMailNotifications: res.functional_mails && res.messaging_mails && res.appointment_mails && res.delivery_mails && res.messaging_mails && res.confirmation_mails,
                    userParameters: res
                });

                // set current parameters as base parameters for switches
                setIsMailNotifications(res.functional_mails && res.messaging_mails && res.appointment_mails && res.delivery_mails && res.messaging_mails && res.confirmation_mails);
                setIsAllowFunctionalMails(res.functional_mails || false);
                setIsAllowMessagingMails(res.messaging_mails || false);
                setIsAllowAppointmentMails(res.appointment_mails || false);
                setIsAllowDeliveryMails(res.delivery_mails || false);
                setIsAllowMessagingMails(res.messaging_mails || false);
                setIsAllowConfirmationMails(res.confirmation_mails || false);
                setIsSubNewsletter(res.sub_newsletter || false);
                setIsAllowCookies(res.allow_cookies || false);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchParameters();
    }, []);

    const hasParamChanged = () => {
        if (
            currentParameters && (
                currentParameters.isMailNotifications !== isMailNotifications ||
                currentParameters.userParameters.sub_newsletter !== isSubNewsletter ||
                currentParameters.userParameters.allow_cookies !== isAllowCookies ||
                currentParameters.userParameters.appointment_mails !== isAllowAppointmentMails ||
                currentParameters.userParameters.confirmation_mails !== isAllowConfirmationMails ||
                currentParameters.userParameters.delivery_mails !== isAllowDeliveryMails ||
                currentParameters.userParameters.functional_mails !== isAllowFunctionalMails ||
                currentParameters.userParameters.messaging_mails !== isAllowMessagingMails
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    const submitNewParameters = async () => {
        setIsLoading(true);
        
        userAuthService.updateUserParameters({
            sub_newsletter: isSubNewsletter,
            allow_cookies: isAllowCookies,
            functional_mails:   isMailNotifications ?? isAllowFunctionalMails,
            messaging_mails:    isMailNotifications ?? isAllowMessagingMails,
            appointment_mails:  isMailNotifications ?? isAllowAppointmentMails,
            delivery_mails:     isMailNotifications ?? isAllowDeliveryMails,
            confirmation_mails: isMailNotifications ?? isAllowConfirmationMails
        })
            .then(async () => {
                await fetchParameters()
                    .then(() => toggleUpdateSuccess())
                    .catch(() => toggleUpdateError());
            })
            .catch(() => toggleUpdateError())
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            title={<h2 className='bold'>{t.translate('title')}</h2>}
            body={isLoading
                ? <Loader />
                : <div className='flex-column'>
                    <CustomSuccessContainer isShow={updateSuccess} close={toggleUpdateSuccess} text={t.translate('update.success')} />
                    <CustomErrorContainer isShow={updateError} close={toggleUpdateError} text={t.translate('update.error')} />
                    <div className='full-width row space-between'>
                        <h3 className='bold'>{t.translate('generalSettings')}</h3>
                    </div>
                    <div className='flex-row'>
                        <div className='flex-column' style={{ margin: '10px' }}>
                            <h4>{t.translate('newsletter')} :</h4>
                            <CustomToggleSwitch name={"newsletter"} isChecked={isSubNewsletter} setIsChecked={setIsSubNewsletter} />
                        </div>
                        <div className='flex-column' style={{ margin: '10px' }}>
                            <h4>{t.translate('cookies')} :</h4>
                            <CustomToggleSwitch name={"allow_cookies"} isChecked={isAllowCookies} setIsChecked={setIsAllowCookies} />
                        </div>
                    </div>
                    {hasParamChanged() &&
                        <Button label={t.translate('update.btn')} onClick={() => toggleConfirmModal()} />
                    }
                    <ModalConfirmAction
                        showModal={confirmModal}
                        handleClose={toggleConfirmModal}
                        confirmAction={submitNewParameters}
                        description={t.translate('update.confirm')}
                    />
                </div>
            }
        />
    )
}

export default DashboardUserParameters;