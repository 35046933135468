import { configureStore } from '@reduxjs/toolkit';

import test from "../redux/test";
import cartStatus from "../redux/cartStatus";
import updateProfilePicture from "../redux/updateProfilePicture";


export default configureStore({
  reducer: {
    counter: test,
    cartIsEmpty : cartStatus,
    profilePicture : updateProfilePicture,
  },
});
