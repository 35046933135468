import translationService from "services/translation.service";

export interface IDoctorQuestion {
    label: string
    questions: {
        answer?: string[]
    }
}

const doctorsQuestions: {
    content: IDoctorQuestion[]
} = {
    content: [
        {
            label: translationService.translate('faq.labels.essential-information'),
            questions: {
                answer: translationService.translateArray('faq.text.essential-information')
            }
        },
        {
            label: translationService.translate('faq.labels.referent'),
            questions: {
                answer: translationService.translateArray('faq.text.referent')
            }
        },
        {
            label: translationService.translate('faq.labels.slack'),
            questions: {
                answer: translationService.translateArray('faq.text.slack')
            }
        },
        {
            label: translationService.translate('faq.labels.securite'),
            questions: {
                answer: translationService.translateArray('faq.text.securite')
            }
        },
        {
            label: translationService.translate('faq.labels.support'),
            questions:{
                answer: translationService.translateArray('faq.text.support')
            }
        },
        {
            label: translationService.translate('faq.labels.general-rates'),
            questions: {
                answer: translationService.translateArray('faq.text.general-rates')
            }
        },
        {
            label: translationService.translate('faq.labels.limits-of-digital-health'),
            questions: {
                answer: translationService.translateArray('faq.text.limits-of-digital-health')
            }
        },
        {
            label: translationService.translate('faq.labels.seen-by-patients'),
            questions: {
                answer: translationService.translateArray('faq.text.seen-by-patients')
            }
        },
        {
            label: translationService.translate('faq.labels.a-to-z'),
            questions: {
                answer: translationService.translateArray('faq.text.a-to-z')
            }
        },
    ]
}

export default doctorsQuestions;