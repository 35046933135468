import { Step, StepLabel, Stepper } from "@mui/material";
import { Button, CheckIcon } from "components";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import useWindowDimensions from "hooks/useWindowDimensions";
import { ISubscriptionTrackingStep } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingStep";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";

export interface step {
    index: number,
    libelle: string,
    value?: ISubscriptionTrackingStep
}

const PatientSubscriptionTracking: React.FC<({
    tracking?: ISubscriptionTrackingSteps,
    setCurrentStep?: React.Dispatch<React.SetStateAction<step | undefined>>
    setNextStep?: React.Dispatch<React.SetStateAction<step | undefined>>
})> = ({
    tracking,
    setCurrentStep,
    setNextStep
}) => {
    const t = useTranslation('components/SubscriptionTracking');
    const history = useHistory();
    const { width } = useWindowDimensions();

    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    
    const [ activeStep, setActiveStep ] = useState<number>(-1);
    const [ steps, setSteps ] = useState<step[]>([]);


    const DEFAULT_STEPS = useMemo<step[]>(() => 
        subscriptionTrackingDetail?.handleShouldShowAppointment()
            ? [
                {
                    index: 1,
                    libelle: 'steps.kit-registered',
                    value: tracking?.kitClaim
                },
                {
                    index: 2,
                    libelle: 'steps.kit-sent',
                    value: tracking?.kitSend
                },
                {
                    index: 3,
                    libelle: 'steps.kit-under-analysis',
                    value: tracking?.kitAnalysing
                },
                {
                    index: 4,
                    libelle: 'steps.kit-analysis-finalized',
                    value: tracking?.analyseDone
                },
                {
                    index: 5,
                    libelle: 'steps.kit-scheduled-appointment',
                    value: tracking?.appointment
                },
            ]
            : [
                {
                    index: 1,
                    libelle: 'steps.kit-registered',
                    value: tracking?.kitClaim
                },
                {
                    index: 2,
                    libelle: 'steps.kit-sent',
                    value: tracking?.kitSend
                },
                {
                    index: 3,
                    libelle: 'steps.kit-under-analysis',
                    value: tracking?.kitAnalysing
                },
                {
                    index: 4,
                    libelle: 'steps.kit-analysis-finalized',
                    value: tracking?.analyseDone
                },
            ]
    , [tracking?.kitClaim, tracking?.kitSend, tracking?.kitAnalysing, tracking?.analyseDone, tracking?.appointment]);

    const initActiveStep = useCallback(() => {
        let index = 0;
        let isFinish = false;

        while (index < DEFAULT_STEPS.length && !isFinish) {
            DEFAULT_STEPS[index].value?.isDone ?
                index++ : isFinish = true;
        }

        setActiveStep(index > 0 ? index - 1 : -1);
    }, [DEFAULT_STEPS, tracking]);

    useEffect(() => {
        initActiveStep();

        if (width <= 980) {
            if (activeStep >= (DEFAULT_STEPS.length-1)) {
                setSteps([
                    DEFAULT_STEPS[activeStep-1],
                    DEFAULT_STEPS[activeStep]
                ]);
            } else {
                if (activeStep === -1) {
                    setSteps([
                        DEFAULT_STEPS[0],
                        DEFAULT_STEPS[1]
                    ]);
                } else {
                    setSteps([
                        DEFAULT_STEPS[activeStep],
                        DEFAULT_STEPS[activeStep+1]
                    ]);
                }
            }
        } else {
            setSteps(DEFAULT_STEPS);
        }

        // used to initiate currentStep & nextStep in information modal
        if (setCurrentStep && setNextStep) {
            if (activeStep >= (DEFAULT_STEPS.length-1)) {
                setCurrentStep(DEFAULT_STEPS[activeStep]);
            } else {
                if (activeStep === -1) {
                    setCurrentStep(DEFAULT_STEPS[0]);
                    setNextStep(DEFAULT_STEPS[1]);
                } else {
                    setCurrentStep(DEFAULT_STEPS[activeStep]);
                    setNextStep(DEFAULT_STEPS[activeStep+1]);
                }
            }
        }
    }, [initActiveStep, DEFAULT_STEPS, activeStep, width, setCurrentStep, setNextStep]);

    const handleDetailButton = () => {
        if (subscriptionTrackingDetail?.parcelToPatient?.isDelivered() && !subscriptionTrackingDetail?.parcelToLaboratory?.isDelivered()) {
            return <Button label={t.translate('how-it-works')} onClick={() => history.push('/how-it-works')} />;
        }

        if (subscriptionTrackingDetail?.analysisKit?.areResultsAvailable()) {
            if (subscriptionTrackingDetail?.doctorAppointment) {
                if (subscriptionTrackingDetail.doctorAppointment?.isNotBooked()) {
                    return <Button label={t.translate('book-appointment')} onClick={() => history.push('/account/appointments')} />;
                } else {
                    return <Button label={t.translate('see-appointment')} onClick={() => history.push('/account/appointments')} />;
                }
            } else if (subscriptionTrackingDetail.appointmentWNutritionist) {
                if (subscriptionTrackingDetail.appointmentWNutritionist?.isNotBooked()) {
                    return <Button label={t.translate('book-appointment')} onClick={() => history.push('/account/appointments')} />;
                } else {
                    return <Button label={t.translate('see-appointment')} onClick={() => history.push('/account/appointments')} />;
                }
            } else {
                return <Fragment>
                    <p>
                        <i>{t.translate('noAppointmentIncluded')}</i>
                    </p>
                    <Button label={t.translate('buyAppointment')} onClick={() => history.push('/account/appointments')} />
                </Fragment>
            }
        }

        if (
            (subscriptionTrackingDetail?.parcelToPatient?.isDelivered() && !subscriptionTrackingDetail?.parcelToLaboratory?.isDelivered()) ||
            (subscriptionTrackingDetail?.parcelToLaboratory?.isDelivered() && !subscriptionTrackingDetail.analysisKit?.areResultsAvailable())
        ) {
            return <Button label={t.translate('answerSurvey')} onClick={() => history.push("/account/kit/questionnaire")} />
        }

        return null;
    }

    return (
        <div id="patient-order-processing">
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel
                            icon={
                                <div
                                    className={`
                                        step ${step?.value?.isDone
                                            ? "step-done"
                                            : "step-not-done"} 
                                    `}
                                >
                                    {!step?.value?.isDone ?
                                        <span>0{step.index}</span> :
                                        <CheckIcon />
                                    }
                                </div>
                            }
                        >
                            <span className="step-label">
                                {t.translate(step?.libelle)}
                            </span>
                            {(step?.index === 1 && activeStep === 0) && (
                                step?.value?.dhlToPatient
                                    ? <p className="clickable text-main-color" onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${step?.value?.dhlToPatient}`, '_blank')}>{t.translate('follow-up-number') + ' ' + step?.value?.dhlToPatient}</p>
                                    : <p>{t.translate('noFollowUp')}</p>
                            )}
                            {(step?.index === 2 && activeStep === 1 && subscriptionTrackingDetail?.analysisKit?.activated) && (
                                step?.value?.dhlToLab
                                    ? <p className="clickable text-main-color" onClick={() => window.open(`https://www.dhl.com/fr-en/home/tracking.html?tracking-id=${step?.value?.dhlToLab}`, '_blank')}>{t.translate('follow-up-number') + ' ' + step?.value?.dhlToLab}</p>
                                    : <p>{t.translate('noFollowUpLab')}</p>
                            )}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            <div className="flex-column full-width" style={{ marginTop: '4vh' }}>
                {handleDetailButton()}
            </div>
        </div>
    );
}

export default PatientSubscriptionTracking;