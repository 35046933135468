import { UserCircleIcon } from "components";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { CSSProperties, useEffect, useState } from "react";
import profilePictureService from "services/profilePicture.service";
import './scss/ProfilePicture.scss';

const ShowProfilePicture: React.FC<{
    user?: IDatabaseUser;
    image?: string;
    maxSize?: string | number;
    margin?: {
        top?: boolean;
        left?: boolean;
        bottom?: boolean;
        right?: boolean;
    }
}> = ({
    user,
    image,
    maxSize,
    margin
}) => {
    const [ profilePicture, setProfilePicture ] = useState<string>();

    useEffect(() => {
        if (user) {
            profilePictureService.getUserProfilePicture(user?.id)
                .then((res) => setProfilePicture(res))
                .catch(() => setProfilePicture(undefined))
        } else {
            if (image) {
                setProfilePicture(image);
            } else {
                setProfilePicture(undefined);
            }
        }
    }, [user]);

    const handleStyle = (): CSSProperties => {
        return {
            aspectRatio: "1/1",
            width: maxSize ? (typeof maxSize === 'number' ? `${maxSize}px` : maxSize) : undefined,
            height: maxSize ? (typeof maxSize === 'number' ? `${maxSize}px` : maxSize) : undefined,
            marginLeft: margin?.left ? '10px' : undefined,
            marginTop: margin?.top ? '10px' : undefined,
            marginRight: margin?.right ? '10px' : undefined,
            marginBottom: margin?.bottom ? '10px' : undefined,
        }
    }

    return (
        <div
            id="profile-picture"
            style={handleStyle()}
        >
            <div
                id="profile-pic-container-no-hover"
                className="column background-main-color"
            >
                { profilePicture
                    ? <img src={profilePicture} alt={`user ${user?.id}`} id="profile-picture-img"/>
                    : <div id="no-picture-container" className="flex-column">
                        <UserCircleIcon size={"100%"} id="icon-user" />
                    </div>
                }
            </div>
        </div>
    )
}

export default ShowProfilePicture;