import CustomProvider from 'context/CustomProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './app/store';
import './index.scss';
import reportWebVitals from './reportWebVitals';

/** App setup structure for React -17 */
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CustomProvider>
        <App />
      </CustomProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

/** New app setup structure to use when switch to React 18+ */
// const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <CustomProvider>
//         <App />
//       </CustomProvider>
//     </Provider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
