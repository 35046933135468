import imgPrepare1 from 'assets/questionnaire/doctor-questionnaire-prepare1.png';
import imgPrepare10 from 'assets/questionnaire/doctor-questionnaire-prepare10.png';
import imgPrepare11 from 'assets/questionnaire/doctor-questionnaire-prepare11.png';
import imgPrepare2 from 'assets/questionnaire/doctor-questionnaire-prepare2.png';
import imgPrepare3 from 'assets/questionnaire/doctor-questionnaire-prepare3.png';
import imgPrepare4 from 'assets/questionnaire/doctor-questionnaire-prepare4.png';
import imgPrepare5 from 'assets/questionnaire/doctor-questionnaire-prepare5.png';
import imgPrepare6 from 'assets/questionnaire/doctor-questionnaire-prepare6.png';
import imgPrepare7 from 'assets/questionnaire/doctor-questionnaire-prepare7.png';
import imgPrepare8 from 'assets/questionnaire/doctor-questionnaire-prepare8.png';
import imgPrepare9 from 'assets/questionnaire/doctor-questionnaire-prepare9.png';
import imgSlack1 from 'assets/questionnaire/doctor-questionnaire-slack1.png';
import imgSlack2 from 'assets/questionnaire/doctor-questionnaire-slack2.png';
import imgSlack3 from 'assets/questionnaire/doctor-questionnaire-slack3.png';
import imgSlack4 from 'assets/questionnaire/doctor-questionnaire-slack4.png';
import listQuestionsDoctor from "components/FAQ/consts/listQuestionsDoctor";
import "components/FAQ/scss/Faq.scss";
import React, { useState } from "react";
import { useTranslation } from "services";

const FaqDoctor: React.FC = () => {
    const t = useTranslation('faq');
    const [ indexThemeSelected, setIndexThemeSelected ] = useState<number>(0);
    
    const render = (json: any[]) => { // eslint-disable-line
        return json.map((data, dataIndex) => {
            switch (data.type) {
                case 'h2':
                    return <h2 key={dataIndex}>{data.content}</h2>
                case 'p':
                    return <div key={dataIndex} className={data.className} dangerouslySetInnerHTML={{__html: data.content}}></div>
                case "ul":
                    // eslint-disable-next-line
                    return <ul key={dataIndex}>{data.content.map((li: any, index: number) => <li key={`${dataIndex}-${index}`}><div dangerouslySetInnerHTML={{__html: li}}></div></li>)}</ul>
                case "img":
                    return <img key={dataIndex} alt={"complementary information"} src={getImage(data.content)} style={{maxWidth: "600px"}} />
                default:
                    return <p key={dataIndex} className={data.className && ""}>{data.content}</p>
            }
        })
    }

    const getImage = (name: string) => {
        switch (name) {
            case "doctor-questionnaire-prepare1.png":
                return imgPrepare1;
            case "doctor-questionnaire-prepare2.png":
                return imgPrepare2;
            case "doctor-questionnaire-prepare3.png":
                return imgPrepare3;
            case "doctor-questionnaire-prepare4.png":
                return imgPrepare4;
            case "doctor-questionnaire-prepare5.png":
                return imgPrepare5;
            case "doctor-questionnaire-prepare6.png":
                return imgPrepare6;
            case "doctor-questionnaire-prepare7.png":
                return imgPrepare7;
            case "doctor-questionnaire-prepare8.png":
                return imgPrepare8;
            case "doctor-questionnaire-prepare9.png":
                return imgPrepare9;
            case "doctor-questionnaire-prepare10.png":
                return imgPrepare10;
            case "doctor-questionnaire-prepare11.png":
                return imgPrepare11;
            case "doctor-questionnaire-slack1.png":
                return imgSlack1;
            case "doctor-questionnaire-slack2.png":
                return imgSlack2;
            case "doctor-questionnaire-slack3.png":
                return imgSlack3;
            case "doctor-questionnaire-slack4.png":
                return imgSlack4;
            default:
                break;
        }
    }

    const renderQuestions = listQuestionsDoctor.content.map((theme, index) =>
        index === indexThemeSelected &&
        <div key={`question-${index}`} className="column question-answer-wrapper">
            <div id="answer-container" className="column full-width">
                <div>{render(theme?.questions?.answer ?? [])}</div>
            </div>
        </div>
    )

    const renderTeamsFilters = listQuestionsDoctor.content.map((theme, index) =>
        <div
            key={index}
            id={indexThemeSelected === index 
                ? "faq-filterWrapper__selected"
                : "faq-filterWrapper"
            }
            onClick={() => handleSelectTheme(index)}
        >
            <div id="filterWrapped">
                <span className={`MavenPro-bold text-uppercase ${indexThemeSelected === index ? "text-gradient" : ""}`}>{theme.label}</span>
            </div>
        </div>
    );

    const handleSelectTheme = (index: number) => {
        setIndexThemeSelected(index);
    }

    return (
        <div id="faq" className="column">
            <div id="banner-top-container">
                <div id="banner-text-container">
                    <h1 id="title" className="font-title-bold">
                        {t.translate('title')}
                    </h1>
                    <p id="sub-title" className="text-letter-spacing">
                        {t.translate('sub')}
                    </p>
                </div>
            </div>
            <div className="column" id="faq-content-container">
                <div className="row" id="faq-filters-container">
                    {renderTeamsFilters}
                </div>
                <div className="row" id="questions-answers-container">
                    <div id="questions-container" className="column">
                        {renderQuestions}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqDoctor;