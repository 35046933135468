import { TextArea } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import React from "react";
import { useTranslation } from "services";

const InformationSpe: React.FC<({
    information?: string
})> = ({ information }) => {
    const t = useTranslation('patientDetail');

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate("patientSpecialInformation.title")}</h1>}
            body={
                information
                    ? <TextArea
                        name="special-informations"
                        value={information}
                        disabled
                    />
                    : <p className="no-data">{t.translate('patientSpecialInformation.noData')}</p>
            }
        />
    )
}

export default InformationSpe;