import img from 'assets/Help/profile.svg';
import React from 'react';
import { useTranslation } from 'services';

const DSFirstPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2>{t.translate('doctor.profile.title')}</h2>
            <div className="help-step-content">
                <img src={img} className='help-img' alt="Complete profile" />
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '250px' }}>
                    <p>{t.translate('doctor.profile.body')}</p>
                </div>
            </div>
        </div>
    )
}

export default DSFirstPage;