import { Button, CheckCircleIcon, Input, LeftArrowIcon, Loader } from "components";
import "components/ForgotPassword/scss/RequestReset.scss";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import verifierService from "services/verifier.service";

const RequestReset: React.FC = () => {
    const location = useLocation();
    const t = useTranslation('pages/requestReset');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ email, setEmail ] = useState<string>(location?.state?.email ?? '');
    const [ isRequestSent, setIsRequestSent ] = useState<boolean>(false);
    const history = useHistory();

    const createRequestReset = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);

        if (email) {
            userAuthService.requestResetPassword(email)
                .then(() => setIsRequestSent(true))
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return (
            <div id="request-reset" className="flex-column flex-grow">
                <Loader />
            </div>
        )
    } else if (isRequestSent) {
        return (
            <div id="request-reset" className="flex-column flex-grow">
                <div id="content" className="flex-column shadow">
                    <CheckCircleIcon size={70} id="icon-success" />
                    <h2 className="success-request-sent-message MavenPro-bold text-align-center">
                        {t.translate('success.big-text')}
                    </h2>
                    <p className="success-request-sent-message small-text">
                        {t.translate('success.small-text')}
                    </p>
                </div>
            </div>
        )
    } else {
        return (
            <div id="request-reset" className="flex-column">
                <div id="content" className="column shadow">
                    <LeftArrowIcon
                        className='clickable'
                        onClick={() => history.go(-1)}
                    />
                    <h1 id="request-reset-title" className="font-title-bold">
                        {t.translate('form.title')}
                    </h1>
                    <p id="request-reset-sub-title">
                        {t.translate("form.subtitle")}
                    </p>
                    <form id="input-container" className="flex-column" onSubmit={createRequestReset}>
                        <Input
                            name="email"
                            type="email"
                            value={email}
                            setValue={(e) => setEmail(e.currentTarget.value)}
                            placeholder={"email@example.com"}
                            checkFunction={() => verifierService.checkEmail(email)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                        {error !== "too_many_password_reset_request"
                            ? <ErrorMessage error={error} />
                            : <div className="flex-column full-width">
                                <p>{t.translate('error.desc')}</p>
                                    <p style={{ margin: 0 }}>- {t.translate('error.mail')}</p>
                                    <p style={{ margin: 0 }}>- {t.translate('error.spam')}</p>
                                <p>{t.translate('error.contact-us')}</p>
                                <Button label={t.translate('error.btn')} onClick={() => history.push('/contact-us')} />
                            </div>
                        }
                        {error !== "too_many_password_reset_request" && <Button label={t.translate('send')} type="submit" />}
                    </form>
                </div>
            </div>
        );
    }
}

export default RequestReset;