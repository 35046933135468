import { Button, Input, Label, Loader } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IDatabaseSubscriptionPlan } from "interfaces/Database/IDatabaseSubscriptionPlan";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { useState } from "react";
import { useTranslation } from "services";
import companyService from "services/company.service";
import verifierService from "services/verifier.service";

const AllocateNewEmployee: React.FC<({
    isLoading: boolean
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    error?: string
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
    setEmployee: React.Dispatch<React.SetStateAction<IDatabaseUser | undefined>>
    setSuccess: React.Dispatch<React.SetStateAction<string | undefined>>
    solution: IDatabaseSubscriptionPlan
})> = ({ isLoading, setIsLoading, error, setError, setEmployee, setSuccess, solution }) => {
    const t = useTranslation('dashboard/company/employees');
    const [ firstName, setFirstName ] = useState<string>();
    const [ lastName, setLastName ] = useState<string>();
    const [ email, setEmail ] = useState<string>();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(undefined);
        setIsLoading(true);

        if (email && firstName && lastName && email) {
            const requestData = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                kitId: solution.id
            }

            companyService.checkEmployee(email)
                .then(() => {
                    companyService.createEmployee(requestData)
                        .then((res) => {
                            setEmployee(res.data);
                            setSuccess("yes");
                        })
                        .catch((err) => setError(err.message))
                        .finally(() => setIsLoading(false));
                })
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        }
    }

    if (isLoading) {
        return (
            <Loader />
        )
    } else {
        return (
            <form className='allocate-form' onSubmit={(e) => handleSubmit(e)}>
                <div className='allocate-form-fields'>
                    <div className='allocate-form-fields-wrapper'>
                        <Label for="new-employee-lastname" label={t.translate('allocateModal.new.lastname')} />
                        <Input
                            required
                            name="new-employee-lastname"
                            value={lastName}
                            setValue={(e) => setLastName(e.currentTarget.value)}
                            placeholder={t.translate('allocateModal.new.lastname_placeholder')}
                            disabled={isLoading}
                            checkFunction={() => verifierService.checkString(lastName)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <div className='allocate-form-fields'>
                    <div className='allocate-form-fields-wrapper'>
                        <Label for="new-employee-firstname" label={t.translate('allocateModal.new.firstname')} />
                        <Input
                            required
                            name="new-employee-firstname"
                            value={firstName}
                            setValue={(e) => setFirstName(e.currentTarget.value)}
                            placeholder={t.translate('allocateModal.new.firstname_placeholder')}
                            disabled={isLoading}
                            checkFunction={() => verifierService.checkString(firstName)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <div className='allocate-form-fields'>
                    <div className='allocate-form-fields-wrapper'>
                        <Label for="new-employee-email" label={t.translate('allocateModal.new.email')} />
                        <Input
                            required
                            name="new-employee-email"
                            type={"email"}
                            value={email}
                            setValue={(e) => setEmail(e.currentTarget.value)}
                            placeholder={t.translate('allocateModal.new.email_placeholder')}
                            disabled={isLoading}
                            checkFunction={() => verifierService.checkEmail(email)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>

                <ErrorMessage error={error} />
                
                <Button label={isLoading ? t.translate('allocateModal.new.loading') : t.translate('allocateModal.new.validate')} type="submit" disabled={isLoading} />
                <p className='MavenPro allocate-form-sub'>*{t.translate('allocateModal.new.warn')}</p>
            </form>
        )
    }
}

export default AllocateNewEmployee;