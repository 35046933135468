import InformationWrapper from 'components/InformationWrapper';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import ShowProfilePicture from 'components/common/ProfilePicture/ShowProfilePicture';
import { IDatabaseUser } from 'interfaces/Database/IDatabaseUser';
import React from 'react';
import { useTranslation } from 'services';
import doctorService from 'services/doctor.service';
import '../scss/MedecinReferent.scss';

const MedecinReferent: React.FC<({
    doctor?: IDatabaseUser
})> = ({ doctor }) => {
    const t = useTranslation('patientDetail');

    const doctorName = doctorService.formatDoctorName(doctor?.doctorInfo);

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate("referringDoctor.title")}</h1>}
            body={
                <div id='content-medecin-information' className='flex-row flex-wrap full-width' style={{ marginTop: '20px' }}>
                    <div className='flex-column' style={{ width: '40%' }}>
                        <ShowProfilePicture user={doctor} maxSize={"max(10vh, 10vw)"} />
                    </div>
                    <div className='col-data flex-column-start' style={{ width: '60%' }}>
                        <InformationWrapper
                            libelle={t.translate("referringDoctor.name")}
                            value={doctorName}
                            alignStart
                        />
                        <InformationWrapper
                            libelle={t.translate("referringDoctor.email")}
                            value={doctor?.email}
                            alignStart
                        />
                    </div>
                </div>
            }
        />
    )
}

export default MedecinReferent