import box from 'assets/home/microbiota_analysis.webp';
import { Button, LeftArrowIcon, StepIndicator } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'services';

const NoPlanAvailable: React.FC = () => {
    const t = useTranslation('pages/kitRegister')
    const history = useHistory();

    return (
        <DefaultContainer
            wrapperClassName="customContainerWrapper"
            title={
                <div className='flex-row-start'>
                    <LeftArrowIcon
                        className="clickable"
                        onClick={() => history.push('/account/home')}
                    />
                    <div style={{ margin: '0 1vw' }} className='flex-column-start'>
                        <StepIndicator step={1} />
                    </div>
                </div>
            }
            body={
                <div className='row'>
                    <div id="image-wrapper" className="column">
                        <img src={box} alt="kit" id="kit-image" />
                    </div>
                    <div id="form-wrapper" className="flex-column">
                        <div className="flex-column">
                            <h2 id="title" className="MavenPro-black full-width" style={{ textAlign: "center" }}>
                                {t.translate('noPlanAvailable.title')}
                            </h2>
                            <p id="form-description" className="full-width" style={{ textAlign: "center" }}>
                                {t.translate('noPlanAvailable.desc')}
                            </p>
                        </div>
                        <div className="flex-row full-width flex-wrap">
                            <Button label={t.translate('noPlanAvailable.dashboard')} onClick={() => history.push('/account/home')} style={{ boxSizing: 'border-box', margin: '2vw' }} />
                            <Button label={t.translate('noPlanAvailable.buySolution')} onClick={() => history.push("/order")} style={{ boxSizing: 'border-box', margin: '2vw' }} />
                        </div>
                    </div>
                </div>
            }
            hideSeparator={true}
        />
    )
}

export default NoPlanAvailable;