import { IDSurveyAnswer } from "interfaces/Database";
import React from "react";
import translationService from "services/translation.service";
import "../scss/QA.scss";

const QA: React.FC<({
    answer: IDSurveyAnswer;
    index: number;
})> = ({ answer, index }) => {

    return (
        <div id="qa" className="column">
            <span className="question MavenPro-bold">
                {index}. {translationService.translateSurveyQuestion(answer.question.codeQuestion)}
            </span>
            <span>
                {answer.answer}
            </span>
        </div>
    );
}

export default QA;