import React from 'react';
import './scss/CustomToggleSwitch.scss';

const CustomToggleSwitch: React.FC<({
    name: string
    isChecked: boolean
    setIsChecked: (newValue: boolean) => void
})> = ({ name, isChecked, setIsChecked }) => {
    return (
        <div className='toggle-switch'>
            <input
                type={"checkbox"}
                className='toggle-switch-checkbox'
                name={name}
                id={name}
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
            />

            <label className='toggle-switch-label' htmlFor={name}>
                <span className='toggle-switch-inner' />
                <span className='toggle-switch-switch' />
            </label>
        </div>
    )
}

export default CustomToggleSwitch;