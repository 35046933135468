import apiService from "./api.service";

class contactService {
    public sendContact = async (businessName: string, email: string, firstName: string, lastName: string, content: string) => {
        return await apiService.makeApiCall(`/sendContact`, 'post', {
            businessName: businessName,
            email: email,
            firstName: firstName,
            lastName: lastName,
            content: content
        }, false);
    }
}

export default new contactService();