/* eslint-disable react/react-in-jsx-scope */
import Food from 'components/common/Food/Food';
import { IDDiet, IDDietFoodType, IDScoreFood } from "interfaces/Database";
import { IFoodResult } from 'interfaces/IFoodResult';
import apiService from "./api.service";
import translationService from "./translation.service";
import verifierService from "./verifier.service";

class FoodsService {
    public isGreen(score: number, green: number) {
        return score > green;
    }

    public isOrange(score: number, orange: number) {
        return score >= orange;
    }

    public filterByQuality(quality: {name: 'Good' | 'Medium' | 'Bad'}[], foods: IDScoreFood[], green: number = 0, orange: number = 0): IDScoreFood[] {
        const response: IDScoreFood[] = [];

        if (quality.length === 0) {
            return foods;
        } else {
            quality.forEach(element => {
                switch (element.name) {
                    case 'Good':
                        response.push(...foods.filter((scoredFood) => scoredFood.score && this.isGreen(scoredFood.score, green)));
                        break;
                    case 'Medium':
                        response.push(...foods.filter((scoredFood) => scoredFood.score && !this.isGreen(scoredFood.score, green) && this.isOrange(scoredFood.score, orange)));
                        break;
                    case 'Bad':
                        response.push(...foods.filter((scoredFood) => scoredFood.score && !this.isOrange(scoredFood.score, orange)));
                        break;
                    default:
                        break;
                }
            });
        }

        return response;
    }

    public filterByDiet(dietList: IDDiet[], foods: IDScoreFood[]): IDScoreFood[] {
        const response: IDScoreFood[] = [];

        if (dietList.length === 0) {
            return foods;
        } else {
            dietList.forEach(diet => response.push(...foods.filter((scoredFood) => scoredFood.diet && (scoredFood?.diet?.name === diet.name) )));
        }

        return response;
    }

    public filterByType(typeList: IDDietFoodType[], foods: IDScoreFood[]): IDScoreFood[] {
        const response: IDScoreFood[] = [];

        if (typeList.length === 0) {
            return foods;
        } else {
            typeList.forEach(type => response.push(...foods.filter((scoredFood) => scoredFood.type && (scoredFood?.type?.name === type.name) )));
        }

        return response;
    }

    public searchByName(search: string, foods: IDScoreFood[]): IDScoreFood[] {
        let response: IDScoreFood[] = [];

        if (!verifierService.checkString(search, { len: { min: 1 } })) {
            return foods;
        } else {
            const re = new RegExp(search, "gmi");
            response = foods.filter((food) => translationService.translateFood(food.food).match(re));
        }

        return response;
    }

    private sortByScore(order: 'asc' | 'desc', a: IDScoreFood, b: IDScoreFood): number {
        const isA = 1;
        const isB = -1;

        if (a.score) {
            if (b.score) {
                if (order === "desc") {
                    if (a.score < b.score) {
                        return isA;
                    } else {
                        return isB;
                    }
                } else {
                    if (a.score > b.score) {
                        return isA;
                    } else {
                        return isB;
                    }
                }
            } else {
                return isA;
            }
        } else {
            return isB;
        }
    }

    private sortByName(order: 'asc' | 'desc', a: IDScoreFood, b: IDScoreFood): number {
        const isA = 1;
        const isB = -1;

        const aFoodName = translationService.translateFood(a.food);
        const bFoodName = translationService.translateFood(b.food);

        if (aFoodName) {
            if (bFoodName) {
                if (order === 'desc') {
                    if (aFoodName > bFoodName) {
                        return isA;
                    } else {
                        return isB;
                    }
                } else {
                    if (aFoodName < bFoodName) {
                        return isA;
                    } else {
                        return isB;
                    }
                }
            } else {
                return isA;
            }
        } else {
            return isB;
        }
    }

    private sorting(sortType: string, order: 'asc' | 'desc', foods: IDScoreFood[]): IDScoreFood[] {
        let response: IDScoreFood[] = [];

        switch (sortType) {
            case 'score':
                response = foods.sort((a, b) => this.sortByScore(order, a, b));
                break;
            case 'name':
                response = foods.sort((a, b) => this.sortByName(order, a, b));
                break;
        
            default:
                return foods;
        }

        return response;
    }

    public sort(orderVal: string, foods: IDScoreFood[]): IDScoreFood[] {
        let type = 'score';
        let order: 'asc' | 'desc' = 'desc';

        const tmp_sort = orderVal.split('_');

        type = tmp_sort[0];
        order = tmp_sort[1] as 'asc' | 'desc';

        return this.sorting(type, order, foods);
    }

    public renderFoods(foods: IDScoreFood[], greenValue: number = 0, orangeValue: number = 0, handleClick?: (food: IDScoreFood) => void) {
        return foods.map((food, index) => (
            <Food
                key={index}
                color={food.score
                    ? this.isGreen(food.score, greenValue)
                        ?'var(--dashboard-main-color)'
                        : this.isOrange(food.score, orangeValue)
                            ? 'var(--orange)'
                            : 'var(--red)'
                    : 'var(--gray)'}
                handleClick={handleClick ? () => handleClick(food) : () => void(0)}
                name={food.food}
            />
        ))
    }

    public getFoodColor(foodScore: number, greenLimit?: number, orangeLimit?: number) {
        if (foodScore < (orangeLimit ? orangeLimit : 100)) {
            return 'var(--red)';
        } else if (foodScore < (greenLimit ? greenLimit : 200)) {
            return 'var(--orange)';
        } else {
            return 'var(--dashboard-main-color)';
        }
    }

    public getFoods = async (limit?: number) => {
        return await apiService.makeApiCall<IFoodResult>(`/api/foods/scored${limit ? '?limit='+limit : ''}`, 'get');
    }

    public getDashboardFoods = async () => {
        return await apiService.makeApiCall<IFoodResult>(`/api/foods/scored/dashboardHome`, 'get');
    }
}

export default new FoodsService();