import { Button } from 'components';
import CustomLoader from 'components/CustomLoader';
import 'components/DoctorForms/FormPrescription/scss/FormPrescription.scss';
import GoBack from "components/DoctorPatients/components/GoBack";
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { UserContext } from 'context/User/UserContext';
import { IDatabasePrescription } from "interfaces/Database/IDatabasePrescription";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { IoIosPaper } from 'react-icons/io';
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import fileService from "services/file.service";
import prescriptionService from "services/prescription.service";

const Prescription: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('patientDetail');
    const { user } = useContext(UserContext);
    const { patientId, appointmentId } = useParams<({ patientId: string, appointmentId: string })>();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ prescription, setPrescription ] = useState<IDatabasePrescription>();
    const [ patient, setPatient ] = useState<IDatabaseUser>();

    const [ isPrescriptionDownloading, setIsPrescriptionDownloading ] = useState<boolean>(false);
    const [ prescriptionPDF, setPrescriptionPDF ] = useState<string>();
    const [ isDietaryDownloading, setIsDietaryDownloading ] = useState<boolean>(false);
    const [ dietaryPDF, setDietaryPDF ] = useState<string>();

    const fetchPatient = async () => {
        setIsLoading(true);

        await doctorService.getPatientDetails(patientId)
            .then(async (res) => {
                setPatient(res.result);
                setPrescription(res.prescription);

                if (res.prescription?.id) {
                    await prescriptionService.getPrescriptionPDF(res.prescription?.id)
                        .then(res => setPrescriptionPDF(res))
                        .catch(err => console.warn(err));

                    await prescriptionService.getDietarySupplementPDF(res.prescription?.id)
                        .then(res => setDietaryPDF(res))
                        .catch(err => console.warn(err));
                }
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchPatient();
    }, [0]);

    const handleDownloadPrescriptionPDF = () => {
        if (prescription?.id) {
            setIsPrescriptionDownloading(true);
            if (prescriptionPDF) {
                fileService.downloadFile(prescriptionPDF, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-prescription_${prescription.id}` });
                setIsPrescriptionDownloading(false);
            } else {
                prescriptionService.getPrescriptionPDF(prescription?.id)
                    .then(res => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-prescription_${prescription.id}` }))
                    .catch(err => console.warn(err))
                    .finally(() => setIsPrescriptionDownloading(false));
            }
        }
    }

    const handleDownloadDietaryPDF = () => {
        if (prescription?.id) {
            setIsDietaryDownloading(true);
            if (dietaryPDF) {
                fileService.downloadFile(dietaryPDF, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-dietary_supplement_${prescription.id}` })
                setIsDietaryDownloading(false);
            } else {
                prescriptionService.getPrescriptionPDF(prescription?.id)
                    .then(res => fileService.downloadFile(res, { filename: `${patient?.patientInfo?.firstname}_${patient?.patientInfo?.lastname}-dietary_supplement_${prescription.id}` }))
                    .catch(err => console.warn(err))
                    .finally(() => setIsDietaryDownloading(false));
            }
        }
    }

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    const handleFormNewPrescription = () => {
        history.push(`/account/patient/${patientId}/prescription/${appointmentId}/new`);
    }

    const handleFormNewDietarySupplement = () => {
        history.push(`/account/patient/${patientId}/dietarySupplement/${appointmentId}/new`);
    }

    return (
        <div id='form-prescription' className="flex-column full-width">
            <GoBack className="MavenPro-Black" containerStyle={{ left: 0, marginRight: 'auto' }} text={patient?.patientInfo?.firstname + ' ' + patient?.patientInfo?.lastname} handleGoBack={handleGoBack} />
            <div className="flex-row align-items-stretch flex-wrap dashboard-container-gap full-width">
                {isLoading
                    ? <CustomLoader />
                    : <Fragment>
                        <DefaultContainer
                            title={<h2 className='bold'>{t.translate('pdf.prescription.medication.title')}</h2>}
                            body={
                                !prescriptionPDF
                                    ? <IoIosPaper size={100} color={"var(--dashboard-main-color)"} />
                                    : <embed
                                        style={{ aspectRatio: '1/1' }}
                                        id="pdf-visual"
                                        src={prescriptionPDF}
                                        height="100%"
                                        width="100%">
                                    </embed>
                            }
                            footer={
                                <div className='flex-row'>
                                    {user?.isDoctorOf(patient) && <Button onClick={() => handleFormNewPrescription()} label={t.translate('pdf.edit')}/>}
                                    <Button disabled={isPrescriptionDownloading} onClick={() => handleDownloadPrescriptionPDF()} label={t.translate('pdf.card.download')}/>
                                </div>
                            }
                        />
                        <DefaultContainer
                            title={<h2 className='bold'>{t.translate('pdf.prescription.dietarySupplement.title')}</h2>}
                            body={
                                !dietaryPDF
                                    ? <IoIosPaper size={100} color={"var(--dashboard-main-color)"} />
                                    : <embed
                                        id="pdf-visual"
                                        src={dietaryPDF}
                                        height="100%"
                                        width="100%">
                                    </embed>
                            }
                            footer={
                                <div className='flex-row'>
                                    {(user?.isDoctorOf(patient) || (user?.isNutritionistOf(patient))) && <Button onClick={() => handleFormNewDietarySupplement()} label={t.translate('pdf.edit')}/>}
                                    <Button disabled={isDietaryDownloading} onClick={() => handleDownloadDietaryPDF()} label={t.translate('pdf.card.download')}/>
                                </div>
                            }
                        />
                    </Fragment>
                }
            </div>
        </div>
    )
}

export default Prescription;