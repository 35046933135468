import { InputSlider } from "components";
import { IDSurveyAnswer } from "interfaces/Database";
import { IQuestion } from "interfaces/Survey/IQuestion";
import React, { useEffect, useState } from "react";
import translationService from "services/translation.service";
import "../scss/Question.scss";

const QuestionSlider: React.FC<({
    question: IQuestion;
    handleValidation: (isValid: boolean) => void;
    handleUserAnswer: (params: (string|number)[]) => void;
    previousAnswers?: IDSurveyAnswer[];
})> = ({
    question,
    handleValidation,
    handleUserAnswer,
    previousAnswers
}) => {
    const [indexSlider, setIndexSlider] = useState<number>(0);

    useEffect(() => {
        handleUserAnswer([question.answers[indexSlider]]);
    }, [indexSlider, question.answers, handleUserAnswer]);

    useEffect(() => {
        if (previousAnswers && previousAnswers.length > 0) {
            const previousAnswer = previousAnswers[previousAnswers.length-1];
            if (parseInt(previousAnswer.answer)) {
                setIndexSlider(parseInt(previousAnswer.answer));
            }
        }
    }, [previousAnswers]);

    useEffect(() => {
        handleValidation(indexSlider !== -1);
    }, [indexSlider, question.answers, handleValidation, handleUserAnswer]);

    return (
        <div className="question-wrapper">
            <h2>{translationService.translateSurveyQuestion(question.code)}</h2>
            {question.description && <span id="question-description">{translationService.translateSurveyDescription(question.code)}</span>}
            <InputSlider
                value={indexSlider}
                max={question.answers.length - 1}
                onChange={(e) => setIndexSlider(e.currentTarget.valueAsNumber)}
            />
            <p id="slider-label" className="MavenPro-black">{translationService.translateSurveyAnswer(question.code, question.answers[indexSlider])}</p>
        </div >
    );
}

export default QuestionSlider;