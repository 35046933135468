import { Button, InformationCircleIcon } from "components";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { IDatabaseAnalysisKit } from "interfaces/Database/IDatabaseAnalysisKit";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import DefaultContainer from "../DefaultContainer/DefaultContainer";
import PatientSubscriptionTracking, { step } from "./components/PatientSubscriptionTracking";
import TrackingStepDetail from "./modal/TrackingStepDetail";
import './style/SubscriptionTracking.scss';

const SubscriptionTracking: React.FC<({
    tracking?: ISubscriptionTrackingSteps
})> = ({
    tracking
}) => {
    const t = useTranslation('components/SubscriptionTracking');
    const history = useHistory();

    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const [ isShow, setIsShow ] = useState<boolean>(false);
    const toggleIsShow = () => setIsShow(!isShow);
    const [ currentStep, setCurrentStep ] = useState<step>();
    const [ nextStep, setNextStep ] = useState<step>();

    const analysisKit: IDatabaseAnalysisKit | undefined = tracking?.analysisKit;

    return (
        <DefaultContainer
            title={
                <div className="flex-row full-width space-between">
                    <div className="flex-row">
                        <InformationCircleIcon className="clickable" style={{ marginRight: '10px' }} onClick={() => toggleIsShow()} />
                        <div className="flex-column-start">
                            <h1 className="container-title bold">
                                {t.translate('title')}{subscriptionTrackingDetail?.subscriptionPlan?.type?.name ? (' - ' + t.translateSubscriptionTypeName(subscriptionTrackingDetail?.subscriptionPlan.type?.name)) : ''}
                            </h1>
                            {(analysisKit && analysisKit?.activated && analysisKit.verificationCode) && <span id="order-id">#{analysisKit.verificationCode}</span>}
                        </div>
                    </div>

                    <Button label={t.translate('results-btn')} onClick={() => history.push('/account/tests')}/>
                </div>
            }

            body={
                <Fragment>
                    {!tracking?.kitClaim?.isDone && <div className="flex-column">
                        <h3 style={{ textAlign: 'center' }}>{t.translate('no-solution')}</h3>
                    </div>}
                    <PatientSubscriptionTracking tracking={tracking} setCurrentStep={setCurrentStep} setNextStep={setNextStep} />
                    <TrackingStepDetail isShow={isShow} toggleIsShow={toggleIsShow} currentStep={currentStep} nextStep={nextStep} />
                </Fragment>
            }
        />
    )
}

export default SubscriptionTracking;