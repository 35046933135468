import { Input, Label, Loader, MultipleSelect, Select } from "components";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ModalFoodDetail from "components/common/Modals/FoodDetails/FoodDetail";
import { IDatabaseDiet } from "interfaces/Database/IDatabaseDiet";
import { IDatabaseDietFoodType } from "interfaces/Database/IDatabaseDietFoodType";
import { IDatabaseScoreFood } from "interfaces/Database/IDatabaseScoreFood";
import { IDietFoodQuality } from "interfaces/IDietFoodQuality";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import foodsService from "services/foods.service";
import translationService from "services/translation.service";

type IFoodOrder = 'score_desc' | 'score_asc' | 'name_desc' | 'name_asc';

const Foods: React.FC = () => {
    const t = useTranslation('dashboard/common/Foods');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isRefreshing, setIsRefreshing ] = useState<boolean>(false);
    const [ selectedFoodModal, setSelectedFoodModal ] = useState<IDatabaseScoreFood>();

    const [ allDietNames, setAllDietNames ] = useState<IDatabaseDiet[]>([]);
    const [ selectedDiets, setSelectedDiets ] = useState<IDatabaseDiet[]>([]);

    const [ allFoodTypes, setAllFoodTypes ] = useState<IDatabaseDietFoodType[]>([]);
    const [ selectedFoodTypes, setSelectedFoodTypes ] = useState<IDatabaseDietFoodType[]>([]);

    const [ selectedQualities, setSelectedQualities ] = useState<IDietFoodQuality[]>([]);

    const [ order, setOrder ] = useState<IFoodOrder>('score_desc');

    const [ searchFoodName, setSearchFoodName ] = useState<string>("");

    const [ orangeColor, setOrangeColor ] = useState<number>(0);
    const [ greenColor, setGreenColor ] = useState<number>(0);

    const [ allFoods, setAllFoods ] = useState<IDatabaseScoreFood[]>([]);
    const [ filteredFoods, setFilteredFoods ] = useState<IDatabaseScoreFood[]>([]);

    function loadDiets(foodList: IDatabaseScoreFood[]) {
        const tmp_diet_list: IDatabaseDiet[] = [];

        foodList.forEach(element => {
            if (tmp_diet_list.filter((diet) => diet.name === element.diet?.name).length === 0 && element.diet) {
                tmp_diet_list.push(element.diet);
            }
        });

        setAllDietNames(tmp_diet_list);

        const query = new URLSearchParams(document.location.search);

        const diets = query.get('diets')?.split(';');
        
        if (diets && diets.length > 0) {
            const choosedList: IDatabaseDiet[] = [];

            diets?.forEach((diet) => {
                choosedList.push(tmp_diet_list.filter((e) => e.name === diet)[0]);
            });
        
            setSelectedDiets(choosedList);
        }
    }

    function loadTypes(foodList: IDatabaseScoreFood[]) {
        const tmp_types_list: IDatabaseDietFoodType[] = [];

        foodList.forEach(element => {
            if (element.type && tmp_types_list.filter((type) => type.name === element.type?.name).length === 0) {
                tmp_types_list.push(element.type);
            }
        });

        setAllFoodTypes(tmp_types_list);
    }

    const fetchScoredFoods = async () => {
        setIsLoading(true);

        foodsService.getFoods()
            .then((res) => {
                setOrangeColor(res.colors.orange);
                setGreenColor(res.colors.green);

                setAllFoods(res.foods);

                const d = foodsService.sort(order, res.foods);
                setFilteredFoods(d);

                loadDiets(res.foods);
                loadTypes(res.foods);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchScoredFoods();
    }, []);

    useEffect(() => {
        setIsRefreshing(true);
        const delayDebounceFn = setTimeout(() => {
            let tmp_foods = allFoods;

            tmp_foods = foodsService.searchByName(searchFoodName, tmp_foods);
            tmp_foods = foodsService.filterByQuality(selectedQualities, tmp_foods, greenColor, orangeColor);
            tmp_foods = foodsService.filterByDiet(selectedDiets, tmp_foods);
            tmp_foods = foodsService.filterByType(selectedFoodTypes, tmp_foods);

            tmp_foods = foodsService.sort(order, tmp_foods);

            setFilteredFoods(tmp_foods);

            setIsRefreshing(false);
        }, 2000)
      
        return () => clearTimeout(delayDebounceFn);
    }, [allFoods, searchFoodName, selectedQualities, selectedDiets, selectedFoodTypes]);

    function handleChangeOrder(newOrder: IFoodOrder) {
        const tmp_foods = foodsService.sort(newOrder, filteredFoods);

        setFilteredFoods(tmp_foods);
        setOrder(newOrder);
    }

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate('header.pre')} title={t.translate('header.title')} />
            <DefaultContainer
                title={<h2 className="bold">{t.translate('filters.title')}</h2>}
                body={
                    <Fragment>
                        <div className="flex-row space-around" style={{ minWidth: '400px' }}>
                            <div className="flex-column-start" style={{ width: '45%' }}>
                                <Label for="foodName" label={`${t.translate('filters.foodName')} :`} />
                                <Input
                                    name="foodName"
                                    value={searchFoodName}
                                    setValue={(e) => setSearchFoodName(e.currentTarget.value)}
                                />
                            </div>
                            <div className="flex-column-start" style={{ width: '45%' }}>
                                <Label for="foodQuality" label={`${t.translate('filters.quality.label')} :`} />
                                <MultipleSelect
                                    name="foodQuality"
                                    baseItems={[ { name: 'Good' }, { name: 'Medium' }, { name: 'Bad' } ]}
                                    selectedItems={selectedQualities}
                                    setSelectedItems={setSelectedQualities}
                                    comparisonKey="name"
                                    displayKey="name"
                                    transformer={(e) => t.translate('filters.quality.'+e)}
                                />
                            </div>
                        </div>
                        <div className="flex-row space-around" style={{ minWidth: '400px' }}>
                            <div className="flex-column-start" style={{ width: '45%' }}>
                                <Label for="foodDiet" label={`${t.translate('filters.diet')} :`} />
                                <MultipleSelect
                                    name="foodDiet"
                                    baseItems={allDietNames}
                                    selectedItems={selectedDiets}
                                    setSelectedItems={setSelectedDiets}
                                    comparisonKey="id"
                                    displayKey="name"
                                    transformer={(e) => translationService.translateDiet(e)}
                                />
                            </div>
                            <div className="flex-column-start" style={{ width: '45%' }}>
                                <Label for="foodType" label={`${t.translate('filters.type')} :`} />
                                <MultipleSelect
                                    name="foodType"
                                    baseItems={allFoodTypes}
                                    selectedItems={selectedFoodTypes}
                                    setSelectedItems={setSelectedFoodTypes}
                                    comparisonKey="id"
                                    displayKey="name"
                                    transformer={(e) => translationService.translateFoodType(e)}
                                />
                            </div>
                        </div>
                    </Fragment>
                }
            />
            <DefaultContainer
                title={
                    <Fragment>
                        <h2 className="bold">{t.translate('body.title')} ({filteredFoods.length})</h2>
                        <div className="flex-row">
                            <Label for="foodSort" labelStyle={{ marginRight: '1vw' }} label={t.translate('body.sort')} />
                            <Select
                                name="foodSort"
                                value={order}
                                setValue={(e) => handleChangeOrder(e.currentTarget.value as IFoodOrder)}
                                options={
                                    <Fragment>
                                        <option value={'score_desc'}>{t.translate('body.order.score_desc')}</option>
                                        <option value={'score_asc'}>{t.translate('body.order.score_asc')}</option>
                                        <option value={'name_desc'}>{t.translate('body.order.name_desc')}</option>
                                        <option value={'name_asc'}>{t.translate('body.order.name_asc')}</option>
                                    </Fragment>
                                }
                            />
                        </div>
                    </Fragment>
                }
                body={
                    <div className="flex-row" style={{ flexWrap: 'wrap' }}>
                        {isLoading || isRefreshing
                            ? <Loader />
                            : filteredFoods.length === 0
                                ? <p>{t.translate('body.none')}</p>
                                : foodsService.renderFoods(filteredFoods, greenColor, orangeColor, (food) => setSelectedFoodModal(food))
                        }
                    </div>
                }
            />

            <ModalFoodDetail
                handleClose={() => setSelectedFoodModal(undefined)}
                food={selectedFoodModal}
                greenLevel={greenColor}
                orangeLevel={orangeColor}
            />
        </Fragment>
    )
}

export default Foods;