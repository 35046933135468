import { Button, Input, Label, Phone } from "components";
import { Modal } from "components/common/Modal";
import { IDUser } from "interfaces/Database";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import verifierService from "services/verifier.service";

const ModalEditDoctorContactInfo: React.FC<({
    showModal: boolean
    handleClose: () => void
    handleSuccess: () => void
    user?: IDUser
})> = ({ showModal, handleClose, handleSuccess, user }) => {
    const t = useTranslation('profile/doctor/contact');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ email, setEmail ] = useState<string>();
    const [ phoneNumber, setPhoneNumber ] = useState<string>();
    const [ countryCode, setCountryCode ] = useState<string>();

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'auto';
    }

    const successModal = () => {
        handleSuccess();
        closeModal();
    }

    useEffect(() => {
        if (user) {
            setEmail(user?.email);
            setPhoneNumber(user?.phone?.number);
            setCountryCode(user?.phone?.country?.phonecode);
        }
    }, [user]);

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    async function handleEditUser() {
        setIsLoading(true);

        doctorService.updateInformations({
            phoneCode: countryCode,
            phoneNumber: phoneNumber
        })
            .then(() => successModal())
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate("titleModal")}
            footer={
                <div className="flex-row flex-end">
                    <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                    <Button label={t.translate('save')} onClick={() => handleEditUser()} disabled={isLoading} displayLoader={isLoading} />
                </div>
            }
        >
            <Fragment>
                <div className="flex-column-start full-width" style={{ margin: '1vh 0' }}>
                    <Label name="phone" label={t.translate('phone')} required />
                    <Phone
                        required
                        labelledBy="phone"
                        defaultCountryCode={countryCode}
                        setCountryCode={setCountryCode}
                        defaultPhoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        checkFunction={() => verifierService.checkPhone(countryCode, phoneNumber)}
                    />
                </div>
                <div className="flex-column-start full-width" style={{ margin: '1vh 0' }}>
                    <Label for="email" label={t.translate('email')} required />
                    <Input
                        disabled
                        required
                        name="email"
                        type="email"
                        value={email}
                        setValue={(e) => setEmail(e.currentTarget.value)}
                        checkFunction={() => verifierService.checkEmail(email)}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
            </Fragment>
        </Modal>
    );
}

export default ModalEditDoctorContactInfo;