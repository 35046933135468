import React, { CSSProperties } from "react";
import { RequireAtLeastOne } from "types";

export interface IDashboardLabelProps {
    required?: boolean;
    requiredColor?: string;
    requiredContent?: string;
    label: string;
    labelClassName?: string;
    labelStyle?: CSSProperties;
    name?: string;
    for?: string;
}

export type DashboardLabelProps = RequireAtLeastOne<IDashboardLabelProps, 'for' | 'name'>;

const DashboardLabel: React.FC<DashboardLabelProps> = (props) => {
    return (
        <label
            className={`label-input ${props.labelClassName}`}
            style={props.labelStyle}
            htmlFor={props.for}
            id={props.name}
        >
            {props.required && <span style={{ color: props?.requiredColor ? props.requiredColor : 'red' }}>{props?.requiredContent ? props.requiredContent : '*'}</span>}
            {props.label}
        </label>
    )
}

export default DashboardLabel;