import { Button, Input, Label, LeftArrowIcon } from "components";
import "components/Login/scss/Login.scss";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { LanguageContext } from "context/Language/LanguageContext";
import { UserContext } from "context/User/UserContext";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "services";
import { default as userAuth } from 'services/user-auth.service';

const Login: React.FC = () => {
    const t = useTranslation('pages/login');
    const { refreshUser, refreshProfilePicture } = useContext(UserContext);
    const { refreshLanguage } = useContext(LanguageContext);
    const { refreshType } = useContext(UserTypeContext);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ username, setUserName ] = useState<string>();
    const [ password, setPassword ] = useState<string>();
    const history = useHistory();

    const search = useLocation().search;

    useEffect(() => {
        const handleRedirectIfLogged = async () => {
            if (await userAuth.isUserConnected()) {
                const redirectTo = new URLSearchParams(search).get("redirectTo");

                history.push(redirectTo ?? '/account/home');
            }
        }

        handleRedirectIfLogged();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setError(undefined);

        if (username && password) {
            userAuth.login({ username, password })
                .then(() => {
                    refreshUser();
                    refreshProfilePicture();
                    refreshLanguage();
                    refreshType();

                    const redirectTo = new URLSearchParams(search).get("redirectTo");

                    history.push(redirectTo ?? '/account/home');
                })
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        } else {
            setError('Missing email or password');
            setIsLoading(false);
        }
    }

    const handleContactUs = () => {
        history.push('/contact-us');
    }

    const handleCreateAccount = () => {
        history.push({
            pathname: '/register',
        });
    }

    const handleResetPassword = () => {
        history.push('/account-recovery', { email: username });
    }

    return (
        <div id="login" className="flex-column flex-grow">
            <div id="wrapper" className="column shadow">
                <LeftArrowIcon onClick={() => history.go(-1)} />
                <h1 id="login-title" className="font-title-bold">
                    {t.translate("title")}
                </h1>
                <p id="login-sub-title">
                    {t.translate("subtitle")}
                </p>
                <form id="login-form" onSubmit={handleSubmit}>
                    <div className="flex-row flex-wrap" style={{ minWidth: '160px' }}>
                        <label className="login-label">
                            <Label for="login-email" label={t.translate('email.label')} required />
                            <Input
                                required
                                name="login-email"
                                disabled={isLoading}
                                type="email"
                                placeholder={t.translate("email.placeholder")}
                                value={username}
                                setValue={(e) => setUserName(e.currentTarget.value)}
                            />
                        </label>
                        <label className="login-label">
                            <Label for="login-password" label={t.translate('password.label')} required />
                            <Input
                                required
                                name="login-password"
                                disabled={isLoading}
                                type="password"
                                placeholder={t.translate("password.placeholder")}
                                value={password}
                                setValue={(e) => setPassword(e.currentTarget.value)}
                            />
                        </label>
                    </div>
                    <ErrorMessage error={error} />
                    <div className="flex-row space-between" style={{ padding: '2vh 0' }}>
                        <span onClick={handleResetPassword} id="forgot-password-text">
                            {t.translate("forgot-password")}
                        </span>
                        <Button type="submit" label={t.translate('login')} disabled={isLoading} />
                    </div>
                </form>
            </div>
            <div id="wrapper" className="flex-column shadow">
                <h2 className="font-title-bold" style={{ color: 'var(--gray)' }}>{t.translate('no-account.title')}</h2>
                <div className="flex-row full-width flex-wrap">
                    <div className="flex-column" style={{ width: '40%', minWidth: '220px' }}>
                        <p>{t.translate('no-account.particular.label')}</p>
                        <Button label={t.translate('no-account.particular.button')} onClick={() => handleCreateAccount()} disabled={isLoading} />
                    </div>
                    <div className="flex-column" style={{ width: '40%', minWidth: '220px' }}>
                        <p>{t.translate('no-account.company.label')}</p>
                        <Button label={t.translate('no-account.company.button')} onClick={() => handleContactUs()} disabled={isLoading} />
                    </div>
                    <div className="flex-column" style={{ width: '40%', minWidth: '220px' }}>
                        <p>{t.translate('no-account.doctor.label')}</p>
                        <Button label={t.translate('no-account.company.button')} onClick={() => handleContactUs()} disabled={isLoading} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;