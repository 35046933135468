import banner from 'assets/our_solution/banner.webp';
import { Button } from 'components';
import 'components/OrderKit/scss/OrderKit.scss';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import { LanguageContext } from 'context/Language/LanguageContext';
import { SubscriptionTrackingDetailContext } from 'context/SolutionProcessingContext';
import { UserContext } from 'context/User/UserContext';
import { RoleName } from 'interfaces/User/IUserRole';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'services';
import userAuthService from 'services/user-auth.service';
import { DSFifthPage, DSFirstPage, DSFourthPage, DSSecondPage, DSSixthPage, DSWelcome } from './DoctorSteps';
import { SSFifthPage, SSFirstPage, SSFourthPage, SSSecondPage, SSSixthPage, SSWelcome } from './StandaloneSteps';
import "./styles/Help.scss";

const Help: React.FC<({
    toggleHelp: () => void,
    type: RoleName
})> = ({
    toggleHelp,
    type
}) => {
    const t = useTranslation('dashboard/standalone/help');

    const { user, refreshUser } = useContext(UserContext);
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const { language } = useContext(LanguageContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ steps, setSteps ] = useState<JSX.Element[]>([]);
    const [ currentStep, setCurrentStep ] = useState<number>(1);

    useEffect(() => {
        switch (type) {
            case 'Doctor':
                setSteps([
                    <DSWelcome key={'doctor_step_1'} />,
                    <DSFirstPage key={'doctor_step_2'} />,
                    <DSSecondPage key={'doctor_step_3'} />,
                    <DSFourthPage key={'doctor_step_4'} />,
                    <DSFifthPage key={'doctor_step_5'} />,
                    <DSSixthPage key={'doctor_step_6'} />
                ]);
                break;
            default :
                if (subscriptionTrackingDetail?.subscriptionPlan?.isTypeCancer()) {
                    setSteps([
                        <SSWelcome key={'standalone_step_1'} />,
                        <SSFirstPage key={'standalone_step_2'} />,
                        <SSSecondPage key={'standalone_step_3'} />,
                        <SSFourthPage key={'standalone_step_4'} />,
                        <SSSixthPage key={'standalone_step_6'} />
                    ]);
                } else {
                    setSteps([
                        <SSWelcome key={'standalone_step_1'} />,
                        <SSFirstPage key={'standalone_step_2'} />,
                        <SSSecondPage key={'standalone_step_3'} />,
                        <SSFourthPage key={'standalone_step_4'} />,
                        <SSFifthPage key={'standalone_step_5'} />,
                        <SSSixthPage key={'standalone_step_6'} />
                    ]);
                }
        }
    }, [type, language]);

    const nextStep = () => {
        if (currentStep <= steps.length) {
            setCurrentStep(currentStep+1);
        }
    }

    const prevStep = () => {
        if (currentStep-1 > 0) {
            setCurrentStep(currentStep-1);
        }
    }

    const handleFinish = async () => {
        if (user?.firstLogin) {
            setIsLoading(true);
            userAuthService.setFirstLogin({ firstLogin: false })
                .then(() => refreshUser())
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }

        toggleHelp();
    }

    return (
        <DefaultContainer
            header={{
                image: banner,
                imageStyle: {
                    transform: 'scaleX(-1)'
                },
                content: <h2>{t.translate('title')}</h2>
            }}
            body={steps[currentStep-1]}
            footer={
                <div className='button-wrapper'>
                    {currentStep !== 1 && <Button label={t.translate('prevBtn')} onClick={() => prevStep()} disabled={isLoading} />}
                    { currentStep !== 1 &&
                        <div className='flex-row'>
                            {steps.map((_, index) =>
                                <div key={index} onClick={() => !isLoading && setCurrentStep(index+1)} className={index+1 === currentStep ? "list-tick-actual": "list-tick"} />
                            )}
                        </div>}
                    { currentStep !== steps.length
                        ? <Button label={t.translate('nextBtn')} onClick={() => nextStep()} disabled={isLoading} />
                        : <Button label={t.translate('endBtn')} onClick={() => handleFinish()} disabled={isLoading} displayLoader={isLoading} />
                    }
                </div>
            }
        />
    )
}

export default Help;