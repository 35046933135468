import { Button } from 'components';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import React, { Fragment } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'services';
import '../scss/Questionnaire.scss';

const Questionnaire: React.FC<({
    currentStep: number
})> = ({ currentStep }) => {
    const t = useTranslation('patientDetail');

    const history = useHistory();
    const { patientId } = useParams<({ patientId: string })>();
    const questionNumber = 58;

    const handleRedirectQuestionnaireOverview = () => {
        history.push(`/account/patient/${patientId}/questionnaire`);
    }

    return (
        <DefaultContainer
            wrapperStyle={{
                flex: '1 1 400px'
            }}
            title={<h1 className="container-title bold">{t.translate("questionnaire.title")}</h1>}
            body={
                currentStep === 0
                    ? <p className='no-data'>{t.translate('questionnaire.notStarted')}</p>
                    : <Fragment>
                        <div id="circular-progressbar" className="flex-column" style={{ margin: '0 0 2vh 0' }}>
                            <CircularProgressbar
                                value={(currentStep / questionNumber) * 100}
                                text={`${currentStep}/${questionNumber}`}
                                styles={buildStyles({
                                    textColor: '#000',
                                    trailColor: 'var(--light-gray2)',
                                    pathColor: `var(--dashboard-main-color)`,
                                })}
                                className="MavenPro-bold"
                            />
                        </div>
                        <Button
                            label={t.translate("questionnaire.visualize")}
                            onClick={handleRedirectQuestionnaireOverview}
                        />
                    </Fragment>
            }
        />
    )
}

export default Questionnaire;