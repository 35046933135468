import { LeftArrowIcon } from 'components';
import RegisterForm from 'components/Register/components/RegisterForm';
import 'components/Register/scss/Register.scss';
import { LanguageContext } from 'context/Language/LanguageContext';
import { UserContext } from 'context/User/UserContext';
import { UserTypeContext } from 'context/User/UserTypeContext';
import React, { useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'services';
import userAuthService from 'services/user-auth.service';

const Register: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('pages/register');

    const { refreshUser } = useContext(UserContext);
    const { refreshLanguage } = useContext(LanguageContext);
    const { refreshType } = useContext(UserTypeContext);

    const search = useLocation().search;

    return (
        <div id="register" className="flex-column flex-grow">
            <div id="wrapper" className="column shadow">
                <LeftArrowIcon
                    className='clickable'
                    onClick={() => history.go(-1)}
                />
                <h1 id="register-title" className="font-title-bold">
                    {t.translate("title")}
                </h1>
                <p id="register-sub-title">
                    {t.translate("subtitle")}
                </p>
                <RegisterForm
                    onSuccess={({ email, password }) => userAuthService
                        .login({ username: email, password: password })
                        .then(() => {
                            refreshUser();
                            refreshLanguage();
                            refreshType();

                            const redirectTo = new URLSearchParams(search).get("redirectTo");

                            history.push(redirectTo ?? '/account/home');
                        })
                    }
                />
            </div>
        </div>
    )
}

export default Register;