import DhlEnglishTerms from "assets/DhlTermsConditions/dhl_english_terms.pdf";
import DhlFrenchTerms from "assets/DhlTermsConditions/dhl_french_terms.pdf";
import ManualEnglish from "assets/Manual/manual_english-version.pdf";
import ManualFrench from "assets/Manual/manual_french-version.pdf";
import "components/DocumentLanguageSelection/scss/DocumentLanguageSelection.scss";
import Languages from 'context/Language/Languages';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "services";

const DOCUMENT_TYPE_MANUAL = "manual";
const DOCUMENT_TYPE_DHL = "dhl";

const DocumentLanguageSelection: React.FC = () => {
    const t = useTranslation('document-language-selection');

    const { documentType } = useParams<({ documentType: string })>();
    const [indexCurrentDocument, setIndexCurrentDocument] = useState<number>(0);

    const DOCUMENTS = [
        {
            docType: DOCUMENT_TYPE_MANUAL,
            title: t.translate('manual.title'),
            documents: [
                {
                    countryCode: 'fr',
                    content: ManualFrench
                },
                {
                    countryCode: 'en',
                    content: ManualEnglish
                }
            ]
        },
        {
            docType: DOCUMENT_TYPE_DHL,
            title: t.translate('dhl.title'),
            documents: [
                {
                    countryCode: 'fr',
                    content: DhlFrenchTerms
                },
                {
                    countryCode: 'en',
                    content: DhlEnglishTerms
                }
            ]
        }
    ]

    useEffect(() => {
        initCurrentDocument();
    }, []);


    const initCurrentDocument = () => {
        DOCUMENTS.forEach((document, index) => {
            if (document.docType === documentType) {
                setIndexCurrentDocument(index);
            }
        });
    }

    const handleOpenDocument = (currentCountryCode: string) => {
        DOCUMENTS[indexCurrentDocument].documents.forEach(document => {
            if (document.countryCode === currentCountryCode) {
                window.open(document.content);
            }
        });
    }

    const renderTitle = DOCUMENTS.map((document, index) =>
        index === indexCurrentDocument &&
        <h1 key={index} id="title" className="MavenPro-black">
            {document.title}
        </h1>
    );

    const renderLanguages = Languages.map((language, index) =>
        <div className="language column" key={index} onClick={() => handleOpenDocument(language.countryCode)}>
            <img src={language.flagImage} alt={language.name} className="language-flag" />
            <div className="language-name-wrapper column">
                <span className="language-name MavenPro-bold">
                    {language.name}
                </span>
            </div>
        </div>
    );

    return (
        <div id="document-language-selection" className="column">
            <div id="language-selection-wrapper" className="MavenPro-bold column">
                {renderTitle}
            </div>
            <div id="languages-wrapper">
                {renderLanguages}
            </div>
        </div>
    );
}

export default DocumentLanguageSelection;