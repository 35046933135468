import { Loader } from "components";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { default as userAuth, default as userAuthService } from "services/user-auth.service";

const Layout: React.FC<({
    children: React.ReactNode
})> = ({ children }) => {

    const { pathname } = useLocation();
    const [ isLoading, setIsLoading ] = useState(true);
    const history = useHistory();

    const initUser = useCallback(async () => {
        const token = userAuthService.getToken();

        userAuth.checkToken({ token }).catch(() => history.push('/login'));
    }, [history]);

    useEffect(() => {
        const handleOnLoad = async () => {
            setIsLoading(true);
            await initUser();
            setIsLoading(false);
        }

        handleOnLoad();
    }, [initUser]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (isLoading) {
        return (
            <div className="column loader-container">
                <Loader />
            </div>
        )
    }
    else {
        return (
            <main style={{ minHeight: '100vh' }}>{children}</main>
        );
    }
}

export default Layout;