import { Button } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDPrescription } from "interfaces/Database";
import React, { useState } from 'react';
import { useTranslation } from 'services';
import doctorService from "services/doctor.service";
import fileService from "services/file.service";
import prescriptionService from "services/prescription.service";
import "../scss/PrescriptionList.scss";

const PrescriptionList: React.FC<({
    prescriptions: IDPrescription[]
})> = ({ prescriptions }) => {
    const t = useTranslation('dashboard/patient/prescription');
    const [ isDownloading, setIsDownloading ] = useState<{
        id: number,
        document: 'prescription' | 'dietarySupplement'
    }>();

    const handleDownloadPrescription = (prescription: IDPrescription) => {
        if (prescription) {
            setIsDownloading({
                id: prescription.id,
                document: 'prescription'
            });

            prescriptionService.getPrescriptionPDF(prescription?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${prescription?.patient?.patientInfo?.firstname}_${prescription?.patient?.patientInfo?.lastname}-prescription_${prescription.id}` }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(undefined));
        }
    }

    const handleDownloadDietarySupplement = (prescription: IDPrescription) => {
        if (prescription) {
            setIsDownloading({
                id: prescription.id,
                document: 'dietarySupplement'
            });

            prescriptionService.getDietarySupplementPDF(prescription?.id)
                .then((res) => fileService.downloadFile(res, { filename: `${prescription?.patient?.patientInfo?.firstname}_${prescription?.patient?.patientInfo?.lastname}-dietary_supplement_${prescription.id}` }))
                .catch((err) => console.warn(err))
                .finally(() => setIsDownloading(undefined));
        }
    }

    const renderPrescriptions = prescriptions.map((prescription, index) => {
        const analysisKits = prescription?.doctorAppointment?.subscriptionPlan.analysisKits ?? [];
        const analysisKit = analysisKits[analysisKits.length-1];

        return <div
            key={index}
            className="flex-row"
            style={{
                backgroundColor: 'var(--light-gray2)',
                padding: '1vh 2vw',
                marginBottom: index !== prescriptions.length-1
                    ? '1vh'
                    : '0'
            }}
        >
            <div className="flex-column" style={{ margin: '0 2vw' }}>
                <p>{t.translateReplaceValues('all-prescriptions.documentOfAnalysisKit', [
                    {
                        tag: '{{analysisKitId}}',
                        value: analysisKit.verificationCode
                    }
                ])}</p>
            </div>
            <div className="flex-column" style={{ margin: '0 2vw' }}>
                <p className="MavenPro-black text-align-center">{t.translate('last-prescription.prescription')}</p>
                <i>
                    {t.translate('last-prescription.written-by') + ' '}
                    {doctorService.formatDoctorName(prescription?.doctor?.doctorInfo)}
                </i>
                <span>
                    {t.translateReplaceValues('last-prescription.didAt', [
                        {
                            tag: '{{date}}',
                            value: t.translateDate(prescription.updatedAt)
                        }
                    ])}
                </span>
                {!prescription.prescriptionMedications || prescription.prescriptionMedications?.length === 0
                    ? <i className="text-align-center" style={{ margin: '1vh 0' }} >{t.translate('last-prescription.noPrescription')}</i>
                    : <Button
                        label={t.translate('last-prescription.button-label')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading?.id === prescription.id && isDownloading?.document === 'prescription'}
                        onClick={() => handleDownloadPrescription(prescription)}
                        style={{
                            margin: '1vh 0'
                        }}
                    />
                }
            </div>
            <div className="flex-column" style={{ margin: '0 2vw' }}>
                <p className="MavenPro-black text-align-center">{t.translate('last-prescription.dietaryComplement')}</p>
                <i>
                    {t.translate('last-prescription.written-by') + ' '}
                    {doctorService.formatDoctorName(prescription?.doctor?.doctorInfo)}
                </i>
                <span>
                    {t.translateReplaceValues('last-prescription.didAt', [
                        {
                            tag: '{{date}}',
                            value: t.translateDate(prescription.updatedAt)
                        }
                    ])}
                </span>
                {!prescription.dietarySupplements || prescription.dietarySupplements?.length === 0
                    ? <i className="text-align-center" style={{ margin: '1vh 0' }} >{t.translate('last-prescription.noComplement')}</i>
                    : <Button
                        label={t.translate('last-prescription.button-label')}
                        disabled={isDownloading !== undefined}
                        displayLoader={isDownloading?.id === prescription.id && isDownloading?.document === 'dietarySupplement'}
                        onClick={() => handleDownloadDietarySupplement(prescription)}
                        style={{
                            margin: '1vh 0'
                        }}
                    />
                }
            </div>
        </div>
    })

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate('all-prescriptions.title')}</h1>}
            body={prescriptions.length > 0
                ? renderPrescriptions
                : <p className="no-data">
                    {t.translate('all-prescriptions.no-prescription')}
                </p>
            }
        />
    );
}

export default PrescriptionList;