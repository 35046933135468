import { Loader } from "components";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { IDUser } from "interfaces/Database";
import { IStandaloneSidebarActions } from "interfaces/IStandaloneSidebarActions";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserService, useTranslation } from "services";
import standaloneService from "services/standalone.service";
import DefaultSidebar from "./DefaultSidebar";

const StandaloneSidebar: React.FC<({
    menuStatus?: IMenuStatus,
    user?: IDUser
})> = ({
    menuStatus,
    user
}) => {
    const t = useTranslation('layout/sidebar/standalone');

    const { subscriptionTrackingDetail: solutionProcessing, reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const history = useHistory();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ sidebarActions, setSidebarActions ] = useState<IStandaloneSidebarActions>();

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            standaloneService.getSidebarActions()
                .then((res) => setSidebarActions(res)),
            reloadSubscriptionTrackingDetail()
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <DefaultSidebar
            displayShare={true}
            menuStatus={menuStatus}
            menuItems={UserService.handleStandaloneMenuItems(solutionProcessing)}
            helloMessage={
                <p className="sidebar-title">{t.translate('hello')} <span id="last-name">{user?.patientInfo?.firstname ?? 'PATIENT'}</span></p>
            }
            subMessage={
                <p id="subTitle">{t.translate('sub')}</p>
            }
            button={isLoading
                ? <Loader />
                : <div className="flex-column">
                    {UserService.handleStandaloneSidebar(t, history, sidebarActions, solutionProcessing)}
                </div>
            }
        />
    );
}

export default StandaloneSidebar;