import phoneConfirmation from "assets/phone-confirmation.png";
import { Button, CheckCircleIcon, Input, Label, Loader } from "components";
import { UserContext } from "context/User/UserContext";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { useContext, useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import "../../scss/ClaimKitConfirmPhone.scss";

const MAX_LENGTH_CODE = 7;
const SEND_BACK_CODE_TIME = 59000;

const ClaimKitConfirmPhone: React.FC<({
    handleNextStep: () => void
})> = ({ handleNextStep }) => {
    const t = useTranslation('firstConnection');
    const { user, refreshUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ isConfirmationDone, setIsConfirmationDone ] = useState<boolean>(false);
    const [ smsCode, setSmsCode ] = useState<string>();
    const [ countDownKey, setCountDownKey ] = useState<number>(1);
    const [ countDownTime, setCountDownTime ] = useState<number>(Date.now() + SEND_BACK_CODE_TIME);

    useEffect(() => {
        if (user) {
            handleReceiveSmsCode(user);
            setIsLoading(false);
        } else {
            refreshUser()
                .then((res) => handleReceiveSmsCode(res))
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }
    }, []);

    const getSmsCode = async () => {
        setCountDownKey(countDownKey + 1);
        setCountDownTime(Date.now() + SEND_BACK_CODE_TIME);
        setSmsCode("");
        try {
            await userAuthService.getSmsCode();
        } catch (err) {
            console.warn(err);
        }
    }

    async function verifySMSCode() {
        setIsLoading(true);

        if (smsCode) {
            userAuthService.checkSMSCode({ code: smsCode })
                .then(() => handleNextStep())
                .catch((err) => console.warn(err))
                .finally(() => {
                    setIsLoading(false);
                    setSmsCode(undefined);
                });
        }
    }

    // Renderer callback with condition
    const renderCountDown = ({ seconds, completed }: CountdownRenderProps) => {
        if (completed) {
            return <span id="send-back-text" onClick={getSmsCode}>{' '}{t.translate('resendCodeSMS')}</span>;
        } else {
            return <span id="send-back-text-disable">{' '}{t.translate('resendCodeSMS')} ({seconds})</span>;
        }
    };

    const handleInputCode = (code: string) => {
        if (code.length <= MAX_LENGTH_CODE) {
            setSmsCode(code);
        }
    }

    const handleReceiveSmsCode = async (user: IDatabaseUser) => {
        if (!user.phoneIsVerified) {
            try {
                await userAuthService.getSmsCode();
            } catch (err) {
                console.warn(err);
            }
        } else {
            setIsConfirmationDone(true);
        }
    }

    if (isLoading) {
        return (
            <Loader />
        )
    }
    else {
        return (
            <div id="claim-kit-confirm-phone" className="column">
                { isConfirmationDone
                    ? <div id="confirmation-container" className="column">
                        <div className="column" id="text-container">
                            <CheckCircleIcon size={70} />
                            <p id="title" className="MavenPro-bold">
                                {t.translate('successCodeSMS')}
                            </p>
                        </div>
                        <button className="dashboardBtn" onClick={() => { setIsLoading(true); handleNextStep(); }}>{t.translate('next')}</button>
                    </div>
                    : <div className="row" id="phone-container">
                        <div id="image-container">
                            <img src={phoneConfirmation} alt="phone" />
                        </div>

                        <div className="column" id="form-container">
                            <div className="column" id="title-container">
                                <h1 id="title">
                                    {t.translate('confirmPhone')}
                                </h1>
                                <p id="sub-title">
                                    {t.translate('textConfirmPhone')}
                                </p>
                            </div>
                            <div className="column" id="input-container">
                                <Label for="code-phone" label={t.translate('codePhone')} required />
                                <Input
                                    name="code-phone"
                                    type="number"
                                    value={smsCode || ""}
                                    setValue={(e) => handleInputCode(e.currentTarget.value)}
                                    required
                                />
                            </div>
                            <Countdown
                                date={countDownTime}
                                renderer={renderCountDown}
                                key={countDownKey}
                            />
                            <Button onClick={() => verifySMSCode()} label={t.translate('validate')} disabled={(smsCode?.length ?? 0) < 7} />
                        </div>
                    </div>
                }
            </div>
        );
    }

}

export default ClaimKitConfirmPhone;