import video_meeting from 'assets/order/microbiota_analysis/video_meeting.png';
import { useTranslation } from "services";

export interface VideoMeetingProps {
    key: number | string
}

const VideoMeeting: React.FC<VideoMeetingProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');

    return (
        <div key={props.key} className="orderSolution-section">
            <div className='flex-column'>
                <div className="orderSolution-section-title">
                    <p>3.</p>
                    <div className="flex-column-start">
                        <h2>{t.translate('microbiota_analysis.video_meeting.title')}</h2>
                    </div>
                </div>

                <p>{t.translate('microbiota_analysis.video_meeting.body')}</p>
                <p><u>{t.translate('microbiota_analysis.video_meeting.pre_list')}</u></p>

                <ul>
                    <li>{t.translate('microbiota_analysis.video_meeting.list.foods')}</li>
                    <li>{t.translate('microbiota_analysis.video_meeting.list.slowBioAging')}</li>
                    <li>{t.translate('microbiota_analysis.video_meeting.list.increaseAbsorbtion')}</li>
                    <li>{t.translate('microbiota_analysis.video_meeting.list.stimulateMicroActivities')}</li>
                    <li>{t.translate('microbiota_analysis.video_meeting.list.suppressBadActivities')}</li>
                    <li>{t.translate('microbiota_analysis.video_meeting.list.strengthenIntestinalMucosa')}</li>
                    <li>{t.translate('microbiota_analysis.video_meeting.list.optimizeGlycemaResponse')}</li>
                </ul>
            </div>
            <div className='orderSolution-img-wrapper'>
                <img src={video_meeting} />
            </div>
        </div>
    )
}

export default VideoMeeting;