import { Button } from "components/Form";
import { Modal } from "components/common/Modal";
import { useTranslation } from "services";
import "./OrderSolutionComplementary.scss";
import { CancerDetectionDetailedResults, CancerDetectionInCasePositive, CancerDetectionMobileApp, CancerDetectionTestKit } from "./components/CancerDetection";
import { MicrobiotaAnalysisDetailedResults, MicrobiotaAnalysisMobileApp, MicrobiotaAnalysisTestKit, MicrobiotaAnalysisVideoMeeting, MicrobiotaAnalysisWeTakeCare } from "./components/MicrobiotaAnalysis";

export interface OrderSolutionComplementaryProps {
    solutionName: string
    isShow: boolean
    toggleShow: () => void
}

const OrderSolutionComplementary: React.FC<OrderSolutionComplementaryProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');

    const microbiota_analysis = [
        MicrobiotaAnalysisTestKit,
        MicrobiotaAnalysisDetailedResults,
        MicrobiotaAnalysisVideoMeeting,
        MicrobiotaAnalysisWeTakeCare,
        MicrobiotaAnalysisMobileApp
    ];

    const cancer_detection = [
        CancerDetectionTestKit,
        CancerDetectionDetailedResults,
        CancerDetectionInCasePositive,
        CancerDetectionMobileApp
    ];

    const handleElements = () => {
        switch (props.solutionName) {
            case 'cancer_detection':
                return cancer_detection;

            default:
                return microbiota_analysis;
        }
    }

    return (
        <Modal
            isShow={props.isShow}
            toggleShow={props.toggleShow}
            title={t.translateSubscriptionType(props.solutionName, 'short_name')}
            footer={
                <div className="flex-column full-width">
                    <Button label={t.translate('close')} onClick={props.toggleShow} />
                </div>
            }
        >
            {handleElements()
                .map((Element, index) => <Element key={index} />)
            }
        </Modal>
    )
}

export default OrderSolutionComplementary;