import languages from 'context/Language/Languages';
import { IReplacementValue } from 'interfaces/Services/TranslationService/IReplacementValue';
import { IUserLanguage } from 'interfaces/User/IUserLanguage';
import moment from 'moment-timezone';
import reactStringReplace from 'react-string-replace';
import languageService from './language.service';
import monthService from './month.service';

class translationService {
    // manually go and set fallback language to french, from constant list in context folder
    private fallbackLng: IUserLanguage = languages[1];

    private loadTranslationFile = (filename: string = 'common', lng?: IUserLanguage) => {
        try {
            return require(`../assets/locales/${lng ? lng.countryCode : this.getFallbackLng().countryCode}/${filename}`);
        } catch (error) {
            return "FILE NOT FOUND";
        }
    }

    private isString = (str: string, valid: boolean = true): boolean => {
        if (valid) {
            return (typeof str === "string" && str.length > 0 && str !== "")
        } else {
            return (typeof str !== "string" || str.length === 0 || str === "" || !str)
        }
    }

    private isArray = (
        array: any,
        valid: boolean = true
    ): boolean => {
        if (valid) {
            return (Array.isArray(array))
        } else {
            return (!Array.isArray(array) || !array)
        }
    }

    public getFallbackLng = (): IUserLanguage => {
        return this.fallbackLng;
    }

    private translateFallback = (fieldName: string): string => {
        const file = fieldName.split(".");
        let res = this.loadTranslationFile(file[0]);
        file.shift();

        for (let i = 0; i < file.length; i++) {
            if (!res) {
                return fieldName;
            }
            res = res[file[i]];
        }

        if (this.isString(res)) {
            return res;
        } else {
            return fieldName;
        }
    }

    private translateArrayFallback = (fieldName: string): string[] | undefined => {
        const file = fieldName.split(".");
        let res = this.loadTranslationFile(file[0]);
        file.shift();

        for (let i = 0; i < file.length; i++) {
            if (!res) return undefined;
            res = res[file[i]];
        }

        if (this.isArray(res)) {
            return res;
        } else {
            return undefined;
        }
    }

    public getLanguage = () => {
        return languageService.getLSLanguage();
    }

    public translate = (fieldName?: string): string => {
        if (fieldName) {
            const file = fieldName.split(".");
            let res;

            if (file.length === 1) {
                const a = this.loadTranslationFile('common', this.getLanguage());
                if (this.isString(a[file[0]])) {
                    return a[file[0]]
                } else {
                    const b = this.loadTranslationFile('common');

                    if (this.isString(b[file[0]])) {
                        return b[file[0]]
                    } else {
                        return fieldName;
                    }
                }
            } else {
                res = this.loadTranslationFile(file[0], this.getLanguage());
                file.shift();

                for (let i = 0; i < file.length; i++) {
                    if (!res) {
                        return this.translateFallback(fieldName);
                    }
                    res = res[file[i]];
                }

                if (this.isString(res)) {
                    return res;
                } else {
                    return this.translateFallback(fieldName);
                }
            }
        } else {
            return "";
        }
    }

    public translateWithValues = (fieldName: string, replacements: IReplacementValue[] = []) => {
        let translated: string | React.ReactNode[] = this.translate(fieldName);

        replacements.forEach((replacement) => {
            translated = reactStringReplace(translated, replacement.tag, () => replacement.value);
        });

        return translated;
    }

    public translateArray = (fieldName: string): string[] | undefined => {
        if (fieldName) {
            const file = fieldName.split(".");
            let res;

            if (file.length === 1) {
                const a = this.loadTranslationFile('common', this.getLanguage());
                if (this.isArray(a[file[0]])) {
                    return a[file[0]];
                } else {
                    const b = this.loadTranslationFile('common');

                    if (this.isArray(b[file[0]])) {
                        return b[file[0]];
                    } else {
                        return undefined;
                    }
                }
            } else {
                res = this.loadTranslationFile(file[0], this.getLanguage());
                file.shift();

                for (let i = 0; i < file.length; i++) {
                    if (!res) {
                        return this.translateArrayFallback(fieldName);
                    }
                    res = res[file[i]];
                }

                if (this.isArray(res)) {
                    return res;
                } else {
                    return this.translateArrayFallback(fieldName);
                }
            }
        } else {
            return undefined;
        }
    }

    public loadImage = (imagePath: string): string => {
        const file = imagePath.split('/');
        const splitFileName = file[file.length-1].split('.');
        let newFileName = splitFileName[0].concat(`-${localStorage.getItem('siteLanguage')}.`, splitFileName[1]);
        file[file.length-1] = newFileName;
        let newFilePath = file.join('/');

        const imgs = require.context('../assets', true);
        let img;

        try {
            img = imgs(newFilePath);
        } catch (error) {
            try {
                newFileName = splitFileName[0].concat(`-${this.fallbackLng}.`, splitFileName[1]);
                file[file.length-1] = newFileName;
                newFilePath = file.join('/');
                img = imgs(newFilePath);
            } catch (error) {
                img = {
                    default: ""
                }
            }
        }

        return img.default;
    }

    public loadMonthNbr = (month: string): number => {
        if (month) {
            return parseInt(this.translate('various/month.'+month.toLowerCase()+'.order'));
        } else {
            return 0;
        }
    }

    public loadMonth = (month: string): string => {
        return this.translate('various/month.'+month.toLowerCase()+'.name');
    }

    public translateDay = (date: Date): string => {
        switch (this.getLanguage()?.countryCode.toLowerCase()) {
            case 'fr':
                return moment(date).format('D');
            default:
                return moment(date).format('Do');
        }
    }

    public translateWeekDay = (date: Date): string => {
        return this.translate('various/day.'+date.getDay());
    }

    public translateDate = (
        date?: Date,
        params?: {
            lowerMonth?: boolean,
            shortYear?: boolean,
            nextYear?: boolean,
            dayName?: boolean,
            hours?: boolean,
            minutes?: boolean
        }
    ): string => {
        if (date && typeof date === 'object') {
            // local variable needed to "unlink" with passed var
            const usedDate = date;
            const momentDate = moment(usedDate);
            if (params?.nextYear) momentDate.add(1, 'year');

            switch (this.getLanguage()?.countryCode.toLowerCase()) {
                case "fr":
                    return ((params?.dayName ? (this.translateWeekDay(usedDate) + ' ') : '') +
                        (this.translateDay(usedDate) + ' ') +
                        (params?.lowerMonth ? monthService.getMonth(usedDate.getMonth()).toLowerCase() : monthService.getMonth(usedDate.getMonth()) + ' ') +
                        (params?.shortYear ? momentDate.format('YY').padStart(2, '0') : momentDate.format('YYYY')) + ' ' +
                        (params?.hours ? usedDate.getHours().toString().padStart(2, '0')+(params?.minutes ? ':' : '') : '') +
                        (params?.minutes ? usedDate.getMinutes().toString().padStart(2, '0') : '')
                    );
            
                default:
                    return ((params?.dayName ? (this.translateWeekDay(usedDate) + ' ') : '') +
                        (params?.lowerMonth ? monthService.getMonth(usedDate.getMonth()).toLowerCase() : monthService.getMonth(usedDate.getMonth()) + ' ') +
                        (this.translateDay(usedDate) + ' ') +
                        (params?.shortYear ? momentDate.format('YY').padStart(2, '0') : momentDate.format('YYYY')) + ' ' +
                        (params?.hours ? usedDate.getHours().toString().padStart(2, '0')+(params?.minutes ? ':' : '') : '') +
                        (params?.minutes ? usedDate.getMinutes().toString().padStart(2, '0') : '')
                    );
            }
        } else {
            return 'Invalid date object';
        }
    }

    public translateAPIError(error: string): string {
        return this.translate('various/apiErrors.' + error);
    }

    public translateDiet(diet: string): string {
        return this.translate('various/diet.' + diet.toLowerCase());
    }

    public translateFoodType(type: string): string {
        return this.translate('various/foodType.' + type.toLowerCase());
    }

    public translateFood(food: string): string {
        return this.translate('various/food.' + food.toLowerCase());
    }

    public translateGender(gender: string): string {
        return this.translate('various/gender.' + gender.toLowerCase());
    }

    public translatePlanTypeName(name?: string): string {
        return this.translate('various/kit.type.' + name?.toLowerCase());
    }

    public translateSurveyQuestion(code: string): string {
        return this.translate('various/questions.' + code.toLowerCase() + '.question');
    }

    public translateSurveyAnswer(code: string, answer: string | number): string {
        if (typeof answer === 'string') {
            return this.translate('various/questions.' + code.toLowerCase() + '.answers.' + answer.toLowerCase());
        } else {
            return answer.toString();
        }
    }

    public translateSurveyDescription(code: string): string {
        return this.translate('various/questions.' + code.toLowerCase() + '.description');
    }
}

export default new translationService();