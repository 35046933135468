import { Button, CheckCircleIcon } from "components";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";

const ConsentFormSuccess: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('dashboard/patient/register-kit');

    return (
        <div className="flex-grow flex-column" style={{ paddingBottom: '10vh' }}>
            <div className="flex-row">
                <CheckCircleIcon size={52} style={{ marginRight: '1vw' }} />
                <h1
                    id="title"
                    className="MavenPro-black text-align-center"
                >
                    {t.translate('consentForm.title')}
                </h1>
            </div>
            <div className="flex-column" style={{ maxWidth: '600px' }}>
                <p className="text-align-center">{t.translate('consentForm.success.validation')}</p>
                <p className="text-align-center">{t.translate('consentForm.success.description')}</p>
                <div className="flex-row space-around flex-wrap">
                    <Button style={{ margin: '10px' }} label={t.translate('consentForm.success.howItWorks')} onClick={() => history.push('/how-it-works')} />
                    <Button style={{ margin: '10px' }} label={t.translate('consentForm.success.dashboardBtn')} onClick={() => history.push('/account/home')} />
                </div>
            </div>
        </div>
    )
}

export default ConsentFormSuccess;