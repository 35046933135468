import React from "react";

const DashboardRadio: React.FC<({
    required?: boolean
    name: string
    value: any
    isChecked: boolean
    setChecked: (newValue: any) => void
    label?: React.ReactNode | string
    id?: string
})> = ({
    required,
    name,
    value,
    isChecked,
    setChecked,
    label,
    id,
}) => {
    return (
        <div className="flex-row" onClick={() => setChecked(value)}>
            <input
                required={required}
                className="dashboardInput"
                type="radio"
                id={id ? id : name}
                name={name}
                value={value}
                checked={isChecked}
            />
            {label ? label : null}
        </div>
    )
}

export default DashboardRadio;