import take_care from 'assets/order/microbiota_analysis/take_care.jpg';
import { useState } from 'react';
import { useTranslation } from "services";

export interface WeTakeCareProps {
    key: number | string
}

const WeTakeCare: React.FC<WeTakeCareProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');

    const [ showDetail, setShowDetail ] = useState<'offered_consult' | 'personalized_recipes' | 'order_complements' | undefined>();

    const handleUpdateShowDetail = (newDetail: 'offered_consult' | 'personalized_recipes' | 'order_complements' | undefined) => {
        if (newDetail && showDetail !== newDetail) {
            setShowDetail(newDetail);
        } else {
            setShowDetail(undefined);
        }
    }

    return (
        <div key={props.key} className="orderSolution-section">
            <div className='orderSolution-img-wrapper'>
                <img src={take_care} />
            </div>
            <div className="flex-column">
                <div className="orderSolution-section-title">
                    <p>4.</p>
                    <div className="flex-column-start">
                        <h2>{t.translate('microbiota_analysis.we_take_care.title')}</h2>
                    </div>
                </div>

                <p>{t.translate('microbiota_analysis.we_take_care.body')}</p>

                <ul>
                    <li className='clickable' onClick={() => handleUpdateShowDetail('offered_consult')}>{t.translate('microbiota_analysis.we_take_care.list.offered_consult')}</li>
                    <p className={showDetail === 'offered_consult' ? 'detail-show' : 'detail-hide'}>{t.translate('microbiota_analysis.we_take_care.list.offered_consult_detail')}</p>
                    <li className='clickable' onClick={() => handleUpdateShowDetail('personalized_recipes')}>{t.translate('microbiota_analysis.we_take_care.list.personalized_recipes')}</li>
                    <p className={showDetail === 'personalized_recipes' ? 'detail-show' : 'detail-hide'}>{t.translate('microbiota_analysis.we_take_care.list.personalized_recipes_detail')}</p>
                    <li className='clickable' onClick={() => handleUpdateShowDetail('order_complements')}>{t.translate('microbiota_analysis.we_take_care.list.order_complements')}</li>
                    <p className={showDetail === 'order_complements' ? 'detail-show' : 'detail-hide'}>{t.translate('microbiota_analysis.we_take_care.list.order_complements_detail')}</p>
                </ul>
            </div>
        </div>
    )
}

export default WeTakeCare;