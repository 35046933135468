import languages from "context/Language/Languages";
import { IReplacementValue } from "interfaces/Services/TranslationService/IReplacementValue";
import { IUserLanguage } from "interfaces/User/IUserLanguage";
import moment from "moment-timezone";
import { ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';
import languageService from "./language.service";
import monthService from "./month.service";
import priceService from "./price.service";

export type TranslationSubscriptionPlanTypeFields = 'name' | 'short_name' | 'description' | 'currency' | 'price'

export interface ITranslationService {
    filename: string;
    getLanguage: () => IUserLanguage;
    getFullPath: (fieldName: string) => string;
    translate: (fieldName?: string) => string;
    translateFromFile: (path: string, field?: string) => string;
    translateFormField: (field: string) => string;
    translateGender: (field: string) => string;
    translateSubscriptionTypeName: (field: string) => string;
    translateSubscriptionType: (name: string, field?: TranslationSubscriptionPlanTypeFields) => string;
    translateSubscriptionPrice: (name: string) => string;
    translateCountry: (field: string) => string;
    translateApiError: (field: string) => string;
    translateReplaceValues: (fieldName: string, replacements?: IReplacementValue[]) => string;
    translateReplaceValuesFromFile: (path: string, field?: string, replacements?: IReplacementValue[]) => string;
    translateDayName: (day: string) => string;
    translateComplementQuantityType: (quantityType: string) => string;
    translateComplementCategoryName: (categoryName: string) => string;
    translateDate: (
        date?: Date,
        params?: {
            lowerMonth?: boolean,
            shortYear?: boolean,
            nextYear?: boolean,
            dayName?: boolean,
            hours?: boolean,
            minutes?: boolean,
            hideDay?: boolean,
            hideMonth?: boolean,
            hideYear?: boolean
        }
    ) => string;
}

interface IObject {
    [key: string]: IObject | string;
}

interface ILoadTFileParams {
    filePath?: string;
    lng?: IUserLanguage;
}

export class translationService implements ITranslationService {
    private fallbackLng: IUserLanguage = languages[1];

    private translationFile: IObject = {};
    private fallbackTranslationFile: IObject = {};

    filename: string;
    
    constructor(filename: string) {
        this.filename = filename;

        this.loadTranslationFiles();
    }
    
    private loadTranslationFiles() {
        this.translationFile = this.loadTranslationFile({ lng: this.getLanguage() });
        this.fallbackTranslationFile = this.loadTranslationFile();
    }

    private loadTranslationFile = (params?: ILoadTFileParams): IObject => {
        try {
            return require(`../assets/locales/${params?.lng ? params.lng.countryCode : this.getFallbackLng().countryCode}/${params?.filePath ?? this.filename}`);
        } catch (error) {
            return {};
        }
    }

    public getLanguage = () => {
        return languageService.getLSLanguage();
    }

    public getFallbackLng = (): IUserLanguage => {
        return this.fallbackLng;
    }

    public getFullPath(fieldName: string) {
        return this.filename+'.'+fieldName;
    }

    private t = (field: string, translationFile: IObject) => {
        const shards = field.split('.');

        let progression: IObject = translationFile;

        for (let i = 0; i < shards.length; i++) {
            const a = progression[shards[i]];
            if (a) {
                if (typeof a === 'object') {
                    progression = a;
                } else {
                    return a;
                }
            }
        }

        return field;
    }

    private translateFallback(field: string): string
    {
        return this.t(field, this.fallbackTranslationFile);
    }

    public translate(field?: string): string
    {
        if (field) {
            const res = this.t(field, this.translationFile);

            if (res && res !== field) return res;

            return this.translateFallback(field);
        } else {
            return '';
        }
    }

    private translateFromFileFallback(path: string, field: string): string
    {
        const file = this.loadTranslationFile({ filePath: path });

        return this.t(field, file);
    }

    public translateFromFile(path: string, field?: string): string
    {
        if (field) {
            const file = this.loadTranslationFile({ filePath: path, lng: this.getLanguage() });

            const res = this.t(field, file);

            if (res && res !== field) return res;

            return this.translateFromFileFallback(path, field);
        } else {
            return '';
        }
    }

    public translateDayName(dayName: string): string {
        return this.translateFromFile('various/day', dayName);
    }

    public translateDay(date: Date): string {
        switch (this.getLanguage()?.countryCode.toLowerCase()) {
            case 'fr':
                return moment(date).format('D');
            default:
                return moment(date).format('Do');
        }
    }

    public translateWeekDay(date: Date): string {
        return this.translateFromFile('various/day', date.getDay().toString());
    }

    public translateReplaceValues(
        fieldName: string, replacements: IReplacementValue[] = []
    ): string {
        let translated: string | ReactNode[] = this.translate(fieldName);

        replacements.forEach((replacement) => {
            translated = reactStringReplace(translated, replacement.tag, () => replacement.value);
        });

        return translated;
    }

    public translateReplaceValuesFromFile(path: string, field?: string, replacements: IReplacementValue[] = []): string
    {
        if (field) {
            const file = this.loadTranslationFile({ filePath: path, lng: this.getLanguage() });

            const res = this.t(field, file);

            let temp_res: string | ReactNode[] = this.translateFromFileFallback(path, field);

            replacements.forEach((replacement) => {
                temp_res = reactStringReplace(temp_res, replacement.tag, () => replacement.value);
            });

            if (res && res !== field) return temp_res;

            temp_res = this.translateFromFileFallback(path, field);

            replacements.forEach((replacement) => {
                temp_res = reactStringReplace(temp_res, replacement.tag, () => replacement.value);
            });

            return temp_res;
        } else {
            return '';
        }
    }

    public translateDate(
        date?: Date,
        params?: {
            lowerMonth?: boolean,
            shortYear?: boolean,
            nextYear?: boolean,
            dayName?: boolean,
            hours?: boolean,
            minutes?: boolean,
            hideDay?: boolean,
            hideMonth?: boolean,
            hideYear?: boolean
        }
    ): string {
        if (date && typeof date === 'object') {
            // local variable needed to "unlink" with passed var
            const usedDate = date;
            const momentDate = moment(usedDate);
            if (params?.nextYear) momentDate.add(1, 'year');

            switch (this.getLanguage()?.countryCode.toLowerCase()) {
                case "fr":
                    return ((params?.hideDay ? '' : (params?.dayName ? (this.translateWeekDay(usedDate) + ' ') : '')) +
                        (params?.hideDay ? '' : (this.translateDay(usedDate) + ' ')) +
                        (params?.hideMonth ? '' : (params?.lowerMonth ? monthService.getMonth(usedDate.getMonth()).toLowerCase() : monthService.getMonth(usedDate.getMonth()) + ' ')) +
                        (params?.hideYear ? '' : (params?.shortYear ? momentDate.format('YY').padStart(2, '0') : momentDate.format('YYYY')) + ' ') +
                        (params?.hours ? usedDate.getHours().toString().padStart(2, '0')+(params?.minutes ? ':' : '') : '') +
                        (params?.minutes ? usedDate.getMinutes().toString().padStart(2, '0') : '')
                    );
            
                default:
                    return ((params?.dayName ? (this.translateWeekDay(usedDate) + ' ') : '') +
                        (params?.lowerMonth ? monthService.getMonth(usedDate.getMonth()).toLowerCase() : monthService.getMonth(usedDate.getMonth()) + ' ') +
                        (this.translateDay(usedDate) + ' ') +
                        (params?.shortYear ? momentDate.format('YY').padStart(2, '0') : momentDate.format('YYYY')) + ' ' +
                        (params?.hours ? usedDate.getHours().toString().padStart(2, '0')+(params?.minutes ? ':' : '') : '') +
                        (params?.minutes ? usedDate.getMinutes().toString().padStart(2, '0') : '')
                    );
            }
        } else {
            return 'Invalid date object';
        }
    }

    public translateComplementQuantityType(quantityType: string): string
    {
        return this.translateFromFile('various/complementQuantityType', quantityType);
    }

    public translateComplementCategoryName(categoryName: string): string
    {
        return this.translateFromFile('various/complementCategory', categoryName + '.name');
    }

    public translateApiError(field: string): string
    {
        return this.translateFromFile('various/apiErrors', field);
    }

    public translateFormField(field: string): string
    {
        return this.translateFromFile('various/formFields', field);
    }
    
    public translateGender(field: string): string
    {
        return this.translateFromFile('various/gender', field.toLowerCase());
    }

    public translateSubscriptionTypeName(field: string): string
    {
        return this.translateFromFile('various/kit', `type.${field}.name`);
    }

    public translateSubscriptionType(name: string, field: TranslationSubscriptionPlanTypeFields = 'name'): string
    {
        return this.translateFromFile('various/kit', `type.${name}.${field}`);
    }

    public translateSubscriptionPrice(name: string): string
    {
        switch (this.getLanguage().countryCode) {
            case 'en':
                return `${this.translateSubscriptionType(name, 'currency')}${priceService.roundAndFormat(parseInt(this.translateSubscriptionType(name, 'price')))}`;
            default:
                return `${priceService.roundAndFormat(parseInt(this.translateSubscriptionType(name, 'price')))} ${this.translateSubscriptionType(name, 'currency')}`;
        }
    }

    public translateCountry(field: string): string
    {
        return this.translateFromFile('various/countries', field.toLowerCase());
    }
}

export default function useTranslationService(filename?: string): ITranslationService {
    return new translationService(filename ?? 'common');
}