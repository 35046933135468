import { AddIcon, RemoveIcon } from "components/Icons";
import DashboardDefaultButton from "components/common/DashboardDefaultButton/DashboardDefaultButton";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import cancerCheckQuestions from "components/common/Support/CancerCheckQuestions";
import companyQuestions from "components/common/Support/CompanyQuestions";
import doctorQuestions from "components/common/Support/DoctorQuestions";
import patientQuestions from "components/common/Support/PatientQuestions";
import standaloneQuestions from "components/common/Support/StandaloneQuestions";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import "../scss/FrequentQuestions.scss";

export interface FaqQuestion {
    libelle: string;
    answer?: string;
    customComponent?: React.FC;
}

const FrequentQuestions: React.FC = () => {
    const t = useTranslation('dashboard/support');
    const { type } = useContext(UserTypeContext);
    const { subscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);

    const history = useHistory();
    const [ indexQuestionSelected, setIndexQuestionSelected ] = useState<number>(-1);

    const renderQuestions = (): FaqQuestion[] => {
        switch (true) {
            case type.isCompany():
                return companyQuestions;
            case type.isDoctor():
                return doctorQuestions;
            case type.isStaff():
            case type.isPatient():
                return patientQuestions;
            case type.isStandalone():
                return standaloneQuestions;
            default:
                return [];
        }
    }

    const handleSelectQuestion = (index: number, withRemoval: boolean = false) => {
        if (withRemoval) {
            setIndexQuestionSelected(index === indexQuestionSelected ? -1 : index);
        } else {
            setIndexQuestionSelected(index);
        }
    }

    const renderFAQ = (questions: FaqQuestion[]) => questions.map((question, index) =>
        <div key={`question-${index}`} className="flex-column full-width question-container">
            <div className="row question-wrapper" onClick={() => handleSelectQuestion(index, true)}>
                <p className={`question text-uppercase ${index === indexQuestionSelected ? "bold" : null}`}>
                    {t.translate('frequent.'+question.libelle)}
                </p>
                {index === indexQuestionSelected 
                    ? <RemoveIcon className="icon-faq" />
                    : <AddIcon className="icon-faq" />
                }
            </div>
            {(index === indexQuestionSelected) &&
                (question.customComponent
                    ? <question.customComponent />
                    : <div className="answer-wrapper">
                        <p className="answer" dangerouslySetInnerHTML={{__html: t.translate('frequent.'+question.answer)}}></p>
                    </div>)
            }
        </div>
    )

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate('frequent.title')}</h1>}
            body={
                <Fragment>
                    {
                        subscriptionTrackingDetail?.subscriptionPlan?.isTypeCancer() || renderFAQ(renderQuestions())
                    }
                    {(type.isStandalone() && subscriptionTrackingDetail?.subscriptionPlan?.isTypeCancer()) &&
                        <Fragment>
                            <h3 className="text-align-start full-width">{t.translateSubscriptionTypeName('cancer_detection')}</h3>
                            {renderFAQ(cancerCheckQuestions)}
                        </Fragment>
                    }
                </Fragment>
            }
            footer={
                <DashboardDefaultButton
                    libelle={t.translate('frequent.other')} 
                    handleClick={
                        type.isDoctor()
                            ? () => history.push('/account/questions')
                            : () => window.open("/questions", "_blank")?.focus()
                    }
                />
            }
        />
    );
}

export default FrequentQuestions;
