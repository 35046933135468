import { Button, Loader } from "components";
import GoBack from "components/DoctorPatients/components/GoBack";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDatabaseGeneralComment } from "interfaces/Database/IDatabaseGeneralComment";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";

const GeneralComment: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('patientDetail');
    const { patientId, appointmentId } = useParams<({ patientId: string, appointmentId: string })>();
    
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ patient, setPatient ] = useState<IDatabaseUser>();
    const [ generalComment, setGeneralComment ] = useState<IDatabaseGeneralComment>();

    const fetchPatient = () => {
        if (patientId) {
            doctorService.getPatientDetails(patientId)
                .then(async (res) => {
                    setPatient(res.result);
                    setGeneralComment(res.generalComment);
                })
                .catch((err) => console.warn(err))
                .finally(() => setIsLoading(false));
        }
    }

    useEffect(() => {
        fetchPatient();
    }, []);

    const handleGoBack = () => {
        history.push(`/account/patient/${patientId}`);
    }

    const handleFormNewGeneralComment = () => {
        history.push(`/account/patient/${patientId}/general-comment/${appointmentId}/new`)
    }

    if (isLoading) {
        return (
            <Loader />
        )
    }
    return (
        <div id="form-prescription" className="column">
            <GoBack className="MavenPro-Black" text={`${patient?.patientInfo?.firstname} ${patient?.patientInfo?.lastname}`} handleGoBack={handleGoBack} />
            <DefaultContainer
                title={
                    <Fragment>
                        <h2 className='bold'>{t.translate('pdf.generalComment.title')}</h2>
                        {generalComment?.updatedAt && <p>{t.translate('pdf.lastUpdate')}<b>{t.translateDate(generalComment.updatedAt, { hours: true, minutes: true })}</b></p>}
                    </Fragment>
                }
                body={
                    <div id="previsualisation">
                        {generalComment?.comment 
                            ? <p>&quot;{generalComment?.comment}&quot;</p>
                            : <p className="no-data">{t.translate('pdf.generalComment.none')}</p>
                        }
                    </div>
                }
                footer={
                    <Button
                        label={t.translate('pdf.edit')}
                        onClick={handleFormNewGeneralComment}
                        disabled={false}
                    />
                }
            />
        </div>
    )
}

export default GeneralComment;