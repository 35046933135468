import axios from "axios";
import ErrorService from "./Error.service";
import dateService from "./date.service";
import userAuthService from "./user-auth.service";

class apiService {
    private BASE_URI = process.env.REACT_APP_API_URL;
    private headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };
   
    private headersFormData = {
        "Accept": "application/json",
        'Content-Type': 'multipart/form-data',
    };


    constructor(activeCredentials = true) {
        axios.defaults.withCredentials = activeCredentials;
        axios.interceptors.response.use(
            (originalResponse) => {
                dateService.handleDates(originalResponse.data);
                return originalResponse;
            }
        );
    }

    async get<T = unknown,>(endpoint: string, _?: unknown, useToken: boolean = true) {
        return axios.get<T>(`${this.BASE_URI}${endpoint}`, {
            headers: {
                ...this.headers,
                ...(useToken && {
                    "Authorization": `Bearer ${userAuthService.getToken()}`
                })
            },
            withCredentials: false
        });
    }

    async getFile<T = unknown,>(endpoint: string, _?: unknown, useToken: boolean = true) {
        return axios.get<T>(
            `${this.BASE_URI}${endpoint}`,
            {
                responseType: 'arraybuffer',
                headers: {
                    ...this.headers,
                    'Accept': 'application/pdf',
                    ...(useToken && {
                        "Authorization": `Bearer ${userAuthService.getToken()}`
                    })
                },
                withCredentials: false
            });
    }

    async post<T = unknown,>(endpoint: string, query?: unknown, useToken: boolean = true) {
        return axios.post<T>(
            `${this.BASE_URI}${endpoint}`,
            query
            , {
                headers: {
                    ...this.headers,
                    ...(useToken && {
                        "Authorization": `Bearer ${userAuthService.getToken()}`
                    })
                },
                withCredentials: false,
            });
    }
   
    async postFormData<T = unknown,>(endpoint: string, query?: unknown, useToken: boolean = true) {
        return axios.post<T>(
            `${this.BASE_URI}${endpoint}`,
            query
            , {
                headers: {
                    ...this.headersFormData,
                    ...(useToken && {
                        "Authorization": `Bearer ${userAuthService.getToken()}`
                    })
                },
                withCredentials: false,
            });
    }

    async put<T = unknown,>(endpoint: string, query?: unknown, useToken: boolean = true) {
        return axios.put<T>(
            `${this.BASE_URI}${endpoint}`,
            query
            , {
                headers: {
                    ...this.headers,
                    ...(useToken && {
                        "Authorization": `Bearer ${userAuthService.getToken()}`
                    })
                },
                withCredentials: false,
            });
    }

    async delete<T = unknown,>(endpoint: string, _?: unknown, useToken: boolean = true) {
        return axios.delete<T>(
            `${this.BASE_URI}${endpoint}`,
            {
                headers: {
                    ...this.headers,
                    ...(useToken && {
                        "Authorization": `Bearer ${userAuthService.getToken()}`
                    })
                },
                withCredentials: false,
            });
    }

    public async makeApiCall<T = any>(endpoint: string, method: 'get' | 'getFile' | 'post' | 'postFormData' | 'put' | 'delete', data?: unknown, useToken: boolean = true): Promise<T> {
        return this[method]<T>(endpoint, data, useToken)
            .then((response) => response.data)
            .catch((err) => Promise.reject(ErrorService.handleApiError(err)));
    }
}

export default new apiService();
