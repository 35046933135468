import { CloseIcon } from 'components';
import React, { ReactNode, useEffect } from "react";

const Modal: React.FC<({
    isShow: boolean;
    toggleShow: () => void;
    title?: string;
    subTitle?: string;
    children?: ReactNode;
    footer?: ReactNode;
})> = ({
    isShow,
    toggleShow,
    title,
    subTitle,
    children,
    footer
}) => {
    useEffect(() => {
        if (isShow) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ isShow ]);

    return (
        isShow ? <div className='modal'>
            <div className='modal-content'>
                <div className='modal-content-header'>
                    <h2 className="bold">{title}</h2>
                    {subTitle ? <i>{subTitle}</i> : null}
                    <CloseIcon className='close' style={{ top: '20px', right: 0 }} onClick={() => toggleShow()} />
                </div>
                <div className='modal-content-body'>
                    {children}
                </div>
                {footer
                    ? <div className='modal-content-footer'>
                        {footer}
                    </div>
                    : null
                }
            </div>
        </div> : null
    )
}

export default Modal;