import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe, StripeEmbeddedCheckoutOptions } from "@stripe/stripe-js";
import { Loader } from "components";
import { LanguageContext } from "context/Language/LanguageContext";
import { Cart } from "interfaces";
import { IDAddress, IDatabaseComplement, IDPlatform, IDProductOrderType } from "interfaces/Database";
import PaymentType from "interfaces/Database/IDatabasePaymentType";
import { useContext, useEffect, useMemo, useState } from "react";
import useCheckoutService from "services/checkout.service";

export interface PaymentProps {
    cart: Cart<IDatabaseComplement>
    platform: IDPlatform,
    payment_types: PaymentType[],
    product_order_type: IDProductOrderType,
    renew: boolean,
    billingAddress: IDAddress,
    togglePaymentFormLoaded: () => void
    togglePreviousStep: () => void
}

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PK), { locale: 'auto' });

const Payment: React.FC<PaymentProps> = (props) => {
    const checkoutService = useCheckoutService();

    const { language } = useContext(LanguageContext);

    const [ clientSecret, setClientSecret ] = useState<string>('');

    const options: StripeEmbeddedCheckoutOptions = useMemo(() => ({
        clientSecret: clientSecret,
    }), [ clientSecret ]);

    const loadData = () => {
        checkoutService.createCheckoutSessionForComplement({
            cart: props.cart,
            platformId: props.platform.id,
            productOrderTypeId: props.product_order_type.id,
            renew: props.renew,
            language: language.countryCode
        })
            .then((res) => setClientSecret(res.client_secret))
            .catch((err) => console.warn(err));
    }

    useEffect(() => {
        setClientSecret('');
        loadData();
    }, [language]);

    if (!clientSecret) {
        return (
            <Loader />
        )
    }
    
    return (
        <div id="checkout" className="full-width">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    )
}

export default Payment;