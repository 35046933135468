import box from 'assets/home/microbiota_analysis.webp';
import { Button, Checkbox, LeftArrowIcon, StepIndicator } from 'components';
import EditAddressForm from "components/common/Address/EditAddressForm";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { UserContext } from 'context/User/UserContext';
import { IAddress, defaultAddress } from "interfaces/User/IAddress";
import { isEqual } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'services';
import addressService from 'services/address.service';
import standaloneService from "services/standalone.service";
import "../scss/KitRegister.scss";

const Addresses: React.FC<({
    handleNextStep: () => void
})> = ({
    handleNextStep
}) => {
    const t = useTranslation('pages/kitRegister');
    const history = useHistory();

    const { user } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const [ sameAddresses, setSameAddresses ] = useState<boolean>(true);
    const [ newBillingAddress, setNewBillingAddress ] = useState<IAddress>(defaultAddress);
    const [ newShippingAddress, setNewShippingAddress ] = useState<IAddress>(defaultAddress);

    const loadAddresses = useCallback(() => {
        setIsLoading(true);

        (user?.isStandalone()
            ? standaloneService
            : addressService).getBillingAndShippingAddresses()
            .then((res) => {
                if (res.billing) setNewBillingAddress(res.billing);
                if (res.shipping) setNewShippingAddress(res.shipping);

                if ((res.billing || res.shipping) && isEqual(res.billing, res.shipping)) setSameAddresses(true);
            }).catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        loadAddresses();
    }, [loadAddresses]);

    const handleSaveData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        
        setIsLoading(true);
        setError(undefined);

        (user?.isStandalone()
            ? standaloneService
            : addressService
        ).updateAddresses({
            sameAddresses: sameAddresses,
            billing: newBillingAddress,
            shipping: newShippingAddress
        })
            .then(() => handleNextStep())
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            wrapperClassName="customContainerWrapper"
            title={
                <div className="flex-row-start">
                    <LeftArrowIcon
                        className='clickable'
                        onClick={() => history.push('/account/home')}
                    />
                    <div style={{ margin: '0 1vw' }} className='flex-column-start'>
                        <StepIndicator step={1} />
                        <h3 style={{ margin: '0 1vw' }}>{t.translate('title')}<span style={{ color: 'var(--dashboard-main-color)', margin: '0 1vw' }}>3/3</span></h3>
                    </div>
                </div>
            }
            body={
                <div className='row'>
                    <div id="image-wrapper" className="column">
                        <img src={box} alt="kit" id="kit-image" />
                    </div>
                    <div id="form-wrapper" className="flex-column">
                        <div className="flex-column">
                            <h2 id="title" className="MavenPro-black full-width" style={{ textAlign: "center" }}>
                                {t.translate('addresses.title')}
                            </h2>
                            <p id="form-description" className="full-width" style={{ textAlign: "center" }}>
                                {t.translate('addresses.sub')}
                            </p>
                        </div>

                        <div className="flex-column">
                            <EditAddressForm
                                isLoading={isLoading}
                                address={newBillingAddress}
                                setAddress={setNewBillingAddress}
                            />
                            <Checkbox
                                name='areAddressesSame'
                                isChecked={sameAddresses}
                                setChecked={setSameAddresses}
                                label={t.translate('addresses.areSame')}
                            />
                            {!sameAddresses
                                ? <EditAddressForm
                                    isLoading={isLoading}
                                    address={newShippingAddress}
                                    setAddress={setNewShippingAddress}
                                />
                                : null
                            }
                        </div>

                        <ErrorMessage error={error} />
                        <Button label={t.translate('addresses.save')} onClick={(e) => handleSaveData(e)} />
                    </div>
                </div>
            }
            hideSeparator={true}
        />
    )
}

export default Addresses;