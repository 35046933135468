import { loadStripe } from "@stripe/stripe-js";
import kitImg from 'assets/home/microbiota_analysis.webp';
import banner from 'assets/order_box_banner.png';
import { Button, Checkbox, InsuranceCoverage, Loader, StepIndicator } from "components";
import NoAddress from "components/OrderKit/component/NoAddress";
import 'components/OrderKit/scss/OrderKit.scss';
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import { UserTypeContext } from "context/User/UserTypeContext";
import { IDPlatform, IDPrices, IDProductOrderType, IDSubscriptionPlanType } from "interfaces/Database";
import PaymentType from "interfaces/Database/IDatabasePaymentType";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import addressService from "services/address.service";
import usePaymentTypeService from "services/api/paymentType.service";
import usePlatformService from "services/api/platform.service";
import useProductOrderTypeService from "services/api/productOrderType.service";
import companyService from "services/company.service";
import priceService from "services/price.service";
import subscriptionPlanTypeService from "services/subscriptionPlanType.service";

loadStripe(String(process.env.REACT_APP_STRIPE_PK), { locale: 'auto' });

const DashboardOrderKit: React.FC = () => {
    const t = useTranslation('dashboard/company/order_kit');
    const history = useHistory();
    const platformService = usePlatformService();
    const productOrderTypeService = useProductOrderTypeService();
    const paymentTypeService = usePaymentTypeService();

    const { type } = useContext(UserTypeContext);

    const [ showConfirm, setConfirm ] = useState<boolean>(false);
    const toggleConfirm = () => setConfirm(!showConfirm);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();
    const [ hasAddress, setHasAddress ] = useState<boolean>(false);
    const [ isInCheckout, setIsInCheckout ] = useState<boolean>(false);

    const [ platform, setPlatform ] = useState<IDPlatform>();
    const [ productOrderType, setProductOrderType ] = useState<IDProductOrderType>();

    const [ solution_nbr, set_solution_nbr ] = useState<number>(1);

    const [ planType, setPlanType ] = useState<IDSubscriptionPlanType>();
    const [ planTypes, setPlanTypes ] = useState<IDSubscriptionPlanType[]>([]);

    const [ paymentTypes, setPaymentTypes ] = useState<PaymentType[]>([]);
    const [ choosedPaymentType, setChoosedPaymentType ] = useState<PaymentType>();

    const [ customPrices, setCustomPrices ] = useState<IDPrices[]>([]);
    const [ customPrice, setCustomPrice ] = useState<IDPrices>();
    const [ toPay, set_to_pay ] = useState<number>(0);

    const [ cguState, setCguState ] = useState<boolean>(false);
    const toggleCguState = () => setCguState(!cguState);

    const [ cardList, setCardList ] = useState<any[]>([]); // eslint-disable-line

    const [ price, setPrice ] = useState<number>(0);

    // handle location validation
    useEffect(() => {
        const search = window.location.search;
        
        if (search) {
            const session = search.substring(1).split('&').filter((e) => e.split('=')[0] === 'session_id');
            if (session[0]) {
                const sessionId = session[0].split('=');
                history.push(`/account/order-kit/session/${sessionId}`);
            } else {
                const subscription = search.substring(1).split('&').filter((e) => e.split('=')[0] === 'subscription_id');
                if (subscription[0]) {
                    const subscriptionId = subscription[0].split('=');
                    history.push(`/account/order-kit/subscription/${subscriptionId}`);
                }
            }
        }
    }, [history]);

    const loadData = useCallback(async () => {
        setIsLoading(true);

        await Promise.all([
            platformService.getWebPlatform()
                .then((res) => setPlatform(res)),
            productOrderTypeService.getPlanType()
                .then((res) => setProductOrderType(res)),
            paymentTypeService.getAllPaymentTypes()
                .then((res) => setPaymentTypes(res)),
            addressService.getBillingAddress()
                .then(() => setHasAddress(true))
                .catch(() => setHasAddress(false)),
            subscriptionPlanTypeService.getAvailableTypes()
                .then((res) => {
                    setPlanTypes(res);
    
                    if (res.length > 0) {
                        setPlanType(res[0]);
                    }
                }),
            subscriptionPlanTypeService.getCustomPrices()
                .then((res: IDPrices[]) => {
                    setCustomPrices(res);
    
                    if (planTypes.length === 1) {
                        setCustomPrice(res.filter((price) => price.planType === planType)[0]);
                    }
                })
        ]).catch((err) => console.warn(err));

        if (type.isCompany()) {
            await companyService.getCompanyCurrentCard()
                .then((res) => setCardList(res))
                .catch(() => setCardList([]));
        }

        setIsLoading(false);
    
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (planType) {
            setCustomPrice(customPrices.filter((e) => e.planType && e.planType.id === planType.id)[0]);
            setPrice(planType?.price ?? 0);

            if (!planType.monthlyPayment) {
                setChoosedPaymentType(paymentTypes.filter((e) => e.isOneTime())[0]);
            }
        }
    }, [planType, customPrices]);

    useEffect(() => {
        const nbr = solution_nbr ? solution_nbr : 0;
        if (choosedPaymentType?.isMonthly()) {
            set_to_pay((price*nbr)/12);
        } else {
            set_to_pay(price*nbr);
        }
    }, [choosedPaymentType, solution_nbr, price, planType]);

    const handleOneTime = () => {
        handleSubmit();
    }

    const handleSubmit = async (cardId?: number) => {
        setIsInCheckout(true);

        let byPassPaymentType: PaymentType | undefined = choosedPaymentType;

        if (!byPassPaymentType) {
            if (planType?.monthlyPayment) {
                t.translate('solutionMissing');
            } else {
                byPassPaymentType = paymentTypes.filter((type) => type.isOneTime())[0];
                setChoosedPaymentType(paymentTypes.filter((type) => type.isOneTime())[0]);
            }
        }

        if ((!planType || !planType?.id) || !platform || !productOrderType?.id || !byPassPaymentType) {
            setError(t.translate('solutionMissing'));
        } else {
            await companyService.createOrGetStripeCustomer();

            if (byPassPaymentType?.isMonthly()) {
                if (cardId) {
                    await companyService.monthlyPayment({
                        payment_type_id: byPassPaymentType.id,
                        plan_type_id: planType.id,
                        platform_id: platform?.id,
                        product_order_type_id: productOrderType?.id,
                        renew: false,
                        solution_nbr: solution_nbr,
                        card_id: cardId
                    })
                        .then((res) => history.push(`/account/order-kit/subscription/${res.data.sub_id}`))
                        .catch(() => setError(t.translate('error')))
                        .finally(() => setIsInCheckout(false));
                } else {
                    setError(t.translate('missingCard'));
                }
            } else {
                await companyService.oneTimePayment({
                    payment_type_id: byPassPaymentType?.id,
                    plan_type_id: planType.id,
                    platform_id: platform?.id,
                    product_order_type_id: productOrderType.id,
                    renew: false,
                    solution_nbr: solution_nbr
                })
                    .then((res) => res?.url
                        ? window.open(res.url, '_parent', 'height=400,width=400,status=yes,toolbar=no,menubar=no,location=no')
                        : null
                    )
                    .catch(() => setError(t.translate('error')))
                    .finally(() => setIsInCheckout(false));
            }
        }
    }

    const handleSelectNumber = (displayPlanType: IDSubscriptionPlanType, quantity: number) => {
        setPlanType(displayPlanType);
        set_solution_nbr(quantity);
    }

    if (isLoading) {
        return <div className="column loader-container" style={{ minHeight: '100vh' }}>
            <Loader />
        </div>
    } else if (!hasAddress) {
        return <NoAddress reload={loadData} />
    } else {
        return (
            <div className="page-wrapper">
                <StepIndicator step={1} />
                <div className="order-dashboard" style={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <div className="order-dashboard-banner">
                        <img src={banner} alt="Women typing on a laptop" />
                        <h2 className="text-uppercase MavenPro-black">
                            {t.translate('title.first')}
                            <br />
                            {t.translate('title.second')}
                        </h2>
                        <hr />
                    </div>
                    <form className="order-form-wrapper" onSubmit={(e) => {
                        e.preventDefault();

                        if (planType?.name === 'cancer_check') {
                            toggleConfirm();
                        } else {
                            handleOneTime();
                        }
                    }}>
                        {planTypes.map((displayPlanType, index, arr) =>
                            <div
                                key={index}
                                className={`order-form-wrapper-kit${displayPlanType.id === planType?.id ? '-selected' : ''}${index < (arr.length-1) ? ' not-last' : ''}`}
                                onClick={() => handleSelectNumber(displayPlanType, 1)}
                            >
                                <img src={kitImg} alt="Kit" width={200} />
                                <div className="flex-column-start">
                                    <h4 style={{ marginBottom: 0 }}>{t.translate(`kit.${displayPlanType.name}.title`)}</h4>
                                    <p>{t.translate(`kit.${displayPlanType.name}.desc`)}</p>
                                </div>
                            </div>
                        )}

                        <fieldset>
                            <legend>
                                <h2 className='text-uppercase MavenPro'>{t.translate('amount.label')}</h2>
                            </legend>
                            <div className='flex-column'>
                                <p style={{ margin: 0 }}>
                                    {(customPrice && (planType && (customPrice.price !== planType.price)))
                                        ? <Fragment>
                                            <span className='line-over'>
                                                {toPay ? toPay : '0'}&nbsp;€
                                            </span> {choosedPaymentType?.isMonthly() ?
                                                priceService.roundPrice((customPrice.price*solution_nbr)/12).toLocaleString() :
                                                priceService.roundPrice(customPrice.price*solution_nbr).toLocaleString()
                                            }€
                                        </Fragment>
                                        : `${toPay ? priceService.roundPrice(toPay).toLocaleString() : '0'} €`
                                    }
                                    {choosedPaymentType?.isMonthly() ? t.translate('amount.byMonth') : ' '}

                                    {t.translateReplaceValues('amount.accessTo', [
                                        {
                                            tag: '{{nbr}}',
                                            value: solution_nbr
                                        },
                                        {
                                            tag: '{{solutionName}}',
                                            value: planType ? t.translate(`kit.${planType.name}.title`) : ''
                                        }
                                    ])}, {t.translate('amount.toPay')}
                                </p>

                                <Checkbox
                                    required
                                    name="cgv"
                                    isChecked={cguState}
                                    setChecked={() => toggleCguState()}
                                    label={t.translateReplaceValues('cgu.acceptCgu', [
                                        {
                                            tag: '{{cgv}}',
                                            value: <a href="/conditions" target="_blank">{t.translate('cgu.cgv')}</a>
                                        }
                                    ])}
                                />

                                <ErrorMessage error={error} />

                                <Button
                                    type='submit'
                                    disabled={!cguState || isInCheckout || toPay === 0 || (
                                        (customPrice && (planType && (customPrice.price !== planType.price)))
                                            ? priceService.roundPrice(customPrice.price*solution_nbr) > 999999.999
                                            : (toPay
                                                ? (priceService.roundPrice(toPay) > 999999.999)
                                                : true
                                        )   
                                    )}
                                    displayLoader={isInCheckout}
                                    label={t.translate('cardPayment.onetimeBtn')}
                                />
                            </div>
                        </fieldset>
                    </form>
                    <ModalConfirmAction
                        showModal={showConfirm}
                        confirmAction={handleSubmit}
                        handleClose={toggleConfirm}
                        description={
                            <div>
                                <p>{t.translate('confirm.desc')}</p>
                                <ul>
                                    <li>{t.translate('confirm.no-cancer')}</li>
                                </ul>
                            </div>
                        }
                    />
                </div>
                {type.isStandalone()
                    ? <InsuranceCoverage />
                    : null
                }
            </div>
        )
    }
}

export default DashboardOrderKit;