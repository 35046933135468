import { Loader } from "components";
import ResultsAnalysis from "components/TestResults/components/ResultsAnalysis";
import TestInProgress from "components/TestResults/components/TestInProgress";
import "components/TestResults/scss/TestResults.scss";
import SurveyResume from "components/common/Dashboard/SurveyResume/SurveyResume";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import PreviousAnalysis from "components/common/PreviousAnalysis/PreviousAnalysis";
import { IDatabaseAnalysisKit } from "interfaces/Database/IDatabaseAnalysisKit";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import 'moment-timezone';
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "services";
import analysisKitService from 'services/analysisKit.service';
import subscriptionPlanService from "services/subscriptionPlan.service";

const TestResults: React.FC = () => {
    const t = useTranslation('dashboard/patient/testResults');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ currentOrder, setCurrentOrder ] = useState<ISubscriptionTrackingSteps>();
    const [ analysisKit, setAnalysisKit ] = useState<IDatabaseAnalysisKit>();

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            subscriptionPlanService.getSubscriptionProcessing()
                .then((res) => setCurrentOrder(res)),
            analysisKitService.getOnGoingAnalysisKit()
                .then((res) => setAnalysisKit(res))
        ])
            .catch(console.warn)
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData()
    }, [fetchData]);

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate('pre')} title={t.translate('title')} />
            {isLoading
                ? <Loader loaderDescription={t.translate('loader-message')} />
                : <Fragment>
                    <TestInProgress order={currentOrder} analysisKit={analysisKit} />
                    <SurveyResume />
                    <div id="text-info-wrapper" style={{ position: 'relative', zIndex: 5 }}>
                        <p className="MavenPro-black uppercase">
                            {t.translate('informations.title')}
                        </p>
                        <p className="MavenPro text-info">
                            {t.translate('informations.subtitle')}
                        </p>
                        <p className="text-green text-info">
                            {t.translate('informations.infoDoctor')}
                        </p>
                    </div>
                    <ResultsAnalysis analysisKit={analysisKit} />
                    <PreviousAnalysis />
                </Fragment>
            }
        </Fragment>
    );
}

export default TestResults;