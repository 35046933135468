import { CloseIcon } from "components/Icons";
import React from "react";
import './scss/CustomSuccessContainer.scss';

const CustomSuccessContainer: React.FunctionComponent<({
    isShow: boolean,
    close: () => void,
    text: string
})> = ({
    isShow,
    close,
    text = 'Action effectuée avec succès'
}) => {
    if (!isShow) {
        return null;
    } else {
        return (
            <div className='success-container-wrapper'>
                <p className='success-container-wrapper-text'>{text}</p>
                <CloseIcon onClick={() => close()} />
            </div>
        )
    }
}

export default CustomSuccessContainer;