import { Button, Input, Label, LeftArrowIcon, Loader } from "components";
import "components/ForgotPassword/scss/ResetPassword.scss";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";
import verifierService from "services/verifier.service";

interface ResetPasswordResponse {
    email: string;
    token: string;
    expires_at?: Date;
}

const NewResetPassword: React.FC = () => {
    const t = useTranslation('pages/ResetPassword');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ expired, setExpired ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ success, setSuccess ] = useState<boolean>(false);
    const [ password, setPassword ] = useState<string>();
    const [ confirmPassword, setConfirmPassword ] = useState<string>();
    const [ missMatchPassword, setMissMatchPassword ] = useState<boolean>(false);

    const { request } = useParams<({ request: string })>();
    const [ resetPasswordObj, setResetPasswordObj ] = useState<ResetPasswordResponse>();
    const history = useHistory();

    const b64DecodeQuery = (baseString: string): ResetPasswordResponse => {
        const base64ToString = atob(baseString);
        const obj = JSON.parse(base64ToString) as {
            email: string;
            token: string;
            expires_at?: string;
        };

        return {
            email: obj.email,
            token: obj.token,
            expires_at: obj.expires_at ? moment(obj.expires_at).toDate() : undefined,
        };
    }

    const checkRequestIsValid = async () => {
        if (!request) return history.push('/account-recovery');

        let requestObj;

        try {
            requestObj = b64DecodeQuery(request);
            setResetPasswordObj(requestObj);
        } catch (error) {
            return history.push('/account-recovery');
        }

        if (!requestObj) return history.push('/account-recovery');

        if (requestObj.expires_at && moment(requestObj.expires_at).isBefore(moment(new Date()))) {
            setExpired(true);
            setIsLoading(false);
            return;
        }

        userAuthService.checkRequestResetPassword({
            email: requestObj.email,
            token: requestObj.token
        })
            .catch((err) => {
                if (err.message === 'password_request_not_found_or_expired') {
                    setExpired(true);
                } else {
                    userAuthService.logout(history, '/login', true);
                    toast.error(t.translate('error'), {
                        onClick: () => history.push('/account-recovery'),
                        closeOnClick: true
                    });
                }
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        checkRequestIsValid();
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setError(undefined);
        setMissMatchPassword(false);

        if (resetPasswordObj && password && verifierService.checkPwdSame(password, confirmPassword)) {
            userAuthService.resetPassword({
                email: resetPasswordObj.email,
                token: resetPasswordObj.token,
                password: password
            })
                .then(() => setSuccess(true))
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        } else {
            setMissMatchPassword(true);
            setIsLoading(false);
        }
    }

    const handleContent = () => {
        if (isLoading) return <Loader />

        if (success) {
            return <div className='flex-column'>
                <p className='text-align-center'>{t.translate('success')}</p>
                <Button label={t.translate('login')} onClick={() => history.push('/login')} />
            </div>
        }

        if (expired) {
            return <div className='flex-column'>
                <p className='text-align-center'>{t.translate('expired')}</p>
                <Button label={t.translate('retry')} onClick={() => history.push('/account-recovery')} />
            </div>
        }

        return <form id="form-reset" className='flex-column' onSubmit={handleSubmit} method='post'>
            <div className="input-container column" style={{ margin: '10px 0' }}>
                <Label for="password-new" label={t.translate('password.new')} required />
                <Input
                    required
                    name="password-new"
                    type="password"
                    value={password}
                    setValue={(e) => setPassword(e.currentTarget.value)}
                    checkFunction={() => verifierService.checkPwdEnough(password)}
                    checkmark={{
                        displayed: true
                    }}
                />
            </div>
            <div className="input-container column" style={{ margin: '10px 0' }}>
                <Label for="password-confirm" label={t.translate('password.repeat')} required />
                <Input
                    required
                    name="password-confirm"
                    type="password"
                    value={confirmPassword}
                    setValue={(e) => setConfirmPassword(e.currentTarget.value)}
                    checkFunction={() => verifierService.stringEquals(password, confirmPassword)}
                    checkmark={{
                        displayed: true
                    }}
                />
            </div>
            
            <ErrorMessage error={missMatchPassword ? t.translate('password.missmatch') : undefined} withTranslation={false} />
            <ErrorMessage error={error} />

            <Button label={t.translate('update')} type="submit" />
        </form>
    }

    return (
        <div id="reset-password" className="flex-column flex-grow">
            <div id='wrapper' className='flex-column shadow'>
                <div className="flex-row full-width">
                    <LeftArrowIcon onClick={() => history.go(-1)} />
                    <h1 className="MavenPro-bold full-width text-align-center" style={{ margin: 0 }}>{t.translate('title')}</h1>
                </div>
                {handleContent()}
            </div>
        </div>
    )
}

export default NewResetPassword;