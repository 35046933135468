import { Loader } from "components";
import CompanyProfile from "components/Profile/components/Company/CompanyProfile";
import DoctorProfile from "components/Profile/components/Doctor/DoctorProfile";
import PatientProfile from "components/Profile/components/Patient/PatientProfile";
import StandaloneProfile from "components/Profile/components/Standalone/StandaloneProfile";
import { UserContext } from "context/User/UserContext";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";

const Profile: React.FC = () => {
    const t = useTranslation('pages/profile');
    const { type } = useContext(UserTypeContext);
    const { user, setUser } = useContext(UserContext);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const fetchUser = useCallback(async () => {
        if (!user) {
            setIsLoading(true);
        
            await userAuthService.getUser()
                .then((res) => setUser(res))
                .catch((err) => console.warn(err));

            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [user, setUser]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const handleDashboardView = () => {
        switch (true) {
            case type.isStaff():
            case type.isPatient():
                return <PatientProfile />
            case type.isCompany():
                return <CompanyProfile />
            case type.isDoctor():
                return <DoctorProfile />
            case type.isStandalone():
                return <StandaloneProfile />
            default:
                return (
                    <div id="dashboard-home" className="flex row">
                        <p>{t.translate('none')}</p>
                    </div>
                )
        }
    }

    return (isLoading
        ? <Loader />
        : handleDashboardView()
    )
}

export default Profile;