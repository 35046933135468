import CustomLinkWrapper from "components/common/CustomLinkWrapper/CustomLinkWrapper";
import CustomSpacer from 'components/common/CustomSpacer/CustomSpacer';
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { LanguageContext } from "context/Language/LanguageContext";
import React, { Fragment, useContext } from "react";
import { useTranslation } from 'services';
import '../scss/CompanyKitContracts.scss';

const CompanyKitContracts: React.FC = () => {
    const t = useTranslation('dashboard/company/home');
    const { language } = useContext(LanguageContext);

    const openConditions = () => {
        const currentLanguage = t.getLanguage();

        switch(currentLanguage.countryCode.toLowerCase()) {
            case 'fr':
                return 'https://bodycheckup.com/la-condition/';
            default:
                return 'https://bodycheckup.com/conditions/';
        }
    }

    const openGDPR = () => {
        const currentLanguage = t.getLanguage();

        switch(currentLanguage.countryCode.toLowerCase()) {
            case 'fr':
                return 'https://bodycheckup.com/confidentialite/';
            default:
                return 'https://bodycheckup.com/privacy-policy/';
        }
    }

    return (
        <div className="row">
            <DefaultContainer
                title={<h2 className="bold">{t.translate('kitContracts.kit.title')}</h2>}
                body={
                    <CustomLinkWrapper title={t.translate('kitContracts.kit.sub')} link_text={t.translate('kitContracts.kit.btn')} link={"/communicationsKit.pdf"} />
                }
            />
            <CustomSpacer />
            <DefaultContainer
                title={<h2 className="bold">{t.translate('kitContracts.contracts.title')}</h2>}
                body={
                    <Fragment>
                        <CustomLinkWrapper
                            title={t.translate('kitContracts.contracts.cgu.sub')}
                            link_text={t.translate('kitContracts.contracts.cgu.btn')}
                            link={openConditions()}
                        />
                        <CustomLinkWrapper
                            title={t.translate('kitContracts.contracts.cga.sub')}
                            link_text={t.translate('kitContracts.contracts.cga.btn')}
                            link={openGDPR()}
                        />
                    </Fragment>
                }
            />
        </div>
    )
}

export default CompanyKitContracts;