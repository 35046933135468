import React from 'react';
import "./scss/DashboardHeader.scss";

const DashboardHeader: React.FC<({
    preTitle?: string
    title?: string
    button?: JSX.Element
})> = ({ preTitle, title, button }) => {
    return (
        <div id="dashboard-header">
            <div id="title-container">
                <h1 id="title">
                    {preTitle
                        ? <span className="MavenPro uppercase">
                            {preTitle}{' '}
                        </span>
                        : null
                    }
                    {title
                        ? <span className="text-gradient font-title-bold">
                            {title}
                        </span>
                        : null
                    }
                </h1>
                {button
                    ? button
                    : null
                }
            </div>
        </div>
    );

}

export default DashboardHeader;