import { Button, Loader } from "components";
import { Modal } from 'components/common/Modal';
import { UserContext } from "context/User/UserContext";
import 'moment-timezone';
import React, { useCallback, useContext, useEffect, useState } from "react";
import Cropper from 'react-easy-crop';
import { Area } from "react-easy-crop/types";
import { useTranslation } from "services";
import getCroppedImg from "services/croppedImage.service";
import userAuthService from "services/user-auth.service";

const ModalEditProfilePicture: React.FC<({
    showModal: boolean
    handleClose: () => void
    handleSuccess: () => void
    img?: File
})> = ({ showModal, handleClose, handleSuccess, img }) => {
    const t = useTranslation('components/profilePicture');
    const { refreshProfilePicture } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ crop, setCrop ] = useState({ x: 0, y: 0 });
    const [ zoom, setZoom ] = useState<number>(1);
    const [ croppedAreaPixels, setCroppedAreaPixels ] = useState<Area>();

    const closeModal = () => {
        handleClose();
        setIsLoading(false);
        document.documentElement.style.overflow = 'auto';
    }

    const successModal = () => {
        handleSuccess();
        closeModal();
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    const handleSaveProfileInfo = async () => {
        if (img && croppedAreaPixels) {
            setIsLoading(true);

            getCroppedImg(
                URL.createObjectURL(img),
                croppedAreaPixels
            )
                .then(async (res) => {
                    if (res) {
                        await handleUploadProfilePicture(res);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
        
    }

    const handleUploadProfilePicture = async (croppedImage: Blob) => {
        const pictureFormData = new FormData();
        pictureFormData.append('photo', croppedImage);

        userAuthService.uploadProfilePicture(pictureFormData)
            .then(async () => {
                await refreshProfilePicture();
                successModal();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate('title')}
            footer={
                !isLoading
                    ? <div className="flex-row">
                        <Button label={t.translate('cancel')} onClick={() => closeModal()} />
                        <Button label={t.translate('save')} onClick={() => handleSaveProfileInfo()} />
                    </div>
                    : null
            }
        >
            {isLoading
                ? <Loader loaderDescription={t.translate('load')} />
                : img
                    ? <Cropper
                        image={URL.createObjectURL(img)}
                        cropShape="round"
                        crop={crop}
                        onCropChange={setCrop}
                        zoom={zoom}
                        onZoomChange={setZoom}
                        cropSize={{ height: 400, width: 400 }}
                        onCropComplete={(croppedArea, croppedAreaPixels) => onCropComplete(croppedArea, croppedAreaPixels)}
                        style={{ containerStyle: { position: 'relative', display: 'block', height: '400px', width: '400px' }, mediaStyle: {}, cropAreaStyle: {} }}
                    />
                    : <p className="no-data">{t.translate('error.loadingUserImage')}</p>
            }
        </Modal>
    );
}

export default ModalEditProfilePicture;