import { UserContext } from "context/User/UserContext";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { insuranceService, useTranslation } from "services";
import fileService from "services/file.service";
import DefaultContainer from "./common/DefaultContainer/DefaultContainer";
import { Button } from "./Form";

const InsuranceCoverage: React.FC<{
    forceShow?: boolean
}> = ({
    forceShow = false
}) => {
    if (!forceShow) return null;

    const t = useTranslation('components/InsuranceTest');

    const { user } = useContext(UserContext);
    const [ isContacting, setIsContacting ] = useState<boolean>(false);
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const [ isSendingByMail, setIsSendingByMail ] = useState<boolean>(false);

    const handleDownload = () => {
        setIsDownloading(true);

        insuranceService.getInsuranceCoverage()
            .then((res) => fileService.downloadFile(res, { filename: `${user?.formatPatientName() ?? 'user'}-insurance coverage`.toLowerCase().replaceAll(' ', '_') }))
            .catch((err) => console.warn(err))
            .finally(() => setIsDownloading(false));
    }

    const handleSendByMail = () => {
        setIsSendingByMail(true);

        insuranceService.sendInsuranceCoverageByMail()
            .then(() => toast.success('Un mail vous a été envoyé avec le devis en pièce jointe.'))
            .catch((err) => console.warn(err))
            .finally(() => setIsSendingByMail(false));
    }
    
    const handleContact = () => {
        setIsSendingByMail(true);

        insuranceService.sendInsuranceCoverageByMail()
            .then(() => toast.success('Un mail vous a été envoyé avec le devis en pièce jointe.'))
            .catch((err) => console.warn(err))
            .finally(() => setIsSendingByMail(false));
    }

    return (
        <DefaultContainer
            wrapperClassName="shadow-plus"
            wrapperStyle={{
                borderRadius: '20px'
            }}
            title={
                <div className="flex-row-start">
                    {/* <ToolTip
                        title={t.translate('tooltip.title')}
                    >
                        <p>{t.translate('tooltip.desc')}</p>
                        <ul>
                            {['Harmonie Mutuelle', 'MGEN', 'Aésio Mutuelle', 'La Mutuelle Générale', 'Apivia Macif Mutuelle'].map((e) => <li key={e}>{e}</li>)}
                        </ul>
                    </ToolTip> */}
                    <h2 className="container-title bold">{t.translate('title')}</h2>
                </div>
            }
            body={
                <p className="MavenPro" style={{ fontSize: '1.4rem' }}>{t.translate('description')}</p>
            }
            footer={
                <div className="flex-row">
                    <Button label={t.translate('contact')} onClick={() => handleContact()} displayLoader={isContacting} disabled={isContacting || isDownloading || isSendingByMail} />
                    {/* <Button label={t.translate('email')} onClick={() => handleSendByMail()} displayLoader={isSendingByMail} disabled={isSendingByMail || isDownloading} /> */}
                    {/* <Button label={t.translate('button')} onClick={() => handleDownload()} displayLoader={isDownloading} disabled={isDownloading || isSendingByMail} /> */}
                </div>
            }
        />
    )
}

export default InsuranceCoverage;