import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { StripeEmbeddedCheckoutOptions, loadStripe } from "@stripe/stripe-js";
import { Button, Checkbox, Loader } from "components";
import { Modal } from "components/common/Modal";
import { LanguageContext } from "context/Language/LanguageContext";
import { IDAddress, IDPlanType, IDPlatform, IDPrices, IDProductOrderOption, IDProductOrderType } from "interfaces/Database";
import PaymentType from "interfaces/Database/IDatabasePaymentType";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "services";
import useCheckoutService from "services/checkout.service";

export interface PaymentProps {
    platform: IDPlatform,
    payment_types: PaymentType[],
    plan_type: IDPlanType,
    product_order_type: IDProductOrderType,
    renew: boolean,
    options: IDProductOrderOption[],
    customPrice?: IDPrices,
    billingAddress: IDAddress,
    togglePaymentFormLoaded: () => void
    togglePreviousStep: () => void
}

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PK), { locale: 'auto' });

const Payment: React.FC<PaymentProps> = (props) => {
    const checkoutService = useCheckoutService();
    const t = useTranslation('pages/orderSolution');

    const { language } = useContext(LanguageContext);

    const [ clientSecret, setClientSecret ] = useState<string>('');

    const [ payment_type, setPaymentType ] = useState<PaymentType>();
    const [ solution_nbr ] = useState<number>(1);

    const [ confirmationModal, setConfirmationModal ] = useState<boolean>(props.plan_type.name === 'cancer_detection');
    const toggleConfirmationModal = () => setConfirmationModal(!confirmationModal);

    const [ hasValidateCustomRequirements, setHasValidateCustomRequirements ] = useState<boolean>(false);
    const toggleHasValidateCustomRequirements = () => setHasValidateCustomRequirements(!hasValidateCustomRequirements);

    const [ validateRestrictions, setValidateRestrictions ] = useState<{
        age: boolean,
        noDiagnosisOrTreatment: boolean,
        noAnySymptoms: boolean,
        noClinicalSuspicion: boolean,
        noBloodTransfusion: boolean,
        arrangingAppointment: boolean,
        accepGoodBodyTaC: boolean,
        consentResultsAvailable: boolean
    }>({
        age: false,
        noDiagnosisOrTreatment: false,
        noAnySymptoms: false,
        noClinicalSuspicion: false,
        noBloodTransfusion: false,
        arrangingAppointment: false,
        accepGoodBodyTaC: false,
        consentResultsAvailable: false
    });

    const options: StripeEmbeddedCheckoutOptions = useMemo(() => ({
        clientSecret: clientSecret,
    }), [ clientSecret ]);

    const loadData = () => {
        if (payment_type) {
            checkoutService.createCheckoutSession({
                payment_type_id: payment_type?.id,
                plan_type_id: props.plan_type.id,
                platform_id: props.platform.id,
                product_order_type_id: props.product_order_type.id,
                renew: props.renew,
                options: props.options,
                solution_nbr: solution_nbr,
                language: language.countryCode
            })
                .then((res) => setClientSecret(res.client_secret));
        }
    }

    useEffect(() => {
        if (props.plan_type) {
            if (!props.plan_type.monthlyPayment) {
                setPaymentType(props.payment_types.filter((e) => e.isOneTime())[0]);
            }
        }
    }, [props.plan_type, props.customPrice]);

    useEffect(() => {
        setClientSecret('');
        loadData();
    }, [payment_type, language]);

    const handleValidate = () => {
        toggleConfirmationModal();
        toggleHasValidateCustomRequirements();
    }

    const handleCloseModal = () => {
        props.togglePreviousStep();
        toggleConfirmationModal();
    }

    if (!clientSecret) {
        return (
            <Loader />
        )
    }
    
    return (
        <div id="checkout" className="full-width">
            {(props.plan_type.name === 'cancer_detection' && !hasValidateCustomRequirements)
                ? <Loader />
                : <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={options}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            }
            <Modal
                isShow={confirmationModal}
                toggleShow={handleCloseModal}
                title="Confirmation"
                footer={
                    <div className="flex-row">
                        <Button label="Annuler" onClick={handleCloseModal} />
                        <Button
                            label="Confirmer"
                            onClick={handleValidate}
                            disabled={
                                !validateRestrictions.age ||
                                !validateRestrictions.noDiagnosisOrTreatment ||
                                !validateRestrictions.noAnySymptoms ||
                                !validateRestrictions.noClinicalSuspicion ||
                                !validateRestrictions.noBloodTransfusion ||
                                !validateRestrictions.arrangingAppointment ||
                                !validateRestrictions.accepGoodBodyTaC ||
                                !validateRestrictions.consentResultsAvailable
                            }
                        />
                    </div>
                }
            >
                <p>{t.translate('steps.payment.restrictedModal.desc')}</p>

                <Checkbox
                    required
                    name="cancer-age"
                    label={t.translate('steps.payment.restrictedModal.cancer.1')}
                    isChecked={validateRestrictions.age}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        age: !validateRestrictions.age
                    }))}
                    wrapperClassName="full-width"
                />
                <Checkbox
                    required
                    name="cancer-no-diagnosis"
                    label={t.translate('steps.payment.restrictedModal.cancer.2')}
                    isChecked={validateRestrictions.noDiagnosisOrTreatment}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        noDiagnosisOrTreatment: !validateRestrictions.noDiagnosisOrTreatment
                    }))}
                    wrapperClassName="full-width"
                />
                <Checkbox
                    required
                    name="cancer-no-symptoms"
                    label={t.translate('steps.payment.restrictedModal.cancer.3')}
                    isChecked={validateRestrictions.noAnySymptoms}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        noAnySymptoms: !validateRestrictions.noAnySymptoms
                    }))}
                    wrapperClassName="full-width"
                />
                <Checkbox
                    required
                    name="cancer-no-suspicions"
                    label={t.translate('steps.payment.restrictedModal.cancer.4')}
                    isChecked={validateRestrictions.noClinicalSuspicion}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        noClinicalSuspicion: !validateRestrictions.noClinicalSuspicion
                    }))}
                    wrapperClassName="full-width"
                />
                <Checkbox
                    required
                    name="cancer-no-blood-transfusions"
                    label={t.translate('steps.payment.restrictedModal.cancer.5')}
                    isChecked={validateRestrictions.noBloodTransfusion}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        noBloodTransfusion: !validateRestrictions.noBloodTransfusion
                    }))}
                    wrapperClassName="full-width"
                />
                <Checkbox
                    required
                    name="cancer-arranging-appointments"
                    label={t.translate('steps.payment.restrictedModal.cancer.6')}
                    isChecked={validateRestrictions.arrangingAppointment}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        arrangingAppointment: !validateRestrictions.arrangingAppointment
                    }))}
                    wrapperClassName="full-width"
                />
                <Checkbox
                    required
                    name="cancer-accept-good-body-tac"
                    label={t.translateReplaceValues('steps.payment.restrictedModal.cancer.7', [
                        {
                            tag: "{{here}}",
                            value: <a
                                href="https://health.goodbodyclinic.com/tandc/"
                                className="text-main-color clickable"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t.translate('steps.payment.restrictedModal.cancer.7-2')}
                            </a>
                        }
                    ])}
                    isChecked={validateRestrictions.accepGoodBodyTaC}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        accepGoodBodyTaC: !validateRestrictions.accepGoodBodyTaC
                    }))}
                    wrapperClassName="full-width"
                />
                <Checkbox
                    required
                    name="cancer-consent-results"
                    label={t.translate('steps.payment.restrictedModal.cancer.8')}
                    isChecked={validateRestrictions.consentResultsAvailable}
                    onClick={() => setValidateRestrictions((prev) => ({
                        ...prev,
                        consentResultsAvailable: !validateRestrictions.consentResultsAvailable
                    }))}
                    wrapperClassName="full-width"
                />

                <p>{t.translate('steps.payment.restrictedModal.footer')}</p>
            </Modal>
        </div>
    )
}

export default Payment;