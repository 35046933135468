import { IIcon, defaultIconProps } from "interfaces/IIcon";
import React from "react";
import { FaUserCircle } from "react-icons/fa";

const UserCircle: React.FC<IIcon> = (props) => {
    return (
        <FaUserCircle
            id={props.id}
            className={props.className}
            size={props.size}
            color={props.color}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            style={{
                minWidth: props.size,
                minHeight: props.size,
                ...props.style
            }}
        />
    )
}

UserCircle.defaultProps = defaultIconProps;

export default UserCircle;