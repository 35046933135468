import { Button, CloseIcon } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import { ProfilePicture } from "components/common/ProfilePicture";
import ToolTip from "components/common/ToolTip/ToolTip";
import { IDAppointmentWithNutritionist } from "interfaces/Database";
import 'moment-timezone';
import React, { Fragment, useState } from "react";
import Calendar from 'react-calendar';
import { toast } from 'react-toastify';
import { useTranslation } from 'services';
import appointmentService from "services/appointment.service";
import doctorService from "services/doctor.service";
import ModalScheduleAppointmentWithNutritionist from "../../ModalScheduleAppointmentWithNutritionist";
import "../scss/NextAppointment.scss";

const NextAppointmentWNutritionist: React.FC<({
    nextAppointment?: IDAppointmentWithNutritionist;
    startVideoMeeting: React.MouseEventHandler<HTMLButtonElement> | undefined;
    isMeeting: boolean;
    idAppointmentToSchedule?: number;
    handleRefresh: () => Promise<void>;
    getResults?: boolean;
    isLoadingMeeting?: boolean;
})> = ({
    nextAppointment,
    startVideoMeeting,
    isMeeting, 
    idAppointmentToSchedule,
    handleRefresh,
    getResults = false,
    isLoadingMeeting = false
}) => {
    const t = useTranslation('dashboard/patient/appointment');

    const [ showModalSchedule, setShowModalSchedule ] = useState<boolean>(false);
    const [ showModalCancel, setShowModalCancel ] = useState<boolean>(false);

    const handleCancelAppointment = async () => {
        if (nextAppointment?.id) {
            await appointmentService.cancelAppointmentWNutritionist(nextAppointment?.id)
                .then(() => {
                    handleRefresh();
                    toast.success(t.translate('next-appointment.cancel.success'));
                })
                .catch((err) => {
                    if (err.message === 'appointment_not_cancelled') {
                        toast.error(t.translate('next-appointment.cancel.error'));
                    } else {
                        toast.error(t.translateApiError(err.message));
                        handleRefresh();
                    }
                });
        }
    }

    return (
        <DefaultContainer
            title={
                <div className="flex-row-start align-items-center">
                    <ToolTip title="Visio-conférences BodyCheckup">
                        <div className="flex-column">
                            <p style={{ textAlign: 'justify' }}>
                                {t.translate('next-appointment.toolTip.body')}
                            </p>
                            <Button label={t.translate('next-appointment.toolTip.button')} onClick={() => window.open('')} />
                        </div>
                    </ToolTip>
                    <h1 className="bold">
                        {t.translate('next-appointmentWNutritionist.title')}
                    </h1>
                </div>
            }
            body={nextAppointment && nextAppointment.appointmentOn
                ? <Fragment>
                    <div className="flex-row" id="next-appointment-wrapper">
                        <div id="doctor-wrapper" className="flex-column flex-gap-column">
                            <div className="row" id="doctor-content-wrapper">
                                <div id="doctor-img-wrapper">
                                    <ProfilePicture user={nextAppointment.nutritionist} />
                                </div>
                                <div className="column" id="doctor-info-wrapper">
                                    <p id="doctor-name" className="uppercase MavenPro-black">
                                        {doctorService.formatDoctorName(nextAppointment?.nutritionist?.doctorInfo)}
                                    </p>
                                    <p id="appointment-date">{appointmentService.getFormattedDate(nextAppointment?.appointmentOn)}</p>
                                </div>
                            </div>
                            <Button
                                label={t.translate('next-appointment.start-video')}
                                disabled={!isMeeting || isLoadingMeeting}
                                displayLoader={isLoadingMeeting}
                                onClick={startVideoMeeting}
                            />
                        </div>
                        <div id="calendar-wrapper">
                            <Calendar
                                minDate={nextAppointment.appointmentOn}
                                maxDate={nextAppointment.appointmentOn}
                            />
                        </div>
                    </div>
                    <div className="row" id="appointment-controls-wrapper">
                        <div className="row control-wrapper" onClick={() => setShowModalCancel(true)} id="cancel">
                            <CloseIcon color="var(--red)" />
                            <span className="control-label MavenPro-bold">
                                {t.translate('next-appointment.cancel-appointment')}
                            </span>
                        </div>
                    </div>
                    <ModalConfirmAction
                        showModal={showModalCancel}
                        handleClose={() => setShowModalCancel(false)}
                        confirmAction={handleCancelAppointment}
                        description={t.translate('next-appointment.confirmModal.desc')}
                    />
                </Fragment>
                : getResults
                    ? <Fragment>
                        <p className="no-data" style={{ margin: '15px 0' }}>
                            {t.translate('next-appointment.none')}
                        </p>
                        
                        {idAppointmentToSchedule &&
                            <Fragment>
                                <div id="btn-schedule-appointment" onClick={() => setShowModalSchedule(true)}>
                                    <span className="MavenPro-bold">
                                        {t.translate('next-appointment.schedule-appointment')}
                                    </span>
                                </div>

                                <ModalScheduleAppointmentWithNutritionist
                                    showModal={showModalSchedule}
                                    handleClose={() => setShowModalSchedule(false)}
                                    handleSuccess={handleRefresh}
                                    appointmentId={idAppointmentToSchedule}
                                />
                            </Fragment>
                        }
                    </Fragment>
                    : <p className="no-data" style={{ margin: '15px 0' }}>
                        {t.translate('next-appointment.waitingResults')}
                    </p>
            }
        />
    )
}

export default NextAppointmentWNutritionist;