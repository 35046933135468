import { Loader } from "components";
import { ChartCaptionParams } from "components/common/Charts/ChartCaption";
import DonutChart from "components/common/Charts/DonutChart";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ToolTip from "components/common/ToolTip/ToolTip";
import { IChartData } from "interfaces/Services/ChartService/IChartData";
import IDrawChartParams from "interfaces/Services/ChartService/IDrawChartParams";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "services";
import companyService from "services/company.service";

interface ISolutionResume {
    planType: string,
    total?: number,
    free?: number,
    attributed?: number,
    ongoing?: number,
    ended?: number,
    cancelled?: number,
}

const chartParams: IDrawChartParams = {
    padAngle: .02,
    customInnerRadius: 120,
    displayLabel: true,
    fontColor: '#000'
}

const captionParams: ChartCaptionParams = {
    showCaption: true,
    captionPosition: 'right',
    hidePercentage: true,
    translateLabel: true
}

const CompanySolutionResume: React.FC = () => {
    const t = useTranslation('dashboard/company/kitResume');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ faResume, setFAResume ] = useState<IChartData[]>([]);
    const [ stateResume, setStateResume ] = useState<IChartData[]>([]);

    const fetchData = useCallback(() => {
        setIsLoading(true);

        companyService.getSolutionResume()
            .then((res: ISolutionResume[]) => {
                const freeChartData: IChartData = {
                    value: 0,
                    color: 'var(--dashboard-main-color)',
                    label: t.getFullPath('solutionsStatus.available')
                };
                const attrChartData: IChartData = {
                    value: 0,
                    color: 'var(--orange)',
                    label: t.getFullPath('solutionsStatus.attributed')
                };

                const goingChartData: IChartData = {
                    value: 0,
                    color: 'var(--dashboard-main-color)',
                    label: t.getFullPath('solutionsStatus.ongoing')
                };
                const endedChartData: IChartData = {
                    value: 0,
                    color: 'var(--light-gray)',
                    label: t.getFullPath('solutionsStatus.ended')
                };
                const cancelledChartData: IChartData = {
                    value: 0,
                    color: 'var(--red)',
                    label: t.getFullPath('solutionsStatus.cancelled')
                };

                res.forEach((resume) => {
                    freeChartData.value += resume?.free ? resume.free : 0;
                    attrChartData.value += resume?.attributed ? resume.attributed: 0;

                    goingChartData.value += resume?.ongoing ? resume.ongoing : 0;
                    endedChartData.value += resume?.ended ? resume.ended : 0;
                    cancelledChartData.value += resume?.cancelled ? resume.cancelled : 0;
                });

                setFAResume([ freeChartData, attrChartData ].sort((a, b) => a.value > b.value ? 1 : -1));
                setStateResume([ goingChartData, endedChartData, cancelledChartData ].sort((a, b) => a.value > b.value ? 1 : -1));
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DefaultContainer
            title={
                <div className='flex-row-start'>
                    <ToolTip>
                        <Fragment>
                            <p>{t.translate('tooltip.menu')}</p>
                            <p>{t.translate('tooltip.first')}</p>
                            <p>{t.translate('tooltip.second')}</p>
                        </Fragment>
                    </ToolTip>
                    <h2 className='bold'>{t.translate('title')}</h2>
                </div>
            }
            body={isLoading
                ? <Loader />
                : <div className="flex-row full-width" style={{ justifyContent: 'space-around' }}>
                    <DonutChart
                        data={faResume}
                        chartParams={chartParams}
                        captionParams={captionParams}
                    />
                    <DonutChart
                        data={stateResume}
                        chartParams={chartParams}
                        captionParams={captionParams}
                    />
                </div>

            }
        />
    )
}

export default CompanySolutionResume;