import { IDAddress } from "interfaces/Database";
import { IAddress } from "interfaces/User/IAddress";
import { isEqual } from "lodash";
import apiService from "./api.service";

class AddressService {
    public databaseAddressToLocal(address?: IDAddress): IAddress {
        if (address) {
            return {
                additionalInfos: address.additionalInfos,
                aptNumber: address.aptNumber,
                city: address.city,
                company: address.company,
                countryName: address.country?.name ? address.country.name : undefined,
                firstname: address.firstname,
                lastname: address.lastname,
                postalCode: address.postalCode,
                siret: address.siret,
                streetAddress: address.streetAddress,
            }
        } else {
            return {
                additionalInfos: undefined,
                aptNumber: undefined,
                city: undefined,
                company: undefined,
                countryName: undefined,
                firstname: undefined,
                lastname: undefined,
                postalCode: undefined,
                siret: undefined,
                streetAddress: undefined,
            }
        }
    }

    public compareAddresses(billing?: IDAddress, shipping?: IDAddress): boolean {
        return billing?.streetAddress === shipping?.streetAddress &&
            billing?.postalCode === shipping?.postalCode &&
            billing?.city === shipping?.city &&
            isEqual(billing?.country, shipping?.country) &&
            billing?.aptNumber === shipping?.aptNumber &&
            billing?.additionalInfos === shipping?.additionalInfos;
    }

    public compareDBWithLiveAddress(addressA: IDAddress, addressB: IAddress): boolean {
        return addressA?.streetAddress === addressB?.streetAddress &&
            addressA?.postalCode === addressB?.postalCode &&
            addressA?.city === addressB?.city &&
            addressA?.country.name === addressB?.countryName &&
            addressA?.aptNumber === addressB?.aptNumber &&
            addressA?.additionalInfos === addressB?.additionalInfos;
    }

    public async getAddresses() {
        return await apiService.makeApiCall<{ shipping?: IDAddress, billing?: IDAddress }>(`/api/address`, 'get');
    }

    // TODO : Duplicate of getAddresses, update next to merge all addresses gesture here (check standalone & company)
    public async getBillingAndShippingAddresses() {
        return await apiService.makeApiCall<{ shipping?: IDAddress, billing?: IDAddress }>(`/api/address`, 'get');
    }
    
    public async getBillingAddress() {
        return await apiService.makeApiCall<IDAddress>('/api/address/billing', 'get');
    }

    public async getShippingAddress() {
        return await apiService.makeApiCall<IDAddress>('/api/address/shipping', 'get');
    }

    public async updateAddresses(data: {
        shipping?: IAddress
        billing?: IAddress
        sameAddresses: boolean
    }) {
        return await apiService.makeApiCall('/api/address/new', 'post', data)
    }

    
}

export default new AddressService();