import { Modal } from "components/common/Modal";
import { Button } from "components/Form";
import { LanguageContext } from "context/Language/LanguageContext";
import { Cart } from "interfaces";
import { IComplement } from "interfaces/Database";
import { useContext } from "react";
import { FaPills } from "react-icons/fa";
import { useTranslation } from "services";
import priceService from "services/price.service";
import './ComplementDetailModal.scss';

interface ComplementDetailModalProps {
    complement?: IComplement
    setComplement: React.Dispatch<React.SetStateAction<IComplement | undefined>>
    cart: Cart<IComplement>
    setCart: React.Dispatch<React.SetStateAction<Cart<IComplement>>>
}

const ComplementDetailModal: React.FC<ComplementDetailModalProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');
    const handleClose = () => props.setComplement(undefined);
    const handleAddToBasket = () => {
        if (props.complement) {
            props.setCart(new Cart(props.cart.addItem(props.complement, 1)));
        }

        handleClose();
    }

    const handleRemoveFromBasket = () => {
        if (props.complement) {
            props.setCart(new Cart(props.cart.removeItem(props.complement)));
        }

        handleClose();
    }

    const { isLanguage } = useContext(LanguageContext);

    if (!props.complement || props.complement === undefined) return null;

    const handleRenderDescription = () => {
        if (!props.complement) return null;
        
        if (isLanguage('fr') && props.complement.description_fr) {
            return props.complement.description_fr;
        }

        return props.complement.description;
    }

    return <Modal
        isShow={props.complement !== undefined}
        toggleShow={handleClose}
        subTitle={
            props.complement.category?.name
                && t.translateComplementCategoryName(props.complement.category?.name)
        }
        title={props.complement.name}
        footer={
            <div className="flex-row full-width space-around">
                <h3>
                    {t.translateReplaceValues('price', [
                        {
                            tag: '{{price}}',
                            value: priceService.roundAndFormat(props.complement.price)
                        }
                    ])}
                </h3>

                {props.cart.hasItem(props.complement)
                    ? <Button label={t.translate('removeFromCart')} onClick={handleRemoveFromBasket} classname="dashboardBtn-dngr" />
                    : <Button label={t.translate('addToCart')} onClick={handleAddToBasket} />
                }
            </div>
        }
    >
        <div className="complement-detail-modal">
            <div className="complement-detail-modal-detail">
                <img
                    className="complement-detail-modal-detail-img"
                    src={props.complement.picture?.getUrl()}
                />
                <div className="flex-column-start">
                    <h2>{props.complement.name}</h2>
                    <p>{handleRenderDescription()}</p>
                </div>
            </div>
            <p><FaPills color="var(--dashboard-main-color)" size={28} style={{ margin: '0 8px 0 0', verticalAlign: 'middle' }} />{t.translateReplaceValues('perJar', [
                {
                    tag: '{{amount}}',
                    value: `${props.complement.quantityInBox} ${t.translateComplementQuantityType(props.complement.quantityType)}`
                }
            ])}</p>
        </div>
    </Modal>
}

export default ComplementDetailModal;