import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { ProfilePicture } from "components/common/ProfilePicture";
import { IDNutritionalComment } from "interfaces/Database";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import nutritionalCommentService from "services/nutritionalComment.service";
import translationService from "services/translation.service";
import verifierService from "services/verifier.service";

const NutritionalCommentResume: React.FC = () => {
    const t = useTranslation('dashboard/patient/recommendations');
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ comment, setComment ] = useState<IDNutritionalComment>();

    const fetchData = useCallback(() => {
        setIsLoading(true);

        Promise.all([
            nutritionalCommentService.getLatestUserNutritionalComment()
                .then((res) => setComment(res)),
        ])
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const renderDiets = comment?.diets.map((diet, index) =>
        <div key={`recommendedDiets-${index}`} style={{
            padding: '1vh 2vw',
        }}>
            <p className="MavenPro-black text-align-center">{translationService.translateDiet(diet.name)}</p>
        </div>
    );

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate('details.recommendedDiets.title')}</h1>}
            body={isLoading
                ? <Loader />
                : comment
                    ? <Fragment>
                        <div className="flex-row full-width">
                            <div className="flex-column" style={{ width: '40%' }}>
                                {comment && comment.doctor
                                    ? <div className="flex-row" style={{ padding: '20px' }}>
                                        <div className="flex-column" style={{ marginRight: '10px' }}>
                                            <ProfilePicture
                                                user={comment?.doctor}
                                                maxSize={'6rem'}
                                            />
                                        </div>
                                        <div className="flex-column">
                                            { comment?.doctor?.doctorInfo
                                                ? <Fragment>
                                                    <p style={{ margin: '5px' }}>{doctorService.formatDoctorName(comment?.doctor?.doctorInfo)}</p>
                                                    <Button label={t.translate('details.recommendedDiets.messageHim')} onClick={() => history.push('/account/messaging', { openDoctorConversation: comment?.doctor.id })} />
                                                </Fragment>
                                                : <p className="no-data">{t.translateFromFile('common', 'no-data')}</p>
                                            }
                                        </div>
                                    </div>
                                    : <p className="no-data">{t.translate('details.recommendedDiets.doctorNotAssigned')}</p>
                                }
                            </div>
                            <div className="flex-column-start" style={{ width: '60%' }}>
                                <p className="MavenPro-black">{t.translate('details.recommendedDiets.doctorsCommentary')} :</p>
                                {verifierService.checkString(comment?.comment)
                                    ? <div className="flex-row space-center align-items-center full-width">
                                        <div className="flex-row" style={{ width: '80%' }}>
                                            <RiDoubleQuotesL size={32} className="align-items-start" style={{ marginBottom: 'auto' }} />
                                            <p className="text-align-justify" style={{ margin: '10px' }}>{comment?.comment}</p>
                                            <RiDoubleQuotesR size={32} className="align-items-end" style={{ marginTop: 'auto' }} />
                                        </div>
                                    </div>
                                    : <div className="flex-row full-width space-center">
                                        <p className="no-data">{t.translate('details.recommendedDiets.noCommentary')}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="column" id="recommended-foods-header">
                            <span className="MavenPro-black text-uppercase">
                                {t.translate('details.recommendedDiets.subTitle')}
                            </span>
                            <p id="header-description">
                                {t.translate('details.recommendedDiets.description')}
                            </p>
                        </div>

                        <div className="flex-row space-evenly">
                            {renderDiets}
                        </div>
                    </Fragment>
                    : <p className="no-data">{t.translate('details.recommendedDiets.noCommentaryYet')}</p>
            }
            footer={
                comment && <Button label={t.translate('details.recommendedDiets.seeAllFoods')} onClick={() => history.push(`/account/foods?diets=${comment?.diets.map((diet) => diet.name).join(';')}`)} />
            }
        />
    )
}

export default NutritionalCommentResume;