import React, { CSSProperties } from 'react';
import "./style/DefaultContainer.scss";

const DefaultContainer: React.FC<({
    wrapperClassName?: string
    wrapperStyle?: CSSProperties
    header?: {
        image: string;
        imageStyle?: React.CSSProperties;
        content: React.ReactNode;
    }
    title?: React.ReactNode | string
    titleContainerClassName?: string
    body?: React.ReactNode
    bodyContainerStyle?: React.CSSProperties
    bodyContainerClassName?: string
    footer?: React.ReactNode
    footerContainerClassName?: string
    hideSeparator?: boolean
    fullWidth?: boolean
})> = ({
    wrapperClassName = "",
    wrapperStyle,
    header,
    title,
    titleContainerClassName = "",
    body,
    bodyContainerStyle,
    bodyContainerClassName = "",
    footer,
    footerContainerClassName = "",
    hideSeparator = false,
    fullWidth = true
}) => {
    return (
        <div className={`defaultContainer shadow ${header ? 'space-header' : ''} ${fullWidth ? 'full-width' : ''} ${wrapperClassName}`} style={wrapperStyle}>
            {header
                ? <div className={`defaultContainer-header`}>
                    <img src={header.image} alt="Women smiles looking at her phone" style={header.imageStyle ? header.imageStyle : {}} />
                    {header.content}
                    <hr />
                </div>
                : null
            }
            {title
                ? <div className={`defaultContainer-title ${titleContainerClassName}`}>
                    {typeof title === 'string'
                        ? <h2 className="container-title bold">{title}</h2>
                        : title
                    }
                </div>
                : null
            }
            <div className={`defaultContainer-body ${bodyContainerClassName}`} style={bodyContainerStyle}>
                {body}
            </div>
            {footer
                ? <div className={`defaultContainer-footer ${footerContainerClassName}`}>
                    {footer}
                </div>
                : null
            }
            {hideSeparator ? null : <section className="defaultContainer-separator" />}
        </div>
    )
}

export default DefaultContainer;