import { Button, Input, Label, Phone } from "components";
import { Modal } from 'components/common/Modal';
import { IDUser } from "interfaces/Database";
import React, { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "services";
import patientService from "services/patient.service";
import verifierService from "services/verifier.service";

const ModalEditContactInfo: React.FC<({
    showModal: boolean
    handleClose: () => void
    handleSuccess: () => void
    currentUser?: IDUser
})> = ({ showModal, handleClose, handleSuccess, currentUser }) => {
    const t = useTranslation('profile/patient/contact');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ email, setEmail ] = useState<string | undefined>(currentUser?.email);
    const [ phoneNumber, setPhoneNumber ] = useState<string | undefined>(currentUser?.phone?.number ?? undefined);
    const [ countryCode, setCountryCode ] = useState<string | undefined>(currentUser?.phone?.country?.phonecode ?? undefined);

    const inputContainer: CSSProperties = {
        width: '48%'
    };

    const rowInputContainer: CSSProperties = {
        marginBottom: '2vh'
    };

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'scroll';
    }

    const successModal = () => {
        handleSuccess();
        closeModal();
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        }
    })

    const handleEditUser = () => {
        setIsLoading(true);
        patientService.updateContactInformations({
            countryCode: countryCode,
            phoneNumber: phoneNumber,
        })
            .then(() => successModal())
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false))
    }

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate("titleModal")}
            footer={
                <div className="row flex-end">
                    <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                    <Button label={t.translate('save')} onClick={() => handleEditUser()} displayLoader={isLoading} disabled={isLoading} />
                </div>
            }
        >
            <div className="row space-between" style={rowInputContainer}>
                <div className="column" style={inputContainer}>
                    <Label name="phone" label={t.translate('phone')} required />
                    <Phone
                        required
                        labelledBy="phone"
                        defaultCountryCode={countryCode}
                        setCountryCode={setCountryCode}
                        defaultPhoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        checkFunction={() => !!countryCode && !!phoneNumber}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
                <div className="column" style={inputContainer}>
                    <Label for="email" label={t.translate('email')} required />
                    <Input
                        required
                        name="email"
                        value={email}
                        setValue={(e) => setEmail(e.currentTarget.value)}
                        checkFunction={() => verifierService.checkEmail(email)}
                        checkmark={{
                            displayed: true
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default ModalEditContactInfo;