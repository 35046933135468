import { Loader } from "components";
import QuestionWithAnswers from "components/QuestionnaireOverview/QuestionWithAnswers";
import "components/QuestionnaireOverview/scss/DoctorQuestionnaireOverview.scss";
import { IQuestionWithAnswers } from "interfaces";
import { IDSurvey } from "interfaces/Database";
import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import surveyService from "services/survey.service";

const Patient: React.FC = () => {
    const t = useTranslation('questionnaire');
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ patientSurvey, setPatientSurvey ] = useState<IDSurvey>();

    const [ filteredAnswers, setFilteredAnswers ] = useState<IQuestionWithAnswers[]>([]);
    const [ renderedQuestion, setRenderedQuestion ] = useState<number>(10);

    const fetchData = async () => {
        surveyService.getSurveyQuestions()
            .then((questions) =>
                surveyService.getSurvey()
                    .then((res) => {
                        setPatientSurvey(res);

                        const newFilteredAnswers: IQuestionWithAnswers[] = [];
                        
                        questions.map((question) => {
                            newFilteredAnswers.push({
                                ...question,
                                answers: res.userAnswers.filter((answer) => answer.question.codeQuestion === question.codeQuestion)
                            });
                        });

                        setFilteredAnswers(newFilteredAnswers);
                    })
                    .catch((err) => console.warn(err))
            )
            .catch((err) => console.warn(err));
    }

    useEffect(() => {
        let mounted = true;

        fetchData().then(() => {
            if (mounted) {
                setIsLoading(false);
            }
        });

        return () => {
            mounted = false
        }
    }, []);

    const handleShowMoreQuestion = () => {
        setRenderedQuestion(renderedQuestion + 10);
    }

    const isAllQuestionDisplayed = () => {
        if (patientSurvey) {
            return renderedQuestion > patientSurvey.userAnswers.length;
        } else {
            return true;
        }
    }

    const displaySurveyCurrentStep = () => {
        return patientSurvey ? patientSurvey.currentStep : 0;
    }

    const isSurveyEmpty = () => {
        return !patientSurvey || patientSurvey.userAnswers.length === 0;
    }

    return (
        <div id="doctor-questionnaire-overview" className="column">
            <div id="doctor-questionnaire-overview-wrapper" className="column shadow">
                <div id="question-overview-header" className="row">
                    <div className="column">
                        <span>
                            {t.translate('title')} ({displaySurveyCurrentStep()}/58)
                        </span>
                    </div>
                    <AiOutlineClose
                        size={30}
                        color="var(--dashboard-main-color)"
                        id="icon-close"
                        onClick={() => history.go(-1)}
                    />
                </div>
                <div id="qa-list-wrapper" className="column">
                    {isLoading
                        ? <Loader />
                        : <Fragment>
                            {filteredAnswers.length > 0
                                && filteredAnswers.map((questionWithAnswers, index) =>
                                    <QuestionWithAnswers key={index} questionWithAnswers={questionWithAnswers} />
                                )
                            }
                            {!isAllQuestionDisplayed() &&
                                <div onClick={handleShowMoreQuestion} id="button-see-more">
                                    <span className="MavenPro-black">{t.translate('seeMore')}</span>
                                </div>
                            }
                            {isSurveyEmpty() &&
                                <div id="empty-survey-wrapper" className="column">
                                    <p className="no-data">
                                        {t.translate('notFilled')}
                                    </p>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default Patient;