import { Button, Checkbox } from 'components';
import EditAddressForm from 'components/common/Address/EditAddressForm';
import ErrorMessage from 'components/common/ErrorMessage/ErrorMessage';
import { Modal } from 'components/common/Modal';
import { IDatabaseAddress } from 'interfaces/Database/IDatabaseAddress';
import { IAddress, defaultAddress } from 'interfaces/User/IAddress';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'services';
import addressService from 'services/address.service';

const ModalEditAddressInfo: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void,
    areSame?: boolean,
    billingAddress?: IDatabaseAddress,
    shippingAddress?: IDatabaseAddress
})> = ({
    showModal,
    handleClose,
    handleSuccess,
    areSame = false,
    billingAddress,
    shippingAddress
}) => {
    const t = useTranslation('profile/patient/address');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();

    const [ sameAddresses, setSameAddresses ] = useState<boolean>(true);
    const [ newBillingAddress, setNewBillingAddress ] = useState<IAddress>(defaultAddress);
    const [ newShippingAddress, setNewShippingAddress ] = useState<IAddress>(defaultAddress);

    useEffect(() => {
        setNewBillingAddress(addressService.databaseAddressToLocal(billingAddress));
    }, [billingAddress]);

    useEffect(() => {
        setNewShippingAddress(addressService.databaseAddressToLocal(shippingAddress));
    }, [shippingAddress]);

    useEffect(() => {
        setSameAddresses(areSame);
    }, [areSame]);

    const closeModal = () => {
        handleClose();
    }

    const successModal = async () => {
        setIsLoading(true);
        setError(undefined);

        addressService.updateAddresses({
            sameAddresses: sameAddresses,
            billing: newBillingAddress,
            shipping: newShippingAddress
        })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => setError(err.message))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate('titleModal')}
            footer={
                <div className="flex-row full-width">
                    <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                    <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                </div>
            }
        >
            <EditAddressForm
                isLoading={isLoading}
                address={newBillingAddress}
                setAddress={setNewBillingAddress}
            />
            <Checkbox
                name='areAddressesSame'
                isChecked={sameAddresses}
                setChecked={setSameAddresses}
                label={t.translate('areAddressesSame')}
            />
            { !sameAddresses
                ? <EditAddressForm
                    isLoading={isLoading}
                    address={newShippingAddress}
                    setAddress={setNewShippingAddress}
                />
                : null
            }

            <ErrorMessage error={error} />
        </Modal>
    )
}

export default ModalEditAddressInfo