import doctors from 'assets/congratulation_medecin.png';
import { Button } from 'components';
import React from "react";
import { useTranslation } from "services";
import "../../scss/FinishStepsSuccess.scss";
import ProgressBar from "./ProgressBar";

const FinishStepsSuccess: React.FC<({
    isError: boolean
})> = ({ isError }) => {
    const t = useTranslation('firstConnection');

    return (
        <div className="column full-width" id="">
            <ProgressBar currentStep={0} numberSteps={0} />
            { !isError 
                ? <div id="finish-steps">
                    <img alt="Doctors congratulating" src={doctors} />
                    <div className="column" id="finish-steps-wrapper">
                        <div id="logo-wrapper">
                            <h2 id="welcome-on">{t.translate('congratulation')} !</h2>
                            <p id="text-info">
                                {t.translate('kitDelivery')}
                            </p>

                            <Button label={t.translate('finish')} onClick={() => window.location.reload()} />
                        </div>
                    </div>
                </div>
                : <div id="finish-steps">
                    <img alt="Doctors congratulating" src={doctors} />
                    <div className="column" id="finish-steps-wrapper">
                        <div id="logo-wrapper">
                            <h2 id="welcome-on">Oops !</h2>
                            <p id="text-info">
                                {t.translate('failCodeSMS')}
                            </p>

                            <Button label={t.translate('finish')} onClick={() => window.location.reload()} />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default FinishStepsSuccess;