import { Button } from "components";
import { IDUser } from "interfaces/Database";
import { IMenuItem } from "interfaces/Pages/Layout/IMenuItems";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import companyService from "services/company.service";
import DefaultSidebar from "./DefaultSidebar";

const CompanySidebar: React.FC<({
    menuStatus?: IMenuStatus,
    user?: IDUser
})> = ({
    menuStatus,
    user
}) => {
    const t = useTranslation('layout/sidebar');
    const history = useHistory();

    const [ companyCurrentState, setCompanyCurrentState ] = useState<'orderKits' | 'leftKitToAttribute' | '' | 'none'>('none');
    const [ complementaryValues, setComplementaryValues ] = useState<any>(); // eslint-disable-line
    const [ disableMenuBtn, setDisableMenuBtn ] = useState<boolean>(true);

    useEffect(() => {
        companyService.checkSidebarStatus()
            .then((res) => {
                setCompanyCurrentState(res.state);
                setComplementaryValues(res.additionalData);
                setDisableMenuBtn(false);
            })
            .catch(() => setDisableMenuBtn(false));
    }, []);

    const menuItems: IMenuItem[] = [
        {
            name: 'menuItems.company.dashboard.name',
            path: '/account/home',
            code: 'home',
            logout: false
        },
        {
            name: 'menuItems.company.employees.name',
            path: '/account/employees',
            code: 'employees',
            logout: false
        },
        {
            name: 'menuItems.company.invoicing.name',
            path: '/account/invoicing',
            code: 'invoicing',
            logout: false
        },
        {
            name: 'menuItems.company.profil.name',
            path: '/account/profile',
            code: 'profile',
            logout: false
        },
        {
            name: 'menuItems.company.messaging.name',
            path: '/account/messaging',
            code: 'messaging',
            logout: false
        },
        {
            name: 'menuItems.company.support.name',
            path: '/account/support',
            code: 'support',
            logout: false
        },
        {
            name: 'menuItems.company.logout.name',
            path: '/logout',
            code: 'logout',
            logout: true
        },
    ];

    function handleButton() {
        switch (companyCurrentState) {
            case 'leftKitToAttribute':
                return (
                    <div className="flex-column">
                        <p style={{ textAlign: 'center' }}>{t.translateReplaceValues('action.company.kitLeftToAttribute.label', [{ tag: '##kitsLeft', value: complementaryValues }])}</p>
                        <Button label={t.translate('action.company.kitLeftToAttribute.button')} onClick={() => history.push('/account/employees')} />
                    </div>
                )
            case 'orderKits':
            default:
                return <Button label={t.translate('action.company.orderKit')} onClick={() => history.push('/order')} disabled={disableMenuBtn} />
        }
    }

    return (
        <DefaultSidebar
            menuStatus={menuStatus}
            menuItems={menuItems}
            helloMessage={
                <p className="sidebar-title">
                    {t.translate('header.hello')}
                    <br/>
                    <span id="last-name">{user?.companyInfo?.name ?? 'COMPANY'}</span>
                </p>
            }
            subMessage={<p id="subTitle">{t.translate('header.companySub')}</p>}
            button={handleButton()}
        />
    );
}

export default CompanySidebar;