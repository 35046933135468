import { IDAnalysisKit } from "interfaces/Database";
import 'moment-timezone';
import apiService from "./api.service";

class analysisKitService {
    public async getOnGoingAnalysisKit() {
        return await apiService.makeApiCall<IDAnalysisKit>('/api/analysisKits/onGoing', 'get');
    }

    public async getPreviousAnalysisKits() {
        return await apiService.makeApiCall<IDAnalysisKit[]>(`/api/analysisKits/previous`, 'get');
    }

    public async canFillConsent() {
        return await apiService.makeApiCall<boolean>(`/api/analysisKit/canFillConsent`, 'get');
    }

    public async getAnalysisKitResultsPdf(id: number) {
        return await apiService.makeApiCall<string>(`/api/analysisKit/${id}/results/pdf`, 'get');
    }
}

export default new analysisKitService();