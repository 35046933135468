import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ToolTip from "components/common/ToolTip/ToolTip";
import { IDatabaseScoreFood } from "interfaces/Database/IDatabaseScoreFood";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import foodsService from "services/foods.service";
import "../scss/FoodAdvicePreview.scss";

const FoodAdvicePreview: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('dashboard/patient/home');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ resultsUserBased, setResultsUserBased ] = useState<boolean>(false);
    const [ orangeColor, setOrangeColor ] = useState<number>(0);
    const [ greenColor, setGreenColor ] = useState<number>(0);
    const [ foodList, setFoodList ] = useState<IDatabaseScoreFood[]>([]);

    function fetchFoodList() {
        setIsLoading(true);

        foodsService.getDashboardFoods()
            .then((res) => {
                setResultsUserBased(res.resultsUserBased);
                setOrangeColor(res.colors.orange);
                setGreenColor(res.colors.green);
                setFoodList(res.foods);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchFoodList();
    }, []);

    return (
        <DefaultContainer
            title={
                <div className="flex-row space-between full-width" style={{ alignItems: 'center' }}>
                    <div className="flex-row">
                        {!resultsUserBased
                            ? <ToolTip title={t.translate('nutritional-advice.tooltip.title')}>
                                <p>{t.translate('nutritional-advice.tooltip.content')}</p>
                            </ToolTip>
                            : null
                        }

                        <div className="flex-column-start">
                            <h1 className="bold">
                                {t.translate('nutritional-advice.title')}
                            </h1>
                            {resultsUserBased
                                ? <span>
                                    {t.translate('nutritional-advice.sub-title')}
                                </span>
                                : null
                            }
                        </div>
                    </div>
                    <Button
                        label={t.translate('nutritional-advice.button-label')}
                        onClick={() => history.push("/account/recommendations/details")}
                    />
                </div>
            }
            body={
                <div className="flex-row flex-wrap" id="food-list">
                    {isLoading
                        ? <Loader />
                        : foodsService.renderFoods(foodList, greenColor, orangeColor)
                    }
                </div>
            }
        />
    );
}

export default FoodAdvicePreview;