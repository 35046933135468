import appImage from "assets/our_solution/tel-avec-logo.webp";
import { Loader } from "components";
import "components/DownloadApp/scss/DownloadApp.scss";
import React from "react";
import { useTranslation } from "services";

const DownloadApp: React.FC = () => {
    const t = useTranslation('pages/downloadApp');

    return (
        <div id="download-app" className="column">
            <div className="column" id="download-app-wrapper">
                <h1 className="MavenPro-bold" id="title">
                    {t.translate('title')}
                </h1>
                <img src={appImage} alt="bodycheckup-mobile" id="app-overview" />
                <div className="row" id="app-coming-wrapper">
                    <span className="MavenPro-bold">{t.translate('buildInProgress')}</span>
                    <Loader isSmall={true} />
                </div>
            </div>
        </div>
    );
}

export default DownloadApp;