import mobile_app from 'assets/order/microbiota_analysis/mobile_app.png';
import { useTranslation } from "services";

export interface MobileAppProps {
    key: number | string
}

const MobileApp: React.FC<MobileAppProps> = (props) => {
    const t = useTranslation('modals/OrderSolutionComplementary');

    return (
        <div key={props.key} className="orderSolution-section">
            <div className='flex-column'>
                <div className="orderSolution-section-title">
                    <p>5.</p>
                    <div className="flex-column-start">
                        <h2>{t.translate('microbiota_analysis.mobile_app.title')}</h2>
                    </div>
                </div>

                <p>{t.translate('microbiota_analysis.mobile_app.body')}</p>

                <ul>
                    <li>{t.translate('microbiota_analysis.mobile_app.list.directAccessAnalysis')}</li>
                    <li>{t.translate('microbiota_analysis.mobile_app.list.accessDocuments')}</li>
                    <li>{t.translate('microbiota_analysis.mobile_app.list.shareWithDoctor')}</li>
                    <li>{t.translate('microbiota_analysis.mobile_app.list.receiveAdviceOnYourDiet')}</li>
                    <li>{t.translate('microbiota_analysis.mobile_app.list.planVideoMeeting')}</li>
                    <li>{t.translate('microbiota_analysis.mobile_app.list.askEasily')}</li>
                </ul>
            </div>
            <div className='orderSolution-img-wrapper'>
                <img src={mobile_app} />
            </div>
        </div>
    )
}

export default MobileApp;