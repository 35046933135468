import { IDatabaseProductOrder } from "interfaces/Database/IDatabaseProductOrder";
import { ICustomProductOrder } from "interfaces/ICustomProductOrder";
import apiService from "./api.service";

interface getPaginatedOrdersParams {
    page: number,
    nbrOnPage: number,
    orderBy?: 'createdOn',
    orderType?: 'ASC' | 'DESC'
}

class OrderService {
    public getOrders = async () => {
        return await apiService.makeApiCall(`/api/getUserOrders`, 'get');
    }

    public getOrdersSummary = async () => {
        return await apiService.makeApiCall<{ total: number }>(`/api/productOrder/summary`, 'get');
    }

    public getPaginatedOrders = async (params: getPaginatedOrdersParams) => {
        return await apiService.makeApiCall<ICustomProductOrder[]>(`/api/productOrder/paginated?` +
            (params.page ? `page=${params.page}` : '') +
            (params.nbrOnPage ? `&quantity=${params.nbrOnPage}` : '') +
            (params.orderBy ? `&orderBy=${params.orderBy}` : '') +
            (params.orderType ? `&orderType=${params.orderType}` : ''), 'get');
    }

    public async getLastOrders(params: {
        quantity: number
    } = {
        quantity: 3
    }) {
        return await apiService.makeApiCall<ICustomProductOrder[]>(`/api/productOrder/last?quantity=${params.quantity}`, 'get');
    }

    public getProductOrderDetails = async (subscriptionPlanId: string) => {
        return await apiService.makeApiCall(`/api/productOrder/${subscriptionPlanId}/details`, 'get')
            .then((res) => res as IDatabaseProductOrder);
    }

    public async getOrderPdf(orderId: string) {
        return await apiService.makeApiCall<{
            pdf: string,
            base64: string
        }>(`/api/productOrder/${orderId}/pdf`, 'get');
    }

    public handleNewOneTimeOrder = async (props: {
        session_id?: string,
        payment_intent?: string
    }) => {
        return await apiService.makeApiCall(`/api/handleOneTimeValidation`, 'post', props);
    }

    public handleNewOneTimeAppointmentOrder = async (props: {
        session_id?: string,
        payment_intent?: string
    }) => {
        return await apiService.makeApiCall(`/api/checkout/appointment/validation`, 'post', props);
    }

    public handleNewMonthlyOrder = async (subscriptionId: string) => {
        return await apiService.makeApiCall(`/api/handleMonthlyValidation`, 'post', {
            subscription_id: subscriptionId
        });
    }
}
export default new OrderService();