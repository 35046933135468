import { CheckIcon, CloseIcon } from "components/Icons";
import { IFormDashboardAddressInput } from "interfaces/Form/IFormDashboardAddressInput";
import { IAddress } from "interfaces/User/IAddress";
import React, { CSSProperties } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

const DashboardAddressInput: React.FC<(IFormDashboardAddressInput)> = ({
    name,
    disabled = false,
    required = false,
    backgroundColor = "transparent",
    min,
    type = "text",
    value,
    setValue,
    address,
    setAddress,
    checkFunction,
    checkmark = {
        displayed: false
    },
}) => {
    const invalidDashboardInput: CSSProperties = {
        background: backgroundColor,
        WebkitAppearance: 'none',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '34px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const validDashboardInput: CSSProperties = {
        background: backgroundColor,
        WebkitAppearance: 'none',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '34px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const {
        ready,
        suggestions: { status, data },
        setValue: setInputValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            types: ['address'],
            componentRestrictions: { country: ["fr", "uk"] }
        },
        debounce: 300
    });

    const renderSuggestions = () =>
        <div
            style={{
                position: 'absolute'
            }}
        >
            {data.map((suggestion) => {
                const {
                    place_id,
                    structured_formatting: { main_text, secondary_text },
                } = suggestion;

                return (
                    <li key={place_id} onClick={handleSelect(suggestion)} className="address-suggestion">
                        <strong>{main_text}</strong> <span>{secondary_text}</span>
                    </li>
                );
            })}
        </div>

    // eslint-disable-next-line
    const handleFillingFormAddressAutocompletion = (address_array: any[]) => {
        let streetNumber;
        let route;

        let new_postal_code;
        let new_countryName;
        let new_city;

        address_array.forEach(e => {
            switch (true) {
                case e.types.includes("postal_code"):
                    new_postal_code = e.long_name;
                    break;
                case e.types.includes("country"):
                    new_countryName = e.long_name.toUpperCase();
                    break;

                case e.types.includes("locality"):
                    new_city = e.long_name;
                    break;

                case e.types.includes("route"):
                    route = e.long_name
                    break;

                case e.types.includes("street_number"):
                    streetNumber = e.long_name
                    break;

                default:
                    break;
            }
        });

        const newAddress: IAddress = {
            additionalInfos: address?.additionalInfos,
            city: new_city ?? address?.city,
            countryName: new_countryName ?? address?.countryName,
            firstname: address?.firstname,
            lastname: address?.lastname,
            postalCode: new_postal_code ?? address?.postalCode,
            streetAddress: `${streetNumber ?? ''} ${route}` ?? address?.streetAddress,
            aptNumber: address?.aptNumber,
            company: address?.company,
            siret: address?.siret
        }

        setAddress(newAddress);
    }

    // eslint-disable-next-line
    const handleSelect = ({ description }: any) =>
        () => {
            // When user selects a place, we can replace the keyword without request data from API
            // by setting the second parameter to "false"
            setInputValue(description, false);
            clearSuggestions();

            // Get latitude and longitude via utility functions
            getGeocode({ address: description })
                .then((results) => handleFillingFormAddressAutocompletion(results[0].address_components))
                .catch((err) => console.warn("Geo code error : ", err));
        };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e);
        setInputValue(e.target.value);
    }

    const checkmarkDefaultStyle: CSSProperties = {
        position: 'absolute',
        top: 0,
        right: '10px',
        marginTop: '12px'
    }

    const checkInvalid = () => {
        if (checkFunction && !checkFunction()) {
            return true;
        } else {
            return false;
        }
    }

    const shouldDisplay = (): boolean => {
        if (required && !value) return true;

        if (!value) {
            return false;
        } else {
            if (value.length === 0) {
                return false;
            } else {
                return true;
            }
        }
    }

    return (
        <div ref={ref} style={{ position: 'relative', width: "100%" }}>
            <input
                name={name}
                disabled={!ready || disabled}
                required={required}
                type={type}
                min={min}
                // defaultValue={value}
                value={address?.streetAddress}
                onChange={handleInputChange}
                style={
                    shouldDisplay() && checkInvalid() ?
                        invalidDashboardInput :
                        validDashboardInput
                }
                className={
                    shouldDisplay() && checkInvalid() ?
                        'dashboardInput-invalid' :
                        'dashboardInput'
                }
            />
            {
                (shouldDisplay() && (!disabled && checkmark.displayed)) && (
                    checkInvalid() ?
                    (
                        checkmark.invalid?.component ?
                            <checkmark.invalid.component
                                color={checkmark.invalid.color ? checkmark.invalid.color : 'var(--red)'}
                                className={checkmark.invalid.className ? checkmark.invalid.className : 'icon-check'}
                                style={checkmarkDefaultStyle} /> :
                            <CloseIcon size={16} color={`var(--red)`} style={checkmarkDefaultStyle} />
                    ) :
                    (
                        checkmark.valid?.component ?
                            <checkmark.valid.component
                                color={checkmark.valid.color ? checkmark.valid.color : 'var(--dashboard-main-color)'}
                                className={checkmark.valid.className ? checkmark.valid.className : 'icon-check'}
                                style={checkmarkDefaultStyle} /> :
                            <CheckIcon size={16} style={checkmarkDefaultStyle} />
                    )
                )
            }

            <div id="address-suggestion-container" className="column">
                {status === "OK" && <ul>{renderSuggestions()}</ul>}
            </div>
        </div>
    )
}

export default DashboardAddressInput;