import { CheckIcon, CloseIcon } from "components";
import { IFormDashboardSelect } from "interfaces/Form/IFormDashboardSelect";
import React, { CSSProperties, Fragment } from "react";

const DashboardSelect: React.FC<(IFormDashboardSelect)> = ({
    name,
    disabled = false,
    required = false,
    backgroundColor = "transparent",
    options = [],
    defaultValue = "",
    value,
    setValue,
    checkFunction,
    checkmark = {
        displayed: false
    },
    wrapperStyle,
    inputStyle
}) => {
    const invalidDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'textfield',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '12px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const validDashboardInput: CSSProperties = {
        position: 'relative',
        background: backgroundColor,
        WebkitAppearance: 'textfield',
        MozAppearance: 'textfield',
        paddingTop: '7px',
        paddingRight: '12px',
        paddingBottom: '7px',
        paddingLeft: '12px'
    }

    const checkmarkDefaultStyle: CSSProperties = {
        position: 'absolute',
        top: 0,
        right: '10px',
        marginTop: '12px'
    }

    const checkInvalid = () => {
        if (checkFunction && !checkFunction()) {
            return true;
        } else {
            return false;
        }
    }

    const shouldDisplay = (): boolean => {
        if (required && !value) return true;

        if (value) {
            if (value?.length === 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    const calculateStyle = (): CSSProperties => {
        let style: CSSProperties = {};
        
        if (shouldDisplay()) {
            if (checkInvalid()) {
                style = invalidDashboardInput;
            } else {
                style = validDashboardInput;
            }

            style.paddingRight = '34px';
        } else {
            style = Object.assign(validDashboardInput);
        }

        return style;
    }

    return (
        <Fragment>
            <div style={{ position: 'relative', width: "100%", ...wrapperStyle }}>
                <select
                    name={name}
                    disabled={disabled}
                    required={required}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={setValue}
                    className={
                        shouldDisplay() && checkInvalid() ?
                            'dashboardSelect-invalid' :
                            'dashboardSelect'
                    }
                    // style={
                    //     shouldDisplay() && checkInvalid() ?
                    //         invalidDashboardInput :
                    //         validDashboardInput
                    // }
                    style={{
                        ...calculateStyle(),
                        ...inputStyle
                    }}
                >
                    {options}
                </select>
                {
                    (shouldDisplay() && (checkmark && checkmark.displayed)) && (
                        checkInvalid() ?
                        (
                            checkmark.invalid?.component ?
                                <checkmark.invalid.component
                                    color={checkmark.invalid.color ? checkmark.invalid.color : 'var(--red)'}
                                    className={checkmark.invalid.className ? checkmark.invalid.className : 'icon-check'}
                                    style={checkmarkDefaultStyle} /> :
                                <CloseIcon
                                    size={16}
                                    color={`var(--red)`}
                                    style={checkmarkDefaultStyle} />
                        ) :
                        (
                            checkmark.valid?.component ?
                                <checkmark.valid.component
                                    color={checkmark.valid.color ? checkmark.valid.color : 'var(--dashboard-main-color)'}
                                    className={checkmark.valid.className ? checkmark.valid.className : 'icon-check'}
                                    style={checkmarkDefaultStyle} /> :
                                <CheckIcon
                                    size={16}
                                    style={checkmarkDefaultStyle} />
                        )
                    )
                }
            </div>
        </Fragment>
    )
}

export default DashboardSelect;