import { Loader } from 'components';
import HistoryActions from 'components/History/components/HistoryActions';
import HistoryAppointments from 'components/History/components/HistoryAppointments';
import "components/History/scss/History.scss";
import DashboardHeader from 'components/common/DashboardHeader/DashboardHeader';
import { IDAppointment, IDAppointmentWithNutritionist, IDHistory } from 'interfaces/Database';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'services';
import useLogService from 'services/api/log.service';
import doctorService from 'services/doctor.service';

const History: React.FC = () => {
    const t = useTranslation('history');
    const logService = useLogService();

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ listActions, setListActions ] = useState<IDHistory[]>([]);
    const [ lastAppointments, setLastAppointments ] = useState<(IDAppointment | IDAppointmentWithNutritionist)[]>([]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            logService.getHistory()
                .then((res) => setListActions(res))
                .catch(() => setListActions([])),
            doctorService.getPreviousAppointments()
                .catch(() => []),
            doctorService.getPreviousAppointmentsWithNutritionist()
                .catch(() => []),
        ])
            .then(([_, previousAppointments, previousAppointmentsNutritionist]) => {
                let tempPrev = [...previousAppointments, ...previousAppointmentsNutritionist];
                tempPrev = tempPrev.sort((a, b) => {
                    if (!a.appointmentOn && !b.appointmentOn) return 0;
                    if (!a.appointmentOn) return -1;
                    if (!b.appointmentOn) return 1;

                    return a.appointmentOn < b.appointmentOn ? 1 : -1;
                });

                setLastAppointments(tempPrev);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Fragment>
            <DashboardHeader title={t.translate("title")} />
            {isLoading
                ? <Loader />
                : <div className='flex-row align-items-stretch space-between' style={{ height: '80vh' }}>
                    <HistoryAppointments lastAppointments={lastAppointments} />
                    <HistoryActions listActions={listActions} />
                </div>
            }
        </Fragment>
    )
}

export default History;