import apiService from "./api.service";

interface IDownloadFileParams {
    filename?: string
    type?: 'pdf',
    addPre?: boolean
}

class FileService {
    public extractFileName = (resp: any) => {
        let filename = "";
        
        if (resp && resp.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(resp);
            if (matches !== null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        
        return filename;
    }

    private clearFilename(filename: string): string {
        let output = filename.normalize("NFD").replaceAll(/\p{Diacritic}/gu, "");

        output = output.toLowerCase();
        output = output.trim();
        output = output.replaceAll(' ', '_');

        return output;
    }

    /**
     * @description Download b64 input as file
     * @param data File's b64 string complete
     * @param filename Document filename, optional param with default value : 'document.pdf'
     * @param addPre Should function automatically add header "data:application/pdf;base64," or not
     */
    public downloadFile(
        data: string,
        params?: IDownloadFileParams
    ) {
        let finalName = '';
        let finalData = '';

        switch (params?.type) {
            case 'pdf':
            default:
                finalName = `${params?.filename ? params?.filename : 'document'}.pdf`;
                finalData = params?.addPre ? ("data:application/pdf;base64," + data) : data;
        }

        const a = document.createElement('a');
        a.download = this.clearFilename(finalName);
        a.href = finalData;
        a.title = this.clearFilename(finalName);
        a.click();
    }

    public saveAsFile = (data: any, filename: string = 'document.pdf') => {
        // Step 1: Create the blob object with the data you received
        // const type = 'application/data'; // modify or get it from response
        const blob = new Blob([data], { type: 'application/pdf' });
      
        // Step 2: Create Blob Object URL for that blob
        const url = URL.createObjectURL(blob);
      
        // Step 3: Trigger downloading the object using that URL
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click(); // triggering it manually
    }

    public handleDownload = (res: any) => {
        const filename = this.extractFileName(res.headers['content-disposition']);
        this.saveAsFile(res.data, filename);
    }

    public downloadResults = async (path: string) => {
        return await apiService.makeApiCall(`/api/files/results/download?path=${path}`, 'getFile');
    }
}

export default new FileService();