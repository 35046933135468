import imgSrc from 'assets/questionnaire/data-cancer-genetics-trucheck-bild-01.jpg';
import React from "react";

const UnderstandCancerCheck: React.FC = () => {
    return (
        <div className="answer-wrapper">
            <img
                src={imgSrc}
                title="How does cancer check is working"
                alt="Schema showing a vein and how tumerous cells are going through it"
                style={{
                    width: '100%',
                    height: 'auto'
                }}
            />
        </div>
    )
}

export default UnderstandCancerCheck;