import { Button, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { IDScoreFood } from "interfaces/Database";
import { IDatabaseScoreFood } from "interfaces/Database/IDatabaseScoreFood";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AiFillWarning } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import foodsService from "services/foods.service";
import "../scss/RecommendedFoods.scss";

const RecommendedFoods: React.FC<({
    handleSelectFood: (food: IDatabaseScoreFood) => void
})> = ({ handleSelectFood }) => {
    const t = useTranslation('dashboard/patient/recommendations');
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ isUserBased, setIsUserBased ] = useState<boolean>(false);

    const [ orangeColor, setOrangeColor ] = useState<number>(0);
    const [ greenColor, setGreenColor ] = useState<number>(0);
    const [ foods, setFoods ] = useState<IDScoreFood[]>([]);

    const fetchData = useCallback(() => {
        setIsLoading(true);

        foodsService.getFoods(6)
            .then((res) => {
                setIsUserBased(res.resultsUserBased);
                setOrangeColor(res.colors.orange);
                setGreenColor(res.colors.green);
                setFoods(res.foods);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Fragment>
            { !isUserBased &&
                <DefaultContainer
                    title={
                        <div className="flex-row-start">
                            <AiFillWarning color="var(--orange)" size={20} style={{ marginRight: '10px' }} />
                            <h2 className="container-title bold">{t.translate('details.recommendedFoods.unavailable.title')}</h2>
                        </div>
                    }
                    body={
                        <div>
                            <p>{t.translate('details.recommendedFoods.unavailable.first')}</p>
                            <p>{t.translate('details.recommendedFoods.unavailable.second')}</p>
                        </div>
                    }
                />
            }
            <DefaultContainer
                title={
                    <div className="flex-row full-width space-between align-items-center" id="title-wrapper">
                        <h1 className="container-title bold">
                            {t.translate('details.recommendedFoods.title')}
                        </h1>
                        <Button label={t.translate('details.recommendedFoods.search')} onClick={() => history.push('/account/foods')} />
                    </div>
                }
                body={
                    <Fragment>
                        <div className="column" id="recommended-foods-header">
                            <span className="MavenPro-black text-uppercase">
                                {t.translate('details.recommendedFoods.subTitle')}
                            </span>
                            <p id="header-description">
                                {t.translate('details.recommendedFoods.description')}
                            </p>
                        </div>

                        <div id="foods-wrapper">
                            {isLoading
                                ? <Loader />
                                : foodsService.renderFoods(foods, greenColor, orangeColor, (food) => handleSelectFood(food))
                            }
                        </div>
                    </Fragment>
                }
            />
        </Fragment>
    );
}

export default RecommendedFoods;