import { Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IDCompanyAdministrator } from "interfaces/Database";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AiFillBackward, AiFillCaretLeft, AiFillCaretRight, AiFillForward, AiOutlinePlus } from 'react-icons/ai';
import { TiArrowRight } from "react-icons/ti";
import { useTranslation } from "services";
import companyService from "services/company.service";
import CompanyAddContact from "../modals/CompanyAddContact";
import CompanyEditContact from "../modals/CompanyEditContact";

const CompanyDetailContacts: React.FC = () => {
    const t = useTranslation('dashboard/company/profile');
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();
    const [ showEditModal, setShowEditModal ] = useState<boolean>(false);
    const [ showAddModal, setShowAddModal ] = useState<boolean>(false);
    
    const [ sumNbrLeft, setSumNbrLeft ] = useState<number>(0);
    const nbrOnPage = 5;
    const [ page, setPage ] = useState<number>(1);

    const [ contactList, setContactList ] = useState<IDCompanyAdministrator[]>([]);
    const [ choosedContact, setChoosedContact ] = useState<IDCompanyAdministrator>();

    const toggleShowEdit = () => setShowEditModal(!showEditModal);
    const toggleShowAdd = () => setShowAddModal(!showAddModal);

    const fetchData = useCallback(async (withSummary=false) => {
        setIsLoading(true);

        if (withSummary) {
            await companyService.getCompanyContactsSummary()
                .then((res) => setSumNbrLeft(res.total%nbrOnPage))
                .catch((err) => setError(err.message));
        }

        await companyService.getCompanyContactsPaginated({ page: page, nbrOnPage: nbrOnPage })
            .then((res) => setContactList(res))
            .catch((err) => setError(err.message));

        setIsLoading(false);
    }, [ page, nbrOnPage ]);

    useEffect(() => {
        fetchData(true);
    }, [fetchData]);

    const toFirstPage = () => {
        setPage(1);
    }

    const prevPage = () => {
        if (page > 1) {
            setPage(page-1);
        }
    }

    const nextPage = () => {
        if (page < (sumNbrLeft !== 0 ? (sumNbrLeft) : (sumNbrLeft)+1)) {
            setPage(page+1);
        }
    }

    const toLastPage = () => {
        setPage((sumNbrLeft !== 0 ? (sumNbrLeft) : (sumNbrLeft)+1));
    }

    const renderContactList = () =>
        contactList.map((contact, index) =>
            <tr key={index} className='full-width data-row accountOrder-wrapper-table-body-row'>
                <td><p className={`MavenPro-bold ${contact.preferredContact && 'text-gradient'}`} style={{ width: 'max-content' }}>{contact.lastname} {contact.firstname}</p></td>
                <td><p className="MavenPro">{contact.email}</p></td>
                <td><p className={`MavenPro ${contact?.phones?.length === 0 ? 'no-data' : ''}`}>{contact?.phones && contact?.phones?.length > 0 ? contact.phones[contact.phones.length-1].number : t.translateFromFile('common', 'no-data')}</p></td>
                <td><p className={`MavenPro ${!contact?.position ? 'no-data' : ''}`}>{contact.position ?? t.translateFromFile('common', 'no-data')}</p></td>
                <td
                    className="accountOrder-wrapper-table-body-row-btn"
                    onClick={() => {
                        setChoosedContact(contact);
                        toggleShowEdit();
                    }}
                >
                    <TiArrowRight color={"var(--dashboard-main-color)"} size={30} />
                </td>
            </tr>
        )

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h2 className='bold'>{t.translate('contacts.title')}</h2>
                    <AiOutlinePlus
                        className="clickable"
                        color="var(--dashboard-main-color)"
                        size={30}
                        onClick={() => toggleShowAdd()}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    {isLoading
                        ? <Loader />
                        : error
                            ? <ErrorMessage error={error} />
                            : contactList.length > 0
                                ? <div className='table flex-column accountOrder-wrapper'>
                                    <table id='data-table' className='full-width accountOrder-wrapper-table'>
                                        <thead className='accountOrder-wrapper-table-header'>
                                            <tr>
                                                <th className='data-titre titre-colonne text-align-center'>{t.translate('contacts.list.name')}</th>
                                                <th className='data-titre titre-colonne text-align-center'>{t.translate('contacts.list.email')}</th>
                                                <th className='data-titre titre-colonne text-align-center'>{t.translate('contacts.list.phone')}</th>
                                                <th className='data-titre titre-colonne text-align-center'>{t.translate('contacts.list.position')}</th>
                                                <th className='data-titre titre-colonne text-align-center'></th>
                                            </tr>
                                        </thead>
                                        <tbody className='accountOrder-wrapper-table-body'>
                                            {renderContactList()}
                                        </tbody>
                                    </table>
                                    {/*  PAGINATION  */}
                                    <div className='flex-row'>
                                        <button disabled={page === 1} className="dashboardBtn" type='button' onClick={() => toFirstPage()}><AiFillBackward /></button>
                                        <button disabled={page === 1} className="dashboardBtn" type='button' onClick={() => prevPage()}><AiFillCaretLeft /></button>
                                        <p>{page}/{sumNbrLeft !== 0 ? sumNbrLeft : sumNbrLeft+1}</p>
                                        <button disabled={page - (sumNbrLeft !== 0 ? sumNbrLeft : sumNbrLeft+1) === 0} className="dashboardBtn" type='button' onClick={() => nextPage()}><AiFillCaretRight /></button>
                                        <button disabled={page - (sumNbrLeft !== 0 ? sumNbrLeft : sumNbrLeft+1) === 0} className="dashboardBtn" type='button' onClick={() => toLastPage()}><AiFillForward /></button>
                                    </div>
                                </div>
                                : <i className="full-width text-align-center">{t.translate('contacts.none')}</i>
                    }
                    <CompanyAddContact isShow={showAddModal} toggleShow={toggleShowAdd} refreshContacts={() => fetchData(true)} />
                    <CompanyEditContact isShow={showEditModal} toggleShow={toggleShowEdit} contact={choosedContact} refreshContacts={() => fetchData(false)} />
                </Fragment>
            }
        />
    )
}

export default CompanyDetailContacts;