import { IResult } from "interfaces/IResult";
import apiService from "./api.service";

class ResultsService {
    public getTestResult = async () => {
        return await apiService.makeApiCall(`/api/results/testResults`, 'get');
    }

    public getFormattedExampleResults = async () => {
        return await apiService.makeApiCall(`/api/results/formattedExampleResults`, 'get');
    }

    public async getResultsFromKitAsPdf(analysisKitId: string | number, type: 'doctor' | 'patient' = 'patient') {
        return await apiService.makeApiCall<string>(`/api/results/fromKit/${analysisKitId}/pdf?type=${type}`, 'get');
    }

    public getFormattedResults = async (analysisKitId: string | number) => {
        return await apiService.makeApiCall<IResult[]>(`/api/results/formattedResults/${analysisKitId}`, 'get');
    }

    public retrieveResultsForKit = async (analysisKitId: string | number, forDoctor: boolean = false) => {
        return await apiService.makeApiCall(`/api/results/resultsPdf/${analysisKitId}?type=` + (forDoctor ? 'doctor' : 'patient'), 'get');
    }

    public retrieveResultsByVerificationCode = async (verificationCode: string, forDoctor: boolean = false) => {
        return await apiService.makeApiCall(`/api/results/resultsPdf/byCode/${verificationCode}?type=` + (forDoctor ? 'doctor' : 'patient'), 'get');
    }

    public async canDownloadLimsResultsPdf(analysisKitId: number) {
        return await apiService.makeApiCall<{
            downloadable: boolean,
            reason: string
        }>(`/api/results/${analysisKitId}/downloadable`, 'get');
    }
}

export default new ResultsService();