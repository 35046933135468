import { Button, RightArrowIcon } from "components";
import { Modal } from "components/common/Modal";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "services";
import { step } from "../components/PatientSubscriptionTracking";
import "../style/TrackingStepDetail.scss";

const TRACKING_STEPS: step[] = [
    {
        index: 1,
        libelle: 'steps.kit-registered',
        value: undefined
    },
    {
        index: 2,
        libelle: 'steps.kit-sent',
        value: undefined
    },
    {
        index: 3,
        libelle: 'steps.kit-under-analysis',
        value: undefined
    },
    {
        index: 4,
        libelle: 'steps.kit-analysis-finalized',
        value: undefined
    },
    {
        index: 5,
        libelle: 'steps.kit-scheduled-appointment',
        value: undefined
    },
];

const TrackingStepDetail: React.FC<({
    isShow: boolean
    toggleIsShow: () => void
    currentStep?: step
    nextStep?: step
})> = ({
    isShow,
    toggleIsShow,
    currentStep,
    nextStep
}) => {
    const t = useTranslation('components/SubscriptionTracking');

    useEffect(() => {
        if (isShow) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ isShow ]);

    const loadContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 1:
                return <div>
                    <p>{t.translate('step-detail.kit-registered')}</p>
                </div>
            case 2:
                return <div>
                    <p>{t.translate('step-detail.kit-sent')}</p>
                </div>
            case 3:
                return <div>
                    <p>{t.translate('step-detail.kit-under-analysis')}</p>
                </div>
            case 4:
                return <div>
                    <p>{t.translate('step-detail.kit-analysis-finalized')}</p>
                </div>
            case 5:
                return <div>
                    <p>{t.translate('step-detail.kit-scheduled-appointment')}</p>
                </div>
            default:
                return <div className="flex-column full-width">
                    <p>{t.translate('step-detail.unknown')}</p>
                </div>
        }
    }

    return (
        <Modal
            isShow={isShow}
            toggleShow={toggleIsShow}
            footer={<Button label={t.translate('close')} onClick={() => toggleIsShow()} />}
        >
            <div className="now-steps">
                <div className="step">
                    <p className="MavenPro-bold text-uppercase">{t.translate(currentStep?.libelle)}</p>
                </div>
                {(nextStep && currentStep?.value?.isDone)
                    ? <Fragment>
                        <RightArrowIcon />
                        <div className="step">
                            <p className="MavenPro-bold text-uppercase">{t.translate(nextStep?.libelle)}</p>
                        </div>
                    </Fragment>
                    : null
                }
            </div>
            <div className="all-steps">
                {TRACKING_STEPS.map((step) =>
                    <div key={`${step.index}-detail`}>
                        <h4>0{step.index}. {t.translate(step.libelle)}</h4>
                        {loadContent(step.index)}
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default TrackingStepDetail;