import { FaqQuestion } from "components/Support/components/FrequentQuestions";

const doctorQuestions: FaqQuestion[] = [
    {
        libelle: 'questions.doctor.1.ask',
        answer: 'questions.doctor.1.answer'
    },
    {
        libelle: 'questions.doctor.2.ask',
        answer: 'questions.doctor.2.answer'
    },
    {
        libelle: 'questions.doctor.3.ask',
        answer: 'questions.doctor.3.answer'
    },
    {
        libelle: 'questions.doctor.4.ask',
        answer: 'questions.doctor.4.answer'
    }
];

export default doctorQuestions;