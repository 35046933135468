import { IDAnalysisKitStatus, IDParcel } from ".";

export interface IDatabaseAnalysisKit {
    id: number;
    createdOn: Date;
    parcelles: IDParcel[];
    verificationCode: string;
    activated: boolean;
    activatedOn: Date;
    labCode?: string;
    labStatus?: number;
    generalResultsReceived: boolean;
    generalResultsReceivedOn: Date;
    microbiotaResultsReceived: boolean;
    microbiotaResultsReceivedOn: Date;
    status: IDAnalysisKitStatus;
}

export default class DatabaseAnalysisKit implements IDatabaseAnalysisKit {
    id: number;
    createdOn: Date;
    parcelles: IDParcel[];
    verificationCode: string;
    activated: boolean;
    activatedOn: Date;
    labCode?: string;
    labStatus?: number;
    generalResultsReceived: boolean;
    generalResultsReceivedOn: Date;
    microbiotaResultsReceived: boolean;
    microbiotaResultsReceivedOn: Date;
    status: IDAnalysisKitStatus;
  
    constructor(data: IDatabaseAnalysisKit) {
        this.id = data.id;
        this.createdOn = new Date(data.createdOn);
        this.parcelles = data.parcelles;
        this.verificationCode = data.verificationCode;
        this.activated = data.activated;
        this.activatedOn = new Date(data.activatedOn);
        this.labCode = data.labCode;
        this.labStatus = data.labStatus;
        this.generalResultsReceived = data.generalResultsReceived;
        this.generalResultsReceivedOn = new Date(data.generalResultsReceivedOn);
        this.microbiotaResultsReceived = data.microbiotaResultsReceived;
        this.microbiotaResultsReceivedOn = new Date(data.microbiotaResultsReceivedOn);
        this.status = data.status;
    }

    public areResultsAvailable(): boolean {
        return this.generalResultsReceived && this.microbiotaResultsReceived;
    }
}