import { Loader } from 'components';
import AllocateResume from 'components/CompanyEmployees/components/AllocateResume';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import ToolTip from 'components/common/ToolTip/ToolTip';
import { IDatabaseUser } from 'interfaces/Database/IDatabaseUser';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'services';
import companyService from 'services/company.service';
import '../scss/CompanyEmployees.scss';

const CompanyEmployees: React.FC = () => {
    const t = useTranslation('dashboard/company/home');
    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ registeredEmployees, setRegisteredEmployees ] = useState<IDatabaseUser[]>([]);
    const [ notRegisteredEmployees, setNotRegisteredEmployees ] = useState<IDatabaseUser[]>([]);

    const fetchData = useCallback(() => {
        setIsLoading(true);

        companyService.getEmployeesData()
            .then((res) => {
                setRegisteredEmployees(res.registeredEmployees);
                setNotRegisteredEmployees(res.notRegisteredEmployees);
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <DefaultContainer
            title={
                <div className='flex-row-start'>
                    <ToolTip>
                        <p>
                            {t.translateReplaceValues(
                                'employees.tooltip.content',
                                [{
                                    tag: "{{all}}",
                                    value: (registeredEmployees.length ?? 0) + (notRegisteredEmployees.length ?? 0)
                                }, {
                                    tag: "{{registered}}",
                                    value: registeredEmployees.length ?? 0
                                } ])
                            }
                        </p>
                    </ToolTip>
                    <h2 className='bold'>{t.translate('employees.title')}</h2>
                </div>
            }
            body={isLoading
                ? <Loader />
                : <AllocateResume
                    freeSolutions={notRegisteredEmployees.length}
                    givenSolutions={registeredEmployees.length}
                    desc={t.translate('employees.desc')}
                    btnFunc={() => history.push('/account/employees')}
                    btnText={t.translate('employees.seeAll')}
                    btnDisabled={false}
                />
            }
        />
    )
}

export default CompanyEmployees;