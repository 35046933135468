import CompanyInfo, { IDatabaseCompanyInfo } from "./IDatabaseCompanyInfo";
import IDoctorInfo from "./IDatabaseDoctorInfo";
import { IDatabasePatientInfo } from "./IDatabasePatientInfo";
import { IDatabasePhone } from "./IDatabasePhone";
import DatabaseUserType, { IDatabaseUserType } from "./IDatabaseUserType";

export interface IDatabaseUser {
    id: number,
    email: string,
    type?: IDatabaseUserType,
    phone?: IDatabasePhone,
    phoneIsVerified?: boolean,
    picture?: string,
    doctor?: IDatabaseUser,
    nutritionist?: IDatabaseUser,
    doctorInfo?: IDoctorInfo,
    staffInfo?: any,
    company?: IDatabaseUser,
    companyInfo?: any,
    patientInfo?: IDatabasePatientInfo,
    createdOn?: Date,
    firstLogin: boolean
}

export default class User implements IDatabaseUser {
    id: number;
    email: string;
    type?: DatabaseUserType;
    phone?: IDatabasePhone;
    phoneIsVerified?: boolean;
    picture?: string;
    doctor?: IDatabaseUser;
    nutritionist?: IDatabaseUser;
    doctorInfo?: IDoctorInfo;
    staffInfo?: any;
    company?: IDatabaseUser;
    companyInfo?: IDatabaseCompanyInfo;
    patientInfo?: IDatabasePatientInfo;
    createdOn?: Date;
    firstLogin: boolean;

    constructor(user: IDatabaseUser) {
        this.id = user.id;
        this.email = user.email;
        this.type = new DatabaseUserType(user.type?.id, user.type?.name);
        this.phone = user.phone;
        this.phoneIsVerified = user.phoneIsVerified;
        this.picture = user.picture;
        this.doctor = user.doctor;
        this.nutritionist = user.nutritionist;
        this.doctorInfo = user.doctorInfo ? new IDoctorInfo(user.doctorInfo) : undefined;
        this.staffInfo = user.staffInfo;
        this.company = user.company;
        this.companyInfo = user.companyInfo ? new CompanyInfo(user.companyInfo) : undefined;
        this.patientInfo = user.patientInfo;
        this.createdOn = user.createdOn;
        this.firstLogin = user.firstLogin;
    }

    public formatName() {
        switch (this.type?.name) {
            case 'Company':
                return this.companyInfo?.name;
            case 'Doctor':
                return this?.doctorInfo?.firstname + ' ' + this?.doctorInfo?.lastname?.charAt(0) + '.';
            case 'Staff':
                return this?.staffInfo?.firstname + ' ' + this?.staffInfo?.lastname?.charAt(0) + '.';
            case 'Patient':
            case 'Standalone':
            default:
                return this?.patientInfo?.firstname + ' ' + this?.patientInfo?.lastname?.charAt(0) + '.';
        }
    }

    public getFirstName() {
        switch (this.type?.name) {
            case 'Company':
                return this.companyInfo?.name;
            case 'Doctor':
                return this.doctorInfo?.firstname;
            case 'Staff':
                return this.staffInfo?.firstname;
            case 'Patient':
            case 'Standalone':
            default:
                return this.patientInfo?.firstname;
        }
    }

    public getLastName() {
        switch (this.type?.name) {
            case 'Company':
                return '';
            case 'Doctor':
                return this.doctorInfo?.lastname;
            case 'Staff':
                return this.staffInfo?.lastname;
            case 'Patient':
            case 'Standalone':
            default:
                return this.patientInfo?.lastname;
        }
    }

    public formatPatientName(options?: { upperCase?: 'all' | 'firstname' | 'lastname' }) {
        return (options?.upperCase === 'all' || options?.upperCase === 'lastname' ? this.patientInfo?.lastname?.toUpperCase() : this.patientInfo?.lastname) + ' ' +
        (options?.upperCase === 'all' || options?.upperCase === 'firstname' ? this.patientInfo?.firstname?.toUpperCase() : this.patientInfo?.firstname);
    }

    public formatDoctorName() {
        return `Dr. ${this.doctorInfo?.lastname} ${this.doctorInfo?.firstname}`;
    }

    public isPatient() {
        return this.type?.isPatient();
    }

    public isStandalone() {
        return this.type?.isStandalone();
    }

    public isDoctor() {
        return this.type?.isDoctor();
    }

    public isNutritionist() {
        return this.type?.isDoctor() && this.doctorInfo?.doctorIsNutritionist();
    }

    public isPractitionerOf(patient?: User | IDatabaseUser) {
        return (patient?.doctor?.id === this.id) ||
            (patient?.nutritionist?.id === this.id);
    }

    public isDoctorOf(patient?: User | IDatabaseUser) {
        return patient?.doctor?.id === this.id;
    }

    public isNutritionistOf(patient?: User | IDatabaseUser) {
        return patient?.nutritionist?.id === this.id;
    }
}