import { Input, Loader } from "components";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React from "react";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import stringService from "services/string.service";
import translationService from "services/translation.service";

const SearchRecipient: React.FC<({
    isLoading: boolean
    recipients: IDatabaseUser[]
    userSearch?: string
    setUserSearch: React.Dispatch<string>
    setUserSelected: React.Dispatch<IDatabaseUser>
})> = ({
    isLoading,
    recipients,
    userSearch,
    setUserSearch,
    setUserSelected
}) => {
    const t = useTranslation('common');

    const filter = (search?: string) => {
        if (search) {
            try {
                return recipients.filter((user) => {
                    const type = user?.type?.name;
                    let userInfo = undefined;
                    let userName = undefined;
    
                    switch (type) {
                        case "Patient":
                            userInfo = user.patientInfo
                            break;
                        case "Doctor":
                            userInfo = user.doctorInfo
                            break;
                        case "Staff":
                            userInfo = user.staffInfo
                            break;
                        case "Company":
                            userInfo = user.companyInfo
                            break;
                        default:
                            userInfo = user.patientInfo
                            break;
                    }
    
                    try {
                        userName = userInfo.length === 0 ? ''.toLowerCase().includes(search) : userInfo.firstname.toLowerCase().includes(search) || userInfo.lastname.toLowerCase().includes(search)
                    } catch (err) {
                        userName = stringService.capitalize(user.email.split('@')[0]);
                    }
    
                    return userName;
                });
            } catch (err) {
                return [];
            }
        } else {
            return recipients;
        }
    }

    return (
        <div id="search-patient">
            <Input
                name="search-patient"
                value={userSearch}
                setValue={(e) => setUserSearch(e.target.value.toLowerCase())}
                placeholder={translationService.translate('messaging.placeholder.searchUser')}
                wrapperStyle={{
                    marginBottom: '10px'
                }}
            />

            <div id="wrapper-list-patient">
                { isLoading
                    ? <div className="text-center"><Loader /></div>
                    : userSearch && filter(userSearch).length === 0
                        ? <p id="no-patient">{translationService.translate('messaging.modalNewMessage.noOne')}</p>
                        : <table id="table-new-message">
                                <thead>
                                    <tr className="table-head fullwidth">
                                        <th>{translationService.translate('messaging.modalNewMessage.infoUser')}</th>
                                        <th>{translationService.translate('messaging.modalNewMessage.type')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { !recipients
                                    ? <Loader />
                                    : filter(userSearch).map((user, i: number) => {
                                        switch (user?.type?.name) {
                                            case 'Patient':
                                            case 'Standalone':
                                                return (
                                                    <tr key={i} className="data-table" onClick={() => setUserSelected(user)}>
                                                        <td className={`bold ${!user.patientInfo ? 'no-data' : ''}`}>
                                                            {user.patientInfo
                                                                ? stringService.capitalize(user?.patientInfo?.lastname) + ' ' + stringService.capitalize(user?.patientInfo?.firstname)
                                                                : t.translate('no-data')
                                                            }
                                                        </td>
                                                        <td>{user?.type?.name}</td>
                                                    </tr>
                                                )
                                            case 'Doctor':
                                                return (
                                                    <tr key={i} className="data-table" onClick={() => setUserSelected(user)}>
                                                        <td className="bold">{doctorService.formatDoctorName(user?.doctorInfo)}</td>
                                                        <td>{user?.type?.name}</td>
                                                    </tr>
                                                )
                                            case 'Company':
                                                return (
                                                    <tr key={i} className="data-table" onClick={() => setUserSelected(user)}>
                                                        <td className={`bold ${!user?.companyInfo?.name ? 'no-data' : ''}`}>{user.companyInfo ? user.companyInfo.name : t.translate('no-data')}</td>
                                                        <td>{user?.type?.name}</td>
                                                    </tr>
                                                )
                                            case 'Staff':
                                                return (
                                                    <tr key={i} className="data-table" onClick={() => setUserSelected(user)}>
                                                        <td className={`bold ${!user?.staffInfo ? 'no-data' : ''}`}>{user.staffInfo ? stringService.capitalize(user?.staffInfo?.lastname) + ' ' + stringService.capitalize(user?.staffInfo?.firstname) : t.translate('no-data')}</td>
                                                        <td>{user?.type?.name}</td>
                                                    </tr>
                                                )
                                            default:
                                                return (
                                                    <tr key={i} className="data-table" onClick={() => setUserSelected(user)}>
                                                        <td className="bold">{stringService.capitalize(user.email.split('@')[0])}</td>
                                                        <td>{user?.type?.name ?? t.translate('no-data')}</td>
                                                    </tr>
                                                )
                                        }
                                    })
                                }
                                </tbody>
                            </table>
                }
            </div>
        </div>
    )
}

export default SearchRecipient;