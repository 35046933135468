import { EditIcon, Loader } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "services";
import EditStandaloneSpecialInfo from "../modals/EditStandaloneSpecialInfo";

const StandaloneSpecialInformations: React.FC = () => {
    const t = useTranslation('profile/patient/mySpecialInformation');
    const { user, refreshUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const toggleShowEdit = () => setShowModal(!showModal);

    const refreshData = async () => {
        setIsLoading(true);

        refreshUser()
            .then(() => setShowModal(false))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="bold">{t.translate("title")}</h1>
                    <EditIcon
                        className="clickable"
                        onClick={toggleShowEdit}
                    />
                </Fragment>
            }
            body={
                <Fragment>
                    {isLoading
                        ? <Loader />
                        : user?.patientInfo?.specialInformation
                            ? <p id="special-information-placeholder">
                                {user?.patientInfo?.specialInformation}
                            </p>
                            : <p className="no-data">{t.translate("placeholder")}</p>
                    }
                    <EditStandaloneSpecialInfo
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        handleSuccess={() => refreshData()}
                    />
                </Fragment>
            }
        />
    );
}

export default StandaloneSpecialInformations;