import { IDatabaseGeneralComment } from "interfaces/Database/IDatabaseGeneralComment";
import apiService from "./api.service";

class GeneralCommentService {
    private ROOT_ROUTE = '/api/generalComment';

    public async getSelf() {
        return await apiService.makeApiCall<IDatabaseGeneralComment>(this.ROOT_ROUTE+'/self', 'get');
    }

    public async getGeneralComment(generalCommentId: number) {
        return await apiService.makeApiCall<string>(this.ROOT_ROUTE+`/${generalCommentId}`, 'get');
    }

    public async getGeneralCommentPDF(generalCommentId: number) {
        return await apiService.makeApiCall<string>(this.ROOT_ROUTE+`/${generalCommentId}/pdf`, 'get');
    }

    public async createOrUpdate(data: {
        appointmentId: number,
        comment: string
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/createOrUpdate', 'post', data);
    }
}

export default new GeneralCommentService();