import React from "react";
import "../scss/SampleVideo.scss";
import ReactPlayer from 'react-player';
import translationService from "services/translation.service";

const SampleVideo: React.FC = () => {

    return (
        <div id="sample-video" className="column">
            <div id="title-wrapper" className="column">
                <h1 className="text-gradient font-title" id="title">
                    {translationService.translate("how-it-works.main-video.title")}
                </h1>
                <span id="sub-title" className="MavenPro-black">
                    {translationService.translate("how-it-works.main-video.sub-title")}
                </span>
            </div>
            <div id="video-wrapper">
                <ReactPlayer
                    url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </div>
        </div>
    );
}

export default SampleVideo;