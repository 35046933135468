import { Checkbox } from "components";
import { IDay } from "interfaces/IDay";
import { IDayTime } from "interfaces/IDayTime";
import { IIntakeSchedule } from "interfaces/IIntakeSchedule";
import React from "react";

const IntakeCalendar: React.FC<({
    index: number;
    pre?: string;
    intakeSchedule: IIntakeSchedule;
    setSchedule: (intakeSchedule: IIntakeSchedule) => void;
    labelledBy: string;
})> = ({
    index,
    pre,
    intakeSchedule,
    setSchedule,
    labelledBy
}) => {
    const updateOne = (day: IDay, time: IDayTime) => {
        const newSchedule: IIntakeSchedule = {
            ...intakeSchedule,
            [day]: {
                ...intakeSchedule[day],
                [time]: !intakeSchedule[day][time]
            }
        }

        setSchedule(newSchedule);
    }

    const updateFullDay = (day: IDay) => {
        const checked = [
            intakeSchedule[day].morning,
            intakeSchedule[day].evening,
            intakeSchedule[day].noon,
        ];

        const valids = checked.filter((e) => e);
        const invalids = checked.filter((e) => !e);

        let newVal;
        if (valids.length === checked.length) {
            newVal = false;
        } else if (invalids.length === checked.length) {
            newVal = true;
        } else {
            newVal = valids.length < invalids.length;
        }

        const dailySchedule = {
            morning: newVal,
            noon: newVal,
            evening: newVal
        }

        const newSchedule: IIntakeSchedule = {
            ...intakeSchedule,
            [day]: dailySchedule
        }

        setSchedule(newSchedule);
    }

    const updateFullTime = (time: IDayTime) => {
        const checked = [
            intakeSchedule.monday[time],
            intakeSchedule.tuesday[time],
            intakeSchedule.wednesday[time],
            intakeSchedule.thursday[time],
            intakeSchedule.friday[time],
            intakeSchedule.saturday[time],
            intakeSchedule.sunday[time],
        ];

        const valids = checked.filter((e) => e);
        const invalids = checked.filter((e) => !e);

        let newVal;
        if (valids.length === checked.length) {
            newVal = false;
        } else if (invalids.length === checked.length) {
            newVal = true;
        } else {
            newVal = valids.length < invalids.length;
        }

        const newSchedule: IIntakeSchedule = {
            monday: {
                ...intakeSchedule.monday,
                [time]: newVal
            },
            tuesday: {
                ...intakeSchedule.tuesday,
                [time]: newVal
            },
            wednesday: {
                ...intakeSchedule.wednesday,
                [time]: newVal
            },
            thursday: {
                ...intakeSchedule.thursday,
                [time]: newVal
            },
            friday: {
                ...intakeSchedule.friday,
                [time]: newVal
            },
            saturday: {
                ...intakeSchedule.saturday,
                [time]: newVal
            },
            sunday: {
                ...intakeSchedule.sunday,
                [time]: newVal
            },
        }

        setSchedule(newSchedule);
    }

    return (
        <table aria-labelledby={labelledBy} tabIndex={pre === 'med' ? index : 100000+index} >
            <thead>
                <tr>
                    <th></th>
                    <th className="clickable" onClick={() => updateFullDay('monday')}>Lundi</th>
                    <th className="clickable" onClick={() => updateFullDay('tuesday')}>Mardi</th>
                    <th className="clickable" onClick={() => updateFullDay('wednesday')}>Mercredi</th>
                    <th className="clickable" onClick={() => updateFullDay('thursday')}>Jeudi</th>
                    <th className="clickable" onClick={() => updateFullDay('friday')}>Vendredi</th>
                    <th className="clickable" onClick={() => updateFullDay('saturday')}>Samedi</th>
                    <th className="clickable" onClick={() => updateFullDay('sunday')}>Dimanche</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th className="clickable" onClick={() => updateFullTime('morning')}>Matin</th>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-mon-mor`}
                            isChecked={intakeSchedule.monday.morning}
                            setChecked={() => updateOne('monday', 'morning')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-tue-mor`}
                            isChecked={intakeSchedule.tuesday.morning}
                            setChecked={() => updateOne('tuesday', 'morning')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-wed-mor`}
                            isChecked={intakeSchedule.wednesday.morning}
                            setChecked={() => updateOne('wednesday', 'morning')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-thu-mor`}
                            isChecked={intakeSchedule.thursday.morning}
                            setChecked={() => updateOne('thursday', 'morning')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-fri-mor`}
                            isChecked={intakeSchedule.friday.morning}
                            setChecked={() => updateOne('friday', 'morning')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-sat-mor`}
                            isChecked={intakeSchedule.saturday.morning}
                            setChecked={() => updateOne('saturday', 'morning')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-sun-mor`}
                            isChecked={intakeSchedule.sunday.morning}
                            setChecked={() => updateOne('sunday', 'morning')}
                        />
                    </td>
                </tr>
                <tr>
                    <th className="clickable" onClick={() => updateFullTime('noon')}>Midi</th>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-mon-noo`}
                            isChecked={intakeSchedule.monday.noon}
                            setChecked={() => updateOne('monday', 'noon')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-tue-noo`}
                            isChecked={intakeSchedule.tuesday.noon}
                            setChecked={() => updateOne('tuesday', 'noon')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-wed-noo`}
                            isChecked={intakeSchedule.wednesday.noon}
                            setChecked={() => updateOne('wednesday', 'noon')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-thu-noo`}
                            isChecked={intakeSchedule.thursday.noon}
                            setChecked={() => updateOne('thursday', 'noon')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-fri-noo`}
                            isChecked={intakeSchedule.friday.noon}
                            setChecked={() => updateOne('friday', 'noon')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-sat-noo`}
                            isChecked={intakeSchedule.saturday.noon}
                            setChecked={() => updateOne('saturday', 'noon')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-sun-noo`}
                            isChecked={intakeSchedule.sunday.noon}
                            setChecked={() => updateOne('sunday', 'noon')}
                        />
                    </td>
                </tr>
                <tr>
                    <th className="clickable" onClick={() => updateFullTime('evening')}>Soir</th>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-mon-eve`}
                            isChecked={intakeSchedule.monday.evening}
                            setChecked={() => updateOne('monday', 'evening')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-tue-eve`}
                            isChecked={intakeSchedule.tuesday.evening}
                            setChecked={() => updateOne('tuesday', 'evening')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-wed-eve`}
                            isChecked={intakeSchedule.wednesday.evening}
                            setChecked={() => updateOne('wednesday', 'evening')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-thu-eve`}
                            isChecked={intakeSchedule.thursday.evening}
                            setChecked={() => updateOne('thursday', 'evening')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-fri-eve`}
                            isChecked={intakeSchedule.friday.evening}
                            setChecked={() => updateOne('friday', 'evening')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-sat-eve`}
                            isChecked={intakeSchedule.saturday.evening}
                            setChecked={() => updateOne('saturday', 'evening')}
                        />
                    </td>
                    <td>
                        <Checkbox
                            name={`${pre ? `${pre}-` : ''}${index}-sun-eve`}
                            isChecked={intakeSchedule.sunday.evening}
                            setChecked={() => updateOne('sunday', 'evening')}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default IntakeCalendar;