import { IDSurvey, IDSurveyAnswer, IDSurveyQuestion } from "interfaces/Database";
import apiService from "./api.service";

class surveyService {
    async getOrCreateSurvey() {
        return await apiService.makeApiCall('/api/user/survey/getOrCreate', 'get');
    }

    async getSurvey() {
        return await apiService.makeApiCall<IDSurvey>(`/api/survey`, 'get');
    }

    async getSurveyQuestions() {
        return await apiService.makeApiCall<IDSurveyQuestion[]>(`/api/survey/questions`, 'get');
    }

    public updateSurvey = async (information: {
        question: string,
        answers: (string|number)[],
        step: number,
        type: string
    }) => {
        return await apiService.makeApiCall(`/api/createSurveyAnswer`, 'post', JSON.stringify(information));
    };

    public updateSurveyUserInformation = async (information: {
        question: string,
        answers: (string|number)[],
        step: number
    }) => {
        return await apiService.makeApiCall(`/api/updateUserSurvey`, 'post', JSON.stringify(information));
    };


    public getAnswerFromQuestion = async (codeQuestion: string|number) => {
        return await apiService.makeApiCall(`/api/getSurveyAnswerFromQuestion`, 'post', JSON.stringify({ codeQuestion }));
    };

    public isSurveyCompleted = async () => {
        return await apiService.makeApiCall(`/api/getSurveyFromUser`, 'get').then((res) => res.isComplete);
    };

    public getSurveyOverview = async () => {
        return await apiService.makeApiCall<IDSurvey>(`/api/getTotalSurveyAnswers`, 'get');
    };

    public async getQuestionUserAnswers(questionCode: string) {
        return await apiService.makeApiCall<IDSurveyAnswer[]>(`/api/survey/question/${questionCode.toLowerCase()}/answers`, 'get');
    }
}
export default new surveyService();