import { LeftArrowIcon, RightArrowIcon } from "components";
import { IDUser } from "interfaces/Database";
import React, { Fragment, useState } from "react";
import { useTranslation } from "services";
import dateService from "services/date.service";
import translationService from "services/translation.service";
import '../scss/PatientBaseInformations.scss';

const PatientBaseInformations: React.FC<({ patient: IDUser })> = ({ patient }) => {
    const t = useTranslation('doctorPatients');
    const [ baseInformationsOpened, setBaseInformationsOpened ] = useState<boolean>(true);

    const toggleBaseInformations = () => {
        setBaseInformationsOpened(!baseInformationsOpened);
    }

    const date = patient.patientInfo?.birthDate;

    return (
        <Fragment>
            {baseInformationsOpened
                ? <div className="baseInformations-wrapper">
                    <RightArrowIcon
                        onClick={toggleBaseInformations}
                        style={{
                            padding: '10px'
                        }}
                    />
                    <p>
                        {patient?.patientInfo?.lastname}<br/>{patient?.patientInfo?.firstname}
                    </p>
                    <hr />
                    <h4>{t.translate('patient.gender')}</h4>
                    <p>{patient?.patientInfo?.gender?.name ? translationService.translateGender(patient.patientInfo?.gender?.name) : t.translateFromFile('common', 'no-data')}</p>
                    <hr />
                    <h4>{t.translate('patient.age')}</h4>
                    <p>{date && dateService.calculateAge(date) ? `${dateService.calculateAge(date)} ans` : t.translateFromFile('common', 'no-data')}</p>
                    <hr />
                    <h4>{t.translate('patient.height')}</h4>
                    <p>{patient?.patientInfo?.size ? `${patient?.patientInfo?.size} cm` : t.translateFromFile('common', 'no-data')}</p>
                    <hr />
                    <h4>{t.translate('patient.weight')}</h4>
                    <p>{patient?.patientInfo?.weight ? `${patient?.patientInfo?.weight} kg` : t.translateFromFile('common', 'no-data')}</p>
                    <hr />
                </div>
                : <div className="baseInformations-open-button">
                    <LeftArrowIcon
                        onClick={toggleBaseInformations}
                    />
                </div>
            }

        </Fragment>
    )
}

export default PatientBaseInformations;