import { Button, TextArea } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { UserContext } from "context/User/UserContext";
import React, { Fragment, useContext, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import "../scss/SpecialInformation.scss";

const SpecialInformation: React.FC = () => {
    const t = useTranslation('profile/doctor/mySpecialInformation');
    const { user, refreshUser } = useContext(UserContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const [ information, setInformation ] = useState<string | undefined>(user?.doctorInfo?.specialInformation);

    const success = () => {
        if (information) {
            setIsLoading(true);

            doctorService.updateInformations({
                specialInformation: information
            })
                .then(() => {
                    refreshUser();
                    toast.success(t.translate('success'));
                })
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        }
    }

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate("title")}</h1>}
            body={
                <Fragment>
                    <TextArea
                        name="specialInformation"
                        value={information}
                        setValue={(e) => setInformation(e.currentTarget.value)}
                    />
                    <ErrorMessage error={error} />
                    <Button
                        label={t.translate('save')}
                        onClick={() => success()}
                        disabled={
                            information === "" ||
                            information === user?.doctorInfo?.specialInformation ||
                            isLoading
                        }
                        displayLoader={isLoading}
                    />
                </Fragment>
            }
        />
    );
}

export default SpecialInformation;