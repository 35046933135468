import { Button, Input, Label } from "components";
import { Modal } from 'components/common/Modal';
import { IDUser } from "interfaces/Database";
import moment from 'moment';
import 'moment-timezone';
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import patientService from "services/patient.service";
import verifierService from "services/verifier.service";

const ModalEditMedicalInfo: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void,
    currentUser?: IDUser
})> = ({
    showModal,
    handleClose,
    handleSuccess,
    currentUser
}) => {
    const t = useTranslation('profile/patient/myHealthInformation');

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ size, setSize ] = useState<number | undefined>(currentUser?.patientInfo?.size);
    const [ waistSize, setWaistSize ] = useState<number | undefined>(currentUser?.patientInfo?.waistSize);
    const [ weight, setWeight ] = useState<number | undefined>(currentUser?.patientInfo?.weight);
    const [ birthdate, setBirthdate ] = useState<Date | undefined>(currentUser?.patientInfo?.birthDate);
    const [ specialMedication, setSpecialMedication ] = useState<string | undefined>(currentUser?.patientInfo?.specialMedication);

    const closeModal = () => {
        handleClose();
    }

    const successModal = () => {
        setIsLoading(true);

        patientService.updateInformations({
            weight: weight,
            size: size,
            waistSize: waistSize,
            birthDate: moment(birthdate).format('DD-MM-YYYY') === "Invalid date"
                ? undefined
                : moment(birthdate).format('DD-MM-YYYY'),
            specialMedication: specialMedication,
        })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [ showModal ]);

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate("titleModal")}
            footer={
                <div className="flex-row full-width">
                    <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                    <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                </div>
            }
        >
            <Fragment>
                <div className="flex-row full-width flex-grow flex-gap-col flex-wrap">
                    <div className="flex-column-start" style={{ flex: '1 1 0px' }}>
                        <Label for="size" label={t.translate('size')} required />
                        <Input
                            required
                            name="size"
                            type="number"
                            value={size}
                            setValue={(e) => setSize(e.currentTarget.valueAsNumber)}
                            checkFunction={() => verifierService.checkNumber(size, { min: 0 })}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                    <div className="flex-column-start" style={{ flex: '1 1 0px' }}>
                        <Label for="waistSize" label={t.translate('waistSize')} required />
                        <Input
                            required
                            name="waistSize"
                            type="number"
                            value={waistSize}
                            setValue={(e) => setWaistSize(e.currentTarget.valueAsNumber)}
                            checkFunction={() => verifierService.checkNumber(waistSize, { min: 0 })}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <div className="flex-row full-width flex-grow flex-gap-col flex-wrap">
                    <div className="flex-column-start" style={{ flex: '1 1 0px' }}>
                        <Label for="weight" label={t.translate('weight')} required />
                        <Input
                            required
                            name="weight"
                            type="number"
                            value={weight}
                            setValue={(e) => setWeight(e.currentTarget.valueAsNumber)}
                            checkFunction={() => verifierService.checkNumber(weight, { min: 0 })}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                    <div className="flex-column-start" style={{ flex: '1 1 0px' }}>
                        <Label for="birthDate" label={t.translateFormField('birthdate.label')} required />
                        <Input
                            required
                            name="birthDate"
                            type="date"
                            value={moment(birthdate).format('YYYY-MM-DD')}
                            setValue={(e) => setBirthdate(e.currentTarget.valueAsDate ?? undefined)}
                            checkFunction={() => verifierService.checkDate(birthdate)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                </div>
                <div className="flex-row full-width flex-grow flex-gap-col flex-wrap">
                    <div className="flex-column-start" style={{ flex: '1 1 0px' }}>
                        <Label for="specialMedication" label={t.translate('specialMedication')} required />
                        <Input
                            required
                            name="specialMedication"
                            value={specialMedication}
                            setValue={(e) => setSpecialMedication(e.currentTarget.value)}
                            checkFunction={() => verifierService.checkString(specialMedication)}
                            checkmark={{
                                displayed: true
                            }}
                        />
                    </div>
                    <div className="flex-column-start" style={{ flex: '1 1 0px' }}>
                        <Label for="specialInformation" label={t.translate('specialInformation')} />
                        <Input
                            disabled
                            readOnly
                            name="specialInformation"
                            value={currentUser?.patientInfo?.specialInformation}
                        />
                    </div>
                </div>
            </Fragment>
        </Modal>
    );
}

export default ModalEditMedicalInfo;