import SubscriptionTrackingDetail from "interfaces/SubscriptionProcessing/ISubscriptionTrackingDetail";
import React, { createContext, useState } from "react";
import subscriptionPlanService from "services/subscriptionPlan.service";

type SubscriptionTrackingDetailContextProps = {
    subscriptionTrackingDetail?: SubscriptionTrackingDetail;
    setSubscriptionTrackingDetail: (newSubscriptionTrackingDetail: SubscriptionTrackingDetail) => void;
    reloadSubscriptionTrackingDetail: () => Promise<SubscriptionTrackingDetail | undefined>;
}

export const SubscriptionTrackingDetailContext = createContext<SubscriptionTrackingDetailContextProps>({
    subscriptionTrackingDetail: undefined,
    setSubscriptionTrackingDetail: () => undefined,
    reloadSubscriptionTrackingDetail: () => subscriptionPlanService.getSubscriptionProcessingDetailed()
});

export const SubscriptionTrackingDetailProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const [ subscriptionTrackingDetail, setSubscriptionTrackingDetail ] = useState<SubscriptionTrackingDetail>();

    const reloadSubscriptionTrackingDetail = async () => {
        return subscriptionPlanService.getSubscriptionProcessingDetailed()
            .then((res) => {
                setSubscriptionTrackingDetail(res);
                return res;
            })
            .catch((err) => {
                console.warn(err);
                return undefined;
            });
    }

    return (
        <SubscriptionTrackingDetailContext.Provider
            value={{
                subscriptionTrackingDetail,
                setSubscriptionTrackingDetail,
                reloadSubscriptionTrackingDetail
            }}
        >
            {children}
        </SubscriptionTrackingDetailContext.Provider>
    )
}