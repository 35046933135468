import { Button, Loader } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { Modal } from 'components/common/Modal';
import moment from 'moment';
import 'moment-timezone';
import React, { CSSProperties, Fragment, useCallback, useEffect, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { toast } from "react-toastify";
import { useTranslation } from "services";
import appointmentService from "services/appointment.service";

interface IClinikoAppointmentTime {
    appointment_start: string
}

const ModalScheduleAppointmentWithNutritionist: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void,
    appointmentId: number
})> = ({ showModal, handleClose, handleSuccess, appointmentId }) => {
    const t = useTranslation('dashboard/patient/appointment');

    const scheduleList: CSSProperties = {
        width: '80%',
        marginLeft: '2vw',
        marginRight: '2vw',
    };

    const scheduleTitle: CSSProperties = {
        fontSize: "20px",
        textAlign: "center"
    };

    const emptyScheduleList: CSSProperties = {
        width: '80%',
        alignSelf: 'center',
        alignItems: "center"
    };

    const textEmptyScheduleList: CSSProperties = {
        width: '60%',
        textAlign: "center",
        fontWeight: 600,
        color: 'var(--dashboard-main-color)',
    };

    const startDate = new Date();

    const [ error, setError ] = useState<string>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ daySelected, setDaySelected ] = useState<Date>();
    const [ appointmentTimes, setAppointmentTimes ] = useState<IClinikoAppointmentTime[]>([]);
    const [ isLoadingAppointmentTimes, setIsLoadingAppointmentTimes ] = useState<boolean>(false);
    const [ indexSelectedTime, setIndexSelectedTime ] = useState<number>(-1);
    const [ selectedTime, setSelectedTime ] = useState<string>();

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    const getAppointmentTimes = useCallback(async () => {
        setIsLoadingAppointmentTimes(true);

        appointmentService.getScheduleAvailabilitiesNutritionist(appointmentId, moment(daySelected).format("YYYY-MM-DD"))
            .then((res) => setAppointmentTimes(res))
            .catch((err) => setError(err.message))
            .finally(() => setIsLoadingAppointmentTimes(false));
    }, [daySelected]);

    useEffect(() => {
        if (daySelected) {
            getAppointmentTimes();
        }
    }, [daySelected, getAppointmentTimes]);

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'scroll';
    }

    const handleSchedule = async () => {
        setIsLoading(true);
        setError(undefined);

        const dateFormated = moment(daySelected).format("YYYY-MM-DD");
        const appointmentDatetime = moment(dateFormated + ' ' + selectedTime).format("YYYY-MM-DD HH:mm:ss");

        if (appointmentId) {
            appointmentService.bookAppointmentWNutritionist(appointmentId, appointmentDatetime)
                .then(() => {
                    setDaySelected(undefined);
                    setIndexSelectedTime(-1);
                    successModal();
                    toast.success(t.translate('modal-schedule-appointment.success'))
                })
                .catch((err) => setError(err.message))
                .finally(() => setIsLoading(false));
        }
    }

    const successModal = () => {
        handleSuccess();
        closeModal();
    }

    const handleSelectDay = (day: Date) => {
        if (daySelected) {
            setDaySelected(
                daySelected.getTime() === day.getTime() ?
                    undefined :
                    day
            );
            setIndexSelectedTime(-1);
        }
        else {
            setDaySelected(day);
        }
    }

    const isUserHasSelectedFullAppointment = () => {
        return indexSelectedTime !== -1;
    }

    const handleSelectTime = (indexTime: number, time: string) => {
        setIndexSelectedTime(
            indexTime === indexSelectedTime ?
                -1 : indexTime
        );
        setSelectedTime(moment(time).format("HH:mm:ss"));
    }

    const diplayAppointmentTimes = appointmentTimes.map((appointmentTime, index) =>
        <div key={index}
            className={index === indexSelectedTime ? 'dashboardBtn' : 'dashboardBtn-invert'}
            onClick={() => handleSelectTime(index, appointmentTime.appointment_start)}
            style={{ margin: '5px' }}
        >
            <span
                className={index === indexSelectedTime ? "MavenPro-bold" : "MavenPro"}
            >
                {moment(appointmentTime.appointment_start).format("HH:mm")}
            </span>
        </div>
    )

    return (
        <Modal
            isShow={showModal}
            toggleShow={closeModal}
            title={t.translate('modal-schedule-appointment.title')}
            footer={
                isLoading || <div className="flex-row flex-end">
                    <Button
                        label={t.translate('modal-schedule-appointment.btn-confirm')}
                        onClick={() => handleSchedule()}
                        disabled={!isUserHasSelectedFullAppointment()}
                    />
                </div>
            }
        >
            {isLoading
                ? <Loader />
                : <Fragment>
                    <Calendar
                        minDate={startDate}
                        value={daySelected}
                        onClickDay={(value: Date) => handleSelectDay(value)}
                    />
                    { daySelected
                        ? <div style={scheduleList} className="column">
                            <h3 style={scheduleTitle} className="MavenPro-bold">
                                {t.translate('modal-schedule-appointment.calendar-list-schedules')} :
                            </h3>
                            { isLoadingAppointmentTimes
                                ? <Loader isSmall={true} />
                                : <div className="flex-column">
                                    <div className="flex-row" style={{ flexWrap: 'wrap' }}>
                                        {diplayAppointmentTimes}
                                    </div>

                                    {appointmentTimes.length === 0 &&
                                        <p style={{ textAlign: 'center' }}>
                                            {t.translate('modal-schedule-appointment.no-appointment')}
                                            {' '}{t.translateDate(daySelected)}
                                        </p>
                                    }
                                    <ErrorMessage error={error} />
                                </div>
                            }
                        </div>
                        : <div style={emptyScheduleList} className="column">
                            <p style={textEmptyScheduleList}>
                                {t.translate('modal-schedule-appointment.description')}
                            </p>
                        </div>
                    }
                </Fragment>
            }
        </Modal>
    );
}

export default ModalScheduleAppointmentWithNutritionist;