import ScheduleDayBreak, { IDatabaseScheduleDayBreak, ScheduleDayBreakCreate } from "./IDatabaseScheduleDayBreak";

export const scheduleDayNames = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ] as const;

export type ScheduleDayName = typeof scheduleDayNames[number];

export interface IDatabaseScheduleDay {
    id: number;
    day: ScheduleDayName;
    start: number;
    startHour: number;
    startMinute: number;
    end: number;
    endHour: number;
    endMinute: number;
    breaks: IDatabaseScheduleDayBreak[];
}

export interface IScheduleDayCreate {
    day?: ScheduleDayName;
    start?: number;
    startHour?: number;
    startMinute?: number;
    end?: number;
    endHour?: number;
    endMinute?: number;
    breaks?: (IDatabaseScheduleDay|ScheduleDay|ScheduleDayBreakCreate)[];
}

export class ScheduleDayCreate implements IScheduleDayCreate {
    day?: ScheduleDayName | undefined;
    start?: number;
    startHour?: number;
    startMinute?: number;
    end?: number;
    endHour?: number;
    endMinute?: number;
    breaks: ScheduleDayBreakCreate[];

    constructor(data?: IScheduleDayCreate|IDatabaseScheduleDay) {
        this.day = data?.day;
        this.start = data?.start;
        this.startHour = data?.startHour;
        this.startMinute = data?.startMinute;
        this.end = data?.end;
        this.endHour = data?.endHour;
        this.endMinute = data?.endMinute;
        this.breaks = data?.breaks?.map((dayBreak) => new ScheduleDayBreakCreate(dayBreak)) ?? [];
    }

    public setDay(day: ScheduleDayName) {
        this.day = day;
        return this;
    }

    private updateStart() {
        this.start = ((this.startHour ?? 0) * 60) + (this.startMinute ?? 0);
    }

    public setStartHour(startHour: number) {
        this.startHour = startHour;
        this.updateStart();
        return this;
    }

    public setStartMinute(startMinute: number) {
        this.startMinute = startMinute;
        this.updateStart();
        return this;
    }

    private updateEnd() {
        this.end = ((this.endHour ?? 0) * 60) + (this.endMinute ?? 0);
    }

    public setEndHour(endHour: number) {
        this.endHour = endHour;
        this.updateEnd();
        return this;
    }

    public setEndMinute(endMinute: number) {
        this.endMinute = endMinute;
        this.updateEnd();
        return this;
    }

    public addBreak(data?: ScheduleDayBreakCreate) {
        this.breaks = [...this.breaks, new ScheduleDayBreakCreate(data)];
        return this;
    }

    public removeBreak(index: number) {
        this.breaks = this.breaks.filter((_, i) => index !== i);
        return this;
    }

    public renderStart(): string {
        return (this.startHour ? this.startHour.toString().padStart(2, '0') : '') + ':' + (this.startMinute ? this.startMinute.toString().padStart(2, '0') : '00');
    }

    public renderEnd(): string {
        return (this.endHour ? this.endHour.toString().padStart(2, '0') : '') + ':' + (this.endMinute ? this.endMinute.toString().padStart(2, '0') : '00');
    }
}

export default class ScheduleDay implements IDatabaseScheduleDay {
    id: number;
    day: ScheduleDayName;
    start: number;
    startHour: number;
    startMinute: number;
    end: number;
    endHour: number;
    endMinute: number;
    breaks: ScheduleDayBreak[];

    constructor(data: IDatabaseScheduleDay) {
        this.id = data.id;
        this.day = data.day;
        this.start = data.start;
        this.startHour = data.startHour;
        this.startMinute = data.startMinute;
        this.end = data.end;
        this.endHour = data.endHour;
        this.endMinute = data.endMinute;
        this.breaks = data.breaks.map((dayBreak) => new ScheduleDayBreak(dayBreak));
    }

    public renderStart(): string {
        return this.startHour.toString().padStart(2, '0') + ':' + this.startMinute.toString().padStart(2, '0');
    }

    public renderEnd(): string {
        return this.endHour.toString().padStart(2, '0') + ':' + this.endMinute.toString().padStart(2, '0');
    }
}