import { Button, Input, Label, Select } from "components";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import moment from 'moment';
import 'moment-timezone';
import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { useTranslation } from "services";
import standaloneService from "services/standalone.service";
import translationService from "services/translation.service";
import verifierService from 'services/verifier.service';

const EditStandaloneProfileInfo: React.FC<({
    showModal: boolean,
    handleClose: () => void,
    handleSuccess: () => void,
    currentUser: IDatabaseUser
})> = ({
    showModal,
    handleClose,
    handleSuccess,
    currentUser
}) => {
    const t = useTranslation('profile/doctor/myGeneralInformation');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ user, setUser ] = useState<IDatabaseUser>(currentUser);
    const [ gender, setGender ] = useState<'' | 'Male' | 'Female'>(!user?.patientInfo?.gender ? '' : user.patientInfo.gender.name === 'Male' ? 'Male' : 'Female');
    
    /**
    * Can't use stylesheet file with Modal module.
    */
    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            padding: '3vh 3vw 5vh 3vw',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "40%",
            minWidth: "700px",
            borderRadius: "5px",
            overflow: "hidden",
            boxShadow: '-5px 5px 20px 1px rgba(0, 0, 0, 0.12)'
        }
    };

    const title = {
        margin: 0,
    }

    const containerEditInfo = {
        marginTop: "5vh",
        marginBottom: '7%'
    };

    const inputContainer = {
        width: '48%'
    };

    const rowInputContainer = {
        marginBottom: '2vh'
    };

    const cross = {
        display: 'flex',
        alignItems: 'center', 
        cursor: "pointer",
        color: "var(--dashboard-main-color)"
    }

    const closeModal = () => {
        handleClose();
        document.documentElement.style.overflow = 'scroll';
    }

    const successModal = async () => {
        setIsLoading(true);
    
        standaloneService.updateInformations({
            firstName: user?.patientInfo?.firstname,
            lastName: user?.patientInfo?.lastname,
            birthDate: moment(user?.patientInfo?.birthDate).format('DD-MM-YYYY') === "Invalid date" ?
                undefined :
                moment(user?.patientInfo?.birthDate).format('DD-MM-YYYY'),
            gender: gender
        })
            .then(() => {
                handleSuccess();
                closeModal();
            })
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (showModal) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'auto';
        }
    }, [showModal]);

    return (
        <div id="modal-profil-info">
            <Modal
                isOpen={showModal}
                style={modalStyle}
                contentLabel="Example Modal"
                preventScroll={true}
                ariaHideApp={false}
            >
                <div className="column space-between" style={{ height: '100%' }}>
                    <div className="row space-between">
                        <h2 className="bold" style={title}>{t.translate("titleModal")}</h2>
                        <div onClick={closeModal} style={cross}><AiOutlineClose size={30} /></div>
                    </div>
                    <div className="column" style={containerEditInfo}>
                        <div className="row space-between" style={rowInputContainer}>
                            <div className="column" style={inputContainer}>
                                <Label for="lastname" label={t.translate('lastname')} required />
                                <Input
                                    required
                                    name="lastname"
                                    disabled={isLoading}
                                    value={user?.patientInfo?.lastname}
                                    setValue={(e) => setUser({ ...user, patientInfo: { ...user.patientInfo, lastname: e.currentTarget.value } })}
                                    checkFunction={() => verifierService.checkString(user?.patientInfo?.lastname)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                            <div className="column" style={inputContainer}>
                                <Label for="firstname" label={t.translate('firstname')} required />
                                <Input
                                    required
                                    name="firstname"
                                    disabled={isLoading}
                                    value={user?.patientInfo?.firstname}
                                    setValue={(e) => setUser({ ...user, patientInfo: { ...user.patientInfo, firstname: e.currentTarget.value } })}
                                    checkFunction={() => verifierService.checkString(user?.patientInfo?.firstname)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row space-between" style={rowInputContainer}>
                            <div className="column" style={inputContainer}>
                                <Label for="gender" label={t.translate('genre')} required />
                                <Select
                                    required
                                    name="gender"
                                    disabled={isLoading}
                                    value={gender}
                                    setValue={(e) => setGender(e.currentTarget.value as '' | 'Male' | 'Female')}
                                    checkFunction={() => verifierService.checkString(gender)}
                                    checkmark={{
                                        displayed: true
                                    }}
                                    options={
                                        <Fragment>
                                            <option value='' style={{ fontStyle: 'italic' }}></option>
                                            <option value="Male">{translationService.translateGender('male')}</option>
                                            <option value="Female">{translationService.translateGender('female')}</option>
                                        </Fragment>
                                    }
                                />
                            </div>
                            <div className="column" style={inputContainer}>
                                <Label for="birthdate" label={t.translate('birthdate')} required />
                                <Input
                                    required
                                    name="birthdate"
                                    disabled={isLoading}
                                    type="date"
                                    value={user?.patientInfo?.birthDate ? moment(new Date(user?.patientInfo?.birthDate)).format('YYYY-MM-DD') : undefined}
                                    setValue={(e) => setUser({ ...user, patientInfo: { ...user.patientInfo, birthDate: e.currentTarget.valueAsDate ? e.currentTarget.valueAsDate : undefined } })}
                                    checkFunction={() => !!(user?.patientInfo?.birthDate && verifierService.checkString(moment(new Date(user?.patientInfo?.birthDate)).format('YYYY-MM-DD')))}
                                    checkmark={{
                                        displayed: true
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row flex-end">
                        <Button label={t.translate('cancel')} onClick={() => closeModal()} disabled={isLoading} />
                        <Button label={t.translate('save')} onClick={() => successModal()} disabled={isLoading} />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default EditStandaloneProfileInfo;