import { MeetingDocument } from "components/VideoMeeting/common/VideoMeeting";
import { IDAppointment, IDAppointmentWithNutritionist } from "interfaces/Database";
import moment from 'moment';
import 'moment-timezone';
import apiService from "./api.service";
import userAuthService from "./user-auth.service";

class appointmentService {

    public getBookedAndNotBookedAppointments = async () => {
        return await apiService.makeApiCall<IDAppointment[]>(`/api/getBookedAndNotBookedAppointments`, 'get');
    }

    public getAllAppointmentsExceptNotBooked = async () => {
        return await apiService.makeApiCall<IDAppointment[]>(`/api/getAllAppointmentsExceptNotBooked`, 'get');
    }

    public getNextAppointment = async () => {
        return await apiService.makeApiCall<IDAppointment | IDAppointmentWithNutritionist>(`/api/appointments/next`, 'get');
    }

    public getNextAppointmentWithNutritionist = async () => {
        return await apiService.makeApiCall<IDAppointmentWithNutritionist>(`/api/appointments/nextWithNutritionist`, 'get');
    }

    public getClinikoAppointmentTimes = async (date: string) => {
        return await apiService.makeApiCall(`/api/getDoctorAppointmentsCliniko/18?from=${date}&to=${date}&timezone=${userAuthService.getTimeZone()}`, 'get');
    }

    public getClinikoAppointmentTimesForNutritionist = async (date: string) => {
        return await apiService.makeApiCall(`/api/cliniko/appointments/nutritionist?from=${date}&to=${date}&timezone=${userAuthService.getTimeZone()}`, 'get');
    }

    public setClinikoAppointment = async (idAppointment: string, date: string, timezone: string) => {
        return await apiService.makeApiCall(`/api/appointment/${idAppointment}/book`, 'post', JSON.stringify({ date, timezone }));
    }
    
    public setClinikoAppointmentWNutritionist = async (idAppointment: string, date: string, timezone: string) => {
        return await apiService.makeApiCall(`/api/appointmentWNutritionist/${idAppointment}/book`, 'post', JSON.stringify({ date, timezone }));
    }

    public cancelAppointment = async (idAppointment: string) => {
        return await apiService.makeApiCall(`/api/appointment/${idAppointment}`, 'delete');
    }

    public cancelAppointmentWNutritionist = async (idAppointment: number) => {
        return await apiService.makeApiCall(`/api/appointmentWNutritionist/${idAppointment}`, 'delete');
    }

    public getFormattedDate = (date?: Date) => {
        const formatter = new Intl.DateTimeFormat("fr", {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour12: false
        })

        return `${formatter.format(date)} - ${moment(date).format('HH:mm')}`;
    }

    public getAppointmentDay = (date: Date) => {
        return moment(date).format('DD');
    }

    public getAppointmentMonthName = (date: Date) => {
        const formatter = new Intl.DateTimeFormat('fr', { month: 'long' });
        return formatter.format(new Date(date));
    }

    public getAppointmentYear = (date: Date) => {
        return moment(date).format('YYYY');
    }

    public getAppointmentTime = (date: Date) => {
        return moment(date).format('HH:mm');
    }

    public doctorCancelAppointment = async (idAppointment: string | number) => {
        return await apiService.makeApiCall(`/api/doctor/appointment/${idAppointment}/cancel`, 'post');
    }

    public getCanBook = async () => {
        return await apiService.makeApiCall<{ canBook: boolean }>(`/api/appointments/canBook`, 'get');
    }

    public async connectsToMeeting(roomId: string) {
        return await apiService.makeApiCall(`/api/meeting/${roomId}/join`, 'post');
    }

    public async getMeetingDocument(roomId: string, document: MeetingDocument, type: 'patient' | 'doctor' = 'patient' ) {
        return await apiService.makeApiCall<string>(`/api/meeting/${roomId}/${document}/pdf?type=${type}`, 'get');
    }

    public async getScheduleAvailabilities(appointmentId: number, date: string) {
        return await apiService.makeApiCall(`/api/appointment/${appointmentId}/availabilities?from=${date}&to=${date}&timezone=${userAuthService.getTimeZone()}`, 'get');
    }

    public async getScheduleAvailabilitiesNutritionist(appointmentId: number, date: string) {
        return await apiService.makeApiCall(`/api/appointmentWNutritionist/${appointmentId}/availabilities?from=${date}&to=${date}&timezone=${userAuthService.getTimeZone()}`, 'get');
    }

    public async bookAppointment(appointmentId: number, date: string) {
        return await apiService.makeApiCall(`/api/appointment/${appointmentId}/book`, 'post', {
            date,
            timezone: userAuthService.getTimeZone()
        });
    }

    public async bookAppointmentWNutritionist(appointmentId: number, date: string) {
        return await apiService.makeApiCall(`/api/appointmentWNutritionist/${appointmentId}/book`, 'post', {
            date,
            timezone: userAuthService.getTimeZone()
        });
    }
}
export default new appointmentService();