import ModalEditPassword from "components/Profile/modals/ModalEditPassword";
import ModalConfirmAction from "components/common/Modals/ConfirmAction/ConfirmAction";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "services";
import userAuthService from "services/user-auth.service";

const StandaloneAdditionalActions: React.FC = () => {
    const t = useTranslation('profile/patient/profileAdditionalActions');
    const [ showModalEditPass, setShowModalEditPass ] = useState<boolean>(false);
    const [ showModalDelete, setShowModalDelete ] = useState<boolean>(false);

    const toggleEditPassword = () => setShowModalEditPass(!showModalEditPass);
    const toggleModalDelete = () => setShowModalDelete(!showModalDelete);

    const handleRequestRemovePersonnalData = async () => {
        await userAuthService.sendRequestRemovePersonalData()
            .then(() => toast.success(t.translate("toast.success"),{theme: "light"}))
            .catch(() => toast.error(t.translate("toast.fail"), {theme: "light"}));
    }

    const handleSuccessEditPassword = async () => {
        toggleEditPassword();

        toast.success(t.translate("changePasswordSuccess"), {
            theme: "light"
        });
    }

    return (
        <div id="profile-add-actions">
            <div className="flex-column action-wrapper clickable" onClick={() => setShowModalEditPass(true)}>
                <p className="text-main-color MavenPro-black">
                    {t.translate("changePassword")}
                </p>
                <span className="action-desc">
                    {t.translate("descChangePassword")}
                </span>
            </div>
            <div className="flex-column action-wrapper clickable" onClick={() => setShowModalDelete(true)}>
                <p className="text-main-color MavenPro-black">
                    {t.translate("deletePersonalInformation")}
                </p>
                <span className="action-desc">
                    {t.translate("descDeletePersonalInformation")}
                </span>
            </div>
            <ModalEditPassword
                showModal={showModalEditPass}
                handleClose={() => toggleEditPassword()}
                handleSuccess={handleSuccessEditPassword}
            />
            <ModalConfirmAction
                showModal={showModalDelete}
                handleClose={() => toggleModalDelete()}
                confirmAction={handleRequestRemovePersonnalData}
                description={t.translate('modalPersonnalData.description')}
            />
        </div>
    )
}

export default StandaloneAdditionalActions;