import { IDDietarySupplement, IDPrescription, IDPrescriptionMedication } from "interfaces/Database";
import apiService from "./api.service";

class PrescriptionService {
    private ROOT_ROUTE = "/api/prescription";

    public async getLast() {
        return await apiService.makeApiCall<IDPrescription>(this.ROOT_ROUTE+'/last', 'get');
    }

    public async getAllPrescriptions() {
        return await apiService.makeApiCall<IDPrescription[]>(this.ROOT_ROUTE+`/`, 'get');
    }

    public async getPrescriptionPDF(prescriptionId: number) {
        return await apiService.makeApiCall<string>(this.ROOT_ROUTE+`/${prescriptionId}/pdf`, 'get');
    }

    public async getDietarySupplementPDF(prescriptionId: number) {
        return await apiService.makeApiCall<string>(this.ROOT_ROUTE+`/${prescriptionId}/dietarySupplement/pdf`, 'get');
    }

    public async createOrUpdate(data: {
        appointmentId: string
        medication?: IDPrescriptionMedication[]
        dietarySupplement?: IDDietarySupplement[]
    }) {
        return await apiService.makeApiCall(this.ROOT_ROUTE+'/createOrUpdate', 'post', data);
    }
}

export default new PrescriptionService();