import apiService from "./api.service";

class cartService {

    public createCart = async (idProduct: number) => {
        return await apiService.makeApiCall(`/api/createCart`, 'post', JSON.stringify({ subscriptionPlanType: idProduct }));
    }

    public getCart = async () => {
        return await apiService.makeApiCall(`/api/getCartFromUser`, 'get');
    }

    public isEmptyCart = async () => {
        return await apiService.makeApiCall(`/api/getCartFromUser`, 'get');
    }

    public clearCart = async () => {
        return await apiService.makeApiCall(`/api/removeCartsFromUser`, 'delete');
    }

    public handleCartNextStep = async () => {
        return await apiService.makeApiCall(`/api/handleCartNextStep`, 'post');
    }


    public createOrGetStripeCustomer = async () => {
        return await apiService.makeApiCall(`/api/createOrGetStripeCustomer`, 'post');
    }

    public initStripeSubscription = async (information: any) => {
        return await apiService.makeApiCall(`/api/createSubscription`, 'post', JSON.stringify(information));
    }

    public createAddressDeliveryBilling = async (address: any) => {
        return await apiService.makeApiCall(`/api/handleAddress`, 'post', JSON.stringify(address));
    }
}
export default new cartService();