// const createImage = (url: string) => {
//   const image = new Image();
//   image.setAttribute("crossOrigin", "anonymous");
//   image.src = url;

import { Area } from "react-easy-crop/types";

//   return image;

//   // new Promise((resolve, reject) => {
//   //   const image = new Image();
//   //   image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
//   //   image.src = url;
//   //   image.addEventListener("load", () => resolve(image));
//   //   image.addEventListener("error", (error) => reject(error));
//   // });
// }

// function getRadianAngle(degreeValue: number) {
//   return (degreeValue * Math.PI) / 180;
// }

// /**
//  * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
//  * @param {File} image - Image File url
//  * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
//  * @param {number} rotation - optional rotation parameter
//  */
// export default async function getCroppedImg(imageSrc: string, pixelCrop: { x: number, y: number, width: number, height: number }, rotation = 0) {
//   const image = createImage(imageSrc);
//   const canvas = document.createElement("canvas");

//   const ctx = canvas.getContext("2d");

//   const maxSize = Math.max(image.width, image.height);
//   const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

//   // set each dimensions to double largest dimension to allow for a safe area for the
//   // image to rotate in without being clipped by canvas context
//   canvas.width = safeArea;
//   canvas.height = safeArea;

//   if (ctx) {
//     // translate canvas context to a central location on image to allow rotating around the center.
//     ctx.translate(safeArea / 2, safeArea / 2);
//     ctx.rotate(getRadianAngle(rotation));
//     ctx.translate(-safeArea / 2, -safeArea / 2);

//     // draw rotated image and store data.
//     ctx.drawImage(
//       image,
//       Math.abs(safeArea / 2 - image.width * 0.5),
//       Math.abs(safeArea / 2 - image.height * 0.5)
//     );
//     const data = ctx.getImageData(0, 0, safeArea, safeArea);

//     // set canvas width to final desired crop size - this will clear existing context
//     canvas.width = pixelCrop.width;
//     canvas.height = pixelCrop.height;

//     // paste generated rotate image with correct offsets for x,y crop values.
//     ctx.putImageData(
//       data,
//       Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
//       Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
//     );
//   }

//   // As Base64 string
//   // return canvas.toDataURL('image/jpeg');

//   // As a blob
//   // try {
//   //   const aled = canvas.toBlob();
//   //   // const aled = canvas.toBlob((file) => {
//   //   //   return file;
//   //   //   // resolve(URL.createObjectURL(file));
//   //   // }, "image/jpeg");
//   // } catch (err) {
//   //   throw new Error('Unable to generate file');
//   // }

//   return await new Promise<Blob | null>((resolve) => {
//     canvas.toBlob((file) => {
//       resolve(file);
//       // resolve(URL.createObjectURL(file));
//     }, "image/jpeg");
//   });
// }

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); 
    image.src = url;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
): Promise<Blob> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error('cant find canvas context');
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );


  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        resolve(file);
      } else {
        reject('File not generated');
      }
    }, "image/jpeg");
  });
} 
