import cardLock from 'assets/icons/card_lock.svg';
import banner from 'assets/order_box_banner.png';
import { Button, Loader, StepIndicator } from 'components';
import 'components/OrderKit/scss/OrderKit.scss';
import { MenuStatusContext } from "context/MenuStatusContext";
import { SubscriptionTrackingDetailContext } from "context/SolutionProcessingContext";
import { UserTypeContext } from "context/User/UserTypeContext";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "services";
import orderService from "services/order.service";

const Session: React.FC = () => {
    const t = useTranslation('dashboard/company/order_kit');
    const { id } = useParams<({ id: string })>();
    const { type } = useContext(UserTypeContext);
    const { reloadSubscriptionTrackingDetail } = useContext(SubscriptionTrackingDetailContext);
    const { refreshMenuStatus } = useContext(MenuStatusContext);

    const history = useHistory();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>();
    const [ success, setSuccess ] = useState<boolean>();

    useEffect(() => {
        setIsLoading(true);

        if (id) {
            orderService.handleNewOneTimeOrder({
                session_id: id
            })
                .then(() => {
                    reloadSubscriptionTrackingDetail();
                    refreshMenuStatus();
                    setSuccess(true);
                })
                .catch((err) => setError(err.message))
                .finally(() => {
                    setTimeout(() => setIsLoading(false), 4000);
                });
        }
    }, [id]);
    
    return (
        <div className="page-wrapper">
            <StepIndicator step={1} />
            <div className="order-dashboard flex-column">
                <div className="order-dashboard-banner">
                    <img src={banner} alt="A person is typing on a laptop" />
                    <h2 className="text-uppercase MavenPro-black">
                        {t.translate('title.first')}
                        <br />
                        {t.translate('title.second')}
                    </h2>
                    <hr />
                </div>
                <div className="flex-column" style={{ padding: '30px' }}>
                    {isLoading
                        ? <Fragment>
                            <div className="flex-row MavenPro-bold">
                                <img src={cardLock} width={30} height={30} alt="Card with lock" />
                                <p>{t.translate('resulting.securePayment')}</p>
                            </div>
                            <h2 className="text-uppercase MavenPro-bold order-dashboard-title">
                                {t.translate('resulting.littleMore')}
                            </h2>
                            <p style={{ textAlign: 'center' }}>
                                {t.translate('resulting.fewChecks')}
                                <br />
                                <span style={{ fontWeight: 'bold' }} >
                                    {t.translate('resulting.noLonger')}
                                </span>
                            </p>
                            <Loader loaderDescription={t.translate('resulting.loading')} />
                        </Fragment>
                        : error
                            ? <Fragment>
                                <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{t.translate('resulting.error.title')}</h2>
                                <p>{t.translate('resulting.error.msg')}</p>
                                <div className="flex-row space-evenly">
                                    <Button label={t.translate('resulting.error.contact-us')} onClick={() => history.push('/contact-us')} />
                                    <Button label={t.translate('resulting.error.retry')} onClick={() => history.push('/order')} />
                                </div>
                            </Fragment>
                            : success
                                ? (type.isCompany()
                                    ? <Fragment>
                                        <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{t.translate('resulting.success.title')}</h2>
                                        <p>{t.translate('resulting.success.company.msg')}</p>
                                        <p>{t.translateReplaceValues('resulting.success.company.msg2', [
                                                {
                                                    tag: '{assign}',
                                                    value: <span className='text-important'>{t.translate('resulting.success.company.assign')}</span>
                                                }
                                            ]
                                        )}</p>
                                        <Button label={t.translate('resulting.success.buttons.employees')} onClick={() => history.push('/account/employees')} />
                                    </Fragment>
                                    : <Fragment>
                                        <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{t.translate('resulting.success.title')}</h2>
                                        <p>{t.translate('resulting.success.default.msg')}</p>
                                        <p>{t.translateReplaceValues('resulting.success.default.msg2', [
                                                {
                                                    tag: '{start}',
                                                    value: <span className='text-important'>{t.translate('resulting.success.default.start')}</span>
                                                }
                                            ]
                                        )}</p>
                                        <div className="flex-row flex-wrap">
                                            <Button label={t.translate('resulting.success.buttons.claim')} onClick={() => history.push('/account/kit/register-your-kit')} />
                                        </div>
                                    </Fragment>)
                                : <Fragment>
                                    <h2 className="text-uppercase MavenPro-bold order-dashboard-title">{t.translate('resulting.safezone.title')}</h2>
                                    <p>{t.translate('resulting.safezone.msg')}</p>
                                    <Button label={t.translate('resulting.safezone.btn')} onClick={() => history.push('/account/home')} />
                                </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default Session;