import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import PatientSubscriptionTracking from "components/common/SubscriptionTracking/components/PatientSubscriptionTracking";
import { IDatabaseAnalysisKit } from "interfaces/Database/IDatabaseAnalysisKit";
import { ISubscriptionTrackingSteps } from "interfaces/SubscriptionProcessing/ISubscriptionTrackingSteps";
import React, { Fragment } from "react";
import { useTranslation } from "services";
import "../scss/TestInProgress.scss";

const TestInProgress: React.FC<({
    order?: ISubscriptionTrackingSteps
    analysisKit?: IDatabaseAnalysisKit
})> = ({
    order,
    analysisKit
}) => {
    const t = useTranslation('dashboard/patient/testResults');

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <div className="flex-column-start full-width">
                        <h1 className="bold">
                            {t.translate('testInProgress.currentTest.title')}
                        </h1>

                        <div className="flex-row space-between full-width">
                            <span id="order-id" className={!analysisKit?.verificationCode ? 'no-data' : ''}>{analysisKit && analysisKit.verificationCode ? '#' + analysisKit.verificationCode : t.translateFromFile('common', 'no-data')}</span>
                            <div id="spacer" />
                            <span className="test-in-progress-header-info">
                                {analysisKit && analysisKit.microbiotaResultsReceivedOn
                                    ? t.translateDate(new Date(analysisKit.microbiotaResultsReceivedOn))
                                    : t.translate('testInProgress.unavailable')
                                }
                            </span>
                        </div>
                    </div>
                </Fragment>
                
            }
            body={
                <Fragment>
                    {!order?.kitClaim?.isDone && <div className="flex-column">
                        <h3 style={{ textAlign: 'center' }}>{t.translateFromFile('common', "noKitFollowUp")}</h3>
                    </div>}
                    <PatientSubscriptionTracking tracking={order} />
                </Fragment>
            }
        />
    );
}

export default TestInProgress;