import { Label, Select, TimeInput } from "components/Form";
import { FormError } from "components/Modals/EditSchedule/EditSchedule";
import { ScheduleCreate, ScheduleDayBreakCreate } from "interfaces/Database";
import { ScheduleDayBreakType } from "interfaces/Database/IDatabaseScheduleDayBreak";
import { Fragment } from "react";
import { useTranslation } from "services";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

interface ScheduleDayBreakFormProps {
    key: string;
    day_index: number;
    break_index: number;
    break: ScheduleDayBreakCreate;
    formErrors: FormError[];
    newSchedule: ScheduleCreate;
    setNewSchedule: React.Dispatch<React.SetStateAction<ScheduleCreate>>
}

const ScheduleDayBreakForm: React.FC<ScheduleDayBreakFormProps> = (props) => {
    const t = useTranslation('components/schedule');

    const handleRemoveBreak = (breakIndex: number) => {
        props.newSchedule.days[props.day_index].removeBreak(breakIndex);
        props.setNewSchedule(new ScheduleCreate(props.newSchedule));
    }

    const handleDuplicateBreak = (breakIndex: number) => {
        props.newSchedule.days[props.day_index].addBreak(props.newSchedule.days[props.day_index].breaks[breakIndex]);
        props.setNewSchedule(new ScheduleCreate(props.newSchedule));
    }

    const handleUpdateScheduleDayBreak = (dayIndex: number, breakIndex: number, e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const fieldNameSplitted = e.target.name.split('-');
        const fieldName = fieldNameSplitted[fieldNameSplitted.length-1];

        if (fieldName === 'hours') {
            if (e.target.name.includes('start')) {
                props.newSchedule.days[dayIndex].breaks[breakIndex].setStartHour(parseInt(e.target.value));
            } else {
                props.newSchedule.days[dayIndex].breaks[breakIndex].setEndHour(parseInt(e.target.value));
            }
        } else if (fieldName === 'minutes') {
            if (e.target.name.includes('start')) {
                props.newSchedule.days[dayIndex].breaks[breakIndex].setStartMinute(parseInt(e.target.value));
            } else {
                props.newSchedule.days[dayIndex].breaks[breakIndex].setEndMinute(parseInt(e.target.value));
            }
        } else {
            props.newSchedule.days[dayIndex].breaks[breakIndex].setType(e.target.value as ScheduleDayBreakType);
        }

        props.setNewSchedule(new ScheduleCreate(props.newSchedule));
    }

    return (
        <div>
            <h5>{t.translateReplaceValues('editModal.break', [
                {
                    tag: '{{index}}',
                    value: props.break_index
                }
            ])}</h5>
            <div className="flex-row flex-gap-col">
                <p className="clickable text-main-color" onClick={() => handleDuplicateBreak(props.break_index)}>{t.translate('editModal.duplicateBreak')}</p>
                <p className="clickable text-error" onClick={() => handleRemoveBreak(props.break_index)}>{t.translate('editModal.removeBreak')}</p>
            </div>

            <div>
                <Label for={`scheduleDay-${props.day_index}-break-${props.break_index}-type`} label={t.translateFormField('breakType.label')} required />
                <Select
                    name={`scheduleDay-${props.day_index}-break-${props.break_index}-type`}
                    value={props.break.type}
                    setValue={(e) => handleUpdateScheduleDayBreak(props.day_index, props.break_index, e)}
                    options={
                        <Fragment>
                            <option disabled={props.break.type !== undefined}>{t.translateFormField('chooseOption')}</option>
                            {Object.keys(ScheduleDayBreakType).map((value, break_type_index) =>
                                <option key={`break-type-${break_type_index}`} value={value.toLowerCase()}>{value}</option>
                            )}
                        </Fragment>
                    }
                />
            </div>

            <div>
                <Label for={`scheduleDay-${props.day_index}-break-${props.break_index}-start`} label={t.translateFormField('startHour.label')} required />
                <TimeInput
                    name={`scheduleDay-${props.day_index}-break-${props.break_index}-start`}
                    hours={props.break.startHour}
                    setHours={(e) => handleUpdateScheduleDayBreak(props.day_index, props.break_index, e)}
                    minutes={props.break.startMinute}
                    setMinutes={(e) => handleUpdateScheduleDayBreak(props.day_index, props.break_index, e)}
                />

                <ErrorMessage withTranslation={false} error={props.formErrors.filter((e) => e.index === props.day_index && e.subIndex === props.break_index && e.field === `start`)[0]?.error} />
            </div>
            <div>
                <Label for={`scheduleDay-${props.day_index}-break-${props.break_index}-end`} label={t.translateFormField('endHour.label')} required />
                <TimeInput
                    name={`scheduleDay-${props.day_index}-break-${props.break_index}-end`}
                    hours={props.break.endHour}
                    setHours={(e) => handleUpdateScheduleDayBreak(props.day_index, props.break_index, e)}
                    minutes={props.break.endMinute}
                    setMinutes={(e) => handleUpdateScheduleDayBreak(props.day_index, props.break_index, e)}
                />

                <ErrorMessage withTranslation={false} error={props.formErrors.filter((e) => e.index === props.day_index && e.subIndex === props.break_index && e.field === `end`)[0]?.error} />
            </div>

            <ErrorMessage withTranslation={false} error={props.formErrors.filter((e) => e.index === props.day_index && e.subIndex === props.break_index && e.field === `time`)[0]?.error} />
        </div>
    )
}

export default ScheduleDayBreakForm;