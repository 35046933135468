import { History } from "history";
import { IDUser, IUser } from "interfaces/Database";
import { IDatabaseAddress } from "interfaces/Database/IDatabaseAddress";
import { IDatabaseDoctorSpecialty } from "interfaces/Database/IDatabaseDoctorSpecialty";
import { IDatabaseLanguage } from "interfaces/Database/IDatabaseLanguage";
import { IDatabasePrescription } from "interfaces/Database/IDatabasePrescription";
import User, { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import { IDatabaseUserParameters } from "interfaces/Database/IDatabaseUserParameters";
import { IDatabaseUserType } from "interfaces/Database/IDatabaseUserType";
import { IMenuStatus } from "interfaces/Pages/Layout/IMenuStatus";
import { IAddress } from "interfaces/User/IAddress";
import 'moment-timezone';
import { Cookies } from "react-cookie";
import apiService from "./api.service";
import languageService from "./language.service";

class userAuthService {
    private readonly tokenKey = 'bcu_token';
    private readonly cookieManager = new Cookies();

    public checkCellPhone(phoneNumber: string) {
        if (phoneNumber === null) {
            return false;
        }
        // eslint-disable-next-line
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phoneNumber);
    }

    public getToken() {
        return this.cookieManager.get(this.tokenKey, { doNotParse: false });
    }

    public isToken() {
        return this.cookieManager.get(this.tokenKey, { doNotParse: true }) ? true : false;
    }

    public setToken(newToken: string) {
        this.cookieManager.set(this.tokenKey, newToken, {
            domain: process.env.REACT_APP_CD,
            path: '/',
            sameSite: 'lax'
        });
    }

    public removeToken() {
        this.cookieManager.remove(this.tokenKey, {
            domain: process.env.REACT_APP_CD,
            path: '/'
        });
    }

    public login = async (credentials: {
        username: string,
        password: string
    }) => {
        return await apiService.makeApiCall<{ token: string }>("/api/login_check", 'post', JSON.stringify(credentials), false)
            .then((res) => this.setToken(res.token));
    }

    public logoutWORedirect = async (removeToken: boolean = true) => {
        if (removeToken) {
            this.removeToken();
        }
    }

    public logout = async (history: History, logoutPath: string = '/login', removeToken: boolean = true) => {
        if (removeToken) {
            await this.removeToken();
        }

        history.push(logoutPath);
    }

    public register = async (data: {
        firstName: string
        lastName: string
        email: string
        confirmEmail: string
        phoneCode: string
        phoneNumber: string
        password: string
        confirmPassword: string
        cg: boolean
    }) => {
        return await apiService.makeApiCall(`/api_register`, 'post', data, false);
    }

    public checkToken = async (data: { token?: string }) => {
        return await apiService.makeApiCall("/check_token", 'post', JSON.stringify(data));
    }

    public getUserType = async () => {
        return await apiService.makeApiCall('/api/userType', 'get').then((res) => res as IDatabaseUserType);
    }

    public isUserConnected = () => {
        try {
            const token = this.getToken();
            if (token && token !== undefined && token !== '') {
                return apiService.makeApiCall('/check_token', 'post', JSON.stringify({ token }), false)
                    .then(() => Promise.resolve(true))
                    .catch(() => Promise.resolve(false));
            }
            return false;
        } catch{
            return false;
        }
    }

    public async accountExisting(email: string) {
        return await apiService.makeApiCall<boolean>(`/api/users/accountExisting/${email}`, 'get');
    }

    public async getUser() {
        return await apiService.makeApiCall<IUser>(`/api/users/getInformations`, 'get')
            .then((res) => new User(res));
    }

    public setFirstLogin = async (data: { firstLogin: boolean }) => {
        return await apiService.makeApiCall<IUser>(`/api/users/firstLogin`, 'post', data)
            .then((res) => new User(res));
    }

    public getUserAddresses = async () => {
        return await apiService.makeApiCall(`/api/address`, 'get')
            .then((res) => res as { shipping?: IDatabaseAddress, billing?: IDatabaseAddress });
    }

    public updateUserDefaultAddresses = async (addresses: {
        shipping: IAddress
        billing: IAddress
    }) => {
        return await apiService.makeApiCall(`/api/user/updateAddresses`, 'post', JSON.stringify(addresses));
    }

    public getUserProfilePicture = async () => {
        return await apiService.makeApiCall(`/api/getProfilePicture`, 'get').then((res) => res as string);
    }

    public getMyDoctorPicture = async () => {
        return await apiService.makeApiCall(`/api/getMyDoctorPicture`, 'get').then((res) => res as string);
    }

    public updateUserInformations = async (informations: {
        firstname?: string
        lastname?: string
        email?: string
        weight?: number
        height?: number
        waistSize?: number
        birthDate?: Date
        phoneCode?: string
        phoneNumber?: string
        gender?: string
        specialInformation?: string
        specialMedication?: string
    }) => {
        return await apiService.makeApiCall('/api/user/informations/update', 'post', informations);
    }

    public editUserInformation = async (informations: {
        firstname?: string
        lastname?: string
        email?: string
        weight?: number
        height?: number
        waistSize?: number
        birthDate?: Date
        phoneCode?: string
        phoneNumber?: string
        gender?: string
        specialInformation?: string
        specialMedication?: string
    }) => {
        return await apiService.makeApiCall("/api/users/updateInformations", 'post', JSON.stringify(informations));
    }

    public getSmsCode = async () => {
        return await apiService.makeApiCall("/api/sendSMS", 'post');
    }

    public checkSMSCode = async (data: { code: string }) => {
        return await apiService.makeApiCall("/api/checkSMS", 'post', JSON.stringify(data));
    }

    public uploadProfilePicture = async (data: FormData) => {
        return await apiService.makeApiCall("/api/uploadPhoto", 'postFormData', data);
    }

    public updateUserEmailAndPhone = async (information: {
        phoneCode?: string
        phoneNumber?: string
        email?: string
    }) => {
        return await apiService.makeApiCall("/api/updatePhoneAndEmail", 'post', JSON.stringify(information));
    }

    public updateDoctorInfo = async (information: {
        firstConnection?: boolean
        firstname?: string
        lastname?: string
        email?: string
        birthDate?: Date
        phoneCode?: string
        phoneNumber?: string
        gender?: string
        registrationNumber?: string
        clinic?: string
        specialties?: IDatabaseDoctorSpecialty[] | string[],
        languages?: IDatabaseLanguage[],
        specialInformation?: string
    }) => {
        return await apiService.makeApiCall("/api/updateDoctorInfo", 'post', JSON.stringify(information));
    }

    public handleClaimKit = async () => {
        return await apiService.makeApiCall("/api/claimKit", 'post');
    }

    public getPrescriptions = async () => {
        return await apiService.makeApiCall<IDatabasePrescription[]>(`/api/getPatientPrescriptions`, 'get');
    }

    public getLastPrescription = async () => {
        return await apiService.makeApiCall(`/api/getPatientPrescriptions`, 'get');
    }

    public getPrescriptionDetail = async (idPrescription: string) => {
        return await apiService.makeApiCall<IDatabasePrescription>(`/api/getPrescription/${idPrescription}`, 'get');
    }

    public requestResetPassword = async (email: string) => {
        return await apiService.makeApiCall("/resetPassword/requestChangePassword", 'post', JSON.stringify({ email }));
    }

    public checkRequestResetPassword = async (info: {
        email: string
        token: string
    }) => {
        return await apiService.makeApiCall("/resetPassword/verifyToken", 'post', JSON.stringify(info));
    }

    public resetPassword = async (info: {
        email: string
        token: string
        password: string
    }) => {
        return await apiService.makeApiCall(`/resetPassword/apiReset`, 'post', JSON.stringify(info));
    }

    public updatePassword = async (info: {
        newPassword: string
        confirmPassword: string
        oldPassword: string
    }) => {
        return await apiService.makeApiCall<string>(`/api/updatePassword`, 'post', JSON.stringify(info));
    }

    public getAnalysisKits = async () => {
        return await apiService.makeApiCall(`/api/getPatientAnalysisKits`, 'get');
    }

    public getOrCreateMeeting = async (idAppointment: string|number) => {
        return await apiService.makeApiCall<{ code: number, roomUrl: string }>(`/api/createMeeting/${idAppointment}`, 'post');
    }

    public getTimeZone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public getAllDoctorLanguages = async () => {
        return await apiService.makeApiCall(`/api/getAllDoctorLanguages`, 'get')
            .then((res) => res as IDatabaseLanguage[]);
    }

    public getAllDoctorSpecialties = async () => {
        return await apiService.makeApiCall(`/api/getAllDoctorSpecialties`, 'get');
    }

    public getTestReport = async () => {
        return await apiService.makeApiCall(`/api/getTestReport`, 'get');
    }

    public getGeneralComment = async (id: string|number) => {
        return await apiService.makeApiCall(`/api/getGeneralComment/${id}`, 'get');
    }

    public getResultPDF = async (id: string|number, isDoctor = false) => {
        return await apiService.makeApiCall(`/api/results/resultPDF?id=${id}${isDoctor ? '&type=doctor' : ''}`, 'get');
    }

    public getDoctorGeneralComment = async (idAppointment: string|number) => {
        return await apiService.makeApiCall<{ pdf: string }>(`/api/getGeneralComment/${idAppointment}`, 'get');
    }

    public getDoctorPreventiveAdvice = async (idAppointment: string|number) => {
        return await apiService.makeApiCall(`/api/getPreventiveAdvice/${idAppointment}`, 'get');
    }

    public getFormattedDate = async (date: string | number | Date) => {
        const prefUserLanguage = await languageService.getCurrentLanguage();

        const formatter = new Intl.DateTimeFormat(prefUserLanguage.iso6391 ?? "en", {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour12: false
        })

        return `${formatter.format(new Date(date))}`;
    }

    public getPrescriptionPdf = async (prescriptionId: string|number) => {
        return await apiService.makeApiCall(`/api/getPrescriptionPdf/${prescriptionId}`, 'get');
    }


    public getGeneralCommentPdf = async (appointmentId: string|number) => {
        return await apiService.makeApiCall(`/api/getGeneralCommentPdf/${appointmentId}`, 'get');
    }

    public getPreventionAdvicePdf = async (appointmentId: string|number) => {
        return await apiService.makeApiCall(`/api/getPreventionAdvicePdf/${appointmentId}`, 'get');
    }

    public getDietarySupplementPdf = async (prescriptionId: string|number) => {
        return await apiService.makeApiCall(`/api/getDietarySupplementPdf/${prescriptionId}`, 'get');
    }

    public getNutritionalCommentPdf = async (appointmentId: string|number) => {
        return await apiService.makeApiCall(`/api/getNutritionalCommentPdf/${appointmentId}`, 'get');
    }

    public getNutritionalComment = async (appointmentId: string|number) => {
        return await apiService.makeApiCall(`/api/nutritionalComment/${appointmentId}`, 'get');
    }

    public async getDoctor(doctorId: string|number) {
        return await apiService.makeApiCall<IDatabaseUser>(`/api/doctor/${doctorId}`, 'get');
    } 
    public getDoctorInfo = async (doctorId: string|number) => {
        return await apiService.makeApiCall(`/api/getDoctor/${doctorId}`, 'get').then((res) => res as IDatabaseUser);
    }

    public sendRequestRemovePersonalData = async () => {
        return await apiService.makeApiCall(`/api/user/removePersonal`, 'get');
    }


    public getUserParameters = async () => {
        return await apiService.makeApiCall(`/api/user/getParameters`, 'get').then((res) => res as IDatabaseUserParameters);
    }

    public updateUserParameters = async (newParameters: IDatabaseUserParameters) => {
        return await apiService.makeApiCall(`/api/user/updateParameters`, 'post', newParameters);
    }

    public userPicture = async (id: number) => {
        return await apiService.makeApiCall(`/api/userPicture/${id}`, 'get')
            .then((res) => res as string);
    }

    public getMenuStatus = async () => {
        return await apiService.makeApiCall(`/api/user/menuStatus`, 'get').then((res) => res as IMenuStatus);
    }

    public formatName(user?: IDUser): string {
        let name: string;

        if (!user) return '';

        switch (user.type?.name) {
            case 'Company':
                name = user.companyInfo.name;
                break;
            case 'Patient':
            case 'Standalone':
                name = user.patientInfo?.firstname + ' ' + user.patientInfo?.lastname;
                break;
            case 'Doctor':
                name = `Dr. ${user.doctorInfo?.lastname} ${user.doctorInfo?.firstname}`;
                break;
            case 'Staff':
                name = user.staffInfo?.firstname + ' ' + user.staffInfo?.lastname;
                break;
            default:
                name = user.email.split('@')[0];
        }

        if (name.trim().length === 0) {
            name = user.email.split('@')[0];
        }

        return name;
    }
}
export default new userAuthService();