import claimKitImg from "assets/claim_kit/banner.png";
import bodycheckupLogo from "assets/logos/bcu_text.png";
import { Button } from 'components';
import React from "react";
import { useTranslation } from "services";
import "../../scss/ClaimKitHome.scss";

const ClaimKitHome: React.FC<({
    handleNextStep: () => void
})> = ({ handleNextStep }) => {
    const t = useTranslation('firstConnection');

    return (
        <div id="claim-kit" className="column">
            <img src={claimKitImg} id="img" alt="claim-kit" />
            <div id="claim-kit-content-wrapper" className="column">
                <div className="column" id="logo-wrapper">
                    <p id="welcome-on" className="MavenPro-bold">
                        {t.translate('title')}
                    </p>
                    <img src={bodycheckupLogo} id="bodycheckup-logo" alt="logo bodycheckup" />
                </div>
                <p dangerouslySetInnerHTML={{__html: t.translate('text.patient')}}id="text-info" />
                <Button label={t.translate('registerKit')} onClick={handleNextStep} />
            </div>
        </div>
    );
}

export default ClaimKitHome;