import { createSlice } from '@reduxjs/toolkit';

export const updateProfilePicture = createSlice({
    name: 'profilePicture',
    initialState: {
        value: 0,
    },
    reducers: {
        refreshHeaderProfilePicture: state => {
            state.value += 1;
        }
    }
});

export const { refreshHeaderProfilePicture } = updateProfilePicture.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getUpdatePicture = (state: any) => state.profilePicture.value;

export default updateProfilePicture.reducer;
