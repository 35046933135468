import { Button, EditIcon, InformationWrapper, Loader } from "components";
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import VerifyPhoneModal from "components/common/Modals/VerifyPhoneModal";
import { UserContext } from 'context/User/UserContext';
import React, { Fragment, useContext, useState } from 'react';
import { FaPhone } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { useTranslation } from "services";
import '../scss/Contact.scss';
import ModalEditDoctorContactInfo from './modal/ModalEditDoctorContactInfo';

const Contact: React.FC = () => {
    const t = useTranslation('profile/doctor/contact');
    const { user, refreshUser } = useContext(UserContext);
    
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ showVerifyModal, setShowVerifyModal ] = useState<boolean>(false);

    const toggleShowModal = () => {
        setShowModal(!showModal);
    }

    const toggleVerifyModal = () => {
        setShowVerifyModal(!showVerifyModal);
    }

    const refreshContactInfo = async () => {
        setShowModal(false);
        setIsLoading(true);

        refreshUser()
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }

    return ( 
        <DefaultContainer
            title={
                <Fragment>
                    <h2 className='bold'>{t.translate("title")}</h2>
                    <EditIcon onClick={() => setShowModal(true)} className='icon action' />
                </Fragment>
            }
            body={isLoading
                ? <Loader />
                : <Fragment>
                    <div id='content-contact' className='flex-column full-width' style={{ margin: 0, padding: 0 }}>
                        <div className='flex-row full-width'>
                            <FaPhone color="var(--dashboard-main-color)" size={26} />
                            <InformationWrapper libelle={t.translate("phone")} value={user?.phone?.number} fullWidth />
                        </div>
                        <div className='flex-row full-width' style={{ marginTop: "40px" }}>
                            <MdMail color="var(--dashboard-main-color)" size={26} />
                            <InformationWrapper libelle={t.translate("email")} value={user?.email} fullWidth />
                        </div>
                    </div>
                    <ModalEditDoctorContactInfo
                        showModal={showModal}
                        handleClose={toggleShowModal}
                        handleSuccess={() => refreshContactInfo()}
                        user={user}
                    />
                </Fragment>
            }
            footer={!user?.phoneIsVerified &&
                <Fragment>
                    <p>{t.translate('phoneVerification.notVerified')}</p>
                    <Button label={t.translate('phoneVerification.verify')} onClick={toggleVerifyModal} />
                    <VerifyPhoneModal
                        showModal={showVerifyModal}
                        toggleShowModal={toggleVerifyModal}
                        toggleDataModal={toggleShowModal}
                    />
                </Fragment>
            }
        />
    )
}

export default Contact;