import { Input } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import User from "interfaces/Database/IDatabaseUser";
import React, { Fragment, useState } from "react";
import { useTranslation } from "services";
import Table from "../common/Table";
import '../scss/MyPatients.scss';

const MyPatients: React.FC<({
    patients: User[]
})> = ({patients}) => {
    const t = useTranslation('doctorPatients');
    const [ search, setSearch ] = useState<string>('');

    const filter = (search: string) => {
        return patients.filter(patient => patient?.patientInfo?.firstname?.toLowerCase().includes(search) || patient?.patientInfo?.lastname?.toLowerCase().includes(search))
    } 

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h2 className="bold">{t.translate("myPatients.title")}</h2>
                    <div id='search-patients' className="fullwidth flex">
                        <Input
                            name="my-patients-search"
                            value={search}
                            setValue={(e) => setSearch(e.currentTarget.value.toLowerCase())}
                            placeholder={t.translate("table.search")}
                        />
                    </div>
                </Fragment>
            }
            body={patients.length === 0
                ? <p className="no-data">{t.translate("noPatient")}</p>
                : <Table
                    columns={[
                        t.translate("table.role"),
                        t.translate("table.name"), 
                        t.translate("table.email"), 
                        t.translate("table.phone"),
                        ''
                    ]}
                    patients={filter(search)}
                /> 
            }
        />
    )
}

export default MyPatients