import img from 'assets/Help/doctor.svg';
import React from 'react';
import { useTranslation } from 'services';

const SSFifthPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2><span className='text-main-color'>4.</span> {t.translate('standalone.appointment.title')}</h2>
            <div className="help-step-content">
                <img src={img} className='help-img' alt="Doctor appointment" />
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '320px' }}>
                    <p>{t.translate('standalone.appointment.appointment')} <span className='text-important'>{t.translate('standalone.appointment.minutes')}</span> {t.translate('standalone.appointment.withDoctor')} <span className='text-important'>{t.translate('standalone.appointment.medication')}</span>, <span className='text-important'>{t.translate('standalone.appointment.dietary')}</span> {t.translate('standalone.appointment.and')} <span className='text-important'>{t.translate('standalone.appointment.diet')}</span>.</p>
                </div>
            </div>
        </div>
    )
}

export default SSFifthPage;