import React, { CSSProperties } from "react";
import DashboardLabel from "./DashboardLabel";
import "./scss/DashboardCheckbox.scss";

const DashboardCheckbox: React.FC<({
    required?: boolean,
    error?: string,
    name: string,
    disabled?: boolean,
    label?: string,
    labelStyle?: CSSProperties,
    isChecked?: boolean,
    setChecked?: React.Dispatch<React.SetStateAction<boolean>>,
    onClick?: () => void;
    checkedClass?: string,
    notCheckedClass?: string,
    displayNotChecked?: boolean,
    wrapperClassName?: string
})> = ({
    required,
    name,
    error,
    disabled,
    label,
    labelStyle,
    isChecked,
    setChecked,
    onClick,
    checkedClass = 'checked',
    notCheckedClass = 'not-checked',
    displayNotChecked = false,
    wrapperClassName
}) => {
    return (
        <div className={`${wrapperClassName ?? ''} checkbox-wrapper${label ? '' : '-no-label'}`} onClick={onClick}>
            {label
                ? <label>
                    <input
                        required={required}
                        id={name}
                        name={name}
                        disabled={disabled}
                        type="checkbox"
                        checked={isChecked}
                        onChange={setChecked ? () => setChecked((prev) => !prev) : undefined}
                        className={
                            isChecked ?
                                checkedClass :
                                displayNotChecked ?
                                    notCheckedClass :
                                    ""
                        }
                    />
                    <DashboardLabel for={name} label={label} required={required} labelStyle={labelStyle} />
                </label>
                : <input
                    required={required}
                    id={name}
                    name={name}
                    disabled={disabled}
                    type="checkbox"
                    checked={isChecked}
                    onChange={setChecked ? () => setChecked((prev) => !prev) : undefined}
                    className={
                        isChecked
                            ? checkedClass
                            : displayNotChecked
                                ? notCheckedClass
                                : ""
                    }
                />
            }
            {error && <div className="text-error">
                {error}
            </div>}
        </div>
    )
}

export default DashboardCheckbox;