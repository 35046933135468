import { EditIcon, InformationWrapper, Loader } from "components";
import DisplayAddress from "components/common/Address/DisplayAddress";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { MenuStatusContext } from "context/MenuStatusContext";
import { UserContext } from "context/User/UserContext";
import { IDatabaseAddress } from "interfaces/Database/IDatabaseAddress";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "services";
import addressService from "services/address.service";
import "../scss/AddressInformation.scss";
import ModalEditSpecificInfo from "./modal/ModalEditSpecificInfo";

const AddressInformation: React.FC = () => {
    const t = useTranslation('profile/doctor/address');
    const { user, refreshUser } = useContext(UserContext);
    const { refreshMenuStatus } = useContext(MenuStatusContext);

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ showModal, setShowModal ] = useState<boolean>(false);
    const [ currentAddress, setCurrentAddress ] = useState<IDatabaseAddress>();

    const fetchAddress = useCallback(() => {
        setIsLoading(true);

        addressService.getAddresses()
            .then((res) => setCurrentAddress(res?.billing))
            .catch((err) => console.warn(err))
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        fetchAddress();
    }, [fetchAddress]);

    const refreshAddressInfo = async () => {
        setIsLoading(true);
        setShowModal(false);

        Promise.all([
            addressService.getAddresses()
                .then((res) => setCurrentAddress(res?.billing))
                .catch((err) => console.warn(err)),
            refreshUser(),
            refreshMenuStatus()
        ])
            .then(() => setShowModal(false))
            .finally(() => setIsLoading(false));
    }

    return (
        <DefaultContainer
            title={
                <Fragment>
                    <h1 className="container-title bold">{t.translate("title")}</h1>
                    <EditIcon onClick={() => setShowModal(true)} className='icon action' />
                </Fragment>
            }
            body={isLoading
                ? <Loader />
                : <Fragment>
                    <div className="flex-row full-width flex-wrap space-around" style={{ margin: '20px 0' }}>
                        <div className="information-wrapper">
                            <InformationWrapper
                                libelle={t.translate("number")}
                                value={user?.doctorInfo?.registrationNumber
                                    ? (user.doctorInfo.registrationType
                                        ? `(${user.doctorInfo.registrationType.name}) `
                                        : '')
                                        + user?.doctorInfo?.registrationNumber
                                    : undefined
                                }
                            />
                        </div>
                        <div className="information-wrapper">
                            <InformationWrapper
                                libelle={t.translate("specialty")}
                                value={user?.doctorInfo?.doctorSpecialties && user?.doctorInfo?.doctorSpecialties.length > 0
                                    ? user?.doctorInfo?.doctorSpecialties.map((specialty) => specialty.name).join(', ')
                                    : undefined
                                }
                            />
                        </div>
                        <div className="information-wrapper">
                            <InformationWrapper
                                libelle={t.translate("language")}
                                value={user?.doctorInfo?.languages && user?.doctorInfo?.languages.length > 0
                                    ? user?.doctorInfo?.languages.map((language) => language.name).join(', ')
                                    : undefined
                                }
                            />
                        </div>
                    </div>
                    <DisplayAddress address={currentAddress} />
                    <ModalEditSpecificInfo
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        handleSuccess={() => refreshAddressInfo()}
                        currentUser={user}
                        currentAddress={currentAddress}
                    />
                </Fragment>
            }
        />
    );
}

export default AddressInformation;