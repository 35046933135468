import { Button, Loader } from "components";
import { UserTypeContext } from "context/User/UserTypeContext";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import doctorService from "services/doctor.service";
import messagingService from "services/messaging.service";
import patientService from "services/patient.service";
import standaloneService from "services/standalone.service";
import DefaultContainer from "../DefaultContainer/DefaultContainer";
import { ProfilePicture } from "../ProfilePicture";
import "./scss/ReferringDoctor.scss";

const MAX_MESSAGES = 5;

const ReferringDoctor: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('dashboard/patient/home');
    const { type } = useContext(UserTypeContext);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const [ doctor, setDoctor ] = useState<IDatabaseUser>();
    const [ totalMessages, setTotalMessages ] = useState<number>(0);
    const [ nutritionist, setNutritionist ] = useState<IDatabaseUser>();
    const [ totalMessagesToNutritionist, setTotalMessagesToNutritionist ] = useState<number>(0);

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        Promise.all([
            (type.isStandalone()
                ? standaloneService
                : patientService).getDoctor()
                    .then((res) => setDoctor(res)),
            messagingService.getTotalMessagesWithDoctor()
                .then((res) => setTotalMessages(res)),
            // (type.isStandalone()
            //     ? standaloneService
            //     : patientService).getNutritionist()
            //         .then((res) => setNutritionist(res)),
            // messagingService.getTotalMessagesWithNutritionist()
            //     .then((res) => setTotalMessagesToNutritionist(res))
        ])
            .catch(console.warn)
            .finally(() => setIsLoading(false));
    }, [type]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <DefaultContainer
            title={
                <h1 className="container-title bold">
                    {t.translate('referring-doctor.title')}
                </h1>
            }
            body={isLoading
                ? <Loader />
                : doctor
                    ? <Fragment>
                        {nutritionist && <h3>Docteur référent :</h3>}
                        <div className="flex-row" id="referring-doctor-wrapper">
                            <div id="doctor-image-wrapper">
                                <ProfilePicture user={doctor} />
                            </div>
                            <div className="flex-column">
                                <div className="flex-column-start full-width">
                                    <span className="label-referring-doctor MavenPro-black">
                                        {t.translate('referring-doctor.doctor-name')}
                                    </span>
                                    <p className="info-referring-doctor" id="doctor-name">
                                        {doctorService.formatDoctorName(doctor?.doctorInfo)}
                                    </p>
                                </div>
                                <div className="flex-column-start full-width">
                                    <span className="label-referring-doctor MavenPro-black">
                                        {t.translate('referring-doctor.doctor-message')}
                                    </span>
                                    <div className="row align-items-start">
                                        <p className="info-referring-doctor">
                                            {totalMessages ?? 0}/5 {t.translate('referring-doctor.doctor-message-count')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {nutritionist && 
                            <h3>Nutritionniste référent :</h3>}
                        {nutritionist
                            && <div className="flex-row" id="referring-doctor-wrapper">
                                <div id="doctor-image-wrapper">
                                    <ProfilePicture user={nutritionist} />
                                </div>
                                <div className="flex-column">
                                    <div className="flex-column-start full-width">
                                        <span className="label-referring-doctor MavenPro-black">
                                            {t.translate('referring-doctor.doctor-name')}
                                        </span>
                                        <p className="info-referring-doctor" id="doctor-name">
                                            {doctorService.formatDoctorName(nutritionist?.doctorInfo)}
                                        </p>
                                    </div>
                                    <div className="flex-column-start full-width">
                                        <span className="label-referring-doctor MavenPro-black">
                                            {t.translate('referring-doctor.doctor-message')}
                                        </span>
                                        <div className="row align-items-start">
                                            <p className="info-referring-doctor">
                                                {totalMessagesToNutritionist ?? 0}/5 {t.translate('referring-doctor.doctor-message-count')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Fragment>
                    : <p className="no-data">
                        {t.translate('referring-doctor.none')}
                    </p>
            }
            footer={
                (!isLoading && doctor) &&
                    <div className="flex-row flex-wrap flex-col-gap flex-row-gap">
                        <Button
                            label={nutritionist ? t.translate('referring-doctor.button-doctor') : t.translate('referring-doctor.button-label')}
                            onClick={() => history.push('/account/messaging', { openDoctorConversation: doctor.id })}
                            disabled={totalMessages >= MAX_MESSAGES}
                        />
                        {nutritionist
                            && <Button
                                label={t.translate('referring-doctor.button-nutritionist')}
                                onClick={() => history.push('/account/messaging', { openDoctorConversation: nutritionist.id })}
                                disabled={totalMessages >= MAX_MESSAGES}
                            />
                        }
                    </div>
            }
        />
    );
}

export default ReferringDoctor;