import { Loader } from "components";
import { UserTypeContext } from "context/User/UserTypeContext";
import { RoleName } from "interfaces/User/IUserRole";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";

const AuthRoute: React.FC<({
    component?: React.ComponentType
    path: string
    requiredRoles?: RoleName[]
    exact?: boolean
})> = ({
    component,
    path,
    requiredRoles,
    exact
}) => {
    const history = useHistory();

    const { type, refreshType } = useContext(UserTypeContext);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const checkRole = (role: RoleName) => {
        if (!requiredRoles || requiredRoles.includes(role)) {
            setIsLoading(false);
        } else {
            history.push('/account/home');
        }
    }

    const handleRole = useCallback(() => {
        if (type.isDefined()) {
            checkRole(type?.name);
        } else {
            refreshType().catch(() => history.push('/account/home'));
        }
    }, [type]);

    useEffect(() => {
        handleRole();
    }, [handleRole]);

    return (
        <Fragment>
            {isLoading
                ? <Loader />
                : <Route exact={exact} path={path} component={component} />}
        </Fragment>
    )
}

export default AuthRoute;