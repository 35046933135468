import { IDatabaseConsentForm } from "interfaces/Database/IDatabaseConsentForm";
import { IAddress } from "../interfaces/User/IAddress";
import apiService from "./api.service";
import ErrorService from "./Error.service";

class ConsentFormService {
    public getConsentFormById = async (consentFormId: string) => {
        return await apiService.get(`/api/consentForm/${consentFormId}`)
            .then((res) => res)
            .catch((err) => ErrorService.handleApiError(err));
    }

    public retrieveConsentForm = async (analysisKitCode?: string) => {
        return await apiService.makeApiCall<IDatabaseConsentForm>(`/api/consentForm/analysisKit/${analysisKitCode}`, 'get');
    }

    public retrieveLastConsentForm = async () => {
        return await apiService.makeApiCall<IDatabaseConsentForm>(`/api/consentForm/getLast`, 'get');
    }

    public create = async (data: {
        analysisKitCode: string | undefined
        firstname: string,
        lastname: string,
        birthdate: string,
        address: IAddress,
        hasAcceptedCGU: boolean,
        hasAcceptedCGV: boolean,
        hasAcceptedDisclosure: boolean,
        hasConsentToDoSamples: boolean,
        didAt: string
    }) => {
        return await apiService.makeApiCall(`/api/consentForm/createOrUpdate`, 'post', data)
            .then((res) => res);
    }
}

export default new ConsentFormService();