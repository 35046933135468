import box from 'assets/home/microbiota_analysis.webp';
import { Button, LeftArrowIcon, StepIndicator } from 'components';
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'services';

const PlanAlreadyActivated: React.FC = () => {
    const t = useTranslation('pages/kitRegister');
    const history = useHistory();

    return (
        <DefaultContainer
            wrapperClassName="customContainerWrapper"
            title={
                <div className='flex-row-start'>
                    <LeftArrowIcon
                        className="clickable"
                        onClick={() => history.push('/account/home')}
                    />
                    <div style={{ margin: '0 1vw' }} className='flex-column-start'>
                        <StepIndicator step={1} />
                    </div>
                </div>
            }
            body={
                <div className='row'>
                    <div id="image-wrapper" className="column">
                        <img src={box} alt="kit" id="kit-image" />
                    </div>
                    <div id="form-wrapper" className="flex-column">
                        <div className="flex-column">
                            <h2 id="title" className="MavenPro-black full-width" style={{ textAlign: "center" }}>
                                {t.translate('ongoing.title')}
                            </h2>
                            <p id="form-description" className="full-width" style={{ textAlign: "center" }}>
                                {t.translate('ongoing.desc')}
                            </p>
                            <div className="flex-row flex-wrap full-width space-around" style={{ padding: '2vh 0' }}>
                                <Button label={t.translate('ongoing.contactUs')} style={{ margin: "5px" }} onClick={() => history.push("/contact-us")} />
                                <Button label={t.translate('ongoing.dashboard')} style={{ margin: "5px" }} onClick={() => history.push("/account/home")} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            hideSeparator={true}
        />
    )
}

export default PlanAlreadyActivated;