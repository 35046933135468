import { DUserType, IDUserType } from "interfaces/Database";
import React, { createContext, useState } from "react";
import userAuthService from "services/user-auth.service";

type UserTypeContextProps = {
    type: DUserType;
    setType: (type?: DUserType) => void;
    refreshType: () => Promise<IDUserType>;
}

export const defaultUserType: DUserType = new DUserType();

export const UserTypeContext = createContext<UserTypeContextProps>({
    type: defaultUserType,
    setType: (type?: DUserType) => undefined,
    refreshType: async () => await userAuthService.getUserType()
});

export const UserTypeProvider: React.FC<({ children: React.ReactNode })> = ({ children }) => {
    const [ type, updateType ] = useState<DUserType>(defaultUserType);

    async function refreshType() {
        return await userAuthService.getUserType()
            .then((res) => {
                setType(new DUserType(res?.id, res?.name));
                return res;
            })
            .catch((err) => {
                setType(defaultUserType);
                return err;
            });
    }

    function setType(newType?: DUserType) {
        if (newType) {
            updateType(newType);
        } else {
            updateType(defaultUserType);
        }
    }

    return (
        <UserTypeContext.Provider value={{ type, setType, refreshType }}>
            {children}
        </UserTypeContext.Provider>
    )
}