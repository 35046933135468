import { Button } from "components/Form";
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'services';
import "../scss/FaqContact.scss";

const FaqContact: React.FC = () => {
    const t = useTranslation('dashboard/support');
    const history = useHistory();

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate('contact.title')}</h1>}
            body={
                <div className="row full-width" id="faq-contact-wrapper">
                    <div className="row contact-wrapper">
                        <div className="column">
                            <Button
                                label={t.translate('contact.writeUs')}
                                onClick={() => history.push("/account/messaging")}
                            />
                        </div>
                    </div>
                    <div className="row contact-wrapper">
                        <MdMail color="var(--dashboard-main-color)" />
                        <div className="column">
                            <span className="MavenPro-black contact-info">{t.translate('contact.email.value')}</span>
                            <span className="uppercase contact-label MavenPro-bold">{t.translate('contact.email.title')}</span>
                        </div>
                    </div>
                    <div className="row contact-wrapper">
                        <FaWhatsapp color="var(--dashboard-main-color)" />
                        <div className="column">
                            <span className="MavenPro-black contact-info">{t.translate('contact.whatsapp.value')}</span>
                            <span className="uppercase contact-label MavenPro-bold">{t.translate('contact.whatsapp.title')}</span>
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default FaqContact;