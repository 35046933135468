import { DAnalysisKit, DAppointment, DAppointmentWithNutritionist, DConsentForm, DParcel, DSubscriptionPlan, DSurvey, IDAnalysisKit, IDAppointment, IDAppointmentWithNutritionist, IDConsentForm, IDParcel, IDSubscriptionPlan, IDSurvey } from "interfaces/Database";

export interface ISubscriptionTrackingDetail {
    subscriptionPlan?: IDSubscriptionPlan;
    analysisKit?: IDAnalysisKit;
    consentForm?: IDConsentForm;
    survey?: IDSurvey;
    parcelToPatient?: IDParcel;
    parcelToLaboratory?: IDParcel;
    doctorAppointment?: IDAppointment;
    appointmentWNutritionist?: IDAppointmentWithNutritionist;
}

export default class SubscriptionTrackingDetail implements ISubscriptionTrackingDetail {
    subscriptionPlan?: DSubscriptionPlan;
    analysisKit?: DAnalysisKit;
    consentForm?: DConsentForm;
    survey?: DSurvey;
    parcelToPatient?: DParcel;
    parcelToLaboratory?: DParcel;
    doctorAppointment?: DAppointment;
    appointmentWNutritionist?: DAppointmentWithNutritionist;

    constructor(data: ISubscriptionTrackingDetail) {
        if (data.subscriptionPlan) this.subscriptionPlan = new DSubscriptionPlan(data.subscriptionPlan);
        if (data.analysisKit) this.analysisKit = new DAnalysisKit(data.analysisKit);
        if (data.consentForm) this.consentForm = new DConsentForm(data.consentForm);
        if (data.survey) this.survey = new DSurvey(data.survey);
        if (data.parcelToPatient) this.parcelToPatient = new DParcel(data.parcelToPatient);
        if (data.parcelToLaboratory) this.parcelToLaboratory = new DParcel(data.parcelToLaboratory);
        if (data.doctorAppointment) this.doctorAppointment = new DAppointment(data.doctorAppointment);
        if (data.appointmentWNutritionist) this.appointmentWNutritionist = new DAppointmentWithNutritionist(data.appointmentWNutritionist);
    }

    public isPlanMicrobiotaOnly(): boolean {
        return this?.subscriptionPlan?.isTypeMicrobiotaOnly() ?? false;
    }

    public isPlanMicrobiotaStressBurnout(): boolean {
        return this?.subscriptionPlan?.isTypeMicrobiotaStressBurnout() ?? false;
    }

    public isPlanStressBurnout(): boolean {
        return this?.subscriptionPlan?.isTypeStressBurnout() ?? false;
    }

    public isPlanMicrobiota(): boolean {
        return this?.subscriptionPlan?.isTypeMicrobiota() ?? false;
    }

    public isPlanCancerDetection(): boolean {
        return this?.subscriptionPlan?.isTypeCancer() ?? false;
    }

    public areDoctorAndNutritionistAppointments(): boolean {
        return !!(this?.doctorAppointment && this?.appointmentWNutritionist) ?? false;
    }

    public areDoctorOrNutritionistAppointments(): boolean {
        return !(this?.doctorAppointment !== undefined || this?.appointmentWNutritionist != undefined);
    }

    public arentDoctorAndNutritionistAppointments(): boolean {
        return (!this?.doctorAppointment && !this?.appointmentWNutritionist) ?? false;
    }

    public arentDoctorOrNutritionistAppointments(): boolean {
        return (!this?.doctorAppointment || !this?.appointmentWNutritionist) ?? false;
    }

    public handleShouldShowAppointment = (): boolean => {
        if (this?.isPlanCancerDetection()) return false;
        if (this?.isPlanMicrobiotaOnly() && this?.arentDoctorAndNutritionistAppointments()) return false;
        if (this?.isPlanMicrobiotaStressBurnout() && this?.arentDoctorAndNutritionistAppointments()) return false;
        if (this?.isPlanStressBurnout() && this?.arentDoctorAndNutritionistAppointments()) return false;

        // By default, render appointments
        return true;
    }

    public handleShouldHideAppointment(): boolean {
        if (this?.isPlanCancerDetection()) return true;
        if (this?.isPlanMicrobiotaOnly() && this?.areDoctorOrNutritionistAppointments()) return true;
        if (this?.isPlanMicrobiotaStressBurnout() && this?.areDoctorOrNutritionistAppointments()) return true;
        if (this?.isPlanStressBurnout() && this?.areDoctorOrNutritionistAppointments()) return true;

        // By default, render appointments
        return false;
    }

    public handleShouldHidePrescription(): boolean {
        if (this?.isPlanCancerDetection()) return true;
        if (this?.isPlanMicrobiotaOnly() && this?.areDoctorOrNutritionistAppointments()) return true;
        if (this?.isPlanMicrobiotaStressBurnout() && this?.areDoctorOrNutritionistAppointments()) return true;
        if (this?.isPlanStressBurnout() && this?.areDoctorOrNutritionistAppointments()) return true;

        // By default, render appointments
        return false;
    }
}