import { InsuranceCoverage } from "components";
import FaqContact from "components/Support/components/FaqContact";
import FrequentQuestions from "components/Support/components/FrequentQuestions";
import "components/Support/scss/Support.scss";
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import { UserTypeContext } from 'context/User/UserTypeContext';
import React, { useContext } from 'react';
import { useTranslation } from 'services';

const Support: React.FC = () => {
    const t = useTranslation('dashboard/support');
    const { type } = useContext(UserTypeContext);

    return (
        <div>
            <DashboardHeader title={t.translate('header.title')} />
            <div className="flex-column full-width">
                <FrequentQuestions />
                {type.isStandalone() && <InsuranceCoverage />}
                <FaqContact />
            </div>
        </div>
    );
}

export default Support;
