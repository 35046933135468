import { Button, InsuranceCoverage, Loader } from "components";
import 'components/Order/scss/Order.scss';
import DashboardHeader from "components/common/DashboardHeader/DashboardHeader";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import { ICustomProductOrder } from "interfaces/ICustomProductOrder";
import moment from "moment-timezone";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { AiFillBackward, AiFillCaretLeft, AiFillCaretRight, AiFillForward } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useTranslation } from "services";
import orderService from "services/order.service";

const Invoicing: React.FC = () => {
    const history = useHistory();
    const t = useTranslation('dashboard/company/invoicing');

    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    // const [ isGenerating, setIsGenerating ] = useState<boolean>(false);
    const [ reloadingPages, setReloadingPages ] = useState<boolean>(true);
    // const [ choosedOrder, setChoosedOrder ] = useState<ICustomProductOrder>();

    const [ sumNbrLeft, setSumNbrLeft ] = useState<number>(0);
    const nbrOnPage = 10;
    const [ page, setPage ] = useState<number>(1);
    const [ productOrders, setProductOrders ] = useState<ICustomProductOrder[]>([]);

    const fetchData = useCallback((withSummary=false) => {
        setReloadingPages(true);
        setIsLoading(true);

        const requests = [
            orderService.getPaginatedOrders({page: page, nbrOnPage: nbrOnPage})
                .then((res) => setProductOrders(res))
        ]
        if (withSummary) {
            requests.push(
                orderService.getOrdersSummary()
                    .then((res) => setSumNbrLeft(res.total%nbrOnPage))
            )
        }

        Promise.all(requests)
            .catch((err) => console.warn(err))
            .finally(() => {
                setReloadingPages(false);
                setIsLoading(false);
            });
    }, [nbrOnPage, page]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const toFirstPage = () => {
        setPage(1);
    }

    const prevPage = () => {
        if (page > 1) {
            setPage(page-1);
        }
    }

    const nextPage = () => {
        if (page < (sumNbrLeft !== 0 ? (sumNbrLeft)+1 : sumNbrLeft)) {
            setPage(page+1);
        }
    }

    const toLastPage = () => {
        setPage((sumNbrLeft !== 0 ? (sumNbrLeft)+1 : sumNbrLeft));
    }

    // const fetchOrder = (order: ICustomProductOrder) => {
    //     setIsGenerating(true);
    //     setChoosedOrder(order);

    //     orderService.getOrderPdf(order.id.toString())
    //         .then((response) => fileService.downloadFile(response.base64, { filename: `order_${order.id}` }))
    //         .catch((err) => console.warn(err.message))
    //         .finally(() => {
    //             setIsGenerating(false);
    //             setChoosedOrder(undefined);
    //         });
    // }

    const renderContent = (productOrder: ICustomProductOrder) => {
        if (productOrder.appointmentType) return <p className="MavenPro">
            {t.translateReplaceValues('body.appointmentWith', [
                {
                    tag: '{{name}}',
                    value: t.translate(`body.${productOrder.appointmentType.name}`)
                }
            ])}
        </p>;
        if (productOrder.plans && productOrder.plans.length > 0) return <p className="MavenPro">
            {productOrder?.plans?.length} plan{productOrder?.plans?.length > 1 ? 's' : ''} {productOrder.plans[0]?.type?.name ? t.translateSubscriptionTypeName(productOrder.plans[0].type.name) : t.translateFromFile('common', 'no-data')}
        </p>;

        return <p className="MavenPro no-data">{t.translateFromFile('common', 'no-data')}</p>;
    }

    const handleStripePdf = (order: ICustomProductOrder) => {
        if (order.stripePdfLink) {
            const a = document.createElement('a');
            a.download = `stripe_invoice_${order.id}.pdf`;
            a.href = order.stripePdfLink;
            a.click();
        }
    }

    const finalRender = () => {
        if (isLoading || reloadingPages) {
            return <Loader />
        } else {
            if (productOrders.length === 0) {
                return (
                    <div className='flex-column'>
                        <p>{t.translate('orders.noKit')}</p>
                        <p>{t.translate('orders.canBuy')}</p>
                        <Button onClick={() => history.push('/order')} label={t.translate('orders.btn')} />
                    </div>
                )
            } else {
                return (
                    <div className='table flex-column accountOrder-wrapper'>
                        {/*  TABLE   */}
                        <table id='data-table' className='full-width accountOrder-wrapper-table'>
                            <thead className='accountOrder-wrapper-table-header'>
                                <tr>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.number')}</th>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.date')}</th>
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.quantity')}</th>
                                    {/* <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.purchaseOrder')}</th> */}
                                    <th className='data-titre titre-colonne text-align-center'>{t.translate('orders.stripeInvoice')}</th>
                                </tr>
                            </thead>
                            <tbody className='accountOrder-wrapper-table-body align-items-stretch'>
                                {productOrders.map((productOrder, index) =>
                                    <tr key={index} className='data-row accountOrder-wrapper-table-body-row'>
                                        <td><p className="MavenPro-bold">#{productOrder.id}</p></td>
                                        <td><p className="MavenPro">{moment(productOrder.createdOn).format('DD/MM/YYYY')}</p></td>
                                        <td>{renderContent(productOrder)}</td>
                                        {/* {productOrder.appointmentType
                                                ? <p className="MavenPro">
                                                    {productOrder.plans.length} plan{productOrder.plans.length > 1 ? 's' : ''} {productOrder?.plans[0]?.type?.name ? t.translateSubscriptionTypeName(productOrder.plans[0].type.name) : t.translateFromFile('common', 'no-data')}
                                                </p>
                                                : <p className={`MavenPro ${!productOrder?.plans || !productOrder?.plans[0]?.type?.name ? 'no-data' : '' }`}>
                                                    {productOrder?.plans?.length} plan{(productOrder?.plans?.length ?? 0) > 1 ? 's' : ''} {productOrder?.plans && productOrder?.plans[0]?.type?.name ? t.translateSubscriptionTypeName(productOrder.plans[0].type.name) : t.translateFromFile('common', 'no-data')}
                                                </p>
                                            } */}
                                        {/* <td>
                                            <Button
                                                label={t.translate('orders.download')}
                                                onClick={() => fetchOrder(productOrder)}
                                                disabled={isGenerating}
                                                displayLoader={choosedOrder?.id === productOrder?.id}
                                            />
                                        </td> */}
                                        <td>
                                            {productOrder.stripePdfLink
                                                ? <Button
                                                    label={t.translate('orders.download')}
                                                    onClick={() => handleStripePdf(productOrder)}
                                                />
                                                : <p className='no-data'>{t.translate('orders.stripeInvoiceUnavailable')}</p>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {/*  PAGINATION  */}
                        <div className='flex-row'>
                            <button disabled={page === 1} type={'button'} className={"dashboardBtn"} onClick={() => toFirstPage()}><AiFillBackward /></button>
                            <button disabled={page === 1} type={'button'} className={"dashboardBtn"} onClick={() => prevPage()}><AiFillCaretLeft /></button>
                            <p>{page}/{sumNbrLeft !== 0 ? sumNbrLeft : sumNbrLeft+1}</p>
                            <button disabled={page - (sumNbrLeft !== 0 ? sumNbrLeft : sumNbrLeft+1) === 0} type={'button'} className={"dashboardBtn"} onClick={() => nextPage()}><AiFillCaretRight /></button>
                            <button disabled={page - (sumNbrLeft !== 0 ? sumNbrLeft : sumNbrLeft+1) === 0} type={'button'} className={"dashboardBtn"} onClick={() => toLastPage()}><AiFillForward /></button>
                        </div>
                    </div>
                )
            }
        }
    }

    return (
        <Fragment>
            <DashboardHeader preTitle={t.translate('header.pre')} title={t.translate('header.title')} />
            <DefaultContainer
                title={
                    <Fragment>
                        <h2 className='bold'>{t.translate('orders.title')}</h2>
                        <Button label={t.translate('header.button')} onClick={() => history.push('/order')} />
                    </Fragment>
                }
                body={finalRender()}
            />
            <InsuranceCoverage forceShow={true} />
        </Fragment>
    )
}

export default Invoicing;