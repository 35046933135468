import img from "assets/company_news/andrhdt-380x252.jpg";
import { Button } from "components";
import DefaultContainer from "components/common/DefaultContainer/DefaultContainer";
import React, { Fragment } from "react";
import { useTranslation } from "services";
import '../scss/CompanyNews.scss';

const CompanyNews: React.FC = () => {
    const t = useTranslation('dashboard/company/home');

    const data = {
        img: img,
        title: t.translate('news.imgDesc'),
        date: new Date(),
        link: 'https://www.lagazettedescommunes.com/764604/medecine-preventive-et-nouvelles-conceptions-du-travail-au-centre-des-preoccupations/'
    }

    const openLink = () => {
        const currentLanguage = t.getLanguage();

        switch(currentLanguage.countryCode.toLowerCase()) {
            case 'fr':
                return window.open('https://bodycheckup.com/blogues/');
            default:
                return window.open('https://bodycheckup.com/blogs/');
        }
    }

    return (
        <DefaultContainer
            title={<h2 className="bold">{t.translate("news.title")}</h2>}
            body={
                <Fragment>
                    <p>{t.translate('news.blog.desc')}</p>
                    <Button label={t.translate('news.blog.button')} onClick={openLink} />
                </Fragment>
                // <div className="flex-row CompanyNewsSection-wrapper">
                //     <img src={data.img} alt="Scientific conference" />
                //     <div className="flex-column CompanyNewsSection-wrapper-content">
                //         <h3>{data.title}</h3>
                //         <p>{moment(data.date).format('DD-MM-YYYY')}</p>
                //         <Button label={t.translate("news.readArticle")} onClick={() => window.open(data.link)} />
                //     </div>
                // </div>
            }
        />
    )
}

export default CompanyNews;