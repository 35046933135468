import InformationWrapper from 'components/InformationWrapper';
import DefaultContainer from 'components/common/DefaultContainer/DefaultContainer';
import ShowProfilePicture from 'components/common/ProfilePicture/ShowProfilePicture';
import { IDatabaseUser } from 'interfaces/Database/IDatabaseUser';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'services';
import '../scss/Information.scss';

const Information: React.FC<({
    patient?: IDatabaseUser
})> = ({ patient }) => {
    const t = useTranslation('patientDetail');

    return (
        <DefaultContainer
            title={<h1 className="container-title bold">{t.translate("patientGlobalInformation.title")}</h1>}
            body={
                <Fragment>
                    <ShowProfilePicture user={patient} maxSize={"max(10vh, 10vw)"} />
                    <div id='information-personnel' className='flex-row full-width space-center flex-wrap' style={{ marginTop: '20px' }}>
                        <div className='half-width flex-column'>
                            <InformationWrapper
                                libelle={t.translate("patientGlobalInformation.name")}
                                value={`${patient?.patientInfo?.firstname} ${patient?.patientInfo?.lastname}`}
                            />
                            <InformationWrapper
                                libelle={t.translate("patientGlobalInformation.genre")}
                                value={patient?.patientInfo?.gender?.name}
                            />
                        </div>
                        <div className='half-width flex-column'>
                            <InformationWrapper
                                libelle={t.translate("patientGlobalInformation.birthdate")}
                                value={patient?.patientInfo?.birthDate ? moment(patient?.patientInfo?.birthDate).format('DD/MM/YYYY') : undefined}
                            />
                            {patient?.type?.name === 'Patient'
                                && <InformationWrapper libelle={t.translate("patientGlobalInformation.society")} value={patient?.patientInfo?.company} />
                            }
                        </div>
                    </div>
                </Fragment>
            }
        />
    )
}

export default Information