import img from 'assets/Help/order.svg';
import React from 'react';
import { useTranslation } from 'services';

const DSSecondPage: React.FC = () => {
    const t = useTranslation('dashboard/standalone/help');

    return (
        <div className="help-step">
            <h2>{t.translate('doctor.availabilities.title')}</h2>
            <div className="help-step-content">
                <div className='flex-column' style={{ textAlign: 'justify', maxWidth: '250px' }}>
                    <p>{t.translate('doctor.availabilities.body')}</p>
                </div>
                <img src={img} className='help-img' alt="Order cart" />
            </div>
        </div>
    )
}

export default DSSecondPage;