import { Button, Label, Loader, Select } from "components";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { IDatabaseSubscriptionPlan } from "interfaces/Database/IDatabaseSubscriptionPlan";
import { IDatabaseUser } from "interfaces/Database/IDatabaseUser";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "services";
import companyService from "services/company.service";

const AllocateExistingEmployee: React.FC<({
    isLoading: boolean
    setIsLoading: (newValue: boolean) => void
    setSuccess: (param: string) => void
    solution: IDatabaseSubscriptionPlan
    setEmployee: (employee: IDatabaseUser) => void
})> = ({ isLoading, setIsLoading, setSuccess, solution, setEmployee }) => {
    const t = useTranslation('dashboard/company/employees');
    const [ error, setError ] = useState<string>();
    const [ sectionIsLoading, setSectionIsLoading ] = useState<boolean>(true);

    const [ employeeList, setEmployeeList ] = useState<IDatabaseUser[]>([]);
    const [ employeeId, setEmployeeId ] = useState<string>();

    const fetchEmployees = async () => {
        setSectionIsLoading(true);

        await companyService.getAvailableEmployees()
            .then((res) => setEmployeeList(res))
            .catch((err) => setError(err && err.message ? err.message : t.translate('allocateModal.existing.getError')));

        setSectionIsLoading(false);
    }

    useEffect(() => {
        fetchEmployees();
    }, []);
    
    const handleAllocateExistingEmployee = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setError(undefined);

        if (solution?.id && employeeId) {
            await companyService.assignPlanEmployee({
                employeeId: employeeId,
                kitId: solution.id
            })
                .then(() => setSuccess(t.translate('allocateModal.existing.success')))
                .catch((err) => setError(err && err.message ? err.message : t.translate('allocateModal.existing.error')));
        }

        setIsLoading(false);
    }

    if (sectionIsLoading) {
        return (
            <Loader />
        )
    } else if (employeeList.length === 0) {
        return (
            <div className="flex-column">
                <h4>{t.translate('allocateModal.existing.none.title')}</h4>
                <p style={{ textAlign: 'center', marginBottom: '5px' }}>{t.translate('allocateModal.existing.none.addBefore')}</p>
                <p style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}>{t.translate('allocateModal.existing.none.useAbove')}</p>
                <p style={{ textAlign: 'center', marginTop: '5px' }}>{t.translate('allocateModal.existing.none.alreadyTaken')}</p>
            </div>
        )
    } else {
        return (
            <form className='allocate-form' onSubmit={(e) => handleAllocateExistingEmployee(e)}>
                <div className='allocate-form-fields'>
                    <div>
                        <Label for="employee" label={t.translate('allocateModal.existing.list')} required />
                        <Select
                            name="employee"
                            value={employeeId}
                            setValue={(e) => setEmployeeId(e.currentTarget.value)}
                            options={
                                <Fragment>
                                    <option value='' disabled>{t.translate('allocateModal.existing.choose')}</option>
                                    {employeeList.map((employee, index) =>
                                        <option key={`employee-${index}`} value={employee.id} onClick={() => setEmployee(employee)}>
                                            {employee.patientInfo && employee.patientInfo.lastname ? employee.patientInfo.lastname : ''}{" "}
                                            {employee.patientInfo && employee.patientInfo.firstname ? employee.patientInfo.firstname : ''}
                                        </option>
                                    )}
                                </Fragment>
                            }
                        />
                    </div>
                </div>

                <ErrorMessage error={error} />

                <Button
                    label={t.translate('allocateModal.existing.validate')}
                    type="submit"
                    disabled={isLoading}
                    displayLoader={sectionIsLoading}
                />
                <p className='MavenPro allocate-form-sub'>*{t.translate('allocateModal.existing.warn')}</p>
            </form>
        )
    }
}

export default AllocateExistingEmployee;