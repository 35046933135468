export interface IAddress {
    streetAddress?: string;
    postalCode?: string;
    aptNumber?: string;
    city?: string;
    countryName?: string;
    additionalInfos?: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    siret?: string;
}

export const defaultAddress: IAddress = {
	streetAddress: "",
	postalCode: "",
	aptNumber: undefined,
	city: "",
	countryName: "",
	firstname: "",
	lastname: "",
	additionalInfos: undefined,
	company: undefined,
	siret: undefined
};